import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/saga';
import StudentSaga from './student/saga';
import lessonsSaga from './lessons/saga';
import classroomSaga from './classroom/saga';
import profileimageSaga from './profileimage/saga';
import openaiSaga from "./openai/saga";
import aiactivityimageSaga from "./aiactivityimage/saga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(StudentSaga),
    fork(lessonsSaga),
    fork(classroomSaga),
    fork(profileimageSaga),
    fork(openaiSaga),
    fork(aiactivityimageSaga),
  ]);
}
