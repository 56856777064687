/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { Header } from './Header';
import { Navbar } from './Navbar';
export function HeaderWrapper() {
  const { config, classes } = useLayout();
  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div
      id='kt_app_header'
      className='app-header d-sm-block d-lg-none'
      // data-kt-sticky='true'
      // data-kt-sticky-activate-='true'
      // data-kt-sticky-name='app-header-sticky'
      // data-kt-sticky-offset="{default: '200px', lg: '300px'}"
    >
      <div
        className={clsx(
          'app-container container-xxl d-flex align-items-stretch justify-content-between',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
        id='kt_app_header_container'
      >
        <div
          className='app-header-wrapper d-flex flex-grow-1 align-items-stretch justify-content-between'
          id='kt_app_header_wrapper'
        >
          {/*--begin::Navbar LOGO icon--*/}
          <div className='app-navbar flex-shrink-0 d-lg-none'>
            {/*--begin::Header menu mobile toggle--*/}
            <div
              className='app-navbar-item ms-2 me-n3'
              title='Show header menu'
            >
              <a href='#' className='d-flex d-lg-none'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
                  className='h-30px theme-light-show'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
                  className='h-30px theme-dark-show'
                />
              </a>
            </div>
          </div>
          {/*--end::Navbar--*/}
          {/*--begin::Navbar Menu Icon--*/}
          <div className='app-navbar flex-shrink-0 d-lg-none'>
            {/*--begin::Header menu mobile toggle--*/}
            <div
              className='app-navbar-item ms-2 me-n3'
              title='Show header menu'
            >
              <div
                className='btn btn-icon btn-color-gray-600 btn-active-color-primary ms-n3 me-2 d-flex d-lg-none'
                id='kt_app_sidebar_toggle'
              >
                <span className='me-2'>Menu</span>
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                      fill='currentColor'
                    />
                    <path
                      opacity='0.3'
                      d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {/*--end::Navbar--*/}
          {/*--begin::Navbar--*/}
          <div className='app-navbar flex-shrink-0 invisible'>
            {/*--begin::Header menu mobile toggle--*/}
            <div
              className='app-navbar-item d-none ms-2 me-n3'
              title='Show header menu'
            >
              <div
                className='btn btn-icon btn-color-gray-600 btn-active-color-primary'
                id='kt_app_header_menu_toggle'
              >
                {/*--begin::Svg Icon | path: icons/duotune/text/txt001.svg--*/}
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z'
                      fill='currentColor'
                    />
                    <path
                      opacity='0.3'
                      d='M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                {/*--end::Svg Icon--*/}
              </div>
            </div>
            {/*--end::Header menu mobile toggle--*/}
          </div>
          {/*--end::Navbar--*/}
        </div>
      </div>
    </div>
  );
}
