import React, { memo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from 'theme/helpers';
import premiumPng from 'app/components/AdModal/icons/Speaker_cross.png';

const UpgradeThankYou = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <div className='modal-body border-0'>
          <div className='d-flex flex-column'>
            <div className='row mt-10'>
              <div className='col-xl-12'>
                <div className='card card-xl-stretch border-secondary border'>
                  <div className='card-body'>
                    <div data-kt-stepper-element='content' className='current'>
                      <div className='w-100'>
                        <div className='pb-4 pb-lg-5 text-center'>
                          <div className=''>
                            <img
                              src={toAbsoluteUrl(
                                '/media/illustrations/misc/success.gif'
                              )}
                              alt=''
                              height={90}
                            />
                          </div>
                        </div>
                        <div className='pb-4 pb-lg-5 text-center'>
                          <h1 className='fw-bolder text-dark'>Congrats!</h1>
                        </div>
                        <div className='card text-center bg-light rounded'>
                          <div className='card-body'>
                            <h2 className='card-title'>
                              <b>Premium</b> (No Ads)
                            </h2>
                            <img
                              src={premiumPng}
                              height={40}
                              className=''
                              alt=''
                            />
                            <p>Ad-Free, uninterrupted learning and lessons</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button variant='secondary' onClick={() => onHide()}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(UpgradeThankYou);
