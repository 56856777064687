import { AI_ACTIVITY_SHOW_TEXT, AI_IMAGE_GENERATE, AI_IMAGE_GENERATE_FAILURE, AI_IMAGE_GENERATE_SUCCESS } from "./actionTypes";

export const aiImageGenerate = (payload: any) => {
    return {
        type: AI_IMAGE_GENERATE,
        payload,
    }
};

export const aiImageGenerateSuccess = (payload: any) => ({
    type: AI_IMAGE_GENERATE_SUCCESS,
    payload,
});

export const aiImageGenerateFailure = (payload: any) => ({
    type: AI_IMAGE_GENERATE_FAILURE,
    payload,
});


export const aiActivityShowText = (payload: any) => ({
    type: AI_ACTIVITY_SHOW_TEXT,
    payload,
});



