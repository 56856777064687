/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { SidebarMenuItem } from './SidebarMenuItem';
import useIntlCustom from 'hooks/useIntlCustom';
import { useGetQuery } from 'hooks/reactQueryHelper';
import { useLocation } from 'react-router';
import { useAuth } from 'app/modules/auth';

const SidebarMenuMain = () => {
  const { formatMessage } = useIntlCustom();
  const { auth } = useAuth();

  const { pathname } = useLocation();
  const getDuaDateWarningQuery: any = useGetQuery(
    'getDuaDateWarning',
    '/enrollment/duadatewarning',
    {}
  );

  useEffect(() => {
    if (auth && auth.token) {
      getDuaDateWarningQuery.mutateAsync();
    }
  }, [pathname]);

  return (
    <>
      <div className='d-none'>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      </div>
      {/* <SidebarMenuItem
        to='/start-lesson'
        title='Comenzar lección'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/start-lesson.svg'
      /> */}
      {/* <SidebarMenuItem
        to='/class-room-new'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Clase en vivo'
        fontIcon='bi-layers'
      /> */}
      <SidebarMenuItem
        to='/course'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Curso'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem
        to='/social-channel'
        title='Canal social'
        fontIcon='bi bi-diagram-3'
        icon='/media/icons/duotune/social/soc004.svg'
      /> */}
      <SidebarMenuItem
        to='/account'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Cuenta'
        fontIcon='bi-layers'
        warningIcon={getDuaDateWarningQuery?.data?.ResultObject}
      />
      <SidebarMenuItem
        to='/teacher'
        icon='/media/icons/duotune/communication/com013.svg'
        title={formatMessage({
          id: 'TEACHERS',
        })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/contact-us'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Contactate con nosotros'
        fontIcon='bi-layers'
      />
      {/* <div className={`col mb-2`}>
        <a
          className='btn btn-icon btn-sm-bg-light btn-bg-light btn-active-light-primary btn-flex justify-content-start px-3 px-md-0 flex-row  w-100 border-gray-200'
          data-kt-button='true'
          href='mailto:info@hopecityschool.org'
        >
          <span className='menu-icon w-15'>
            <span className='svg-icon svg-icon-1 me-2 me-md-0'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='mh-50px'
              >
                <path
                  d='M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z'
                  fill='currentColor'
                ></path>
                <rect
                  opacity='0.3'
                  x='14'
                  y='4'
                  width='4'
                  height='4'
                  rx='2'
                  fill='currentColor'
                ></rect>
                <path
                  d='M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z'
                  fill='currentColor'
                ></path>
                <rect
                  opacity='0.3'
                  x='6'
                  y='5'
                  width='6'
                  height='6'
                  rx='3'
                  fill='currentColor'
                ></rect>
              </svg>
            </span>
          </span>
          <span className='fs-3 fs-md-6 fw-bold mt-1'>Contactanos</span>
        </a>
      </div> */}
      <div className='d-none'>
        <SidebarMenuItem
          to='/live-class'
          title='Live Classes'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen018.svg'
        />
        <SidebarMenuItem
          to='/'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Teacher Invitation'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/'
          title='Grades'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen020.svg'
        />
        <SidebarMenuItem
          to='/'
          title='Certificates or Transcript'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen017.svg'
        />
        <SidebarMenuItem
          to='/'
          title='Add/Drop Courses'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen016.svg'
        />
      </div>
    </>
  );
};

export { SidebarMenuMain };
