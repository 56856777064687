import LoaderContent from 'app/components/ContentLoader';
import usePercentageCounter from 'hooks/Calculate';
import useIntlCustom from 'hooks/useIntlCustom';
import { memo, useEffect, useState } from 'react';
import { toAbsoluteUrl } from 'theme/helpers';
import moment from 'moment';

const Grades = ({ getGradesPercentagePointsByCourseQuery }: any) => {
  const { formatMessage } = useIntlCustom();
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@hopecityschool.org';
  };

  const [dueDatePoint, setDueDatePoint] = useState<any>();

  useEffect(() => {
    if (
      getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course
        ?.dueDates?.length > 0
    ) {
      let points =
        getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course?.dueDates?.find(
          (item: any) =>
            moment(item?.date).isSameOrAfter(
              moment(new Date()).startOf('day'),
              'day'
            )
        )?.points;
      setDueDatePoint(points);
    }
  }, [
    getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course
      ?.dueDates,
  ]);

  return (
    <div className='card card-flush shadow h-100'>
      <div className='card-body'>
        <LoaderContent
          loading={getGradesPercentagePointsByCourseQuery.isLoading}
        />
        {getGradesPercentagePointsByCourseQuery.isSuccess && (
          <>
            <div className='row mb-5'>
              <label className='col-lg-10 col-xl-5 fs-5 fw-bold text-gray-800 d-flex align-items-center'>
                {formatMessage({
                  id: 'COURSE.COURSE_RATING',
                })}{' '}
                :
              </label>
              <div className='col-lg-7 col-xl-7'>
                <div className='d-flex flex-wrap align-items-center'>
                  <div className='fw-bolder fs-2hx text-primary fs-bolder me-7'>
                    {
                      getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                        ?.averagePercentage
                    }
                    %
                  </div>
                  <div
                    className={`${
                      getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                        ?.grade?.name === 'A'
                        ? 'badge badge-success border px-5'
                        : getGradesPercentagePointsByCourseQuery?.data
                            ?.ResultObject?.grade?.name === 'B'
                        ? 'badge badge-primary border px-5'
                        : getGradesPercentagePointsByCourseQuery?.data
                            ?.ResultObject?.grade?.name === 'C'
                        ? 'badge badge-warning border px-5'
                        : getGradesPercentagePointsByCourseQuery?.data
                            ?.ResultObject?.grade?.name === 'D'
                        ? 'badge badge-info border px-5'
                        : getGradesPercentagePointsByCourseQuery?.data
                            ?.ResultObject?.grade?.name === 'F'
                        ? 'badge badge-danger border px-5'
                        : ''
                    } fs-3 fw-bold rounded-pill`}
                  >
                    {getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                      ?.grade?.name === '-'
                      ? formatMessage({
                          id: 'COURSE.NO_GRADE_AVAILABLE',
                        })
                      : getGradesPercentagePointsByCourseQuery?.data
                          ?.ResultObject?.grade?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-8'>
              <label className='col-lg-10 col-xl-5 fs-5 fw-bold text-gray-800'>
                {formatMessage({
                  id: 'COURSE.COMPLETED_POINTS',
                })}{' '}
                :
              </label>
              <div className='col-lg-7 col-xl-7'>
                <div className='d-flex flex-wrap w-100 me-2'>
                  <div>
                    <span className='me-2 fw-bolder fs-4 text-dark'>
                      {formatMessage({
                        id: 'COURSE.POINT_COMPLETED_VALUE',
                        values: {
                          param1:
                            getGradesPercentagePointsByCourseQuery?.data
                              ?.ResultObject?.totalPointSum,
                          param2: dueDatePoint
                            ? dueDatePoint
                            : getGradesPercentagePointsByCourseQuery?.data
                                ?.ResultObject?.course
                                ?.pointsNeededToCompleteCourse,
                        },
                      })}
                    </span>
                  </div>
                  <div className='progress flex-fill align-self-center h-25px w-50'>
                    <div
                      className='progress-bar bg-info'
                      role='progressbar'
                      style={{
                        width: `${usePercentageCounter(
                          Number(
                            getGradesPercentagePointsByCourseQuery?.data
                              ?.ResultObject?.totalPointSum
                          ),
                          Number(
                            getGradesPercentagePointsByCourseQuery?.data
                              ?.ResultObject?.course
                              ?.pointsNeededToCompleteCourse
                          )
                        )}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-0'>
              <label className='col-lg-10 col-xl-5 fs-5 fw-bold text-gray-800'>
                {formatMessage({
                  id: 'COURSE.CERTIFICATE_OBTAINED',
                })}{' '}
                :
                <p className='text-gray-400 mt-1 fw-semibold fs-6'>
                  {' '}
                  (50{' '}
                  {formatMessage({
                    id: 'POINTS',
                  })}
                  )
                </p>
              </label>
              <div className='col-lg-7 col-xl-7'>
                <div className='position-relative'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='w-100'>
                      <img
                        src={toAbsoluteUrl(
                          '/media/images/hoepcityschool_grades_certificate.jpg'
                        )}
                        alt='AI Image'
                        className='img-fluid w-225px'
                      />
                    </div>
                  </div>
                </div>
                <div className='pt-2'>
                  {formatMessage({
                    id: 'COURSE.EMAIL_TO_GET_CERTIFICATE',
                  })}
                  <p>
                    <button
                      onClick={handleEmailClick}
                      type='button'
                      className='btn btn-link p-0'
                    >
                      info@hopecityschool.org
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Grades);
