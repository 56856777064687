import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_4 = (props: any) => {
  const {
    formik,
    formK12,
    formK6,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
  } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Social_Science_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          SOCIAL SCIENCE : How will your student meet the requirements?{' '}
          <span className='text-danger'> * </span>
        </label>

        <p className='form-label fw-bold'>
          CIENCIAS SOCIALES: ¿Cómo cumplirá su estudiante con los requisitos?
        </p>

        {formK12 && (
          <>
            <p className='form-label mb-2'>
              SOCIAL SCIENCE: Anthropology, economics, geography, history,
              political science, psychology, and sociology. (Age/Developmentally
              appropriate material.)
            </p>

            <p className='form-label mb-2'>
              CIENCIAS SOCIALES: Antropología, economía, geografía, historia,
              ciencias políticas, psicología y sociología. (Edad/material
              apropiado para el desarrollo).
            </p>
          </>
        )}
        {formK6 && (
          <>
            <p className='form-label mb-2'>
              SOCIAL SCIENCE: Anthropology, economics, geography, history,
              political science, psychology, and sociology, (Age/developmentally
              appropriate).
            </p>

            <p className='form-label mb-2'>
              CIENCIAS SOCIALES: Antropología, economía, geografía, historia,
              ciencias políticas, psicología y sociología. (Edad/material
              apropiado para el desarrollo).
            </p>
          </>
        )}

        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Social_Science_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('Social_Science_Requirement_Subject');
                      onHandleRquirementSubject(
                        'Social_Science_Requirement_Subject',
                        data?.Value,
                        null
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Social_Science_Requirement_Subject}
          errors={formik.errors.Social_Science_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_4;
