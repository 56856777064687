import React from 'react';

const K12_SocialScience_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>Social Science</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Learning geography, anthropology, economics, geography, history,
            political science, psychology, and sociology, etc.
          </li>
          <li>- Cultural Diversity and Tolerance</li>
          <li>- Global Economics and Trade</li>
          <li>- Geopolitics and Global Issues</li>
          <li>- Modern History and Global Perspectives</li>
          <li>- International Relations and Global Diplomacy</li>
          <li>- Abnormal Psychology and Mental Health Awareness</li>
          <li>- Global Societal Trends and Cultural Perspectives</li>
          <li>
            - Learning about American Government, US History, and California
            Government
          </li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. Aquí hay algunos ejemplos de cosas que su escuela
          local puede enseñar para cumplir con el requisito; no las exigimos
          todas.
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Aprender acerca de geografía, antropología, economía, geografía,
            historia, ciencias políticas, psicología, sociología, etc.
          </li>
          <li>- Diversidad cultural y tolerancia</li>
          <li>- Economía y Comercio Global</li>
          <li>- Geopolítica y Problemas Globales</li>
          <li>- Historia Moderna y Perspectivas Globales</li>
          <li>- Relaciones Internacionales y Diplomacia Global</li>
          <li>- Psicología anormal y conciencia de la salud mental</li>
          <li>- Tendencias Sociales Globales y Perspectivas Culturales</li>
          <li>
            - Aprender sobre el gobierno estadounidense, la historia de los EE.
            UU. y el gobierno de California
          </li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, un padre o tutor
          puede enseñarle al estudiante esta materia en casa a través de videos
          de YouTube y material en línea. Desafortunadamente, si no se cumplen
          estos requisitos para esta materia escolar, no podremos inscribir a su
          estudiante en Hope City.
        </div>
      </div>
    </div>
  );
};

export default K12_SocialScience_Requirement;
