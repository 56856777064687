import React, { useEffect, useMemo, useRef, useState } from 'react';
import Section7_12_17 from '../sections7_12/Section7_12_17';
import Section7_12_18 from '../sections7_12/Section7_12_18';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import RegistrationFormFooter from '../../RegistrationFormFooter';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import Section7_12_17_1 from '../sections7_12/Section7_12_17_1';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { removeEmptyStringProperties } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import HighlightedIssuesText from 'app/pages/enrollmenthooks/HighlightedIssuesText';

const Step3 = (props: any) => {
  const {
    setStep,
    finishYear,
    step,
    fromK12Data,
    formType,
    setIsFinishYear,
    grade,
    fieldReportedIssue,
  } = props;

  const saveFormStep3Query: any = usePostQuery(
    'saveFormStep3',
    '/enrollmentregister/save',
    {}
  );

  const IsFormK12 = useMemo(() => {
    return formType == 1 ? true : false;
  }, [formType]);

  const IsFormK6 = useMemo(() => {
    return formType == 2 ? true : false;
  }, [formType]);

  const navigate = useNavigate();

  useEffect(() => {
    if (grade == '6th') {
      setIsFinishYear(true);
    }
  }, [grade]);

  const IsFinishYear: Boolean = useMemo(() => {
    if (finishYear && IsFormK12) {
      return false;
    } else {
      return true;
    }
  }, [finishYear]);

  const isCompleteMaterial: Boolean = useMemo(() => {
    if (finishYear && formType == 1) {
      return true;
    } else if (formType == 2 && grade == '6th') {
      return true;
    } else {
      return false;
    }
  }, [grade, formType, finishYear]);

  const initialValues = {
    I_Agree_I_Will_Complete_Material: false,
    Electronic_Signature_Name: '',
    Associated_With_Handbook: false,
    Transcript_Verification_Form: false,
    FinalSave: true,
    FormType: formType,
    ButtonAction: '',
    MailSend: true,
    Status: 1,
  };

  useEffect(() => {
    if (fromK12Data) {
      formik.setFieldValue(
        'I_Agree_I_Will_Complete_Material',
        fromK12Data?.I_Agree_I_Will_Complete_Material?.value
          ? fromK12Data?.I_Agree_I_Will_Complete_Material?.value
          : false
      );
      formik.setFieldValue(
        'Associated_With_Handbook',
        fromK12Data?.Associated_With_Handbook?.value
          ? fromK12Data?.Associated_With_Handbook?.value
          : false
      );
      formik.setFieldValue(
        'Transcript_Verification_Form',
        fromK12Data?.Transcript_Verification_Form?.value
          ? fromK12Data?.Transcript_Verification_Form?.value
          : false
      );

      formik.setFieldValue(
        'Electronic_Signature_Name',
        fromK12Data?.Electronic_Signature_Name?.value
          ? fromK12Data?.Electronic_Signature_Name?.value
          : ''
      );
    }
  }, [fromK12Data]);

  const formvalidationSchema = Yup.object().shape({
    I_Agree_I_Will_Complete_Material:
      isCompleteMaterial &&
      Yup.bool().oneOf([true], 'You must check this box.'),
    Electronic_Signature_Name:
      IsFinishYear && Yup.string().required('Please enter signature name'),
    Associated_With_Handbook:
      IsFinishYear && Yup.bool().oneOf([true], 'You must check this box.'),
    Transcript_Verification_Form:
      IsFinishYear && Yup.bool().oneOf([true], 'You must check this box.'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      console.log(values);
      await removeEmptyStringProperties(values);

      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        values.MailSend = true;
        saveFormStep3Query.mutateAsync(values);
      } else {
        values.MailSend = false;
        values.Status = fromK12Data?.Status ? fromK12Data?.Status : 1;
        saveFormStep3Query.mutateAsync(values);
      }
    },
  });

  useEffect(() => {
    if (saveFormStep3Query.isSuccess) {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        setStep(0);
      } else {
        navigate('/account');
      }
    }
  }, [saveFormStep3Query.isSuccess]);

  const onFormInformationSave = (actionName: any) => {
    formik.setFieldValue('ButtonAction', actionName);
  };

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');
  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div>
      <Loader loading={saveFormStep3Query.isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <Card className='mt-5'>
          <Card.Body className='p-6'>
            {finishYear && formType == 1 && (
              <Section7_12_17
                formik={formik}
                hasFieldError={hasFieldError}
                removeHightlight={removeHightlight}
              />
            )}
            {formType == 2 && grade == '6th' && (
              <Section7_12_17_1
                formik={formik}
                hasFieldError={hasFieldError}
                removeHightlight={removeHightlight}
              />
            )}
            <Section7_12_18
              formik={formik}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
            />
            <HighlightedIssuesText formik={formik} />
          </Card.Body>
        </Card>
        <RegistrationFormFooter
          IsBackButton={true}
          setStep={setStep}
          step={step}
          grade={grade}
          onSaveForm={onFormInformationSave}
          isSubmitButton={true}
        />
      </form>
    </div>
  );
};

export default Step3;
