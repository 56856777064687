import React from 'react';

const Context = React.createContext<any>(null);

const AdProvider: React.FC<{ children: React.ReactChild }> = ({ children }) => {
  const [showAd, setShowAd] = React.useState(false);

  const callbackRef = React.useRef(() => null);

  const onCloseAd = React.useCallback(() => {
    setShowAd(false);
    callbackRef.current();
    callbackRef.current = () => null;
  }, []);

  const onShowAd = React.useCallback(() => {
    setShowAd(true);
  }, []);

  return (
    <Context.Provider
      value={{
        showAd,
        onCloseAd,
        onShowAd,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useAd() {
  return React.useContext(Context);
}

export default AdProvider;
