export const STUDENT_GET = 'STUDENT_GET';
export const STUDENT_GET_SUCCESS = 'STUDENT_GET_SUCCESS';
export const STUDENT_GET_FAILURE = 'STUDENT_GET_FAILURE';

export const STUDENT_GET_BY_ID = 'STUDENT_GET_BY_ID';
export const STUDENT_GET_BY_ID_SUCCESS = 'STUDENT_GET_BY_ID_SUCCESS';
export const STUDENT_GET_BY_ID_FAILURE = 'STUDENT_GET_BY_ID_FAILURE';

export const STUDENT_DELETE = 'STUDENT_DELETE';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAILURE = 'STUDENT_DELETE_FAILURE';

export const STUDENT_UPDATE = 'STUDENT_UPDATE';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAILURE = 'STUDENT_UPDATE_FAILURE';

export const STUDENT_GETBYID = 'STUDENT_GETBYID';
export const STUDENT_GETBYID_SUCCESS = 'STUDENT_GETBYID_SUCCESS';
export const STUDENT_GETBYID_FAILURE = 'STUDENT_GETBYID_FAILURE';
