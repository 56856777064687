import { IMAGE_UPLOAD, IMAGE_UPLOAD_FAILURE, IMAGE_UPLOAD_SUCCESS } from "./actionTypes";


export const imageUpload = (payload: any) => {
    return {
        type: IMAGE_UPLOAD,
        payload,
    }
};

export const imageUploadSuccess = (payload: any) => ({
    type: IMAGE_UPLOAD_SUCCESS,
    payload,
});

export const imageUploadFailure = (payload: any) => ({
    type: IMAGE_UPLOAD_FAILURE,
    payload,
});
