import Loader from 'app/components/Loader';
import React, { useEffect, useState } from 'react';
import GradeSubmissionFormHeader from './GradeSubmissionFormHeader';
import Section1 from './section/Section1';
import Section2 from './section/Section2';
import Section3 from './section/Section3';
import Section4 from './section/Section4';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Stepper from '../stepper/Stepper';
import Section5 from './section/Section5';

const GradeSubmissionForm = (props: any) => {
  const getByUserGradeQurey: any = usePostQuery(
    'getByUserGrade',
    '/enrollment/grade/getbyuserid',
    {}
  );

  const getEnrollmentReportQuery: any = useGetQuery(
    'getEnrollmentReport',
    '/enrollment/report/get',
    {}
  );

  const [stepper, setStepper] = useState(2);
  const [step, setStep] = useState(1);
  const [grade, setGrade] = useState<any>('');
  const [gradeFormData, setGradeFormData] = useState('');
  const [formType, setFormType] = useState<Number>(2);
  const [fieldReportedIssue, setFieldReportedIssue] = useState<any>('');
  const [finishHighSchool, setFinishHighSchool] = useState('');

  useEffect(() => {
    getByUserGradeQurey.mutateAsync({ formType: formType });
  }, [step]);

  useEffect(() => {
    if (getByUserGradeQurey.isSuccess) {
      const data = getByUserGradeQurey?.data?.ResultObject;
      setGradeFormData(data);
      setGrade(data?.School_Grade?.value);
      setFormType(data?.FormType ? data?.FormType : formType);
      setFinishHighSchool(data?.Student_Finish_High_School?.value);

      const checkFormStatus = parseInt(
        data?.School_Grade?.value?.match(/\d+/)[0]
      );
      if (checkFormStatus >= 0 && checkFormStatus <= 6) {
        setFormType(2);
        setStepper(2);
      } else {
        setStepper(3);
        setFormType(1);
      }
    }
  }, [getByUserGradeQurey.isSuccess]);

  useEffect(() => {
    getEnrollmentReportQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getEnrollmentReportQuery?.isSuccess) {
      setFieldReportedIssue(
        getEnrollmentReportQuery?.data?.ResultObject?.fieldReportedIssue
      );
    }
  }, [getEnrollmentReportQuery?.isSuccess]);

  useEffect(() => {
    if (grade) {
      const checkFormStatus = parseInt(grade?.match(/\d+/)[0]);
      if (checkFormStatus >= 0 && checkFormStatus <= 6) {
        setFormType(2);
        setStepper(2);
      } else {
        setStepper(3);
        setFormType(1);
      }
    }
  }, [grade]);

  return (
    <>
      <div>
        <Loader loading={getByUserGradeQurey.isLoading} />
        <GradeSubmissionFormHeader
          step={step}
          setStep={setStep}
          formType={formType}
        />
        {step != 0 && !getByUserGradeQurey.isLoading && (
          <Stepper stepper={stepper} step={step} />
        )}
        {step == 1 && !getByUserGradeQurey.isLoading && (
          <Section1
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 2 && formType == 2 && !getByUserGradeQurey.isLoading && (
          <Section4
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            grade={grade}
          />
        )}

        {step == 2 && formType == 1 && !getByUserGradeQurey.isLoading && (
          <Section5
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 3 && !getByUserGradeQurey.isLoading && (
          <Section4
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            finishHighSchool={finishHighSchool}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
      </div>
    </>
  );
};

export default GradeSubmissionForm;
