import Loader from 'app/components/Loader';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ReEnrollmentFormFooter from '../ReEnrollmentFormFooter';
import { removeEmptyStringProperties } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import {
  CheckBoxComponents,
  DatePickerComponents,
  RadioButtonComponents,
  SelectComponents,
  TextFieldComponents,
} from 'hooks/formikFormHook';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import SchoolYearShowDetails from 'app/pages/enrollmentmodel/SchoolYearShowDetails';

const Section1 = (props: any) => {
  const {
    setStep,
    step,
    reEnrollmentData,
    formType,
    setFormType,
    fieldReportedIssue,
  } = props;

  const saveReEnrollmentSection1Query: any = usePostQuery(
    'saveReEnrollmentSection1',
    '/reenrollment/save',
    {}
  );
  const getCalendarTypeK6Query: any = useGetQuery(
    'getCalendartype',
    '/enrollment/calendartype/get',
    {}
  );

  const navigate = useNavigate();

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');
  const [showSchoolYearModel, setShowSchoolYearModel] = useState(false);

  const [startDate, setStartDate] = useState<any>('');
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    getCalendarTypeK6Query.mutateAsync();
  }, []);

  const IsFormK12: Boolean = useMemo(() => {
    return formType == 1;
  }, [formType]);

  const initialValues = {
    Email: '',
    Firstname: '',
    Lastname: '',
    Country: '',
    DateOfBirth: '',
    Student_Grade: '',
    School_Year_Type: '',
    Credits_Graduate_HighSchool: '',
    Student_60Percentage_Higher_Classes: false,
    ButtonAction: '',
    Status: 0,
    Step: 1,
    EnrollmetType: 'ReEnrollment',
  };

  useEffect(() => {
    if (reEnrollmentData) {
      formik.setFieldValue(
        'Email',
        reEnrollmentData?.Email ? reEnrollmentData?.Email : ''
      );
      formik.setFieldValue(
        'Firstname',
        reEnrollmentData?.FirstName ? reEnrollmentData?.FirstName : ''
      );
      formik.setFieldValue(
        'Lastname',
        reEnrollmentData?.LastName ? reEnrollmentData?.LastName : ''
      );
      formik.setFieldValue(
        'Country',
        reEnrollmentData?.Country_Name ? reEnrollmentData?.Country_Name : ''
      );
      formik.setFieldValue(
        'Student_Grade',
        reEnrollmentData?.Student_Grade ? reEnrollmentData?.Student_Grade : ''
      );
      formik.setFieldValue(
        'School_Year_Type',
        reEnrollmentData?.School_Year_Type?.value
          ? reEnrollmentData?.School_Year_Type?.value
          : ''
      );
      formik.setFieldValue(
        'Credits_Graduate_HighSchool',
        reEnrollmentData?.Credits_Graduate_HighSchool?.value
          ? reEnrollmentData?.Credits_Graduate_HighSchool?.value
          : ''
      );
      formik.setFieldValue(
        'DateOfBirth',
        reEnrollmentData?.DateOfBirth?.value
          ? new Date(reEnrollmentData?.DateOfBirth?.value)
          : ''
      );
      setStartDate(
        reEnrollmentData?.DateOfBirth?.value
          ? new Date(reEnrollmentData?.DateOfBirth?.value)
          : ''
      );
      formik.setFieldValue(
        'Student_60Percentage_Higher_Classes',
        reEnrollmentData?.Student_60Percentage_Higher_Classes?.value
          ? reEnrollmentData?.Student_60Percentage_Higher_Classes?.value
          : false
      );
      formik.setFieldValue(
        'Status',
        reEnrollmentData?.Status ? reEnrollmentData?.Status : 0
      );
      formik.setFieldValue(
        'EnrollmentID',
        reEnrollmentData?.EnrollmentID
          ? reEnrollmentData?.EnrollmentID
          : undefined
      );
    }
  }, [reEnrollmentData]);

  const formvalidationSchema = Yup.object().shape({
    // Email: Yup.string().email('Email address is incorrect').required('Please enter email address'),
    // Firstname: Yup.string().required('Please enter first name'),
    // Lastname: Yup.string().required('Please enter last name'),
    // Country: Yup.string().required('Please enter country name'),
    DateOfBirth: Yup.date().required('Please select a date'),
    School_Year_Type: Yup.string().required('Please select school year type'),
    Student_Grade: Yup.string().required('Please select grade'),
    Credits_Graduate_HighSchool:
      IsFormK12 && Yup.string().required('Please select any option'),
    Student_60Percentage_Higher_Classes: Yup.bool().oneOf(
      [true],
      'You must check this box.'
    ),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      await removeEmptyStringProperties(values);
      saveReEnrollmentSection1Query.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveReEnrollmentSection1Query.isSuccess) {
      setStep(2);
    }
  }, [saveReEnrollmentSection1Query.isSuccess]);

  const gradeForm = [
    { value: '0', label: 'Kindergarten' },
    { value: '1st', label: '1st' },
    { value: '2nd', label: '2nd' },
    { value: '3rd', label: '3rd' },
    { value: '4th', label: '4th' },
    { value: '5th', label: '5th' },
    { value: '6th', label: '6th' },
    { value: '7th', label: '7th' },
    { value: '8th', label: '8th' },
    { value: '9th', label: '9th' },
    { value: '10th', label: '10th' },
    { value: '11th', label: '11th' },
    { value: '12th', label: '12th' },
  ];

  const credits_Graduate_HighSchool = [
    {
      label:
        'Yes, I understand my student will need 22 credits in high school to graduate',
      value: 'Yes',
    },
    { label: 'No, I need more explanation', value: 'No' },
  ];

  useEffect(() => {
    if (formik.values.Student_Grade) {
      const grade: any = formik.values.Student_Grade;
      const checkFormStatus = parseInt(grade.match(/\d+/)[0]);
      if (checkFormStatus >= 0 && checkFormStatus <= 6) {
        setFormType(2);
      }
      if (checkFormStatus >= 7 && checkFormStatus <= 12) {
        setFormType(1);
      }
    }
  }, [formik.values.Student_Grade]);

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  const handleDateChange = (date: any) => {
    setStartDate(date), formik.setFieldValue('DateOfBirth', date);
    removeHightlight('DateOfBirth');
  };

  const handleNoButton = () => {
    setShowSchoolYearModel(false);
    formik.setFieldValue('School_Year_Type', '');
  };

  const [checkSchoolYearType, setCheckSchoolYearType] = useState('');

  const onHandleSchoolYearTypeChange = (id: any) => {
    const data = getCalendarTypeK6Query?.data?.ResultObject;
    const foundItem = data.find((item: any) => item._id === id);
    setShowSchoolYearModel(true);
    setCheckSchoolYearType(foundItem?.calendarType);
  };

  return (
    <>
      <Loader loading={saveReEnrollmentSection1Query.isLoading} />
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className='card mt-3 mb-3' ref={tabRef}>
            <div className='card-body p-6'>
              <div className='p-3'>
                <label className='form-label'>
                  Email <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Email'
                  placeholder='Your email address'
                  type={'email'}
                  disabled={true}
                />
                <FieldsErrorText
                  touched={formik.touched.Email}
                  errors={formik.errors.Email}
                />
              </div>
              <div className='p-3'>
                <label className='form-label'>
                  Student First Name / Primer Nombre{' '}
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Firstname'
                  placeholder='Your first name'
                  disabled={true}
                />
                <FieldsErrorText
                  touched={formik.touched.Firstname}
                  errors={formik.errors.Firstname}
                />
              </div>
              <div className='p-3'>
                <label className='form-label'>
                  Student Last Name / Apellidos{' '}
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Lastname'
                  placeholder='Your last name'
                  disabled={true}
                />
                <FieldsErrorText
                  touched={formik.touched.Lastname}
                  errors={formik.errors.Lastname}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('DateOfBirth')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label'>
                  Student Date of Birth <span className='text-danger'> * </span>
                </label>
                <p className='form-label'>Fecha de nacimiento del estudiante</p>
                <div className='form-date-picker custom-datepicker'>
                  <DatePickerComponents
                    formik={formik}
                    fieldName={'DateOfBirth'}
                    startDate={startDate}
                    today={today}
                    handleDateChange={handleDateChange}
                  />
                  <FieldsErrorText
                    touched={formik.touched.DateOfBirth}
                    errors={formik.errors.DateOfBirth}
                  />
                </div>
              </div>

              <div className='p-3'>
                <label className='form-label'>
                  What country does student live in?{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label'>¿En qué país vive su estudiante?</p>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Country'
                  placeholder='Your country name'
                  onChange={() => {
                    removeHightlight('Country');
                  }}
                  disabled={true}
                />
                <FieldsErrorText
                  touched={formik.touched.Country}
                  errors={formik.errors.Country}
                />
              </div>

              <div
                className={
                  hasFieldError?.includes('School_Year_Type')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  School Year Type: <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>Tipo de año escolar:</p>
                {getCalendarTypeK6Query?.data?.ResultObject.map((data: any) => {
                  return (
                    <>
                      <div className='form-check mt-4'>
                        <RadioButtonComponents
                          formik={formik}
                          fieldName={'School_Year_Type'}
                          dataValue={data._id}
                          onChange={() => {
                            removeHightlight('School_Year_Type');
                            onHandleSchoolYearTypeChange(data._id);
                          }}
                        />
                        <label className='form-label fw-normal'>
                          {data.description} / {data.description_spanish}
                        </label>
                      </div>
                    </>
                  );
                })}

                <FieldsErrorText
                  touched={formik.touched.School_Year_Type}
                  errors={formik.errors.School_Year_Type}
                />
              </div>

              <div
                className={
                  hasFieldError?.includes('Student_60Percentage_Higher_Classes')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label'>
                  Your student must make a 60% or higher in classes to be
                  enrolled in Hope City. If your student does not achieve a 60%
                  or higher, they will be dropped and not receive credit for
                  their school year with Hope City.{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  Su estudiante debe obtener un{' '}
                  <b>
                    60 o más en las clases para recibir crédito en Hope City.
                  </b>{' '}
                  Si su estudiante no logra un <b>60 o más,</b> podría ser
                  suspendido de Hope City. ¿Entiendes y estás de acuerdo con
                  esto?
                </p>
                <p className='form-label'>
                  Do you understand and agree to this?
                </p>
                <div className='form-check mt-4'>
                  <CheckBoxComponents
                    formik={formik}
                    fieldName='Student_60Percentage_Higher_Classes'
                    onChange={() => {
                      removeHightlight('Student_60Percentage_Higher_Classes');
                    }}
                  />
                  <label className='form-label fw-normal'>
                    YES, I UNDERSTAND
                  </label>
                </div>

                <FieldsErrorText
                  touched={formik.touched.Student_60Percentage_Higher_Classes}
                  errors={formik.errors.Student_60Percentage_Higher_Classes}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Student_Grade')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label'>
                  Student's Grade Enrolling for 2023:{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label'>
                  Grado del estudiante que se inscribe para 2023:
                </p>

                <SelectComponents
                  formik={formik}
                  fieldName='Student_Grade'
                  onChange={() => {
                    removeHightlight('Student_Grade');
                  }}
                >
                  <option selected value=''>
                    Choose
                  </option>
                  {gradeForm.map((data: any) => {
                    return (
                      <option value={data.value}>
                        {data.label} {data.value != 0 && 'Grade'}{' '}
                      </option>
                    );
                  })}
                </SelectComponents>

                <FieldsErrorText
                  touched={formik.touched.Student_Grade}
                  errors={formik.errors.Student_Grade}
                />
              </div>
              {formType == 1 && (
                <div
                  className={
                    hasFieldError?.includes('Credits_Graduate_HighSchool')
                      ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                      : 'p-3'
                  }
                >
                  <label className='form-label'>
                    22 Credits to Graduate High School{' '}
                    <span className='text-danger'> * </span>
                  </label>
                  <p className='form-label'>
                    In order to graduate from high school with a Hope City
                    Diploma, your student needs to complete 22 Credits during
                    the 4 years in high school. Each full year class gives 1
                    credits. Each half year class gives .5 credits.{' '}
                  </p>
                  {credits_Graduate_HighSchool.map((data: any, index: any) => {
                    return (
                      <div className='form-check mt-2'>
                        <RadioButtonComponents
                          formik={formik}
                          fieldName={'Credits_Graduate_HighSchool'}
                          dataValue={data.value}
                          onChange={() => {
                            removeHightlight('Credits_Graduate_HighSchool');
                          }}
                        />
                        <label className='form-label'>{data.label}</label>
                      </div>
                    );
                  })}

                  <FieldsErrorText
                    touched={formik.touched.Credits_Graduate_HighSchool}
                    errors={formik.errors.Credits_Graduate_HighSchool}
                  />
                </div>
              )}
            </div>
          </div>
          <ReEnrollmentFormFooter
            isBackButton={false}
            isSubmitButton={false}
            setStep={setStep}
            step={step}
          />
        </form>
      </div>
      <SchoolYearShowDetails
        setShowSchoolYearModel={setShowSchoolYearModel}
        showSchoolYearModel={showSchoolYearModel}
        checkSchoolYearType={checkSchoolYearType}
        handleNoButton={handleNoButton}
      />
    </>
  );
};

export default Section1;
