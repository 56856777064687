import actionTypes from './actionTypes';

export function fetchLessonsAction(payload: any = {}) {
  return { type: actionTypes.GET_REQUESTED, payload };
}

export function fetchLessonByIdAction(payload: any = {}) {
  return { type: actionTypes.DETAILS_REQUESTED, payload };
}


export function clearLesson(payload: any = {}) {
  return { type: actionTypes.CLEAR_LESSON, payload };
}