/* eslint-disable no-debugger */
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/bootstrap.css';
import PhoneInput from 'react-phone-input-2';

export const CheckBoxComponents = (props: any) => {
  const { formik, fieldName, onChange } = props;

  const handleChange = (event: any) => {
    formik.handleChange(event);
    onChange?.(event);
  };

  return (
    <input
      type='checkbox'
      id={fieldName}
      {...formik.getFieldProps(fieldName)}
      onChange={handleChange}
      value='true'
      checked={formik.values[fieldName]}
      className={clsx(
        'form-check-input',
        {
          'is-invalid': formik.touched[fieldName] && formik.errors[fieldName],
        },
        {
          'is-valid': formik.touched[fieldName] && !formik.errors[fieldName],
        }
      )}
    />
  );
};

export const TextFieldComponents = (props: any) => {
  const { formik, fieldName, placeholder, type, disabled, onChange } = props;

  const handleChange = (event: any) => {
    formik.handleChange(event);
    onChange?.(event);
  };

  return (
    <input
      type={type ? type : 'text'}
      placeholder={placeholder}
      autoComplete='off'
      {...formik.getFieldProps(fieldName)}
      onChange={handleChange}
      className={clsx(
        'form-control',
        {
          'is-invalid': formik.touched[fieldName] && formik.errors[fieldName],
        },
        {
          'is-valid': formik.touched[fieldName] && !formik.errors[fieldName],
        }
      )}
      disabled={disabled ? true : false}
    />
  );
};

export const RadioButtonComponents = (props: any) => {
  const { formik, fieldName, dataValue, onChange } = props;

  const handleChange = (event: any) => {
    formik.handleChange(event);
    onChange?.(event);
  };

  return (
    <input
      type='radio'
      id={fieldName}
      {...formik.getFieldProps(fieldName)}
      onChange={handleChange}
      value={dataValue}
      checked={formik.values[fieldName] == dataValue}
      className={`form-check-input ${
        formik.touched[fieldName] && formik.errors[fieldName]
          ? 'is-invalid'
          : formik.touched[fieldName] && !formik.errors[fieldName]
          ? 'is-valid'
          : 'border border-color-dark'
      }`}
    />
  );
};

export const DatePickerComponents = (props: any) => {
  const { formik, fieldName, startDate, today, handleDateChange, onChange } =
    props;

  // const handleChange = (event: any) => {
  //   formik.handleChange(event);
  //   onChange?.(event);
  // };

  return (
    <DatePicker
      selected={startDate}
      onChange={(date: any) => {
        handleDateChange(date);
        // handleChange;
      }}
      maxDate={today}
      placeholderText='Select date of birth'
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      dateFormat='dd-MM-yyyy'
      className={clsx(
        'form-control',
        {
          'is-invalid': formik.touched[fieldName] && formik.errors[fieldName],
        },
        {
          'is-valid': formik.touched[fieldName] && !formik.errors[fieldName],
        }
      )}
    />
  );
};

export const PhoneNumberComponents = (props: any) => {
  const { formik, fieldName, onChange } = props;

  return (
    <PhoneInput
      country='us'
      value={formik.values[fieldName]}
      onChange={(value) => {
        formik.setFieldValue(fieldName, value);
        formik.handleChange(value);
        onChange?.(value);
      }}
      onBlur={formik.handleBlur}
      inputProps={{
        id: `${fieldName}`,
        className: `form-control ${
          formik.errors[fieldName] ? 'is-invalid' : ''
        }`,
      }}
    />
  );
};

export const SelectComponents = (props: any) => {
  const { formik, fieldName, children, onChange } = props;

  const handleChange = (event: any) => {
    formik.handleChange(event);
    onChange?.(event);
  };

  return (
    <div>
      <select
        aria-label={`Default select for ${fieldName}`}
        {...formik.getFieldProps(fieldName)}
        onChange={handleChange}
        className={clsx(
          'form-control',
          {
            'is-invalid': formik.touched[fieldName] && formik.errors[fieldName],
          },
          {
            'is-valid': formik.touched[fieldName] && !formik.errors[fieldName],
          }
        )}
      >
        {children}
      </select>
    </div>
  );
};

export const TextareaFieldComponents = (props: any) => {
  const { formik, fieldName, placeholder, disabled, onChange } = props;

  const handleChange = (event: any) => {
    formik.handleChange(event);
    onChange?.(event);
  };

  return (
    <textarea
      placeholder={placeholder}
      autoComplete='off'
      rows='3'
      {...formik.getFieldProps(fieldName)}
      onChange={handleChange}
      className={clsx(
        'form-control',
        {
          'is-invalid': formik.touched[fieldName] && formik.errors[fieldName],
        },
        {
          'is-valid': formik.touched[fieldName] && !formik.errors[fieldName],
        }
      )}
      disabled={disabled ? true : false}
    />
  );
};
