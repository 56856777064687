import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import { get, post } from "../../helpers/api-helper";
import { imageUploadFailure, imageUploadSuccess } from "./actions";
import { IMAGE_UPLOAD } from "./actionTypes";

const ImageUploadRequest = (data: any, config: any) => post("profileimage/update", data, config)

function* ImageUploadSaga(action: any) {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const formData = new FormData();
        formData.append('file', action.payload.file);
        formData.append("UserID", action.payload.UserID)
        const response: {
            ResultCode: string;
            ResultMessage: string;
            ResultObject: any;
        } = yield call(ImageUploadRequest, formData, config);
        yield put(imageUploadSuccess(response));
    } catch (error: any) {
        yield put(imageUploadFailure({ error: error }));
    }
}


function* authSaga() {
    yield all([takeLatest(IMAGE_UPLOAD, ImageUploadSaga)]);
}

export default authSaga;
