import React, { useState } from 'react';
import ClearFormModel from './ClearFormModel';
import useIntlCustom from 'hooks/useIntlCustom';

const EnrollmentFooter = (props: any) => {
  const { formatMessage } = useIntlCustom();

  const {
    isBackButtonShow,
    isSubmitButtonShow,
    setStep,
    step,
    onFormReset,
    onSaveForm,
    isDisableNextButton,
  } = props;

  const [show, setShow] = useState(false);

  const onClearForm = () => {
    setShow(true);
  };

  const onBackButton = () => {
    setStep(step - 1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        {isBackButtonShow && (
          <button
            className='float-start btn btn-primary mb-5 mt-5'
            style={{ marginRight: '10px' }}
            type='button'
            onClick={onBackButton}
          >
            {formatMessage({
              id: 'BACK',
            })}
          </button>
        )}
        <button
          className='float-start btn btn-primary mb-5 mt-5'
          type='submit'
          style={{ marginRight: '10px' }}
          onClick={() => {
            onSaveForm(isSubmitButtonShow ? 'Submit' : 'Next');
          }}
          disabled={isDisableNextButton}
        >
          {isSubmitButtonShow
            ? formatMessage({
                id: 'SUBMIT',
              })
            : formatMessage({
                id: 'NEXT',
              })}{' '}
        </button>
        {/* <button
          className='float-end btn btn-secondary mb-5 mt-5'
          type='button'
          onClick={onClearForm}
        >
          Clear Form
        </button> */}
      </div>
      {show && (
        <ClearFormModel
          handleClose={handleClose}
          handleShow={handleShow}
          show={show}
          onFormReset={onFormReset}
        />
      )}
    </>
  );
};

export default EnrollmentFooter;
