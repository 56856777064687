/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'theme/helpers';
import VideoNewLayout from 'app/components/VideoNewLayout';
import AchieveGrade from './AchiveGrade';
import useIntlCustom from 'hooks/useIntlCustom';
import useToaster from 'hooks/useToaster';

const Lesson = ({ data = null }: any) => {
  const navigate = useNavigate();
  const { warningToaster } = useToaster();
  const { formatMessage } = useIntlCustom();

  const [lesson, setLesson] = useState<any>({
    id: null,
    name: null,
    vocabularyVideoUrl: null,
  });

  const updateScreenWidth = () => {
    const element: any = document.getElementById(`videoNewLayout_${data._id}`);
    const kt_app_header: any = document.getElementById('kt_app_header');
    const kt_app_toolbar: any = document.getElementById('kt_app_toolbar');
    const courseCard: any = document.getElementById(`courseCard_${data._id}`);

    if (element) {
      if (window.screen.width <= 425) {
        // element.style.height = '10px !important';
        element.setAttribute(
          'style',
          `height: ${
            window.innerHeight -
            kt_app_header.clientHeight -
            kt_app_toolbar.clientHeight -
            courseCard.clientHeight -
            10
          }px !important;`
        );
      } else {
        element.setAttribute('style', 'height: 100% !important;');
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateScreenWidth();
    }, 100);
  }, []);

  // useEffect(() => {
  //   // Add an event listener when the component mounts
  //   window.addEventListener('resize', updateScreenWidth);

  //   // Clean up by removing the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('resize', updateScreenWidth);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (data?.notCompletedLesson) {
  //     const vocabularyVideoUrl =
  //       data?.notCompletedLesson?.Activity_Details?.filter(
  //         (itemActivityDetail: any) =>
  //           itemActivityDetail?.Section == 'VocabularySection'
  //       )?.find(
  //         (itemVocabularySection: any) =>
  //           itemVocabularySection?.VocabularyVideoUrl
  //       );
  //     if (vocabularyVideoUrl) {
  //       setLesson({
  //         id: data?.notCompletedLesson._id,
  //         name: data?.notCompletedLesson.Lesson_Name,
  //         vocabularyVideoUrl: vocabularyVideoUrl?.Video_Url,
  //       });
  //     }
  //   }
  // }, [data?.notCompletedLesson]);

  useEffect(() => {
    const getLessonData = (lessonData: any) => {
      const vocabularyVideoUrl = lessonData?.Activity_Details?.filter(
        (itemActivityDetail: any) =>
          itemActivityDetail?.Section === 'VocabularySection'
      )?.find(
        (itemVocabularySection: any) =>
          itemVocabularySection?.VocabularyVideoUrl
      );

      return vocabularyVideoUrl
        ? {
            id: lessonData._id,
            name: lessonData.Lesson_Name,
            vocabularyVideoUrl: vocabularyVideoUrl.Video_Url,
          }
        : null;
    };

    let lessonData = null;

    if (data?.notCompletedLesson) {
      lessonData = getLessonData(data.notCompletedLesson);
    }

    if (!lessonData && data?.repeatLesson) {
      lessonData = getLessonData(data.repeatLesson);
    }

    if (lessonData) {
      setLesson(lessonData);
    }
  }, [data?.notCompletedLesson, data?.repeatLesson]); // Ensure correct properties are monitored

  console.log('lesson', lesson);

  const handleLesson = (lessonId: any) => {
    navigate(`/course/${data?._id}/start-lesson/${lessonId}`);
  };

  return (
    <div className='h-100'>
      {data?.notCompletedLesson && lesson?.id && (
        <>
          <AchieveGrade
            joinDate={data?.createdAt}
            gradesPercentagePoints={data?.gradesPercentagePoints}
            course={data?.course}
          />
          <VideoNewLayout
            src={lesson?.vocabularyVideoUrl}
            autoPlay={'autoplay'}
            loop
            muted
            playsInline={'playsinline'}
            preload='auto'
            className='rounded-bottom h-100 aspect-ratio-9-16'
            poster={toAbsoluteUrl('/media/icons/loader1_2.gif')}
            onHandleLeftButton={() =>
              navigate(`/course/${data?._id}/student-course-details`)
            }
            onHandleLeftButtonTitle={formatMessage({
              id: 'COURSE.GRADES',
            })}
            onHandleRightButton={() => handleLesson(lesson?.id)}
            onHandleRightButtonTitle={formatMessage({
              id: 'COURSE.VIDEO_VOCABULARY',
            })}
            id={`videoNewLayout_${data._id}`}
          />
        </>
      )}
      {/* {!data?.notCompletedLesson ||
        (data?.notCompletedLesson && lesson?.id === null && (
          <div className='text-center d-flex justify-content-center align-items-center flex-column aspect-ratio-9-16'>
            <div id={`videoNewLayout_${data._id}`}>
              <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/images/noLessonFound.png')}
                  className='h-150px w-150px'
                  alt={formatMessage({
                    id: 'COURSE.LESSON_NOT_AVAILABLE_MESSAGE',
                  })}
                />
                <h3>
                  {formatMessage({
                    id: 'COURSE.LESSON_NOT_AVAILABLE_MESSAGE',
                  })}
                </h3>
              </div>
            </div>
          </div>
        ))} */}
    </div>
  );
};

export default memo(Lesson);
