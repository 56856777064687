import React, { FC } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import AddNewClass from './AddNewClass';


const AddNewClassWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Add New Class</PageTitle>
      <AddNewClass />
    </>
  );
};

export default AddNewClassWrapper;
