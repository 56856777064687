import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ShowWarningText = (props: any) => {
  const {
    showWarningModel,
    setShowWarningModel,
    textValue,
    onCloseShowWarningModel,
  } = props;

  return (
    <>
      <Modal
        show={showWarningModel}
        onHide={() => onCloseShowWarningModel()}
        size='lg'
        centered
        scrollable
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h5>{textValue}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => onCloseShowWarningModel()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowWarningText;
