import LoaderContent from 'app/components/ContentLoader';
import React from 'react';
import moment from 'moment';
import useIntlCustom from 'hooks/useIntlCustom';

const Log = ({ courseInviteId, getAssignmentByCourseInviteId }: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <div className='card card-stretch'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0 text-gray-800'>
            {/* Assignment Details */}
            {formatMessage({
              id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS',
            })}
          </h3>
        </div>
      </div>

      <div className='card-body'>
        <LoaderContent
          loading={getAssignmentByCourseInviteId.isLoading}
          loaderType='tableSection'
        />
        {getAssignmentByCourseInviteId.isSuccess && (
          <>
            <div className='table-responsive'>
              <table className='table table-row-dashed border-gray-300 align-middle gy-3'>
                <thead>
                  <tr className='fw-bold fs-5 text-gray-800'>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.DATE',
                      })}
                    </th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.POINTS',
                      })}
                    </th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.GRADE',
                      })}
                    </th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.TYPE',
                      })}
                    </th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.ASSIGNMENT',
                      })}
                    </th>
                  </tr>
                </thead>
                <tbody className='fs-6'>
                  {getAssignmentByCourseInviteId?.data?.ResultObject?.map(
                    (item: any) => (
                      <>
                        <tr>
                          <td>
                            {moment(item?.submittedDate).format('MM/DD/YYYY')}
                          </td>
                          <td>{item?.point}</td>
                          <td>{item?.percentage}%</td>
                          <td>
                            {item?.lessonId?.Lesson_Name && <>Lesson</>}
                            {item?.liveClass?.name && <>Live class</>}
                            {item?.instantClass?.videoName && (
                              <>Instant class</>
                            )}
                            {item?.googleMeetClass?.name && (
                              <>Google meet class</>
                            )}
                          </td>
                          <td>
                            {item?.lessonId?.Lesson_Name && (
                              <span title={item?.lessonId?.Lesson_Name}>
                                {item?.lessonId?.Lesson_Name}
                                <a
                                  href={`/course/${courseInviteId}/start-lesson/${item?.lessonId?._id}`}
                                >
                                  {' '}
                                  Redo
                                </a>
                              </span>
                            )}

                            {item?.liveClass?.name && (
                              <span title={item?.liveClass?.name}>
                                {item?.liveClass?.name}
                              </span>
                            )}

                            {item?.instantClass?.videoName && (
                              <span title={item?.instantClass?.videoName}>
                                {item?.instantClass?.videoName}
                              </span>
                            )}

                            {item?.googleMeetClass?.name && (
                              <span title={item?.googleMeetClass?.name}>
                                {item?.googleMeetClass?.name}
                              </span>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  )}
                  {getAssignmentByCourseInviteId.isSuccess &&
                    getAssignmentByCourseInviteId?.data?.ResultObject
                      ?.length === 0 && (
                      <tr>
                        <td colSpan={99} className='text-center text-muted'>
                          {/* No data available */}
                          {formatMessage({
                            id: 'NO_DATA_AVAILABLE',
                          })}
                        </td>
                      </tr>
                    )}
                  {getAssignmentByCourseInviteId.isLoading && (
                    <tr>
                      <td colSpan={99} className='text-center text-muted'>
                        Loading...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Log;
