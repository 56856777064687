import React, { useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useAuth } from '../core/Auth';
import AuthHeader from '../components/AuthHeader';
import { useCookies } from 'react-cookie';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SIGN_IN_METHODS_URL } from 'shared/constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useIntlCustom from 'hooks/useIntlCustom';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { toast } from 'react-toastify';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .required('Please enter email address'),
  password: Yup.string().required('Please enter password'),
});

const Login2: React.FC = () => {
  const { formatMessage } = useIntlCustom();
  const { saveAuth, setCurrentUser } = useAuth();
  const [cookieData, setCookieData] = useCookies();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const CourseId: any = searchParams.get('courseInviteId');

  const scheduleGoogleMeetId: any = searchParams.get('scheduleGoogleMeetId');

  const loginQuery: any = usePostQuery('loginQuery', `/sign_in`, {
    params: {
      t: 'web',
    },
  });

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  useEffect(() => {
    initialValues.email = cookieData.email ? cookieData.email?.toString() : '';
    initialValues.password = cookieData.password
      ? cookieData.password?.toString()
      : '';
    initialValues.rememberMe = cookieData.rememberMe === true ? true : false;
  }, [
    cookieData.email,
    cookieData.password,
    cookieData.rememberMe,
    initialValues,
  ]);

  const handleSubmit = async (values: any) => {
    try {
      loginQuery.mutateAsync(
        {
          email: values.email,
          password: values.password,
          CourseId: CourseId,
        },
        {
          params: {
            t: 'web',
          },
        }
      );
    } catch (error) {}
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    (document.getElementById('email') as HTMLInputElement).value =
      cookieData.email ? cookieData.email : '';
    (document.getElementById('password') as HTMLInputElement).value =
      cookieData.password ? cookieData.password : '';
    (document.getElementById('rememberMe') as HTMLInputElement).checked =
      cookieData.rememberMe?.toString() === 'true' ? true : false;
  }, []);

  type T = keyof typeof SIGN_IN_METHODS_URL;

  const aRef = React.useRef<any>(null);

  const [currentModalView, setCurrentModalView] = React.useState<T | null>(
    null
  );
  const [instructionShow, setInstructionShow] = React.useState(false);
  const handleInstructionModalClose = () => setInstructionShow(false);

  const handleClose = () => setCurrentModalView(null);

  useEffect(() => {
    if (loginQuery.isSuccess && loginQuery?.data) {
      if (loginQuery?.data?.ok) {
        saveAuth(loginQuery?.data);

        if (formik.values.rememberme === true) {
          setCookieData('email', formik.values.email);
          setCookieData('password', formik.values.password);
          setCookieData('rememberme', 'true');
        } else {
          setCookieData('email', '');
          setCookieData('password', '');
          setCookieData('rememberme', 'false');
        }

        if (loginQuery?.data.data.isDiplomaStudent === true) {
          navigate('/account');
        }

        if (scheduleGoogleMeetId) {
          navigate(`/google-meet-earn-point/${scheduleGoogleMeetId}`);
        }
      } else {
        formik.setStatus(loginQuery?.data?.message?.toUpperCase());
      }
    }
  }, [loginQuery.isSuccess]);

  useEffect(() => {
    if (loginQuery?.isError) {
      formik.setStatus(
        loginQuery?.error?.response?.data?.message?.toUpperCase()
      );
    }
  }, [loginQuery?.isError]);

  console.log('loginQuery', loginQuery);

  return (
    <>
      <Modal
        show={instructionShow}
        onHide={handleInstructionModalClose}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <h5>How to connect chatbot with an existing account?</h5>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>Login with your existing account.</li>
            <li>Go to Canal social menu.</li>
            <li>Click on connect button in any of social box.</li>
            <li>Enter details and click on submit.</li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleInstructionModalClose}>
            OK
          </Button>
          <Button variant='secondary' onClick={handleInstructionModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={Boolean(currentModalView)} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h4 className='delete_text'>Are you an existing user?</h4>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              setInstructionShow(true);
              handleClose();
            }}
          >
            Yes
          </Button>
          <Button variant='secondary'>No</Button>
        </Modal.Footer>
      </Modal>
      <AuthHeader />
      <a href='#' ref={aRef} hidden>
        {null}
      </a>
      <div className='py-10'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='card-body'>
            <div className='text-start mb-10'>
              <h1
                className='text-dark mb-3 fs-3x'
                data-kt-translate='sign-in-title'
              >
                {/* Sign In */}
                {formatMessage({
                  id: 'AUTH.LOGIN.SIGN_IN',
                })}
              </h1>
              <div
                className='text-gray-400 fw-semibold fs-6'
                data-kt-translate='general-desc'
              >
                {/* Welcome Hope City School ! Please Enter Your Login Details */}
                {formatMessage({
                  id: 'AUTH.LOGIN.WELCOME_MSG',
                })}
              </div>
            </div>
            {formik.status && (
              <div className='alert alert-dismissible bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10'>
                <span className='svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.3'
                      x='2'
                      y='2'
                      width='20'
                      height='20'
                      rx='10'
                      fill='currentColor'
                    ></rect>
                    <rect
                      x='11'
                      y='14'
                      width='7'
                      height='2'
                      rx='1'
                      transform='rotate(-90 11 14)'
                      fill='currentColor'
                    ></rect>
                    <rect
                      x='11'
                      y='17'
                      width='2'
                      height='2'
                      rx='1'
                      transform='rotate(-90 11 17)'
                      fill='currentColor'
                    ></rect>
                  </svg>
                </span>
                <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
                  <h4 className='mb-2 text-light'>Error</h4>
                  <span>{formik.status}</span>
                </div>
              </div>
            )}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                {formatMessage({
                  id: 'EMAIL',
                })}
                {/* Email */}
              </label>
              <input
                placeholder='Enter your email address'
                id='email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-white',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {/* Password */}
                {formatMessage({
                  id: 'PASSWORD',
                })}
              </label>
              <input
                type='password'
                placeholder='Enter your password'
                id='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-white',
                  {
                    'is-invalid':
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid':
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10'>
              <div>
                <label className='form-check form-check-inline'>
                  <input
                    className='form-check-input border-dark'
                    id='rememberMe'
                    type='checkbox'
                    {...formik.getFieldProps('rememberMe')}
                  />
                  <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1 cursor-pointer'>
                    {/* Remember Me */}
                    {formatMessage({
                      id: 'REMEMBER_ME',
                    })}
                  </span>
                </label>
              </div>
            </div>
            <div className='d-flex flex-stack flex-row-reverse'>
              <button
                id='kt_sign_in_submit'
                className='btn btn-primary me-2 flex-shrink-0 w-100'
              >
                {!loginQuery.isLoading && (
                  <span className='indicator-label'>
                    {/* Sign In */}
                    {formatMessage({
                      id: 'AUTH.LOGIN.SIGN_IN',
                    })}
                  </span>
                )}
                {loginQuery.isLoading && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </button>
            </div>
            <div className='d-flex flex-stack justify-content-center p-2'>
              <Link to='/forgot-password'>
                {' '}
                {formatMessage({
                  id: 'AUTH.FORGOT.PASSWORD',
                })}
                ?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login2;
