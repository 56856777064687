import React, { FC } from 'react'
import { toAbsoluteUrl } from '../../../theme/helpers';
const LiveClass: FC = () => {

  return (
    <div className='row g-5 g-xl-10 justify-content-center'>
      <div className='col-xl-6 mb-5 mb-xl-4'>
        <div className='card card-custom bg-body border-0 h-md-100'>
          <div className='card-body d-flex justify-content-center flex-wrap'>
            <div className="card text-center bg-light rounded w-100" >

              <div className="card-body" style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
                <div className="text-center px-4">
                  <img className="mw-100 mh-300px" alt="" src="https://app.hopecityschool.org/media/illustrations/sketchy-1/5.png" />
                </div>
                <h1 className="card-title mt-3"><b>Coming Soon...</b></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export { LiveClass }
