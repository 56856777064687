import { useAuth } from 'app/modules/auth';
import { log } from 'console';
import { post } from 'helpers/api-helper';
import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from 'theme/helpers';

const AuthenticationUrl = () => {
  const { saveAuth } = useAuth();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const liveClassRoomId = queryParams.get('liveClassRoomId');
  const scheduleGoogleMeetId = queryParams.get('scheduleGoogleMeetId');
  const courseInviteIdWaitingRoom = queryParams.get('courseInviteId');

  const { token, page, id } = useParams();
  const [error, setError] = useState(false);
  const addInviteQuery: any = usePostQuery('addInvite', '/course/invite', {});

  const putMutation = useMutation({
    mutationKey: 'authenticateTokenOrNot',
    mutationFn: (data: any) => {
      return post('user/loginFromChatbot', data);
    },
    onError: (error: any) => {
      navigate('login', { replace: true });
    },
    onSuccess: async (response: any) => {
      if (
        response.StatusCode === 200 &&
        response.ResultCode === 'SUCCESS' &&
        response?.ResultData &&
        response?.ResultData.data
      ) {
        let authData = {
          ...response?.ResultData,
          data: {
            firstName: response?.ResultData?.data?.UserId?.FirstName,
            lastName: response?.ResultData?.data?.UserId?.LastName,
            profilePic: response?.ResultData?.data?.UserId?.ProfilePic,
            isDiplomaStudent: response?.ResultData?.data?.IsDiplomaStudent,
          },
        };
        await saveAuth(authData);
        if (page === 'course' && id) {
          let formData = {
            courseInviteId: id,
          };
          await addInviteQuery.mutateAsync(formData);
          navigate(`/${page}`);
        } else if (page === 'googleMeetVocabularySubmission' && id) {
          navigate(`/google-meet-earn-point/${id}`);
        } else if (page === 'profile') {
          navigate(`/account/overview`);
        } else if (page === 'waitingRoom') {
          navigate(
            `/course/${courseInviteIdWaitingRoom}/waiting-class-room/${liveClassRoomId}`
          );
        } else if (page === 'googleMeetWaitingRoom') {
          navigate(`/google-meet-earn-point/${scheduleGoogleMeetId}`);
        } else {
          navigate(`/${page}`);
        }
      } else if (response.ResultCode === 'EXPIRED') {
        setError(response.ResultMessage);
      } else if (response.ResultCode === 'INACTIVE') {
        setError(response.ResultMessage);
      }
    },
  });

  useEffect(() => {
    if (addInviteQuery.isSuccess) {
      if (page === 'profile') {
        let origin: any = window.location.origin;
        window.location.replace(`${origin}/account/overview`);
      } else {
        let origin: any = window.location.origin;
        window.location.replace(`${origin}/${page}`);
      }
    }
  }, [addInviteQuery.isSuccess]);

  useEffect(() => {
    if (addInviteQuery.isError) {
      toast.warning('Something went to wrong..');
    }
  }, [addInviteQuery.isError]);

  useEffect(() => {
    putMutation.mutate({
      token: token,
    });
  }, []);

  return (
    <>
      <div className='border d-flex align-items-center justify-content-center vh-100'>
        <div className='d-flex flex-column align-items-center justify-content-center gap-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
            className='theme-light-show h-150px'
          />
          {putMutation.isLoading ||
            (addInviteQuery.isLoading && (
              <button className='btn btn-primary mx-5' type='button'>
                <span
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></span>
                Authentication...
              </button>
            ))}
          {/* {addInviteQuery.isLoading && (
            <button className='btn btn-primary mx-5' type='button'>
              <span className='' role='status' aria-hidden='true'></span>
              Inviting...
            </button>
          )} */}
          {putMutation.isSuccess && error && (
            <div className='alert alert-warning' role='alert'>
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AuthenticationUrl;
