import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { CheckBoxComponents, TextFieldComponents } from 'hooks/formikFormHook';

const Section7_12_18 = (props: any) => {
  const { formik, hasFieldError, removeHightlight } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Associated_With_Handbook')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          Terms and Conditions for Enrollment{' '}
          <span className='text-danger'> * </span>
        </label>
        <p className='form-label fw-bold'>
          Términos y condiciones para la inscripción
        </p>
        <label className='form-label fw-bold'>
          I understand and agree to all of the terms and guidelines mentioned in
          the Handbook and will ensure that my student and I will follow all
          said guidelines. I understand that it is my responsibility to ask a
          Hope City Counselor if I have any questions or concerns associated
          with the Handbook.
        </label>
        <label className='form-label fw-bold'>
          Entiendo y acepto todos los términos y pautas mencionados en el Manual
          y me aseguraré de que mi estudiante y yo sigamos todas las pautas
          mencionadas. Entiendo que es mi responsabilidad hacer cualquier
          pregunta o inquietud asociada con el Manual a un consejero de Hope
          City.
        </label>
        <p className='form-label'>
          Hope City Handbook Link:{' '}
          <a
            href='https://docs.google.com/document/d/1Lootn9m84rs_WNbmrDpF7LU8QXmr0JsfcpOHx6NcfJY/edit?usp=sharing'
            target='_blank'
          >
            https://docs.google.com/document/d/1Lootn9m84rs_WNbmrDpF7LU8QXmr0JsfcpOHx6NcfJY/edit?usp=sharing
          </a>
        </p>
        <p className='form-label'>
          Enlace al manual de Hope City:{' '}
          <a
            href='https://docs.google.com/document/d/1Lootn9m84rs_WNbmrDpF7LU8QXmr0JsfcpOHx6NcfJY/edit?usp=sharing'
            target='_blank'
          >
            https://docs.google.com/document/d/1Lootn9m84rs_WNbmrDpF7LU8QXmr0JsfcpOHx6NcfJY/edit?usp=sharing
          </a>
        </p>
        <div className='form-check mt-4'>
          <CheckBoxComponents
            formik={formik}
            fieldName={'Associated_With_Handbook'}
            onChange={() => {
              removeHightlight('Associated_With_Handbook');
            }}
          />
          <label className='form-label fw-normal'>
            I Agree / Estoy de acuerdo
          </label>
        </div>

        <FieldsErrorText
          touched={formik.touched.Associated_With_Handbook}
          errors={formik.errors.Associated_With_Handbook}
        />
      </div>
      <div
        className={
          hasFieldError?.includes('Transcript_Verification_Form')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          Transcript Verification Form: This is to certify that all
          transcripts/report cards submitted on behalf of my student are
          accurate and true. I agree that it is my responsibility to ensure that
          all documents submitted are not altered or falsified in any way. I
          attest that there have been no changes that alter the authenticity of
          the documents and verify that my student has received the grades with
          academic integrity. I am aware that if any documents are found to be
          forged, my student will be immediately expelled from Hope City Private
          School and will no longer be eligible to re-enroll.*This document will
          remain in effect for the duration of a student's enrollment at Hope
          City School.
        </label>
        <label className='form-label fw-bold'>
          Formulario de verificación de expediente académico. Esto es para
          certificar que todas los expedientes académicos/ boletas de
          calificaciones presentadas en nombre de mi estudiante sean precisos y
          verdaderos. Estoy de acuerdo en que es mi responsabilidad asegurarme
          de que todos los documentos presentados no sean alterados o
          falsificados de ninguna manera. Doy fe de que no ha habido
          modificaciones que alteren la autenticidad de los documentos y
          verifico que mi alumno ha recibido las calificaciones con integridad
          académica. Soy consciente de que si se descubre que algún documento es
          falsificado, mi estudiante será expulsado inmediatamente de Hope City
          Private School y ya no será elegible para volver a inscribirse. *Este
          documento permanecerá vigente durante toda la duración de la
          inscripción de los estudiantes en Hope City School.
        </label>
        <div className='form-check mt-2'>
          <CheckBoxComponents
            formik={formik}
            fieldName={'Transcript_Verification_Form'}
            onChange={() => {
              removeHightlight('Transcript_Verification_Form');
            }}
          />
          <label className='form-label fw-normal'>Agree / aceptar</label>
        </div>
        <FieldsErrorText
          touched={formik.touched.Transcript_Verification_Form}
          errors={formik.errors.Transcript_Verification_Form}
        />
      </div>
      <div className='p-3'>
        <label className='form-label fw-bold'>Release of Information:</label>
        <p className='form-label'>
          I acknowledge that I am the parent or legal guardian of the student, I
          understand that information about my student may not be released to
          unauthorized persons without my permission and that I must notify Hope
          City School in writing via a Release of Information of anyone
          authorized to request and receive information on behalf of my
          students.
        </p>
        <p className='form-label'>
          Liberación de información: Reconozco que soy el padre o tutor legal
          del estudiante. Entiendo que sin mi permiso, no se puede divulgar
          información sobre mi estudiante a personas no autorizadas y que debo
          informar a Hope City School por escrito a través de una Divulgación de
          información de cualquier persona autorizada a solicitar y recibir
          información en nombre de mis estudiantes.
        </p>
      </div>
      <div
        className={
          hasFieldError?.includes('Electronic_Signature_Name')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          Guardian's Name (Digital Signature) / Nombre del tutor (firma digital){' '}
          <span className='text-danger'> * </span>
        </label>
        <TextFieldComponents
          formik={formik}
          fieldName='Electronic_Signature_Name'
          placeholder='Your electronic signature name'
          disabled={false}
          onChange={() => {
            removeHightlight('Electronic_Signature_Name');
          }}
        />
        <FieldsErrorText
          touched={formik.touched.Electronic_Signature_Name}
          errors={formik.errors.Electronic_Signature_Name}
        />
      </div>
    </>
  );
};

export default Section7_12_18;
