import LoaderContent from 'app/components/ContentLoader';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillRightCircle } from 'react-icons/ai';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { toAbsoluteUrl } from 'theme/helpers';
import { useAuth } from 'app/modules/auth';
import useRandomNumber from 'hooks/useRandomNumber';
import useIntlCustom from 'hooks/useIntlCustom';
import Video from 'app/components/Video';

const Lesson = ({
  courseInviteId,
  getLessonFromCourse,
  getGradesPercentagePointsByCourseQuery,
}: any) => {
  const { formatMessage } = useIntlCustom();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState<any>({
    id: null,
    name: null,
    vocabularyVideoUrl: null,
  });
  let sectionType = 'VocabularySection';

  const addUserLessonLog: any = usePostQuery(
    'addUserLessonLog',
    '/userLessonsLogs/addUserLessonsLogs',
    {}
  );
  useEffect(() => {
    if (getLessonFromCourse?.data?.ResultObject?.notCompleted) {
      let vocabularyVideoUrl =
        getLessonFromCourse?.data?.ResultObject?.notCompleted?.Activity_Details?.filter(
          (itemActivityDetail: any) =>
            itemActivityDetail?.Section == 'VocabularySection'
        )?.find(
          (itemVocabularySection: any) =>
            itemVocabularySection?.VocabularyVideoUrl
        );
      if (vocabularyVideoUrl) {
        setLesson({
          id: getLessonFromCourse?.data?.ResultObject?.notCompleted?._id,
          name: getLessonFromCourse?.data?.ResultObject?.notCompleted
            ?.Lesson_Name,
          vocabularyVideoUrl: vocabularyVideoUrl?.Video_Url,
        });
      }
    }
  }, [getLessonFromCourse?.data?.ResultObject]);

  const handleVideoNextButton = (id: any) => {
    const formData = {
      LessonId: id,
      // CourseName:
      //   getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.name,
      // GradePoint:
      //   getGradesPercentagePointsByCourseQuery?.data?.ResultObject
      //     ?._overallPoint + 1,
    };
    if (formData) {
      addUserLessonLog.mutate(formData);
    }
    navigate(`/course/${courseInviteId}/start-lesson/${id}`);
  };

  const JoinLesson = () => {
    return (
      <>
        <button
          type='button'
          className='btn btn-primary btn-sm rounded-pill text-truncate'
          onClick={(e) => {
            e.stopPropagation();
            handleVideoNextButton(lesson?.id);
          }}
        >
          {sectionType === 'ConversationSection' ? (
            'Video Conversation'
          ) : sectionType === 'VocabularySection' ? (
            <>
              {formatMessage({
                id: 'COURSE.VIDEO_VOCABULARY',
              })}
            </>
          ) : sectionType === 'GrammarSection' ? (
            'Video Grammar'
          ) : (
            ''
          )}{' '}
          <AiFillRightCircle />
        </button>
      </>
    );
  };
  return (
    <div className='card card-flush shadow h-100'>
      <LoaderContent
        loading={getLessonFromCourse?.isLoading}
        loaderType='videoSection'
      />
      {getLessonFromCourse?.isSuccess && lesson?.id != null && (
        <>
          <div className='card-body rounded'>
            <h5
              className='card-title fw-bold text-gray-800 m-0 d-inline-block col-10 text-truncate'
              title={`${lesson?.name}`}
            >
              {lesson?.name}
            </h5>
            <h6 className='card-subtitle mb-2 text-muted'>
              {' '}
              {formatMessage({
                id: 'COURSE.EARN',
                values: {
                  param1: process.env.REACT_APP_DEFAULT_LESSON_POINT,
                },
              })}{' '}
              {process.env.REACT_APP_DEFAULT_LESSON_POINT}{' '}
              {formatMessage({
                id:
                  process.env.REACT_APP_DEFAULT_LESSON_POINT === '1'
                    ? 'POINT'
                    : 'COURSE.POINTs',
              })}{' '}
            </h6>
            <Video
              src={lesson?.vocabularyVideoUrl}
              autoPlay={'autoplay'}
              loop
              muted
              playsInline={'playsinline'}
              preload='auto'
              className=' rounded h-100'
              poster={toAbsoluteUrl('/media/icons/loader1_2.gif')}
              onHandleClick={<JoinLesson />}
            />
          </div>
        </>
      )}
      {getLessonFromCourse?.isSuccess && lesson?.id == null && (
        <>
          <div className='card-body rounded'>
            <div className='relative h-100 d-flex justify-content-center align-content-around flex-wrap'>
              <div>
                <img
                  src={toAbsoluteUrl('/media/images/noLessonFound.png')}
                  className='mh-125px mh-md-300px img-fluid'
                  alt='Lesson not available'
                />
              </div>
              <div className='text-center p-10'>
                <h1 className='card-title'>
                  {formatMessage({
                    id: 'COURSE.LESSON_NOT_AVAILABLE_MESSAGE',
                  })}
                </h1>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Lesson;
