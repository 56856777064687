import {
  STUDENT_DELETE,
  STUDENT_DELETE_FAILURE,
  STUDENT_DELETE_SUCCESS,
  STUDENT_GET,
  STUDENT_GETBYID,
  STUDENT_GET_BY_ID,
  STUDENT_GET_BY_ID_FAILURE,
  STUDENT_GET_BY_ID_SUCCESS,
  STUDENT_GET_FAILURE,
  STUDENT_GET_SUCCESS,
} from './actionTypes';
import { AuthActions, AuthState } from './types';

const initialState: AuthState = {
  loading: false,
  token: '',
  error: '',
  success: '',
  studentlist: '',
  studentdetails: '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STUDENT_GET:
      return {
        ...state,
      };
    case STUDENT_GET_SUCCESS:
      return {
        ...state,
        studentlist: action.payload.success,
      };
    case STUDENT_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case STUDENT_GET_BY_ID:
      return {
        ...state,
        loading: false,
        success: '',
        studentdetails: '',
      };
    case STUDENT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        studentdetails: action.payload,
        error: '',
        success: '',
        loading: '',
      };
    case STUDENT_GET_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case STUDENT_DELETE:
      return {
        ...state,
      };
    case STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
      };
    case STUDENT_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
