import { AI_ACTIVITY_SHOW_TEXT, AI_IMAGE_GENERATE, AI_IMAGE_GENERATE_FAILURE, AI_IMAGE_GENERATE_SUCCESS } from "./actionTypes"

const initialState: any = {
    aiImageUrlloading: false,
    aiImageError: "",
    aiImageSuccess: "",
    aiImageUrl: "",
    showText: "",
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AI_IMAGE_GENERATE:
            return {
                ...state,
                aiImageUrlloading: true,
                success: "",
                error: "",
                aiImageUrl: ""
            }
        case AI_IMAGE_GENERATE_SUCCESS:
            return {
                ...state,
                aiImageUrl: action.payload,
                aiImageUrlloading: false,
            }
        case AI_IMAGE_GENERATE_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case AI_ACTIVITY_SHOW_TEXT:
            return {
                ...state,
                showText: action.payload.charAt(0).toUpperCase() + action.payload.slice(1).toLowerCase(),
                aiImageUrlloading: false,
            }
        default:
            return {
                ...state
            }
    }
}
export default reducer
