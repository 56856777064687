import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';
import { RiAlertFill } from 'react-icons/ri';
import { useGetQuery } from 'hooks/reactQueryHelper';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  warningIcon?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  warningIcon = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  //{active: isActive}

  return (
    <>
      <div className={`col mb-2 ${isActive ? 'here show' : ''}`}>
        <Link
          className={clsx(
            'btn btn-icon btn-sm-bg-light btn-bg-light btn-active-light-primary btn-flex justify-content-start px-3 px-md-0 flex-row  w-100 border-gray-200'
          )}
          to={to}
          data-kt-button='true'
        >
          {hasBullet && (
            <span className='mb-2'>
              <i className='fonticon-calendar fs-2'></i>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon w-15'>
              {' '}
              <KTSVG path={icon} className='svg-icon-1 me-2 me-md-0' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}

          <span className='fs-3 fs-md-6 fw-bold mt-1'>{title}</span>

          {warningIcon && (
            <span className='m-auto'>
              <RiAlertFill size={25} color='red' />
            </span>
          )}
        </Link>
        {children}
      </div>
    </>
  );
};

export { SidebarMenuItem };
