import React from 'react';
import { getCurrentYear } from '../enrollmenthooks/EnrollmentHooks';
import EditResponseAndSaveButton from '../enrollmenthooks/EditResponseAndSaveButton';

const EnrollmentFormHeader = (props: any) => {
  const { step, setStep } = props;

  return (
    <div className='card text-center mt-5 p-6'>
      <div className='card-body p-6'>
        <h1>{getCurrentYear} Application: K-12 (Spanish/English)</h1>
        <h1>Application {getCurrentYear}: K - 12 (Español/Inglés)</h1>
        {step != 0 && (
          <>
            <div className='fs-5'>
              IMPORTANT! Please complete an application for each student
              applying.{' '}
            </div>
            <div className='fs-5'>
              ¡IMPORTANTE! Complete una solicitud para cada estudiante que
              aplica.
            </div>
          </>
        )}

        {step == 0 && (
          <>
            <div className='fs-5'>Thank you for your interest. </div>
            <div className='fs-5'>Someone will be contacting you shortly. </div>
            <EditResponseAndSaveButton setStep={setStep} />
          </>
        )}
      </div>
    </div>
  );
};

export default EnrollmentFormHeader;
