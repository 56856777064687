import React, { useEffect, useState } from 'react';
import RegistrationFormHeder from '../RegistrationFormHeder';
import Step1 from './step/Step1';
import Step2 from './step/Step2';
import Step3 from './step/Step3';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import Stepper from 'app/pages/stepper/Stepper';

const RegistrationForm = (props: any) => {
  const getByIdFormQuery: any = usePostQuery(
    'getByIdForm',
    '/enrollmentregister/getbyid',
    {}
  );

  const getEnrollmentReportQuery: any = useGetQuery(
    'getEnrollmentReport',
    '/enrollment/report/get',
    {}
  );

  const [stepper, setStepper] = useState(3);
  const [step, setStep] = useState(1);
  const [grade, setGrade] = useState<any>('');
  const [finishYear, setIsFinishYear] = useState<Boolean>();
  const [fromData, setFromData] = useState('');
  const [formType, setFormType] = useState<any>(2);
  const [fieldReportedIssue, setFieldReportedIssue] = useState<any>('');

  useEffect(() => {
    getByIdFormQuery.mutateAsync();
  }, [step]);

  useEffect(() => {
    getEnrollmentReportQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getEnrollmentReportQuery?.isSuccess) {
      setFieldReportedIssue(
        getEnrollmentReportQuery?.data?.ResultObject?.fieldReportedIssue
      );
    }
  }, [getEnrollmentReportQuery?.isSuccess]);

  useEffect(() => {
    if (getByIdFormQuery.isSuccess) {
      setFromData(getByIdFormQuery?.data?.ResultObject);
      const grade = getByIdFormQuery?.data?.ResultObject?.School_Grade?.value;
      setGrade(grade);
      setFormType(
        getByIdFormQuery?.data?.ResultObject?.FormType
          ? getByIdFormQuery?.data?.ResultObject?.FormType
          : 2
      );
    }
  }, [getByIdFormQuery.isSuccess]);

  useEffect(() => {
    if (grade) {
      const checkFormStatus = parseInt(grade?.match(/\d+/)[0]);
      if (checkFormStatus >= 0 && checkFormStatus <= 6) {
        setFormType(2);
      } else {
        setFormType(1);
      }
    }
  }, [grade]);

  return (
    <>
      <div>
        <Loader loading={getByIdFormQuery.isLoading} />
        <RegistrationFormHeder
          step={step}
          setStep={setStep}
          formType={formType}
        />
        {step != 0 && <Stepper stepper={stepper} step={step} />}
        {step == 1 && (
          <Step1
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            fromK12Data={fromData}
            formType={formType}
            setFormType={setFormType}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
        {step == 2 && (
          <Step2
            setStep={setStep}
            step={step}
            grade={grade}
            finishYear={finishYear}
            setIsFinishYear={setIsFinishYear}
            fromK12Data={fromData}
            formType={formType}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
        {step == 3 && (
          <Step3
            setStep={setStep}
            step={step}
            finishYear={finishYear}
            fromK12Data={fromData}
            formType={formType}
            setIsFinishYear={setIsFinishYear}
            grade={grade}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
      </div>
    </>
  );
};

export default RegistrationForm;
