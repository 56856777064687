import { isDevelopment, isProduction, isTesting } from './mode';

// const BASE_DEV_URL = 'http://localhost:8085';
const BASE_DEV_URL = 'http://localhost:8084';
const BASE_PROD_URL = 'https://api.hopecityschool.org'; //live API
const BASE_TEST_URL = 'https://testapi.hopecityschool.org'; //test API

export const BASE_URL = isDevelopment()
  ? BASE_DEV_URL
  : isProduction()
  ? BASE_PROD_URL
  : isTesting()
  ? BASE_TEST_URL
  : BASE_DEV_URL;
