import Modal from 'react-bootstrap/Modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BForm } from 'react-bootstrap';
import { useAuth } from 'app/modules/auth';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { useEffect } from 'react';
import Loader from 'app/components/Loader';
import useIntlCustom from 'hooks/useIntlCustom';
import FormikErrorText from 'app/components/palette/FormikErrorText';

function TeacherAddNewFolder(props: any) {
  const { formatMessage } = useIntlCustom();
  let initialValues = {
    folderName: '',
  };

  const validationSchema = Yup.object().shape({
    folderName: Yup.string().required(
      formatMessage({
        id: 'TEACHER.FOLDER_NAME.ERROR.MSG',
      })
    ),
  });

  const { show, onHide, getAllData, selectedFolderId, UserID } = props;
  const { auth } = useAuth();
  const createdBy = auth.data.userID;

  const addNewFolder: any = usePostQuery(
    'addNewFolder',
    '/folder/addNewFolder',
    {}
  );

  useEffect(() => {
    if (addNewFolder.isSuccess) {
      getAllData();
      onHide();
    }
  }, [addNewFolder.isSuccess]);

  const onSubmit = (values: any) => {
    addNewFolder.mutate({
      FolderName: values.folderName,
      ParentID: selectedFolderId,
      UserID: UserID,
    });
  };

  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {/* Add New Folder */}
          {formatMessage({
            id: 'TEACHER.ADD_NEW_FOLDER',
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader loading={addNewFolder?.isLoading || addNewFolder?.isLoading} />
        <p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              getFieldProps,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <BForm.Group className='mb-3'>
                    <BForm.Control
                      id='folderName'
                      {...getFieldProps('folderName')}
                      onChange={handleChange}
                      type='text'
                      className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                        errors.folderName && touched.folderName
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {touched.folderName && errors.folderName && (
                      <BForm.Text className='text-danger'>
                        <FormikErrorText name='folderName' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <Button type='submit'>
                    {formatMessage({
                      id: 'ADD',
                    })}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </p>
      </Modal.Body>
    </Modal>
  );
}
export default TeacherAddNewFolder;
