import Loader from 'app/components/Loader';
import React, { useEffect, useState } from 'react';
import ReEnrollmentFormHeader from './ReEnrollmentFormHeader';
import Stepper from '../stepper/Stepper';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Section1 from './section/Section1';
import Section3 from './section/Section3';
import Section2 from './section/Section2';

const ReEnrollmentForm = () => {
  const getByUserIDReEnrollmentQuery: any = usePostQuery(
    'getByUserIDReEnrollment',
    '/reenrollment/getbyid',
    {}
  );

  const getEnrollmentReportQuery: any = useGetQuery(
    'getEnrollmentReport',
    '/enrollment/report/get',
    {}
  );

  const [stepper, setStepper] = useState(3);
  const [step, setStep] = useState(1);
  const [grade, setGrade] = useState('');
  const [formType, setFormType] = useState<Number>(2);
  const [reEnrollmentData, setReEnrollmentData] = useState<any>('');
  const [fieldReportedIssue, setFieldReportedIssue] = useState<any>('');

  useEffect(() => {
    getByUserIDReEnrollmentQuery.mutateAsync({ formType: formType });
  }, [step]);

  useEffect(() => {
    if (getByUserIDReEnrollmentQuery.isSuccess) {
      setReEnrollmentData(getByUserIDReEnrollmentQuery?.data?.ResultObject);
      setGrade(getByUserIDReEnrollmentQuery?.data?.ResultObject?.Student_Grade);
    }
  }, [getByUserIDReEnrollmentQuery.isSuccess]);

  useEffect(() => {
    getEnrollmentReportQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getEnrollmentReportQuery?.isSuccess) {
      setFieldReportedIssue(
        getEnrollmentReportQuery?.data?.ResultObject?.fieldReportedIssue
      );
    }
  }, [getEnrollmentReportQuery?.isSuccess]);

  return (
    <>
      <div>
        <Loader loading={getByUserIDReEnrollmentQuery.isLoading} />
        <ReEnrollmentFormHeader
          step={step}
          formType={formType}
          setStep={setStep}
        />

        {step != 0 && !getByUserIDReEnrollmentQuery.isLoading && (
          <Stepper stepper={stepper} step={step} />
        )}

        {step == 1 && !getByUserIDReEnrollmentQuery.isLoading && (
          <Section1
            setStep={setStep}
            step={step}
            reEnrollmentData={reEnrollmentData}
            formType={formType}
            setFormType={setFormType}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 2 && !getByUserIDReEnrollmentQuery.isLoading && (
          <Section2
            setStep={setStep}
            step={step}
            grade={grade}
            reEnrollmentData={reEnrollmentData}
            formType={formType}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 3 && !getByUserIDReEnrollmentQuery.isLoading && (
          <Section3
            setStep={setStep}
            step={step}
            reEnrollmentData={reEnrollmentData}
            formType={formType}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
      </div>
    </>
  );
};

export default ReEnrollmentForm;
