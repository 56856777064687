import React from 'react';

const K6_Science_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>Science</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>- Learning Human Anatomy</li>
          <li>- Learning Fauna and Flora</li>
          <li>- Learning Ecology</li>
          <li>- Cell and molecular biology</li>
          <li>- Earth and environmental science</li>
          <li>- Learning Chemistry and Chemical Reactions</li>
          <li>- Exploring the Universe and Astronomy</li>
          <li>- Investigating Climate Change and Meteorology</li>
          <li>- Studying Microorganisms and Microbiology</li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. A continuación, se muestran algunos ejemplos de
          temas relacionados que podrían aplicarse al requisito:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>- Aprendizaje de la anatomía humana</li>
          <li>- Aprendizaje de la fauna y la flora</li>
          <li>- Aprender acerca de ecología</li>
          <li>- Biología celular y molecular</li>
          <li>- Ciencias de la Tierra y del medio ambiente</li>
          <li>- Aprendizaje de Química y Reacciones Químicas</li>
          <li>- Explorando el Universo y la Astronomía</li>
          <li>- Investigación del Cambio Climático y la Meteorología</li>
          <li>- Estudio de Microorganismos y Microbiología</li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, desafortunadamente
          no podremos inscribir a su estudiante en Hope City. Sin embargo, si un
          padre o tutor puede enseñarle al estudiante esta materia en casa a
          través de videos de youtube y material en línea, entonces se cumplirá
          con este requisito del curso y podrá inscribirse en Hope City. Por
          favor confírmanos si su escuela o usted puede cumplir con este
          requisito.
        </div>
      </div>
    </div>
  );
};

export default K6_Science_Requirement;
