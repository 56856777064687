import React from 'react';
import { Card } from 'react-bootstrap';
import { getCurrentYear } from '../enrollmenthooks/EnrollmentHooks';
import EditResponseAndSaveButton from '../enrollmenthooks/EditResponseAndSaveButton';

const GradeSubmissionFormHeader = (props: any) => {
  const { step, setStep, formType } = props;
  return (
    <>
      <Card className='text-center mt-5 p-3'>
        <Card.Body>
          <h1>
            {getCurrentYear} Grade Submission Form{' '}
            {formType == 1 ? '(7-12)' : '(K-6)'}{' '}
          </h1>
          <h1>
            Formulario de envío de calificaciones de {getCurrentYear}{' '}
            {formType == 1 ? '(7-12)' : '(K-6)'}{' '}
          </h1>
          {step != 0 && (
            <>
              <div className='fs-5'>
                IMPORTANT: Final Submission Deadline: Jan 31,{' '}
                {getCurrentYear + 1}
              </div>
              <div className='fs-5'>
                IMPORTANTE: Fecha límite de envío final: 31 de enero de{' '}
                {getCurrentYear + 1}
              </div>
            </>
          )}
          {step == 0 && (
            <>
              <div className='fs-5'>Thank you for your interest. </div>
              <div className='fs-5'>
                Someone will be contacting you shortly.{' '}
              </div>
              <EditResponseAndSaveButton setStep={setStep} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default GradeSubmissionFormHeader;
