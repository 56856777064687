import { removeAuth } from 'app/modules/auth';
import { useMutation, useQuery } from 'react-query';
import { BASE_URL } from 'shared/apiConfig';

import axios from 'axios';
import { getJwtToken } from './tokenHook';

const axiosApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosApi.interceptors.request.use((config: any) => {
  const token = getJwtToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

//validate response
axiosApi.interceptors.response.use(
  (response) => {
    if (response.data.StatusCode === 401) {
      // return (window.location.href = '/auth');
    }

    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      removeAuth();

      return (window.location.href = '/login');
    }

    return Promise.reject(error);
  }
);

const useGetQuery = (mutationKey = '', url: any, config: any = {}): any => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (config: any = {}) => {
      return await axiosApi.get(url, config).then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: async (error: any) => {
      return await error?.response?.data;
    },
  });
};

const usePostQuery = (mutationKey = '', url: any, config = {}) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (formData: any = {}) => {
      return await axiosApi
        .post(url, formData, config)
        .then((response) => response?.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: async (error: any) => {
      return await error?.response?.data;
    },
  });
};

const usePutQuery = (mutationKey = '', url: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (formData: any = {}, config: any = {}) => {
      return await axiosApi
        .put(url, formData, config)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
  });
};

const useDeleteQuery = (mutationKey = '', url: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (config: any = {}) => {
      return await axiosApi
        .delete(url, { ...config })
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
  });
};

export { useGetQuery, usePostQuery, usePutQuery, useDeleteQuery };
