/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { Languages } from './Languages';
import { toAbsoluteUrl } from '../../../helpers';
import { jwtTokenDecode } from 'hooks/tokenHook';
import useIntlCustom from 'hooks/useIntlCustom';

const HeaderUserMenu: FC = () => {
  const { logout, auth } = useAuth();
  const { formatMessage } = useIntlCustom();
  const navigate = useNavigate();
  const logoutUser = () => {
    // if (auth?.data?.isDiplomaStudent === true) {
    //   navigate('/login?isDiploma=true');
    // }
    logout();
  };

  const Fullname = auth?.data?.firstName + ' ' + auth?.data?.lastName;

  const studentId = jwtTokenDecode()?.studentId;
  const studentProfileId = jwtTokenDecode()?.studentProfileId;

  const profilePic = auth?.data?.profilePic;
  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-325px w-md-275'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {profilePic !== null && profilePic !== undefined ? (
              <img alt='Logo' src={profilePic} className='object-fit-cover' />
            ) : (
              <img alt='' src={defaultImageUrl} className='object-fit-cover' />
            )}
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {Fullname}
            </div>
            <span className='fw-semibold text-muted fs-5'>
              # {studentProfileId}
            </span>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/account/overview' className='menu-link px-5'>
          Mi Perfil
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logoutUser} className='menu-link px-5'>
          {formatMessage({
            id: 'SIGN_OFF',
          })}
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
