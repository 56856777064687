import { AI_ACTIVITY_IMAGE_DETAILS_FAILURE, AI_ACTIVITY_IMAGE_DETAILS_GET, AI_ACTIVITY_IMAGE_DETAILS_GET_SUCCESS, AI_ACTIVITY_IMAGE_DETAILS_SAVE, AI_ACTIVITY_IMAGE_DETAILS_SAVE_SUCCESS } from "./actionTypes"

const initialState: any = {
    loading: false,
    error: "",
    success: "",
    AIActivityImageList: "",
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AI_ACTIVITY_IMAGE_DETAILS_GET:
            return {
                ...state,
                loading: true,
                success: "",
                error: "",
            }

        case AI_ACTIVITY_IMAGE_DETAILS_GET_SUCCESS:
            return {
                ...state,
                AIActivityImageList: action.payload,
                loading: false,
                success: "",
            }
        case AI_ACTIVITY_IMAGE_DETAILS_SAVE:
            return {
                ...state,
                loading: true,
                success: "",
                error: "",
                AIActivityImageList: "",
            }
        case AI_ACTIVITY_IMAGE_DETAILS_SAVE_SUCCESS:
            return {
                ...state,
                success: action.payload,
                loading: false,
            }
        case AI_ACTIVITY_IMAGE_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        default:
            return {
                ...state
            }
    }
}
export default reducer
