import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
  Form as BForm,
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { getAxiosInstance, post } from 'helpers/api-helper';
import { useAuth } from 'app/modules/auth';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { useEffect } from 'react';
import Loader from 'app/components/Loader';
import FormikErrorText from 'app/components/palette/FormikErrorText';

let initialValues = {
  folderName: '',
};

const validationSchema = Yup.object().shape({
  folderName: Yup.string().required('Folder name is required'),
});

function AddNewFolder(props: any) {
  const { folder, setFolder, show, onHide, getFolder, onSortEnd } = props;
  const { auth } = useAuth();
  const createdBy = auth.data.userID;
  const addFolderQuery: any = usePostQuery(
    'addFolderQuery',
    '/folder/addFolder',
    {}
  );

  const onSubmit = (values: any) => {
    const formData = {
      folderName: values.folderName,
      createdBy: createdBy,
    };
    addFolderQuery.mutate(formData);
  };
  useEffect(() => {
    if (addFolderQuery.isSuccess) {
      getFolder?.refetch();
      onHide();
    }
  }, [addFolderQuery.isSuccess]);

  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Add New Folder
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader
          loading={addFolderQuery?.isLoading || addFolderQuery?.isLoading}
        />
        <p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            // onSubmit={(values) => {
            //   setFolder([...folder, {
            //     id: Math.floor(Math.random() * 1000000),
            //     title: values.folderName,
            //   }]);
            //   handleSubmit(values)
            //   onHide();
            //   // handle form submission here
            // }}
            onSubmit={onSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              getFieldProps,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <BForm.Group className='mb-3'>
                    <BForm.Control
                      id='folderName'
                      {...getFieldProps('folderName')}
                      onChange={handleChange}
                      type='text'
                      className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                        errors.folderName && touched.folderName
                          ? 'border border-danger'
                          : ''
                      }`}
                    />
                    {touched.folderName && errors.folderName && (
                      <BForm.Text className='text-danger'>
                        <FormikErrorText name='folderName' />
                      </BForm.Text>
                    )}
                  </BForm.Group>
                  <Button type='submit'>Add</Button>
                </Form>
              );
            }}
          </Formik>
        </p>
      </Modal.Body>
    </Modal>
  );
}
export default AddNewFolder;
