import React, { memo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form as BForm } from 'react-bootstrap';
import Loader from 'app/components/Loader';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import { Stepper } from 'react-form-stepper';

import TranslateInSpanish from 'app/components/TranslateInSpanish';
import generateBirthYears from 'hooks/useGenerateBirthYears';

const initialValues = {
  nativeLanguage: '',
  birthYear: '',
};

const validationSchema1 = yup.object().shape({
  nativeLanguage: yup
    .string()
    .nullable()
    .required('Please select native language'),
});

const validationSchema2 = yup.object().shape({
  birthYear: yup.string().nullable().required('Please select birth year'),
});

const UPDATE_URL = `/user/updateProfileAndInviteCourse`;

const PreFillProfileModal = () => {
  const queryParams: any = new URLSearchParams(window.location.search);

  const getAllLanguage: any = useGetQuery(
    'getLanguage',
    `/language/getAllLanguages`,
    {}
  );
  const updateProfileAndInviteCourseDataQuery: any = usePostQuery(
    'updateProfileAndInviteCourse',
    UPDATE_URL,
    {}
  );
  const getAllLevel: any = useGetQuery('getLevel', `/level/getAll`, {});

  const { currentUser, setCurrentUser, setPreFilledCompleted } = useAuth();

  const [steps, setSteps] = React.useState([
    {
      label: '',
    },
    {
      label: '',
    },
  ]);

  const [show, setShow] = useState(true);
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getAllLanguage.mutate();
    getAllLevel.mutate();
  }, []);

  const submitForm: any = (values: any, { setSubmitting }: any) => {
    if (step === 1) {
      setStep(2);
    } else {
      const formData = {
        Native_Language: values?.nativeLanguage,
        BirthYear: values?.birthYear,
        courseId: queryParams.get('courseId'),
      };

      if (formData) {
        updateProfileAndInviteCourseDataQuery.mutate(formData);
      }
    }
  };

  useEffect(() => {
    if (updateProfileAndInviteCourseDataQuery.isSuccess) {
      setCurrentUser(updateProfileAndInviteCourseDataQuery?.data?.ResultObject);
      setShow(false);
      setPreFilledCompleted(true);
    }
  }, [updateProfileAndInviteCourseDataQuery.isSuccess]);

  return (
    <>
      <Loader loading={updateProfileAndInviteCourseDataQuery?.isLoading} />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        mw-650px
      >
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema1}
          validationSchema={step === 1 ? validationSchema1 : validationSchema2}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={submitForm}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            getFieldProps,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (currentUser) {
                setFieldValue(
                  'nativeLanguage',
                  currentUser?.Native_Language?._id
                    ? currentUser?.Native_Language?._id
                    : ''
                );
                setFieldValue(
                  'birthYear',
                  currentUser?.BirthYear ? currentUser?.BirthYear : ''
                );
              }
            }, [currentUser]);

            return (
              <Form>
                <div className='col-12 mx-auto mt-5'>
                  <Stepper
                    activeStep={step}
                    steps={steps}
                    styleConfig={{
                      activeBgColor: 'rgb(224, 224, 224)',
                      activeTextColor: 'white',
                      completedBgColor: '#0055ffb3',
                      completedTextColor: 'white',
                      inactiveBgColor: '#e0e0e0',
                      inactiveTextColor: '#ffffff',
                      size: '3rem',
                      circleFontSize: '1.5rem',
                      labelFontSize: '0.875rem',
                      borderRadius: '50%',
                      fontWeight: '500',
                    }}
                    connectorStateColors={false}
                    hideConnectors={false}
                    className={'stepper pe-none'}
                    stepClassName={'stepper__step stepper__connector'}
                  />
                </div>
                {step === 1 && (
                  <>
                    <div className='modal-body border-0'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row'>
                                <div className='col-12'>
                                  <div className='text-center w-100 pb-5'>
                                    <h1 className='fw-bolder text-dark'>
                                      ¿Qué idioma HABLAS?
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-12'>
                                  <div className='d-flex align-items-center w-100 py-5 flex-wrap'>
                                    {getAllLanguage?.isLoading ? (
                                      <div className='d-flex justify-content-center w-100'>
                                        <div
                                          className='spinner-border'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      getAllLanguage?.data?.ResultObject?.map(
                                        (option: any, index: any) => (
                                          <label
                                            key={option._id}
                                            className='form-check form-check-custom form-check-solid py-3 w-50'
                                            htmlFor={option._id}
                                          >
                                            <Field
                                              type='radio'
                                              {...getFieldProps(
                                                'nativeLanguage'
                                              )}
                                              name='nativeLanguage'
                                              value={option._id}
                                              id={option._id}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`form-check-input ${
                                                errors.nativeLanguage &&
                                                touched.nativeLanguage
                                                  ? 'border border-danger'
                                                  : ''
                                              }`}
                                            />
                                            <span className='form-check-label fw-bolder text-dark'>
                                              <TranslateInSpanish
                                                text={option.Language_Name}
                                              />
                                            </span>
                                          </label>
                                        )
                                      )
                                    )}
                                  </div>
                                  {touched.nativeLanguage &&
                                    errors.nativeLanguage && (
                                      <BForm.Text className='text-danger'>
                                        <TranslateInSpanish
                                          text={errors.nativeLanguage}
                                        />
                                      </BForm.Text>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal.Footer
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        variant='primary'
                        type='submit'
                        disabled={getAllLanguage?.isLoading}
                      >
                        Continuar..
                      </Button>
                    </Modal.Footer>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className='modal-body border-0'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row'>
                                <div className='col-12'>
                                  <div className='text-center w-100 pb-5'>
                                    <h1 className='fw-bolder text-dark'>
                                      ¿En qué año nació el estudiante?
                                    </h1>
                                  </div>
                                </div>

                                <div className='col-12'>
                                  <Field
                                    as='select'
                                    {...getFieldProps('birthYear')}
                                    className={`form-control py-3 level_hover_text ${
                                      errors.birthYear && touched.birthYear
                                        ? 'border-danger'
                                        : ''
                                    }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option key={''} value={''}>
                                      {'Select'}
                                    </option>
                                    {generateBirthYears()?.map(
                                      (val: any, index: any) => (
                                        <option key={index} value={val}>
                                          {val}
                                        </option>
                                      )
                                    )}
                                  </Field>

                                  {touched.birthYear && errors.birthYear && (
                                    <BForm.Text className='text-danger'>
                                      <TranslateInSpanish
                                        text={errors.birthYear}
                                      />
                                    </BForm.Text>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal.Footer
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        variant='primary'
                        type='button'
                        onClick={() => setStep(step - 1)}
                      >
                        Previa
                      </Button>
                      <Button variant='success' type='submit'>
                        Entregar
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default memo(PreFillProfileModal);
