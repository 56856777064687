import React, { Suspense, memo, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { toAbsoluteUrl } from 'theme/helpers';
import premiumPng from 'app/components/AdModal/icons/Speaker_cross.png';
import useIntlCustom from 'hooks/useIntlCustom';

const Paypal = React.lazy(
  () => import('app/components/UpgradePlan/Subscription/Paypal')
);
const StripeSubscriptionElement = React.lazy(
  () =>
    import(
      'app/components/UpgradePlan/Subscription/stripe/StripeSubscriptionElement'
    )
);

const UpgradePlanModal = ({
  show,
  onHide,
  showUpgradeThankYou,
}: {
  show: boolean;
  onHide: () => void;
  showUpgradeThankYou: () => void;
}) => {
  const { formatMessage } = useIntlCustom();
  const [paymentType, setPaymentType] = React.useState('stripe');

  useEffect(() => {
    if (show) {
      setPaymentType('stripe');
    }
  }, [show]);

  function handleClick(type: any) {
    setPaymentType(type);
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className='border-0' />
        <Modal.Body className='pt-0'>
          <div className='mb-13 text-center'>
            <h1 className='mb-3'>{formatMessage({ id: 'UPGRADE.TITLE' })}</h1>
            <div className='fs-5'>
              <span className='text-muted'>
                {formatMessage({ id: 'UPGRADE.SUB_TITLE_1' })}
              </span>
              <span className='text-dark'>
                {formatMessage({ id: 'UPGRADE.SUB_TITLE_2' })}
              </span>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='row g-5'>
              <div className='col-xl-6'>
                <div className='row g-5'>
                  <div className={`col-md-6`}>
                    <button
                      className={`p-0 rounded border ${
                        paymentType === 'stripe'
                          ? 'border-primary'
                          : 'border-secondary'
                      }`}
                      onClick={() => handleClick('stripe')}
                    >
                      <img
                        src={toAbsoluteUrl('/media/icons/stripe.jpg')}
                        className={`img-fluid rounded`}
                        alt='stripe'
                      />
                    </button>
                  </div>
                  <div className={`col-md-6`}>
                    <button
                      className={`p-0 rounded border ${
                        paymentType === 'paypal'
                          ? 'border-primary'
                          : 'border-secondary'
                      }`}
                      onClick={() => handleClick('paypal')}
                    >
                      <img
                        src={toAbsoluteUrl('/media/icons/paypal.jpg')}
                        className={`img-fluid rounded`}
                        alt='paypal'
                      />
                    </button>
                  </div>
                  <div className='col-12'>
                    {paymentType === 'stripe' && (
                      <Suspense
                        fallback={
                          <div className='d-flex align-items-center justify-content-center m-5'>
                            <Spinner animation='border' role='status'>
                              <span className='visually-hidden'>
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        }
                      >
                        <StripeSubscriptionElement
                          showUpgradeThankYou={showUpgradeThankYou}
                        />
                      </Suspense>
                    )}

                    {paymentType === 'paypal' && (
                      <Suspense
                        fallback={
                          <div className='d-flex align-items-center justify-content-center m-5'>
                            <Spinner animation='border' role='status'>
                              <span className='visually-hidden'>
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        }
                      >
                        <Paypal showUpgradeThankYou={showUpgradeThankYou} />
                      </Suspense>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='card card-xl-stretch mb-xl-8 border-secondary border'>
                  <div className='card-header border-0'>
                    <h1 className='card-title'>
                      {formatMessage({ id: 'UPGRADE.YOUR_PLAN' })}
                    </h1>
                  </div>
                  <div className='card-body pt-0'>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex align-items-center flex-grow-1'>
                        <div className='d-flex flex-column'>
                          <p>
                            {formatMessage({
                              id: 'UPGRADE.PLAN.DESCRIPTION',
                              values: {
                                email: (
                                  <a href='mailto:info@hopecityschool.org'>
                                    info@hopecityschool.org
                                  </a>
                                ),
                              },
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex align-items-center my-5 py-5'>
                      <div className='d-flex align-items-center flex-grow-1'>
                        <div className='d-flex flex-column'>
                          <h3 className=''>
                            {formatMessage({ id: 'UPGRADE.TOTAL' })}
                          </h3>
                        </div>
                      </div>
                      <div className='my-0'>
                        <h3 className=''>US $5.99</h3>
                      </div>
                    </div>
                    <hr />

                    <div className='card text-center bg-light rounded'>
                      <div className='card-body'>
                        <h2 className='card-title'>
                          <b>{formatMessage({ id: 'UPGRADE.PREMIUM' })}</b> (
                          {formatMessage({ id: 'UPGRADE.NO_ADS' })}
                        </h2>
                        <img src={premiumPng} height={40} alt='Premium' />
                        <p>
                          {formatMessage({
                            id: 'UPGRADE.AD_FREE_UNINTERRUPTED',
                          })}
                          {formatMessage({
                            id: 'UPGRADE.LEARNING_AND_LESSONS',
                          })}
                        </p>
                        <h4 className='card-title'>
                          <b>
                            $5.99 / {formatMessage({ id: 'UPGRADE.MONTH' })}
                          </b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(UpgradePlanModal);
