import React, { useEffect, useRef, useState } from 'react';
import LoaderNew from '../LoaderNew';
import Select from 'react-select';
import { useGetQuery } from 'hooks/reactQueryHelper';
import * as Yup from 'yup';
import { Form as BForm, Container, Col, Row } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { MdDeleteForever } from 'react-icons/md';
import Swal from 'sweetalert2';
import useIntlCustom from 'hooks/useIntlCustom';

const GoogleMeetVocabularyForm = ({
  shuffleWord,
  scheduleGoogleMeetId,
  verifyVocabularyWordsQuery,
}: any) => {
  const { formatMessage } = useIntlCustom();

  const [lastFocusedElement, setLastFocusedElement] =
    useState<HTMLInputElement | null>(null);

  const validationSchema = Yup.object({
    courseInviteId: Yup.object().nullable().required('Please select.'),
    vocabularyWords: Yup.array()
      .of(Yup.string().required('vocabulary word is required'))
      .min(1, 'At least one vocabulary word is required'),
  });

  const initialValues = {
    courseInviteId: [],
    vocabularyWords: [],
  };

  const getAllInvitedCourseQuery: any = useGetQuery(
    'getAllInvitedCourse',
    `/course/getAllInvitedCourse`,
    {}
  );

  useEffect(() => {
    getAllInvitedCourseQuery.mutate();
  }, []);

  const _handleSubmit = async (values: any, actions: any) => {
    const formData: any = {
      scheduleGoogleMeetId: scheduleGoogleMeetId,
      vocabularyWords: values?.vocabularyWords,
      courseInviteId: values?.courseInviteId?.value,
    };

    showConfirmation(formData);
  };

  const handleFocus = (e: any) => {
    e.preventDefault();
    setLastFocusedElement(e.target);
  };

  const showConfirmation = (formData: any) => {
    Swal.fire({
      title: formatMessage({ id: 'VOCABULARY.CONFIRMATION.TITLE' }),
      text: formatMessage({ id: 'VOCABULARY.CONFIRMATION.SUBTITLE' }),
      icon: 'question', // You can use 'question' for confirmation dialogs
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: formatMessage({ id: 'CANCEL' }),
      confirmButtonColor: '#3e97ff',
    }).then((result: any) => {
      if (result.isConfirmed) {
        verifyVocabularyWordsQuery.mutate(formData);
      }
    });
  };

  return (
    <div className='card h-100'>
      <LoaderNew loading={verifyVocabularyWordsQuery?.isLoading} />
      <div className='card-header align-items-center'>
        <h5 className='card-title'>
          {formatMessage({ id: 'INSTRUCTIONS' })}:{' '}
          {formatMessage({ id: 'VOCABULARY.INSTRUCTIONS' })}
        </h5>
      </div>
      <div className='card-body'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }: any) => {
            let i: any = 0;
            const handleButtonClick = (e: any, char: string) => {
              e.preventDefault();
              const input: HTMLInputElement = lastFocusedElement!; // Get the input element
              const selectionStart: any = input.selectionStart; // Get the cursor position
              const value = input.value;
              const newValue =
                value.substring(0, selectionStart) +
                char +
                value.substring(selectionStart);

              input.selectionStart = input.selectionEnd = selectionStart + 1;
              i = selectionStart + 1;
              input.value = newValue;
              if (input) {
                setFieldValue(input.name, input.value);
                input.focus();
                input.setSelectionRange(i, i);
              }
              // Move the cursor to the end of the newly inserted character
            };

            return (
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <BForm.Group className='mb-3' controlId='courseInviteId'>
                        <BForm.Label>
                          {formatMessage({ id: 'COURSE' })} :{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </BForm.Label>
                        <Field
                          name='courseInviteId'
                          isMulti={false}
                          component={Select}
                          options={getAllInvitedCourseQuery?.data?.ResultObject.map(
                            (option: any, index: any) => {
                              return {
                                ...option,
                                value: option.courseInviteId,
                                label: option.courseName,
                              };
                            }
                          )}
                          placeholder='Select'
                          isClearable={true}
                          isDisabled={getAllInvitedCourseQuery?.isLoading}
                          isLoading={getAllInvitedCourseQuery?.isLoading}
                          classNamePrefix='select'
                          value={values?.courseInviteId}
                          onChange={(option: any) => {
                            setFieldValue('courseInviteId', option);
                          }}
                          // onBlur={handleBlur}
                          styles={{
                            control: (baseStyles: any, state: any) => {
                              return {
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? errors.courseInviteId &&
                                    touched.courseInviteId
                                    ? '#f1416c'
                                    : 'grey'
                                  : errors.courseInviteId &&
                                    touched.courseInviteId
                                  ? '#f1416c'
                                  : '#b5b5c3',
                                boxShadow: '#b5b5c3',
                                '&:hover': {
                                  borderColor:
                                    errors.courseInviteId &&
                                    touched.courseInviteId
                                      ? '#f1416c'
                                      : 'none',
                                },
                                '&:focus': {
                                  borderColor:
                                    errors.courseInviteId &&
                                    touched.courseInviteId
                                      ? '#f1416c'
                                      : 'none',
                                },
                              };
                            },
                          }}
                        />

                        {errors.courseInviteId && touched.courseInviteId ? (
                          <div className='text-danger'>
                            {errors.courseInviteId}
                          </div>
                        ) : null}
                      </BForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <BForm.Group className='mb-3' controlId='name'>
                        <BForm.Label>
                          {formatMessage({ id: 'VOCABULARY_WORD' })}:
                          <span style={{ color: 'red' }}>*</span>
                        </BForm.Label>
                        <FieldArray name='vocabularyWords'>
                          {({ insert, remove, push }) => (
                            <div className='d-flex flex-column gap-2'>
                              {values.vocabularyWords.length > 0 &&
                                values.vocabularyWords.map(
                                  (item: any, index: number) => (
                                    <div key={index}>
                                      <div
                                        key={index}
                                        className='d-flex align-items-center'
                                      >
                                        <div className='w-100'>
                                          <Field
                                            name={`vocabularyWords.${index}`}
                                            value={item}
                                            placeholder={formatMessage({
                                              id: 'VOCABULARY.FORM.PLACEHOLDER',
                                            })}
                                            type='text'
                                            onFocus={handleFocus}
                                            className={`form-control ${
                                              touched.vocabularyWords &&
                                              errors.vocabularyWords &&
                                              touched.vocabularyWords[index] &&
                                              errors.vocabularyWords[index]
                                                ? 'border border-danger'
                                                : ''
                                            } `}
                                          />
                                        </div>

                                        {/* {index > 0 && ( */}
                                        <div className=''>
                                          <button
                                            type='button'
                                            className='secondary border-0 bg-transparent fs-1 text-danger'
                                            onClick={() => remove(index)}
                                          >
                                            <MdDeleteForever />
                                          </button>
                                        </div>
                                        {/* )} */}
                                      </div>
                                      <ErrorMessage
                                        name={`vocabularyWords.${index}`}
                                        component='div'
                                        className='text-danger'
                                      />
                                    </div>
                                  )
                                )}
                              <div className='d-flex'>
                                <button
                                  type='button'
                                  className='btn btn-success btn-sm'
                                  onClick={() => push('')}
                                >
                                  {formatMessage({
                                    id: 'VOCABULARY.FORM.ADD_VOCABULARY_WORD',
                                  })}
                                </button>
                              </div>
                              {touched.vocabularyWords &&
                                errors.vocabularyWords &&
                                !Array.isArray(errors.vocabularyWords) && (
                                  <ErrorMessage
                                    name={`vocabularyWords`}
                                    component='div'
                                    className='text-danger'
                                  />
                                )}
                            </div>
                          )}
                        </FieldArray>
                      </BForm.Group>
                    </Col>
                  </Row>
                  <div className='mt-3 d-flex justify-content-between gap-5 flex-wrap'>
                    <div className='d-flex gap-5 flex-column'>
                      <div className='text-center h4'>
                        {formatMessage({ id: 'EXTRA_CHARACTER' })}:
                      </div>
                      <div className='d-flex gap-2 flex-wrap'>
                        {shuffleWord?.split('').map((item: any, index: any) => {
                          return (
                            <button
                              disabled={
                                !lastFocusedElement ||
                                !values.vocabularyWords ||
                                values.vocabularyWords.length === 0
                              }
                              type='button'
                              onClick={(e) => handleButtonClick(e, item)}
                              key={index}
                              className='badge bg-light border text-dark fs-5 p-3'
                            >
                              {item}
                            </button>
                          );
                        })}
                      </div>
                    </div>

                    <div className=' '>
                      <button type='submit' className='btn btn-primary'>
                        {formatMessage({ id: 'SUBMIT' })}
                      </button>
                    </div>
                  </div>
                </Form>
              </Container>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default GoogleMeetVocabularyForm;
