import React, { useEffect } from 'react';
import * as Yup from 'yup';

type Props = {
  editModel: boolean;
  handleEditRecord: any;
  handleCloseEditModel: any;
  studentDetail: any;
};

const StudentEditModel: React.FC<Props> = (props: any) => {
  const { editModel, handleEditRecord, handleCloseEditModel, studentDetail } =
    props;

  useEffect(() => {
    if (studentDetail) {

    }
  }, [studentDetail]);
  return (
    <div
      className={editModel ? 'modal fade show d-block' : 'modal fade'}
      id='Edit_Modal_scrollable'
    // style={{ display: "none" }}
    >
      <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {' '}
              Edit <label id=''></label>
            </h5>
            {/* begin::Close */}
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <span className='svg-icon svg-icon-2x'></span>
            </div>
            {/* end::Close */}
          </div>
          <div className='modal-body'>
            <div className='form'>
              {/*begin::Input group*/}
              <div className='row mb-5'>
                <div className='col-md-6 fv-row'>
                  <label className='required fs-5 fw-semibold mb-2'>
                    Student Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='first-name'
                  />
                </div>
                <div className='col-md-6 fv-row'>
                  <label className='required fs-5 fw-semibold mb-2'>
                    Email
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='last-name'
                  />
                </div>
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className='row mb-5'>
                <div className='col-md-6 fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      id='flexCheckChecked'
                    />
                    <label className='form-check-label'>Active ?</label>
                  </div>
                </div>
              </div>
              {/*end::Input group*/}
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              onClick={handleCloseEditModel}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleEditRecord}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentEditModel;
