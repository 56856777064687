import React from 'react';
import { BsFillFileTextFill } from 'react-icons/bs';

// import { BsFiletypeDoc, BsFiletypeDocx, BsFiletypePdf } from 'react-icons/bs';
import { MdRemoveCircle } from 'react-icons/md';
import { BiFile } from 'react-icons/bi';

const EnrollmentFormImageList = ({ imageItems, deleteImageItem }: any) => {
  return (
    <div className='d-flex flex-row flex-wrap gap-2'>
      {imageItems?.map((item: any, index: any) => {
        return (
          <div className='position-relative' key={item}>
            {['pdf', 'txt', 'doc', 'docx'].includes(
              item?.split('.').pop().toLowerCase()
            ) ? (
              <>
                {['pdf'].includes(item?.split('.').pop().toLowerCase()) && (
                  <BiFile className='img-thumbnail h-100px w-100px object-fit-cover p-2' />
                )}
                {['txt'].includes(item?.split('.').pop().toLowerCase()) && (
                  <BsFillFileTextFill className='img-thumbnail h-100px w-100px object-fit-cover p-2' />
                )}
                {['doc'].includes(item?.split('.').pop().toLowerCase()) && (
                  <BiFile className='img-thumbnail h-100px w-100px object-fit-cover p-2' />
                )}
                {['docx'].includes(item?.split('.').pop().toLowerCase()) && (
                  <BiFile className='img-thumbnail h-100px w-100px object-fit-cover p-2' />
                )}
              </>
            ) : (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                src={item}
                className='img-thumbnail h-100px w-100px object-fit-cover'
                alt='Image'
              />
            )}
            <button
              type='button'
              className='bg-transparent border-0 fs-1 position-absolute'
              style={{ right: '-15px', top: '-15px' }}
              onClick={() => deleteImageItem(index)}
            >
              <MdRemoveCircle />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default EnrollmentFormImageList;
