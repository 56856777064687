import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';
import { Button } from 'react-bootstrap';

const GradeSubmissionFormFooter = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    IsBackButton,
    setStep,
    step,
    grade,
    stepper,
    formType,
    isSubmitButton,
  } = props;

  return (
    <>
      <div className='mt-3'>
        <Button
          type='submit'
          className='btn btn-primary float-end'
          style={{ marginLeft: '10px' }}
        >
          {isSubmitButton
            ? formatMessage({
                id: 'SUBMIT',
              })
            : formatMessage({
                id: 'NEXT',
              })}
        </Button>

        <Button type='button' className='btn btn-secondary float-end'>
          {formatMessage({
            id: 'CANCEL',
          })}
        </Button>
        {IsBackButton && (
          <Button
            type='button'
            className='btn btn-secondary float-start'
            onClick={() => setStep(step - 1)}
          >
            {formatMessage({
              id: 'BACK',
            })}
          </Button>
        )}
      </div>
    </>
  );
};

export default GradeSubmissionFormFooter;
