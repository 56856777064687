import React from 'react';

const K12_Math_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>Math</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Geometry: points, lines, planes, angles, parallel lines,
            triangles, similarity, trigonometry, quadrilaterals,
            transformations, circles and area.
          </li>
          <li>
            - Precalculus: Exponential functions and logarithms, factoring and
            solving equations, functions and straight lines, geometry and word
            problems, inequalities and absolute value, trigonometry - Advanced
            Algebra and Quadratic Equations
          </li>
          <li>- Geometry: Advanced Concepts and Trigonometry</li>
          <li>- Math Puzzles and Logic Problems</li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. Aquí hay algunos ejemplos de cosas que su escuela
          local puede enseñar para cumplir con el requisito; no las exigimos
          todas.
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Álgebra: Fundamentos de álgebra, exponentes, simplificación de
            expresiones algebraicas, polinomios, ecuaciones cuadráticas, etc.
          </li>
          <li>
            - Geometría: puntos, líneas, planos, ángulos, rectas paralelas,
            triángulos, semejanzas, trigonometría, cuadriláteros,
            transformaciones, círculos y áreas.
          </li>
          <li>
            - Precálculo: Funciones exponenciales y logaritmos, factorización y
            resolución de ecuaciones, funciones y rectas, geometría y problemas
            verbales, desigualdades y valor absoluto, trigonometría
          </li>
          <li>- Álgebra avanzada y ecuaciones cuadráticas</li>
          <li>- Geometría: Conceptos Avanzados y Trigonometría</li>
          <li>- Acertijos matemáticos y problemas de lógica</li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, un padre o tutor
          puede enseñarle al estudiante esta materia en casa a través de videos
          de YouTube y material en línea. Desafortunadamente, si no se cumplen
          estos requisitos para esta materia escolar, no podremos inscribir a su
          estudiante en Hope City.
        </div>
      </div>
    </div>
  );
};

export default K12_Math_Requirement;
