import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { CheckBoxComponents } from 'hooks/formikFormHook';

const Section7_12_17 = (props: any) => {
  const { formik, hasFieldError, removeHightlight } = props;

  const SeniorYearRequirements = [
    {
      Value_English: 'USA History',
      Value_Spanish: 'Historia de Estados Unidos',
      Link: 'https://forms.gle/gCx5UsqkVADECF6W8',
    },
    {
      Value_English: 'Government of USA',
      Value_Spanish: 'Gobierno de EE.UU.',
      Link: 'https://forms.gle/vscwtrDLcmqA4nb97',
    },
    {
      Value_English: 'History of California',
      Value_Spanish: 'Historia de California',
      Link: 'https://forms.gle/JjB6HrY8oTXVnDEd7',
    },
    {
      Value_English: 'Government of California',
      Value_Spanish: 'Gobierno de California',
      Link: 'https://forms.gle/owzwRaQsTFFhGLjHA',
    },
    {
      Value_English: 'General Agriculture',
      Value_Spanish: 'Agricultura general',
      Link: 'https://forms.gle/Xv2SEM3TiGrsQwaK7',
    },
    {
      Value_English: 'General Business Education',
      Value_Spanish: 'Educación comercial general',
      Link: 'https://forms.gle/qQ72NT3xdBsJcJBi6',
    },
    {
      Value_English: 'Human Rights Overview',
      Value_Spanish: 'Panorama general de los derechos humanos',
      Link: 'https://forms.gle/7iPsKzTM6NqzQaCe7',
    },
    {
      Value_English: 'Consumer and Housemaking Education',
      Value_Spanish: 'Educación para el consumidor y el sector doméstico',
      Link: 'https://forms.gle/PeGhd1F2MDZr36FUA',
    },
  ];

  return (
    <>
      <div className='p-3'>
        <label className='form-label fw-bold'>
          Senior Year Requirements / Requisitos del último año
        </label>
        <p className='form-label'>
          Students that are a senior and will be graduating this year must
          complete the following material before the end of the year in order to
          graduate with Hope City. / Los estudiantes de último año que se
          graduarán este año deben completar el siguiente material antes de fin
          de año para poder graduarse en Hope City.
        </p>
      </div>
      <div
        className={
          hasFieldError?.includes('I_Agree_I_Will_Complete_Material')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        {SeniorYearRequirements.map((data: any, index: any) => {
          return (
            <>
              <label className='form-label fw-bold'>
                {index + 1}. {data.Value_English + ' / ' + data.Value_Spanish}
              </label>
              <p>
                {' '}
                <a href={data.Link} target='_blank'>
                  {data.Link}
                </a>
              </p>
            </>
          );
        })}

        <div className='form-check mt-4'>
          <CheckBoxComponents
            formik={formik}
            fieldName={'I_Agree_I_Will_Complete_Material'}
            onChange={() => {
              removeHightlight('I_Agree_I_Will_Complete_Material');
            }}
          />
          <label className='form-label fw-normal'>
            I agree that I will complete this material before the end of the
            year. / Acepto que completaré este material antes de fin de año.
          </label>
        </div>
        <FieldsErrorText
          touched={formik.touched.I_Agree_I_Will_Complete_Material}
          errors={formik.errors.I_Agree_I_Will_Complete_Material}
        />
      </div>
    </>
  );
};

export default Section7_12_17;
