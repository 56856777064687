export function createSagaActionTypes<
  E extends string,
  A extends string,
  T extends readonly string[]
>(
  entity: E,
  actionName: A,
  types: T
): {
  [K in T[number] as Uppercase<K>]: `@@saga/@@${Lowercase<E>}/${Uppercase<A>}_${Uppercase<E>}_${Uppercase<K>}`;
} {
  const entries = types.map((type) => {
    const key = type.toUpperCase();
    const value =
      `@@saga/@@${entity.toLowerCase()}/${actionName.toUpperCase()}_${entity.toUpperCase()}_${type.toUpperCase()}` as const;
    return [key, value];
  });

  const obj = Object.fromEntries(entries);

  return obj;
}
