import { IoIosArrowForward } from 'react-icons/io';

const TeacherFolderBreadcrumbs: React.FC<any> = (props: any) => {
  const {
    breadcrumbs,
    onFolderClick,
    getBreadcrumb,
    setBreadcrumbs,
    selectedFolderId,
    getRecord,
    getFolderList,
  } = props;
  return (
    <>
      <h3>
        {breadcrumbs?.length > 0 && (
          <div className='breadcrumbs'>
            {breadcrumbs.map((crumb: any, index: any) => {
              const lastIndex = breadcrumbs.length - 1;
              return (
                <h4
                  key={index}
                  className={`breadcrumb-link text-center pointer-class ${
                    selectedFolderId == crumb.ID
                      ? 'text-secondary-emphasis'
                      : 'text-dark'
                  }`}
                  onClick={() => {
                    if (crumb.ID) {
                      onFolderClick(crumb.ID),
                        setBreadcrumbs([
                          { ID: null, FolderName: 'Home' },
                          ...getBreadcrumb(crumb.ID),
                        ]),
                        getRecord(crumb.ID),
                        getFolderList(crumb.ID);
                    } else {
                      onFolderClick(crumb.ID),
                        getRecord(crumb.ID),
                        getFolderList(crumb.ID),
                        setBreadcrumbs([{ ID: null, FolderName: 'Home' }]);
                    }
                  }}
                >
                  {crumb.FolderName}{' '}
                  <span>
                    {lastIndex != index && <IoIosArrowForward size={20} />}
                  </span>
                </h4>
              );
            })}
          </div>
        )}
      </h3>
    </>
  );
};

export default TeacherFolderBreadcrumbs;
