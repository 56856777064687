import React, { memo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form as BForm } from 'react-bootstrap';
import Loader from 'app/components/Loader';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import { Stepper } from 'react-form-stepper';
import TranslateInSpanish from 'app/components/TranslateInSpanish';
import { toAbsoluteUrl } from '../../../theme/helpers';
import generateBirthYears from 'hooks/useGenerateBirthYears';
import Video from 'app/components/Video';
const initialValues = {
  targetLanguage: '',
  nativeLanguage: '',
  level: '',
  birthYear: '',
};

const validationSchema1 = yup.object().shape({
  targetLanguage: yup
    .string()
    .required('Please select target language')
    .notOneOf(
      [yup.ref('nativeLanguage')],
      'Target language and Native language should not be same.'
    ),
});

const validationSchema2 = yup.object().shape({
  nativeLanguage: yup
    .string()
    .nullable()
    .required('Please select native language')
    .notOneOf(
      [yup.ref('targetLanguage')],
      'Target language and Native language should not be same.'
    ),
});

const validationSchema3 = yup.object().shape({
  level: yup.string().nullable().required('Please select level'),
});

const validationSchema4 = yup.object().shape({
  birthYear: yup.string().nullable().required('Please select birth year'),
});

const UPDATE_URL = `/user/updateProfileAndInviteCourse`;

const PreFillProfileInviteModal = () => {
  const videoMediaUrl = toAbsoluteUrl('/media/images/free_course_.mp4');
  const getAllLanguage: any = useGetQuery(
    'getLanguage',
    `/language/getAllLanguages`,
    {}
  );
  const updateProfileAndInviteCourseDataQuery: any = usePostQuery(
    'updateProfileAndInviteCourse',
    UPDATE_URL,
    {}
  );
  const getAllLevel: any = useGetQuery('getLevel', `/level/getAll`, {});

  const { currentUser, setCurrentUser, setPreFilledCompleted } = useAuth();

  const [steps, setSteps] = React.useState([
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: '',
    },
  ]);

  const [show, setShow] = useState(true);
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getAllLanguage.mutate();
    getAllLevel.mutate();
  }, []);

  const submitForm: any = (values: any, { setSubmitting }: any) => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else {
      const formData = {
        Native_Language: values?.nativeLanguage,
        Target_Language: values?.targetLanguage,
        Level: values?.level,
        BirthYear: values?.birthYear,
      };

      if (formData) {
        updateProfileAndInviteCourseDataQuery.mutate(formData);
      }
    }
  };

  useEffect(() => {
    if (updateProfileAndInviteCourseDataQuery.isSuccess) {
      setCurrentUser(updateProfileAndInviteCourseDataQuery?.data?.ResultObject);
      setShow(false);
      setPreFilledCompleted(true);
    }
  }, [updateProfileAndInviteCourseDataQuery.isSuccess]);

  return (
    <>
      <Loader loading={updateProfileAndInviteCourseDataQuery?.isLoading} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <div className='modal-body'>
          <div className='row g-5'>
            <div className='col-md-4'>
              <div className='position-relative h-xl-100'>
                <div className='embed-responsive embed-responsive-16by9 h-xl-100'>
                  <div className='d-flex align-items-center justify-content-center h-xl-100'>
                    <Video
                      src={videoMediaUrl}
                      autoPlay={'autoplay'}
                      loop
                      muted
                      playsInline={'playsinline'}
                      preload='auto'
                      className=' rounded h-100'
                      poster={toAbsoluteUrl('/media/icons/loader1_2.gif')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-8'>
              <Formik
                key='formik'
                initialValues={initialValues}
                // validationSchema={validationSchema1}
                validationSchema={
                  step === 1
                    ? validationSchema1
                    : step === 2
                    ? validationSchema2
                    : step === 3
                    ? validationSchema3
                    : validationSchema4
                }
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={submitForm}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  getFieldProps,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    if (currentUser) {
                      setFieldValue(
                        'targetLanguage',
                        currentUser?.Target_Language?._id
                          ? currentUser?.Target_Language?._id
                          : ''
                      );
                      setFieldValue(
                        'nativeLanguage',
                        currentUser?.Native_Language?._id
                          ? currentUser?.Native_Language?._id
                          : ''
                      );
                      setFieldValue(
                        'level',
                        currentUser?.Level?._id ? currentUser?.Level?._id : ''
                      );
                      setFieldValue(
                        'birthYear',
                        currentUser?.BirthYear ? currentUser?.BirthYear : ''
                      );
                    }
                  }, [currentUser]);

                  const levelListFilter = getAllLevel?.data?.filter(
                    (item: any) => values.level == item._id
                  );
                  let levelListFilterDescription;
                  if (levelListFilter?.length > 0) {
                    levelListFilterDescription =
                      levelListFilter[0].Level_Description;
                  }

                  return (
                    <Form className='border-start w-100 h-100' key='form'>
                      <div className='row h-100 align-content-between flex-wrap gap-5'>
                        <div className='col-10 mx-auto'>
                          <Stepper
                            activeStep={step}
                            steps={steps}
                            styleConfig={{
                              activeBgColor: 'rgb(224, 224, 224)',
                              activeTextColor: 'white',
                              completedBgColor: '#0055ffb3',
                              completedTextColor: 'white',
                              inactiveBgColor: '#e0e0e0',
                              inactiveTextColor: '#ffffff',
                              size: '3rem',
                              circleFontSize: '1.5rem',
                              labelFontSize: '0.875rem',
                              borderRadius: '50%',
                              fontWeight: '500',
                            }}
                            connectorStateColors={false}
                            hideConnectors={false}
                            className={'stepper pe-none'}
                            stepClassName={'stepper__step stepper__connector'}
                          />
                        </div>
                        {step === 1 && (
                          <>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-10 mx-auto'>
                                  <div className='text-center w-100 pb-5'>
                                    <h1 className='fw-bolder text-dark'>
                                      ¿Qué Idioma quieres APRENDER?
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-10 mx-auto'>
                                  <div className='d-flex align-items-center w-100 py-5 flex-wrap'>
                                    {getAllLanguage?.isLoading ? (
                                      <div className='d-flex justify-content-center w-100'>
                                        <div
                                          className='spinner-border'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      getAllLanguage?.data?.ResultObject?.map(
                                        (option: any, index: any) => (
                                          <label
                                            key={index}
                                            className='form-check form-check-custom form-check-solid py-3 w-50'
                                          >
                                            <Field
                                              type='radio'
                                              {...getFieldProps(
                                                'targetLanguage'
                                              )}
                                              name='targetLanguage'
                                              value={option._id}
                                              className={`form-check-input ${
                                                errors.targetLanguage &&
                                                touched.targetLanguage
                                                  ? 'border border-danger'
                                                  : ''
                                              }`}
                                            />
                                            <span className='form-check-label fw-bolder text-dark'>
                                              <TranslateInSpanish
                                                text={option.Language_Name}
                                              />
                                            </span>
                                          </label>
                                        )
                                      )
                                    )}
                                  </div>
                                  {touched.targetLanguage &&
                                    errors.targetLanguage && (
                                      <BForm.Text className='text-danger'>
                                        <TranslateInSpanish
                                          text={errors.targetLanguage}
                                        />
                                      </BForm.Text>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-10 mx-auto'>
                              <div className='stepper-footer d-flex justify-content-end align-items-end'>
                                <Button
                                  variant='primary'
                                  type='submit'
                                  disabled={getAllLanguage?.isLoading}
                                >
                                  Continuar..
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {step === 2 && (
                          <>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-10 mx-auto'>
                                  <div className='text-center w-100 pb-5'>
                                    <h1 className='fw-bolder text-dark'>
                                      ¿Qué idioma HABLAS?
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-10 mx-auto'>
                                  <div className='d-flex align-items-center w-100 py-5 flex-wrap'>
                                    {getAllLanguage?.isLoading ? (
                                      <div className='d-flex justify-content-center'>
                                        <div
                                          className='spinner-border'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      getAllLanguage?.data?.ResultObject?.map(
                                        (option: any, index: any) => (
                                          <label
                                            key={index}
                                            className='form-check form-check-custom form-check-solid py-3 w-50'
                                            htmlFor={option._id}
                                          >
                                            <Field
                                              type='radio'
                                              {...getFieldProps(
                                                'nativeLanguage'
                                              )}
                                              name='nativeLanguage'
                                              value={option._id}
                                              id={option._id}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`form-check-input ${
                                                errors.nativeLanguage &&
                                                touched.nativeLanguage
                                                  ? 'border border-danger'
                                                  : ''
                                              }`}
                                            />
                                            <span className='form-check-label fw-bolder text-dark'>
                                              <TranslateInSpanish
                                                text={option.Language_Name}
                                              />
                                            </span>
                                          </label>
                                        )
                                      )
                                    )}
                                  </div>
                                  {touched.nativeLanguage &&
                                    errors.nativeLanguage && (
                                      <BForm.Text className='text-danger'>
                                        <TranslateInSpanish
                                          text={errors.nativeLanguage}
                                        />
                                      </BForm.Text>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-10 mx-auto'>
                              <div className='stepper-footer d-flex justify-content-between align-items-end'>
                                <Button
                                  variant='primary'
                                  type='button'
                                  onClick={() => setStep(step - 1)}
                                >
                                  Previa
                                </Button>
                                <Button
                                  variant='primary'
                                  type='submit'
                                  disabled={getAllLanguage?.isLoading}
                                >
                                  Continuar..
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {step === 3 && (
                          <>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-10 mx-auto'>
                                  <div className='text-center w-100 pb-5'>
                                    <h1 className='fw-bolder text-dark'>
                                      Nivel de aprendizaje
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-10 mx-auto'>
                                  <Field
                                    as='select'
                                    {...getFieldProps('level')}
                                    name='level'
                                    className={`form-control py-3 level_hover_text ${
                                      errors.level && touched.level
                                        ? 'border-danger'
                                        : ''
                                    }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option key={''} value={''}>
                                      {'Select'}
                                    </option>
                                    {getAllLevel?.isLoading ? (
                                      <div className='d-flex justify-content-center'>
                                        <div
                                          className='spinner-border'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      getAllLevel?.data?.map(
                                        (option: any, index: any) => (
                                          <option
                                            key={index}
                                            value={option._id}
                                          >
                                            {
                                              <TranslateInSpanish
                                                text={option.Level_Name}
                                              />
                                            }
                                          </option>
                                        )
                                      )
                                    )}
                                  </Field>
                                  {levelListFilterDescription && (
                                    <div className='p-2'>
                                      {levelListFilterDescription}
                                    </div>
                                  )}

                                  {touched.level && errors.level && (
                                    <BForm.Text className='text-danger'>
                                      <TranslateInSpanish text={errors.level} />
                                    </BForm.Text>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-10 mx-auto'>
                              <div className='stepper-footer d-flex justify-content-between align-items-end'>
                                <Button
                                  variant='primary'
                                  type='button'
                                  onClick={() => setStep(step - 1)}
                                >
                                  Previa
                                </Button>
                                <Button variant='primary' type='submit'>
                                  Continuar..
                                </Button>
                              </div>
                            </div>
                          </>
                        )}

                        {step === 4 && (
                          <>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-10 mx-auto'>
                                  <div className='text-center w-100 pb-5'>
                                    <h1 className='fw-bolder text-dark'>
                                      ¿En qué año nació el estudiante?
                                    </h1>
                                  </div>
                                </div>
                                <div className='col-10 mx-auto'>
                                  <Field
                                    as='select'
                                    {...getFieldProps('birthYear')}
                                    className={`form-control py-3 level_hover_text ${
                                      errors.birthYear && touched.birthYear
                                        ? 'border-danger'
                                        : ''
                                    }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option key={''} value={''}>
                                      {'Select'}
                                    </option>
                                    {generateBirthYears()?.map(
                                      (val: any, index: any) => (
                                        <option key={index} value={val}>
                                          {val}
                                        </option>
                                      )
                                    )}
                                  </Field>

                                  {touched.birthYear && errors.birthYear && (
                                    <BForm.Text className='text-danger'>
                                      <TranslateInSpanish
                                        text={errors.birthYear}
                                      />
                                    </BForm.Text>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-10 mx-auto'>
                              <div className='stepper-footer d-flex justify-content-between align-items-end'>
                                <Button
                                  variant='primary'
                                  type='button'
                                  onClick={() => setStep(step - 1)}
                                >
                                  Previa
                                </Button>
                                <Button variant='success' type='submit'>
                                  Entregar
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default memo(PreFillProfileInviteModal);
