import { BsFolder } from 'react-icons/bs';
import { RiArrowRightSFill, RiArrowDownSFill } from 'react-icons/ri';
import { getAxiosInstance } from 'helpers/api-helper';
import { useEffect, useState } from 'react';
import { usePostQuery } from 'hooks/reactQueryHelper';

const TeacherTreeView: React.FC<any> = (props: any) => {
  const {
    treeData,
    parentId,
    setExpandedNodes,
    expandedNodes,
    courseID,
    setCourseList,
    courseList,
    courseDataID,
    setFolderDataID,
    folderDataID,
    setSelectedFolderId,
    selectedFolderId,
    onFolderClick,
    setFolderDataList,
    folderParentID,
    getBreadcrumb,
    setBreadcrumbs,
    setDragType,
    dragType,
    setFolderParentID,
    dragFolderID,
    setTreeData,
    setDragFolderID,
    drag,
    UserID,
    getAllFolderAndCourseQuery,
  } = props;

  const updateTreeFolderParentID: any = usePostQuery(
    'updateTreeFolderParentID',
    '/folder/updateFolderParentID',
    {}
  );

  useEffect(() => {
    if (updateTreeFolderParentID.isSuccess) {
      getRecord(selectedFolderId ? selectedFolderId : null);
      setTreeData(updateTreeFolderParentID?.data?.ResultObject?.folders);
    }
  }, [updateTreeFolderParentID.isSuccess]);

  useEffect(() => {
    if (folderParentID != null) {
      if (expandedNodes.includes(folderParentID)) {
        setExpandedNodes(
          expandedNodes.filter((id: any) => id !== folderParentID)
        );
      } else {
        setExpandedNodes([...expandedNodes, folderParentID]);
      }
    }
  }, [folderParentID]);

  const filteredData = treeData.filter(
    (node: any) => node.ParentID === parentId
  );

  const [getIndex, setGetIndex] = useState<any>(null);

  useEffect(() => {
    setGetIndex(getIndex);
  }, [getIndex]);

  const getRecord = async (folderID: any) => {
    getAllFolderAndCourseQuery.mutate({ folderID: folderID, UserID: UserID });
  };

  const handleToggle = async (nodeId: number) => {
    if (expandedNodes.includes(nodeId)) {
      setExpandedNodes(expandedNodes.filter((id: any) => id !== nodeId));
    } else {
      setExpandedNodes([...expandedNodes, nodeId]);
    }
  };

  const handleToggleFolder = async (event: any, nodeId: number, index: any) => {
    setBreadcrumbs([
      { ID: null, FolderName: 'Home' },
      ...getBreadcrumb(nodeId),
    ]);
    setFolderDataID(nodeId);
    getRecord(nodeId);
  };

  const handleDragOver = async (event: any) => {
    event.preventDefault();
  };

  const handleDrop = async (event: any, id: any) => {
    event.preventDefault();
    if (dragType == 'Folder' && id != dragFolderID) {
      const sourceNodeId = event.dataTransfer.getData('nodeId');
      const isParentToChildDrop = await isDescendant(id, sourceNodeId);
      if (isParentToChildDrop) {
        saveFolder(dragFolderID, id);
      } else {
        return isParentToChildDrop;
      }
    }

    if (dragType == 'Course') {
      setGetIndex(null);
      onSaveCourse(id, folderDataID, courseDataID);
    }
  };

  const handleFolderClick = (folderId: string) => {
    onFolderClick(folderId);
  };

  const saveFolder = async (folderID: any, parentID: any) => {
    setFolderParentID(folderID);
    const folderSaveData = {
      parentID: parentID,
      folderID: folderID,
      userID: UserID,
    };

    updateTreeFolderParentID.mutate(folderSaveData);
  };

  const onSaveCourse = (id: any, folderID: any, courseID: any) => {
    const saveValue = {
      CourseID: courseID,
      folderID: id,
    };

    getAxiosInstance()
      .post('/folder/saveFolderCourse', saveValue)
      .then((res) => {
        getRecord(folderID ? folderID : null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDragStarts = (event: any, id: any, type: any) => {
    var x = document.getElementsByClassName('folder-name-list');
    [].forEach.call(x, function (el: any) {
      el.classList.remove('folder-name-list-hover');
    });
    setDragFolderID(id);
    setDragType(type);
  };

  const onDragends = () => {
    var x = document.getElementsByClassName('folder-name-list');
    [].forEach.call(x, function (el: any) {
      el.classList.add('folder-name-list-hover');
    });
    setDragFolderID(null);
    setDragType(null);
  };

  const isDescendant = (parentId: string | null, childId: string): boolean => {
    const parentFolder = treeData.find(
      (folder: any) => folder._id === parentId
    );
    if (!parentFolder) {
      return false;
    }

    if (parentFolder.ParentID === childId) {
      return false;
    }

    if (parentFolder.ParentID === null) {
      return true;
    }
    return isDescendant(parentFolder.ParentID, childId);
  };

  const handleDragEnter = (index: any) => {
    setGetIndex(index);
  };

  const handleDragLeave = (index: any) => {
    setGetIndex(null);
  };

  return (
    <>
      <div className='tree-structure'>
        <div className='tree-structure-ul'>
          {filteredData.map((node: any, index: any) => {
            return (
              <>
                <div className='tree-structure-li'>
                  <h3
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => {
                      handleDrop(e, node._id);
                    }}
                    className={`folder-name-list folder-name-list-hover ${
                      selectedFolderId === node._id
                        ? 'folder-drop-hover'
                        : getIndex === index
                        ? 'folder-drop-hover'
                        : ''
                    }`}
                    onDragEnter={(e) => handleDragEnter(index)}
                    onDragLeave={(e) => handleDragLeave(index)}
                    onMouseUp={(e) => {
                      if (drag) {
                        handleDrop(e, node._id);
                      }
                    }}
                  >
                    <span
                      onClick={() => handleToggle(node._id)}
                      className='pointer-class'
                    >
                      {' '}
                      {expandedNodes.includes(node._id) ? (
                        <RiArrowDownSFill />
                      ) : (
                        <RiArrowRightSFill />
                      )}
                    </span>
                    <span
                      onClick={(event) => {
                        handleToggleFolder(event, node._id, index),
                          handleFolderClick(node._id);
                      }}
                      draggable='true'
                      onDragStart={(e) => {
                        onDragStarts(e, node?._id, 'Folder'),
                          e.dataTransfer.setData('nodeId', node?._id);
                      }}
                      onDragEnd={onDragends}
                      className='pointer-class btn btn-sm btn-light fw-bold text-dark text-start'
                      style={{ width: '100%' }}
                    >
                      <BsFolder size={16} />{' '}
                      <span style={{ paddingLeft: '5px' }}>
                        {node.FolderName}
                      </span>
                    </span>
                  </h3>
                  {expandedNodes.includes(node._id) && (
                    <TeacherTreeView
                      treeData={treeData}
                      parentId={node._id}
                      setExpandedNodes={setExpandedNodes}
                      expandedNodes={expandedNodes}
                      courseID={courseID}
                      setCourseList={setCourseList}
                      courseList={courseList}
                      courseDataID={courseDataID}
                      setFolderDataID={setFolderDataID}
                      folderDataID={folderDataID}
                      setSelectedFolderId={setSelectedFolderId}
                      selectedFolderId={selectedFolderId}
                      onFolderClick={onFolderClick}
                      setFolderDataList={setFolderDataList}
                      getBreadcrumb={getBreadcrumb}
                      setBreadcrumbs={setBreadcrumbs}
                      setDragType={setDragType}
                      dragType={dragType}
                      setTreeData={setTreeData}
                      setFolderParentID={setFolderParentID}
                      dragFolderID={dragFolderID}
                      setDragFolderID={setDragFolderID}
                      drag={drag}
                      getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
                      UserID={UserID}
                    />
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TeacherTreeView;
