import React from 'react';
import { Card } from 'react-bootstrap';
import EditResponseAndSaveButton from '../enrollmenthooks/EditResponseAndSaveButton';

const RegistrationFormHeder = (props: any) => {
  const { step, setStep, formType } = props;
  return (
    <>
      <Card className='text-center mt-5 p-3'>
        <Card.Body>
          <h1>
            Registration/Matrícula {formType == 1 && '(7th-12th)'}{' '}
            {formType == 2 && '(K-6)'}
          </h1>
          {step != 0 && (
            <>
              <div className='fs-5'>
                IMPORTANT! Please complete a registration form for each student
                registering.
              </div>
              <div className='fs-5'>
                ¡IMPORTANTE! Complete una matrícula para cada estudiante que
                matriculando.
              </div>
            </>
          )}
          {step == 0 && (
            <>
              <div className='fs-5'>Thank you for your interest. </div>
              <div className='fs-5'>
                Someone will be contacting you shortly.{' '}
              </div>
              <EditResponseAndSaveButton setStep={setStep} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RegistrationFormHeder;
