import Loader from 'app/components/Loader';
import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';

const UnEnrollmentForm = () => {
  const getStudentDataQuery: any = usePostQuery(
    'getStudentData',
    'user/studentGetById',
    {}
  );
  const unEnrollmentFormQuery: any = usePostQuery(
    'unEnrollmentForm',
    '/enrollment/unenrollform',
    {}
  );

  const navigate = useNavigate();
  const [studentData, setStudentData] = useState<any>('');

  useEffect(() => {
    getStudentDataQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getStudentDataQuery.isSuccess) {
      setStudentData(getStudentDataQuery?.data?.ResultObject);
    }
  }, [getStudentDataQuery.isSuccess]);

  const initialValues = {
    Email: '',
    Student_Firstname: '',
    Student_Lastname: '',
    GuardianFullname: '',
    StudentID: '',
    GuardianSignature: '',
    WhyAppealUnEnrollForm: '',
  };

  useEffect(() => {
    if (studentData) {
      formik.setFieldValue(
        'Email',
        studentData?.UserId?.Email ? studentData?.UserId?.Email : ''
      );
      formik.setFieldValue(
        'Student_Firstname',
        studentData?.UserId?.FirstName ? studentData?.UserId?.FirstName : ''
      );
      formik.setFieldValue(
        'Student_Lastname',
        studentData?.UserId?.LastName ? studentData?.UserId?.LastName : ''
      );
      formik.setFieldValue(
        'StudentID',
        studentData?.Student_Profile_ID ? studentData?.Student_Profile_ID : ''
      );
    }
  }, [studentData]);

  const formvalidationSchema = Yup.object().shape({
    // Email: Yup.string().email('email address is incorrect').required('Please enter email address'),
    // Student_Firstname: Yup.string().required('Please enter first name'),
    // Student_Lastname: Yup.string().required('Please enter last name'),
    // StudentID: Yup.string().required('Please enter student id'),
    GuardianFullname: Yup.string().required('Please enter full name'),
    GuardianSignature: Yup.string().required('Please enter signature'),
    WhyAppealUnEnrollForm: Yup.string().required('Please enter discription'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      unEnrollmentFormQuery.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (unEnrollmentFormQuery.isSuccess) {
      navigate('/account');
    }
  }, [unEnrollmentFormQuery.isSuccess]);

  const onFormInformationReset = () => {
    formik.resetForm();
  };

  const onFormInformationSave = (actionName: any) => {
    formik.setFieldValue('buttonAction', actionName);
  };

  return (
    <>
      <div>
        <Loader
          loading={
            unEnrollmentFormQuery.isLoading || getStudentDataQuery.isLoading
          }
        />
        <div className='card text-center mt-5 p-6'>
          <div className='card-body p-6'>
            <h1>Un-Enrollment Appeal</h1>
          </div>
        </div>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className='card mt-3 mb-3'>
              <div className='card-body p-6'>
                <div className='p-3'>
                  <label className='form-label'>
                    Email
                    {/* <span className='text-danger'> * </span> */}
                  </label>
                  <input
                    type='Email'
                    placeholder='Your Email address'
                    autoComplete='off'
                    {...formik.getFieldProps('Email')}
                    className='form-control'
                    // className={clsx(
                    //     'form-control',
                    //     {
                    //         'is-invalid': formik.touched.Email && formik.errors.Email,
                    //     },
                    //     {
                    //         'is-valid': formik.touched.Email && !formik.errors.Email,
                    //     }
                    // )}
                    disabled={true}
                  />
                  {/* {formik.touched.Email && formik.errors.Email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.Email}</span>
                                            </div>
                                        </div>
                                    )} */}
                </div>
                <div className='p-3'>
                  <label className='form-label'>
                    Guardian First and Last Name{' '}
                    <span className='text-danger'> * </span>
                  </label>
                  <input
                    type='text'
                    placeholder='Your guardian fullname'
                    autoComplete='off'
                    {...formik.getFieldProps('GuardianFullname')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.GuardianFullname &&
                          formik.errors.GuardianFullname,
                      },
                      {
                        'is-valid':
                          formik.touched.GuardianFullname &&
                          !formik.errors.GuardianFullname,
                      }
                    )}
                    disabled={false}
                  />
                  {formik.touched.GuardianFullname &&
                    formik.errors.GuardianFullname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            {formik.errors.GuardianFullname}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='p-3'>
                  <label className='form-label'>
                    Student First Name / Primer Nombre
                    {/* <span className='text-danger'> * </span> */}
                  </label>
                  <input
                    type='text'
                    placeholder='Your first name'
                    autoComplete='off'
                    {...formik.getFieldProps('Student_Firstname')}
                    className='form-control'
                    // className={clsx(
                    //     'form-control',
                    //     {
                    //         'is-invalid': formik.touched.Student_Firstname && formik.errors.Student_Firstname,
                    //     },
                    //     {
                    //         'is-valid': formik.touched.Student_Firstname && !formik.errors.Student_Firstname,
                    //     }
                    // )}
                    disabled={true}
                  />
                  {/* {formik.touched.Student_Firstname && formik.errors.Student_Firstname && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.Student_Firstname}</span>
                                            </div>
                                        </div>
                                    )} */}
                </div>
                <div className='p-3'>
                  <label className='form-label'>
                    {' '}
                    Student Last Name / Apellidos
                    {/* <span className='text-danger'> * </span> */}
                  </label>
                  <input
                    type='text'
                    placeholder='Your last name'
                    autoComplete='off'
                    {...formik.getFieldProps('Student_Lastname')}
                    className='form-control'
                    // className={clsx(
                    //     'form-control',
                    //     {
                    //         'is-invalid': formik.touched.Student_Lastname && formik.errors.Student_Lastname,
                    //     },
                    //     {
                    //         'is-valid': formik.touched.Student_Lastname && !formik.errors.Student_Lastname,
                    //     }
                    // )}
                    disabled={true}
                  />
                  {/* {formik.touched.Student_Lastname && formik.errors.Student_Lastname && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.Student_Lastname}</span>
                                            </div>
                                        </div>
                                    )} */}
                </div>
                <div className='p-3'>
                  <label className='form-label'>
                    Hope City Student ID number: / Número de identificación del
                    estudiante de Hope City:
                    {/* <span className='text-danger'> * </span> */}
                  </label>
                  <input
                    type='text'
                    placeholder='Your student id'
                    autoComplete='off'
                    {...formik.getFieldProps('StudentID')}
                    className='form-control'
                    // className={clsx(
                    //     'form-control',
                    //     {
                    //         'is-invalid': formik.touched.StudentID && formik.errors.StudentID,
                    //     },
                    //     {
                    //         'is-valid': formik.touched.StudentID && !formik.errors.StudentID,
                    //     }
                    // )}
                    disabled={true}
                  />
                  {/* {formik.touched.StudentID && formik.errors.StudentID && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.StudentID}</span>
                                            </div>
                                        </div>
                                    )} */}
                </div>
                <div className='p-3'>
                  <label className='form-label'>
                    Tell us why you would like to appeal our decision to
                    un-enroll your student from Hope City?
                    <span className='text-danger'> * </span>
                  </label>
                  <input
                    type='text'
                    placeholder='Your discription'
                    autoComplete='off'
                    {...formik.getFieldProps('WhyAppealUnEnrollForm')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.WhyAppealUnEnrollForm &&
                          formik.errors.WhyAppealUnEnrollForm,
                      },
                      {
                        'is-valid':
                          formik.touched.WhyAppealUnEnrollForm &&
                          !formik.errors.WhyAppealUnEnrollForm,
                      }
                    )}
                    disabled={false}
                  />
                  {formik.touched.WhyAppealUnEnrollForm &&
                    formik.errors.WhyAppealUnEnrollForm && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            {formik.errors.WhyAppealUnEnrollForm}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='p-3'>
                  <label className='form-label'>
                    Guardian sign<span className='text-danger'> * </span>
                  </label>
                  <p className='form-label'>
                    By typing my name below, I accept this as an electronic
                    signature alternative:
                  </p>
                  <p className='form-label'>
                    Al escribir mi nombre a continuación, acepto esto como una
                    alternativa de firma electrónica:
                  </p>
                  <input
                    type='text'
                    placeholder='Your guardian signature'
                    autoComplete='off'
                    {...formik.getFieldProps('GuardianSignature')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.GuardianSignature &&
                          formik.errors.GuardianSignature,
                      },
                      {
                        'is-valid':
                          formik.touched.GuardianSignature &&
                          !formik.errors.GuardianSignature,
                      }
                    )}
                    disabled={false}
                  />
                  {formik.touched.GuardianSignature &&
                    formik.errors.GuardianSignature && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            {formik.errors.GuardianSignature}
                          </span>
                        </div>
                      </div>
                    )}
                </div>

                {formik.submitCount > 0 &&
                  Object.keys(formik.errors).length > 0 && (
                    <div className='fv-plugins-message-container p-3'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          Please review the form and fixed this highlighted
                          issues
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div>
              <button
                className='float-end btn btn-primary mb-5 mt-5'
                type='submit'
              >
                Submit
              </button>
              <button
                className='float-end btn btn-secondary mb-5 mt-5'
                type='button'
                style={{ marginRight: '10px' }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UnEnrollmentForm;
