import actionTypes from './actionTypes';

const initialState: any = {
  list: {
    loading: false,
    error: null,
    data: null,
  },
  details: { loading: false, error: null, data: null },
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_REQUESTED: {
      return {
        ...state,
        list: {
          loading: true,
          error: null,
          data: null,
        },
      };
    }
    case actionTypes.GET_SUCCEED: {
      return {
        ...state,
        list: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    }
    case actionTypes.GET_SUCCEED: {
      return {
        ...state,
        list: { loading: false, data: null, error: action.payload.error },
      };
    }

    case actionTypes.GET_JOIN_LIVE_CLASS_SCHEDULE_ID_REQUESTED: {
      return {
        ...state,
        details: {
          loading: true,
          error: null,
          data: null,
        },
      };
    }

    case actionTypes.GET_JOIN_LIVE_CLASS_SCHEDULE_ID_SUCCEED: {
      return {
        ...state,
        details: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case actionTypes.GET_JOIN_LIVE_CLASS_SCHEDULE_ID_FAILED: {
      return {
        ...state,
        details: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    }

    case actionTypes.GET_JOIN_REQUESTED: {
      return {
        ...state,
        list: {
          loading: true,
          error: null,
          data: null,
        },
      };
    }
    case actionTypes.GET_JOIN_SUCCEED: {
      return {
        ...state,
        list: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    }
    case actionTypes.GET_JOIN_SUCCEED: {
      return {
        ...state,
        list: { loading: false, data: null, error: action.payload.error },
      };
    }

    case actionTypes.GET_JOIN_LIVE_CLASS_ID_REQUESTED: {
      return {
        ...state,
        details: {
          loading: true,
          error: null,
          data: null,
        },
      };
    }

    case actionTypes.GET_JOIN_LIVE_CLASS_ID_SUCCEED: {
      return {
        ...state,
        details: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case actionTypes.GET_JOIN_LIVE_CLASS_ID_FAILED: {
      return {
        ...state,
        details: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
