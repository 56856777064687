/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ClassRoomFolderList from './ClassRoomFolderList';
import ClassRoomList from './ClassRoomList';
import { useMutation, useQuery } from 'react-query';
import { get, getAxiosInstance } from 'helpers/api-helper';
import { useGetQuery } from 'hooks/reactQueryHelper';

const ClassRoom: React.FC = () => {
  const [drag, setDrag] = useState<any>(false);
  const [dragIndex, setDragIndex] = useState<any>(null);

  const getFolder = useQuery({
    queryFn: () =>
      getAxiosInstance().get(`/folder/getAllFolder`),
  });

  const folderList = getFolder?.data?.data?.ResultObject;
  const [folder, setFolder] = React.useState([]);

  useEffect(() => {
    if (getFolder?.isSuccess) {
      if (getFolder.data.data.ResultCode === 'SUCCESS') {
        setFolder(folderList);
      }
    }
  }, [getFolder.isSuccess, folderList])

  const [classList1, setClassList1] = useState([
    {
      id: 1,
      type: 'class',
      description: {
        title: 'English 120',
        Due: '8 Days, 4 hours',
        Grade: 'B',
        Percentage: '83%',
        PointsNeeded: '39 of 50',
      },
      button: [
        {
          id: 1,
          title: 'Go',
          color: 'primary'
        }
      ]
    },
    {
      id: 2,
      type: 'class',
      description: {
        title: 'Spanish Period 1',
        Due: '8 Days, 4 hours',
        Grade: 'B',
        Percentage: '83%',
        PointsNeeded: '39 of 50',
      },
      button: [
        {
          id: 1,
          title: 'Go',
          color: 'primary'
        }
      ]
    },
    {
      id: 3,
      type: 'class',
      description: {
        title: 'English 101',
        Due: '8 Days, 4 hours',
        Grade: 'B',
        Percentage: '83%',
        PointsNeeded: '39 of 50',
      },
      button: [
        {
          id: 1,
          title: 'View',
          color: 'secondary'
        }
      ]
    },
    {
      id: 4,
      type: 'folder',
      button: [
        {
          id: 1,
          title: 'Live Oak Classes 23',
          color: 'secondary'
        }
      ]
    },
    {
      id: 5,
      type: 'other',
      description: {
        title: 'English 201',
        ClassGPA: 'Students Enrolled',
      },
      button: [
        {
          id: 1,
          title: 'Go',
          color: 'success'
        },
        {
          id: 1,
          title: 'Invite Link',
          color: 'success'
        }
      ]
    },
  ])

  const [classList, setClassList] = useState([])

  return (
    <>
      <Container fluid>
        <Row className='g-5'>
          <Col lg={3}>
            <ClassRoomFolderList getFolder={getFolder} drag={drag} setDrag={setDrag} dragIndex={dragIndex} setDragIndex={setDragIndex} folder={folder} setFolder={setFolder} classList={classList} setClassList={setClassList} />
          </Col>
          <Col lg={9}>
            <ClassRoomList drag={drag} setDrag={setDrag} dragIndex={dragIndex} setDragIndex={setDragIndex} classList={classList} setClassList={setClassList} />
          </Col>
        </Row>
      </Container >
    </>
  );
};
export default ClassRoom;
