import useIntlCustom from 'hooks/useIntlCustom';
import React, { useEffect } from 'react';
import { toAbsoluteUrl } from 'theme/helpers';

const GoogleMeetVocabularyVerifiedFormMessage = () => {
  const { formatMessage } = useIntlCustom();

  return (
    <div className='card h-100'>
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='fw-bold fs-1 p-5 text-center'>
          <img
            src={toAbsoluteUrl('/media/svg/submit-successfully.svg')}
            alt='Edge'
            className='h-150px w-150px'
          />
          <div className='mt-2'>
            {formatMessage({ id: 'VOCABULARY.VERIFIED_FORM_MESSAGE' })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMeetVocabularyVerifiedFormMessage;
