import React from 'react';

const K12_English_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>English</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Learning Literature and language, speaking, reading, listening,
            spelling, handwriting, and composition.
          </li>
          <li>- Modern Literature and Diverse Voices</li>
          <li>- Advanced English Language Usage</li>
          <li>- Essay Writing and Persuasive Techniques</li>
          <li>- Critical Reading and Interpreting Texts</li>
          <li>- Active Listening and Effective Communication</li>
          <li>- Speech and Rhetoric in Everyday Lif</li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. Aquí hay algunos ejemplos de cosas que su escuela
          local puede enseñar para cumplir con el requisito; no las exigimos
          todas.
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Aprender literatura y lenguaje, hablar, leer, escuchar, deletrear,
            escribir a mano y composición.
          </li>
          <li>- Literatura moderna y voces diversas</li>
          <li>- Uso avanzado del idioma inglés</li>
          <li>- Redacción de ensayos y técnicas persuasivas</li>
          <li>- Lectura e interpretación crítica de textos</li>
          <li>- Escucha activa y comunicación efectiva</li>
          <li>- Discurso y retórica en la vida cotidiana</li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, un padre o tutor
          puede enseñarle al estudiante esta materia en casa a través de videos
          de YouTube y material en línea. Desafortunadamente, si no se cumplen
          estos requisitos para esta materia escolar, no podremos inscribir a su
          estudiante en Hope City.
        </div>
      </div>
    </div>
  );
};

export default K12_English_Requirement;
