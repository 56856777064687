import React from 'react';

const HighlightedIssuesText = (props: any) => {
  const { formik } = props;
  return (
    <>
      {formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && (
        <div className='fv-plugins-message-container p-3'>
          <div className='fv-help-block'>
            <h5 className='text-danger'>
              Revise el formulario y solucione los problemas.
            </h5>
          </div>
        </div>
      )}
    </>
  );
};

export default HighlightedIssuesText;
