import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Confetti from 'react-dom-confetti';
import { toast } from 'react-toastify';
import FooterActivityPage from '../activityfooter/FooterActivityPage';
import { Badge } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import successSound from '../../../theme/assets/audio/successSound.mp3';

import { faker } from '@faker-js/faker';

declare global {
  interface Navigator {
    userLanguage: any;
    browserLanguage: any;
    systemLanguage: any;
  }
}

const WriteItActivity: React.FC<any> = (props: any) => {
  const {
    singleLessonData,
    setListenITActivityShow,
    setWriteITActivityShow,
    sectionType,
    setVideoSectionShow,
    setSectionType,
    setResultAnswerCount,
    resultAnswerCount,
    setTapITActivityShow,
    setSayITActivityShow,
    setPrevLastIndexQuestion,
    nativeLang,
    targetLang,
    prevLastIndexQuestion,
    setQuestionResultList,
    questionResultList,
    quizQuestionCount,
    setQuizQuestionCount,
    trueAnswerCount,
    setTrueAnswerCount,
  } = props;

  const [config, setConfig] = useState<any>({
    angle: 90,
    spread: '60',
    startVelocity: '41',
    elementCount: '40',
    dragFriction: '0.13',
    duration: 1000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '414px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  });

  const [writeITCorrectAnswer, setWriteITCorrectAnswer] = useState<any>('');
  const [animationShow, setAnimationShow] = useState(false);
  const [answerCount, setAnswerCount] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [correctAnswerButton, setCorrectAnswerButton] = useState(false);
  const [writeITIndex, setWriteITIndex] = useState(0);
  const [nextButtonShow, setNextButtonShow] = useState(false);
  const [randomChar, setRandomChar] = useState([]);
  const [randomWord, setRandomWord] = useState<any>([]);
  const [randomWordArray, setRandomWordArray] = useState<any>([]);

  const [btnDisable, setBtnDisable] = useState(false);

  var writeITQuestionsLenght: number;
  var writeAns: any;

  const [writeAnsData, setWriteAnsData] = useState(writeAns);

  var data = singleLessonData?.Activity_Details?.filter(
    (x: any) => x.Section == sectionType
  )[0];

  let checkPhone = navigator.userAgent;
  let regexpPhone =
    /android|webOS|BlackBerry|Windows Phone|iPod|iphone|kindle|ipad/i;
  let isMobileDeviceCheck = regexpPhone.test(checkPhone);

  useEffect(() => {
    if (prevLastIndexQuestion) {
      setWriteITIndex(writeITQuestionsLenght);
    }
  }, [prevLastIndexQuestion]);

  useEffect(() => {
    const languages: any = {
      en: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      es: 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZāáǎàēéěèīíǐìōóǒòūúǔùǖǘǚǜ',
      fr: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZàâçéèêëîïôùûüÿ',
      de: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÄÖÜäöüß',
      // add more languages as needed
    };

    const characters = languages[targetLang]
      ? languages[targetLang]
      : languages['en'];

    var lenString = 3;
    var charRandom: any = ' ';

    for (var i = 0; i < lenString; i++) {
      var rnum = Math.floor(Math.random() * characters.length);
      charRandom += characters.substring(rnum, rnum + 1);
    }

    let regex = /[.,\s]/g;
    let writeAnsLower = writeAns?.toLowerCase().trim().replace(regex, '');
    let charRandomLower = charRandom.toLowerCase().trim().replace(regex, '');
    var data = (writeAnsLower + charRandomLower).match(/\S/g).sort(function () {
      return -1 + Math.floor(Math.random() * 3);
    });

    let dataLower = data.filter(
      (item: any, index: any) => data.indexOf(item) === index
    );
    setRandomChar(dataLower);

    if (
      faker.locales[targetLang] &&
      targetLang == 'ja' &&
      targetLang == 'zh-CN' &&
      targetLang == 'ar'
    ) {
      faker.locale = targetLang;
    } else {
      faker.locale = 'en';
    }

    // console.log(faker.word.adjective(), faker.name.firstName())

    let arr = (
      writeAns +
      ' ' +
      faker.name.firstName() +
      ' ' +
      faker.name.firstName()
    ).split(' ');
    let n = arr.length;

    // let dataWord = (writeAns + " " + faker.name.firstName() + " " + faker.name.firstName()).split(" ").sort(function () {
    //   return (-1 + Math.floor((Math.random() * 1)))
    // })

    for (let i = 0; i < n; i++) {
      let newIndex = Math.floor(Math.random() * n);
      let temp = arr[i];
      arr[i] = arr[newIndex];
      arr[newIndex] = temp;
    }
    setRandomWord(arr);
  }, [writeITIndex]);

  const showErrorMessage = () => {
    toast.error('Try Again !', { autoClose: 1000 });
  };

  const showIncorrectMessage = () => {
    toast.error('In Correct !', { autoClose: 1000 });
  };

  useEffect(() => {
    setWriteITCorrectAnswer(writeITCorrectAnswer);
  }, [writeITCorrectAnswer]);

  const handleChangeWriteITQuestionsAnswer = (event: any) => {
    const answerData = event.target.value;
    setWriteITCorrectAnswer(answerData);
  };

  const handleWriteITQuestionsAnswer = async (
    index: any,
    answer: any,
    answerAudio: any
  ) => {
    if (writeITCorrectAnswer == '') {
      return null;
    }

    const answerAudioPlay: any = document.getElementById(
      'pronounceAudioAnswer'
    );

    setAnswerCount(answerCount + 1);
    const questionAnswer = writeITCorrectAnswer
      .toLowerCase()
      .trim()
      .replace('.', '');
    const matchAnswer = answer.toLowerCase().trim().replace('.', '');
    if (questionAnswer === matchAnswer || answerCount == 1) {
      if (answerCount === 1) {
        if (questionAnswer !== matchAnswer) {
          setWriteITCorrectAnswer('');
          if (sectionType == 'ConversationSection') {
            toast.dismiss();
            showIncorrectMessage();

            setAnswerCount(0);
            setTimeout(() => {
              toast.dismiss();
              // setSectionType("GrammarSection")
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setVideoSectionShow(true);
                setWriteITActivityShow(false);
              }
            }, 1000);
          } else if (sectionType == 'GrammarSection') {
            toast.dismiss();
            showIncorrectMessage();
            setTimeout(() => {
              toast.dismiss();
              // setSectionType("QuizSection")
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setVideoSectionShow(true);
                setWriteITActivityShow(false);
              }
            }, 1000);
            setAnimationShow(false);
          } else if (sectionType == 'VocabularySection') {
            toast.dismiss();
            showIncorrectMessage();
            setTimeout(() => {
              toast.dismiss();
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setListenITActivityShow(true);
                setWriteITActivityShow(false);
              }
              setAnimationShow(false);
            }, 1000);
            setAnimationShow(false);
          } else {
            setTimeout(() => {
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setListenITActivityShow(true);
                setWriteITActivityShow(false);
              }
              setAnimationShow(false);
            }, 1000);
          }
        }
      }
      if (questionAnswer === matchAnswer) {
        setCorrectAnswer(false);
        setCorrectAnswerButton(false);
        setWriteITCorrectAnswer('');

        setAnswerCount(0);
        const audioSuccess: any = document.getElementById('audioSuccess');
        if (sectionType != 'QuizSection') {
          toast.dismiss();
          await audioSuccess.play();
          setAnimationShow(true);
          if (sectionType == 'ConversationSection') {
            await answerAudioPlay.play();

            answerAudioPlay.addEventListener('ended', () => {
              toast.dismiss();
              // setSectionType("GrammarSection")
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setVideoSectionShow(true);
                setWriteITActivityShow(false);
              }
              setAnimationShow(false);
            });
          } else if (sectionType == 'GrammarSection') {
            await answerAudioPlay.play();
            answerAudioPlay.addEventListener('ended', () => {
              toast.dismiss();
              // setSectionType("QuizSection")
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setVideoSectionShow(true);
                setWriteITActivityShow(false);
              }
              setAnimationShow(false);
            });
          } else if (sectionType == 'VocabularySection') {
            await answerAudioPlay.play();
            answerAudioPlay.addEventListener('ended', () => {
              toast.dismiss();
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setListenITActivityShow(true);
                setWriteITActivityShow(false);
              }
              setAnimationShow(false);
            });
          } else {
            setTimeout(() => {
              if (index != writeITQuestionsLenght) {
                setWriteITIndex(writeITIndex + 1);
              } else {
                setListenITActivityShow(true);
                setWriteITActivityShow(false);
              }
              setAnimationShow(false);
            }, 1000);
          }
        } else {
          setBtnDisable(true);
          setQuizQuestionCount(
            (quizQuestionCount: any) => quizQuestionCount + 1
          );
          setTimeout(() => {
            const questionList = {
              Question: `Pregunta - ${quizQuestionCount}`,
              Answer: true,
            };
            setQuestionResultList([...questionResultList, questionList]);
          }, 100);
          setResultAnswerCount(resultAnswerCount + 1);
          setTimeout(() => {
            if (index != writeITQuestionsLenght) {
              setWriteITIndex(writeITIndex + 1);
            } else {
              setListenITActivityShow(true);
              setWriteITActivityShow(false);
            }
          }, 1000);
        }
      }
    } else {
      if (sectionType != 'QuizSection') {
        showErrorMessage();
        setNextButtonShow(true);
        setCorrectAnswer(true);
      } else {
        setBtnDisable(true);
        setQuizQuestionCount((quizQuestionCount: any) => quizQuestionCount + 1);
        setTimeout(() => {
          const questionList = {
            Question: `Pregunta - ${quizQuestionCount}`,
            Answer: false,
          };
          setQuestionResultList([...questionResultList, questionList]);
        }, 100);
        setTimeout(() => {
          setListenITActivityShow(true);
          setWriteITActivityShow(false);
        }, 1000);
        setTrueAnswerCount(trueAnswerCount + 1);
      }
    }
  };

  const handleAnswer = () => {
    setCorrectAnswerButton(!correctAnswerButton);
  };

  const onPronounce = async (pronounceAudioId: string) => {
    const pronounceAudio: any = document.getElementById(pronounceAudioId);
    pronounceAudio.currentTime = 0;
    await pronounceAudio.play();
  };

  const onPronounce2 = async (pronounceAudioId: string) => {
    const pronounceAudio: any = document.getElementById(pronounceAudioId);
    pronounceAudio.currentTime = 0;
    await pronounceAudio.play();
  };

  const onPreviousButton = () => {
    setTrueAnswerCount(trueAnswerCount - 1);
    setRandomWordArray([]);
    setCorrectAnswer(false);
    setCorrectAnswerButton(false);
    setWriteITCorrectAnswer('');
    if (writeITIndex != 0) {
      setWriteITIndex(writeITIndex - 1);
      setNextButtonShow(false);
      setAnimationShow(false);
      toast.dismiss();
      setAnswerCount(0);
    } else {
      if (
        sectionType == 'ConversationSection' ||
        sectionType == 'VocabularySection' ||
        sectionType == 'AISection'
      ) {
        // do not change this comment code
        // say it activity hide in mobile and web disply
        // setSayITActivityShow(true);
        if (isMobileDeviceCheck) {
          setWriteITActivityShow(false);
          setTapITActivityShow(true);
        } else {
          setWriteITActivityShow(false);
          setSayITActivityShow(true);
        }

        setSectionType(sectionType);
        setPrevLastIndexQuestion(true);
        toast.dismiss();
      } else {
        setPrevLastIndexQuestion(true);
        setTapITActivityShow(true);
        setWriteITActivityShow(false);
        setSectionType(sectionType);
        toast.dismiss();
      }
    }
  };

  useEffect(() => {
    if (animationShow) {
      setTrueAnswerCount(trueAnswerCount + 1);
    }
  }, [animationShow]);

  const handleVideoNextButton = () => {
    setRandomWordArray([]);
    setCorrectAnswer(false);
    setCorrectAnswerButton(false);
    setWriteITCorrectAnswer('');
    if (writeITQuestionsLenght != writeITIndex) {
      setWriteITIndex(writeITIndex + 1);
      setNextButtonShow(false);
      setAnimationShow(false);
      toast.dismiss();

      setAnswerCount(0);
    } else {
      if (sectionType == 'ConversationSection') {
        setAnswerCount(0);
        // setSectionType("GrammarSection")
        setVideoSectionShow(true);
        setWriteITActivityShow(false);
        toast.dismiss();
      } else if (sectionType == 'GrammarSection') {
        // setSectionType("QuizSection")
        setVideoSectionShow(true);
        setWriteITActivityShow(false);
        setAnimationShow(false);
        toast.dismiss();
      } else {
        setListenITActivityShow(true);
        setWriteITActivityShow(false);
        setAnimationShow(false);
        toast.dismiss();
      }
    }
  };
  const handleSpecialCharacter = (event: any) => {
    setWriteITCorrectAnswer(writeITCorrectAnswer + event);
  };

  useEffect(() => {
    setWriteITCorrectAnswer(writeITCorrectAnswer);
  }, [writeITCorrectAnswer]);

  const handleSpecialWord = (event: any, indexs: any) => {
    setRandomWord(
      randomWord.filter((element: any, index: any) => {
        return index != indexs;
      })
    );

    let arr = [...randomWordArray, event];
    let n = arr.length;

    for (let i = 0; i < n; i++) {
      if (i === 0) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      } else {
        arr[i] = arr[i].toLowerCase();
      }
    }
    setRandomWordArray(arr);
    setWriteITCorrectAnswer(writeITCorrectAnswer + event + ' ');
  };

  const addSpecialWord = (data: any) => {
    let arr = randomWordArray.filter((ele: any) => {
      return ele.toLowerCase() != data;
    });
    let n = arr.length;
    for (let i = 0; i < n; i++) {
      if (i === 0) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      } else {
        arr[i] = arr[i].toLowerCase();
      }
    }
    setRandomWordArray(arr);
    setRandomWord([...randomWord, data]);
    setWriteITCorrectAnswer(
      writeITCorrectAnswer
        .split(' ')
        .filter((el: any) => {
          return el != data;
        })
        .join(' ')
    );
  };

  const handleEnterKey = (
    event: any,
    index: any,
    answer: any,
    answerAudio: any
  ) => {
    if (event.target.value != '') {
      if (event.key === 'Enter') {
        handleWriteITQuestionsAnswer(index, answer, answerAudio);
      }
    }
  };

  return (
    <>
      {data?.Write_IT.Questions.map((key: any, index: any) => {
        writeITQuestionsLenght = data?.Write_IT?.Questions?.length - 1;
        if (index == writeITIndex) {
          let langQuestionData = key?.Question?.Language?.filter(
            (x: any) => x.Language_Code == nativeLang
          )[0];
          let langQuestionDataTarget = key?.Question?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let langAnswerData = key?.Answer?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let questionsAnswer = langQuestionData?.Answer;
          writeAns =
            targetLang == 'ja' || targetLang == 'zh-CN' || targetLang == 'ar'
              ? langAnswerData?.Answer_Roman_Language
              : langAnswerData?.Answer;
          var checkTargetLanguage =
            targetLang == 'ja' || targetLang == 'zh-CN' || targetLang == 'ar'
              ? true
              : false;
          var checkNativeLanguage =
            nativeLang == 'ja' || nativeLang == 'zh-CN' || nativeLang == 'ar'
              ? true
              : false;
          return (
            <div
              className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'
              key={index}
            >
              <audio controls id='audioSuccess' preload='auto' hidden>
                <source src={successSound} type='audio/mp3' />
              </audio>

              {langQuestionData?.Audio_Title && (
                <audio controls id='pronounceAudio' preload='auto' hidden>
                  <source
                    src={langQuestionData?.Audio_Title}
                    type='audio/mp3'
                  />
                </audio>
              )}
              {langQuestionDataTarget?.Audio_Title && (
                <audio
                  controls
                  id='pronounceAudioQuestionTarget'
                  preload='auto'
                  hidden
                >
                  <source src={langQuestionDataTarget?.Audio_Title} />
                </audio>
              )}

              {langAnswerData?.Audio_Answer && (
                <audio controls id='pronounceAudioAnswer' preload='auto' hidden>
                  <source src={langAnswerData?.Audio_Answer} />
                </audio>
              )}

              <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
                <div className='container layout_container'>
                  <div
                    className='row main-component'
                    onKeyDownCapture={(event) =>
                      handleEnterKey(
                        event,
                        index,
                        writeAns,
                        langAnswerData?.Audio_Answer
                      )
                    }
                    tabIndex={0}
                  >
                    <div className='col-12'>
                      <div className='w-100 position-relative d-flex flex-column'>
                        <div className='row d-grid'>
                          <div className='col-12 mx-auto'>
                            <div className='w-100'>
                              <div className='progress-bar mt-3'>
                                <ProgressBar
                                  animated
                                  now={
                                    writeITIndex == 0
                                      ? 60
                                      : writeITIndex
                                      ? 70
                                      : 85
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {writeITIndex == 0 && sectionType != 'AISection' ? (
                            <>
                              <div className='col-10 mx-auto writeit-gap-padding'>
                                <div className='w-100 z-10 text-black'>
                                  <div className='title-box'>
                                    <div className='d-flex text-center justify-content-between align-items-center'>
                                      <div className='volume_logo_label'>
                                        <button
                                          id='Mouse'
                                          value='Mouse'
                                          type='button'
                                          className='bg-white'
                                          onClick={() =>
                                            onPronounce('pronounceAudio')
                                          }
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='25'
                                            height='25'
                                            fill='#000000'
                                            className='bi bi-volume-up'
                                            viewBox='0 0 16 16'
                                          >
                                            <path d='M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
                                            <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z' />
                                            <path d='M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z' />
                                          </svg>
                                        </button>
                                      </div>
                                      <div>
                                        {checkNativeLanguage ? (
                                          <div>
                                            <h3>
                                              {
                                                langQuestionDataTarget?.Title_Roman_Language
                                              }
                                            </h3>
                                            <h4>
                                              {langQuestionDataTarget?.Title}
                                            </h4>
                                          </div>
                                        ) : (
                                          <h1>{langQuestionData?.Title}</h1>
                                        )}
                                      </div>
                                      <div>{langQuestionData?.Type}</div>
                                    </div>
                                    <div>
                                      <div className='text-center'>
                                        {langQuestionData?.Description}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='image-box-center'>
                                    <div className='main_img'>
                                      <img
                                        src={langQuestionData?.Image}
                                        alt='Hope City School'
                                        className='question-img'
                                      />
                                      <div className='Confetti-div'>
                                        <Confetti
                                          active={animationShow}
                                          config={config}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-10 mx-auto show-ans-box'>
                                {/* {correctAnswer && */}
                                {sectionType != 'QuizSection' && (
                                  <div className='text-center'>
                                    <div
                                      className={
                                        correctAnswerButton
                                          ? 'd-none'
                                          : 'show-ans-text-box bg-green'
                                      }
                                      onClick={handleAnswer}
                                    >
                                      SHOW ANSWER
                                    </div>
                                    <div
                                      className={
                                        correctAnswerButton
                                          ? 'show-ans-text-box pointer-events-none bg-grey'
                                          : 'd-none'
                                      }
                                      onClick={handleAnswer}
                                    >
                                      {writeAns}
                                    </div>
                                  </div>
                                )}
                                {/* } */}
                              </div>
                              <div className='col-12 mx-auto'>
                                <div className='w-100 z-10 py-1'>
                                  <div className='input-group writeit-input-box'>
                                    <span className='input-group-text'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='30'
                                        height='30'
                                        fill='currentColor'
                                        className='bi bi-type'
                                        viewBox='0 0 16 16'
                                      >
                                        <path d='m2.244 13.081.943-2.803H6.66l.944 2.803H8.86L5.54 3.75H4.322L1 13.081h1.244zm2.7-7.923L6.34 9.314H3.51l1.4-4.156h.034zm9.146 7.027h.035v.896h1.128V8.125c0-1.51-1.114-2.345-2.646-2.345-1.736 0-2.59.916-2.666 2.174h1.108c.068-.718.595-1.19 1.517-1.19.971 0 1.518.52 1.518 1.464v.731H12.19c-1.647.007-2.522.8-2.522 2.058 0 1.319.957 2.18 2.345 2.18 1.06 0 1.716-.43 2.078-1.011zm-1.763.035c-.752 0-1.456-.397-1.456-1.244 0-.65.424-1.115 1.408-1.115h1.805v.834c0 .896-.752 1.525-1.757 1.525z' />
                                      </svg>
                                    </span>
                                    <input
                                      type='text'
                                      id='input-blink'
                                      name='answer'
                                      value={
                                        writeITCorrectAnswer
                                          .charAt(0)
                                          .toUpperCase() +
                                        writeITCorrectAnswer.slice(1)
                                      }
                                      onChange={
                                        handleChangeWriteITQuestionsAnswer
                                      }
                                      tabIndex={0}
                                      autoFocus
                                      autoComplete='off'
                                      className='cursor-grab form-control'
                                      placeholder='Type The Word'
                                      aria-label="Recipient's username"
                                      aria-describedby='button-addon2'
                                      onKeyUpCapture={(event) =>
                                        handleEnterKey(
                                          event,
                                          index,
                                          writeAns,
                                          langAnswerData?.Audio_Answer
                                        )
                                      }
                                    />
                                    <button
                                      className={
                                        btnDisable
                                          ? 'btn btn-primary disabled'
                                          : 'btn btn-primary'
                                      }
                                      onClick={() =>
                                        handleWriteITQuestionsAnswer(
                                          index,
                                          writeAns,
                                          langAnswerData?.Audio_Answer
                                        )
                                      }
                                      type='button'
                                      id='button-addon2'
                                    >
                                      Submit
                                    </button>
                                  </div>
                                  <div
                                    className='text-center fw-bold'
                                    style={{
                                      paddingTop: '0.8rem',
                                      paddingBottom: '0.4rem',
                                    }}
                                  >
                                    Extra Characters
                                  </div>
                                  <div className='special-caracater-word-btn'>
                                    {randomChar.map((data: any, index: any) => {
                                      return (
                                        <Badge
                                          bg='light'
                                          text='dark'
                                          className='special-caracater-btn'
                                          onClick={(event) =>
                                            handleSpecialCharacter(data)
                                          }
                                          style={{
                                            border: '1px solid grey',
                                            padding: '7px',
                                            margin: '0px 5px 7px 0px',
                                            cursor: 'pointer',
                                            position: 'relative',
                                            marginBottom: '8px',
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: '17px',
                                              fontWeight: 400,
                                            }}
                                          >
                                            {data.toLowerCase()}
                                          </span>
                                        </Badge>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='mx-auto writeit-gap-padding'>
                                <div className='w-100 z-10 text-black'>
                                  <div className='image-box-center'>
                                    <div className='main_img'>
                                      <img
                                        src={langQuestionData?.Image}
                                        alt='Hope City School'
                                        className='question-img'
                                      />
                                      <div className='Confetti-div'>
                                        <Confetti
                                          active={animationShow}
                                          config={config}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='title-box'>
                                    <div className='d-flex py-2'>
                                      <div className='volume_logo_label'>
                                        <button
                                          id='Mouse'
                                          value='Mouse'
                                          type='button'
                                          className='bg-white'
                                          onClick={() =>
                                            onPronounce2(
                                              'pronounceAudioQuestionTarget'
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='25'
                                            height='25'
                                            fill='#000000'
                                            className='bi bi-volume-up'
                                            viewBox='0 0 16 16'
                                          >
                                            <path d='M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
                                            <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z' />
                                            <path d='M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z' />
                                          </svg>
                                        </button>
                                      </div>
                                      <div className='writeit-title-text'>
                                        <h3>{langQuestionData?.Title}</h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className='writeit-input-text'
                                    id='div-focus'
                                  >
                                    {randomWordArray.map(
                                      (data: any, index: any) => {
                                        return (
                                          <>
                                            <Badge
                                              bg='light'
                                              text='dark'
                                              style={{
                                                border: '1px solid grey',
                                                padding: '5px',
                                                marginLeft: '5px',
                                                cursor: 'pointer',
                                                position: 'relative',
                                                marginBottom: '8px',
                                              }}
                                            >
                                              {data}
                                              <span
                                                className='close-icon-badge'
                                                onClick={(event) =>
                                                  addSpecialWord(
                                                    data.toLowerCase()
                                                  )
                                                }
                                              >
                                                <MdClose size={10} />
                                              </span>
                                            </Badge>{' '}
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className='special-caracater-word-btn-box'>
                                    {randomWord &&
                                      randomWord?.map(
                                        (data: any, index: any) => {
                                          return (
                                            <>
                                              <h2>
                                                <Badge
                                                  bg='light'
                                                  text='dark'
                                                  onClick={(event) =>
                                                    handleSpecialWord(
                                                      data.toLowerCase(),
                                                      index
                                                    )
                                                  }
                                                  style={{
                                                    border: '1px solid grey',
                                                    padding: '5px',
                                                    marginLeft: '5px',
                                                    cursor: 'pointer',
                                                  }}
                                                >
                                                  {data.toLowerCase()}
                                                </Badge>{' '}
                                              </h2>
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                  <div className='writeit-submit-btn'>
                                    <button
                                      className={
                                        btnDisable
                                          ? 'btn btn-primary disabled'
                                          : 'btn btn-primary'
                                      }
                                      onClick={() =>
                                        handleWriteITQuestionsAnswer(
                                          index,
                                          writeAns,
                                          langAnswerData?.Audio_Answer
                                        )
                                      }
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <FooterActivityPage
                  nextButton={nextButtonShow}
                  onPreviousButton={onPreviousButton}
                  handleVideoNextButton={handleVideoNextButton}
                  previousButton={sectionType != 'QuizSection' ? true : false}
                  urlHash={key?.Question_ID || undefined}
                  lesson={singleLessonData?._id || undefined}
                />
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default WriteItActivity;
