import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section3 = (props: any) => {
  const { formType, formik, fieldReportedIssue } = props;
  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');

  const GradeCurriculum = [
    {
      Value_English: 'YES, I confirm my student completed this material.',
      Value_Spanish: 'SÍ, confirmo que mi estudiante completó este material.',
    },
    {
      Value_English: "I'm not sure if my student completed this curriculum.",
      Value_Spanish:
        'No estoy seguro si mi estudiante completó este plan de estudios.',
    },
  ];

  const formk12 = [
    {
      ID: 1,
      Value_Spanish: 'Historia de los EE. UU.',
      Value_English: 'USA History',
      URL: 'https://forms.gle/gCx5UsqkVADECF6W8',
    },
    {
      ID: 2,
      Value_Spanish: 'Gobierno de los EE. UU.',
      Value_English: 'USA American Government',
      URL: 'https://forms.gle/vscwtrDLcmqA4nb97',
    },
    {
      ID: 3,
      Value_Spanish: 'Historia de California',
      Value_English: 'California History',
      URL: 'https://forms.gle/JjB6HrY8oTXVnDEd7',
    },
    {
      ID: 4,
      Value_Spanish: 'Gobierno de California',
      Value_English: 'California Government',
      URL: 'https://forms.gle/owzwRaQsTFFhGLjHA',
    },
    {
      ID: 5,
      Value_Spanish: 'Agricultura general',
      Value_English: 'General Agriculture',
      URL: 'https://forms.gle/Xv2SEM3TiGrsQwaK7',
    },
    {
      ID: 6,
      Value_Spanish: 'Educación General sobre Negocios',
      Value_English: 'General Business Education',
      URL: 'https://forms.gle/qQ72NT3xdBsJcJBi6',
    },
    {
      ID: 7,
      Value_Spanish: 'Generalidades sobre Derechos Humanos',
      Value_English: 'Human Rights Overview',
      URL: 'https://forms.gle/7iPsKzTM6NqzQaCe7',
    },
    {
      ID: 8,
      Value_Spanish: 'Educación del Consumidor y del Hogar',
      Value_English: 'Consumer and Housemaking Education',
      URL: 'https://forms.gle/PeGhd1F2MDZr36FUA',
    },
  ];

  const formk6 = [
    {
      ID: 1,
      Value_Spanish: 'Historia de los EE. UU.',
      Value_English: 'USA History',
      URL: 'https://forms.gle/SF5R1j8NyPm6PRt9A',
    },
    {
      ID: 2,
      Value_Spanish: 'Gobierno de los EE. UU.',
      Value_English: 'USA American Government',
      URL: 'https://forms.gle/tXrryp7hsavcgrPz9',
    },
    {
      ID: 3,
      Value_Spanish: 'Historia de California',
      Value_English: 'California History',
      URL: 'https://forms.gle/SWjwmrytyD5VjXQH8',
    },
    {
      ID: 4,
      Value_Spanish: 'Gobierno de California',
      Value_English: 'California Government',
      URL: 'https://forms.gle/N6EhDa2sEN4fFsM58',
    },
  ];

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Card className='mt-5'>
            <h5 className='card-header align-items-center bg-light'>
              {' '}
              {formType == 1 ? 'Senior' : '6th'} Grade Hope City Curriculum -
              Plan de estudios del último año año de secundaria de Hope City
              School
            </h5>
            <Card.Body className='p-6'>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Did your student complete the Hope City{' '}
                  {formType == 1 ? 'Senior' : '6th'} Grade Curriculum that was
                  sent to you in the following subjects:
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ¿Su estudiante completó el plan de{' '}
                  {formType == 1 ? 'estudios del último año' : '6th'} de
                  secundaria de Hope City que se le envió en las siguientes
                  materias:
                </p>

                {formType == 1 &&
                  formk12?.map((data) => {
                    return (
                      <>
                        <ol className='mb-5'>
                          <li className='list-group-item'>
                            <div className='fw-bold'>
                              {data?.ID}. {data?.Value_Spanish}
                            </div>
                            <div>
                              <a href={data?.URL} target='_blank'>
                                {data?.URL}
                              </a>
                            </div>
                          </li>
                        </ol>
                      </>
                    );
                  })}

                {formType == 2 &&
                  formk6?.map((data) => {
                    return (
                      <>
                        <ol className='mb-5'>
                          <li className='list-group-item'>
                            <div className='fw-bold'>
                              {data?.ID}. {data?.Value_Spanish}
                            </div>
                            <div>
                              <a href={data?.URL} target='_blank'>
                                {data?.URL}
                              </a>
                            </div>
                          </li>
                        </ol>
                      </>
                    );
                  })}

                {GradeCurriculum?.map((data) => {
                  return (
                    <>
                      <div className='form-check mt-4'>
                        <RadioButtonComponents
                          formik={formik}
                          fieldName={'Student_Completed_Grade_Curriculum'}
                          dataValue={data.Value_English}
                          onChange={() => {
                            removeHightlight(
                              'Student_Completed_Grade_Curriculum'
                            );
                          }}
                        />
                        <label className='form-label fw-normal'>{`${data.Value_English} / ${data.Value_Spanish}`}</label>
                      </div>
                    </>
                  );
                })}
                <FieldsErrorText
                  touched={formik.touched.Student_Completed_Grade_Curriculum}
                  errors={formik.errors.Student_Completed_Grade_Curriculum}
                />
              </div>
            </Card.Body>
          </Card>
        </form>
      </div>
    </>
  );
};

export default Section3;
