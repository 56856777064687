import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './CompletedLessonCard.module.scss';
import { FormattedLesson } from './Lesson.utils';
import { toAbsoluteUrl } from 'theme/helpers';

const CompletedLessonsCard: React.FC<{
  loading?: boolean;
  lessons?: FormattedLesson[];
  onStart: (id: Lesson['id']) => void;
}> = (props) => {
  const { loading, lessons, onStart } = props;

  if (loading) return null;

  return (
    <Card className={styles.container}>
      <Card.Body>
        <Card.Title as={'h3'}>Completed Lessons</Card.Title>
        <ul className='completed-lesson'>
          {lessons?.map((l) => (
            <li key={l.id}>
              <img
                src={toAbsoluteUrl('/media/svg/circle_green_checkmark.svg')}
                alt='Done'
              />
              <h1>{l.name}</h1>
              <Card.Link href='#' onClick={() => onStart(l.id)}>
                Redo
              </Card.Link>
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default CompletedLessonsCard;
