import jwt_decode from 'jwt-decode';

function jwtTokenDecode(): any {
  const localStorageData: any = localStorage.getItem('kt-auth-react-v');
  const token: string = localStorageData
    ? JSON.parse(localStorageData)?.token
    : null;
  const jwt_decode_token = token ? jwt_decode(token) : null;

  return jwt_decode_token as object;
}

function getJwtToken(): any {
  const localStorageData: any = localStorage.getItem('kt-auth-react-v');
  const token: string = localStorageData
    ? JSON.parse(localStorageData).token
    : null;

  return token;
}

export { getJwtToken, jwtTokenDecode };
