import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { LayoutSplashScreen } from '../../../../theme/layout/core';
import { AuthModel, AuthSubscriptionModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { WithChildren } from '../../../../theme/helpers';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../../store/actions';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  authSubscription: AuthSubscriptionModel | undefined;
  saveAuthSubscription: (auth: AuthSubscriptionModel | undefined) => void;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  authSubscription: authHelper.getAuth(),
  saveAuthSubscription: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<any>(initAuthContextPropsState);

const useAuth: any = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [authSubscription, setAuthSubscription] = useState<
    AuthSubscriptionModel | undefined
  >();
  const [currentUser, setCurrentUser] = useState<any | undefined>();
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [preFilledCompleted, setPreFilledCompleted] = useState<Boolean>(false);
  const [learningStreakDaysUpdated, setLearningStreakDaysUpdated] =
    useState<Boolean>(false);
  const saveAuth = async (auth: AuthModel | undefined) => {
    await setAuth(auth);
    if (auth) {
      await authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const saveAuthSubscription = (
    authSubscription: AuthSubscriptionModel | undefined
  ) => {
    setAuthSubscription(authSubscription);
  };

  const dispatch = useDispatch();
  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    dispatch(logoutUser());
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        authSubscription,
        saveAuthSubscription,
        preFilledCompleted,
        setPreFilledCompleted,
        learningStreakDaysUpdated,
        setLearningStreakDaysUpdated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout } = useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  useEffect(() => {
    if (auth && auth.token) {
      setShowSplashScreen(false);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
