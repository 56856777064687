import React from 'react';
import { toAbsoluteUrl } from 'theme/helpers';
import './fireworks.css';
import useIntlCustom from 'hooks/useIntlCustom';

const GoogleMeetVocabularyVerifiedFormSubmittedMessage = ({ point }: any) => {
  const { formatMessage } = useIntlCustom();

  return (
    <div className='card h-100'>
      <div className='fireworks'>
        <div className='before'></div>
        <div className='after'></div>
      </div>
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='fw-bold fs-1 p-5 text-center'>
          <img
            src={toAbsoluteUrl('/media/svg/congratulation-hat.svg')}
            alt='Edge'
            className='h-150px w-150px'
          />
          <div className='mt-2'>
            {formatMessage({
              id: 'VOCABULARY.CONGRATULATIONS_MESSAGE',
              values: {
                param1: point,
              },
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMeetVocabularyVerifiedFormSubmittedMessage;
