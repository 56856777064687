import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className='d-flex flex-column flex-sm-row align-items-center text-dark order-2 order-md-1 text-center w-100'>
        <div className='text-muted fw-semibold me-1'>
          <Link
            to={'/privacy-policy'}
            className='text-gray-800 text-hover-primary'
          >
            Privacy Policy
          </Link>
        </div>
        <i className='bi bi-dot fs-2'></i>
        <div className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
          <a
            href={location.origin}
            target='_blank'
            className='text-gray-800 text-hover-primary'
          >
            Hope City
          </a>
        </div>

        <i className='bi bi-dot fs-2'></i>
        <div className='text-muted  fw-semibold'>
          Report issues to:{' '}
          <span className='text-gray-800 fw-semibold px-md-1'>
            info@hopecityschool.org
          </span>
        </div>
      </div>
    </>
  );
};

export { Footer };
