import { FC } from 'react';
import TeacherCourse from './Teacher-Course/Teacher-Course';
import { PageTitle } from 'theme/layout/core';
import useIntlCustom from 'hooks/useIntlCustom';

const TeacherWrapper: FC = () => {
  const { formatMessage } = useIntlCustom();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {formatMessage({
          id: 'TEACHERS',
        })}
      </PageTitle>
      <TeacherCourse />
    </>
  );
};

export default TeacherWrapper;
