import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import ReEnrollmentFormFooter from '../ReEnrollmentFormFooter';
import { removeEmptyStringProperties } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import {
  CheckBoxComponents,
  RadioButtonComponents,
} from 'hooks/formikFormHook';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';

const Section2 = (props: any) => {
  const {
    setStep,
    step,
    grade,
    reEnrollmentData,
    formType,
    fieldReportedIssue,
  } = props;

  const saveReEnrollmentSection2Query: any = usePostQuery(
    'saveReEnrollmentSection2Query',
    '/reenrollment/save',
    {}
  );

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');

  const [isCheckGrade, setIsCheckGrade] = useState<Boolean>(false);

  const IsFormK6: Boolean = useMemo(() => {
    return formType == 2;
  }, [formType]);

  useEffect(() => {
    if (reEnrollmentData) {
      formik.setFieldValue(
        'Student_Enrolled_Grade_Local_School',
        reEnrollmentData?.Student_Enrolled_Grade_Local_School?.value
          ? reEnrollmentData?.Student_Enrolled_Grade_Local_School?.value
          : false
      );
      formik.setFieldValue(
        'School_Requirements',
        reEnrollmentData?.School_Requirements?.value
          ? reEnrollmentData?.School_Requirements?.value
          : ''
      );
      formik.setFieldValue(
        'EnrollmentID',
        reEnrollmentData?.EnrollmentID
          ? reEnrollmentData?.EnrollmentID
          : undefined
      );
      formik.setFieldValue(
        'Status',
        reEnrollmentData?.Status ? reEnrollmentData?.Status : 0
      );

      setIsCheckGrade(reEnrollmentData?.Student_Grade == '6th' ? true : false);
      formik.setFieldValue(
        'Complete_6th_Grade',
        reEnrollmentData?.Complete_6th_Grade?.value ? true : false
      );
    }
  }, [reEnrollmentData]);

  const initialValues = {
    Student_Enrolled_Grade_Local_School: false,
    School_Requirements: '',
    ButtonAction: '',
    Status: 0,
    Step: 2,
    Complete_6th_Grade: false,
  };

  const formvalidationSchema = Yup.object().shape({
    // Student_Enrolled_Grade_Local_School:
    //   IsFormK6 && Yup.string().required('Please select any option'),
    Student_Enrolled_Grade_Local_School:
      IsFormK6 && Yup.bool().oneOf([true], 'You must check this box.'),
    School_Requirements: Yup.string().required('Please select any option'),
    Complete_6th_Grade:
      isCheckGrade && Yup.bool().oneOf([true], 'You must check this box.'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const modifiedValues = isCheckGrade
        ? initialValues
        : (({ Complete_6th_Grade, ...rest }) => rest)(initialValues);

      await removeEmptyStringProperties(values);
      saveReEnrollmentSection2Query.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveReEnrollmentSection2Query.isSuccess) {
      setStep(3);
    }
  }, [saveReEnrollmentSection2Query.isSuccess]);

  const enrolled_Grade_Local_School = ['Yes', 'No'];
  const requirements_Local_School = [
    "YES, the student's local school will meet these requirements.",
    'I have questions and need to speak with a Hope City staff member.',
  ];

  const GoogleFormsLinks = [
    {
      label: 'History of USA - Google Forms Links',
      link: 'https://forms.gle/SF5R1j8NyPm6PRt9A',
    },
    {
      label: 'Government of USA - Google Forms Links',
      link: 'https://forms.gle/tXrryp7hsavcgrPz9',
    },
    {
      label: 'History of CA - Google Forms Links',
      link: 'https://forms.gle/SWjwmrytyD5VjXQH8',
    },
    {
      label: 'Government of CA - Google Forms Links',
      link: 'https://forms.gle/N6EhDa2sEN4fFsM58',
    },
  ];

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <>
      <div>
        <Loader loading={saveReEnrollmentSection2Query.isLoading} />
        <form onSubmit={formik.handleSubmit}>
          <div className='card mb-5' ref={tabRef}>
            <h1 className='card-header align-items-center bg-light'>
              {grade != 0 && grade}
              {grade == 0 && 'Kindergarten'} Grade
            </h1>
            <div className='card-body p-6'>
              {formType == 2 && (
                <div
                  className={
                    hasFieldError?.includes(
                      'Student_Enrolled_Grade_Local_School'
                    )
                      ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                      : 'p-3'
                  }
                >
                  <label className='form-label'>
                    My student is enrolled in {grade != 0 && grade}{' '}
                    {grade == 0 && 'Kindergarten'} grade in a local school.{' '}
                    <span className='text-danger'> * </span>
                  </label>
                  <div className='form-check mt-2'>
                    <CheckBoxComponents
                      formik={formik}
                      fieldName='Student_Enrolled_Grade_Local_School'
                      onChange={() => {
                        removeHightlight('Student_Enrolled_Grade_Local_School');
                      }}
                    />
                    <label className='form-label fw-normal'>Yes / Si</label>
                  </div>
                  <FieldsErrorText
                    touched={formik.touched.Student_Enrolled_Grade_Local_School}
                    errors={formik.errors.Student_Enrolled_Grade_Local_School}
                  />
                </div>
              )}
              <div className='p-3'>
                <label className='form-label mb-3'>
                  Will these requirements be met in your local school?{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bolder fs-5 mb-3'>
                  School Requirements:
                </p>

                {formType == 1 && (
                  <>
                    <p className='form-label fw-bolder fs-5'>English:</p>
                    <p className='form-label mb-3'>
                      Literature, language, composition, reading, listening, and
                      speaking.
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Social sciences:
                    </p>
                    <p className='form-label mb-3'>
                      Anthropology, economics, geography, history, political
                      science, psychology, and sociology. (Age/Developmentally
                      appropriate material.)
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Foreign language or languages: (beginning not later than
                      grade 7) understanding, speaking, reading, and writing the
                      particular language.
                    </p>
                    <p className='form-label mt-5'>
                      Requisitos de Idioma o idiomas extranjeros: (comenzando a
                      más tardar en el grado 7) comprender, hablar, leer y
                      escribir el idioma en particular.
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Physical education:
                    </p>
                    <p className='form-label mb-3'>
                      With emphasis given to physical activities that are
                      conducive to health and to vigor of body and mind, as
                      required by Section 51222.
                    </p>

                    <p className='form-label fw-bolder fs-5'>Science:</p>
                    <p className='form-label mb-3'>
                      Including the physical and biological aspects, with
                      emphasis on basic concepts, theories, and processes of
                      scientific investigation and on the place of humans in
                      ecological systems, and with appropriate applications of
                      the interrelation and interdependence of the sciences.
                    </p>

                    <p className='form-label fw-bolder fs-5'>Mathematics:</p>
                    <p className='form-label mb-3'>
                      Including instruction designed to develop mathematical
                      understandings, operational skills, and insight into
                      problem-solving procedures.
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Visual and performing arts:
                    </p>
                    <p className='form-label mb-3'>
                      Dance, music, theater, and visual arts, with emphasis upon
                      development of aesthetic appreciation and the skills of
                      creative expression.
                    </p>

                    <p className='form-label fw-bolder fs-5'>Applied arts: </p>
                    <p className='form-label mb-5'>
                      Consumer and homemaking education, industrial arts,
                      general business education, or general agriculture.
                    </p>
                  </>
                )}

                {formType == 2 && (
                  <>
                    <p className='form-label fw-bolder fs-5'>English:</p>
                    <p className='form-label mb-3'>
                      Literature and language, speaking, reading, listening,
                      spelling, handwriting, and composition.
                    </p>

                    <p className='form-label fw-bolder fs-5'>Mathematics:</p>
                    <p className='form-label mb-3'>
                      Concepts, operational skills, and problem solving.
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Social sciences:{' '}
                    </p>
                    <p className='form-label mb-3'>
                      Anthropology, economics, geography, history, political
                      science, psychology, and sociology, (Age/developmentally
                      appropriate).
                    </p>

                    <p className='form-label fw-bolder fs-5'>Science:</p>
                    <p className='form-label mb-3'>
                      Including the biological and physical aspects, with
                      emphasis on the processes of experimental inquiry and on
                      the place of humans in ecological systems.
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Visual and performing arts:{' '}
                    </p>
                    <p className='form-label mb-3'>
                      Including instruction in the subjects of dance, music,
                      theatre, and visual arts, aimed at the development of
                      aesthetic appreciation and the skills of creative
                      expression.
                    </p>

                    <p className='form-label fw-bolder fs-5'>Health:</p>
                    <p className='form-label mb-3'>
                      Including instruction in the principles and practices of
                      individual, family, and community health.
                    </p>

                    <p className='form-label fw-bolder fs-5'>
                      Physical education:
                    </p>
                    <p className='form-label mb-5'>
                      Emphasis upon the physical activities for the pupils that
                      may be conducive to health and vigor of body and mind, for
                      a total period of time of not less than 200 minutes each
                      10 schooldays, exclusive of recesses and the lunch period.
                    </p>
                  </>
                )}

                {requirements_Local_School.map((data: any) => {
                  return (
                    <div className='form-check mt-2'>
                      <RadioButtonComponents
                        formik={formik}
                        fieldName={'School_Requirements'}
                        dataValue={data}
                        onChange={() => {
                          removeHightlight('School_Requirements');
                        }}
                      />
                      <label className='form-label'>{data}</label>
                    </div>
                  );
                })}
                <FieldsErrorText
                  touched={formik.touched.School_Requirements}
                  errors={formik.errors.School_Requirements}
                />
              </div>

              {isCheckGrade && (
                <>
                  <div
                    className={
                      hasFieldError?.includes('Complete_6th_Grade')
                        ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                        : 'p-3'
                    }
                  >
                    <label className='form-label mb-3'>
                      When a student is in 6th grade with Hope City, they need
                      to complete the following material in order to stay
                      enrolled in our school and graduate from 6th grade with
                      Hope City.
                      <span className='text-danger'> * </span>
                    </label>

                    {GoogleFormsLinks.map((data) => {
                      return (
                        <>
                          <p className='form-label fw-bolder fs-6'>
                            {data.label}
                          </p>
                          <p className='form-label mb-3'>
                            <a href={data.link}>{data.link}</a>
                          </p>
                        </>
                      );
                    })}

                    <p className='form-label mb-3'>
                      Yes, I understand my 6th Grader needs to complete this
                      Hope City Curriculum by July 31st, 2023
                    </p>

                    <div className='form-check mt-2'>
                      <CheckBoxComponents
                        formik={formik}
                        fieldName='Complete_6th_Grade'
                        onChange={() => {
                          removeHightlight('Complete_6th_Grade');
                        }}
                      />
                      <label className='form-label'>YES, I understand</label>
                    </div>

                    <FieldsErrorText
                      touched={formik.touched.Complete_6th_Grade}
                      errors={formik.errors.Complete_6th_Grade}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <ReEnrollmentFormFooter
            isBackButton={true}
            isSubmitButton={false}
            setStep={setStep}
            step={step}
          />
        </form>
      </div>
    </>
  );
};

export default Section2;
