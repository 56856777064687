import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Button, Form as BForm, Modal, Container } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import LoaderNew from 'app/components/LoaderNew';
import { toast } from 'react-toastify';
import useIntlCustom from 'hooks/useIntlCustom';

function EnrolledCourseModal(props: any) {
  const { formatMessage } = useIntlCustom();
  const { show, onHide, callBack } = props;
  const validationSchema = Yup.object({
    languageLevel: Yup.object().nullable().required('Please select.'),
    targetLanguage: Yup.object().nullable().required('Please select.'),
  });

  let initialValues = {
    targetLanguage: null,
    languageLevel: null,
  };
  const getAllLanguage: any = useGetQuery(
    'getLanguage',
    `/language/getAllLanguages`,
    {}
  );
  const getAllLevel: any = useGetQuery('getLevel', `/level/getAll`, {});

  const enrolledCourse: any = usePostQuery(
    'enrolledCourse',
    '/course/enrolledCourseByLevelAndTargetLanguage',
    {}
  );

  useEffect(() => {
    if (show) {
      getAllLanguage.mutate();
      getAllLevel.mutate();
    }

    return () => {
      toast.dismiss();
    };
  }, [show]);

  const _handleSubmit = async (values: any, actions: any) => {
    const formData = {
      languageLevel: values?.languageLevel?.value,
      targetLanguage: values?.targetLanguage?.value,
    };
    enrolledCourse.mutate(formData);
  };

  useEffect(() => {
    if (enrolledCourse.isSuccess) {
      callBack();
      onHide();
    }
  }, [enrolledCourse?.isSuccess]);

  useEffect(() => {
    if (enrolledCourse.isError) {
      toast.warning(
        enrolledCourse?.error?.response?.data?.ResultMessage
          ? enrolledCourse?.error?.response?.data?.ResultMessage
          : 'Something went to wrong.'
      );
    }
  }, [enrolledCourse?.isError]);

  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <LoaderNew
        loading={enrolledCourse?.isLoading}
        isFull={false}
        className='rounded'
      />
      <Modal.Header closeButton className='border-0'>
        <Modal.Title>
          {formatMessage({
            id: 'COURSE.ADD.NEW_COURSE',
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }: any) => {
            return (
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <BForm.Group className='mb-3' controlId='targetLanguage'>
                      <BForm.Label>
                        {formatMessage({
                          id: 'COURSE.ADD.WHAT_LANGUAGE_DO_YOU_WANT_TO_LEARN',
                        })}
                        {'?'}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <Field
                        name='targetLanguage'
                        isMulti={false}
                        component={Select}
                        options={getAllLanguage?.data?.ResultObject?.map(
                          (option: any, index: any) => {
                            return {
                              ...option,
                              value: option._id,
                              label: option.Language_Name,
                            };
                          }
                        )}
                        placeholder='Select an option'
                        isClearable={true}
                        classNamePrefix='select'
                        onChange={(option: any) => {
                          setFieldValue('targetLanguage', option);
                        }}
                        onBlur={handleBlur}
                        value={values.targetLanguage}
                        isLoading={getAllLanguage.isLoading}
                        isDisabled={getAllLanguage.isLoading}
                        styles={{
                          control: (baseStyles: any, state: any) => {
                            return {
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? errors.targetLanguage &&
                                  touched.targetLanguage
                                  ? '#f1416c'
                                  : 'grey'
                                : errors.targetLanguage &&
                                  touched.targetLanguage
                                ? '#f1416c'
                                : '#b5b5c3',
                              boxShadow: '#b5b5c3',
                              '&:hover': {
                                borderColor:
                                  errors.targetLanguage &&
                                  touched.targetLanguage
                                    ? '#f1416c'
                                    : 'none',
                              },
                              '&:focus': {
                                borderColor:
                                  errors.targetLanguage &&
                                  touched.targetLanguage
                                    ? '#f1416c'
                                    : 'none',
                              },
                            };
                          },
                        }}
                      />
                      {errors.targetLanguage && touched.targetLanguage ? (
                        <div className='text-danger'>
                          {errors.targetLanguage}
                        </div>
                      ) : null}
                    </BForm.Group>
                    <BForm.Group className='mb-3' controlId='formCode'>
                      <BForm.Label>
                        {formatMessage({
                          id: 'COURSE.ADD.WHAT_LEVEL',
                        })}
                        {'?'}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <Field
                        name='languageLevel'
                        isMulti={false}
                        component={Select}
                        options={getAllLevel?.data?.map(
                          (option: any, index: any) => {
                            return {
                              ...option,
                              value: option._id,
                              label: option.Level_Name,
                            };
                          }
                        )}
                        placeholder='Select an option'
                        isClearable={true}
                        classNamePrefix='select'
                        onChange={(option: any) => {
                          setFieldValue('languageLevel', option);
                        }}
                        onBlur={handleBlur}
                        isLoading={getAllLevel.isLoading}
                        isDisabled={getAllLevel.isLoading}
                        value={values.languageLevel}
                        styles={{
                          control: (baseStyles: any, state: any) => {
                            return {
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? errors.languageLevel && touched.languageLevel
                                  ? '#f1416c'
                                  : 'grey'
                                : errors.languageLevel && touched.languageLevel
                                ? '#f1416c'
                                : '#b5b5c3',
                              boxShadow: '#b5b5c3',
                              '&:hover': {
                                borderColor:
                                  errors.languageLevel && touched.languageLevel
                                    ? '#f1416c'
                                    : 'none',
                              },
                              '&:focus': {
                                borderColor:
                                  errors.languageLevel && touched.languageLevel
                                    ? '#f1416c'
                                    : 'none',
                              },
                            };
                          },
                        }}
                      />
                      {values?.languageLevel?.value && (
                        <div className='p-2'>
                          {values?.languageLevel?.Level_Description}
                        </div>
                      )}
                      {errors.languageLevel && touched.languageLevel ? (
                        <div className='text-danger'>
                          {errors.languageLevel}
                        </div>
                      ) : null}
                    </BForm.Group>
                    <div className='d-flex justify-content-center'>
                      <Button
                        variant='primary'
                        size='lg'
                        type='submit'
                        disabled={enrolledCourse.isLoading}
                      >
                        {enrolledCourse.isLoading ? (
                          'Loading…'
                        ) : (
                          <>
                            {formatMessage({
                              id: 'GO',
                            })}
                          </>
                        )}
                      </Button>
                    </div>
                  </Modal.Body>
                </Form>
              </Container>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
export default EnrolledCourseModal;
