export const SIGN_IN_METHODS_URL = {
  facebook: 'https://m.me/hopecityeducation?ref=w18205086',
  whatsapp: 'https://wa.me/17607235885?text=START',
  instagram: 'https://ig.me/m/hopecityschool',
} as const;

export const BASE_LAYOUT_CONFIG_KEY = '';
export const THEME_NAME = 'theme';

export const ISSUE_TYPES = {
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  WRONG_ANSWER_PROVIDED_IN_QUESTION: 'WRONG_ANSWER_PROVIDED_IN_QUESTION',
  OTHER: 'OTHER',
};

export const GRADE_COLOR: any = {
  A: 'success',
  B: 'primary',
  C: 'warning',
  D: 'info',
  F: 'danger',
};

export const SKIP_AD_TIMEOUT = 15;
