import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { usePostQuery } from 'hooks/reactQueryHelper';
import useIntlCustom from 'hooks/useIntlCustom';
import { useEffect } from 'react';
import useToaster from 'hooks/useToaster';

export function ResetPassword() {
  const { successToaster, warningToaster } = useToaster();
  const { formatMessage } = useIntlCustom();
  let { token } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Please enter new password')
      .min(8, 'Password must be longer than 8 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        'Must contain one uppercase, one lowercase'
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        'Must contain one special case character'
      )
      .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain one number'),
    confirmNewPassword: Yup.string()
      .required('Please enter confirmation password')
      .when('newPassword', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          "Password and confirm password didn't match"
        ),
      }),
  });

  const resetPasswordQuery: any = usePostQuery(
    'resetPasswordQuery',
    `/reset-password`,
    {
      params: {
        token: token,
      },
    }
  );

  const formik: any = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      try {
        resetPasswordQuery.mutateAsync(
          {
            newPassword: values.newPassword,
          },
          {
            params: {
              token: token,
            },
          }
        );
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  useEffect(() => {
    if (resetPasswordQuery.isSuccess && !!resetPasswordQuery?.data?.ok) {
      successToaster(resetPasswordQuery?.data?.message);
      navigate('/login2');
    }

    if (resetPasswordQuery.isSuccess && !!!resetPasswordQuery?.data?.ok) {
      warningToaster(resetPasswordQuery?.data?.message);
    }
  }, [resetPasswordQuery.isSuccess]);

  // useEffect(() => {
  //   if (resetPasswordQuery.isError) {
  //     warningToaster(resetPasswordQuery?.data?.message);
  //   }
  // }, [resetPasswordQuery.isError]);
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          {formatMessage({
            id: 'AUTH.RESET.PASSWORD',
          })}
          ?
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {formatMessage({
            id: 'AUTH.RESET.PASSWORD.SUB_TITLE',
          })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          {/* Password */}
          {formatMessage({
            id: 'AUTH.RESET.NEW_PASSWORD',
          })}
        </label>
        <input
          type='password'
          placeholder='Enter your new password'
          id='newPassword'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid':
                formik.touched.newPassword && formik.errors.newPassword,
            },
            {
              'is-valid':
                formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          {/* Password */}
          {formatMessage({
            id: 'AUTH.RESET.CONFIRM_NEW_PASSWORD',
          })}
        </label>
        <input
          type='password'
          placeholder='Enter your confirm password'
          id='confirmNewPassword'
          autoComplete='off'
          {...formik.getFieldProps('confirmNewPassword')}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid':
                formik.touched.confirmNewPassword &&
                formik.errors.confirmNewPassword,
            },
            {
              'is-valid':
                formik.touched.confirmNewPassword &&
                !formik.errors.confirmNewPassword,
            }
          )}
        />
        {formik.touched.confirmNewPassword &&
          formik.errors.confirmNewPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmNewPassword}</span>
              </div>
            </div>
          )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-stack flex-row-reverse'>
        <button
          className='btn btn-primary me-2 flex-shrink-0 w-100'
          type='submit'
          disabled={!!resetPasswordQuery.isLoading}
        >
          {!!!resetPasswordQuery.isLoading && (
            <span className='indicator-label'>
              {formatMessage({
                id: 'AUTH.RESET.PASSWORD',
              })}
            </span>
          )}
          {!!resetPasswordQuery.isLoading && (
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          )}
        </button>
      </div>
      <div className='d-flex flex-stack justify-content-center p-2'>
        <Link to='/forgot-password'>
          <Link to='/login2'>
            {formatMessage({
              id: 'AUTH.BACK.LOGIN',
            })}
          </Link>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
