import React from 'react';
import { toAbsoluteUrl } from 'theme/helpers';
import VideoNewLayout from '../VideoNewLayout';
import { useNavigate } from 'react-router-dom';
import useIntlCustom from 'hooks/useIntlCustom';

const VideoAd = () => {
  const { formatMessage } = useIntlCustom();
  const navigate = useNavigate();

  const handleApply = () => {
    navigate(`/account`);
  };

  return (
    <VideoNewLayout
      src={toAbsoluteUrl('/media/video/enrollmentlayoutvideo.mp4')}
      autoPlay={'autoplay'}
      loop
      muted
      playsInline={'playsinline'}
      preload='auto'
      className=' rounded h-100 '
      poster={toAbsoluteUrl('/media/icons/loader1_2.gif')}
      onHandleRightButton={() => handleApply()}
      onHandleRightButtonTitle={formatMessage({
        id: 'APPLY',
      })}
    />
  );
};

export default VideoAd;
