export const removeEmptyStringProperties = async (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === '') {
      delete obj[key];
    }
  }
};

export const extractNumericPart = (ordinal: any) => {
  const numericPart = parseInt(ordinal, 10);
  return isNaN(numericPart) ? ordinal : numericPart;
};

export const getCurrentYear = new Date().getFullYear();
export const getCurrentMonth = new Date().getMonth() + 1;

export const handleDownloadDocuments = (fileUrl: any) => {
  const parts = fileUrl.split('.');
  const extension = parts[parts.length - 1];
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = `documents.${extension}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const subjectRequirementValue = [
  {
    Value: 'Yes',
    Label_English:
      'YES. My local school or parent will be teaching the student this required material and meet all requirements as described above.',
    Label_Spanish:
      'SÍ. Mi escuela local o mis padres le enseñarán al estudiante este material requerido y cumplirán con todos los requisitos descritos anteriormente.',
  },
  {
    Value: 'No',
    Label_English:
      'NO. My local school does not teach this subject or all requirements as described and I need to talk with a staff member to meet these requirements.',
    Label_Spanish:
      'NO. Mi escuela local no enseña esta materia ni todos los requisitos descritos y necesito hablar con un miembro del personal para cumplir con estos requisitos.',
  },
];

export const getMonthNumber = (monthName: String) => {
  const monthNames: any = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames.indexOf(monthName) + 1;
};
