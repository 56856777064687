import { getAxiosInstance } from 'helpers/api-helper';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';

import { toAbsoluteUrl } from 'theme/helpers';
import { PageTitle } from 'theme/layout/core';
import Modal from 'app/components/palette/Modal';

import MessengerConnectPopup from './Modals/MessengerConnectPopup';
import WhatsAppConnectPopup from './Modals/WhatsAppConnectPopup';
import InstagramConnectPopup from './Modals/InstagramConnectPopup';
import Loader from 'app/components/Loader';
import Swal from 'sweetalert2';
import { jwtTokenDecode } from 'hooks/tokenHook';

const socialMedia = {
  facebook: 'fb',
  instagram: 'ig',
  whatsapp: 'wh',
} as const;

const SocialChannel: React.FC = () => {
  const userId = jwtTokenDecode().id;

  const [socialMediaId, setSocialMediaId] = React.useState<any>(null);
  const [socialMediaIdForDisconnect, setSocialMediaIdForDisconnect] =
    React.useState<any>(null);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleClose = () => {
    setSocialMediaId(null);
    setErrorMessage('');
    setSocialMediaIdForDisconnect(null);
  };

  const socialChannelsStatusResult = useQuery('getSocialChannelsStatus', {
    queryFn: () =>
      getAxiosInstance().get(`/user/${userId}/socialChannels/status`),
  });

  const status = socialChannelsStatusResult.data?.data;

  const putMutation = useMutation({
    mutationKey: 'disconnectingSocialMedia',
    mutationFn: (data: any) => {
      return getAxiosInstance().put(
        `/user/${data.userId}/socialChannels/${data.socialMediaId}`,
        {
          id: data?.id,
        }
      );
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data?.error);
      Swal.fire({
        icon: 'error',
        title: error?.response?.data?.error,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    onSuccess: (response: any) => {
      handleClose();
      socialChannelsStatusResult.refetch();
      Swal.fire({
        icon: 'success',
        title: response?.data?.message,
        timer: 2000,
        timerProgressBar: true,
      });
    },
  });

  const handleSubmit = (values: any) => {
    const id =
      values.messengerId || values.phNumber || values.igUsername || null;
    putMutation.mutate({ userId, socialMediaId, id });
  };

  const handleDisconnect = () => {
    putMutation.mutate({
      userId,
      socialMediaId: socialMediaIdForDisconnect,
      id: null,
    });
  };

  const isFbConnected = status?.fb;
  const isIgConnected = status?.ig;
  const isWhConnected = status?.wh;

  const isFbShow = Boolean(socialMediaId === socialMedia.facebook);
  const isIgShow = Boolean(socialMediaId === socialMedia.instagram);
  const isWhShow = Boolean(socialMediaId === socialMedia.whatsapp);

  // const show = socialMediaId !== null;
  const confShow = socialMediaIdForDisconnect !== null;

  const messengerId = socialChannelsStatusResult.data?.data?.fb || '';
  const igUsername = socialChannelsStatusResult.data?.data?.ig || '';
  const phNumber = socialChannelsStatusResult.data?.data?.wh || '';

  return (
    <React.Fragment>
      <Modal
        message='Are you sure you want to disconnect?'
        title='Confirmation?'
        okText='Yes'
        cancelText='No'
        visible={confShow}
        onCancel={handleClose}
        onOk={handleDisconnect}
      />
      <MessengerConnectPopup
        initialValues={{ messengerId }}
        loading={putMutation.isLoading}
        errorMessage={errorMessage}
        visible={isFbShow}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        title='Connect Messenger'
      />
      <InstagramConnectPopup
        initialValues={{ igUsername }}
        loading={putMutation.isLoading}
        errorMessage={errorMessage}
        visible={isIgShow}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        title='Connect Instagram'
      />
      <WhatsAppConnectPopup
        initialValues={{ phNumber }}
        loading={putMutation.isLoading}
        errorMessage={errorMessage}
        visible={isWhShow}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        title='Connect WhatsApp'
      />
      <Loader loading={socialChannelsStatusResult.isLoading} />
      <PageTitle>Cuenta</PageTitle>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Conéctese con las redes sociales</h3>
        </div>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-12 col-md-6 d-flex align-items-center'>
            <div>
              <p className='text-gray-600 fw-semibold fs-5 mb-6 d-block'>
                Please choose how you would like to log in and access your
                student portal?
              </p>
              <div className='d-flex align-items-center flex-wrap align-item-stretch bg-light-primary rounded-2 px-6 py-8'>
                <div className='social_icon flex-grow-1 mt-2 me-9 me-md-0'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    fill='#3e97ff'
                    className='svg-icon-3x svg-icon-danger d-block my-2'
                    viewBox='0 0 16 16'
                  >
                    <path d='M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z' />
                  </svg>
                  <p className='text-primary fw-semibold fs-6 my-2'>
                    With Facebook
                  </p>
                </div>
                <div className='social_connect_button'>
                  <Button
                    type='button'
                    size='sm'
                    className='me-2'
                    onClick={() => setSocialMediaId(socialMedia.facebook)}
                  >
                    {isFbConnected ? 'Change' : 'Connect'}
                  </Button>

                  {isFbConnected && (
                    <Button
                      size='sm'
                      className='me-2'
                      variant='danger'
                      type='button'
                      onClick={() =>
                        setSocialMediaIdForDisconnect(socialMedia.facebook)
                      }
                    >
                      Disconnect
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <img
              src={toAbsoluteUrl('/media/misc/social_media_connect.jpg')}
              className='img-fluid p-10'
              alt=''
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SocialChannel;
