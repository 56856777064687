import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { usePostQuery } from 'hooks/reactQueryHelper';
import FieldsErrorText from '../enrollmenthooks/FieldsErrorText';
import clsx from 'clsx';
import Loader from 'app/components/Loader';

const ResultFooterPage = (props: any) => {
  const { courseInviteId, lessonId } = props;
  const [visible, setVisible] = React.useState(false);

  const onClose = () => setVisible(false);

  return (
    <>
      <div className={'footer-bottom-activity'}>
        <div className={'flag-button-home'}>
          <div
            className='action-btn p-1 pointer-cursor'
            onClick={() => {
              setVisible(true);
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              fill='#ffffff'
              className='bi bi-flag'
              viewBox='0 0 16 16'
            >
              <path d='M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z' />
            </svg>
          </div>
        </div>
      </div>
      <FeedbackModal
        visible={visible}
        onClose={onClose}
        courseInviteId={courseInviteId}
        lessonId={lessonId}
      />
    </>
  );
};

export default ResultFooterPage;

const FeedbackModal: React.FC<any> = ({
  visible,
  onClose,
  courseInviteId,
  lessonId,
}) => {
  const saveFeedbacksQuery: any = usePostQuery(
    'saveFeedbacks',
    '/feedbacks',
    {}
  );

  const initialValues: any = {
    comment: '',
    lessonId: lessonId,
    stars: 0,
    courseInviteId: courseInviteId,
  };

  const formvalidationSchema = Yup.object().shape({
    stars: Yup.number().notRequired(),
    comment: Yup.string().required('Please enter comment'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      saveFeedbacksQuery.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveFeedbacksQuery.isSuccess) {
      const data = saveFeedbacksQuery.data;
      if (data?.ResultCode == 'SUCCESS') {
        formik.resetForm();
        onClose();
        toast.dismiss();
        toast.success(data?.ResultObject, { autoClose: 4000 });
      } else {
        toast.dismiss();
        toast.error('Something went wrong please try later', {
          autoClose: 4000,
        });
      }
    }
  }, [saveFeedbacksQuery.isSuccess]);

  return (
    <>
      <Loader loading={saveFeedbacksQuery.isLoading} />
      <Modal show={visible} onHide={onClose} scrollable centered>
        <Modal.Header closeButton>
          <h5>Report</h5>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Form.Group className='mb-3' controlId='comment'>
              <Form.Label>Feedbacks</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                {...formik.getFieldProps('comment')}
                className={clsx(
                  'form-control',
                  {
                    'is-invalid':
                      formik.touched.comment && formik.errors.comment,
                  },
                  {
                    'is-valid':
                      formik.touched.comment && !formik.errors.comment,
                  }
                )}
              />
              <FieldsErrorText
                touched={formik.touched.comment}
                errors={formik.errors.comment}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              type='submit'
              disabled={saveFeedbacksQuery.isLoading}
            >
              Feedback
            </Button>
            <Button variant='secondary' onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
