import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import image from '../../../../../theme/images/imageexample.jpeg';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import EnrollmentFormImageList from 'app/components/EnrollmentFormImageList';
import useIntlCustom from 'hooks/useIntlCustom';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import {
  CheckBoxComponents,
  RadioButtonComponents,
  TextFieldComponents,
} from 'hooks/formikFormHook';

const Section7_12_1 = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const { formik, hasFieldError, removeHightlight } = props;

  const headers = { 'Content-Type': 'multipart/form-data' };
  const multipleDocumentQueryQuery: any = usePostQuery(
    'multipleDocumentQuery',
    '/upload/add/multipleDocument',
    { headers }
  );

  const getLevelRegisterQuery: any = useGetQuery(
    'getLevelRegister',
    '/level/getAll',
    {}
  );

  const [imageErrorText, setImageErrorText] = useState<any>('');
  const [imageError, setImageError] = useState<any>(false);
  const [level, setLevel] = useState<any>([]);

  const California_Requiered_Option = [
    { Value_English: 'Yes', Value_Spanish: 'Si' },
    {
      Value_English: 'I need more information',
      Value_Spanish: 'Necesito más información',
    },
  ];

  useEffect(() => {
    getLevelRegisterQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getLevelRegisterQuery.isSuccess) {
      setLevel(getLevelRegisterQuery?.data);
    }
  }, [getLevelRegisterQuery.isSuccess]);

  const onUpload = (event: any) => {
    const files = event.currentTarget.files;
    const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files?.length > 0) {
      const formData = new FormData();
      for (const file of files) {
        if (supportedFormats.includes(file.type)) {
          formData.append('files', file);
        } else {
          setImageError(true);
          setImageErrorText('Only JPEG, JPG, and PNG images are allowed');
        }
      }
      multipleDocumentQueryQuery.mutateAsync(formData);
    } else {
      setImageError(true);
      setImageErrorText('Please upload a student photo');
      formik.setFieldValue('Student_CoStudent_Photomplete_Grade_Document', '');
    }
  };

  useEffect(() => {
    if (multipleDocumentQueryQuery.isSuccess) {
      setImageError(false);

      formik.setFieldValue('Student_Photo', [
        ...formik.values.Student_Photo,
        ...multipleDocumentQueryQuery?.data,
      ]);
      setImageErrorText('');
    }
  }, [multipleDocumentQueryQuery.isSuccess]);

  const deleteImageItem = (index: number) => {
    const newArray = [...formik.values.Student_Photo];
    newArray.splice(index, 1);
    formik.setFieldValue('Student_Photo', [...newArray]);
  };
  return (
    <>
      <Card className='mt-5'>
        <Card.Body className='p-6'>
          <div className='p-3'>
            <label className='form-label fw-bold'>
              Email <span className='text-danger'> * </span>
            </label>
            <TextFieldComponents
              type='email'
              formik={formik}
              fieldName='Email'
              placeholder='Your email address'
              disabled={true}
            />
          </div>
          <div className='p-3'>
            <label className='form-label fw-bold'>
              Student First Name / Primer Nombre
              <span className='text-danger'> * </span>
            </label>
            <TextFieldComponents
              formik={formik}
              fieldName='Student_Firstname'
              placeholder='Your first name'
              disabled={true}
            />
          </div>
          <div className='p-3'>
            <label className='form-label fw-bold'>
              Student Last Name / Apellidos{' '}
              <span className='text-danger'> * </span>
            </label>
            <TextFieldComponents
              formik={formik}
              fieldName='Student_Lastname'
              placeholder='Your last name'
              disabled={true}
            />
          </div>
          <div className='p-3'>
            <label className='form-label fw-bold'>
              Hope City Student ID number: / Número de identificación del
              estudiante de Hope City: <span className='text-danger'> * </span>
            </label>
            <p className='form-label fw-normal fw-bold'>
              Please enter Hope City Student ID number that was given to student
              in acceptance letter.
            </p>
            <TextFieldComponents
              formik={formik}
              fieldName='Student_ID_Number'
              placeholder='Your student id number'
              disabled={true}
            />
          </div>
          <div
            className={
              hasFieldError?.includes('Guardian_Firstname')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <label className='form-label fw-bold'>
              Guardian First Name / Nombre del tutor
              <span className='text-danger'> * </span>
            </label>
            <TextFieldComponents
              formik={formik}
              fieldName='Guardian_Firstname'
              placeholder='Guardian first name'
              onChange={() => {
                removeHightlight('Guardian_Firstname');
              }}
              disabled={true}
            />
            <FieldsErrorText
              touched={formik.touched.Guardian_Firstname}
              errors={formik.errors.Guardian_Firstname}
            />
          </div>
          <div
            className={
              hasFieldError?.includes('Guardian_Lastname')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <label className='form-label fw-bold'>
              Guardian Last Name / Apellido del tutor
              <span className='text-danger'> * </span>
            </label>
            <TextFieldComponents
              formik={formik}
              fieldName='Guardian_Lastname'
              placeholder='Guardian Last name'
              onChange={() => {
                removeHightlight('Guardian_Lastname');
              }}
              disabled={true}
            />
            <FieldsErrorText
              touched={formik.touched.Guardian_Lastname}
              errors={formik.errors.Guardian_Lastname}
            />
          </div>
          <div
            className={
              hasFieldError?.includes('Legal_Rights_Of_Student')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <label className='form-label fw-bold'>
              Are there any other individuals that would have legal rights to
              the students records? (Another parent/legal guardian) If so,
              please list their full name, relationship and contact information.{' '}
              <span className='text-danger'> * </span>
            </label>

            <label className='form-label fw-bold'>
              ¿Hay otras personas que tendrían derechos legales sobre los
              registros de los estudiantes? (Otro padre/tutor legal) Si es así,
              indique su nombre completo, relación e información de contacto.
            </label>
            <TextFieldComponents
              formik={formik}
              fieldName='Legal_Rights_Of_Student'
              placeholder='Your answer'
              onChange={() => {
                removeHightlight('Legal_Rights_Of_Student');
              }}
            />
            <FieldsErrorText
              touched={formik.touched.Legal_Rights_Of_Student}
              errors={formik.errors.Legal_Rights_Of_Student}
            />
          </div>
          <div
            className={
              hasFieldError?.includes('I_Agree')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <label className='form-label fw-bold'>
              Your student must make a 60 or higher in classes to receive credit
              at Hope City. If your student does not achieve a 60 or higher,
              they could be suspended from Hope City and be dropped and not
              receive credit for their school year with Hope City. Do you
              understand and agree to this?
            </label>

            <label className='form-label fw-bold'>
              Su estudiante debe obtener un 60 o más en las clases para recibir
              crédito en Hope City. Si su estudiante no logra un 60 o más,
              podría ser suspendido de Hope City. ¿Entiendes y estás de acuerdo
              con esto?
            </label>

            <div className='form-check mt-2'>
              <CheckBoxComponents
                formik={formik}
                fieldName='I_Agree'
                onChange={() => {
                  removeHightlight('I_Agree');
                }}
              />
              <label className='form-label fw-normal'>Yes / Si</label>
            </div>

            <FieldsErrorText
              touched={formik.touched.I_Agree}
              errors={formik.errors.I_Agree}
            />
          </div>

          <div
            className={
              hasFieldError?.includes('Student_Photo')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <label className='form-label fw-bold'>
              Student Photo (The photo should show the student's full face, have
              a solid background color without shadows, avoid wear tank tops
              shirts and NOT include any other people in the photo. Picture must
              be less than 10MB) <span className='text-danger'> * </span>
            </label>

            <label className='form-label fw-bold'>
              Foto del estudiante{' '}
              <b>
                (La foto debe mostrar la cara completa del estudiante, tener un
                color de fondo sólido sin sombras, evitar el uso de camisolas y
                NO incluir a ninguna otra persona en la foto.
              </b>
              La foto debe pesar menos de 10MB)
            </label>
            <div className='p-5'>
              <img
                src={image}
                alt='image'
                style={{
                  display: 'block',
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>

            <EnrollmentFormImageList
              imageItems={formik?.values?.Student_Photo}
              deleteImageItem={deleteImageItem}
            />

            <div className='position-relative d-flex align-items-center mt-2'>
              <label
                htmlFor='hiddenBtn'
                className='btn btn-primary btn-sm'
                id='chooseBtn'
                aria-disabled
              >
                {multipleDocumentQueryQuery.isLoading ? (
                  <>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>{' '}
                    {formatMessage({
                      id: 'UPLOADING',
                    })}
                    ...
                  </>
                ) : (
                  formatMessage({
                    id: 'ADD',
                  })
                )}
              </label>
              <input
                disabled={multipleDocumentQueryQuery.isLoading}
                id='hiddenBtn'
                hidden
                type='file'
                autoComplete='off'
                multiple={true}
                accept='.jpg, .jpeg, .png'
                onChange={(event: any) => {
                  onUpload(event);
                  removeHightlight('Student_Photo');
                }}
              />
            </div>

            {!multipleDocumentQueryQuery.isLoading &&
              formik.touched.Student_Photo &&
              formik.errors.Student_Photo && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {!imageErrorText && (
                      <span role='alert'>{formik.errors.Student_Photo}</span>
                    )}
                  </div>
                </div>
              )}

            {!multipleDocumentQueryQuery.isLoading && imageError && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{imageErrorText}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className={
              hasFieldError?.includes('Schooldays_In_The_School_Year')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <label className='form-label fw-bold'>
              California requires that students complete a minimum of 175 school
              days. Will your student complete 175 or more school days in the
              school year? <span className='text-danger'> * </span>
            </label>

            <label className='form-label fw-bold'>
              California requiere que los estudiantes completen un mínimo de 175
              días escolares. ¿Su estudiante completará 175 o más días escolares
              en el año escolar?
            </label>
            <div>
              {California_Requiered_Option?.map((data: any, index: any) => {
                return (
                  <>
                    <div className='form-check mt-2'>
                      <RadioButtonComponents
                        formik={formik}
                        fieldName={'Schooldays_In_The_School_Year'}
                        dataValue={data?.Value_English}
                        onChange={() => {
                          removeHightlight('Schooldays_In_The_School_Year');
                        }}
                      />
                      <label className='form-label fw-normal'>
                        {data?.Value_English} / {data?.Value_Spanish}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>
            <FieldsErrorText
              touched={formik.touched.Schooldays_In_The_School_Year}
              errors={formik.errors.Schooldays_In_The_School_Year}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Section7_12_1;
