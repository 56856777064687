import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import Terms from 'app/pages/Terms';
import AuthenticationUrl from 'app/pages/AuthenticationUrl';
import Invitation from 'app/pages/teacher/Invitation';
import ShortUrl from 'app/pages/ShortUrl';
import GoogleMeetEarnPointWrapper from 'app/pages/googleMeet/GoogleMeetEarnPointWrapper';
import { MasterLayout } from 'theme/layout/MasterLayout';
import PrivacyPolicy from 'app/pages/privacy-policy/PrivacyPolicy';
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { auth, logout } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='logout' element={<Logout />} />
          <Route path='terms' element={<Terms />} />
          <Route
            path='course/:courseInviteId/invite'
            element={<Invitation />}
          />
          <Route
            path='authentication/:page/:token'
            element={<AuthenticationUrl />}
          />
          <Route
            path='authentication/:page/:token/:id'
            element={<AuthenticationUrl />}
          />

          <Route element={<MasterLayout />}>
            <Route
              path='google-meet-earn-point/:scheduleGoogleMeetId'
              element={<GoogleMeetEarnPointWrapper />}
            />
          </Route>

          <Route path='short/:shortenId' element={<ShortUrl />} />

          {auth ? (
            <>
              <Route path='/diploma' element={<Navigate to='/account' />} />
              <Route index element={<Navigate to='/account' />} />
            </>
          ) : (
            <>
              {/* <Route path='/signup?isDiploma=true' element={<Registration />} /> */}
              <Route
                path='/diploma'
                element={<Navigate to='/login?isDiploma=true' />}
              />
            </>
          )}

          {auth ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to='/dashboard' />} /> */}
              <Route index element={<Navigate to='/course' />} />
            </>
          ) : (
            <>
              <Route path='*' element={<AuthPage />} />
            </>
          )}
          {auth ? (
            <>
              <Route path='login' element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to='/dashboard' />} /> */}
              <Route index element={<Navigate to='/course' />} />
            </>
          ) : (
            <>
              <Route path='/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </>
          )}
          {auth ? (
            <>
              <Route path='login2/*' element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to='/dashboard' />} /> */}
              <Route index element={<Navigate to='/course' />} />
            </>
          ) : (
            <>
              <Route path='/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export { AppRoutes };
