import {
    AI_ACTIVITY_IMAGE_DETAILS_FAILURE,
    AI_ACTIVITY_IMAGE_DETAILS_GET,
    AI_ACTIVITY_IMAGE_DETAILS_GET_SUCCESS,
    AI_ACTIVITY_IMAGE_DETAILS_SAVE,
    AI_ACTIVITY_IMAGE_DETAILS_SAVE_SUCCESS
} from "./actionTypes";

export const aiActivityImageGet = (payload: any) => {
    return {
        type: AI_ACTIVITY_IMAGE_DETAILS_GET,
        payload
    }
}

export const aiActivityImageGetSuccess = (payload: any) => {
    return {
        type: AI_ACTIVITY_IMAGE_DETAILS_GET_SUCCESS,
        payload,
    }
}


export const aiActivityImageSave = (payload: any) => {
    return {
        type: AI_ACTIVITY_IMAGE_DETAILS_SAVE,
        payload,
    }
};

export const aiActivityImageSaveSuccess = (payload: any) => {
    return {
        type: AI_ACTIVITY_IMAGE_DETAILS_SAVE_SUCCESS,
        payload,
    }
};

export const aiActivityImageFailure = (payload: any) => ({
    type: AI_ACTIVITY_IMAGE_DETAILS_FAILURE,
    payload,
});
