import Loader from 'app/components/Loader';
import { useAuth } from 'app/modules/auth';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { getJwtToken, jwtTokenDecode } from 'hooks/tokenHook';
import useIntlCustom from 'hooks/useIntlCustom';
import { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

const Invitation = () => {
  const { setCurrentUser, currentUser } = useAuth();
  const { formatMessage } = useIntlCustom();
  const { courseInviteId } = useParams();
  const navigate = useNavigate();
  const getCourseQuery: any = useGetQuery('getCourse', '/course/getById');
  const addInviteQuery: any = usePostQuery('addInvite', '/course/invite', {});

  useEffect(() => {
    getCourseQuery.mutate({ params: { id: courseInviteId } });
  }, []);

  const onAddInvite = () => {
    if (
      getJwtToken() &&
      getCourseQuery?.data?.ResultObject?.createdBy?._id !==
        jwtTokenDecode()?.id
    ) {
      let formData = {
        courseInviteId: courseInviteId,
      };
      addInviteQuery.mutate(formData);
    } else {
      navigate({
        pathname: '/login',
        search: `?courseInviteId=${courseInviteId}`,
      });
    }
  };

  useEffect(() => {
    if (addInviteQuery.isSuccess) {
      let currentUserData = {
        ...currentUser,
        isDefaultCourseAssign: true,
      };
      setCurrentUser(currentUserData);
      navigate('/course');
    }
  }, [addInviteQuery.isSuccess]);

  return (
    <>
      {(addInviteQuery?.isLoading || getCourseQuery?.isLoading) && (
        <Loader
          loading={addInviteQuery?.isLoading || getCourseQuery?.isLoading}
        />
      )}
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <div className='mb-5'>
                <a href={`${location.origin}`}>
                  <img
                    alt='Logo'
                    src='/media/logos/hope_city_school_logo.png'
                    className='h-100px'
                  />
                </a>
              </div>

              {
                <>
                  <h1 className='fw-bold text-gray-900 mb-5'>
                    {getCourseQuery?.data?.ResultObject?.name}
                  </h1>
                  <h1 className='text-muted mb-5'>
                    {getCourseQuery?.data?.ResultObject?.createdBy?.FirstName}
                  </h1>
                  <h2 className='text-gray-900 mb-5'>
                    {formatMessage({
                      id: 'TEACHER.COURSE.INVITE.TITLE',
                    })}
                    {/* To join the course, please accept the invitation provided
                    below: */}
                  </h2>
                  <div className='mb-11 d-flex justify-content-around'>
                    <button
                      className='btn btn-primary'
                      disabled={
                        getCourseQuery?.data?.ResultObject?.createdBy?._id ===
                        jwtTokenDecode()?.id
                      }
                      onClick={() => navigate('/course')}
                    >
                      {formatMessage({
                        id: 'DECLINE',
                      })}
                      {/* Decline */}
                    </button>
                    <button
                      className='btn btn-success'
                      disabled={
                        getCourseQuery?.data?.ResultObject?.createdBy?._id ===
                        jwtTokenDecode()?.id
                      }
                      onClick={onAddInvite}
                    >
                      {formatMessage({
                        id: 'ACCEPT',
                      })}
                      {/* Accept */}
                    </button>
                  </div>
                </>
              }

              {addInviteQuery?.isError && (
                <Alert variant='warning'>
                  <Alert.Heading>
                    {formatMessage({
                      id: 'TEACHER.COURSE.INVITE.WARNING.MESSAGE',
                    })}
                    {/* Oh snap! You got an error! */}
                  </Alert.Heading>
                  <p>{addInviteQuery?.error?.response?.data?.ResultMessage}</p>
                </Alert>
              )}

              {addInviteQuery?.isSuccess && (
                <Alert variant='success'>
                  <Alert.Heading>
                    {formatMessage({
                      id: 'TEACHER.COURSE.INVITE.SUCCESS.TITLE',
                    })}
                    {/* Invitation accepted! */}
                  </Alert.Heading>
                  <p>
                    {/* Your invitation link is accepted */}
                    {formatMessage({
                      id: 'TEACHER.COURSE.INVITE.SUCCESS.MESSAGE',
                    })}
                  </p>
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invitation;
