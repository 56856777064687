import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderContent: React.FC<{
  loading?: boolean;
  className?: string;
  loaderType?: string;
}> = (props: any) => {
  const { loading = false, className = '', loaderType } = props;
  let numberLines;

  switch (props.screen) {
    case 'mobile': {
      numberLines = 2;
      break;
    }
    case 'desktop': {
      numberLines = 3;
      break;
    }
    case 'large-screen': {
      numberLines = 4;
      break;
    }
    default: {
      numberLines = 5;
      break;
    }
  }

  return (
    <>
      {loading && loaderType === 'imageSection' ? (
        <>
          <div className='row'>
            <div className='col-12 p-10'>
              <ContentLoader
                viewBox='0 0 500 500'
                height={375}
                width={'100%'}
                backgroundColor='#f3e7e7'
                foregroundColor='#c7cdea'
                {...props}
              >
                <path d='M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z' />
                <ellipse cx='120' cy='140' rx='28' ry='28' />
                <rect
                  x='0.53'
                  y='358.52'
                  width='282.21'
                  height='16.48'
                  rx='4.5'
                />
              </ContentLoader>
            </div>
          </div>
        </>
      ) : loading && loaderType === 'videoSection' ? (
        <>
          <div className='row'>
            <div className='col-12 p-10'>
              <ContentLoader
                height={350}
                width={'100%'}
                backgroundColor='#f3e7e7'
                foregroundColor='#c7cdea'
                {...props}
              >
                <rect x='0' y='98' rx='24' ry='24' width='100%' height='230' />
                <rect x='0' y='0.5' width='87.36' height='16.48' rx='4.5' />
                <rect x='0' y='25.22' width='164.67' height='27.07' rx='3.83' />
              </ContentLoader>
            </div>
          </div>
        </>
      ) : loading && loaderType === 'twoSection' ? (
        <>
          <div className='row'>
            <div className='col-12 p-10'>
              <ContentLoader
                height={375}
                width={'100%'}
                backgroundColor='#f3e7e7'
                foregroundColor='#c7cdea'
                {...props}
              >
                <rect x='15' y='15' rx='4' ry='4' width='100%' height='25' />
                <rect x='15' y='50' rx='2' ry='2' width='100%' height='150' />
                <rect x='15' y='230' rx='2' ry='2' width='100%' height='20' />
                <rect x='60' y='230' rx='2' ry='2' width='100%' height='20' />
              </ContentLoader>
            </div>
          </div>
        </>
      ) : loading && loaderType === 'tableSection' ? (
        <>
          <div className='row'>
            <div className='col-12 p-10'>
              <ContentLoader
                width={'100%'}
                height={550}
                backgroundColor='#f3e7e7'
                foregroundColor='#c7cdea'
                {...props}
              >
                <rect x='51' y='45' rx='3' ry='3' width='906' height='17' />
                <circle cx='879' cy='123' r='11' />
                <circle cx='914' cy='123' r='11' />
                <rect x='104' y='115' rx='3' ry='3' width='141' height='15' />
                <rect x='305' y='114' rx='3' ry='3' width='299' height='15' />
                <rect x='661' y='114' rx='3' ry='3' width='141' height='15' />
                <rect x='55' y='155' rx='3' ry='3' width='897' height='2' />
                <circle cx='880' cy='184' r='11' />
                <circle cx='915' cy='184' r='11' />
                <rect x='105' y='176' rx='3' ry='3' width='141' height='15' />
                <rect x='306' y='175' rx='3' ry='3' width='299' height='15' />
                <rect x='662' y='175' rx='3' ry='3' width='141' height='15' />
                <rect x='56' y='216' rx='3' ry='3' width='897' height='2' />
                <circle cx='881' cy='242' r='11' />
                <circle cx='916' cy='242' r='11' />
                <rect x='106' y='234' rx='3' ry='3' width='141' height='15' />
                <rect x='307' y='233' rx='3' ry='3' width='299' height='15' />
                <rect x='663' y='233' rx='3' ry='3' width='141' height='15' />
                <rect x='57' y='274' rx='3' ry='3' width='897' height='2' />
                <circle cx='882' cy='303' r='11' />
                <circle cx='917' cy='303' r='11' />
                <rect x='107' y='295' rx='3' ry='3' width='141' height='15' />
                <rect x='308' y='294' rx='3' ry='3' width='299' height='15' />
                <rect x='664' y='294' rx='3' ry='3' width='141' height='15' />
                <rect x='58' y='335' rx='3' ry='3' width='897' height='2' />
                <circle cx='881' cy='363' r='11' />
                <circle cx='916' cy='363' r='11' />
                <rect x='106' y='355' rx='3' ry='3' width='141' height='15' />
                <rect x='307' y='354' rx='3' ry='3' width='299' height='15' />
                <rect x='663' y='354' rx='3' ry='3' width='141' height='15' />
                <rect x='57' y='395' rx='3' ry='3' width='897' height='2' />
                <circle cx='882' cy='424' r='11' />
                <circle cx='917' cy='424' r='11' />
                <rect x='107' y='416' rx='3' ry='3' width='141' height='15' />
                <rect x='308' y='415' rx='3' ry='3' width='299' height='15' />
                <rect x='664' y='415' rx='3' ry='3' width='141' height='15' />
                <rect x='55' y='453' rx='3' ry='3' width='897' height='2' />
                <rect x='51' y='49' rx='3' ry='3' width='2' height='465' />
                <rect x='955' y='49' rx='3' ry='3' width='2' height='465' />
                <circle cx='882' cy='484' r='11' />
                <circle cx='917' cy='484' r='11' />
                <rect x='107' y='476' rx='3' ry='3' width='141' height='15' />
                <rect x='308' y='475' rx='3' ry='3' width='299' height='15' />
                <rect x='664' y='475' rx='3' ry='3' width='141' height='15' />
                <rect x='55' y='513' rx='3' ry='3' width='897' height='2' />
                <rect x='52' y='80' rx='3' ry='3' width='906' height='17' />
                <rect x='53' y='57' rx='3' ry='3' width='68' height='33' />
                <rect x='222' y='54' rx='3' ry='3' width='149' height='33' />
                <rect x='544' y='55' rx='3' ry='3' width='137' height='33' />
                <rect x='782' y='56' rx='3' ry='3' width='72' height='33' />
                <rect x='933' y='54' rx='3' ry='3' width='24' height='33' />
              </ContentLoader>
            </div>
          </div>
        </>
      ) : (
        loading && (
          <>
            <div className='row'>
              <div className='col-12 p-10'>
                <ContentLoader
                  speed={2}
                  width={'100%'}
                  backgroundColor='#f3e7e7'
                  foregroundColor='#c7cdea'
                  {...props}
                >
                  {props.imageType === 'circle' ? (
                    <circle cx='60' cy='45' r='30' key='circle' />
                  ) : (
                    <rect
                      x='20'
                      y='20'
                      rx='5'
                      ry='5'
                      width='64'
                      height='63'
                      key='rect'
                    />
                  )}
                  {new Array(numberLines).fill(' ').map((_, i) => (
                    <rect
                      key={`line-${i}`}
                      x='105'
                      y={i * 20 + 20}
                      rx='5'
                      ry='5'
                      width='80%'
                      height='12'
                    />
                  ))}
                </ContentLoader>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};
export default LoaderContent;
