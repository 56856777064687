/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import TapItActivity from '../lessonactivity/tap-it-activity';
import SayItActivity from '../lessonactivity/say-it-activity';
import WriteItActivity from '../lessonactivity/write-it-activity';
import ListenItActivity from '../lessonactivity/listen-it-activity';
import VideoSection from '../lessonactivity/video-section';
import LessonResult from './Result';
import {
  clearLesson,
  fetchLessonByIdAction,
  fetchLessonsAction,
} from 'store/actions';
import '../../../theme/assets/css/lesson_activity.css';
import { useParams } from 'react-router-dom';
import PickWordActivity from '../lessonactivity/pick-word-activity';
import AIImageShowActivity from '../lessonactivity/ai-image-show-activity';
import { useAuth } from 'app/modules/auth';
import { useAd } from 'app/components/AdModal/AdProvider';
import { usePostQuery } from 'hooks/reactQueryHelper';

const StartLessonGetById: React.FC = (props: any) => {
  const { authSubscription } = useAuth();
  const { onShowAd } = useAd();

  const { lessongetByIdReq, lessonDetails, loading } = props;
  const { id, courseInviteId } = useParams();

  const [singleLessonData, setSingleLessonData] = useState<any>('');
  const [startLessonShow, setStartLessonShow] = useState(true);
  const [tapITActivityShow, setTapITActivityShow] = useState(true);
  const [sayITActivityShow, setSayITActivityShow] = useState(false);
  const [writeITActivityShow, setWriteITActivityShow] = useState(false);
  const [listenITActivityShow, setListenITActivityShow] = useState(false);
  const [pickWordActivityShow, setPickWordActivityShow] = useState(false);
  const [videoSectionShow, setVideoSectionShow] = useState(false);
  const [resultShow, setResultShow] = useState(false);
  const [prevLastIndexQuestion, setPrevLastIndexQuestion] = useState(false);
  const [finishLessonShow, setFinishLessonShow] = useState(false);
  const [adSectionShow, setAdSectionShow] = useState(false);
  const [aiImageShowActivityShow, setAiImageShowActivityShow] = useState(false);

  const [percentageHide, setPercentageHide] = useState(false);

  const [resultAnswerCount, setResultAnswerCount] = useState(0);
  const [questionResultList, setQuestionResultList] = useState([]);
  const [quizQuestionCount, setQuizQuestionCount] = useState(1);

  const [sectionType, setSectionType] = useState('');
  const [sectionActive, setSectionActive] = useState('');
  const [sectionTotalLength, setSectionTotalLength] = useState<number>();
  const [sectionIndex, setSectionIndex] = useState<number>(0);

  const [nativeLang, setNativeLang] = useState('en');
  const [targetLang, setTargetLang] = useState('es');
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [trueAnswerCount, setTrueAnswerCount] = useState<number>(0);

  const [profileImage, setProfileImage] = useState('');
  const [userID, setUserID] = useState('');
  const [aiActivityDefaultText, setAIActivityDefaultText] = useState<any>();

  useEffect(() => {
    if (
      authSubscription?.isPremium === false &&
      authSubscription?.isFreeExpired === true
    ) {
      onShowAd();
    }
  }, [authSubscription]);

  useEffect(() => {
    setSectionType(sectionType);
  }, [sectionType]);

  useEffect(() => {
    if (id) {
      setSectionType('');
      setSingleLessonData('');
      clearLesson();
      lessongetByIdReq({ Lesson_ID: id, CourseInviteID: courseInviteId });
    }
  }, [id]);

  const addUserLessonLog: any = usePostQuery(
    'addUserLessonLog',
    '/userLessonsLogs/addUserLessonsLogs',
    {}
  );

  useEffect(() => {
    const formData = {
      LessonId: id,
      CourseInviteID: courseInviteId,
    };
    if (formData) {
      addUserLessonLog.mutate(formData);
    }
  }, []);

  useEffect(() => {
    // if (lessonDetails.Result != null && lessonDetails?.Result?.length != 0) {
    if (lessonDetails != null) {
      setSectionTotalLength(lessonDetails?.Result?.Activity_Details.length - 1);
      const SectionTypeData = lessonDetails?.Result?.Activity_Details[0];
      setSectionType(SectionTypeData?.Section);

      const modifiedLessonData = lessonDetails?.Result?.Activity_Details.map(
        ({ Say_IT, ...rest }: { Say_IT: any; [key: string]: any }) => rest
      );

      let indexGet = lessonDetails?.Result?.Activity_Details.findIndex(
        (item: any) => item.Section === 'QuizSection'
      );

      if (indexGet !== -1) {
        const removedItem = lessonDetails?.Result?.Activity_Details.splice(
          indexGet,
          1
        )[0];
        lessonDetails?.Result?.Activity_Details.push(removedItem);
      }
      setSingleLessonData(lessonDetails?.Result);
      setSectionIndex(0);
      setNativeLang(lessonDetails?.Language?.Native_Language?.Language_Code);
      setTargetLang(lessonDetails?.Language?.Target_Language?.Language_Code);
      setProfileImage(lessonDetails?.UserData?.Profile_Image);
      setUserID(lessonDetails?.UserData?.UserID);

      let count: number = 0;

      if (lessonDetails) {
        lessonDetails?.Result?.Activity_Details?.forEach((item: any) => {
          if (item?.Listen_IT) {
            count = Number(count) + Number(item?.Listen_IT?.Questions.length);
          }
          if (item?.Say_IT) {
            count = Number(count) + Number(item?.Say_IT?.Questions.length);
          }

          if (item?.Tap_IT) {
            count = Number(count) + Number(item?.Tap_IT?.Questions.length);
          }

          if (item?.Write_IT) {
            count = Number(count) + Number(item?.Write_IT?.Questions.length);
          }
        });
        setQuestionCount(count);
      }
      var aiActivityData = lessonDetails?.Result?.Activity_Details?.filter(
        (x: any) => x.Section == 'AISection'
      )[0];
      if (aiActivityData) {
        setAIActivityDefaultText(
          aiActivityData?.Additional_AI_Text?.filter(
            (x: any) =>
              x.Language_Code ==
              lessonDetails?.Language?.Native_Language?.Language_Code
          )[0]
        );
      }
    }
  }, [lessonDetails]);

  useEffect(() => {
    setTrueAnswerCount(0);
    const hideAddressBar = () => {
      window.scrollTo(0, 1); // Scrolls to the top of the page
    };

    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        // If in fullscreen mode, hide the address bar
        hideAddressBar();
      }
    };

    // Add the fullscreenchange event listener
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <>
      {videoSectionShow && (
        <>
          <VideoSection
            setVideoSectionShow={setVideoSectionShow}
            setTapITActivityShow={setTapITActivityShow}
            singleLessonData={singleLessonData}
            sectionType={sectionType}
            setStartLessonShow={setStartLessonShow}
            loading={loading}
            sectionTotalLength={sectionTotalLength}
            setSectionIndex={setSectionIndex}
            sectionIndex={sectionIndex}
            setSectionType={setSectionType}
            setFinishLessonShow={setFinishLessonShow}
            setSingleLessonData={setSingleLessonData}
            nativeLang={nativeLang}
            targetLang={targetLang}
            tapITActivityShow={tapITActivityShow}
            setPickWordActivityShow={setPickWordActivityShow}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            userID={userID}
            trueAnswerCount={trueAnswerCount}
            setTrueAnswerCount={setTrueAnswerCount}
            aiActivityDefaultText={aiActivityDefaultText}
          />
        </>
      )}
      {tapITActivityShow && (
        <>
          <TapItActivity
            setTapITActivityShow={setTapITActivityShow}
            setSayITActivityShow={setSayITActivityShow}
            singleLessonData={singleLessonData}
            sectionType={sectionType}
            setWriteITActivityShow={setWriteITActivityShow}
            setResultAnswerCount={setResultAnswerCount}
            resultAnswerCount={resultAnswerCount}
            setVideoSectionShow={setVideoSectionShow}
            prevLastIndexQuestion={prevLastIndexQuestion}
            setPrevLastIndexQuestion={setPrevLastIndexQuestion}
            setSectionIndex={setSectionIndex}
            nativeLang={nativeLang}
            targetLang={targetLang}
            sectionIndex={sectionIndex}
            setQuestionResultList={setQuestionResultList}
            questionResultList={questionResultList}
            quizQuestionCount={quizQuestionCount}
            setQuizQuestionCount={setQuizQuestionCount}
            trueAnswerCount={trueAnswerCount}
            setTrueAnswerCount={setTrueAnswerCount}
            setPickWordActivityShow={setPickWordActivityShow}
          />
        </>
      )}
      {sayITActivityShow && (
        <>
          <SayItActivity
            singleLessonData={singleLessonData}
            setWriteITActivityShow={setWriteITActivityShow}
            setSayITActivityShow={setSayITActivityShow}
            sectionType={sectionType}
            setPrevLastIndexQuestion={setPrevLastIndexQuestion}
            prevLastIndexQuestion={prevLastIndexQuestion}
            setTapITActivityShow={setTapITActivityShow}
            nativeLang={nativeLang}
            targetLang={targetLang}
            trueAnswerCount={trueAnswerCount}
            setTrueAnswerCount={setTrueAnswerCount}
          />
        </>
      )}
      {writeITActivityShow && (
        <>
          <WriteItActivity
            singleLessonData={singleLessonData}
            setListenITActivityShow={setListenITActivityShow}
            setWriteITActivityShow={setWriteITActivityShow}
            sectionType={sectionType}
            setTapITActivityShow={setTapITActivityShow}
            setSectionType={setSectionType}
            setVideoSectionShow={setVideoSectionShow}
            setResultAnswerCount={setResultAnswerCount}
            resultAnswerCount={resultAnswerCount}
            setSayITActivityShow={setSayITActivityShow}
            setPrevLastIndexQuestion={setPrevLastIndexQuestion}
            nativeLang={nativeLang}
            targetLang={targetLang}
            prevLastIndexQuestion={prevLastIndexQuestion}
            setQuestionResultList={setQuestionResultList}
            questionResultList={questionResultList}
            quizQuestionCount={quizQuestionCount}
            setQuizQuestionCount={setQuizQuestionCount}
            trueAnswerCount={trueAnswerCount}
            setTrueAnswerCount={setTrueAnswerCount}
          />
        </>
      )}
      {listenITActivityShow && (
        <>
          <ListenItActivity
            singleLessonData={singleLessonData}
            setTapITActivityShow={setTapITActivityShow}
            setListenITActivityShow={setListenITActivityShow}
            setSectionType={setSectionType}
            sectionType={sectionType}
            setVideoSectionShow={setVideoSectionShow}
            setResultShow={setResultShow}
            setResultAnswerCount={setResultAnswerCount}
            resultAnswerCount={resultAnswerCount}
            setWriteITActivityShow={setWriteITActivityShow}
            setSectionIndex={setSectionIndex}
            sectionIndex={sectionIndex}
            nativeLang={nativeLang}
            targetLang={targetLang}
            setPrevLastIndexQuestion={setPrevLastIndexQuestion}
            setQuestionResultList={setQuestionResultList}
            questionResultList={questionResultList}
            quizQuestionCount={quizQuestionCount}
            setQuizQuestionCount={setQuizQuestionCount}
            setAiImageShowActivityShow={setAiImageShowActivityShow}
            trueAnswerCount={trueAnswerCount}
            setTrueAnswerCount={setTrueAnswerCount}
          />
        </>
      )}

      {(resultShow || finishLessonShow) && (
        <>
          <LessonResult
            currentLesson={singleLessonData || lessonDetails}
            resultAnswerCount={resultAnswerCount}
            setVideoSectionShow={setVideoSectionShow}
            setResultShow={setResultShow}
            setSectionIndex={setSectionIndex}
            setResultAnswerCount={setResultAnswerCount}
            setQuestionResultList={setQuestionResultList}
            questionResultList={questionResultList}
            setQuizQuestionCount={setQuizQuestionCount}
            sectionType={sectionType}
            percentageHide={percentageHide}
            setPercentageHide={setPercentageHide}
          />
        </>
      )}
      {/* <LessonResult
                currentLesson={singleLessonData || lessonDetails}
                resultAnswerCount={resultAnswerCount}
                setVideoSectionShow={setVideoSectionShow}
                setResultShow={setResultShow}
                setSectionIndex={setSectionIndex}
            /> */}
      {pickWordActivityShow && (
        <PickWordActivity
          singleLessonData={singleLessonData}
          nativeLang={nativeLang}
          targetLang={targetLang}
          sectionType={sectionType}
          setSectionType={setSectionType}
          setTapITActivityShow={setTapITActivityShow}
          setPickWordActivityShow={setPickWordActivityShow}
          setVideoSectionShow={setVideoSectionShow}
          setWriteITActivityShow={setWriteITActivityShow}
          Lesson_ID={id}
          aiActivityDefaultText={aiActivityDefaultText}
        />
      )}
      {aiImageShowActivityShow && (
        <AIImageShowActivity
          singleLessonData={singleLessonData}
          nativeLang={nativeLang}
          targetLang={targetLang}
          sectionType={sectionType}
          setAiImageShowActivityShow={setAiImageShowActivityShow}
          setListenITActivityShow={setListenITActivityShow}
          setVideoSectionShow={setVideoSectionShow}
          setProfileImage={setProfileImage}
          profileImage={profileImage}
          setPercentageHide={setPercentageHide}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lessons.list.loading,
    error: state.lessons.list.error,
    data: state.lessons.list.data,
    lessonDetails: state.lessons.details.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearLesson: () => dispatch(clearLesson()),
  lessongetReq: (config: any) => dispatch(fetchLessonsAction(config)),
  lessongetByIdReq: (param: any) => dispatch(fetchLessonByIdAction(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartLessonGetById);
