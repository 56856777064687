import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import EnrollmentFooter from './EnrollmentFooter';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import {
  extractNumericPart,
  getCurrentMonth,
  getCurrentYear,
  getMonthNumber,
} from '../enrollmenthooks/EnrollmentHooks';
import HighlightedIssuesText from '../enrollmenthooks/HighlightedIssuesText';
import FieldsErrorText from '../enrollmenthooks/FieldsErrorText';
import {
  DatePickerComponents,
  RadioButtonComponents,
  SelectComponents,
  TextFieldComponents,
} from 'hooks/formikFormHook';
import ShowWarningText from '../enrollmentmodel/ShowWarningText';
import SchoolYearShowDetails from '../enrollmentmodel/SchoolYearShowDetails';

const Enrollment_Section1 = (props: any) => {
  const { setStep, step, setGradeValue, enrollmentData, fieldReportedIssue } =
    props;
  const tabRef: any = useRef(null);
  const saveEnrollmentQuery: any = usePostQuery(
    'saveEnrollment',
    '/enrollment/add',
    {}
  );
  const getCalendartypeQuery: any = useGetQuery(
    'getCalendartype',
    '/enrollment/calendartype/get',
    {}
  );

  const getLanguageQuery: any = useGetQuery(
    'getLanguage',
    '/language/getAll',
    {}
  );

  const getAllCountryQuery: any = useGetQuery(
    'getAllCountry',
    '/country/getAllCountry',
    {}
  );

  const updateCountryQuery: any = usePostQuery(
    'updateCountry',
    '/enrollment/updateCountry',
    {}
  );

  const getLevelQuery: any = useGetQuery('getLevel', '/level/getAll', {});

  const navigate = useNavigate();
  const yourFieldRef: any = useRef();
  const [language, setLanguage] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>('');
  const [countryName, setCountryName] = useState<any>(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');
  const [showWarningModel, setShowWarningModel] = useState(false);
  const [showSchoolYearModel, setShowSchoolYearModel] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  useEffect(() => {
    getCalendartypeQuery.mutateAsync();
    getLanguageQuery.mutateAsync();
    getLevelQuery.mutateAsync();
    getAllCountryQuery.mutateAsync();
  }, []);

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  useEffect(() => {
    if (getLanguageQuery.isSuccess) {
      setLanguage(getLanguageQuery?.data?.ResultObject);
    }
  }, [getLanguageQuery.isSuccess]);

  useEffect(() => {
    if (enrollmentData) {
      formik.setFieldValue(
        'Email',
        enrollmentData?.Email ? enrollmentData?.Email : ''
      );
      formik.setFieldValue(
        'Firstname',
        enrollmentData?.Firstname ? enrollmentData?.Firstname : ''
      );
      formik.setFieldValue(
        'Lastname',
        enrollmentData?.Lastname ? enrollmentData?.Lastname : ''
      );
      formik.setFieldValue(
        'Country',
        enrollmentData?.Country ? enrollmentData?.Country : ''
      );
      setCountryName(enrollmentData?.Country ? enrollmentData?.Country : '');
      formik.setFieldValue(
        'Student_Grade',
        enrollmentData?.Student_Grade ? enrollmentData?.Student_Grade : ''
      );
      formik.setFieldValue(
        'School_Year_Type',
        enrollmentData?.School_Year_Type?.value
          ? enrollmentData?.School_Year_Type?.value
          : ''
      );
      formik.setFieldValue(
        'DateOfBirth',
        enrollmentData?.DateOfBirth?.value
          ? new Date(enrollmentData?.DateOfBirth?.value)
          : ''
      );
      setStartDate(
        enrollmentData?.DateOfBirth?.value
          ? new Date(enrollmentData?.DateOfBirth?.value)
          : ''
      );
      formik.setFieldValue(
        'Status',
        enrollmentData?.Status ? enrollmentData?.Status : 0
      );
      formik.setFieldValue(
        'EnrollmentID',
        enrollmentData?.EnrollmentID ? enrollmentData?.EnrollmentID : undefined
      );
      formik.setFieldValue(
        'NativeLanguage',
        enrollmentData?.NativeLanguage ? enrollmentData?.NativeLanguage : ''
      );
      formik.setFieldValue(
        'Level',
        enrollmentData?.Level ? enrollmentData?.Level : ''
      );

      formik.setFieldValue(
        'Is_High_School_Graduated',
        enrollmentData?.Is_High_School_Graduated?.value
          ? enrollmentData?.Is_High_School_Graduated?.value
          : ''
      );
    }
  }, [enrollmentData]);

  useEffect(() => {
    if (saveEnrollmentQuery.isSuccess) {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        setStep(2);
        setGradeValue(formik?.values?.Student_Grade);
      }
      if (formik?.values?.ButtonAction == 'Save') {
        navigate('/account');
      }
    }
  }, [saveEnrollmentQuery.isSuccess]);

  const [grade, setGrade] = useState([
    { value: '0', label: 'Kindergarten' },
    { value: '1st', label: '1st' },
    { value: '2nd', label: '2nd' },
    { value: '3rd', label: '3rd' },
    { value: '4th', label: '4th' },
    { value: '5th', label: '5th' },
    { value: '6th', label: '6th' },
    { value: '7th', label: '7th' },
    { value: '8th', label: '8th' },
    { value: '9th', label: '9th' },
    { value: '10th', label: '10th' },
    { value: '11th', label: '11th' },
    { value: '12th', label: '12th' },
  ]);

  const initialValues = {
    Firstname: '',
    Lastname: '',
    Email: '',
    Country: '',
    Student_Grade: '',
    School_Year_Type: '',
    DateOfBirth: '',
    ButtonAction: '',
    Status: 0,
    Step: 1,
    EnrollmetType: 'Enrollment',
    NativeLanguage: '',
    Level: '',
    Is_High_School_Graduated: '',
  };

  const formvalidationSchema = Yup.object().shape({
    Firstname: Yup.string().required('Please enter first name'),
    Lastname: Yup.string().required('Please enter last name'),
    Email: Yup.string()
      .email('Email address is incorrect')
      .required('Please enter email address'),
    Country: Yup.string().required('Please select country'),
    School_Year_Type: Yup.string().required('Please select school year type'),
    Student_Grade: Yup.string().required('Please select grade'),
    DateOfBirth: Yup.date().required('Please select a date'),
    NativeLanguage: Yup.string().required('Please select native language'),
    Level: Yup.string().required('Please select level'),
    Is_High_School_Graduated: Yup.string().required('Please select any option'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      saveEnrollmentQuery.mutateAsync(values);
    },
  });

  const LevelDiscripttion = useMemo(() => {
    if (formik.values.Level) {
      const filteredData = getLevelQuery?.data?.filter(
        (item: any) => item._id === formik.values.Level
      );
      return filteredData
        ? filteredData[0]?.Level_Description_English +
            ' / ' +
            filteredData[0]?.Level_Description
        : null;
    }
  }, [formik.values.Level, getLevelQuery.isSuccess]);

  const onFormInformationReset = () => {
    setStartDate('');
    formik.resetForm();
  };

  const onFormInformationSave = (actionName: any) => {
    formik.setFieldValue('ButtonAction', actionName);
  };

  const onCountryChange = (event: any) => {
    const selectedCountry = event?.target?.value;
    updateCountryQuery.mutateAsync({ CountryID: selectedCountry });
  };

  useEffect(() => {
    if (updateCountryQuery.isSuccess) {
      const data = updateCountryQuery?.data?.ResultObject;
      formik.setFieldValue('Country', data?.Country);
      setCountryName(data?.Country);
    }
  }, [updateCountryQuery.isSuccess]);

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
      const errorFieldId = Object.keys(formik.errors)[0];
      const errorFieldElement = document.getElementById(errorFieldId);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [formik.submitCount]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  const handleDateChange = (date: any) => {
    setStartDate(date), formik.setFieldValue('DateOfBirth', date);
    removeHightlight('DateOfBirth');
  };

  // const checkHighSchoolGraduated = useMemo(() => {
  //   if (formik.values?.Is_High_School_Graduated == 'Yes') {
  //     setShowWarningModel(true);
  //     return true;
  //   }
  //   return false;
  // }, [formik.values?.Is_High_School_Graduated]);

  // const checkSchoolYearType = useMemo(() => {
  //   const data = getCalendartypeQuery?.data?.ResultObject;
  //   if (formik.values?.School_Year_Type && data?.length > 0) {
  //     const idToFind = formik.values?.School_Year_Type;
  //     const foundItem = data.find((item: any) => item._id === idToFind);
  //     setShowSchoolYearModel(true);
  //     return foundItem?.calendarType;
  //   }
  //   return '';
  // }, [formik.values?.School_Year_Type]);

  const handleNoButton = () => {
    setShowSchoolYearModel(false);
    formik.setFieldValue('School_Year_Type', '');
  };

  const [checkSchoolYearType, setCheckSchoolYearType] = useState('');
  const [checkHighSchoolGraduated, setCheckHighSchoolGraduated] =
    useState(false);
  const [calenderYear, setCalenderYear] = useState('');

  const onHandleSchoolYearTypeChange = (
    id: any,
    calendarType: string,
    calenderYear: string
  ) => {
    // const data = getCalendartypeQuery?.data?.ResultObject;
    // const foundItem = data.find((item: any) => item._id === id);
    setShowSchoolYearModel(true);
    setCheckSchoolYearType(calendarType);
    setCalenderYear(calenderYear);
  };

  const onCloseShowWarningModel = () => {
    setShowWarningModel(false);
    formik.setFieldValue('Is_High_School_Graduated', '');
  };

  const onCheckHighSchoolGraduated = (value: any) => {
    if (value == 'Yes') {
      setShowWarningModel(true);
      setCheckHighSchoolGraduated(true);
    } else {
      setCheckHighSchoolGraduated(false);
    }
  };

  const getCalendartypeData = useMemo(() => {
    const data = Array.isArray(getCalendartypeQuery?.data?.ResultObject)
      ? getCalendartypeQuery.data.ResultObject
      : [];
    return data.filter((item: any) => {
      const lastMonthYear = item?.lastMonthYear;
      if (!lastMonthYear) return false; // Skip if lastMonthYear is undefined or null

      const [month, year] = lastMonthYear.split(' ');
      return (
        getCurrentYear < parseInt(year) ||
        (getCurrentYear === parseInt(year) &&
          getCurrentMonth <= getMonthNumber(month))
      );
    });
  }, [getCalendartypeQuery.isSuccess]);

  return (
    <>
      <Loader
        loading={saveEnrollmentQuery.isLoading || updateCountryQuery.isLoading}
      />
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className='card mt-3 mb-3' ref={tabRef}>
            <div className='card-body p-6'>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Email
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Email'
                  placeholder='Your email address'
                  type={'email'}
                  disabled={false}
                />
                <FieldsErrorText
                  touched={formik.touched.Email}
                  errors={formik.errors.Email}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Student First Name / Primer Nombre del estudiante
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Firstname'
                  placeholder='Your first name'
                  disabled={true}
                />
                <FieldsErrorText
                  touched={formik.touched.Firstname}
                  errors={formik.errors.Firstname}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Student Last Name / Apellido del estudiante
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Lastname'
                  placeholder='Your last name'
                  disabled={true}
                />
                <FieldsErrorText
                  touched={formik.touched.Lastname}
                  errors={formik.errors.Lastname}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Is_High_School_Graduated')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Has your student already graduated from high school? / ¿Su
                  estudiante ya se graduó de la escuela secundaria?
                  <span className='text-danger'> * </span>
                </label>
                <div>
                  {['Yes', 'No']?.map((data: any, index: any) => {
                    return (
                      <>
                        <div className='form-check mt-2'>
                          <RadioButtonComponents
                            formik={formik}
                            fieldName={'Is_High_School_Graduated'}
                            dataValue={data}
                            onChange={() => {
                              removeHightlight('Is_High_School_Graduated');
                              onCheckHighSchoolGraduated(data);
                            }}
                          />
                          <label className='form-label fw-normal'>
                            {data == 'Yes' ? `${data} / Si` : data}
                          </label>
                        </div>
                      </>
                    );
                  })}
                </div>
                <FieldsErrorText
                  touched={formik.touched.Is_High_School_Graduated}
                  errors={formik.errors.Is_High_School_Graduated}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('DateOfBirth')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Student Date of Birth
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  Fecha de nacimiento del estudiante
                </p>
                <div className='form-date-picker custom-datepicker'>
                  <DatePickerComponents
                    formik={formik}
                    fieldName={'DateOfBirth'}
                    startDate={startDate}
                    today={today}
                    handleDateChange={handleDateChange}
                    // onChange={() => {
                    //   removeHightlight('DateOfBirth');
                    // }}
                  />
                  <FieldsErrorText
                    touched={formik.touched.DateOfBirth}
                    errors={formik.errors.DateOfBirth}
                  />
                </div>
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  What country does student live in?{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ¿En qué país vive su estudiante?
                </p>
                <select
                  aria-label='Default select example'
                  // {...formik.getFieldProps('Country')}
                  value={countryName}
                  onChange={(event) => onCountryChange(event)}
                  className={clsx(
                    'form-control',
                    {
                      'is-invalid':
                        formik.touched.Country && formik.errors.Country,
                    },
                    {
                      'is-valid':
                        formik.touched.Country && !formik.errors.Country,
                    }
                  )}
                  // disabled={countryName ? true : false}
                >
                  <option selected value=''>
                    Choose
                  </option>
                  {getAllCountryQuery?.data?.ResultObject?.map((data: any) => {
                    return (
                      <option value={data._id}>{data.Country_Name}</option>
                    );
                  })}
                </select>
                <FieldsErrorText
                  touched={formik.touched.Country}
                  errors={formik.errors.Country}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('NativeLanguage')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  What is your native language:{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>¿Cuál es tu idioma natal?</p>
                <SelectComponents
                  formik={formik}
                  fieldName='NativeLanguage'
                  onChange={() => {
                    removeHightlight('NativeLanguage');
                  }}
                >
                  <option selected value=''>
                    Choose
                  </option>
                  {language?.map((data: any) => {
                    return (
                      <option value={data._id}>
                        {data.Language_Name +
                          ' - ' +
                          data.Language_Name_Spanish}
                      </option>
                    );
                  })}
                </SelectComponents>
                <FieldsErrorText
                  touched={formik.touched.NativeLanguage}
                  errors={formik.errors.NativeLanguage}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Level')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  What is the student's English language level?:{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ¿Cuál es el nivel de inglés del estudiante?
                </p>
                <SelectComponents
                  formik={formik}
                  fieldName='Level'
                  onChange={() => {
                    removeHightlight('Level');
                  }}
                >
                  <option selected value=''>
                    Choose
                  </option>
                  {getLevelQuery?.data?.map((data: any) => {
                    return (
                      <option value={data._id}>
                        {data.Level_Name + ' - ' + data.Level_Name_Spanish}
                      </option>
                    );
                  })}
                </SelectComponents>
                <FieldsErrorText
                  touched={formik.touched.Level}
                  errors={formik.errors.Level}
                />
                {LevelDiscripttion && (
                  <label className='form-label mt-3'>{LevelDiscripttion}</label>
                )}
              </div>

              <div
                className={
                  hasFieldError?.includes('School_Year_Type')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  School Year Type: <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>Tipo de año escolar:</p>
                {getCalendartypeData?.map((data: any) => {
                  return (
                    <>
                      <div className='form-check mt-4'>
                        <RadioButtonComponents
                          formik={formik}
                          fieldName={'School_Year_Type'}
                          dataValue={data._id}
                          onChange={() => {
                            removeHightlight('School_Year_Type');
                            onHandleSchoolYearTypeChange(
                              data._id,
                              data.calendarType,
                              data.typeYear
                            );
                          }}
                        />
                        <label className='form-label fw-normal'>
                          {data.description} / {data.description_spanish}
                        </label>
                      </div>
                    </>
                  );
                })}

                <FieldsErrorText
                  touched={formik.touched.School_Year_Type}
                  errors={formik.errors.School_Year_Type}
                />
              </div>

              <div
                className={
                  hasFieldError?.includes('Student_Grade')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Student's Grade Enrolling for{' '}
                  {calenderYear === '' ? getCurrentYear : calenderYear}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  Grado del estudiante que se inscribe para{' '}
                  {calenderYear === '' ? getCurrentYear : calenderYear}:
                </p>
                <SelectComponents
                  formik={formik}
                  fieldName='Student_Grade'
                  onChange={() => {
                    removeHightlight('Student_Grade');
                  }}
                >
                  <option selected value=''>
                    Choose
                  </option>
                  {grade.map((data: any) => {
                    return (
                      <option value={data.value}>
                        {data.label}{' '}
                        {data.value != 0 &&
                          `Grade - ${extractNumericPart(data.label)}° Grado`}
                        {data.value == 0 && `/ Preescolar`}
                      </option>
                    );
                  })}
                </SelectComponents>
                <FieldsErrorText
                  touched={formik.touched.Student_Grade}
                  errors={formik.errors.Student_Grade}
                />
              </div>

              {checkHighSchoolGraduated ? (
                <div className='fv-plugins-message-container p-3'>
                  <div className='fv-help-block'>
                    <h5 className='text-danger'>
                      Los estudiantes que ya están graduados de la escuela
                      secundaria pueden no aplicar.
                    </h5>
                  </div>
                </div>
              ) : (
                <HighlightedIssuesText formik={formik} />
              )}
            </div>
          </div>
          <EnrollmentFooter
            isBackButtonShow={false}
            setStep={setStep}
            step={step}
            onFormReset={onFormInformationReset}
            onSaveForm={onFormInformationSave}
            isDisableNextButton={checkHighSchoolGraduated}
          />
        </form>
      </div>

      <ShowWarningText
        showWarningModel={showWarningModel}
        setShowWarningModel={setShowWarningModel}
        onCloseShowWarningModel={onCloseShowWarningModel}
        textValue={
          'Students that are already graduated from high school can not apply. / Los estudiantes que ya estén graduados de la escuela secundaria no pueden postular.'
        }
      />

      <SchoolYearShowDetails
        setShowSchoolYearModel={setShowSchoolYearModel}
        showSchoolYearModel={showSchoolYearModel}
        checkSchoolYearType={checkSchoolYearType}
        handleNoButton={handleNoButton}
        calenderYear={calenderYear}
      />
    </>
  );
};

export default Enrollment_Section1;
