import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import ReEnrollmentFormFooter from '../ReEnrollmentFormFooter';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import {
  CheckBoxComponents,
  PhoneNumberComponents,
  RadioButtonComponents,
  TextFieldComponents,
} from 'hooks/formikFormHook';

const Section3 = (props: any) => {
  const { setStep, step, reEnrollmentData, formType, fieldReportedIssue } =
    props;

  const saveReEnrollmentSection3Query: any = usePostQuery(
    'saveReEnrollmentSection3',
    '/reenrollment/save',
    {}
  );

  const navigate = useNavigate();

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');
  const [isAnotherParent, setIsAnotherParent] = useState<Boolean>(false);

  const EnglishLink =
    'https://docs.google.com/document/d/1tlZfi-gcjeptZEvE3-gE72r9p83-hZ891kiDejjLO5Q/edit?usp=sharing';

  useEffect(() => {
    if (reEnrollmentData) {
      formik.setFieldValue(
        'Guardian_Firstname',
        reEnrollmentData?.Guardian_Firstname?.value
          ? reEnrollmentData?.Guardian_Firstname?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Lastname',
        reEnrollmentData?.Guardian_Lastname?.value
          ? reEnrollmentData?.Guardian_Lastname?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Address',
        reEnrollmentData?.Guardian_Address?.value
          ? reEnrollmentData?.Guardian_Address?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Number',
        reEnrollmentData?.Guardian_Number?.value
          ? reEnrollmentData?.Guardian_Number?.value
          : ''
      );
      formik.setFieldValue(
        'Legal_Guardian_Of_Student',
        reEnrollmentData?.Legal_Guardian_Of_Student?.value
          ? reEnrollmentData?.Legal_Guardian_Of_Student?.value
          : false
      );
      formik.setFieldValue(
        'Guardian_Relationship',
        reEnrollmentData?.Guardian_Relationship?.value
          ? reEnrollmentData?.Guardian_Relationship?.value
          : ''
      );
      formik.setFieldValue(
        'Certify_Information',
        reEnrollmentData?.Certify_Information?.value
          ? reEnrollmentData?.Certify_Information?.value
          : false
      );
      formik.setFieldValue(
        'Individuals_Legal_Rights_Of_Students_Records',
        reEnrollmentData?.Individuals_Legal_Rights_Of_Students_Records?.value
          ? reEnrollmentData?.Individuals_Legal_Rights_Of_Students_Records
              ?.value
          : ''
      );
      formik.setFieldValue(
        'Terms_And_Conditions',
        reEnrollmentData?.Terms_And_Conditions?.value
          ? reEnrollmentData?.Terms_And_Conditions?.value
          : false
      );
      formik.setFieldValue(
        'Acknowledge_Name',
        reEnrollmentData?.Acknowledge_Name?.value
          ? reEnrollmentData?.Acknowledge_Name?.value
          : ''
      );
      formik.setFieldValue(
        'UserEmail',
        reEnrollmentData?.Email ? reEnrollmentData?.Email : ''
      );
      formik.setFieldValue(
        'EnrollmentID',
        reEnrollmentData?.EnrollmentID
          ? reEnrollmentData?.EnrollmentID
          : undefined
      );
      formik.setFieldValue(
        'Another_Parent_And_Guardian_Name',
        reEnrollmentData?.Another_Parent_And_Guardian_Name?.value
          ? reEnrollmentData?.Another_Parent_And_Guardian_Name?.value
          : ''
      );
    }
  }, [reEnrollmentData]);

  const initialValues = {
    Guardian_Firstname: '',
    Guardian_Lastname: '',
    Guardian_Address: '',
    Guardian_Number: '',
    Legal_Guardian_Of_Student: false,
    Guardian_Relationship: '',
    Certify_Information: false,
    Acknowledge_Name: '',
    Terms_And_Conditions: false,
    Individuals_Legal_Rights_Of_Students_Records: '',
    Another_Parent_And_Guardian_Name: '',
    ButtonAction: '',
    Status: 1,
    MailSend: true,
    Step: 3,
  };

  const formvalidationSchema = Yup.object().shape({
    Guardian_Firstname: Yup.string().required('Please enter first name'),
    Guardian_Lastname: Yup.string().required('Please enter last name'),
    Guardian_Address: Yup.string().required('Please enter address'),
    Guardian_Number: Yup.string().required('Please enter number'),
    Guardian_Relationship: Yup.string().required(
      `Please enter guardian's relationship to student`
    ),
    Certify_Information: Yup.bool().oneOf([true], 'You must check this box.'),
    Acknowledge_Name: Yup.string().required('Please enter name'),
    Terms_And_Conditions: Yup.bool().oneOf([true], 'You must check this box.'),
    Individuals_Legal_Rights_Of_Students_Records: Yup.string().required(
      'Please enter discription'
    ),
    Another_Parent_And_Guardian_Name:
      isAnotherParent && Yup.string().required('Please enter discription'),
    Legal_Guardian_Of_Student: Yup.bool().oneOf(
      [true],
      'You must check this box.'
    ),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      saveReEnrollmentSection3Query.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveReEnrollmentSection3Query.isSuccess) {
      setStep(0);
    }
  }, [saveReEnrollmentSection3Query.isSuccess]);

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    if (formik.values.Individuals_Legal_Rights_Of_Students_Records == 'Yes') {
      setIsAnotherParent(true);
    } else {
      setIsAnotherParent(false);
    }
  }, [formik.values.Individuals_Legal_Rights_Of_Students_Records]);

  const onHandleLegalRights = (data: any) => {
    if (data == 'No') {
      formik.setFieldValue('Another_Parent_And_Guardian_Name', '');
    }
  };

  return (
    <>
      <div>
        <Loader loading={saveReEnrollmentSection3Query.isLoading} />
        <form onSubmit={formik.handleSubmit}>
          <div className='card mt-3 mb-3' ref={tabRef}>
            <h1 className='card-header align-items-center bg-light'>
              Guardian Information - Información del tutor
            </h1>
            <div className='card-body p-6'>
              <div
                className={
                  hasFieldError?.includes('Guardian_Firstname')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Guardian First Name / Nombre del tutor
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Guardian_Firstname'
                  placeholder='Your first name'
                  onChange={() => {
                    removeHightlight('Guardian_Firstname');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Guardian_Firstname}
                  errors={formik.errors.Guardian_Firstname}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Guardian_Lastname')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Guardian Last Name / Apellido del tutor
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Guardian_Lastname'
                  placeholder='Your last name'
                  onChange={() => {
                    removeHightlight('Guardian_Lastname');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Guardian_Lastname}
                  errors={formik.errors.Guardian_Lastname}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Guardian_Address')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Guardian Home Address <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Guardian_Address'
                  placeholder='Your home address'
                  onChange={() => {
                    removeHightlight('Guardian_Address');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Guardian_Address}
                  errors={formik.errors.Guardian_Address}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Guardian_Number')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  WhatsApp # (Include Country Code)
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  WhatsApp # (Incluir código de país) *
                </p>
                <div
                  className={`input-group whatsapp-number
                             ${
                               formik.touched.Guardian_Number &&
                               formik.errors.Guardian_Number &&
                               'error-border'
                             }
                             ${
                               formik.touched.Guardian_Number &&
                               !formik.errors.Guardian_Number &&
                               'success-border'
                             }
                             `}
                >
                  <PhoneNumberComponents
                    formik={formik}
                    fieldName={'Guardian_Number'}
                    onChange={() => {
                      removeHightlight('Guardian_Number');
                    }}
                  />
                  <FieldsErrorText
                    touched={formik.touched.Guardian_Number}
                    errors={formik.errors.Guardian_Number}
                  />
                </div>
              </div>
              <div
                className={
                  hasFieldError?.includes('Legal_Guardian_Of_Student')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Are you the parent/legal guardian of student? (All
                  responsibility of schooling and care of student is your
                  responsibility) <span className='text-danger'> * </span>
                </label>
                <label className='form-label fw-bold'>
                  ¿Es usted el padre/tutor legal del estudiante? (Toda la
                  responsabilidad de la educación y el cuidado del estudiante es
                  su responsabilidad)
                </label>
                <div className='form-check mt-4'>
                  <CheckBoxComponents
                    formik={formik}
                    fieldName='Legal_Guardian_Of_Student'
                    onChange={() => {
                      removeHightlight('Legal_Guardian_Of_Student');
                    }}
                  />
                  <label className='form-label fw-normal'>Yes</label>
                </div>
                <FieldsErrorText
                  touched={formik.touched.Legal_Guardian_Of_Student}
                  errors={formik.errors.Legal_Guardian_Of_Student}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Guardian_Relationship')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  What is the Guardian's Relationship to Student{' '}
                  <span className='text-danger'> * </span>
                </label>
                <label className='form-label fw-bold'>
                  ¿Cuál es la relación del tutor con el estudiante?
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Guardian_Relationship'
                  placeholder='Your answer'
                  onChange={() => {
                    removeHightlight('Guardian_Relationship');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Guardian_Relationship}
                  errors={formik.errors.Guardian_Relationship}
                />
              </div>

              <div
                className={
                  hasFieldError?.includes(
                    'Individuals_Legal_Rights_Of_Students_Records'
                  )
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Are there any other individuals that would have legal rights
                  to the students records? (Another parent/legal guardian) If
                  so, please list their full name, relationship and contact
                  information. <span className='text-danger'> * </span>
                </label>
                <label className='form-label fw-bold'>
                  ¿Hay otras personas que tendrían derechos legales sobre los
                  registros de los estudiantes? (Otro padre/tutor legal) Si es
                  así, indique su nombre completo, relación e información de
                  contacto.
                </label>
                {/* <TextFieldComponents
                  formik={formik}
                  fieldName='Individuals_Legal_Rights_Of_Students_Records'
                  placeholder='Your answer'
                  onChange={() => {
                    removeHightlight(
                      'Individuals_Legal_Rights_Of_Students_Records'
                    );
                  }}
                /> */}
                {['Yes', 'No'].map((data: any) => {
                  return (
                    <div className='form-check mt-2'>
                      <RadioButtonComponents
                        formik={formik}
                        fieldName={
                          'Individuals_Legal_Rights_Of_Students_Records'
                        }
                        dataValue={data}
                        onChange={() => {
                          removeHightlight(
                            'Individuals_Legal_Rights_Of_Students_Records'
                          );
                          onHandleLegalRights(data);
                        }}
                      />
                      <label className='form-label'>{data}</label>
                    </div>
                  );
                })}

                <FieldsErrorText
                  touched={
                    formik.touched.Individuals_Legal_Rights_Of_Students_Records
                  }
                  errors={
                    formik.errors.Individuals_Legal_Rights_Of_Students_Records
                  }
                />
              </div>

              {formik.values.Individuals_Legal_Rights_Of_Students_Records ==
                'Yes' && (
                <>
                  <div
                    className={
                      hasFieldError?.includes(
                        'Another_Parent_And_Guardian_Name'
                      )
                        ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                        : 'p-3'
                    }
                  >
                    <label className='form-label fw-bold'>
                      Another Parent / Guardian Name{' '}
                      <span className='text-danger'> * </span>
                    </label>
                    <p className='form-label fw-bold'>
                      Otro nombre de padre/tutor
                    </p>
                    <TextFieldComponents
                      formik={formik}
                      fieldName='Another_Parent_And_Guardian_Name'
                      placeholder='Your answer'
                      onChange={() => {
                        removeHightlight('Another_Parent_And_Guardian_Name');
                      }}
                    />

                    <FieldsErrorText
                      touched={formik.touched.Another_Parent_And_Guardian_Name}
                      errors={formik.errors.Another_Parent_And_Guardian_Name}
                    />
                  </div>
                </>
              )}

              <div
                className={
                  hasFieldError?.includes('Terms_And_Conditions')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Terms and Conditions for Enrollment{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  Términos y condiciones para la inscripción
                </p>
                <p className='form-label'>
                  - I understand and agree to all of the terms and guidelines
                  mentioned in the Handbook and will ensure that my student and
                  I will follow all said guidelines
                </p>
                <p className='form-label'>English Link:</p>
                <a href={EnglishLink}>{EnglishLink}</a>
                <p className='form-label mt-2'>
                  - I understand that it is my responsibility to update Hope
                  City of any changes in contact information within 24-48 hours
                  of the change.
                </p>
                <p className='form-label'>
                  - I understand that by enrolling my student in Hope City
                  School, that I am doing so on a voluntary basis and that
                  enrollment is not a requirement of my local
                  Ministry/Department of Education.
                </p>
                <p className='form-label'>
                  - I understand that it is my responsibility to contact the
                  Ministry/Department of Education to ensure compliance with
                  Hope City School.
                </p>
                <p className='form-label'>
                  - I understand that just by enrolling in Hope City School, it
                  does not guarantee graduation, high school diploma, or
                  admission into college and I must check and meet all
                  requirements.
                </p>
                <div className='form-check mt-3'>
                  <CheckBoxComponents
                    formik={formik}
                    fieldName='Terms_And_Conditions'
                    onChange={() => {
                      removeHightlight('Terms_And_Conditions');
                    }}
                  />
                  <label className='form-label'>I Agree</label>
                </div>
                <FieldsErrorText
                  touched={formik.touched.Terms_And_Conditions}
                  errors={formik.errors.Terms_And_Conditions}
                />
              </div>

              <div
                className={
                  hasFieldError?.includes('Certify_Information')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  I certify all information is true:
                </label>
                <p className='form-label'>
                  Transcript Verification Form:This is to certify that all
                  transcripts/report cards submitted on behalf of my student are
                  accurate and true. I agree that it is my responsibility to
                  ensure that all documents submitted are not altered or
                  falsified in any way. I attest that there have been no changes
                  that alter the authenticity of the documents and verify that
                  my student has received the grades with academic integrity. I
                  am aware that if any documents are found to be forged, my
                  student will be immediately expelled from Hope City Private
                  School and will no longer be eligible to re-enroll. This
                  document will remain in effect for the duration of a student's
                  enrollment at Hope City School.{' '}
                  <span className='text-danger'> * </span>
                </p>
                <p className='form-label'>
                  Formulario de verificación de expediente académico. Esto es
                  para certificar que todas los expedientes académicos/ boletas
                  de calificaciones presentadas en nombre de mi estudiante sean
                  precisos y verdaderos. Estoy de acuerdo en que es mi
                  responsabilidad asegurarme de que todos los documentos
                  presentados no sean alterados o falsificados de ninguna
                  manera. Doy fe de que no ha habido modificaciones que alteren
                  la autenticidad de los documentos y verifico que mi alumno ha
                  recibido las calificaciones con integridad académica. Soy
                  consciente de que si se descubre que algún documento es
                  falsificado, mi estudiante será expulsado inmediatamente de
                  Hope City Private School y ya no será elegible para volver a
                  inscribirse. *Este documento permanecerá vigente durante toda
                  la duración de la inscripción de los estudiantes en Hope City
                  School.
                </p>
                <div className='form-check mt-2'>
                  <CheckBoxComponents
                    formik={formik}
                    fieldName='Certify_Information'
                    onChange={() => {
                      removeHightlight('Certify_Information');
                    }}
                  />
                  <label className='form-label'>I Agree</label>
                </div>
                <FieldsErrorText
                  touched={formik.touched.Certify_Information}
                  errors={formik.errors.Certify_Information}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Acknowledge_Name')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                {/* <label className='form-label fw-bold'>
                  By typing my name below, I acknowledge that I am the legal
                  guardian of the student and confirm this information is true
                  and accurate, please write your name below.{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  Al escribir mi nombre a continuación, reconozco que soy el
                  tutor legal del estudiante y confirmo que esta información es
                  verdadera y precisa, por favor, escriba su nombre abajo.
                </p> */}
                <label className='form-label fw-bold'>
                  Guardian's Name (Digital Signature) / Nombre del tutor (firma
                  digital) <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Acknowledge_Name'
                  placeholder='Your answer'
                  onChange={() => {
                    removeHightlight('Acknowledge_Name');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Acknowledge_Name}
                  errors={formik.errors.Acknowledge_Name}
                />
              </div>
            </div>
          </div>
          <ReEnrollmentFormFooter
            isBackButton={true}
            isSubmitButton={true}
            setStep={setStep}
            step={step}
          />
        </form>
      </div>
    </>
  );
};

export default Section3;
