import React from 'react';
import { createRoot } from 'react-dom/client';

// React Query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
// Apps
import { ThemeI18nProvider } from './theme/i18n/Themei18n';
import './theme/assets/plugins/fonticon/fonticon.css';
import './theme/assets/sass/style.scss';
import './theme/assets/sass/plugins.scss';
import './theme/assets/sass/style.react.scss';
import './theme/assets/css/style.bundle.css';
import './theme/assets/css/style.css';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/modules/auth';
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './theme/assets/css/stylenew.css';
import { isDevelopment } from 'shared/mode';
import AdProvider from 'app/components/AdModal/AdProvider';
import AdModal from 'app/components/AdModal';

// import areIntlLocalesSupported from 'intl-locales-supported';

// // const localesMyAppSupports = ['en-US', 'fr-FR']; // replace with your app's supported locales
// const localesMyAppSupports = ['en-US', 'fr-FR', 'hi-IN', 'bn-IN', 'te-IN', 'ta-IN', 'pa-IN', 'mr-IN', 'gu-IN', 'kn-IN', 'ml-IN'];

// if (global.Intl) {
//   // Determine if the built-in `Intl` has the locale data we need.
//   if (!areIntlLocalesSupported(localesMyAppSupports)) {
//     // `Intl` exists, but it doesn't have the data we need, so load the
//     // polyfill and try again.
//     require('intl');
//     require('intl/locale-data/jsonp/en.js');
//     require('intl/locale-data/jsonp/fr.js');
//   }
// } else {
//   // No `Intl`, so use and load the polyfill.
//   global.Intl = require('intl');
//   require('intl/locale-data/jsonp/en.js');
//   require('intl/locale-data/jsonp/fr.js');
// }

setupAxios(axios);
Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <QueryClientProvider client={queryClient}>
        <ThemeI18nProvider>
          <AuthProvider>
            <AdProvider>
              <React.Fragment>
                <AdModal />
                <AppRoutes />
              </React.Fragment>
            </AdProvider>
          </AuthProvider>
          {isDevelopment() && <ReactQueryDevtools />}
        </ThemeI18nProvider>
      </QueryClientProvider>
    </Provider>
  );
}
