import React, { useCallback, useEffect, useState } from 'react';
import { IoAddCircleSharp } from 'react-icons/io5';
import EnrolledCourseModal from './EnrolledCourseModal';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import LoaderNew from 'app/components/LoaderNew';
import { useAuth } from 'app/modules/auth';
import { PageTitle } from 'theme/layout/core';
import useIntlCustom from 'hooks/useIntlCustom';
import { Link } from 'react-router-dom';
import Lesson from '../../components/CourseNewLayout/Lesson';
import CountDownGoogleMeet from 'app/components/CourseNewLayout/CountDownGoogleMeet';
import { useNavigate } from 'react-router-dom';
import RequiredCompletedLessonWarnPopup from 'app/components/CourseNewLayout/RequiredCompletedLessonWarnPopup';
import useModal from 'hooks/useModal';
import { getJwtToken } from 'hooks/tokenHook';
import OpenWaitingRoomForIOS from 'app/components/Course/OpenWaitingRoomForIOS';
import Swal from 'sweetalert2';
import useToaster from 'hooks/useToaster';
import { FaBookOpen } from 'react-icons/fa';

const Course: React.FC = () => {
  const { formatMessage } = useIntlCustom();
  const { warningToaster } = useToaster();
  const { toggleModal, show, openModal, modalData } = useModal();
  const navigate = useNavigate();
  const { authSubscription, currentUser } = useAuth();

  const { preFilledCompleted, setPreFilledCompleted } = useAuth();
  const [addNewCourseModalShow, setAddNewCourseModalShow] =
    React.useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [modalOpenWaitingRoomForIOS, setModalOpenWaitingRoomForIOS] = useState({
    show: false,
    link: null,
  });

  const getAllInvitedQuery: any = useGetQuery(
    'getAllInvited',
    `/course/getAllInvited`,
    {}
  );

  const joinScheduleGoogleMeetQuery: any = usePostQuery(
    'joinScheduleGoogleMeet',
    '/scheduleGoogleMeet/joinScheduleGoogleMeet',
    {}
  );

  // const updateScreenWidth = () => {
  //   // let element: any = document.getElementById('kt_app_content_container');
  //   let elementAddNewCourse: any = document.getElementById('addNewCourse');
  //   if (window.screen.width <= 425) {
  //     // element.classList.add('padding-0');
  //     elementAddNewCourse.classList.add('d-none');
  //   } else {
  //     // element.classList.remove('padding-0');
  //     elementAddNewCourse.classList.remove('d-none');
  //   }
  // };

  // useEffect(() => {
  //   // Add an event listener when the component mounts
  //   window.addEventListener('resize', updateScreenWidth);

  //   // Clean up by removing the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('resize', updateScreenWidth);
  //   };
  // }, []);

  useEffect(() => {
    refreshData();
  }, []);

  React.useEffect(() => {
    if (preFilledCompleted == true) {
      refreshData();
      setPreFilledCompleted(false);
    }
  }, [preFilledCompleted]);

  const refreshData = () => {
    getAllInvitedQuery.mutate();
  };

  const generateWaitingUrl = ({
    courseInviteId,
    scheduleGoogleMeetId,
  }: any) => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    return `${protocol}//${host}/authentication/googleMeetWaitingRoom/${getJwtToken()}?courseInviteId=${courseInviteId}&scheduleGoogleMeetId=${scheduleGoogleMeetId}`;
  };

  const joinScheduleGoogleMeet = (item: any) => {
    const formData: any = {
      scheduleGoogleMeetId:
        item?.notCompletedGoogleMeetClass?.scheduleGoogleMeetId,
      courseInviteId: item?._id,
    };

    joinScheduleGoogleMeetQuery.mutate(formData);

    window.location.href =
      item?.notCompletedGoogleMeetClass?.scheduleGoogleMeet.link;
  };

  const handleGoogleMeetRedirect = (item: any) => {
    if (item?.isRestricted) {
      openModal({ ...item });
    } else if (
      authSubscription?.isPremium === false &&
      item?.course?.freeLiveClass > 0 &&
      item?.isFree >= item?.course?.freeLiveClass
    ) {
      Toast.fire({
        icon: 'warning',
        title: `You have reached your limit for live classes this week with a free
        account. Check back next week or upgrade to premium to attend more
        classes.`,
      });
    } else if (item?.notCompletedGoogleMeetClass?.isStart) {
      joinScheduleGoogleMeet(item);
    } else {
      navigate(
        `/course/${item?._id}/waiting-class-room/${item?.notCompletedGoogleMeetClass?.scheduleGoogleMeetId}`
      );
    }

    // if (item?.isRestricted) {
    //   openModal({ ...item });
    // } else if (os === 'iOS') {
    //   console.log('iOS');
    //   let url = generateWaitingUrl({
    //     courseInviteId: item?._id,
    //     scheduleGoogleMeetId:
    //       item?.notCompletedGoogleMeetClass?.scheduleGoogleMeetId,
    //   });
    //   setModalOpenWaitingRoomForIOS((prev): any => ({
    //     ...prev,
    //     show: true,
    //     link: url,
    //   }));
    // } else if (os === 'Android') {
    //   console.log('Android');
    //   let url = generateWaitingUrl({
    //     courseInviteId: item?._id,
    //     scheduleGoogleMeetId:
    //       item?.notCompletedGoogleMeetClass?.scheduleGoogleMeetId,
    //   });
    //   window.location.href = `intent:${url}#Intent;end`;
    // } else {
    //   navigate(
    //     `/course/${item?._id}/waiting-class-room/${item?.notCompletedGoogleMeetClass?.scheduleGoogleMeetId}`
    //   );
    // }
  };

  console.log('getAllInvitedQuery', getAllInvitedQuery);

  const onModalOpenWaitingRoomForIOSHide = useCallback(() => {
    setModalOpenWaitingRoomForIOS((prev): any => ({
      ...prev,
      show: false,
      link: null,
    }));
  }, []);

  return (
    <>
      {modalOpenWaitingRoomForIOS.show && (
        <OpenWaitingRoomForIOS
          show={modalOpenWaitingRoomForIOS.show}
          onHide={onModalOpenWaitingRoomForIOSHide}
          link={modalOpenWaitingRoomForIOS.link}
        />
      )}

      <RequiredCompletedLessonWarnPopup
        show={show}
        onHide={toggleModal}
        msg={formatMessage({
          id:
            Number(modalData?.requiredLessonForLiveClass) === Number(1)
              ? 'COURSE.REQUIRED_LESSON.POINT.MSG'
              : 'COURSE.REQUIRED_LESSON.POINTS.MSG',
          values: {
            param1: modalData?.requiredLessonForLiveClass,
          },
        })}
        centered
        backdrop='static'
      />
      <LoaderNew
        loading={getAllInvitedQuery?.isLoading}
        isFull={true}
        className=''
      />
      <EnrolledCourseModal
        show={addNewCourseModalShow}
        onHide={() => setAddNewCourseModalShow(false)}
        callBack={() => refreshData()}
      />
      <PageTitle breadcrumbs={[]}>
        {formatMessage({ id: 'COURSE.MY_COURSES' })}
      </PageTitle>
      {currentUser?.IsDiplomaStudent === false && (
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='position-absolute btn btn-primary btn-sm addNewCourseBtn d-none d-sm-block'
            onClick={() => setAddNewCourseModalShow(true)}
            title={formatMessage({
              id: 'COURSE.ADD_NEW_COURSE',
            })}
          >
            {formatMessage({ id: 'COURSE.ADD_NEW_COURSE' })}
          </button>
        </div>
      )}

      <div className='row g-10'>
        {getAllInvitedQuery?.isSuccess &&
          getAllInvitedQuery?.data?.ResultObject?.map(
            (item: any, index: any) => (
              <div key={item?._id} className='col-xl-4 col-lg-12 col-12'>
                <div className='card card-stretch h-100'>
                  <div className='card-body p-0'>
                    <div
                      className='card-header p-0'
                      id={`courseCard_${item._id}`}
                    >
                      <div className='d-flex justify-content-between align-items-center bg-primary text-white p-5 w-100 rounded-top'>
                        <Link
                          className='link-light fs-md-3 fs-5 fw-bold col-6'
                          to={`/course/${item?._id}/student-course-details`}
                          title={item?.course?.name}
                        >
                          <div className='text-truncate'>
                            {item?.course?.name}{' '}
                          </div>
                          {item?.notCompletedGoogleMeetClass?.date && (
                            <div>
                              <CountDownGoogleMeet
                                date={
                                  new Date(
                                    item?.notCompletedGoogleMeetClass?.date
                                  )
                                }
                              />
                            </div>
                          )}
                        </Link>
                        {item?.notCompletedGoogleMeetClass?.date && (
                          <button
                            className='btn btn-light btn-sm text-primary p-2 text-truncate fs-8'
                            onClick={() => {
                              handleGoogleMeetRedirect(item);
                            }}
                            title={formatMessage({
                              id: 'COURSE.JOIN_LIVE_CLASS',
                            })}
                          >
                            {formatMessage({
                              id: 'COURSE.JOIN_LIVE_CLASS',
                            })}
                          </button>
                        )}

                        {currentUser?.IsDiplomaStudent === false &&
                          item?.isCompleted && (
                            <div className='ribbon-2 text-white'>
                              <div className='p-1 fw-bolder'>
                                {formatMessage({
                                  id: 'COURSE.COMPLETED',
                                })}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <Lesson data={item} />
                  </div>
                </div>
              </div>
            )
          )}

        {currentUser?.IsDiplomaStudent === false && (
          <div className='col-xl-4 col-lg-12 col-12'>
            <div className='card card-stretch h-100'>
              <div className='card-body'>
                <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                  <button
                    className='btn d-flex flex-column justify-content-center align-items-center '
                    title='Add Another Course'
                    onClick={() => setAddNewCourseModalShow(true)}
                  >
                    <IoAddCircleSharp size={30} />
                    <span className='fs-6'>
                      {formatMessage({
                        id: 'COURSE.ADD_NEW_COURSE',
                      })}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {getAllInvitedQuery.isSuccess === true &&
        getAllInvitedQuery?.data?.ResultObject.length === 0 &&
        currentUser?.IsDiplomaStudent === true && (
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-center'>
              <div className='display-1 mb-1'>
                <FaBookOpen />
              </div>
              <div className='fs-4'>No Course Available</div>
            </div>
          </div>
        )}
    </>
  );
};
export default Course;
