import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GradeSubmissionFormFooter from '../GradeSubmissionFormFooter';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import GradeSubmissionFormDocumentUpload from '../GradeSubmissionFormDocumentUpload';
import { useNavigate } from 'react-router-dom';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import HighlightedIssuesText from 'app/pages/enrollmenthooks/HighlightedIssuesText';
import {
  SchoolRequirementsK12,
  SchoolRequirementsK6,
} from './SchoolRequirementsSection';
import {
  extractNumericPart,
  getCurrentMonth,
  getCurrentYear,
  getMonthNumber,
} from 'app/pages/enrollmenthooks/EnrollmentHooks';
import {
  RadioButtonComponents,
  TextFieldComponents,
  TextareaFieldComponents,
} from 'hooks/formikFormHook';

const Section1 = (props: any) => {
  const {
    formType,
    gradeFormData,
    setStep,
    step,
    stepper,
    fieldReportedIssue,
    setGrade,
  } = props;
  const tabRef: any = useRef(null);
  const saveGradeSubmisiionQurey: any = usePostQuery(
    'saveGradeSubmisiion',
    '/enrollment/grade/save',
    {}
  );

  const getGradeCalendartypeQuery: any = useGetQuery(
    'getGradeCalendartype',
    '/enrollment/calendartype/get',
    {}
  );

  const navigate = useNavigate();

  useEffect(() => {
    getGradeCalendartypeQuery.mutateAsync();
  }, []);

  const SchooleYear = [
    {
      value_english:
        'January/February 2023 - November/December 2023 (Start school year in January and Finish in December 2023)',
      value_spanish:
        'Enero/Febrero - Noviembre/Diciembre (Inicia curso escolar entre enero o febrero y finaliza entre noviembre y diciembre del 2023)',
    },
    {
      value_english:
        'September/October 2023 - June/July 2024 (Start school year in between June and July 2023 and Finish between June and July, 2024)',
      value_spanish:
        'Septiembre/Octubre 2023 - Junio/Julio 2024 (Inicia curso escolar entre septiembre y octubre2023 y finaliza entre junio y julio del 2024)',
    },
  ];

  const GradeData = [
    '0',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
  ];

  const [hasFieldError, setHasFieldError] = useState<any>('');
  const initialValues: any = {
    Email: '',
    Student_Firstname: '',
    Student_Lastname: '',
    StudentID: '',
    Country_Name: '',
    Complete_School_Day: '',
    School_Year: '',
    School_Grade: '',
    Student_Fail_Subject: '',
    Courses_Not_Shown_Transcripts: '',
    List_Courses_Appear_On_Transcripts: '',
    Student_School_Transcript_Subject_Document: [],
    FormType: formType,
  };

  useEffect(() => {
    if (gradeFormData) {
      formik.setFieldValue(
        'Email',
        gradeFormData?.Email ? gradeFormData?.Email : ''
      );
      formik.setFieldValue(
        'Student_Firstname',
        gradeFormData?.Student_Firstname ? gradeFormData?.Student_Firstname : ''
      );
      formik.setFieldValue(
        'Student_Lastname',
        gradeFormData?.Student_Lastname ? gradeFormData?.Student_Lastname : ''
      );
      formik.setFieldValue(
        'Country_Name',
        gradeFormData?.Country_Name ? gradeFormData?.Country_Name : ''
      );
      formik.setFieldValue(
        'StudentID',
        gradeFormData?.StudentID ? gradeFormData?.StudentID : ''
      );
      formik.setFieldValue(
        'Complete_School_Day',
        gradeFormData?.Complete_School_Day?.value
          ? gradeFormData?.Complete_School_Day?.value
          : ''
      );
      formik.setFieldValue(
        'School_Year',
        gradeFormData?.School_Year?.value
          ? gradeFormData?.School_Year?.value
          : ''
      );
      formik.setFieldValue(
        'School_Grade',
        gradeFormData?.School_Grade?.value
          ? gradeFormData?.School_Grade?.value
          : ''
      );
      formik.setFieldValue(
        'Student_Fail_Subject',
        gradeFormData?.Student_Fail_Subject?.value
          ? gradeFormData?.Student_Fail_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Courses_Not_Shown_Transcripts',
        gradeFormData?.Courses_Not_Shown_Transcripts?.value
          ? gradeFormData?.Courses_Not_Shown_Transcripts?.value
          : ''
      );
      formik.setFieldValue(
        'List_Courses_Appear_On_Transcripts',
        gradeFormData?.List_Courses_Appear_On_Transcripts?.value
          ? gradeFormData?.List_Courses_Appear_On_Transcripts?.value
          : ''
      );
      formik.setFieldValue(
        'Student_School_Transcript_Subject_Document',
        gradeFormData?.Student_School_Transcript_Subject_Document?.value
          ? gradeFormData?.Student_School_Transcript_Subject_Document?.value.split()
          : []
      );
    }
  }, [gradeFormData]);

  const formvalidationSchema = Yup.object().shape({
    Complete_School_Day: Yup.string().required(
      'Please enter complete school day'
    ),
    School_Year: Yup.string().required('Please select any option'),
    School_Grade: Yup.string().required('Please select any option'),
    Student_Fail_Subject: Yup.string().required('Please select any option'),
    Courses_Not_Shown_Transcripts: Yup.string().required(
      'Please enter discription'
    ),
    List_Courses_Appear_On_Transcripts: Yup.string().required(
      'Please enter discription'
    ),

    Student_School_Transcript_Subject_Document: Yup.array()
      .required('Please upload a document')
      .min(1, 'Please upload a document'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      console.log(values);
      saveGradeSubmisiionQurey.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveGradeSubmisiionQurey.isSuccess) {
      console.log(gradeData);
      if (gradeData == 'none') {
        navigate(
          `/enrollment/grade-submission-form${formType == 1 ? 'k12' : 'k6'}`
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        setStep(2);
      }
    }
  }, [saveGradeSubmisiionQurey.isSuccess]);

  const gradeData = useMemo(() => {
    return formik.values.School_Grade;
  }, [formik.values.School_Grade]);

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
      const errorFieldId = Object.keys(formik.errors)[0];
      const errorFieldElement = document.getElementById(errorFieldId);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [formik.submitCount]);

  useEffect(() => {
    // if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
    //   const errorFieldId = Object.keys(formik.errors)[0];
    const errorFieldElement = document.getElementById('scoll-class');
    if (errorFieldElement) {
      errorFieldElement.scrollIntoView({ behavior: 'smooth' });
    }
    // }
  }, []);

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  useEffect(() => {
    if (formik.values.School_Grade) {
      setGrade(formik.values.School_Grade);
    }
  }, [formik.values.School_Grade]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  const getCalendartypeData = useMemo(() => {
    const data = getGradeCalendartypeQuery?.data?.ResultObject;
    return data
      ? data?.filter((item: any) => {
          const [month, year] = item?.lastMonthYear?.split(' ');
          return (
            getCurrentYear < parseInt(year) ||
            (getCurrentYear === parseInt(year) &&
              getCurrentMonth <= getMonthNumber(month))
          );
        })
      : [];
  }, [getGradeCalendartypeQuery.isSuccess]);

  return (
    <>
      <div>
        <Loader loading={saveGradeSubmisiionQurey.isLoading} />
        <form onSubmit={formik.handleSubmit}>
          <Card className='mt-5 scoll-class' ref={tabRef}>
            <Card.Body className='p-6'>
              <div
                className={
                  hasFieldError?.includes('School_Grade')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  What Grade are you submitting {getCurrentYear} transcripts
                  for?
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ¿Para qué grado está enviando las transcripciones de{' '}
                  {getCurrentYear}?
                </p>

                {GradeData?.map((grade, index) => {
                  return (
                    <div className='form-check mt-4' key={index}>
                      <RadioButtonComponents
                        formik={formik}
                        fieldName={'School_Grade'}
                        dataValue={grade}
                        onChange={() => {
                          removeHightlight('School_Grade');
                        }}
                      />
                      <label className='form-label fw-normal'>
                        {index != 0 &&
                          `${grade} Grade - ${extractNumericPart(
                            grade
                          )}° Grado`}
                        {index == 0 && `Kindergarten / Preescolar`}
                      </label>
                    </div>
                  );
                })}
                <FieldsErrorText
                  touched={formik.touched.School_Grade}
                  errors={formik.errors.School_Grade}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Email <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  type='email'
                  fieldName='Email'
                  placeholder='Your email address'
                  disabled={true}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Student First Name / Nombre del estudiante{' '}
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Student_Firstname'
                  placeholder='Your first name'
                  disabled={true}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Student Last Name / Apellido del Estudiante{' '}
                  <span className='text-danger'> * </span>
                </label>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Student_Lastname'
                  placeholder='Your last name'
                  disabled={true}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  Hope City Student ID #{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  Número de identificación del estudiante provisto por Hope City
                </p>
                <TextFieldComponents
                  formik={formik}
                  fieldName='StudentID'
                  placeholder='Your student id number'
                  disabled={true}
                />
              </div>
              <div className='p-3'>
                <label className='form-label fw-bold'>
                  What country does student live in?{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ¿En qué país vive su estudiante?
                </p>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Country_Name'
                  placeholder='Your country name'
                  disabled={true}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Complete_School_Day')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  SCHOOL DAYS: How many school days did your student complete
                  from Jan 1, {getCurrentYear} to December 31, {getCurrentYear}?{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label'>
                  (California requires that your student complete a minimum of
                  175 school days)
                </p>
                <p className='form-label fw-bold'>
                  DÍAS ESCOLARES: ¿Cuántos días escolares completó su estudiante
                  desde el 1 de enero de {getCurrentYear} hasta el 31 de
                  diciembre de {getCurrentYear}?
                </p>
                <p className='form-label'>
                  (California requiere que su estudiante complete un mínimo de
                  175 días escolares)
                </p>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Complete_School_Day'
                  // placeholder='Your complete school day'
                  placeholder='Escribe el número de días escolares completados.'
                  disabled={false}
                  onChange={() => {
                    removeHightlight('Complete_School_Day');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Complete_School_Day}
                  errors={formik.errors.Complete_School_Day}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('School_Year')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  School Year Type: <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>Tipo de año escolar: </p>

                {getCalendartypeData?.map((data: any) => {
                  return (
                    <>
                      <div className='form-check mt-4'>
                        <RadioButtonComponents
                          formik={formik}
                          fieldName={'School_Year'}
                          dataValue={data?.description}
                          onChange={() => {
                            removeHightlight('School_Year');
                          }}
                        />
                        <label className='form-label fw-normal'>
                          {data?.description +
                            ' / ' +
                            data?.description_spanish}
                        </label>
                      </div>
                    </>
                  );
                })}
                <FieldsErrorText
                  touched={formik.touched.School_Year}
                  errors={formik.errors.School_Year}
                />
              </div>

              {formType == 2 && <SchoolRequirementsK6 formType={formType} />}
              {formType == 1 && <SchoolRequirementsK12 formType={formType} />}

              <GradeSubmissionFormDocumentUpload
                formik={formik}
                hasFieldError={hasFieldError}
              />

              <div
                className={
                  hasFieldError?.includes('List_Courses_Appear_On_Transcripts')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  WRITE A LIST of the courses that appear on the transcript you
                  uploaded for {getCurrentYear}.{' '}
                  <span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ESCRIBE UNA LISTA de los cursos que aparecen en el expediente
                  académico que subiste para {getCurrentYear}.
                </p>
                <TextareaFieldComponents
                  formik={formik}
                  fieldName='List_Courses_Appear_On_Transcripts'
                  placeholder='Your discription'
                  disabled={false}
                />
                <FieldsErrorText
                  touched={formik.touched.List_Courses_Appear_On_Transcripts}
                  errors={formik.errors.List_Courses_Appear_On_Transcripts}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Student_Fail_Subject')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Did your student fail any subjects? (Final grade of less than
                  60% on any subjects)<span className='text-danger'> * </span>
                </label>
                <p className='form-label fw-bold'>
                  ¿Su estudiante reprobó alguna materia? (Calificación final
                  inferior al 60 puntos en cualquiera de las materias)
                </p>
                {['Yes', 'No']?.map((data: any) => {
                  return (
                    <div className='form-check mt-4'>
                      <RadioButtonComponents
                        formik={formik}
                        fieldName={'Student_Fail_Subject'}
                        dataValue={data}
                        onChange={() => {
                          removeHightlight('Student_Fail_Subject');
                        }}
                      />
                      <label className='form-label fw-normal'>{data}</label>
                    </div>
                  );
                })}

                <FieldsErrorText
                  touched={formik.touched.Student_Fail_Subject}
                  errors={formik.errors.Student_Fail_Subject}
                />
              </div>
              <div
                className={
                  hasFieldError?.includes('Courses_Not_Shown_Transcripts')
                    ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                    : 'p-3'
                }
              >
                <label className='form-label fw-bold'>
                  Please describe any courses not shown on transcripts uploaded:{' '}
                  <span className='text-danger fw-bold'> * </span>
                </label>
                <p className='form-label'>
                  Describa los cursos que no se muestran en la boleta que subió:
                </p>
                <TextFieldComponents
                  formik={formik}
                  fieldName='Courses_Not_Shown_Transcripts'
                  placeholder='Your discription'
                  disabled={false}
                />
                <FieldsErrorText
                  touched={formik.touched.Courses_Not_Shown_Transcripts}
                  errors={formik.errors.Courses_Not_Shown_Transcripts}
                />
              </div>
              <HighlightedIssuesText formik={formik} />
            </Card.Body>
          </Card>
          <GradeSubmissionFormFooter
            IsBackButton={false}
            setStep={setStep}
            step={step}
            stepper={stepper}
            formType={formType}
          />
        </form>
      </div>
    </>
  );
};

export default Section1;
