import React from 'react';
import { toAbsoluteUrl } from 'theme/helpers';

const ContactUs = () => {
  return (
    <div className='card h-100'>
      <div className='card-body p-lg-17'>
        <div className='row h-100'>
          <div className='col-md-6 mx-auto d-flex align-items-center align-items-center'>
            <div className='bg-light card-rounded d-flex flex-column flex-center flex-center  flex-fill  p-10'>
              <div className='card-title'>
                <h1 className='text-dark fw-bold my-1'>
                  <i className='fa-solid fa-envelope fs-1 me-3'></i>Email
                </h1>
              </div>
              <div className='text-gray-700 fw-semibold fs-2'>
                <div className='col mb-2'>
                  <a
                    className='btn btn-icon btn-sm-bg-light btn-bg-light btn-active-light-primary btn-flex justify-content-start px-3 px-md-0 flex-row  w-100 border-gray-200'
                    data-kt-button='true'
                    href='mailto:info@hopecityschool.org'
                  >
                    <span className='fs-2 fs-md-6 fw-bold mt-1 px-3'>
                      info@hopecityschool.org
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
