import React from 'react';
import { Form as BForm } from 'react-bootstrap';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import useIntlCustom from 'hooks/useIntlCustom';

const Step3 = ({ getAllLanguageDataQuery, getAllLevelDataQuery }: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    errors,
    touched,
    values,
    submitForm,
    getFieldProps,
    handleChange,
    handleBlur,
    setFieldValue,
  }: any = useFormikContext();

  return (
    <>
      <BForm.Group className='mb-7'>
        <BForm.Label className='fw-bold fs-2'>
          {formatMessage({
            id: 'TEACHER.TARGET_LANGUAGE',
          })}
          {/* Target language */}
          <span style={{ color: 'red' }}>*</span>
        </BForm.Label>
        <Field
          name='targetLanguage'
          isMulti={false}
          component={Select}
          options={getAllLanguageDataQuery?.data?.ResultObject.map(
            (option: any, index: any) => {
              return {
                ...option,
                value: option._id,
                label: option.Language_Name,
              };
            }
          )}
          placeholder={formatMessage({
            id: 'SELECT',
          })}
          isClearable={true}
          isDisabled={getAllLanguageDataQuery?.isLoading}
          isLoading={getAllLanguageDataQuery?.isLoading}
          classNamePrefix='select'
          value={values?.targetLanguage}
          onChange={(option: any) => {
            setFieldValue('targetLanguage', option);
          }}
          // onBlur={handleBlur}
          styles={{
            control: (baseStyles: any, state: any) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused
                  ? errors.targetLanguage && touched.targetLanguage
                    ? '#f1416c'
                    : 'grey'
                  : errors.targetLanguage && touched.targetLanguage
                  ? '#f1416c'
                  : '#b5b5c3',
                boxShadow: '#b5b5c3',
                '&:hover': {
                  borderColor:
                    errors.targetLanguage && touched.targetLanguage
                      ? '#f1416c'
                      : 'none',
                },
                '&:focus': {
                  borderColor:
                    errors.targetLanguage && touched.targetLanguage
                      ? '#f1416c'
                      : 'none',
                },
              };
            },
          }}
        />
        {touched.targetLanguage && errors.targetLanguage && (
          <BForm.Text className='text-danger'>
            <ErrorMessage name='targetLanguage' />
          </BForm.Text>
        )}
      </BForm.Group>

      <BForm.Group className='mb-7'>
        <BForm.Label className='fw-bold fs-2'>
          {formatMessage({
            id: 'TEACHER.LANGUAGE_LEVEL',
          })}
          <span style={{ color: 'red' }}>*</span>
        </BForm.Label>
        <Field
          name='languageLevel'
          isMulti={false}
          component={Select}
          options={getAllLevelDataQuery?.data?.map(
            (option: any, index: any) => {
              return {
                ...option,
                value: option._id,
                label: option.Level_Name,
              };
            }
          )}
          placeholder={formatMessage({
            id: 'SELECT',
          })}
          isClearable={true}
          isDisabled={getAllLevelDataQuery?.isLoading}
          isLoading={getAllLevelDataQuery?.isLoading}
          classNamePrefix='select'
          value={values?.languageLevel}
          onChange={(option: any) => {
            setFieldValue('languageLevel', option);
          }}
          // onBlur={handleBlur}
          styles={{
            control: (baseStyles: any, state: any) => {
              return {
                ...baseStyles,
                borderColor: state.isFocused
                  ? errors.languageLevel && touched.languageLevel
                    ? '#f1416c'
                    : 'grey'
                  : errors.languageLevel && touched.languageLevel
                  ? '#f1416c'
                  : '#b5b5c3',
                boxShadow: '#b5b5c3',
                '&:hover': {
                  borderColor:
                    errors.languageLevel && touched.languageLevel
                      ? '#f1416c'
                      : 'none',
                },
                '&:focus': {
                  borderColor:
                    errors.languageLevel && touched.languageLevel
                      ? '#f1416c'
                      : 'none',
                },
              };
            },
          }}
        />
        {values?.languageLevel && (
          <div>{values?.languageLevel?.Level_Description}</div>
        )}
        {touched.languageLevel && errors.languageLevel && (
          <BForm.Text className='text-danger'>
            <ErrorMessage name='languageLevel' />
          </BForm.Text>
        )}
      </BForm.Group>
    </>
  );
};

export default Step3;
