/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../theme/helpers';
import AuthFooter from './components/AuthFooter';
const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <a href='#' className='d-block d-lg-none mx-auto py-20'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
            className='theme-light-show h-75px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
            className='theme-dark-show h-75px'
          />
        </a>
        <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
          <div className='d-flex justify-content-center flex-column-fluid flex-column w-100 mw-450px'>
            <Outlet />
          </div>
          <AuthFooter />
        </div>

        <div
          className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg11.png')})`,
          }}
        ></div>
      </div>
    </div>
  );
};

export { AuthLayout };
