import useIntlCustom from 'hooks/useIntlCustom';

const AuthFooter: React.FC<{}> = (props: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <div className='m-0'>
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-wrap px-5'>
        {/* begin::Links */}
        <div className='d-flex fw-semibold text-primary fs-base'>
          <a href='/terms' className='px-5' target='_blank'>
            {/* Terms & Conditions */}
            {formatMessage({
              id: 'TERMS_CONDITIONS',
            })}
          </a>
        </div>
        {/* end::Links */}
      </div>
      {/* end::Footer */}
    </div>
  );
};

export default AuthFooter;
