import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import K12_English_Requirement from './requirementsubject/K12_English_Requirement';
import K6_English_Requirement from './requirementsubject/K6_English_Requirement';
import K6_Math_Requirement from './requirementsubject/K6_Math_Requirement';
import K12_Math_Requirement from './requirementsubject/K12_Math_Requirement';
import K12_Science_Requirement from './requirementsubject/K12_Science_Requirement';
import K6_Science_Requirement from './requirementsubject/K6_Science_Requirement';
import K12_SocialScience_Requirement from './requirementsubject/K12_SocialScience_Requirement';
import K6_SocialScience_Requirement from './requirementsubject/K6_SocialScience_Requirement';
import K12_VisualAndArts_Requirement from './requirementsubject/K12_VisualAndArts_Requirement';
import K6_VisualAndArts_Requirement from './requirementsubject/K6_VisualAndArts_Requirement';
import K12_Health_Requirement from './requirementsubject/K12_Health_Requirement';
import K6_Health_Requirement from './requirementsubject/K6_Health_Requirement';
import K12_Foreign_Requirement from './requirementsubject/K12_Foreign_Requirement';
import K6_Foreign_Requirement from './requirementsubject/K6_Foreign_Requirement';
import K12_Physical_Requirement from './requirementsubject/K12_Physical_Requirement';
import K6_Physical_Requirement from './requirementsubject/K6_Physical_Requirement';

const WarningTextModel = (props: any) => {
  const { showWarningModel, setShowWarningModel, requirement, formType } =
    props;

  return (
    <>
      <Modal
        show={showWarningModel}
        onHide={() => setShowWarningModel(false)}
        size='lg'
        centered
        scrollable
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>{requirement}</Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
          {requirement == 'English_Requirement_Subject' && formType == 1 && (
            <K12_English_Requirement />
          )}

          {requirement == 'English_Requirement_Subject' && formType == 2 && (
            <K6_English_Requirement />
          )}

          {requirement == 'Math_Requirement_Subject' && formType == 1 && (
            <K12_Math_Requirement />
          )}

          {requirement == 'Math_Requirement_Subject' && formType == 2 && (
            <K6_Math_Requirement />
          )}

          {requirement == 'Science_Requirement_Subject' && formType == 1 && (
            <K12_Science_Requirement />
          )}

          {requirement == 'Science_Requirement_Subject' && formType == 2 && (
            <K6_Science_Requirement />
          )}

          {requirement == 'Social_Science_Requirement_Subject' &&
            formType == 1 && <K12_SocialScience_Requirement />}

          {requirement == 'Social_Science_Requirement_Subject' &&
            formType == 2 && <K6_SocialScience_Requirement />}

          {requirement == 'Visual_Arts_Requirement_Subject' &&
            formType == 1 && <K12_VisualAndArts_Requirement />}

          {requirement == 'Visual_Arts_Requirement_Subject' &&
            formType == 2 && <K6_VisualAndArts_Requirement />}

          {requirement == 'Health_Requirement_Subject' && formType == 1 && (
            <K12_Health_Requirement />
          )}

          {requirement == 'Health_Requirement_Subject' && formType == 2 && (
            <K6_Health_Requirement />
          )}

          {requirement == 'Foreign_Language_Requirement_Subject' &&
            formType == 1 && <K12_Foreign_Requirement />}

          {requirement == 'Foreign_Language_Requirement_Subject' &&
            formType == 2 && <K6_Foreign_Requirement />}

          {requirement == 'Physical_Education_Requirement_Subject' &&
            formType == 1 && <K12_Physical_Requirement />}

          {requirement == 'Physical_Education_Requirement_Subject' &&
            formType == 2 && <K6_Physical_Requirement />}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowWarningModel(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WarningTextModel;
