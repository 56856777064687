import { useCountdown } from 'hooks/useCountDown';
import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';

const CountDownGoogleMeet = ({ date }: any) => {
  const { formatMessage } = useIntlCustom();

  const [days, hours, minutes, seconds] = useCountdown(new Date(date));

  return (
    <div className='fs-8'>
      {days + hours + minutes + seconds <= 0 ? (
        <>{formatMessage({ id: 'COURSE.CLASS_HAS_BEEN_STARTED' })}</>
      ) : (
        <>
          {formatMessage({ id: 'COURSE.START_IN' })}: {days > 0 && days}
          {days > 0 && ' : '}
          {hours > 0 && hours}
          {hours > 0 && ' : '}
          {minutes > 0 && minutes}
          {minutes > 0 && ' : '}
          {seconds > 0 && seconds}
        </>
      )}
    </div>
  );
};

export default CountDownGoogleMeet;
