import LoaderContent from 'app/components/ContentLoader';
import { useGetQuery } from 'hooks/reactQueryHelper';
import useOperatingSystem from 'hooks/useOperatingSystem';
import { memo, useCallback, useEffect, useState } from 'react';
import { toAbsoluteUrl } from 'theme/helpers';
import OpenWaitingRoomForIOS from '../../../../components/Course/OpenWaitingRoomForIOS';

import useIntlCustom from 'hooks/useIntlCustom';
import Video from 'app/components/Video';

const InstantLiveClass = ({ courseInviteId }: any) => {
  const { formatMessage } = useIntlCustom();
  const getInstantLiveClassByCourse: any = useGetQuery(
    'getInstantLiveClassByCourse',
    `/course/getInstantLiveClassByCourse`,
    {}
  );

  const joinInstantClassQuery: any = useGetQuery(
    'joinInstantClass',
    '/instantClass/joinInstantClass'
  );

  useEffect(() => {
    getInstantLiveClassByCourse.mutateAsync({
      params: { id: courseInviteId },
    });
  }, []);

  let os = useOperatingSystem();
  const [modalOpenWaitingRoomForIOS, setModalOpenWaitingRoomForIOS] = useState({
    show: false,
    link: null,
  });

  useEffect(() => {
    if (
      joinInstantClassQuery.isSuccess &&
      joinInstantClassQuery.data?.ResultObject?.joiningURL
    ) {
      let url = joinInstantClassQuery?.data?.ResultObject?.joiningURL;
      if (os === 'iOS') {
        setModalOpenWaitingRoomForIOS((prev): any => ({
          ...prev,
          show: true,
          link: url,
        }));
      } else if (os === 'Android') {
        window.location.href = `intent:${url}#Intent;end`;
      } else {
        window.location.href = url;
      }
    }
  }, [joinInstantClassQuery?.data?.ResultObject?.joiningURL]);

  const handleJoinClass = () => {
    joinInstantClassQuery.mutate({
      params: {
        id: getInstantLiveClassByCourse?.data?.ResultObject?._id,
        courseInviteId: courseInviteId,
      },
    });
  };

  const onModalOpenWaitingRoomForIOSHide = useCallback(() => {
    setModalOpenWaitingRoomForIOS((prev): any => ({
      ...prev,
      show: false,
      link: null,
    }));
  }, []);

  const JoinClass = () => {
    return (
      <>
        <button
          type='button'
          className='btn btn-primary btn-sm rounded-pill'
          onClick={handleJoinClass}
          disabled={joinInstantClassQuery?.isLoading}
        >
          {joinInstantClassQuery?.isLoading ? (
            <>
              <span
                className='spinner-border spinner-border-sm me-2'
                role='status'
                aria-hidden='true'
              ></span>
              Loading...
            </>
          ) : (
            <>
              {formatMessage({
                id: 'COURSE.JOIN',
              })}
            </>
          )}
        </button>
      </>
    );
  };

  return (
    <div className='card card-flush shadow h-100 '>
      <LoaderContent
        loading={getInstantLiveClassByCourse?.isLoading}
        loaderType='imageSection'
      />
      {modalOpenWaitingRoomForIOS.show && (
        <OpenWaitingRoomForIOS
          show={modalOpenWaitingRoomForIOS.show}
          onHide={onModalOpenWaitingRoomForIOSHide}
          link={modalOpenWaitingRoomForIOS.link}
        />
      )}
      {getInstantLiveClassByCourse?.isSuccess &&
        getInstantLiveClassByCourse?.data?.ResultObject?.videoName && (
          <>
            <div className='card-body '>
              <h5
                className='card-title fw-bold text-gray-800 m-0 d-inline-block col-10 text-truncate'
                title={`${getInstantLiveClassByCourse?.data?.ResultObject?.videoName} `}
              >
                {`${getInstantLiveClassByCourse?.data?.ResultObject?.videoName} `}
              </h5>
              <h6 className='card-subtitle mb-2 text-muted'>
                {' '}
                {formatMessage({
                  id: 'COURSE.EARN',
                  values: {
                    param1:
                      process.env.REACT_APP_DEFAULT_INSTANT_LIVE_CLASS_POINT,
                  },
                })}{' '}
                {process.env.REACT_APP_DEFAULT_INSTANT_LIVE_CLASS_POINT}{' '}
                {formatMessage({
                  id:
                    process.env.REACT_APP_DEFAULT_INSTANT_LIVE_CLASS_POINT ===
                    '1'
                      ? 'POINT'
                      : 'COURSE.POINTs',
                })}
              </h6>

              <Video
                src={
                  getInstantLiveClassByCourse?.data?.ResultObject
                    ?.previewVideoUrl
                }
                autoPlay={'autoplay'}
                loop
                muted
                playsInline={'playsinline'}
                preload='auto'
                className=' rounded h-100'
                poster={toAbsoluteUrl('/media/icons/loader1_2.gif')}
                onHandleClick={<JoinClass />}
              />
            </div>
          </>
        )}
      {getInstantLiveClassByCourse?.isSuccess &&
        !getInstantLiveClassByCourse?.data?.ResultObject?.videoName && (
          <>
            <div className='card-body rounded'>
              <div className='relative h-100 d-flex justify-content-center align-content-around flex-wrap'>
                <div>
                  <img
                    src={toAbsoluteUrl('/media/images/NoLiveClassSchedule.jpg')}
                    className='mh-125px mh-md-300px img-fluid'
                    alt={formatMessage({
                      id: 'COURSE.LESSON_NOT_AVAILABLE_MESSAGE',
                    })}
                  />
                </div>
                <div className='text-center p-10'>
                  <h1 className='card-title'>
                    {formatMessage({
                      id: 'COURSE.INSTANT_CLASS_NOT_AVAILABLE_MESSAGE',
                    })}
                  </h1>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default memo(InstantLiveClass);
