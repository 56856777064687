import React, { useEffect } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BForm, Form } from 'react-bootstrap';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import Select from 'react-select';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import FormikErrorText from 'app/components/palette/FormikErrorText';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Class name is required'),
  nativeLanguage: Yup.array()
    .min(1, 'Please select at least 1 option.')
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
  targetLanguage: Yup.object().nullable().required('Please select at least 1'),
  languageLevel: Yup.object().nullable().required('Please select at least 1'),
  ageOfGroup: Yup.array()
    .min(1, 'Please select at least 1 option')
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),

  type: Yup.object().nullable().required('Please select.'),
  scheduleDate: Yup.array().when('type.value', {
    is: 'schedule',
    then: Yup.array().min(1, 'Please select.'),
    otherwise: Yup.array(),
  }),
});

const ageGroupOptions: any = [
  { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
  { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
  { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
];

const typeOption: any = [
  { value: 'instant', label: 'instant' },
  { value: 'schedule', label: 'schedule' },
];

const AddNewClass: React.FC = (props: any) => {
  const navigate = useNavigate();
  const getAllLanguage: any = usePostQuery(
    'getAllLanguage',
    `/language/getAllForGrid`,
    {}
  );
  const getAllLevel: any = useGetQuery('getAllLevel', `/level/getAll`, {});

  const addNewClass: any = usePostQuery('addNewClass', `/liveClass/add`, {});

  let initialValues = {
    name: '',
    ageOfGroup: [],
    targetLanguage: [],
    nativeLanguage: [],
    languageLevel: null,
    type: '',
    scheduleDate: [],
  };

  useEffect(() => {
    getAllLanguage.mutate({});
    getAllLevel.mutate({});
  }, []);

  const onSubmit = (values: any) => {
    const formData = {
      name: values?.name,
      ageOfGroup: values?.ageOfGroup,
      languageLevel: values.languageLevel.value,
      nativeLanguage: values?.nativeLanguage?.map((item: any) => item.value),
      targetLanguage: values?.targetLanguage?.value,
      type: values?.type?.value,
      scheduleDate: values?.scheduleDate,
    };
    addNewClass.mutate(formData);
  };

  useEffect(() => {
    if (addNewClass.isSuccess) {
      navigate('/class-room-new');
    }
  }, [addNewClass.isSuccess]);

  return (
    <>
      {addNewClass?.isLoading && <Loader loading={addNewClass?.isLoading} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          getFieldProps,
        }: any) => {
          return (
            <Form onSubmit={handleSubmit} className='card'>
              <div className=' card-body p-9'>
                <BForm.Group className='row mb-7'>
                  <div className='col-lg-12'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Name<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <>
                      <BForm.Control
                        placeholder='Enter class name'
                        type='text'
                        {...getFieldProps('name')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`form-control mb-3 mb-lg-0 ${
                          errors.name && touched.name
                            ? 'border border-danger'
                            : ''
                        }`}
                      />
                      {touched.name && errors.name && (
                        <BForm.Text className='text-danger'>
                          <FormikErrorText name='name' />
                        </BForm.Text>
                      )}
                    </>
                  </div>
                </BForm.Group>

                <BForm.Group className='row mb-7'>
                  <div className='col-lg-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Target language<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='targetLanguage'
                      isMulti={false}
                      component={Select}
                      options={getAllLanguage?.data?.ResultObject?.data?.map(
                        (option: any, index: any) => {
                          return {
                            ...option,
                            value: option._id,
                            label: option.Language_Name,
                          };
                        }
                      )}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      onChange={(option: any) => {
                        setFieldValue('targetLanguage', option);
                      }}
                      onBlur={handleBlur}
                      value={values.targetLanguage}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.targetLanguage && touched.targetLanguage
                                ? '#f1416c'
                                : 'grey'
                              : errors.targetLanguage && touched.targetLanguage
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.targetLanguage && touched.targetLanguage
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.targetLanguage && touched.targetLanguage
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.targetLanguage && errors.targetLanguage && (
                      <BForm.Text className='text-danger'>
                        <FormikErrorText name='targetLanguage' />
                      </BForm.Text>
                    )}
                  </div>

                  <div className='col-lg-6'>
                    <BForm.Label className='col-lg-6 fw-bold text-muted'>
                      Native language<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='nativeLanguage'
                      isMulti={true}
                      component={Select}
                      options={getAllLanguage?.data?.ResultObject?.data?.map(
                        (option: any, index: any) => {
                          return {
                            ...option,
                            value: option._id,
                            label: option.Language_Name,
                          };
                        }
                      )}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      onChange={(option: any) => {
                        setFieldValue('nativeLanguage', option);
                      }}
                      value={values.nativeLanguage}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.nativeLanguage && touched.nativeLanguage
                                ? '#f1416c'
                                : 'grey'
                              : errors.nativeLanguage && touched.nativeLanguage
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.nativeLanguage && touched.nativeLanguage
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.nativeLanguage && touched.nativeLanguage
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.nativeLanguage && errors.nativeLanguage && (
                      <BForm.Text className='text-danger'>
                        <FormikErrorText name='nativeLanguage' />
                      </BForm.Text>
                    )}
                  </div>
                </BForm.Group>

                <BForm.Group className='row mb-7'>
                  <div className='col-lg-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Language Level<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='languageLevel'
                      component={Select}
                      options={getAllLevel?.data?.map(
                        (option: any, index: any) => {
                          return {
                            ...option,
                            value: option._id,
                            label: option.Level_Name,
                          };
                        }
                      )}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      onChange={(option: any) => {
                        setFieldValue('languageLevel', option);
                      }}
                      value={values.languageLevel}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.languageLevel && touched.languageLevel
                                ? '#f1416c'
                                : 'grey'
                              : errors.languageLevel && touched.languageLevel
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.languageLevel && touched.languageLevel
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.languageLevel && touched.languageLevel
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.languageLevel && errors.languageLevel && (
                      <BForm.Text className='text-danger'>
                        <FormikErrorText name='languageLevel' />
                      </BForm.Text>
                    )}
                  </div>
                  <div className='col-lg-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Age Group<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='ageOfGroup'
                      isMulti
                      component={Select}
                      options={ageGroupOptions}
                      placeholder='Select'
                      isClearable={true}
                      classNamePrefix='select'
                      onChange={(option: any) => {
                        setFieldValue('ageOfGroup', option);
                      }}
                      onBlur={handleBlur}
                      value={values.ageOfGroup}
                      // classNames={{
                      //     control: (state: any) =>
                      //         state.isFocused ? (errors.ageOfGroup && touched.ageOfGroup ? 'border-danger' : 'border-grey-300') : (errors.ageOfGroup && touched.ageOfGroup ? 'border-danger' : 'border-grey-300'),
                      // }}

                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.ageOfGroup && touched.ageOfGroup
                                ? '#f1416c'
                                : 'grey'
                              : errors.ageOfGroup && touched.ageOfGroup
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.ageOfGroup && touched.ageOfGroup
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.ageOfGroup && touched.ageOfGroup
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {errors.ageOfGroup && touched.ageOfGroup ? (
                      <div className='text-danger'>{errors.ageOfGroup}</div>
                    ) : null}
                  </div>
                </BForm.Group>

                <BForm.Group className='row mb-7'>
                  <div className='col-lg-6'>
                    <BForm.Label className='col-lg-4 fw-bold text-muted'>
                      Type<span style={{ color: 'red' }}>*</span>
                    </BForm.Label>
                    <Field
                      name='type'
                      isMulti={false}
                      component={Select}
                      options={typeOption}
                      placeholder='Select an option'
                      isClearable={true}
                      classNamePrefix='select'
                      onChange={(option: any) => {
                        setFieldValue('type', option);
                        setFieldValue('scheduleDate', []);
                      }}
                      onBlur={handleBlur}
                      value={values.type}
                      styles={{
                        control: (baseStyles: any, state: any) => {
                          return {
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? errors.type && touched.type
                                ? '#f1416c'
                                : 'grey'
                              : errors.type && touched.type
                              ? '#f1416c'
                              : '#b5b5c3',
                            boxShadow: '#b5b5c3',
                            '&:hover': {
                              borderColor:
                                errors.type && touched.type
                                  ? '#f1416c'
                                  : 'none',
                            },
                            '&:focus': {
                              borderColor:
                                errors.type && touched.type
                                  ? '#f1416c'
                                  : 'none',
                            },
                          };
                        },
                      }}
                    />
                    {touched.type && errors.type && (
                      <BForm.Text className='text-danger'>
                        <FormikErrorText name='type' />
                      </BForm.Text>
                    )}
                  </div>
                  {values?.type?.value === 'schedule' && (
                    <div className='col-lg-6'>
                      <BForm.Label className='col-lg-4 fw-bold text-muted'>
                        schedule Date<span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <Field
                        name='scheduleDate'
                        component={DatePicker}
                        format='MM/DD/YYYY HH:mm:ss'
                        placeholder='Select'
                        multiple
                        onChange={(option: any) => {
                          setFieldValue('scheduleDate', option);
                        }}
                        onBlur={(option: any) => {
                          setFieldValue('scheduleDate', option);
                        }}
                        plugins={[
                          <DatePanel />,
                          <TimePicker position='bottom' />,
                        ]}
                        // onBlur={handleBlur}
                        style={{
                          width: '100%',
                          boxSizing: 'border-box',
                          height: '40px',
                          padding: '10px',
                          borderColor:
                            errors.scheduleDate && touched.scheduleDate
                              ? '#f1416c'
                              : '',
                        }}
                        // className="w-full p-3 rounded text-sm  outline-none  focus:ring-0 "
                        containerStyle={{
                          width: '100%',
                        }}
                        calendarPosition='bottom-center'
                        // className="rmdp-mobile"
                        // portal
                        // inputClass="custom-datePicker"
                      />

                      {touched.scheduleDate && errors.scheduleDate && (
                        <BForm.Text className='text-danger'>
                          <FormikErrorText name='scheduleDate' />
                        </BForm.Text>
                      )}
                    </div>
                  )}
                </BForm.Group>
                <Button type='submit'>Add New Class</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNewClass;
