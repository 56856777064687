import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import HeaderPage from '../activityheader/HeaderActivityPage';
import FooterActivityPage from '../activityfooter/FooterActivityPage';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { aiActivityShowText, aiImageGenerate } from 'store/actions';
import { aiActivityImageSave } from 'store/aiactivityimage/actions';

const PickWordActivity: React.FC<any> = (props: any) => {
  const {
    singleLessonData,
    nativeLang,
    targetLang,
    sectionType,
    setSectionType,
    setTapITActivityShow,
    setPickWordActivityShow,
    setVideoSectionShow,
    setWriteITActivityShow,
    Lesson_ID,
    aiActivityDefaultText,
  } = props;

  var blank: any = 0;
  let sentence1: any = '';
  let sentence2: any = '';
  const dispatch = useDispatch();
  const [fullSentance, setFullSentance] = useState('');
  const [fullSentance1, setFullSentance1] = useState('');
  const [fullSentance2, setFullSentance2] = useState('');
  const [optionSelectError, setOptionSelectError] = useState(false);
  const [option1Value, setOption1Value] = useState<any>('');
  const [option2Value, setOption2Value] = useState<any>('');
  const [indexValue, setIndexValue] = useState<any>(0);
  const [userID, setUserID] = useState('');

  var data = singleLessonData?.Activity_Details?.filter(
    (x: any) => x.Section == sectionType
  )[0];
  const { aiImageUrlloading, aiImageError, aiImageSuccess, aiImageUrl } =
    useSelector((state: any) => state.openai);

  let localStorageData: any = localStorage.getItem('kt-auth-react-v');

  useEffect(() => {
    const uID = JSON.parse(localStorageData);
    setUserID(uID.data.userID);
  }, [localStorageData]);

  useEffect(() => {
    setFullSentance1(sentence1);
  }, [sentence1]);

  useEffect(() => {
    setFullSentance2(sentence2);
  }, [sentence2]);

  const onPreviousButton = () => {
    setVideoSectionShow(true);
    setPickWordActivityShow(false);
  };

  const onHandleSubmit = async (event: any) => {
    if (fullSentance1.includes('_') || fullSentance2.includes('_')) {
      setOptionSelectError(true);
    } else {
      setOptionSelectError(false);
      console.log(option1Value, option2Value);

      dispatch(
        aiActivityImageSave({
          User_ID: userID,
          Lesson_ID: Lesson_ID,
          Sentance: fullSentance1 + '||' + fullSentance2,
          AI_Activity_Image: aiImageUrl,
        })
      );

      const AI_Tap_IT_Answer_Data1_Native = {
        Language_Code: nativeLang,
        Option1: option1Value?.NativeLanguageData?.FakeOptionName,
        Option2: option1Value?.NativeLanguageData?.OptionName,
        Option3: option2Value?.NativeLanguageData?.OptionName,
        Option4: option2Value?.NativeLanguageData?.FakeOptionName,
        Audio_Option1: option1Value?.NativeLanguageData?.Audio_FakeOptionName,
        Audio_Option2: option1Value?.NativeLanguageData?.Audio_OptionName,
        Audio_Option3: option2Value?.NativeLanguageData?.Audio_OptionName,
        Audio_Option4: option2Value?.NativeLanguageData?.Audio_FakeOptionName,
        Option1_Roman_Language:
          option1Value?.NativeLanguageData?.FakeOptionName_Roman_Language,
        Option2_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
        Option3_Roman_Language:
          option2Value?.NativeLanguageData?.OptionName_Roman_Language,
        Option4_Roman_Language:
          option2Value?.NativeLanguageData?.FakeOptionName_Roman_Language,
      };

      let AI_Tap_IT_Answer_Data1_Native_New_Value = await onRandomOption(
        AI_Tap_IT_Answer_Data1_Native
      );
      let OptionsAnswerNativeTapIT1 = await onGetOption(
        AI_Tap_IT_Answer_Data1_Native_New_Value,
        option1Value?.NativeLanguageData?.OptionName
      );

      const AI_Tap_IT_Questions_Data1_Native = {
        Language_Code: nativeLang,
        Answer: OptionsAnswerNativeTapIT1,
        Image: option1Value?.NativeLanguageData?.OptionImage,
        Title: option1Value?.NativeLanguageData?.OptionName,
        Audio_Title: option1Value?.NativeLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Tap_IT_Answer_Data1_Target = {
        Language_Code: targetLang,
        Option1: option1Value?.TargetLanguageData?.FakeOptionName,
        Option2: option1Value?.TargetLanguageData?.OptionName,
        Option3: option2Value?.TargetLanguageData?.OptionName,
        Option4: option2Value?.TargetLanguageData?.FakeOptionName,
        Audio_Option1: option1Value?.TargetLanguageData?.Audio_FakeOptionName,
        Audio_Option2: option1Value?.TargetLanguageData?.Audio_OptionName,
        Audio_Option3: option2Value?.TargetLanguageData?.Audio_OptionName,
        Audio_Option4: option2Value?.TargetLanguageData?.Audio_FakeOptionName,
        Option1_Roman_Language:
          option1Value?.TargetLanguageData?.FakeOptionName_Roman_Language,
        Option2_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
        Option3_Roman_Language:
          option2Value?.TargetLanguageData?.OptionName_Roman_Language,
        Option4_Roman_Language:
          option2Value?.TargetLanguageData?.FakeOptionName_Roman_Language,
      };

      let AI_Tap_IT_Answer_Data1_Target_New_Value = await onRandomOption(
        AI_Tap_IT_Answer_Data1_Target
      );
      let OptionsAnswerTargetTapIT1 = await onGetOption(
        AI_Tap_IT_Answer_Data1_Target_New_Value,
        option1Value?.TargetLanguageData?.OptionName
      );

      const AI_Tap_IT_Questions_Data1_Target = {
        Language_Code: targetLang,
        Answer: OptionsAnswerTargetTapIT1,
        Image: option1Value?.TargetLanguageData?.OptionImage,
        Title: option1Value?.TargetLanguageData?.OptionImage,
        Audio_Title: option1Value?.TargetLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Tap_IT_Answer_Data2_Native = {
        Language_Code: nativeLang,
        Option1: option1Value?.NativeLanguageData?.FakeOptionName,
        Option2: option1Value?.NativeLanguageData?.OptionName,
        Option3: option2Value?.NativeLanguageData?.OptionName,
        Option4: option2Value?.NativeLanguageData?.FakeOptionName,
        Audio_Option1: option1Value?.NativeLanguageData?.Audio_FakeOptionName,
        Audio_Option2: option1Value?.NativeLanguageData?.Audio_OptionName,
        Audio_Option3: option2Value?.NativeLanguageData?.Audio_OptionName,
        Audio_Option4: option2Value?.NativeLanguageData?.Audio_FakeOptionName,
        Option1_Roman_Language:
          option1Value?.NativeLanguageData?.FakeOptionName_Roman_Language,
        Option2_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
        Option3_Roman_Language:
          option2Value?.NativeLanguageData?.OptionName_Roman_Language,
        Option4_Roman_Language:
          option2Value?.NativeLanguageData?.FakeOptionName_Roman_Language,
      };

      let AI_Tap_IT_Answer_Data2_Native_New_Value = await onRandomOption(
        AI_Tap_IT_Answer_Data2_Native
      );
      let OptionsAnswerNativeTapIT2 = await onGetOption(
        AI_Tap_IT_Answer_Data2_Native_New_Value,
        option2Value?.NativeLanguageData?.OptionName
      );
      const AI_Tap_IT_Questions_Data2_Native = {
        Language_Code: nativeLang,
        Answer: OptionsAnswerNativeTapIT2,
        Image: option2Value?.NativeLanguageData?.OptionImage,
        Title: option2Value?.NativeLanguageData?.OptionName,
        Audio_Title: option2Value?.NativeLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option2Value?.NativeLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Tap_IT_Answer_Data2_Target = {
        Language_Code: targetLang,
        Option1: option1Value?.TargetLanguageData?.FakeOptionName,
        Option2: option1Value?.TargetLanguageData?.OptionName,
        Option3: option2Value?.TargetLanguageData?.OptionName,
        Option4: option2Value?.TargetLanguageData?.FakeOptionName,
        Audio_Option1: option1Value?.TargetLanguageData?.Audio_FakeOptionName,
        Audio_Option2: option1Value?.TargetLanguageData?.Audio_OptionName,
        Audio_Option3: option2Value?.TargetLanguageData?.Audio_OptionName,
        Audio_Option4: option2Value?.TargetLanguageData?.Audio_FakeOptionName,
        Option1_Roman_Language:
          option1Value?.TargetLanguageData?.FakeOptionName_Roman_Language,
        Option2_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
        Option3_Roman_Language:
          option2Value?.TargetLanguageData?.OptionName_Roman_Language,
        Option4_Roman_Language:
          option2Value?.TargetLanguageData?.FakeOptionName_Roman_Language,
      };

      let AI_Tap_IT_Answer_Data2_Target_New_Value = await onRandomOption(
        AI_Tap_IT_Answer_Data2_Target
      );
      let OptionsAnswerTargetTapIT2 = await onGetOption(
        AI_Tap_IT_Answer_Data2_Target_New_Value,
        option2Value?.TargetLanguageData?.OptionName
      );
      const AI_Tap_IT_Questions_Data2_Target = {
        Language_Code: targetLang,
        Answer: OptionsAnswerTargetTapIT2,
        Image: option2Value?.TargetLanguageData?.OptionImage,
        Audio_Title: option2Value?.TargetLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option2Value?.TargetLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Tap_IT_Questions_Values = [
        {
          Question_ID:
            Date.now().toString(36) + Math.random().toString(36).substr(2),
          Answer: {
            Language: [
              AI_Tap_IT_Answer_Data1_Native_New_Value,
              AI_Tap_IT_Answer_Data1_Target_New_Value,
            ],
          },
          Question: {
            Language: [
              AI_Tap_IT_Questions_Data1_Native,
              AI_Tap_IT_Questions_Data1_Target,
            ],
          },
        },
        {
          Question_ID:
            Date.now().toString(36) + Math.random().toString(36).substr(2),
          Answer: {
            Language: [
              AI_Tap_IT_Answer_Data2_Native_New_Value,
              AI_Tap_IT_Answer_Data2_Target_New_Value,
            ],
          },
          Question: {
            Language: [
              AI_Tap_IT_Questions_Data2_Native,
              AI_Tap_IT_Questions_Data2_Target,
            ],
          },
        },
      ];

      const AI_Say_IT_Answer_Data1_Target = {
        Language_Code: targetLang,
        Answer: option1Value?.TargetLanguageData?.OptionName,
        Audio_Answer: option1Value?.TargetLanguageData?.Audio_OptionName,
        Answer_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
      };
      const AI_Say_IT_Answer_Data1_Native = {
        Language_Code: nativeLang,
        Answer: option1Value?.NativeLanguageData?.OptionName,
        Audio_Answer: option1Value?.NativeLanguageData?.Audio_OptionName,
        Answer_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
      };

      const AI_Say_IT_Questions_Target1 = {
        Language_Code: targetLang,
        Image: option1Value?.TargetLanguageData?.OptionImage,
        Title: option1Value?.TargetLanguageData?.OptionName,
        Audio_Title: option1Value?.TargetLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Say_IT_Questions_Native1 = {
        Language_Code: nativeLang,
        Image: option1Value?.NativeLanguageData?.OptionImage,
        Title: option1Value?.NativeLanguageData?.OptionName,
        Audio_Title: option1Value?.NativeLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Say_IT_Answer_Data2_Target = {
        Language_Code: targetLang,
        Answer: option2Value?.TargetLanguageData?.OptionName,
        Audio_Answer: option2Value?.TargetLanguageData?.Audio_OptionName,
        Answer_Roman_Language:
          option2Value?.TargetLanguageData?.OptionName_Roman_Language,
      };
      const AI_Say_IT_Answer_Data2_Native = {
        Language_Code: nativeLang,
        Answer: option2Value?.NativeLanguageData?.OptionName,
        Audio_Answer: option2Value?.NativeLanguageData?.Audio_OptionName,
        Answer_Roman_Language:
          option2Value?.NativeLanguageData?.OptionName_Roman_Language,
      };

      const AI_Say_IT_Questions_Targe2 = {
        Language_Code: targetLang,
        Image: option2Value?.TargetLanguageData?.OptionImage,
        Title: option2Value?.TargetLanguageData?.OptionName,
        Audio_Title: option2Value?.TargetLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option2Value?.TargetLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Say_IT_Questions_Native2 = {
        Language_Code: nativeLang,
        Image: option2Value?.NativeLanguageData?.OptionImage,
        Title: option2Value?.NativeLanguageData?.OptionName,
        Audio_Title: option2Value?.NativeLanguageData?.Audio_OptionName,
        Title_Roman_Language:
          option2Value?.NativeLanguageData?.OptionName_Roman_Language,
        Type: '',
        Description: '',
        Type_Roman_Language: '',
        Description_Roman_Language: '',
      };

      const AI_Say_IT_Questions_Values = [
        {
          Question_ID:
            Date.now().toString(36) + Math.random().toString(36).substr(2),
          Answer: {
            Language: [
              AI_Say_IT_Answer_Data1_Target,
              AI_Say_IT_Answer_Data1_Native,
            ],
          },
          Question: {
            Language: [
              AI_Say_IT_Questions_Target1,
              AI_Say_IT_Questions_Native1,
            ],
          },
        },
        {
          Question_ID:
            Date.now().toString(36) + Math.random().toString(36).substr(2),
          Answer: {
            Language: [
              AI_Say_IT_Answer_Data2_Target,
              AI_Say_IT_Answer_Data2_Native,
            ],
          },
          Question: {
            Language: [AI_Say_IT_Questions_Targe2, AI_Say_IT_Questions_Native2],
          },
        },
      ];

      const AI_Listen_IT_Answer_Data1_Native = {
        Language_Code: nativeLang,
        Option1: option1Value?.NativeLanguageData?.FakeOptionName,
        Option2: option2Value?.NativeLanguageData?.FakeOptionName,
        Option3: option2Value?.NativeLanguageData?.OptionName,
        Option4: option1Value?.NativeLanguageData?.OptionName,
        Audio_Option1: option1Value?.NativeLanguageData?.Audio_FakeOptionName,
        Audio_Option2: option2Value?.NativeLanguageData?.Audio_FakeOptionName,
        Audio_Option3: option2Value?.NativeLanguageData?.Audio_OptionName,
        Audio_Option4: option1Value?.NativeLanguageData?.Audio_OptionName,
        Option1_Roman_Language:
          option1Value?.NativeLanguageData?.FakeOptionName_Roman_Language,
        Option2_Roman_Language:
          option2Value?.NativeLanguageData?.FakeOptionName_Roman_Language,
        Option3_Roman_Language:
          option2Value?.NativeLanguageData?.OptionName_Roman_Language,
        Option4_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
      };

      const AI_Listen_IT_Questions_Data1_Native = {
        Language_Code: nativeLang,
        Answer: 'option4',
        AudioText: option1Value?.NativeLanguageData?.OptionName,
        AudioText_Audio: option1Value?.NativeLanguageData?.Audio_OptionName,
        AudioText_Roman_Language:
          option1Value?.NativeLanguageData?.OptionName_Roman_Language,
      };

      const AI_Listen_IT_Answer_Data1_Target = {
        Language_Code: targetLang,
        Option1: option1Value?.TargetLanguageData?.FakeOptionName,
        Option2: option2Value?.TargetLanguageData?.FakeOptionName,
        Option3: option2Value?.TargetLanguageData?.OptionName,
        Option4: option1Value?.TargetLanguageData?.OptionName,
        Audio_Option1: option1Value?.TargetLanguageData?.Audio_FakeOptionName,
        Audio_Option2: option2Value?.TargetLanguageData?.Audio_FakeOptionName,
        Audio_Option3: option2Value?.TargetLanguageData?.Audio_OptionName,
        Audio_Option4: option1Value?.TargetLanguageData?.Audio_OptionName,
        Option1_Roman_Language:
          option1Value?.TargetLanguageData?.FakeOptionName_Roman_Language,
        Option2_Roman_Language:
          option2Value?.TargetLanguageData?.FakeOptionName_Roman_Language,
        Option3_Roman_Language:
          option2Value?.TargetLanguageData?.OptionName_Roman_Language,
        Option4_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
      };

      const AI_Listen_IT_Questions_Data1_Target = {
        Language_Code: targetLang,
        Answer: 'option4',
        AudioText: option1Value?.TargetLanguageData?.OptionName,
        AudioText_Audio: option1Value?.TargetLanguageData?.Audio_OptionName,
        AudioText_Roman_Language:
          option1Value?.TargetLanguageData?.OptionName_Roman_Language,
      };

      const AI_Listen_IT_Questions_Values = [
        {
          Question_ID:
            Date.now().toString(36) + Math.random().toString(36).substr(2),
          Answer: {
            Language: [
              AI_Listen_IT_Answer_Data1_Native,
              AI_Listen_IT_Answer_Data1_Target,
            ],
          },
          Question: {
            Language: [
              AI_Listen_IT_Questions_Data1_Native,
              AI_Listen_IT_Questions_Data1_Target,
            ],
          },
        },
      ];

      const Tap_IT = {
        Questions: AI_Tap_IT_Questions_Values,
      };

      const Say_IT = {
        Questions: AI_Say_IT_Questions_Values,
      };

      const Listen_IT = {
        Questions: AI_Listen_IT_Questions_Values,
      };

      const lessonIndex = singleLessonData?.Activity_Details.findIndex(
        (item: any) => item.Section === 'AISection'
      );
      singleLessonData.Activity_Details[lessonIndex] = {
        ...singleLessonData.Activity_Details[lessonIndex],
        Tap_IT,
        Say_IT,
        Listen_IT,
      };

      setTimeout(() => {
        setPickWordActivityShow(false);
        setTapITActivityShow(true);
        // dispatch(aiImageGenerate({ Sentance: fullSentance, AIImageUrl: aiImageUrl }))
        // dispatch(aiActivityShowText(fullSentance))
        // dispatch(
        //   aiActivityImageSave({
        //     User_ID: userID,
        //     Lesson_ID: Lesson_ID,
        //     Sentance: fullSentance1 + '||' + fullSentance2,
        //     AI_Activity_Image: aiImageUrl,
        //   })
        // );
      }, 500);
    }
  };

  const onHandleOption1 = (event: any, index: any) => {
    setOption1Value(JSON.parse(event.target.value));
    const updatedDropdownValues = fullSentance1.split(' ');
    const findIndex = event.target.selectedIndex;
    updatedDropdownValues[index] =
      event.target.options[findIndex].getAttribute('data-name');
    // const indexData: any = updatedDropdownValues[index].split(" ");
    // const checkLength = indexData.length;
    // if (checkLength > 1) {
    //     const indexValueGet = checkLength - 1
    //     setIndexValue(indexValue + indexValueGet)
    // }
    const updateSentanceValue = updatedDropdownValues.join(' ');
    setFullSentance1(updateSentanceValue);
  };

  const onHandleOption2 = (event: any, index: any) => {
    setOption2Value(JSON.parse(event.target.value));
    const updatedDropdownValues = fullSentance2.split(' ');
    const findIndex = event.target.selectedIndex;
    // const indexValueset = index + indexValue
    updatedDropdownValues[index] =
      event.target.options[findIndex].getAttribute('data-name');
    const updateSentanceValue = updatedDropdownValues.join(' ');
    setFullSentance2(updateSentanceValue);
  };

  const onRandomOption = (data: any) => {
    return new Promise(async (resolve, reject) => {
      const options = [
        {
          Option: data.Option1,
          Audio_Option: data.Audio_Option1,
          Option_Roman_Language: data.Option1_Roman_Language,
        },
        {
          Option: data.Option2,
          Audio_Option: data.Audio_Option2,
          Option_Roman_Language: data.Option2_Roman_Language,
        },
        {
          Option: data.Option3,
          Audio_Option: data.Audio_Option3,
          Option_Roman_Language: data.Option3_Roman_Language,
        },
        {
          Option: data.Option4,
          Audio_Option: data.Audio_Option4,
          Option_Roman_Language: data.Option4_Roman_Language,
        },
      ];

      for (let i = options.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [options[i], options[j]] = [options[j], options[i]];
      }

      data.Option1 = options[0].Option;
      data.Option2 = options[1].Option;
      data.Option3 = options[2].Option;
      data.Option4 = options[3].Option;

      data.Audio_Option1 = options[0].Audio_Option;
      data.Audio_Option2 = options[1].Audio_Option;
      data.Audio_Option3 = options[2].Audio_Option;
      data.Audio_Option4 = options[3].Audio_Option;

      data.Option1_Roman_Language = options[0].Option_Roman_Language;
      data.Option2_Roman_Language = options[1].Option_Roman_Language;
      data.Option3_Roman_Language = options[2].Option_Roman_Language;
      data.Option4_Roman_Language = options[3].Option_Roman_Language;

      resolve(data);
    });
  };

  const onGetOption = (data: any, optionGetValue: any) => {
    return new Promise(async (resolve, reject) => {
      let selectedOption;
      for (let i = 1; i <= 4; i++) {
        const optionKey = `Option${i}`;
        const optionValue = data[optionKey];
        if (optionValue === optionGetValue) {
          selectedOption = optionKey;
          break;
        }
      }
      resolve(selectedOption?.toLowerCase());
    });
  };

  return (
    <>
      {data?.Pick_Word?.Questions?.map((key: any, index: any) => {
        let langQuestionDataNative = key?.Question?.Language?.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
        let langQuestionDataTarget = key?.Question?.Language?.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
        let langAnswerDataTarget = key?.Answer?.Language?.filter(
          (x: any) => x.Language_Code == targetLang
        )[0];
        let langAnswerDataNative = key?.Answer?.Language?.filter(
          (x: any) => x.Language_Code == nativeLang
        )[0];
        let questionsAnswer = langQuestionDataNative?.Answer;
        sentence1 = langQuestionDataNative?.Question_Sentence1;
        sentence2 = langQuestionDataNative?.Question_Sentence2;
        return (
          <>
            <div className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'>
              <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
                <div
                  className='container pick-word-container'
                  style={{
                    backgroundImage: `url("${data?.AI_Background_Image_Slide2}")`,
                  }}
                >
                  <div
                    className='row'
                    style={{ position: 'absolute', top: 0, width: '100%' }}
                  >
                    <div className='col-12'>
                      <div className='w-100'>
                        <div className='progress-bar mt-3'>
                          <ProgressBar animated now={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row w-100 text-center'>
                    <div className='col-12'>
                      <div
                        className='card'
                        style={{ backgroundColor: 'rgb(255 255 218)' }}
                      >
                        <div className='card-body'>
                          <h2 className='text-start'>
                            {aiActivityDefaultText?.Step1} :
                          </h2>
                          <h3
                            style={{
                              lineHeight: '1.5',
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            {sentence1 &&
                              sentence1
                                .split(' ')
                                .map((word: any, index: any) => {
                                  if (word.includes('_')) {
                                    // blank = blank + 1
                                    return (
                                      <>
                                        {/* {blank == 1 && */}
                                        <select
                                          // className="pick-word-custom-dropdown form-control"
                                          className='pick-word-custom-dropdown form-select'
                                          style={{
                                            marginRight: '5px',
                                            marginLeft: '5px',
                                          }}
                                          onChange={() => {
                                            onHandleOption1(event, index);
                                          }}
                                        >
                                          <option
                                            selected
                                            disabled
                                            hidden
                                          ></option>
                                          {langAnswerDataNative?.Option1.map(
                                            (optionData: any, index: any) => {
                                              const TargetLanguageData =
                                                langAnswerDataTarget.Option1[
                                                  index
                                                ];
                                              const NativeLanguageData =
                                                optionData;
                                              const optionValue = {
                                                TargetLanguageData:
                                                  TargetLanguageData,
                                                NativeLanguageData:
                                                  NativeLanguageData,
                                              };
                                              return (
                                                <option
                                                  value={JSON.stringify(
                                                    optionValue
                                                  )}
                                                  data-name={
                                                    optionData.OptionName
                                                  }
                                                >
                                                  {optionData.OptionName}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        {/* } */}
                                        {/* {blank == 2 &&
                                                                                    <select
                                                                                        className="pick-word-custom-dropdown form-select"
                                                                                        style={{ marginRight: "5px", marginLeft: "5px" }}
                                                                                        onChange={() => { onHandleOption2(event, index) }}
                                                                                    >
                                                                                        <option selected disabled hidden></option>
                                                                                        {langAnswerDataNative?.Option2.map((optionData: any, index: any) => {
                                                                                            const TargetLanguageData = langAnswerDataTarget.Option2[index]
                                                                                            const NativeLanguageData = optionData
                                                                                            const optionValue = {
                                                                                                TargetLanguageData: TargetLanguageData,
                                                                                                NativeLanguageData: NativeLanguageData
                                                                                            }
                                                                                            return (
                                                                                                <option value={JSON.stringify(optionValue)} data-name={optionData.OptionName}>{optionData.OptionName}</option>
                                                                                            )
                                                                                        })}
                                                                                    </select>
                                                                                } */}
                                      </>
                                    );
                                  } else {
                                    return (
                                      <span
                                        key={index}
                                        style={{ marginRight: '5px' }}
                                      >
                                        {word}{' '}
                                      </span>
                                    );
                                  }
                                })}
                          </h3>
                          <h2 className='text-start'>
                            {aiActivityDefaultText?.Step2} :
                          </h2>
                          <h3
                            style={{
                              lineHeight: '1.5',
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            {sentence2 &&
                              sentence2
                                .split(' ')
                                .map((word: any, index: any) => {
                                  if (word.includes('_')) {
                                    return (
                                      <>
                                        <select
                                          className='pick-word-custom-dropdown form-select'
                                          style={{
                                            marginRight: '5px',
                                            marginLeft: '5px',
                                          }}
                                          onChange={() => {
                                            onHandleOption2(event, index);
                                          }}
                                        >
                                          <option
                                            selected
                                            disabled
                                            hidden
                                          ></option>
                                          {langAnswerDataNative?.Option2.map(
                                            (optionData: any, index: any) => {
                                              const TargetLanguageData =
                                                langAnswerDataTarget.Option2[
                                                  index
                                                ];
                                              const NativeLanguageData =
                                                optionData;
                                              const optionValue = {
                                                TargetLanguageData:
                                                  TargetLanguageData,
                                                NativeLanguageData:
                                                  NativeLanguageData,
                                              };
                                              return (
                                                <option
                                                  value={JSON.stringify(
                                                    optionValue
                                                  )}
                                                  data-name={
                                                    optionData.OptionName
                                                  }
                                                >
                                                  {optionData.OptionName}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <span
                                        key={index}
                                        style={{ marginRight: '5px' }}
                                      >
                                        {word}{' '}
                                      </span>
                                    );
                                  }
                                })}
                          </h3>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div
                          className='mt-3 rounded'
                          style={{
                            backgroundColor: '#9dbcff',
                            textAlign: 'left',
                          }}
                        >
                          <h6 className='mt-2 px-2 py-2'>
                            <b>Nota:</b>{' '}
                            <i>*La foto puede tardar 24 horas en procesarse </i>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {optionSelectError && (
                      <p style={{ color: 'red' }}>Please select option</p>
                    )}
                  </div>
                </div>
                <FooterActivityPage
                  nextButton={true}
                  onPreviousButton={onPreviousButton}
                  previousButton={sectionType != 'QuizSection' ? true : false}
                  urlHash={key?.Question_ID || undefined}
                  lesson={singleLessonData?._id || undefined}
                  handleVideoNextButton={onHandleSubmit}
                />
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default PickWordActivity;
