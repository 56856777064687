import React from 'react';

type Props = {
  deleteModel: boolean;
  handleDeleteRecord: any;
  handleCloseDeleteModel: any;
};

const StudentDeleteModel: React.FC<Props> = (props: any) => {
  const { deleteModel, handleDeleteRecord, handleCloseDeleteModel } = props;

  return (
    <div
      className={deleteModel ? 'modal fade show d-block' : 'modal fade'}
      id='Delete_Modal_scrollable'
    >
      <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {' '}
              Delete <label id=''></label>
            </h5>
            {/* begin::Close */}
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <span className='svg-icon svg-icon-2x'></span>
            </div>
            {/* end::Close */}
          </div>
          <div className='modal-body'>
            <p>Are you sure you want to delete?</p>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              onClick={handleCloseDeleteModel}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleDeleteRecord}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDeleteModel;
