import { useFormik } from 'formik';
import { getAxiosInstance } from 'helpers/api-helper';
import React, { FC, useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import { IoCloseSharp, IoPlay } from 'react-icons/io5';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { FcCheckmark } from 'react-icons/fc';
import { BiErrorCircle } from 'react-icons/bi';
import CompleteProfile from 'theme/partials/modals/complete-profile/CompleteProfile';
import { useAuth } from 'app/modules/auth';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import useIntlCustom from 'hooks/useIntlCustom';
import ResultFooterPage from '../activityfooter/ResultFooterPage';
import { clearLesson } from 'store/actions';
import { useDispatch } from 'react-redux';

// import { refreshPage } from 'store/actions';

const LessonResult: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const {
    currentUser,
    learningStreakDaysUpdated,
    setLearningStreakDaysUpdated,
  } = useAuth();

  const { formatMessage } = useIntlCustom();

  const {
    resultAnswerCount,
    currentLesson,
    setResultShow,
    setVideoSectionShow,
    setSectionIndex,
    setResultAnswerCount,
    setQuestionResultList,
    questionResultList,
    setQuizQuestionCount,
    percentageHide,
    setPercentageHide,
    onRefresh,
    refreshData,
  } = props;

  const completedLessonQuery: any = usePostQuery(
    'completedLesson',
    '/lessons/completedLesson',
    {}
  );

  const lessonId = currentLesson?._id;
  let { id, courseInviteId } = useParams();

  useEffect(() => {
    if (
      (currentUser && !currentUser?.UserId?.FirstName) ||
      !currentUser?.UserId?.LastName ||
      !currentUser?.UserId?.Email ||
      !currentUser?.BirthYear ||
      !currentUser?.Native_Language?._id ||
      !currentUser?.Country?._id
    ) {
      setUpdateProfileShow(true);
    }
  }, []);

  useEffect(() => {
    if (completedLessonQuery.isSuccess && !learningStreakDaysUpdated) {
      setLearningStreakDaysUpdated(true);
    }
  }, [completedLessonQuery.isSuccess]);

  useEffect(() => {
    if (lessonId) {
      (async () => {
        await completedLessonQuery.mutateAsync({
          courseInviteId: courseInviteId,
          lessonId: id,
          correctAnswers: questionResultList?.filter(
            (item: any) => item?.Answer === true
          )?.length,
          totalQuestions: questionResultList?.length,
          StudentId: currentUser?._id,
          UserId: currentUser?.UserId?._id,
        });
      })();
    }
  }, [lessonId]);

  const colors = {
    orange: '#FFBA5A',
    grey: '#a9a9a9',
  };

  const star = Array(5).fill(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [commentHideShow, setCommentHideShow] = useState(false);
  const [updateProfileShow, setUpdateProfileShow] = useState(false);

  const handleClick = (value: any) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue: any) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const feedbackMutation = useMutation({
    mutationFn: ({ comment, stars }: any) =>
      getAxiosInstance().post('/feedbacks', {
        comment,
        lessonId,
        stars,
        courseInviteId,
      }),
    onSuccess: (data) => {
      setCommentHideShow(true);
      toast.dismiss();
      toast.success(data.data.message, { autoClose: 4000 });
    },
    onError: (data: any) => {
      toast.dismiss();
      toast.error(data.response.data.message, { autoClose: 4000 });
    },
  });

  const { errors, handleSubmit, getFieldProps, setFieldValue } = useFormik({
    initialValues: {
      comment: '',
      stars: 0,
    },
    validationSchema: Yup.object({
      stars: Yup.number().notRequired(),
      comment: Yup.string().required('Please enter comment'),
    }),
    onSubmit: (values) => {
      feedbackMutation.mutate(values);
    },
  });

  const onRedo = () => {
    setVideoSectionShow(true);
    setResultShow(false);
    setSectionIndex(0);
    setResultAnswerCount(0);
    setQuestionResultList([]);
    setQuizQuestionCount(1);
    setPercentageHide(false);
  };

  const onContinue = () => {
    dispatch(clearLesson());
    setResultAnswerCount(0);
    setQuestionResultList([]);
    setQuizQuestionCount(1);
    setPercentageHide(false);
  };

  return (
    <>
      {updateProfileShow && (
        <CompleteProfile
          show={updateProfileShow}
          onHide={() => setUpdateProfileShow(false)}
        />
      )}
      <div className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'>
        <div className='start-lesson-main position-relative start_lesson_main_mobile_video bg-white'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='w-100 position-relative d-flex flex-column'>
                  <div className='row d-grid gap-3'>
                    <div className='col-12 mx-auto'>
                      <div className='w-100 z-10 d-grid gap-1 text-black'>
                        {/* <form onSubmit={handleSubmit}>
                          <div className='grammar-box mt-2'>
                            <div className='grammar-text-box mt-1'>
                              {!percentageHide && (
                                <>
                                  <h1 className='text-center fw-bold'>
                                    Quiz Results
                                  </h1>
                                  <h1 className='text-center'>
                                    {resultAnswerCount == 1
                                      ? '20%'
                                      : resultAnswerCount == 2
                                      ? '40%'
                                      : resultAnswerCount == 3
                                      ? '60%'
                                      : resultAnswerCount == 4
                                      ? '80%'
                                      : resultAnswerCount == 5
                                      ? '100%'
                                      : '0%'}
                                  </h1>
                                </>
                              )}
                              <div
                                className='d-block clearfix'
                                style={{
                                  paddingTop: `${
                                    !percentageHide ? '0%' : '35%'
                                  }`,
                                }}
                              >
                                <div
                                  className='btn btn-primary btn-sm float-start'
                                  onClick={() => window.location.reload()}
                                >
                                  Volver a hacer la lección
                                </div>
                                <Link
                                  className='btn btn-primary btn-sm float-end'
                                  to={`/course/${courseInviteId}/student-course-details`}
                                  onClick={() => {
                                    onContinue();
                                  }}
                                >
                                  Continuar{' '}
                                  <BsArrowRightCircleFill
                                    size={18}
                                    style={{ marginLeft: '5px' }}
                                  />
                                </Link>
                              </div>
                              <div className='mt-2'>
                                <ul className='list-group'>
                                  {questionResultList.map((result: any) => {
                                    return (
                                      <>
                                        <li className='list-group-item text-center fw-bold'>
                                          {result.Question} :{' '}
                                          <span className='ml-3'>
                                            {result.Answer ? (
                                              <FcCheckmark size={20} />
                                            ) : (
                                              <IoCloseSharp
                                                size={20}
                                                color='red'
                                              />
                                            )}
                                          </span>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                              {!commentHideShow && (
                                <div className='mt-2 bg-secondary p-5 rounded-3'>
                                  <h2 className='text-left'>
                                    Mejora nuestra lecciones
                                  </h2>
                                  <div className='d-block clearfix rating-container d-flex'>
                                    <h5
                                      className='text-center mt-3'
                                      style={{ marginRight: '10px' }}
                                    >
                                      Calificación
                                    </h5>
                                    <h2 className='text-center pt-1'>
                                      {star.map((result, index) => {
                                        return (
                                          <FaStar
                                            key={index}
                                            size={24}
                                            onClick={() => {
                                              setFieldValue('stars', index + 1);
                                              handleClick(index + 1);
                                            }}
                                            onMouseOver={() =>
                                              handleMouseOver(index + 1)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                            color={
                                              (hoverValue || currentValue) >
                                              index
                                                ? colors.orange
                                                : colors.grey
                                            }
                                            style={{
                                              marginRight: 10,
                                              cursor: 'pointer',
                                            }}
                                          />
                                        );
                                      })}
                                    </h2>
                                  </div>
                                  <div
                                    className={
                                      commentHideShow ? 'd-none' : 'd-block'
                                    }
                                  >
                                    <input
                                      className='mt-2 feedback-form-textarea form-control'
                                      {...getFieldProps('comment')}
                                    ></input>
                                    <div className='comment-submit mt-2 float-right text-center'>
                                      <button
                                        className='btn btn-secondary'
                                        style={{ backgroundColor: '#8080806b' }}
                                        type='submit'
                                      >
                                        Enviar retroalimentación
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form> */}
                        <form onSubmit={handleSubmit}>
                          <div className='grammar-box mt-2'>
                            <div className='grammar-text-box mt-1'>
                              {!percentageHide && (
                                <>
                                  <h1 className='text-center fw-bold mt-5'>
                                    Quiz Results
                                  </h1>
                                  <h1 className='text-center'>
                                    {resultAnswerCount == 1
                                      ? '20%'
                                      : resultAnswerCount == 2
                                      ? '40%'
                                      : resultAnswerCount == 3
                                      ? '60%'
                                      : resultAnswerCount == 4
                                      ? '80%'
                                      : resultAnswerCount == 5
                                      ? '100%'
                                      : '0%'}
                                  </h1>
                                </>
                              )}
                              <div
                                className='d-block clearfix'
                                style={{
                                  paddingTop: `${
                                    !percentageHide ? '0%' : '35%'
                                  }`,
                                }}
                              >
                                {/* <div
                                  className='btn btn-primary btn-sm float-start'
                                  onClick={() => window.location.reload()}
                                >
                                  Volver a hacer la lección
                                </div>
                                <Link
                                  className='btn btn-primary btn-sm float-end'
                                  to={`/course/${courseInviteId}/student-course-details`}
                                  onClick={() => {
                                    onContinue();
                                  }}
                                >
                                  Continuar{' '}
                                  <BsArrowRightCircleFill
                                    size={18}
                                    style={{ marginLeft: '5px' }}
                                  />
                                </Link> */}
                              </div>
                              <div className='mt-7'>
                                <ul className='list-group'>
                                  {questionResultList.map((result: any) => {
                                    return (
                                      <>
                                        <li className='list-group-item text-center fw-bold'>
                                          {result.Question} :{' '}
                                          <span className='ml-3'>
                                            {result.Answer ? (
                                              <FcCheckmark size={20} />
                                            ) : (
                                              <IoCloseSharp
                                                size={20}
                                                color='red'
                                              />
                                            )}
                                          </span>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                              {/* {!commentHideShow && (
                                <div className='mt-2 bg-secondary p-5 rounded-3'>
                                  <h2 className='text-left'>
                                    Mejora nuestra lecciones
                                  </h2>
                                  <div className='d-block clearfix rating-container d-flex'>
                                    <h5
                                      className='text-center mt-3'
                                      style={{ marginRight: '10px' }}
                                    >
                                      Calificación
                                    </h5>
                                    <h2 className='text-center pt-1'>
                                      {star.map((result, index) => {
                                        return (
                                          <FaStar
                                            key={index}
                                            size={24}
                                            onClick={() => {
                                              setFieldValue('stars', index + 1);
                                              handleClick(index + 1);
                                            }}
                                            onMouseOver={() =>
                                              handleMouseOver(index + 1)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                            color={
                                              (hoverValue || currentValue) >
                                              index
                                                ? colors.orange
                                                : colors.grey
                                            }
                                            style={{
                                              marginRight: 10,
                                              cursor: 'pointer',
                                            }}
                                          />
                                        );
                                      })}
                                    </h2>
                                  </div>
                                  <div
                                    className={
                                      commentHideShow ? 'd-none' : 'd-block'
                                    }
                                  >
                                    <input
                                      className='mt-2 feedback-form-textarea form-control'
                                      {...getFieldProps('comment')}
                                    ></input>
                                    <div className='comment-submit mt-2 float-right text-center'>
                                      <button
                                        className='btn btn-secondary'
                                        style={{ backgroundColor: '#8080806b' }}
                                        type='submit'
                                      >
                                        Enviar retroalimentación
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )} */}

                              <div className='mt-10 text-center'>
                                <Link
                                  className='btn btn-primary btn-lg'
                                  to={`/course`}
                                  onClick={() => {
                                    onContinue();
                                  }}
                                >
                                  {formatMessage({
                                    id: 'CONTINUE',
                                  })}{' '}
                                  <BsArrowRightCircleFill
                                    size={20}
                                    style={{ marginLeft: '5px' }}
                                  />
                                </Link>
                              </div>
                              <div className='mt-10'>
                                <div
                                  className='btn btn-secondary btn-sm float-start'
                                  onClick={() => window.location.reload()}
                                >
                                  {formatMessage({
                                    id: 'LESSON.DO_THE_LESSON_AGAIN',
                                  })}
                                </div>
                                <Link
                                  className='btn btn-secondary btn-sm float-end'
                                  to={`/course/${courseInviteId}/student-course-details`}
                                  onClick={() => {
                                    onContinue();
                                  }}
                                >
                                  {formatMessage({
                                    id: 'COURSE.GRADES',
                                  })}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ResultFooterPage
            lessonId={lessonId}
            courseInviteId={courseInviteId}
          />
        </div>
      </div>
    </>
  );
};

export default LessonResult;
