import React from 'react';
import { Form as BForm } from 'react-bootstrap';
import { useFormikContext, ErrorMessage } from 'formik';
import useIntlCustom from 'hooks/useIntlCustom';

const Step4 = () => {
  const { formatMessage } = useIntlCustom();
  const {
    errors,
    touched,
    values,
    submitForm,
    getFieldProps,
    handleChange,
    handleBlur,
    setFieldValue,
  }: any = useFormikContext();

  function validate(evt: any) {
    var theEvent = evt || window.event;
    // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  return (
    <BForm.Group className='mb-7'>
      <BForm.Label className='fw-bold fs-2'>
        {formatMessage({
          id: 'TEACHER.POINTS_NEEDED_TO_COMPLETE_COURSE',
        })}
        {/* Points Needed to Complete Course */}
        <span style={{ color: 'red' }}>*</span>
      </BForm.Label>
      <BForm.Control
        type='text'
        // maxlength="10"
        onKeyPress={validate}
        placeholder={formatMessage({
          id: 'TEACHER.POINTS_NEEDED_TO_COMPLETE_COURSE.PLACEHOLDER',
        })}
        {...getFieldProps('pointsNeededToCompleteCourse')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`rounded-1 form-control-sm mb-3 mb-lg-0  form-control ${
          errors.pointsNeededToCompleteCourse &&
          touched.pointsNeededToCompleteCourse
            ? 'border border-danger'
            : ''
        }`}
      />
      {touched.pointsNeededToCompleteCourse &&
        errors.pointsNeededToCompleteCourse && (
          <BForm.Text className='text-danger'>
            <ErrorMessage name='pointsNeededToCompleteCourse' />
          </BForm.Text>
        )}

      <div className='card card-flush mt-2 p-2'>
        <div className='card-title text-gray-800 fw-bold h4 px-4 py-2'>
          {/* How students can earn points: */}
          {formatMessage({
            id: 'TEACHER.HOW_STUDENTS_CAN_EARN_POINTS',
          })}
          :
        </div>
        <div className='card-body py-0'>
          <div className='d-flex flex-stack'>
            <div className='text-primary fw-semibold fs-6 me-2'>
              {formatMessage({
                id: 'TEACHER.VIRAL_VIDEO_LESSON',
              })}
              {/* Viral Video Lesson */}
            </div>

            <div className='text-muted w-55px'>
              1{' '}
              {formatMessage({
                id: 'POINT',
              })}
            </div>
          </div>
          <div className='separator separator-dashed my-1'></div>
          <div className='d-flex flex-stack'>
            <div className='text-primary fw-semibold fs-6 me-2'>
              {/* Instant Solo Class */}
              {formatMessage({
                id: 'TEACHER.INSTANT_CLASS',
              })}
            </div>

            <div className='text-muted w-55px'>
              1{' '}
              {formatMessage({
                id: 'POINT',
              })}
            </div>
          </div>
          <div className='separator separator-dashed my-1'></div>
          <div className='d-flex flex-stack'>
            <div className='text-primary fw-semibold fs-6 me-2'>
              {/* Live Class */}
              {formatMessage({
                id: 'TEACHER.LIVE_CLASS',
              })}
            </div>

            <div className='text-muted w-55px'>
              6{' '}
              {formatMessage({
                id: 'POINTS',
              })}
            </div>
          </div>
        </div>
      </div>
    </BForm.Group>
  );
};

export default Step4;
