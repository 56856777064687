import React from 'react';

const K6_English_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>English</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Learning English grammar: Verb tenses, pronouns, adjectives,
            sentence structure, etc.
          </li>
          <li>- Learning basic information questions</li>
          <li>- Learning colors, numbers, shapes, animals, etc.</li>
          <li>Learning Reading, Writing, Speaking and Listening skills</li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. A continuación, se muestran algunos ejemplos de
          temas relacionados que podrían aplicarse al requisito:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Aprender acerca de la gramática inglesa: tiempos verbales,
            pronombres, adjetivos, estructura de oraciones, etc.
          </li>
          <li>- Aprendizaje de preguntas de información básica</li>
          <li>- Aprender colores, números, formas, animales, etc.</li>
          <li>
            - Aprender habilidades de lectura, escritura, expresión oral y
            comprensión auditiva
          </li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, desafortunadamente
          no podremos inscribir a su estudiante en Hope City. Sin embargo, si un
          padre o tutor puede enseñarle al estudiante esta materia en casa a
          través de videos de youtube y material en línea, entonces se cumplirá
          con este requisito del curso y podrá inscribirse en Hope City. Por
          favor confírmanos si su escuela o usted puede cumplir con este
          requisito.
        </div>
      </div>
    </div>
  );
};

export default K6_English_Requirement;
