import React from 'react';
import premiumPng from 'app/components/AdModal/icons/Speaker_cross.png';
import UpgradePlan from '../UpgradePlan';
import useIntlCustom from 'hooks/useIntlCustom';

const PaymentAd = () => {
  const { formatMessage } = useIntlCustom();

  return (
    <div className='h-100'>
      <div className='ad-price-box bg-light'>
        <h1 className='text-center ad-text-color'>
          {formatMessage({ id: 'UPGRADE.PREMIUM' })}(
          {formatMessage({ id: 'UPGRADE.NO_ADS' })})
        </h1>
        <div className='price-speaker-icon'>
          <img src={premiumPng} alt='icon' />
        </div>
        <p className='ad-text-color text-center'>
          {formatMessage({ id: 'UPGRADE.AD_FREE_UNINTERRUPTED' })}
        </p>
        <p className='ad-text-color text-center'>
          {formatMessage({ id: 'UPGRADE.LEARNING_AND_LESSONS' })}
        </p>
        <h2 className='ad-text-color text-center'>
          $5.99 / {formatMessage({ id: 'UPGRADE.MONTH' })}
        </h2>
      </div>
      <div className='text-center p-5'>
        <UpgradePlan
          className='btn btn-success btn-sm'
          title='Adquirir Premium'
        />
        <p className='small mt-2' style={{ fontSize: '10px' }}>
          {formatMessage({ id: 'UPGRADE.CANCEL_ANYTIME' })}
        </p>
      </div>
    </div>
  );
};

export default PaymentAd;
