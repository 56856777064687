import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { ISSUE_TYPES } from 'shared/constants';
import { useMutation } from 'react-query';
import { getAxiosInstance } from 'helpers/api-helper';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ReportModal: React.FC<any> = ({
  visible,
  onClose,
  onSubmit,
  loading,
}) => {
  const formik: any = useFormik({
    onSubmit,
    initialValues: {
      description: '',
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .min(10, 'Please enter at least 10 characters')
        .required('Please write reason why you want to report'),
    }),
  });

  return (
    <Modal show={visible} onHide={onClose} scrollable centered>
      <Modal.Header closeButton>
        <h5>Report</h5>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='description'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              {...formik.getFieldProps('description')}
            />
            {formik.errors.description && (
              <Form.Text className='text-danger'>
                {formik.errors.description}
              </Form.Text>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' type='submit' disabled={loading}>
            Report
          </Button>
          <Button variant='secondary' onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default function Footer(props: any) {
  const { urlHash, lesson } = props;

  let { courseInviteId } = useParams();

  const [visible, setVisible] = React.useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const createIssue = useMutation({
    mutationFn: ({ issueType, description }: any) => {
      return getAxiosInstance().post('/issues', {
        lesson,
        urlHash,
        courseInviteId,
        issueType,
        description,
      });
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.data.message, { autoClose: 4000 });
    },
    onError: (data: any) => {
      toast.dismiss();
      toast.error(data.response.data.message, { autoClose: 4000 });
    },
    onSettled: () => {
      handleClose();
    },
  });

  const onIssueCreate = (type: string) => {
    if (type == ISSUE_TYPES.OTHER) {
      setVisible(true);
      return;
    }
    createIssue.mutate({ issueType: type, description: undefined });
  };

  const handleSubmit = (values: any) => {
    createIssue.mutate({
      issueType: ISSUE_TYPES.OTHER,
      description: values.description,
      courseInviteId: courseInviteId,
    });
  };

  return (
    <>
      {visible && (
        <ReportModal
          loading={createIssue.isLoading}
          visible={visible}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <Dropdown key='up' id={`dropdown-button-drop-up`} drop='up'>
        <Dropdown.Toggle variant='line' id='dropdown-basic' className='p-1'>
          <div className='action-btn'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              fill='#ffffff'
              className='bi bi-flag'
              viewBox='0 0 16 16'
            >
              <path d='M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z' />
            </svg>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className='text-center'>
          <Dropdown.Item
            href='#'
            onClick={() => onIssueCreate(ISSUE_TYPES.TEXT)}
          >
            Text is wrong
          </Dropdown.Item>
          <Dropdown.Item
            href='#'
            onClick={() => onIssueCreate(ISSUE_TYPES.AUDIO)}
          >
            Audio is wrong
          </Dropdown.Item>
          <Dropdown.Item
            href='#'
            onClick={() => onIssueCreate(ISSUE_TYPES.IMAGE)}
          >
            Image is wrong
          </Dropdown.Item>
          <Dropdown.Item
            href='#'
            onClick={() => onIssueCreate(ISSUE_TYPES.VIDEO)}
          >
            Video is missing
          </Dropdown.Item>
          <Dropdown.Item
            href='#'
            onClick={() =>
              onIssueCreate(ISSUE_TYPES.WRONG_ANSWER_PROVIDED_IN_QUESTION)
            }
          >
            My answer was correct
          </Dropdown.Item>
          <Dropdown.Item
            href='#'
            onClick={() => onIssueCreate(ISSUE_TYPES.OTHER)}
            className='dropdown-item-new other'
          >
            Other Problem (Please tell us what is wrong)
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href='#' className='dropdown-item-cancel'>
            Cancel
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
