import { useAuth } from 'app/modules/auth';
import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PreFillProfileInviteModal from './preFillProfileInviteModal';
import PreFillProfileModal from './preFillProfileModal';

const PreRequiredDataCheck = ({ children }: any) => {
  const location = useLocation();

  const {
    auth,
    setCurrentUser,
    currentUser,
    preFilledCompleted,
    setPreFilledCompleted,
  } = useAuth();

  const getStudentDataQuery: any = usePostQuery(
    'getStudentDataQuery',
    '/user/studentGetById',
    {}
  );

  useEffect(() => {
    if (auth?.token) {
      getStudentDataQuery.mutate({});
    }
  }, [location.pathname]);

  useEffect(() => {
    if (getStudentDataQuery.isSuccess) {
      setCurrentUser(getStudentDataQuery?.data?.ResultObject);
    }
  }, [getStudentDataQuery.isSuccess]);

  return (
    <>
      {auth?.token && currentUser && !location.pathname.includes('invite') && (
        <>
          {(() => {
            if (
              currentUser.IsDiplomaStudent === false &&
              currentUser.isDefaultCourseAssign === false &&
              (currentUser?.Native_Language || currentUser?.BirthYear)
            ) {
              return <PreFillProfileInviteModal />; // 4 step
            } else if (
              currentUser.IsDiplomaStudent === false &&
              currentUser.isDefaultCourseAssign === false &&
              (!currentUser?.Native_Language || !currentUser?.BirthYear)
            ) {
              return <PreFillProfileInviteModal />; // 4 step
            } else if (
              currentUser.IsDiplomaStudent === false &&
              currentUser.isDefaultCourseAssign === true &&
              (!currentUser?.Native_Language || !currentUser?.BirthYear)
            ) {
              return <PreFillProfileModal />; // 2 step
            } else if (
              currentUser?.IsDiplomaStudent === true &&
              (!currentUser?.Native_Language || !currentUser?.BirthYear)
            ) {
              return <PreFillProfileModal />; // 2 step
            } else {
              return children;
            }
          })()}
        </>
      )}
    </>
  );
};

export default memo(PreRequiredDataCheck);
