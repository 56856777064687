import React, { FC, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const HeaderActivityPage: React.FC<any> = (props: any) => {
  const { handleVideoNextButton, acticityPageShow } = props;

  return (
    <>
      {/* <div>
        <nav className="top-0 fixed-top z-50 w-full flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative text-center justify-content-between align-items-center lg:w-auto lg:static lg:block lg:justify-start">
              <Dropdown>
                <Dropdown.Toggle className="cursor-pointer text-xl leading-none px-3 py-1 border border-0 rounded bg-transparent block lg:hidden outline-none focus:outline-none" id="dropdown-basic">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" className="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/course">Curso</Dropdown.Item>
                <Dropdown.Item href="/account/overview">Cuenta</Dropdown.Item>
                <Dropdown.Item href="/teacher">Profesoras</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div> */}
    </>
  );
};

export default HeaderActivityPage;
