import { Navigate, Route, Routes } from 'react-router-dom';
import Registration from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import Login from './components/Login';
import { AuthLayout } from './AuthLayout';
import TeacherInvitationAccount from './components/TeacherInvitationAccount';
import Login2 from './components/Login2';
import { ResetPassword } from './components/ResetPassword';

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route
        path='teacherInvitation/:token'
        element={<TeacherInvitationAccount />}
      />
      <Route path='login' element={<Login />} />
      <Route path='login2' element={<Login2 />} />
      <Route path='signup' element={<Registration />} />
      <Route path='diploma' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:token' element={<ResetPassword />} />

      <Route index element={<Login />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Route>
  </Routes>
);

export { AuthPage };
