import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { BsFolder } from 'react-icons/bs';
import TeacherTreeView from './Teacher-TreeView';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper'


const TeacherTreeViewFolder: React.FC<any> = (props: any) => {

    const {
        courseID,
        setCourseList,
        courseList,
        treeData,
        courseDataID,
        setFolderDataID,
        folderDataID,
        setFolderDataList,
        folderParentID,
        getBreadcrumb,
        setBreadcrumbs,
        setSelectedFolderId,
        onFolderClick,
        selectedFolderId,
        setDragType,
        setTreeData,
        setFolderParentID,
        dragFolderID,
        dragType,
        setDragFolderID,
        drag,
        UserID,
        getAllFolderAndCourseQuery
    } = props;

    const [expandedNodes, setExpandedNodes] = useState<number[]>([]);

    return (
        <>
            {treeData &&
                <TeacherTreeView
                    treeData={treeData}
                    parentId={null}
                    setExpandedNodes={setExpandedNodes}
                    expandedNodes={expandedNodes}
                    courseID={courseID}
                    setCourseList={setCourseList}
                    courseList={courseList}
                    courseDataID={courseDataID}
                    setFolderDataID={setFolderDataID}
                    folderDataID={folderDataID}
                    setSelectedFolderId={setSelectedFolderId}
                    selectedFolderId={selectedFolderId}
                    onFolderClick={onFolderClick}
                    setFolderDataList={setFolderDataList}
                    folderParentID={folderParentID}
                    getBreadcrumb={getBreadcrumb}
                    setBreadcrumbs={setBreadcrumbs}
                    setDragType={setDragType}
                    dragType={dragType}
                    setTreeData={setTreeData}
                    setFolderParentID={setFolderParentID}
                    dragFolderID={dragFolderID}
                    setDragFolderID={setDragFolderID}
                    drag={drag}
                    UserID={UserID}
                    getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
                />
            }
        </>
    );
};

export default TeacherTreeViewFolder;


