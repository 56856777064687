import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { KTSVG } from 'theme/helpers';
import { jwtTokenDecode } from 'hooks/tokenHook';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import Swal from 'sweetalert2';
import TeacherFolderBreadcrumbs from '../Teacher-FolderStructure/Teacher-FolderBreadcrumbs';
import { BsTrash } from 'react-icons/bs';
import useIntlCustom from 'hooks/useIntlCustom';
import UpsertCourseAsTeacherModal from '../UpsertCourseAsTeacherModal';

const TrashCourseList = (props: any) => {
  const {
    dragIndex,
    courseList,
    courseDataID,
    setCourseDataID,
    breadcrumbs,
    onFolderClick,
    getBreadcrumb,
    setBreadcrumbs,
    selectedFolderId,
    handleTrashData,
    getFolderList,
    trashCourseList,
    setTrashCourseList,
    isChecked,
    handleCheckboxChange,
    handleDeleteMany,
    selectedCourseIds,
    setSelectedCourseIds,
    isButtonVisible,
    setButtonVisibility,
    handleRestore,
    getRecord,
  } = props;

  const { formatMessage } = useIntlCustom();

  const navigate = useNavigate();
  const [modalAddCourseShow, setModalAddCourseShow] = React.useState(false);

  const handleConfirmation = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Course will be deactivated!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedItems = [...courseList];
        updatedItems.splice(dragIndex, 1);
      }
    });
  };

  const onReloadCourse = () => {
    setModalAddCourseShow(false);
    getRecord(selectedFolderId);
  };
  return (
    <>
      {modalAddCourseShow && (
        <UpsertCourseAsTeacherModal
          show={modalAddCourseShow}
          onHide={() => setModalAddCourseShow(false)}
          onReload={() => onReloadCourse()}
        />
      )}
      <Row className='g-5 pb-5'>
        <Col>
          <div className='card card-flush p-0 shadow-sm'>
            <div className='card-body py-5 d-flex gap-5'>
              <button
                className='btn  btn-light fw-bold w-100'
                onClick={() => setModalAddCourseShow(true)}
              >
                <AiOutlinePlus />
                {/* New Course */}{' '}
                {formatMessage({
                  id: 'TEACHER.NEW_COURSE',
                })}
              </button>
              <button
                className='btn  btn-light fw-bold w-100'
                onClick={handleTrashData}
              >
                <BsTrash />
                {/* Trash */}{' '}
                {formatMessage({
                  id: 'TRASH',
                })}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <TeacherFolderBreadcrumbs
        breadcrumbs={breadcrumbs}
        onFolderClick={onFolderClick}
        getBreadcrumb={getBreadcrumb}
        setBreadcrumbs={setBreadcrumbs}
        selectedFolderId={selectedFolderId}
        getFolderList={getFolderList}
      />
      <SortableList helperClass='sortable-helper'>
        {trashCourseList?.map((item: any, index: any) => (
          <SortableItem
            dataItem={item}
            setCourseDataID={setCourseDataID}
            courseDataID={courseDataID}
            setTrashCourseList={setTrashCourseList}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
            handleDeleteMany={handleDeleteMany}
            isButtonVisible={isButtonVisible}
            setButtonVisibility={setButtonVisibility}
            selectedCourseIds={selectedCourseIds}
            setSelectedCourseIds={setSelectedCourseIds}
            handleRestore={handleRestore}
          />
        ))}
        {getFolderList?.isSuccess &&
          trashCourseList &&
          trashCourseList?.length === 0 && (
            <div className='text-center fw-bold fs-3 p-5 '>
              No Course Available
            </div>
          )}
      </SortableList>
    </>
  );
};

export default TrashCourseList;

const SortableList: any = SortableContainer(({ children, setDrag }: any) => {
  const { formatMessage } = useIntlCustom();

  return (
    <Row className='g-5 sortable-list'>
      <h3>
        {formatMessage({
          id: 'COURSE',
        })}
      </h3>
      {children}
    </Row>
  );
});

const SortableItem: any = SortableElement((props: any) => {
  const { dataItem, selectedCourseIds, handleCheckboxChange } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const navigate = useNavigate();

  const onClickAssignment = (courseId: any) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate(`/course/${courseId}/student-course-details`);
    }, 1000);
  };

  return (
    <>
      <Col
        lg={4}
        data-items={dataItem}
        data-id={dataItem?._id}
        className='sortable-item'
      >
        <div
          className='card card-flush shadow-sm bg-light-warning  border-warning border border-dashed h-100'
          style={{ background: '#ffdddd' }}
        >
          <div className='card-body d-flex flex-column py-5'>
            {dataItem?.name && (
              <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                  <h3 className='card-title fw-bold fs-2 m-0'>
                    {dataItem?.name}
                  </h3>
                  <input
                    type='checkbox'
                    className='form-check-input ms-3 mt-0 border border-2 border-warning'
                    id='customCheck1'
                    checked={selectedCourseIds.includes(dataItem._id)}
                    onChange={(e) => handleCheckboxChange(dataItem._id, e)}
                    style={{
                      border: '1px solid #ff8181',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </div>
              </div>
            )}
            <div className='py-5'>
              {dataItem.createdBy === jwtTokenDecode().id ? (
                <>
                  <div className='card-text text-gray-400 fw-semibold fs-5 mb-4'>
                    Class GPA: Student Enrolled
                  </div>
                </>
              ) : (
                <>
                  <div className='card-text text-gray-400 fw-semibold fs-5'>
                    Due: 8 Days, 4 Hour
                  </div>
                  <div className='card-text text-gray-400 fw-semibold fs-5'>
                    Grade: B
                  </div>
                  <div className='card-text text-gray-400 fw-semibold fs-5'>
                    Percentage: 83%
                  </div>
                  <div className='card-text text-gray-400 fw-semibold fs-5'>
                    Points Needed: 39 of 50
                  </div>
                </>
              )}
            </div>
            <div className='mb-4 mt-auto'>
              {dataItem.createdBy === jwtTokenDecode().id ? (
                <>
                  <div className='btn-group w-100'>
                    <button
                      disabled
                      className={`btn btn-lg btn-success me-3 mt-5 w-100'}`}
                      onClick={(e) => {
                        navigate(`/teacher/${dataItem?._id}/view`);
                      }}
                    >
                      <span className='pe-none'>
                        Go
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-4 me-1'
                        />
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className='btn-group w-100'>
                    <button
                      disabled
                      className={`btn btn-lg btn-primary me-3 mt-5 w-100 btn-primary'}`}
                      onClick={() => onClickAssignment(dataItem._id)}
                    >
                      {isLoading ? (
                        <div className='d-flex align-items-center justify-content-center'>
                          <Spinner animation='border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <span className='pe-none'>
                          Go
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-4 me-1'
                          />
                        </span>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
});
