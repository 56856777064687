import { useEffect, useState } from 'react';
import translate from 'translate';

const TranslateInSpanish = (props: any) => {
  //<<== removing async here fixed the issue============/////////
  const [spanish, setSpanish] = useState(null);

  useEffect(() => {
    translate(props.text, { to: 'es' }).then((res: any) => {
      setSpanish(res);
    });
  }, []);

  return <>{spanish}</>;
};

export default TranslateInSpanish;
