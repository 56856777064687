/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAd } from './AdProvider';
import styles from './AdModal.module.scss';
import PriceCard from './PriceCard';
import dudePng from './icons/dude.png';
import freePng from './icons/Speaker.png';
import premiumPng from './icons/Speaker_cross.png';

const AdModal: React.FC = () => {
  const { showAd, onCloseAd } = useAd();

  const [isAdEnded, setIsAdEnded] = React.useState(false);

  const totalSec = 10;

  const [progress, setProgress] = React.useState(1);
  const seconds = Math.floor((progress / 100) * totalSec);

  const value = progress;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress: any) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setIsAdEnded(true);

          return 100;
        }

        const t = prevProgress + 100 / totalSec;

        return t >= 100 ? 100 : t;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSec]);

  return false ? (
    <Modal show={false} size='lg'>
      <Modal.Body
        style={{
          background: `url(${dudePng})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
        }}
      >
        <div className={styles.header}>
          <h1>Pricing Plans</h1>
          <h3>Cancel anytime</h3>
        </div>

        <div className={styles.wrapper}>
          <PriceCard
            title='Premium'
            amount='$5.99'
            icon={premiumPng}
            description={
              <p>
                Ad-Free, uninterrupted <br /> learning and lessons
              </p>
            }
          />

          <PriceCard
            title='Free'
            amount='$0.00'
            icon={freePng}
            description={
              <p>
                Ad-mixed into learning <br />
                that interrupt lessons.
              </p>
            }
          />

          {!isAdEnded && (
            <React.Fragment>
              <progress id={styles.progress} max='100' value={100 - value}>
                Progress
              </progress>
              <div className={styles.timer}>{`${
                totalSec - seconds
              } seconds`}</div>
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onCloseAd} disabled={!isAdEnded}>
          Skip Ad
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

const AdMain = () => {
  const { showAd } = useAd();

  return false ? <AdModal /> : null;
};

export default React.memo(AdMain);
