import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikConfig } from 'formik';
import { Alert, Button, Form, Modal as BModal, Spinner } from 'react-bootstrap';
import FormikErrorText from 'app/components/palette/FormikErrorText';

// const MAX_ID_LENGTH = 15;
// const regex = new RegExp(`^[0-9]{${MAX_ID_LENGTH},${MAX_ID_LENGTH}}$`, 'g');

type T = {
  messengerId: string;
};

type PropsType<T> = {
  initialValues?: T;
  visible: boolean;
  title: string;
  okText?: string;
  updateText?: string;
  cancelText?: string;
  loading?: boolean;
  onSubmit: FormikConfig<T>['onSubmit'];
  onCancel: () => void;
  errorMessage?: any;
};

const validationSchema = Yup.object({
  messengerId: Yup.string()
    // .trim()
    .defined('Please enter Messenger ID'),
  // .matches(regex, 'Please enter valid Messenger ID'),
});

const MessengerConnectPopup: React.FC<React.PropsWithChildren<PropsType<T>>> = (
  props
) => {
  const {
    title,
    visible = true,
    okText = 'Submit',
    cancelText = 'Cancel',
    updateText = 'Save',
    loading = false,
    onSubmit,
    initialValues: oldInitialValues,
    errorMessage = '',
    onCancel = () => null,
  } = props;

  const initialValues = {
    messengerId: '',
    ...(oldInitialValues || {}),
  };

  const text = oldInitialValues ? updateText : okText;

  const disabled = loading;

  return (
    <BModal show={visible} backdrop='static' keyboard={false} onHide={onCancel}>
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({ touched, errors, getFieldProps, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <BModal.Body>
              {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
              <Form.Group
                className='mb-3'
                controlId='messengerId'
                aria-disabled={disabled}
              >
                <Form.Label required>Messenger ID</Form.Label>
                <Form.Control
                  {...getFieldProps('messengerId')}
                  alt='Messenger ID'
                  placeholder='Enter messenger ID'
                  autoComplete='off'
                  disabled={disabled}
                  max={15}
                  maxLength={15}
                  isInvalid={
                    touched.messengerId && errors.messengerId !== undefined
                  }
                />
                <FormikErrorText name='messengerId' />
              </Form.Group>
            </BModal.Body>
            <BModal.Footer>
              <Button variant='primary' type='submit' disabled={disabled}>
                {loading ? <Spinner size='sm' /> : text}
              </Button>
              <Button
                variant='secondary'
                type='button'
                disabled={disabled}
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            </BModal.Footer>
          </Form>
        )}
      </Formik>
    </BModal>
  );
};

export default MessengerConnectPopup;
