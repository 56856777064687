import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { CheckBoxComponents } from 'hooks/formikFormHook';

const Section7_12_17_1 = (props: any) => {
  const { formik, hasFieldError, removeHightlight } = props;

  const Grade6YearRequirements = [
    {
      Value_English: 'History of USA',
      Value_Spanish: 'Historia de Estados Unidos',
      Link: 'https://forms.gle/SF5R1j8NyPm6PRt9A',
    },
    {
      Value_English: 'Government of USA',
      Value_Spanish: 'Gobierno de EE.UU.',
      Link: 'https://forms.gle/tXrryp7hsavcgrPz9',
    },
    {
      Value_English: 'History of California',
      Value_Spanish: 'Historia de California',
      Link: 'https://forms.gle/SWjwmrytyD5VjXQH8',
    },
    {
      Value_English: 'Government of California',
      Value_Spanish: 'Gobierno de California',
      Link: 'https://forms.gle/N6EhDa2sEN4fFsM58',
    },
  ];

  return (
    <>
      <div className='p-3'>
        <label className='form-label fw-bold'>
          6th Grade Course Material / Material del curso de sexto grado
        </label>

        <p className='form-label'>
          Students in 6th grade must complete the following material before the
          end of the year in order to graduate with Hope City. / Los estudiantes
          de sexto grado deben completar el siguiente material antes de fin de
          año para poder graduarse en Hope City.
        </p>
      </div>

      <div
        className={
          hasFieldError?.includes('I_Agree_I_Will_Complete_Material')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        {Grade6YearRequirements.map((data: any, index: any) => {
          return (
            <>
              <label className='form-label fw-bold'>
                {index + 1}. {data.Value_English + ' / ' + data.Value_Spanish}
              </label>
              <p>
                {' '}
                <a href={data.Link} target='_blank'>
                  {data.Link}
                </a>
              </p>
            </>
          );
        })}
        <div className='form-check mt-4'>
          <CheckBoxComponents
            formik={formik}
            fieldName={'I_Agree_I_Will_Complete_Material'}
            onChange={() => {
              removeHightlight('I_Agree_I_Will_Complete_Material');
            }}
          />
          <label className='form-label fw-normal'>
            I agree that I will complete this material before the end of the
            year. / Acepto que completaré este material antes de fin de año.
          </label>
        </div>
        <FieldsErrorText
          touched={formik.touched.I_Agree_I_Will_Complete_Material}
          errors={formik.errors.I_Agree_I_Will_Complete_Material}
        />
      </div>
    </>
  );
};

export default Section7_12_17_1;
