import Loader from 'app/components/Loader';
import React, { useEffect, useState } from 'react';
import GradeSubmissionFormHeader from './GradeSubmissionFormHeader';
import Section1 from './section/Section1';
import Section2 from './section/Section2';
import Section3 from './section/Section3';
import Section4 from './section/Section4';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Section5 from './section/Section5';
import Stepper from '../stepper/Stepper';

const GradeSubmissionFormK7_12 = (props: any) => {
  const getByUserGradeK12Qurey: any = usePostQuery(
    'getByUserGradeK12',
    '/enrollment/grade/getbyuserid',
    {}
  );

  const [stepper, setStepper] = useState(3);
  const [step, setStep] = useState(1);
  const [grade, setGrade] = useState('');
  const [gradeFormData, setGradeFormData] = useState('');
  const [finishHighSchool, setFinishHighSchool] = useState('');
  const [formType, setFormType] = useState<Number>(1);
  const [fieldReportedIssue, setFieldReportedIssue] = useState<any>('');

  const getEnrollmentReportQuery: any = useGetQuery(
    'getEnrollmentReport',
    '/enrollment/report/get',
    {}
  );

  useEffect(() => {
    getEnrollmentReportQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getEnrollmentReportQuery?.isSuccess) {
      setFieldReportedIssue(
        getEnrollmentReportQuery?.data?.ResultObject?.fieldReportedIssue
      );
    }
  }, [getEnrollmentReportQuery?.isSuccess]);

  useEffect(() => {
    getByUserGradeK12Qurey.mutateAsync({ formType: formType });
  }, [step]);

  useEffect(() => {
    if (getByUserGradeK12Qurey.isSuccess) {
      setGradeFormData(getByUserGradeK12Qurey?.data?.ResultObject);
      setGrade(getByUserGradeK12Qurey?.data?.ResultObject?.School_Grade?.value);
      setFinishHighSchool(
        getByUserGradeK12Qurey?.data?.ResultObject?.Student_Finish_High_School
          ?.value
      );
    }
  }, [getByUserGradeK12Qurey.isSuccess]);

  return (
    <>
      <div>
        <Loader loading={getByUserGradeK12Qurey.isLoading} />
        <GradeSubmissionFormHeader
          step={step}
          setStep={setStep}
          formType={formType}
        />

        {step != 0 && !getByUserGradeK12Qurey.isLoading && (
          <Stepper stepper={stepper} step={step} />
        )}

        {step == 1 && !getByUserGradeK12Qurey.isLoading && (
          <Section1
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 2 && grade == 'none' && !getByUserGradeK12Qurey.isLoading && (
          <Section2
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 2 && grade != 'none' && !getByUserGradeK12Qurey.isLoading && (
          <Section5
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}

        {step == 3 && !getByUserGradeK12Qurey.isLoading && (
          <Section4
            setStep={setStep}
            step={step}
            setGrade={setGrade}
            formType={formType}
            gradeFormData={gradeFormData}
            stepper={stepper}
            finishHighSchool={finishHighSchool}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
      </div>
    </>
  );
};

export default GradeSubmissionFormK7_12;
