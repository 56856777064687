import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { register, validToken } from '../core/_requests';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../theme/helpers';
import { PasswordMeterComponent } from '../../../../theme/assets/ts/components';
import { useAuth } from '../core/Auth';
import AuthFooter from './AuthFooter';
import { usercheckTokenValidOrNot } from '../../../../store/actions';
import { connect } from 'react-redux';
import CorrectSignLogo from '../../../../theme/images/FeaturedIcon.png';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
};

const FeaturedIcon = toAbsoluteUrl('/theme/images/FeaturedIcon.svg');

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('Please enter first name')
    .min(3, 'First name must be at least 3 characters'),
  email: Yup.string()
    .email('Please enter valid email address')
    .required('Please enter email address'),
  lastname: Yup.string()
    .required('Please enter last name')
    .min(3, 'Last name must be at least 3 characters'),
  password: Yup.string()
    .min(8, 'Password must be longer than 8 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      'Must contain one uppercase, one lowercase'
    )
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'Must contain one special case character')
    .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain one number')
    .required('Please enter password'),
  changepassword: Yup.string()
    .required('Please enter password')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        "Confirm password and password didn't match"
      ),
    }),
});

const TeacherInvitationAccount = (props: any) => {
  const [loading, setLoading] = useState(false);

  const { saveAuth } = useAuth();
  const [resultAuth, setResultAuth] = useState();
  const [tokenError, setTokenError] = useState();
  const { token } = useParams();
  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      try {
        const { data: auth } = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword
        );
        if (auth.ResultCode == 'SUCCESS') {
          setResultAuth(auth.ResultCode);
          saveAuth(auth);
          const userId = auth.ResultObject._id;
        } else {
          setStatus(auth.ResultMessage);
          setSubmitting(false);
        }
      } catch (error) {
        saveAuth(undefined);
        setStatus('The registration details is incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const navigate = useNavigate();
  useEffect(() => {
    const getUsers = async () => {
      const users = await validToken(token);
      setTokenError(users.data.ResultCode);

      initialValues.email = users.data.ResultData?.Email
        ? users.data.ResultData?.Email
        : '';
      // if (users.data.ResultCode == 'Token') {
      //   navigate('/error/404');
      // }
      // if (users.data.ResultCode == 'INVALID') {
      //   navigate('/error/404');
      // }
    };
    getUsers();
  }, [token]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <>
      {tokenError == 'EXPIRED' && (
        <>
          <div className='d-flex'>
            <div className='me-2'></div>
          </div>
          <div className='py-20' style={{ flex: 'auto' }}>
            <div
              className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              style={{ marginTop: '240px', textAlign: 'center' }}
            >
              <h4 className='text-align-center mw-100 mh-300px theme-light-show'>
                User is already registered.
              </h4>
            </div>
          </div>
        </>
      )}
      {tokenError == 'ERROR' && (
        <>
          <div className='d-flex'>
            <div className='me-2'></div>
          </div>
          <div className='py-20' style={{ flex: 'auto' }}>
            <div
              className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              style={{ marginTop: '240px', textAlign: 'center' }}
            >
              <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
              <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                We can't find that page.
              </div>
              <div className='mb-3'>
                <img
                  src={toAbsoluteUrl('/media/auth/404-error.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
            </div>
          </div>
        </>
      )}
      {tokenError == 'InvalidToken' && (
        <>
          <div className='d-flex'>
            <div className='me-2'></div>
          </div>
          <div className='py-20' style={{ flex: 'auto' }}>
            <div
              className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              style={{ marginTop: '90px', textAlign: 'center' }}
            >
              <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
              <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                We can't find that page.
              </div>
              <div className='mb-3'>
                <img
                  src={toAbsoluteUrl('/media/auth/404-error.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
            </div>
          </div>
        </>
      )}
      {resultAuth == 'SUCCESS' && (
        <>
          <div className='d-flex'>
            <div className='me-2'></div>
          </div>
          <div className='py-20' style={{ flex: 'auto' }}>
            <div
              className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              style={{ marginTop: '240px', textAlign: 'center' }}
            >
              <img className='w-24 mb-4' src={CorrectSignLogo} alt='' />
              <h1 className='text-dark' style={{ fontSize: '45px' }}>
                Thank you
              </h1>
              <div
                className=''
                style={{ color: '#c3c3c3d6', fontSize: '16px' }}
              >
                Your account has been created successfully
              </div>
              <div className='mt-3'>
                <Link to='/login' className='btn btn-sm btn-primary text-sm'>
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
      {tokenError == 'SUCCESS' && resultAuth !== 'SUCCESS' && (
        <>
          <div className='py-5'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='text-start mb-10'>
                <h1
                  className='text-dark mb-3 fs-3x'
                  data-kt-translate='sign-up-title'
                >
                  Create an Account
                </h1>
                {/* <div
                  className='text-gray-400 fw-semibold fs-6'
                  data-kt-translate='general-desc'
                >
                  Administrator Create URL For 2hours.
                </div> */}
              </div>
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    {formik.status}
                  </div>
                </div>
              )}
              <div className='row fv-row mb-7'>
                <div className='col-xl-6'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    First name
                  </label>
                  <input
                    placeholder='Enter your first name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstname')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.firstname && formik.errors.firstname,
                      },
                      {
                        'is-valid':
                          formik.touched.firstname && !formik.errors.firstname,
                      }
                    )}
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.firstname}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-6'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Last name
                  </label>
                  <input
                    placeholder='Enter your last name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          formik.touched.lastname && formik.errors.lastname,
                      },
                      {
                        'is-valid':
                          formik.touched.lastname && !formik.errors.lastname,
                      }
                    )}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lastname}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Email
                </label>
                <input
                  id='email'
                  placeholder='Enter your email'
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className='form-control'
                  disabled
                />
              </div>
              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Password
                  </label>
                  <div className='position-relative mb-3'>
                    <input
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid':
                            formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid':
                            formik.touched.password && !formik.errors.password,
                        }
                      )}
                      {...formik.getFieldProps('password')}
                      type='password'
                      placeholder='Enter your password'
                      name='password'
                      autoComplete='off'
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                    <span
                      className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                      data-kt-password-meter-control='visibility'
                    >
                      <i className='ki-duotone ki-eye-slash fs-2'></i>
                      <i className='ki-duotone ki-eye fs-2 d-none'></i>
                    </span>
                  </div>
                  <div
                    className='d-flex align-items-center mb-3'
                    data-kt-password-meter-control='highlight'
                  >
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                  </div>
                </div>
                <div className='text-muted'>
                  Use 8 or more characters with a mix of letters, numbers &
                  symbols.
                </div>
              </div>

              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Confirm password
                </label>
                <input
                  type='password'
                  placeholder='Enter your confirm password'
                  autoComplete='off'
                  {...formik.getFieldProps('changepassword')}
                  className={clsx(
                    'form-control',
                    {
                      'is-invalid':
                        formik.touched.changepassword &&
                        formik.errors.changepassword,
                    },
                    {
                      'is-valid':
                        formik.touched.changepassword &&
                        !formik.errors.changepassword,
                    }
                  )}
                />
                {formik.touched.changepassword &&
                  formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.changepassword}</span>
                      </div>
                    </div>
                  )}
              </div>
              <div className='d-flex flex-stack'>
                {/* <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_signup_form_cancel_button'
                    className='btn btn-primary me-2 flex-shrink-0'
                  >
                    Cancel
                  </button>
                </Link> */}
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-primary me-2 flex-shrink-0 w-100'
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span
                      className='indicator-progress'
                      style={{ display: 'block' }}
                    >
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className='d-flex flex-center py-2'>
            <div className='me-2'></div>
            <div className='m-0'>
              <span
                className='text-gray-400 fw-bold fs-5 me-2'
                data-kt-translate='sign-up-head-desc'
              >
                Already a member ?
              </span>
              <Link
                to='/login'
                className='link-primary fw-bold fs-5'
                data-kt-translate='sign-up-head-link'
              >
                Sign In
              </Link>
            </div>
          </div>
          {/* <AuthFooter /> */}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    success: state.login.success,
    loading: state.login.loading,
    tokenData: state.login.tokenData,
    isNotValid: state.login.isNotValid,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  validTokenCheck: (params: any) => dispatch(usercheckTokenValidOrNot(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherInvitationAccount);
