import React from 'react'

const Terms = () => {
  return (
    <>
      <div className="py-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-3 fw-bold">Terms &amp; Conditions</h1>
                {/* <p className="lead px-8">Read our terms below to learn more about your rights and responsibilities as a Product Name user.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <p>Terms and Conditions For good and valuable consideration, the sufficiency and receipt of which is hereby acknowledged and accepted, the student enters into this release benefitting Hope City or Bring Me Hope Foundation or its parent company, affiliates, subsidiaries, and the officers and employees thereof (Company) to induce Company to accept Student at Company&rsquo;s school. I understand that by participating and being involved in the school and program that I agree to the terms and conditions set forth by the Company. I agree that Company may record and photograph student, family members, or anyone involved in activities or time in the school for use in any promotional or marketing activities relating to the school and/or the Company.</p>

              <p>As an essential condition of Student being accepted, I agree to comply with all the rules regarding student's and any instructions regarding safety. I will not photograph or record other persons at the school without the Company&rsquo;s express written permission. I represent and warrant that all information I am providing to Company on this release is valid, true and accurate.</p>

              <p>I acknowledge, accept, and assume the risks, hazards and dangers that may arise from participating in the school, including, but not limited to the following: injury as a result of school activities, physical injury up to and including death; the acts or negligence of others (including Company); spectator injury; harm from others or school staff; aggravation or re-injury of preexisting medical conditions; location-specific injury; injury resulting from third world conditions or insufficient medical care; etc. I also acknowledge that there are numerous unknown risks, hazards, and dangers that I may be exposed to and encounter in connection with the school.</p>

              <p>Accordingly, I assume full and complete responsibility and liability for any and all risks, hazards, and dangers (known and unknown) arising in connection with Company&rsquo;s school that might come to me, family members, or anyone involved in the Company&rsquo;s school. Additionally, I hereby agree not to sue and irrevocably and unconditionally release, waive and forever discharge Company, its past, present and future parents, subsidiaries (whether or not wholly-owned), affiliates, divisions, agents, owners, members, officers, representatives, employees, jointly and individually (hereinafter collectively referred to as &ldquo;Releasees&rdquo;), of and from any and all actions, causes of action, claims, accidents, demands, damages, costs, losses, liabilities, expenses, compensations, third party actions, suits in law or in equity, including claims or suits for contributions and/or indemnity, including suits or other actions brought in a foreign jurisdiction, of whatever nature, and all special, indirect, consequential, incidental, or exemplary damages on account of, or in any way arising from or relating to the Company&rsquo;s school. It is my intention that this be a complete and total release of the Releasees, made without reservation, and perpetually binding upon my heirs, executors, administrators, successors, guardians, conservators, and the like.</p>

              <p>I represent and warrant that I have carefully read this release form, that I have reviewed it, that I understand its contents, and that I have not relied on any non-included statement or representation made by any person or entity including, without limitation, Company, its owners or any of its respective agents with respect to my signing this release.</p>

              <p>I further agree that I shall be liable for any attorneys&rsquo; fees and costs incurred by Company in connection with any claim or lawsuit brought in violation of this release. This release shall be binding on all of my successors-in-interest and heirs. This release sets forth the entire agreement between us with respect to the subject matter hereof and may not be altered or amended except in writing signed by both parties. This release shall be governed by the laws of the State of California and the parties agree that exclusive jurisdiction will lie in the state and federal courts located in San Diego County, California.</p>

              <p>I authorize Bring Me Hope Project, LLC (&ldquo;Company&rdquo;), Production&rsquo;s agents, successors, assigns, and designees use of his/her photo, likeness or image, the ability to edit such as Production may desire, and incorporate such into the Film, any versions of the Film and all related materials thereof, including but not limited to promotion and advertising materials. It is understood and agreed that Production shall retain final editorial, artist, and technical control of the Film and the content of the Film. Production may use, and authorize others to use, the Film, any portions thereof and the Recording in all markets, manner, formats and media, whether now known or hereafter developed, throughout the world, in perpetuity. Production, and Production&rsquo;s successors and assigns, shall own all right, title and interest, including the copyright, in and to the Film, and related materials, to be used and disposed of, without limitation, as Production shall in Production&rsquo;s sole discretion determine.</p>

              <p>I represent that I agree that the grant and release contained therein binds me to all of the terms thereof.</p>

              <p>All parties agree to use the services in accordance with the following terms:</p>

              <p>All communication, data, messages, information, images are to only be used for the intended purposes and no unauthorized use shall be made.</p>

              <p>Any participation in services is the sole responsibility of the user and the user agrees to use the services in a responsible manner, including but not limited to, document uploading, communication, language, &#8230; The following are against our terms and conditions and will result in immediate disciplinary action; Threats or acts of violence, inappropriate dress, &#8230; or any other misuse of services. If you participate in a chat that you find inappropriate, you should report this immediately to the appropriate staff member. Hope City cannot be held responsible for anything that happens in chat, messaging.</p>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Terms;
