import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import FooterActivityPage from '../activityfooter/FooterActivityPage';
import { connect, useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';

const AIImageShowActivity: React.FC<any> = (props: any) => {
  const {
    singleLessonData,
    nativeLang,
    targetLang,
    sectionType,
    setSectionType,
    setVideoSectionShow,
    setListenITActivityShow,
    setAiImageShowActivityShow,
    profileImage,
    setProfileImage,
    setPercentageHide,
  } = props;

  var data = singleLessonData?.Activity_Details?.filter(
    (x: any) => x.Section == sectionType
  )[0];

  const [imageLoader, setImageLoader] = useState(false);
  const [result, setResult] = useState<any>();

  const onPreviousButton = () => {
    setAiImageShowActivityShow(false);
    setListenITActivityShow(true);
  };

  const onHandleSubmit = () => {
    setAiImageShowActivityShow(false);
    setVideoSectionShow(true);
    setPercentageHide(true);
  };

  const {
    aiImageUrlloading,
    aiImageError,
    aiImageSuccess,
    aiImageUrl,
    showText,
  } = useSelector((state: any) => state.openai);

  useEffect(() => {
    if (aiImageUrl) {
      setImageLoader(false);
    } else {
      setImageLoader(true);
    }
  }, [aiImageUrl]);

  useEffect(() => {
    console.log(data);
    if (data) {
      const resultData = data?.Show_Massage?.Questions?.filter((data: any) => {
        return data.Language_Code == nativeLang;
      })[0];
      setResult(resultData);
    }
  }, [data]);

  const ViewResult = (result: any) => {
    // const Result = datas.data.Show_Massage.Questions.filter((data: any) => { return data.Language_Code == nativeLang })[0]
    return (
      <div className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'>
        <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
          <div className='container layout_container'>
            <div className='row'>
              <div className='col-12'>
                <div className='w-100 position-relative d-flex flex-column'>
                  <div className='row d-grid gap-3'>
                    <div className='col-12 mx-auto'>
                      <div className='w-100'>
                        <div className='progress-bar mt-3'>
                          <ProgressBar animated now={100} />
                        </div>
                      </div>
                    </div>
                    <div
                      className='col-12 mx-auto'
                      style={{ paddingTop: '47%' }}
                    >
                      <div className='w-100'>
                        <div
                          className='card'
                          style={{ backgroundColor: '#b7b7b7' }}
                        >
                          <div className='card-body ai-photo-card'>
                            <p className='fw-bold fs-5'>
                              {result?.data?.Message1}
                            </p>
                            <p className='fw-bold fs-5'>
                              {result?.data?.Message2}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterActivityPage
            nextButton={true}
            onPreviousButton={onPreviousButton}
            previousButton={sectionType != 'QuizSection' ? true : false}
            lesson={singleLessonData?._id || undefined}
            handleVideoNextButton={onHandleSubmit}
            showImageActivity={true}
            urlHash={singleLessonData?._id || undefined}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <ViewResult data={result} />
    </>
  );
};

export default AIImageShowActivity;
