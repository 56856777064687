import LoaderContent from 'app/components/ContentLoader';
import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';
import { toAbsoluteUrl } from 'theme/helpers';

const CourseBadge = ({ getCourseByCourseInvitedIdQuery }: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <div className='card card-stretch'>
      <LoaderContent loading={getCourseByCourseInvitedIdQuery.isLoading} />
      {getCourseByCourseInvitedIdQuery?.isSuccess && (
        <>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-gray-800'>
                {/* Award for completing course */}
                {formatMessage({
                  id: 'COURSE.RATINGS.AWARD_FOR_COMPLETING_COURSE',
                })}
              </h3>
            </div>
          </div>
          <div className='card-body'>
            <div className='row g-3'>
              <div className='col-md-4'>
                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                  <img
                    src={
                      getCourseByCourseInvitedIdQuery?.data?.ResultObject
                        ?.course?.badge
                        ? getCourseByCourseInvitedIdQuery?.data?.ResultObject
                            ?.course?.badge
                        : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                    }
                    className='w-100 h-auto rounded'
                    alt='Badge'
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div className='d-flex align-items-center h-100'>
                  <p
                    className='card-text fs-4'
                    title={
                      getCourseByCourseInvitedIdQuery?.data?.ResultObject
                        ?.course?.badgeText
                    }
                  >
                    {getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.badgeText ? (
                      getCourseByCourseInvitedIdQuery?.data?.ResultObject
                        ?.course?.badgeText
                    ) : (
                      <div className='text-muted'>Text not available.</div>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CourseBadge;
