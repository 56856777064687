import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_2 = (props: any) => {
  const { formik, hasFieldError, removeHightlight, onHandleRquirementSubject } =
    props;

  return (
    <>
      <div className='p-3'>
        <label className='form-label fw-bold'>
          Does your local school teach this subject and meet these requirements
          for the subject? <span className='text-danger'> * </span>
        </label>
        <p className='form-label fw-bold'>
          ¿Su escuela local enseña esta materia y cumple con estos requisitos
          para la materia?
        </p>
      </div>
      <div
        className={
          hasFieldError?.includes('English_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          ENGLISH REQUIREMENTS: How will your student meet the requirements for
          English? <span className='text-danger'> * </span>
        </label>

        <p className='form-label fw-bold'>
          REQUISITOS DE INGLÉS: ¿Cómo cumplirá su estudiante con los requisitos
          de inglés?
        </p>
        <p className='form-label mb-2'>
          English Requirements: Literature and language, speaking, reading,
          listening, spelling, handwriting, and composition.
        </p>
        <p className='form-label mb-2'>
          Requisitos de inglés: Literatura y lenguaje, hablar, leer, escuchar,
          ortografía, escritura a mano y composición.
        </p>

        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'English_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('English_Requirement_Subject');
                      onHandleRquirementSubject(
                        'English_Requirement_Subject',
                        data?.Value,
                        null
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>

        <FieldsErrorText
          touched={formik.touched.English_Requirement_Subject}
          errors={formik.errors.English_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_2;
