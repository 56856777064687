/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../../../theme/assets/css/lesson_activity.css';
import { connect, useSelector } from 'react-redux';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { fetchLessonByIdAction, fetchLessonsAction } from 'store/actions';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import { formatList } from 'app/components/Lesson/Lesson.utils';
import LessonCard from 'app/components/Lesson/LessonCard';
import CompletedLessonsCard from 'app/components/Lesson/CompletedLessonsCard';

const StartLesson: React.FC = (props: any) => {
  const { lessongetReq } = props;

  const navigate = useNavigate();

  const [completedLessons, setCompletedLessons] = useState<any>([]);
  const [notCompletedLessons, setNotCompletedLessons] = useState<any>([]);

  const { data, loading, error } = useSelector(
    (state: any) => state.lessons.list
  );

  useEffect(() => {
    lessongetReq();
  }, []);

  const handleStartLesson = (id: any) => {
    navigate(`/start-lesson/${id}`);
  };

  useEffect(() => {
    if (data != null) {
      const completedDataValue = data.completed?.filter((resultData: any) => {
        var completeCondition = false;
        resultData.Lesson_Age_Group.forEach((result: any) => {
          if (data.Age >= parseInt(result.split('+')[0])) {
            completeCondition = true;
          }
          if (
            data.Age >= parseInt(result.split('-')[0]) &&
            data.Age <= parseInt(result.split('-')[1])
          ) {
            completeCondition = true;
          }
        });
        return completeCondition;
      });

      const notCompletedDataValue = data.notCompleted?.filter(
        (resultData: any) => {
          var completeCondition = false;
          resultData.Lesson_Age_Group.forEach((result: any) => {
            if (data.Age >= parseInt(result.split('+')[0])) {
              completeCondition = true;
            }
            if (
              data.Age >= parseInt(result.split('-')[0]) &&
              data.Age <= parseInt(result.split('-')[1])
            ) {
              completeCondition = true;
            }
          });
          return completeCondition;
        }
      );

      setCompletedLessons(
        formatList(Array.isArray(completedDataValue) ? completedDataValue : [])
      );
      setNotCompletedLessons(
        formatList(
          Array.isArray(notCompletedDataValue) ? notCompletedDataValue : []
        )
      );
    }
  }, [data]);

  return (
    <>
      <Loader loading={loading} />
      <>
        <Row className='g-5 g-xl-10'>
          {error && (
            <Alert variant='danger'>
              {error.message}
              <u>
                <a
                  href='#'
                  className='px-2 text-danger underline'
                  onClick={() => lessongetReq()}
                >
                  try again
                </a>
              </u>
            </Alert>
          )}
          {notCompletedLessons.length == 0 && completedLessons.length != 0 && (
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Card.Title as={'h4'} className='text-success'>
                    All lesson are completed please check later
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          )}
          {notCompletedLessons && notCompletedLessons.length > 0 && (
            <Col md={12} key={notCompletedLessons[0].id}>
              <LessonCard
                onStart={(id) => handleStartLesson(id)}
                loading={loading}
                lesson={notCompletedLessons[0]}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12}>
            {completedLessons.length > 0 && (
              <CompletedLessonsCard
                loading={loading}
                lessons={completedLessons}
                onStart={(id: any) => handleStartLesson(id)}
              />
            )}
          </Col>
        </Row>
      </>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.lessons.list.loading,
    error: state.lessons.list.error,
    data: state.lessons.list.data,
    lessonDetails: state.lessons.details.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  lessongetReq: (config: any) => dispatch(fetchLessonsAction(config)),
  lessongetByIdReq: (param: any) => dispatch(fetchLessonByIdAction(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartLesson);
