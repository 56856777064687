import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EditResponseAndSaveButton = (props: any) => {
  const { setStep } = props;
  const navigate = useNavigate();
  const { formatMessage } = useIntlCustom();
  return (
    <div className='mt-5'>
      <button className='btn btn-primary m-3' onClick={() => setStep(1)}>
        {formatMessage({
          id: 'BUTTON.EDIT_RESPONSE',
        })}
      </button>
      <button
        className='btn btn-primary m-3'
        onClick={() => navigate('/account')}
      >
        {formatMessage({
          id: 'BUTTON.SAVE_AND_CLOSE',
        })}
      </button>
    </div>
  );
};

export default EditResponseAndSaveButton;
