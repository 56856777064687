import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_3 = (props: any) => {
  const {
    formik,
    formK12,
    formK6,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
  } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Math_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          MATH REQUIREMENTS: How will your student meet the requirements?
        </label>
        <p className='form-label fw-bold'>
          REQUISITOS DE MATEMÁTICAS: ¿Cómo cumplirá su estudiante con los
          requisitos?
        </p>
        {formK12 && (
          <>
            <p className='form-label mb-2'>
              MATH Requirements: Including instruction designed to develop
              mathematical understandings, operational skills, and insight into
              problem-solving procedures.
            </p>
            <p className='form-label mb-2'>
              Requisitos de MATEMÁTICAS: Incluida la instrucción diseñada para
              desarrollar la comprensión matemática, las habilidades operativas
              y la comprensión de los procedimientos de resolución de problemas.
            </p>
          </>
        )}
        {formK6 && (
          <>
            <p className='form-label mb-2'>
              MATH Requirements: Concepts, operational skills, and problem
              solving.
            </p>
            <p className='form-label mb-2'>
              Requisitos de MATEMÁTICAS: Conceptos, habilidades operativas y
              resolución de problemas.
            </p>
          </>
        )}
        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Math_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('Math_Requirement_Subject');
                      onHandleRquirementSubject(
                        'Math_Requirement_Subject',
                        data?.Value,
                        null
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Math_Requirement_Subject}
          errors={formik.errors.Math_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_3;
