import React from 'react';
import { extractNumericPart } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_9 = (props: any) => {
  const { formik, formType, hasFieldError, onChange, removeHightlight } = props;

  const GradeData = [
    '0',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
  ];

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div
            className={
              hasFieldError?.includes('School_Grade')
                ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                : 'p-3'
            }
          >
            <p className='form-label fw-bold'>
              School grade Requirements Continued / Continuación de los
              requisitos del grado escolar
            </p>
            <label className='form-label fw-bold mt-2'>
              What Grade is your student?{' '}
              <span className='text-danger'> * </span>
            </label>
            <p className='form-label fw-bold'>
              ¿En qué grado está su estudiante?
            </p>

            {GradeData?.map((grade, index) => {
              return (
                <div className='form-check mt-4' key={index}>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'School_Grade'}
                    dataValue={grade}
                    onChange={() => {
                      removeHightlight('School_Grade');
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {index != 0 &&
                      `${grade} Grade - ${extractNumericPart(grade)}° Grado`}
                    {index == 0 && `Kindergarten / Preescolar`}
                  </label>
                </div>
              );
            })}
            <FieldsErrorText
              touched={formik.touched.School_Grade}
              errors={formik.errors.School_Grade}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Section7_12_9;
