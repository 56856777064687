import LoaderNew from 'app/components/LoaderNew';
import { useDeleteQuery, useGetQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Swal from 'sweetalert2';
import { PageTitle } from 'theme/layout/core';
import CourseDetails from './CourseDetails';
import CoursePointCounting from './CoursePointCounting';
import CourseGrading from './CourseGrading';
import Log from './Log';
import CourseBadge from './CourseBadge';
import CompletedCourseNotificationModal from 'app/components/CompletedCourseNotificationModal';
import useIntlCustom from 'hooks/useIntlCustom';
import GPAdetails from './GPAdetails';

const Grades = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntlCustom();
  const { courseInviteId } = useParams();
  const [congratulationModel, setCongratulationModel] = useState(false);

  const getAssignmentByCourseInviteId: any = useGetQuery(
    'getAssignmentByCourseInviteId',
    `/course/getAssignmentByCourseInviteId`
  );

  const getMonthlyGpaByCourseInviteId: any = useGetQuery(
    'getMonthlyGpaByCourseInviteId',
    `/course/getMonthWiseData`
  );

  const getGradesPercentagePointsByCourseQuery: any = useGetQuery(
    'getGradesPercentagePointsByCourseQuery',
    `/course/getGradesPercentagePointsByCourse`
  );
  const deleteEnrolledCourseQuery: any = useDeleteQuery(
    'deleteEnrolledCourse',
    '/course/enrolled/delete'
  );
  const getCourseByCourseInvitedIdQuery: any = useGetQuery(
    'getCourseByCourseInvitedIdQuery',
    '/course/getCourseByCourseInvitedId'
  );

  // const getGradesPercentagePointsByDiplomaCourseQuery = useGetQuery(
  //   'getGradesPercentagePointsByDiplomaCourseQuery',
  //   `/course/getGradesPercentagePointsByDiplomaCourse`
  // );

  useEffect(() => {
    getCourseByCourseInvitedIdQuery.mutateAsync({
      params: { id: courseInviteId },
    });

    getGradesPercentagePointsByCourseQuery.mutateAsync({
      params: { id: courseInviteId },
    });
    getAssignmentByCourseInviteId.mutateAsync({
      params: { id: courseInviteId },
    });
    getMonthlyGpaByCourseInviteId.mutateAsync({
      params: { id: courseInviteId },
    });
  }, []);

  useEffect(() => {
    if (getCourseByCourseInvitedIdQuery.isSuccess) {
      if (!getCourseByCourseInvitedIdQuery?.data?.ResultObject?.courseInvite) {
        navigate(`/course`);
      } else {
        setCongratulationModel(
          getCourseByCourseInvitedIdQuery?.data?.ResultObject?.courseInvite
            ?.isCompleted &&
            getCourseByCourseInvitedIdQuery?.data?.ResultObject?.courseInvite
              ?.isPopupShow === false
        );
      }
    }
  }, [getCourseByCourseInvitedIdQuery.isSuccess]);

  const onHideCongratulationModal = () => {
    setCongratulationModel(false);
    navigate(`/course`);
  };

  useEffect(() => {
    if (deleteEnrolledCourseQuery.isSuccess) {
      Swal.fire(
        'Deactivate Course!',
        'Your Course has been deactivated.',
        'success'
      ).then((result) => {
        navigate('/course');
      });
    }
  }, [deleteEnrolledCourseQuery.isSuccess]);

  return (
    <>
      <CompletedCourseNotificationModal
        show={congratulationModel}
        onHide={onHideCongratulationModal}
      />
      <PageTitle breadcrumbs={[]}>
        {formatMessage({
          id: 'COURSE.GRADES',
        })}
      </PageTitle>
      <div className='row g-3'>
        <LoaderNew
          loading={deleteEnrolledCourseQuery?.isLoading}
          isFull={true}
        />
        <div
          className={`${
            getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
              ?.badgeText
              ? 'col-md-6 col-lg-12 col-xl-6 col-12'
              : 'col-12'
          }`}
        >
          <CourseDetails
            courseInviteId={courseInviteId}
            getCourseByCourseInvitedIdQuery={getCourseByCourseInvitedIdQuery}
            deleteEnrolledCourseQuery={deleteEnrolledCourseQuery}
          />
        </div>
        <div className='col-md-6 col-lg-12 col-xl-6 col-12'>
          <CoursePointCounting
            getGradesPercentagePointsByCourseQuery={
              getGradesPercentagePointsByCourseQuery
            }
            getCourseByCourseInvitedIdQuery={getCourseByCourseInvitedIdQuery}
            // getGradesPercentagePointsByDiplomaCourseQuery={
            //   getGradesPercentagePointsByDiplomaCourseQuery
            // }
          />
        </div>
        <div className='col-md-6 col-lg-12 col-xl-6 col-12'>
          <CourseGrading
            getCourseByCourseInvitedIdQuery={getCourseByCourseInvitedIdQuery}
          />
        </div>
        {getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
          ?.badgeText && (
          <div className='col-md-6 col-lg-12 col-xl-6 col-12'>
            <CourseBadge
              getCourseByCourseInvitedIdQuery={getCourseByCourseInvitedIdQuery}
            />
          </div>
        )}

        <div className={`col-12`}>
          <Log
            courseInviteId={courseInviteId}
            getAssignmentByCourseInviteId={getAssignmentByCourseInviteId}
          />
        </div>
        <div className={`col-12`}>
          <GPAdetails
            courseInviteId={courseInviteId}
            getMonthlyGpaByCourseInviteId={getMonthlyGpaByCourseInviteId}
          />
        </div>
      </div>
    </>
  );
};

export default Grades;
