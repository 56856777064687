export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const USER_CHECK_TOKEN_VALID_OR_NOT = 'USER_CHECK_TOKEN_VALID_OR_NOT';
export const USER_CHECK_TOKEN_VALID_OR_NOT_SUCCESS =
  'USER_CHECK_TOKEN_VALID_OR_NOT_SUCCESS';
export const USER_CHECK_TOKEN_VALID_OR_NOT_ERROR =
  'USER_CHECK_TOKEN_VALID_OR_NOT_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';

export const API_ERROR = 'API_ERROR';
