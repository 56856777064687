import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { BsFolder } from 'react-icons/bs';
import useIntlCustom from 'hooks/useIntlCustom';

const TrashFolderList = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    trashFolderList,
    setFolderDataList,
    setCourseList,
    setFolderParentID,
    onFolderClick,
    selectedFolderId,
    setTreeData,
    treeData,
    courseDataID,
    setBreadcrumbs,
    getBreadcrumb,
    UserID,
    getFolderList,
    isButtonVisible,
    setButtonVisibility,
    selectedFolder,
    handleSelectFolder,
    handleDeleteMany,
    handleDeleteConfirmation,
    handleRestore,
    setTrashFolderList,
  } = props;

  return (
    <>
      <SortableList>
        {trashFolderList?.map((item: any, index: any) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            dataItem={item}
            setFolderDataList={setFolderDataList}
            setCourseList={setCourseList}
            setFolderParentID={setFolderParentID}
            onFolderClick={onFolderClick}
            selectedFolderId={selectedFolderId}
            setTreeData={setTreeData}
            setTrashFolderList={setTrashFolderList}
            treeData={treeData}
            courseDataID={courseDataID}
            setBreadcrumbs={setBreadcrumbs}
            getBreadcrumb={getBreadcrumb}
            isButtonVisible={isButtonVisible}
            setButtonVisibility={setButtonVisibility}
            selectedFolder={selectedFolder}
            handleDeleteMany={handleDeleteMany}
            handleSelectFolder={handleSelectFolder}
            handleRestore={handleRestore}
            UserID={UserID}
            getFolderList={getFolderList}
            handleDeleteConfirmation={handleDeleteConfirmation}
          />
        ))}
        {trashFolderList && trashFolderList?.length === 0 && (
          <div className='text-center fw-bold fs-3 p-5'>
            No Folder Available
          </div>
        )}
      </SortableList>
    </>
  );
};

export default TrashFolderList;

const SortableList: any = SortableContainer(({ children }: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <>
      <Row className='g-5'>
        <h3>
          {formatMessage({
            id: 'FOLDER',
          })}
        </h3>
        {children}
      </Row>
    </>
  );
});

const SortableItem: any = SortableElement((props: any) => {
  const { dataItem, treeData, handleSelectFolder, selectedFolder } = props;

  const isDescendant = (parentId: string | null, childId: string): boolean => {
    const parentFolder = treeData.find(
      (folder: any) => folder._id === parentId
    );
    if (!parentFolder) {
      return false;
    }

    if (parentFolder.ParentID === childId) {
      return false;
    }

    if (parentFolder.ParentID === null) {
      return true;
    }

    return isDescendant(parentFolder.ParentID, childId);
  };

  return (
    <>
      <Col lg={4} data-items={dataItem}>
        <div
          className='card shadow-sm h-100 bg-light-warning  border-warning border border-dashed'
          style={{ background: '#ffdddd' }}
        >
          <div className='card-body d-flex flex-column'>
            <button
              className='btn btn-light text-gray-400 fs-5 fw-bold'
              draggable='true'
            >
              <BsFolder size={23} /> {dataItem && dataItem.FolderName}
              <input
                className='form-check-input ms-10 mt-0 border border-2 border-warning'
                type='checkbox'
                value=''
                id='flexCheckDefault'
                checked={selectedFolder.includes(dataItem._id)}
                onChange={(e) => handleSelectFolder(dataItem._id, e)}
              />
            </button>
          </div>
        </div>
      </Col>
    </>
  );
});
