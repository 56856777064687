function useOperatingSystem() {
  const userAgent = navigator.userAgent;
  // if (/iPad|iPhone|iPod/.test(userAgent)) {
  if (/iPhone/.test(userAgent) && userAgent.includes('FBAV')) {
    return 'iOS';
  } else if (/Android/.test(userAgent) && userAgent.includes('FBAV')) {
    return 'Android';
  } else {
    return 'Unknown';
  }
}
export default useOperatingSystem;
