import {
  API_ERROR,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  USER_CHECK_TOKEN_VALID_OR_NOT,
  USER_CHECK_TOKEN_VALID_OR_NOT_SUCCESS,
  USER_CHECK_TOKEN_VALID_OR_NOT_ERROR,
} from './actionTypes';

import {
  loginRequestPayload,
  loginSuccessPayload,
  loginfailurePayload,
  LoginRequest,
  LoginSucces,
  LoginFailure,
  logoutRequest,
} from './types';

export const loginRequest = (payload: any): LoginRequest => ({
  type: LOGIN_USER,
  payload,
});

export const loginSuccess = (payload: any): LoginSucces => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload: any): LoginFailure => ({
  type: LOGIN_FAILURE,
  payload,
});

export const logoutUser = (): logoutRequest => ({
  type: LOGOUT_USER,
});

export const registerationRequest = (payload: any) => ({
  type: REGISTER_USER,
  payload,
});

export const registartionSuccessRequest = (payload: any) => ({
  type: REGISTER_USER_SUCCESS,
  payload,
});

export const registartionFailure = (payload: any) => ({
  type: REGISTER_USER_ERROR,
  payload,
});

export const usercheckTokenValidOrNot = (payload: any) => {
  return {
    type: USER_CHECK_TOKEN_VALID_OR_NOT,
    payload,
  };
};
export const usercheckTokenValidSuccess = (payload: any) => {
  return {
    type: USER_CHECK_TOKEN_VALID_OR_NOT_SUCCESS,
    payload,
  };
};
export const usercheckTokenValidOrNotError = (payload: any) => {
  return {
    type: USER_CHECK_TOKEN_VALID_OR_NOT_ERROR,
    payload,
  };
};
