import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import './index.css';
import useRandomNumber from 'hooks/useRandomNumber';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';

const VideoNewLayout = ({ src, ...props }: any) => {
  const {
    muted,
    onHandleLeftButton = null,
    onHandleRightButton = null,
    onHandleLeftButtonTitle = null,
    onHandleRightButtonTitle = null,
  } = props;
  const videoRef = useRef<HTMLVideoElement>();
  const [mutedVideo, setMutedVideo] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    // 👇 Get the DOM element from the React element
    const element = videoRef.current;

    if (!element) return;

    if (muted) {
      element.defaultMuted = true;
      element.muted = true;
      setMutedVideo(muted);
    }
  }, []);

  const toggleMute = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const element = videoRef.current;
    if (element) {
      element.muted = !element.muted;
      setMutedVideo(element.muted);
    }
  }, []);

  return (
    <div className='position-relative overflow-hidden'>
      <video
        {...props}
        className={`object-fit-fill ${props.className}`}
        ref={videoRef}
        onLoadedData={() => setLoad(true)}
        onLoadedDataCapture={() => setLoad(true)}
        onLoadStart={() => setLoad(true)}
        onLoadStartCapture={() => setLoad(true)}
      >
        <source
          src={`${src}?v1=${useRandomNumber(100000000000000)}`}
          type='video/mp4'
        ></source>
        Your browser does not support the video tag.
        <track kind='captions' />
      </video>

      <div className='position-absolute bottom-0 w-100' style={{ zIndex: 99 }}>
        <div className='d-flex flex-column gap-2'>
          {load && (
            <div className='d-flex justify-content-end'>
              <button
                onClick={toggleMute}
                className='border border-0 rounded-circle video-overlay-bg-color m-2 text-light cursor-pointer fs-1'
              >
                {mutedVideo ? <MdVolumeOff /> : <MdVolumeUp />}
              </button>
            </div>
          )}
          {(onHandleLeftButton || onHandleRightButton) && (
            <div className='video-overlay-bg-color p-2 rounded-bottom'>
              <div
                className={`d-flex gap-2 ${
                  onHandleLeftButton && onHandleRightButton
                    ? 'justify-content-between'
                    : 'justify-content-end'
                }`}
              >
                <>
                  {onHandleLeftButton && (
                    <button
                      type='button'
                      className='btn btn-secondary btn-sm rounded-pill text-truncate'
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleLeftButton();
                      }}
                    >
                      {onHandleLeftButtonTitle}
                    </button>
                  )}
                </>
                <>
                  {onHandleRightButton && (
                    <button
                      type='button'
                      className='btn btn-primary btn-sm rounded-pill text-truncate'
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleRightButton();
                      }}
                    >
                      {onHandleRightButtonTitle}
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(VideoNewLayout);
