import React, { useState } from 'react';

const Stepper = (props: any) => {
  const { step, stepper } = props;
  const steps: any = Array(stepper).fill(0);

  return (
    <>
      <div className='card mt-5 mb-5'>
        <div className='card-body'>
          <div className='container mt-5'>
            <div className='row text-center d-flex justify-content-center'>
              <div className='col-md-10'>
                <div className='custom-stepper'>
                  {steps?.map((data: any, index: any) => {
                    const stepIndex = index + 1;
                    return (
                      <>
                        <div
                          key={index}
                          className={`btn rounded-circle ${
                            step >= stepIndex ? 'btn-primary' : 'btn-secondary'
                          }`}
                        >
                          {stepIndex}
                        </div>
                        {stepIndex !== steps.length && (
                          <div className='step-divider'></div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepper;
