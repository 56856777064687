import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  registartionFailure,
  registartionSuccessRequest,
  usercheckTokenValidSuccess,
  usercheckTokenValidOrNotError,
} from './actions';
import {
  LOGIN_USER,
  REGISTER_USER,
  USER_CHECK_TOKEN_VALID_OR_NOT,
} from './actionTypes';
import { post } from '../../helpers/api-helper';
import { IAuth } from './types';
import axios from 'axios';

const login = (data: any) => post('account/Adminlogin', data);
const registeration = (data: any) => post('user/register', data);
const validToken = (data: any) => post('invitation/validCheckToken', data);

// const login = async (payload: { email: string, password: string }) => {
//     const { data } = await axios.post<IAuth>(
//         "http://localhost:9000/api/account/Userlogin",
//         { email: payload.email, password: payload.password }, {
//         headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json"
//         },
//     }
//     );
//     return data
// }

function* loginSaga(action: any) {
  try {
    const response: {
      token: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(login, {
      email: action.payload.email,
      password: action.payload.password,
      CourseId: action.payload.CourseId,
    });

    if (response.ResultCode !== 'SUCCESS') {
      yield put(loginFailure({ error: response.ResultMessage }));
    } else {
      const lsValue = JSON.stringify(response.ResultObject);
      localStorage.setItem('kt-auth-react-v', lsValue);
      yield put(loginSuccess({ token: response.token }));
    }
  } catch (e: any) {
    yield put(loginFailure({ error: e.massage }));
  }
}

function* register(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(registeration, {
      ID: action.payload.ID,
      FirstName: action.payload.firstname,
      LastName: action.payload.lastname,
      Email: action.payload.email,
      Password: action.payload.password,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(registartionFailure({ error: response }));
    } else {
      yield put(registartionSuccessRequest({ success: response }));
    }
  } catch (error) {}
}

function* checkTokenvalid(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(validToken, { token: action.payload.Token });
    if (response.ResultCode !== 'INVALID') {
      yield put(usercheckTokenValidOrNotError({ error: response }));
    } else {
      yield put(usercheckTokenValidSuccess({ tokenData: response }));
    }
    if (response.ResultCode !== 'SUCCESS') {
      yield put(usercheckTokenValidOrNotError({ error: response }));
    } else {
      yield put(usercheckTokenValidSuccess({ tokenData: response }));
    }
  } catch (error) {
    yield put(usercheckTokenValidOrNotError(error));
  }
}

function* authSaga() {
  yield all([takeLatest(LOGIN_USER, loginSaga)]);
  yield all([takeLatest(REGISTER_USER, register)]);
  yield all([takeLatest(USER_CHECK_TOKEN_VALID_OR_NOT, checkTokenvalid)]);
}

export default authSaga;
