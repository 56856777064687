import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_6 = (props: any) => {
  const {
    formik,
    formK12,
    formK6,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
  } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Visual_Arts_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          VISUAL AND PERFORMING ARTS REQUIREMENTS: How will your student meet
          the requirements? <span className='text-danger'> * </span>
        </label>
        <p className='form-label fw-bold'>
          ARTES VISUALES Y ESCÉNICAS: ¿Cómo cumplirá su estudiante con los
          requisitos?
        </p>
        {formK12 && (
          <>
            <p className='form-label mb-2'>
              VISUAL AND PERFORMING ARTS: Dance, music, theater, and visual
              arts, with emphasis upon development of aesthetic appreciation and
              the skills of creative expression.
            </p>
            <p className='form-label mb-2'>
              ARTES VISUALES Y ESCÉNICAS: Danza, música, teatro y artes
              visuales, con énfasis en el desarrollo de la apreciación estética
              y las habilidades de expresión creativa.
            </p>
          </>
        )}{' '}
        {formK6 && (
          <>
            <p className='form-label mb-2'>
              VISUAL AND PERFORMING ARTS: Including instruction in the subjects
              of dance, music, theatre, and visual arts, aimed at the
              development of aesthetic appreciation and the skills of creative
              expression.
            </p>
            <p className='form-label mb-2'>
              ARTES VISUALES Y ESCÉNICAS: Danza, música, teatro y artes
              visuales, con énfasis en el desarrollo de la apreciación estética
              y las habilidades de expresión creativa.
            </p>
          </>
        )}
        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Visual_Arts_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('Visual_Arts_Requirement_Subject');
                      onHandleRquirementSubject(
                        'Visual_Arts_Requirement_Subject',
                        data?.Value,
                        null
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Visual_Arts_Requirement_Subject}
          errors={formik.errors.Visual_Arts_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_6;
