import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ClearFormModel(props: any) {
    const { handleClose, handleShow, show, onFormReset } = props;

    const onClearForm = () => {
        onFormReset()
        handleClose()
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Clear form?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This will remove your answers from all questions and cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={onClearForm}>Clear form</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ClearFormModel;