import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { getCurrentYear } from '../enrollmenthooks/EnrollmentHooks';

const SchoolYearShowDetails = (props: any) => {
  const {
    showSchoolYearModel,
    setShowSchoolYearModel,
    checkSchoolYearType,
    handleNoButton,
    calenderYear,
  } = props;

  const CalanderYear = useMemo(() => {
    if (calenderYear !== '') {
      return calenderYear.split('/');
    }
    return [];
  }, [calenderYear]);

  return (
    <>
      <Modal
        show={showSchoolYearModel}
        onHide={() => setShowSchoolYearModel(false)}
        size='lg'
        centered
        scrollable
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='fw-bold'>
          {checkSchoolYearType == 'Calendar year' &&
            `Please confirm. Your student’s school year started in January/February of ${
              CalanderYear.length > 0 ? CalanderYear[0] : getCurrentYear
            } and will finish in December of ${
              CalanderYear.length > 0 ? CalanderYear[0] : getCurrentYear
            }? / Por favor confirmar. ¿El año escolar de su estudiante comenzó en enero/febrero de ${
              CalanderYear.length > 0 ? CalanderYear[0] : getCurrentYear
            } y terminará en diciembre de ${
              CalanderYear.length > 0 ? CalanderYear[0] : getCurrentYear
            }?`}
          {checkSchoolYearType == 'USA school year' &&
            `Please confirm. Your student’s school year started in July/August of ${
              CalanderYear.length > 0 ? CalanderYear[0] : getCurrentYear
            } and will finish in June of ${
              CalanderYear.length > 0 ? CalanderYear[1] : getCurrentYear
            }? / Por favor confirmar. ¿El año escolar de su estudiante comenzó en julio/agosto de ${
              CalanderYear.length > 0 ? CalanderYear[0] : getCurrentYear
            } y terminará en junio de ${
              CalanderYear.length > 0 ? CalanderYear[1] : getCurrentYear
            }?`}
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button
            variant='primary'
            onClick={() => setShowSchoolYearModel(false)}
          >
            YES, this is right / Si, esto es correcto.
          </Button>
          <Button variant='danger' onClick={() => handleNoButton()}>
            NO, this is not right / NO, esto no está bien.
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SchoolYearShowDetails;
