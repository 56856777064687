import LoaderContent from 'app/components/ContentLoader';
import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';
import Swal from 'sweetalert2';

const CourseDetails = ({
  courseInviteId,
  getCourseByCourseInvitedIdQuery,
  deleteEnrolledCourseQuery,
}: any) => {
  const { formatMessage } = useIntlCustom();

  const handleConfirmation = () => {
    if (
      getCourseByCourseInvitedIdQuery.data.ResultObject.course
        .isDiplomaEnglishCourse === true
    ) {
      Swal.fire('Warning', 'Please UnEnroll', 'warning');
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Course will be deactivated!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3e97ff',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteEnrolledCourseQuery.mutateAsync({
            params: {
              id: courseInviteId,
            },
          });
        }
      });
    }
  };

  return (
    <div className='card card-stretch'>
      <LoaderContent
        loading={getCourseByCourseInvitedIdQuery.isLoading}
        loaderType='twoSection'
      />
      {getCourseByCourseInvitedIdQuery.isSuccess && (
        <>
          <div className='card-header'>
            <div className='card-title m-0 justify-content-between w-100'>
              <h3 className='fw-bolder text-gray-800'>
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS',
                })}
              </h3>
              <button
                type='button'
                className='btn btn-sm btn-light-danger p-2'
                onClick={handleConfirmation}
              >
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.DELETE_COURSE',
                })}
              </button>
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.TEACHER',
                })}
                {/* Teacher */}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.createdBy?.FirstName
                  }{' '}
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.createdBy?.LastName
                  }
                </span>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.SCHOOL',
                })}
                {/* School */}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.schoolName
                  }
                </span>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Course Name */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.COURSE_NAME',
                })}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.name
                  }
                </span>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Grade */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.GRADE',
                })}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.grade
                  }
                </span>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Target Language */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.TARGET_LANGUAGE',
                })}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.targetLanguage?.Language_Name
                  }
                </span>
              </div>
            </div>
            <div className='row mb-0'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Language Level */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_DETAILS.LANGUAGE_LEVEL',
                })}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                      ?.languageLevel?.Level_Name
                  }
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CourseDetails;
