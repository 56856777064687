import React, { memo } from 'react';
import UpgradePlanModal from './UpgradePlanModal';
import UpgradeThankYou from './UpgradeThankYou';
import useModal from 'hooks/useModal';

const UpgradePlan = ({
  className,
  title,
}: {
  className: string;
  title: string;
}) => {
  const {
    show: upgradeModalShow,
    openModal: upgradeModalOpenModal,
    closeModal: upgradeModalCloseModal,
  } = useModal();

  const {
    show: upgradeThanksModalShow,
    openModal: upgradeThanksModalOpenModal,
    closeModal: upgradeThanksModalCloseModal,
  } = useModal();

  return (
    <>
      <button className={className} onClick={() => upgradeModalOpenModal({})}>
        {title}
      </button>
      <UpgradePlanModal
        show={upgradeModalShow}
        onHide={upgradeModalCloseModal}
        showUpgradeThankYou={() => {
          upgradeModalCloseModal();
          upgradeThanksModalOpenModal({});
        }}
      />
      <UpgradeThankYou
        show={upgradeThanksModalShow}
        onHide={upgradeThanksModalCloseModal}
      />
    </>
  );
};

export default memo(UpgradePlan);
