import clsx from 'clsx';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import 'react-phone-input-2/lib/bootstrap.css';
import PhoneInput from 'react-phone-input-2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GradeSubmissionFormFooter from '../GradeSubmissionFormFooter';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import Section3 from './Section3';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import HighlightedIssuesText from 'app/pages/enrollmenthooks/HighlightedIssuesText';
import { TextFieldComponents } from 'hooks/formikFormHook';

const Section4 = (props: any) => {
  const {
    formType,
    gradeFormData,
    setStep,
    step,
    stepper,
    setGrade,
    grade,
    finishHighSchool,
    fieldReportedIssue,
  } = props;
  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');

  const saveGradeSubmisiionSection4Qurey: any = usePostQuery(
    'saveGradeSubmisiionSection4',
    '/enrollment/grade/save',
    {}
  );

  const initialValues: any = {
    Guardian_Firstname: '',
    Guardian_Lastname: '',
    Guardian_Number: '',
    Guardian_Address: '',
    Acknowledge_Legal_Guardian_Name: '',
    Student_Completed_Grade_Curriculum: '',
    FormType: formType,
    FinalSave: true,
  };

  const isCurriculum: Boolean = useMemo(() => {
    if (finishHighSchool == 'Yes' || grade == '6th') {
      return true;
    }
    return false;
  }, [finishHighSchool, grade]);

  useEffect(() => {
    if (gradeFormData) {
      formik.setFieldValue(
        'Guardian_Firstname',
        gradeFormData?.Guardian_Firstname?.value
          ? gradeFormData?.Guardian_Firstname?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Lastname',
        gradeFormData?.Guardian_Lastname?.value
          ? gradeFormData?.Guardian_Lastname?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Number',
        gradeFormData?.Guardian_Number?.value
          ? gradeFormData?.Guardian_Number?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Address',
        gradeFormData?.Guardian_Address?.value
          ? gradeFormData?.Guardian_Address?.value
          : ''
      );
      formik.setFieldValue(
        'Acknowledge_Legal_Guardian_Name',
        gradeFormData?.Acknowledge_Legal_Guardian_Name?.value
          ? gradeFormData?.Acknowledge_Legal_Guardian_Name?.value
          : ''
      );
      formik.setFieldValue(
        'Student_Completed_Grade_Curriculum',
        gradeFormData?.Student_Completed_Grade_Curriculum?.value
          ? gradeFormData?.Student_Completed_Grade_Curriculum?.value
          : ''
      );
    }
  }, [gradeFormData]);

  const formvalidationSchema = Yup.object().shape({
    Guardian_Firstname: Yup.string().required(
      'Please enter guardian first name'
    ),
    Guardian_Lastname: Yup.string().required('Please enter guardian last name'),
    Guardian_Number: Yup.string().required('Please enter guardian number'),
    Guardian_Address: Yup.string().required('Please enter guardian address'),
    Acknowledge_Legal_Guardian_Name: Yup.string().required(
      'Please enter acknowledge legal guardian name'
    ),
    Student_Completed_Grade_Curriculum:
      isCurriculum && Yup.string().required('Please select any one option'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      console.log(values);
      saveGradeSubmisiionSection4Qurey.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveGradeSubmisiionSection4Qurey.isSuccess) {
      setStep(0);
    }
  }, [saveGradeSubmisiionSection4Qurey.isSuccess]);

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div>
      <Loader loading={saveGradeSubmisiionSection4Qurey.isLoading} />
      <form onSubmit={formik.handleSubmit}>
        {(finishHighSchool == 'Yes' || grade == '6th') && (
          <>
            <Section3 formik={formik} formType={formType} />
          </>
        )}
        <Card className='mt-5'>
          <h5 className='card-header align-items-center bg-light'>
            Guardian Information - Información del tutor
          </h5>
          <Card.Body className='p-6'>
            <div
              className={
                hasFieldError?.includes('Guardian_Firstname')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian First Name / Nombre del tutor{' '}
                <span className='text-danger'> * </span>
              </label>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Firstname'
                placeholder='Your first name'
                disabled={false}
                onChange={() => {
                  removeHightlight('Guardian_Firstname');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Firstname}
                errors={formik.errors.Guardian_Firstname}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Lastname')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian Last Name / Apellido del tutor
                <span className='text-danger'> * </span>
              </label>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Lastname'
                placeholder='Your last name'
                disabled={false}
                onChange={() => {
                  removeHightlight('Guardian_Lastname');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Lastname}
                errors={formik.errors.Guardian_Lastname}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Number')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian WhatsApp number Including your Country Code{' '}
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                Escriba su número de WhatsApp con el código de su país
              </p>
              <div
                className={`input-group whatsapp-number
                             ${
                               formik.touched.Guardian_Number &&
                               formik.errors.Guardian_Number &&
                               'error-border'
                             }
                             ${
                               formik.touched.Guardian_Number &&
                               !formik.errors.Guardian_Number &&
                               'success-border'
                             }
                             `}
              >
                <PhoneInput
                  country='us'
                  value={formik.values.Guardian_Number}
                  onChange={(value: any) => {
                    formik.setFieldValue('Guardian_Number', value);
                    removeHightlight('Guardian_Number');
                  }}
                  onBlur={formik.handleBlur}
                  inputProps={{
                    id: 'Guardian_Number',
                    className: `form-control ${
                      formik.errors.Guardian_Number ? 'is-invalid' : ''
                    }`,
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Guardian_Number}
                  errors={formik.errors.Guardian_Number}
                />
              </div>
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Address')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian Mailing Address: (Full address)
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                Dirección postal del tutor: (Dirección completa)
              </p>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Address'
                placeholder='Your address'
                disabled={false}
                onChange={() => {
                  removeHightlight('Guardian_Address');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Address}
                errors={formik.errors.Guardian_Address}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Acknowledge_Legal_Guardian_Name')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian's Name (Digital Signature) / Nombre del tutor (firma
                digital) <span className='text-danger'> * </span>
              </label>
              <TextFieldComponents
                formik={formik}
                fieldName='Acknowledge_Legal_Guardian_Name'
                placeholder='Your discription'
                disabled={false}
                onChange={() => {
                  removeHightlight('Acknowledge_Legal_Guardian_Name');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Acknowledge_Legal_Guardian_Name}
                errors={formik.errors.Acknowledge_Legal_Guardian_Name}
              />
            </div>
            <HighlightedIssuesText formik={formik} />
          </Card.Body>
        </Card>
        <GradeSubmissionFormFooter
          IsBackButton={true}
          setStep={setStep}
          step={step}
          stepper={stepper}
          formType={formType}
          isSubmitButton={true}
        />
      </form>
    </div>
  );
};

export default Section4;
