import { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { Button, Form as BForm, Form, Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PasswordMeterComponent } from '../../../../theme/assets/ts/components';

import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Select from 'react-select';
import Loader from 'app/components/Loader';
import { useAuth } from '../core/Auth';
import Swal from 'sweetalert2';
import useIntlCustom from 'hooks/useIntlCustom';
import FormikErrorText from 'app/components/palette/FormikErrorText';

import generateBirthYears from 'hooks/useGenerateBirthYears';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  nativeLanguage: '',
  birthYear: '',
  password: '',
  confirmpassword: '',
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('Please enter first name'),
  email: Yup.string()
    .email('Please enter valid email address')
    .required('Please enter email address'),
  lastname: Yup.string().required('Please enter last name'),
  nativeLanguage: Yup.object().nullable().required('Please select.'),
  birthYear: Yup.object().nullable().required('Please select.'),
  password: Yup.string()
    .min(8, 'Password must be longer than 8 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      'Must contain one uppercase, one lowercase'
    )
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'Must contain one special case character')
    .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain one number')
    .required('Please enter password'),
  confirmpassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        "Password and confirm password didn't match"
      ),
    }),
});

const Registration = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const navigate = useNavigate();
  const { pathname } = window.location;
  const { saveAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const CourseInviteId = searchParams.get('courseInviteId');
  const isDiplomaStudent = searchParams.get('isDiploma');
  const queryParams = new URLSearchParams(window.location.search);
  const addNewUserQuery: any = usePostQuery(
    'addNewUserQuery',
    '/user/register',
    {}
  );

  const isDiploma = searchParams.get('isDiploma') === 'true';

  console.log(isDiploma);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const getAllLanguage = useGetQuery(
    'getAllLanguage',
    `/language/getAllLanguages`
  );

  useEffect(() => {
    getAllLanguage.mutate({});
  }, []);

  const onSubmit = (values: any) => {
    const formData = {
      firstname: values.firstname,
      lastname: values.lastname,
      nativeLanguage: values.nativeLanguage.value,
      birthYear: values.birthYear.value,
      email: values.email,
      password: values.password,
      courseId: CourseInviteId,
      isDiplomaStudent: isDiplomaStudent ? true : false,
    };
    addNewUserQuery.mutate(formData);
  };

  useEffect(() => {
    if (addNewUserQuery?.isSuccess) {
      const dataObject = addNewUserQuery?.data?.data;

      if (dataObject) {
        const lsValue = JSON.stringify(dataObject);
        localStorage.setItem('kt-auth-react-v', lsValue);
      }

      const token = addNewUserQuery?.data?.token;
      if (token) {
        saveAuth(addNewUserQuery?.data);
        if (isDiploma) {
          navigate('/account');
        } else if (pathname === '/signup') {
          // saveAuth(addNewUserQuery?.data);
          navigate('/course');
        }
      }
    }
  }, [addNewUserQuery?.isSuccess]);

  useEffect(() => {
    if (addNewUserQuery?.data?.ResultCode === 'ERROR') {
      Toast.fire({
        icon: 'error',
        title: addNewUserQuery?.data?.ResultMessage,
      });
    }
  }, [addNewUserQuery]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <>
      {addNewUserQuery?.isLoading && (
        <Loader loading={addNewUserQuery?.isLoading} />
      )}
      <Container>
        <h1 className='text-center fw-bolder mb-10'>
          {formatMessage({
            id: 'AUTH.LOGIN.SIGN_UP',
          })}
          {/* Sign Up */}
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            getFieldProps,
          }) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <div className=' card-body p-0'>
                  <BForm.Group className='row fv-row mb-7'>
                    <div className='col-lg-6'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'FIRST_NAME',
                        })}
                        {/* First Name */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <>
                        <BForm.Control
                          placeholder='First name'
                          type='text'
                          {...getFieldProps('firstname')}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          className={`form-control ${
                            errors.firstname && touched.firstname
                              ? 'border border-danger'
                              : ''
                          }`}
                        />
                        {touched.firstname && errors.firstname && (
                          <BForm.Text className='text-danger'>
                            <FormikErrorText name='firstname' />
                          </BForm.Text>
                        )}
                      </>
                    </div>
                    <div className='col-lg-6'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'LAST_NAME',
                        })}
                        {/* Last Name */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <>
                        <BForm.Control
                          placeholder='Last Name'
                          type='text'
                          {...getFieldProps('lastname')}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          className={`form-control ${
                            errors.lastname && touched.lastname
                              ? 'border border-danger'
                              : ''
                          }`}
                        />
                        {touched.lastname && errors.lastname && (
                          <BForm.Text className='text-danger'>
                            <FormikErrorText name='lastname' />
                          </BForm.Text>
                        )}
                      </>
                    </div>
                  </BForm.Group>
                  <BForm.Group className='row fv-row mb-7'>
                    <div className='col-lg-6'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'NATIVE_LANGUAGE',
                        })}
                        {/* Native Language */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <Field
                        name='nativeLanguage'
                        isMulti={false}
                        component={Select}
                        options={getAllLanguage?.data?.ResultObject?.map(
                          (option: any) => {
                            return {
                              ...option,
                              value: option._id,
                              label: `${option.Language_Name} - ${option.Language_Name_Spanish}`,
                            };
                          }
                        )}
                        placeholder='Select'
                        isClearable={true}
                        classNamePrefix='select'
                        onChange={(option: any) => {
                          setFieldValue('nativeLanguage', option);
                        }}
                        // onBlur={handleBlur}
                        value={values.nativeLanguage}
                        styles={{
                          control: (baseStyles: any, state: any) => {
                            return {
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? errors.nativeLanguage &&
                                  touched.nativeLanguage
                                  ? '#f1416c'
                                  : 'grey'
                                : errors.nativeLanguage &&
                                  touched.nativeLanguage
                                ? '#f1416c'
                                : '#b5b5c3',
                              boxShadow: '#b5b5c3',
                              '&:hover': {
                                borderColor:
                                  errors.nativeLanguage &&
                                  touched.nativeLanguage
                                    ? '#f1416c'
                                    : 'none',
                              },
                              '&:focus': {
                                borderColor:
                                  errors.nativeLanguage &&
                                  touched.nativeLanguage
                                    ? '#f1416c'
                                    : 'none',
                              },
                            };
                          },
                        }}
                      />
                      {touched.nativeLanguage && errors.nativeLanguage && (
                        <BForm.Text className='text-danger'>
                          <FormikErrorText name='nativeLanguage' />
                        </BForm.Text>
                      )}
                    </div>
                    <div className='col-lg-6'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'BIRTH_YEAR',
                        })}
                        {/* Birth Year */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <Field
                        name='birthYear'
                        isMulti={false}
                        component={Select}
                        placeholder='Select'
                        options={generateBirthYears()?.map((val: any) => ({
                          value: val,
                          label: val,
                        }))}
                        isClearable={true}
                        classNamePrefix='select'
                        onChange={(option: any) => {
                          setFieldValue('birthYear', option);
                        }}
                        // onBlur={handleBlur}
                        value={values.birthYear}
                        styles={{
                          control: (baseStyles: any, state: any) => {
                            return {
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? errors.birthYear && touched.birthYear
                                  ? '#f1416c'
                                  : 'grey'
                                : errors.birthYear && touched.birthYear
                                ? '#f1416c'
                                : '#b5b5c3',
                              boxShadow: '#b5b5c3',
                              '&:hover': {
                                borderColor:
                                  errors.birthYear && touched.birthYear
                                    ? '#f1416c'
                                    : 'none',
                              },
                              '&:focus': {
                                borderColor:
                                  errors.birthYear && touched.birthYear
                                    ? '#f1416c'
                                    : 'none',
                              },
                            };
                          },
                        }}
                      />
                      {touched.birthYear && errors.birthYear && (
                        <BForm.Text className='text-danger'>
                          <FormikErrorText name='birthYear' />
                        </BForm.Text>
                      )}
                    </div>
                  </BForm.Group>
                  <BForm.Group className='fv-row mb-8'>
                    <div className='col-lg-12'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'EMAIL',
                        })}
                        {/* Email */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <>
                        <BForm.Control
                          id='email'
                          placeholder='Email'
                          type='email'
                          // autoComplete='off'
                          {...getFieldProps('email')}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          className={`form-control ${
                            errors.email && touched.email
                              ? 'border border-danger'
                              : ''
                          }`}
                        />
                        {touched.email && errors.email && (
                          <BForm.Text className='text-danger'>
                            <FormikErrorText name='email' />
                          </BForm.Text>
                        )}
                      </>
                    </div>
                  </BForm.Group>
                  <BForm.Group
                    className='fv-row mb-8'
                    data-kt-password-meter='true'
                  >
                    <div className='col-lg-12'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'PASSWORD',
                        })}
                        {/* Password */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <>
                        <BForm.Control
                          type='password'
                          placeholder='Password'
                          autoComplete='off'
                          {...getFieldProps('password')}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          className={`form-control ${
                            errors.password && touched.password
                              ? 'border border-danger'
                              : ''
                          }`}
                        />
                        {touched.password && errors.password && (
                          <BForm.Text className='text-danger'>
                            <FormikErrorText name='password' />
                          </BForm.Text>
                        )}
                        <div
                          className='d-flex align-items-center mt-3 mb-3'
                          data-kt-password-meter-control='highlight'
                        >
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                          <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        </div>
                      </>
                      <div className='text-muted'>
                        {formatMessage({
                          id: 'PASSWORD_HINT_MSG',
                        })}
                        {/* Use 8 or more characters with a mix of letters, numbers
                        & symbols. */}
                      </div>
                    </div>
                  </BForm.Group>
                  <BForm.Group
                    className='fv-row mb-8'
                    data-kt-password-meter='true'
                  >
                    <div className='col-lg-12'>
                      <BForm.Label className='form-label fw-bolder text-dark fs-6'>
                        {formatMessage({
                          id: 'CONFIRM_PASSWORD',
                        })}
                        {/* Confirm Password */}
                        <span style={{ color: 'red' }}>*</span>
                      </BForm.Label>
                      <>
                        <BForm.Control
                          type='password'
                          placeholder='Password confirmation'
                          autoComplete='off'
                          {...getFieldProps('confirmpassword')}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          className={`form-control ${
                            errors.password && touched.password
                              ? 'border border-danger'
                              : ''
                          }`}
                        />
                        {touched.confirmpassword && errors.confirmpassword && (
                          <BForm.Text className='text-danger'>
                            <FormikErrorText name='confirmpassword' />
                          </BForm.Text>
                        )}
                      </>
                    </div>
                  </BForm.Group>
                  <Button
                    type='submit'
                    className='btn btn-primary me-2 flex-shrink-0 w-100'
                  >
                    {addNewUserQuery.isLoading ? (
                      <>Loading…</>
                    ) : (
                      <>
                        {formatMessage({
                          id: 'SUBMIT',
                        })}
                      </>
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default Registration;
