import { get } from 'helpers/api-helper';

export const GET_SUBSCRIPTION_STATUS_URL =
  '/subscription/getSubscriptionStatus';

const getSubscriptionStatusCall = () => {
  return get(GET_SUBSCRIPTION_STATUS_URL).then((res) => {
    return res;
  });
};

export { getSubscriptionStatusCall };
