import { useIntl } from 'react-intl';

function useIntlCustom() {
  const intl = useIntl();

  const formatMessage = ({ id, values = {} }) => {
    return intl.formatMessage({ id }, values);
  };

  return { formatMessage };
}

export default useIntlCustom;
