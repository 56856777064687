/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Col, Button, Card, Container, Row, Spinner, Stack } from 'react-bootstrap';
import { BsFolder, BsTrash } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';
import AddNewFolder from './AddNewFolder';
import Swal from 'sweetalert2';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'; //https://stackblitz.com/edit/react-sortable-hoc-grid?file=index.js,Hello.js
import { useNavigate } from 'react-router-dom';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import { jwtTokenDecode } from 'hooks/tokenHook';
import Loader from 'app/components/Loader';
import { KTSVG } from '../../../theme/helpers';

const ClassRoomList: React.FC<any> = (props: any) => {
  const navigate = useNavigate();

  const { drag, setDrag, dragIndex, setDragIndex, classList, setClassList, } = props;
  const [modalShow, setModalShow] = React.useState(false);

  const getAllClassWithoutFilter: any = useGetQuery('getAllClassWithoutFilter', `/liveClass/getAllWithoutFilter`, {})
  useEffect(() => {
    getAllClassWithoutFilter.mutate({});
  }, [])

  useEffect(() => {
    if (getAllClassWithoutFilter.isSuccess) {
      setClassList(getAllClassWithoutFilter?.data?.ResultObject)
    }
  }, [getAllClassWithoutFilter.isSuccess])

  //React DnD create sortable lists?
  const handleConfirmation = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Class will be deactivated!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {

        const updatedItems = [...classList];
        updatedItems.splice(dragIndex, 1);
        setClassList(updatedItems);

        await Swal.fire(
          'Deactivate Class!',
          'Your Class has been deactivated.',
          'success'
        )
      }

    })
  }
  const onSortEnd = (data: any) => {
    const { oldIndex, newIndex } = data;
    let newArray: any = arrayMove(classList, oldIndex, newIndex);
    setClassList(newArray);
    setDrag(false)
  };

  const navigateToAddNewClass = () => {
    navigate('/add-new-class');
  };

  return (
    <>
      {getAllClassWithoutFilter?.isLoading &&
        <Loader loading={getAllClassWithoutFilter?.isLoading} />
      }
      <AddNewFolder
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Row className='g-5 pb-5'>
        <Col>
          <div className="card card-flush p-0 shadow-sm">
            <div className="card-body py-5 d-flex gap-5">
              <button className='btn  btn-light fw-bold w-100' onClick={navigateToAddNewClass}><AiOutlinePlus /> New Class</button>
              <button
                className='btn  btn-light fw-bold w-100'
                onMouseUp={() => {
                  if (drag) {
                    handleConfirmation();
                  }
                }}
              ><BsTrash /> Trash</button>
            </div>
          </div>
        </Col>
      </Row>
      <SortableList
        axis="xy"
        onSortEnd={onSortEnd}
        onSortStart={(res: any) => {
          setDragIndex(res.index)
          setDrag(true);
          // trashRef.current.addEventListener('mouseenter', (event: any) => {
          //   setTimeout(() => {
          //     trashRef.current.removeEventListener('mouseenter', (e: any) => {
          //       alert();
          //     });
          //   }, 1000);
          // });
        }}
      >

        {classList?.map((item: any, index: any) => (
          <SortableItem key={`item-${index}`} index={index} dataItem={item} />
        ))}

        {
          classList && classList?.length === 0 &&
          <div className='text-center fw-bold fs-3 p-5 '>No Class Available</div>
        }
      </SortableList >
    </>
  );
};
export default ClassRoomList;

const SortableList: any = SortableContainer(({ children }: any) => <Row className='g-5'>{children}</Row>)

const SortableItem: any = SortableElement(({ dataItem }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const { auth } = useAuth();

  useEffect(() => {
    return () => {
      setIsLoading(false);
    }
  }, []);

  const navigate = useNavigate();
  const handleJoinClass = (liveClassId: any) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate(`/waiting-class-room/${liveClassId}`);
    }, 1000);
  };

  return (
    <Col lg={4} data-items={dataItem}>
      <div className="card card-flush shadow-sm h-100">

        <div className="card-body py-5">
          {
            dataItem?.name && <h3 className="card-title m-0">{dataItem?.name}</h3>
          }
          <div className="py-5">
            <div className="card-text">Due: 8 Days, 4 Hour</div>
            <div className="card-text">Grade: B</div>
            <div className="card-text">Percentage: 83%</div>
            <div className="card-text">Points Needed: 39 of 50</div>
          </div>

          <div className="d-flex flex-column mb-4">
            <div className="btn-group w-100">
              <button
                disabled={isLoading}
                className={`btn btn-lg btn-primary me-3 mt-5 w-100 ${dataItem.createdBy === jwtTokenDecode().id ? 'btn-success' : 'btn-primary'}`}
                onClick={() => handleJoinClass(dataItem._id)}
              >
                {isLoading ?
                  <div className='d-flex align-items-center justify-content-center'>
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  </div>
                  :
                  <span className='pe-none'>
                    Go
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-4 me-1'
                    />
                  </span>
                }
              </button>
            </div>
          </div>

        </div>
      </div>
    </Col>
  )
});
