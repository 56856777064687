import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { get, post } from '../../helpers/api-helper';
import actionTypes from './actionTypes';

function* fetchLessons(action: any) {
  try {
    //@ts-ignore
    const data = yield call(get, 'lessons/getAllForGrid');

    yield put({
      type: actionTypes.GET_SUCCEED,
      payload: { data: data?.ResultObject ?? null },
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_FAILED, payload: { error } });
  }
}

function* fetchLessonById(action: any) {
  try {
    //@ts-ignore
    const data = yield call(post, 'lessons/getbyid', action.payload);
    yield put({
      type: actionTypes.DETAILS_SUCCEED,
      payload: { data: data?.ResultObject ?? null },
    });
  } catch (error) {
    yield put({ type: actionTypes.DETAILS_FAILED, payload: { error } });
  }
}

export default function* lessonsSaga() {
  yield all([
    takeLatest(actionTypes.GET_REQUESTED, fetchLessons),
    takeLatest(actionTypes.DETAILS_REQUESTED, fetchLessonById),
  ]);
}
