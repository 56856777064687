import jwt_decode from 'jwt-decode';

function useJwt() {
  // const jwtTokenDecode = () => {
  //   const localStorageData: any = localStorage.getItem('kt-auth-react-v');
  //   const token: string = localStorageData
  //     ? JSON.parse(localStorageData)?.token
  //     : null;
  //   const jwt_decode_token = token ? jwt_decode(token) : null;

  //   return jwt_decode_token as object;
  // };

  const isTokenValid = () => {
    try {
      const localStorageData: any = localStorage.getItem('kt-auth-react-v');
      const token: string = localStorageData
        ? JSON.parse(localStorageData).token
        : null;

      if (token) {
        const jwtDecodedToken: any = token ? jwt_decode(token) : {};
        const currentTime = Date.now() / 1000; // Current time in seconds

        return jwtDecodedToken.exp > currentTime;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return { isTokenValid };
}

export default useJwt;
