import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import './index.css';
import useRandomNumber from 'hooks/useRandomNumber';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';

const Video = ({ src, ...props }: any) => {
  const { muted, poster, onHandleClick = null } = props;
  const videoRef = useRef<HTMLVideoElement>();
  const [mutedVideo, setMutedVideo] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    // 👇 Get the DOM element from the React element
    const element = videoRef.current;

    if (!element) return;

    if (muted) {
      element.defaultMuted = true;
      element.muted = true;
      setMutedVideo(muted);
    }

    // element.addEventListener('loadstart', () => {
    //   setLoad(true);
    // });

    // return () => {
    //   element.removeEventListener('loadstart', () => {
    //     setLoad(false);
    //   });
    // };
  }, []);

  const toggleMute = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const element = videoRef.current;
    if (element) {
      element.muted = !element.muted;
      setMutedVideo(element.muted);
    }
  }, []);

  return (
    <div className='position-relative overflow-hidden'>
      <video
        {...props}
        className={`object-fit-fill ${props.className}`}
        poster={poster}
        ref={videoRef}
        onLoadedData={() => setLoad(true)}
        onLoadedDataCapture={() => setLoad(true)}
        onLoadStart={() => setLoad(true)}
        onLoadStartCapture={() => setLoad(true)}
      >
        <source
          src={`${src}?v1=${useRandomNumber(100000000000000)}`}
          type='video/mp4'
        ></source>
        Your browser does not support the video tag.
        <track kind='captions' />
      </video>

      <div className='position-absolute bottom-0 w-100' style={{ zIndex: 99 }}>
        <div className='d-flex flex-column gap-2'>
          {load && (
            <div className='d-flex justify-content-end'>
              <button
                onClick={toggleMute}
                className='border border-0 rounded-circle video-overlay-bg-color m-2 text-light cursor-pointer fs-1'
              >
                {mutedVideo ? <MdVolumeOff /> : <MdVolumeUp />}
              </button>
            </div>
          )}
          {onHandleClick && (
            <div className='video-overlay-bg-color p-2'>
              <div className='d-flex justify-content-end'>{onHandleClick}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Video);
