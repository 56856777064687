import { useState } from 'react';

const useModal = () => {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>({});

  const openModal = (data: any) => {
    setShow(true);
    setModalData(data);
  };

  const closeModal = () => {
    setShow(false);
  };

  const toggleModal = () => {
    setShow((prevShow) => !prevShow);
  };

  return {
    show,
    openModal,
    closeModal,
    toggleModal,
    modalData,
  };
};
export default useModal;
