import Loader from 'app/components/Loader';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import * as Yup from 'yup';
import GradeSubmissionFormFooter from '../GradeSubmissionFormFooter';
import HighlightedIssuesText from 'app/pages/enrollmenthooks/HighlightedIssuesText';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section5 = (props: any) => {
  const {
    formType,
    gradeFormData,
    setStep,
    step,
    stepper,
    fieldReportedIssue,
  } = props;

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');

  const saveGradeSubmisiionSection5Qurey: any = usePostQuery(
    'saveGradeSubmisiionSection5',
    '/enrollment/grade/save',
    {}
  );

  const FinishHighSchoolOption = [
    {
      value: 'Yes',
      label_english:
        'YES, my student was a senior and finished high school this year.',
      label_spanish:
        'SÍ, mi estudiante estaba en el último año y terminó la escuela secundaria este año.',
    },
    {
      value: 'No',
      label_english: 'No, my student is not finishing high school.',
      label_spanish:
        'No, mi estudiante no está terminando la escuela secundaria.',
    },
  ];

  const initialValues: any = {
    Student_Finish_High_School: '',
    FormType: formType,
  };

  useEffect(() => {
    if (gradeFormData) {
      formik.setFieldValue(
        'Student_Finish_High_School',
        gradeFormData?.Student_Finish_High_School?.value
          ? gradeFormData?.Student_Finish_High_School?.value
          : ''
      );
    }
  }, [gradeFormData]);

  const formvalidationSchema = Yup.object().shape({
    Student_Finish_High_School: Yup.string().required(
      'Please select any one option'
    ),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      saveGradeSubmisiionSection5Qurey.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveGradeSubmisiionSection5Qurey.isSuccess) {
      setStep(3);
    }
  }, [saveGradeSubmisiionSection5Qurey.isSuccess]);

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div>
      <Loader loading={saveGradeSubmisiionSection5Qurey.isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <Card className='mt-5'>
          <h5 className='card-header align-items-center bg-light'>
            Graduation / Graduación
          </h5>
          <Card.Body className='p-6'>
            <div
              className={
                hasFieldError?.includes('Student_Finish_High_School')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Did your student finish high school? ¿Su estudiante terminó la
                escuela secundaria?
                <span className='text-danger'> * </span>
              </label>
              {FinishHighSchoolOption?.map((data: any) => {
                return (
                  <div className='form-check mt-4'>
                    <RadioButtonComponents
                      formik={formik}
                      fieldName={'Student_Finish_High_School'}
                      dataValue={data.value}
                      onChange={() => {
                        removeHightlight('Student_Finish_High_School');
                      }}
                    />
                    <label className='form-label fw-normal'>
                      {data.label_english + ' ' + data.label_spanish}
                    </label>
                  </div>
                );
              })}

              <FieldsErrorText
                touched={formik.touched.Student_Finish_High_School}
                errors={formik.errors.Student_Finish_High_School}
              />
            </div>

            <HighlightedIssuesText formik={formik} />
          </Card.Body>
        </Card>
        <GradeSubmissionFormFooter
          IsBackButton={true}
          setStep={setStep}
          step={step}
          stepper={stepper}
          formType={formType}
        />
      </form>
    </div>
  );
};

export default Section5;
