import LoaderContent from 'app/components/ContentLoader';
import usePercentageCounter from 'hooks/Calculate';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { GRADE_COLOR } from 'shared/constants';
import useIntlCustom from 'hooks/useIntlCustom';

const CoursePointCounting = ({
  getGradesPercentagePointsByCourseQuery,
  getCourseByCourseInvitedIdQuery,
}: // getGradesPercentagePointsByDiplomaCourseQuery,
any) => {
  const { formatMessage } = useIntlCustom();

  const [dueDate, setDueDate] = useState<any>();
  const [dueDatePoint, setDueDatePoint] = useState<any>();
  const [diplomaPoint, setDiplomaPoint] = useState<any>();

  // useEffect(() => {
  //   if (getGradesPercentagePointsByDiplomaCourseQuery?.isSuccess) {
  //     setDiplomaPoint(
  //       getGradesPercentagePointsByDiplomaCourseQuery?.data?.ResultObject
  //     );
  //   }
  // }, [getGradesPercentagePointsByDiplomaCourseQuery?.isSuccess]);

  useEffect(() => {
    if (
      getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course
        ?.dueDates?.length > 0
    ) {
      let date =
        getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course?.dueDates?.find(
          (item: any) =>
            moment(item?.date).isSameOrAfter(
              moment(new Date()).startOf('day'),
              'day'
            )
        )?.date;
      if (
        getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
          .isDiplomaEnglishCourse === true
      ) {
        const nextMonthLastDate = moment().utc().add(1, 'month').endOf('month');
        date = nextMonthLastDate.format('YYYY-MM-DD');
      }
      let points =
        getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course?.dueDates?.find(
          (item: any) =>
            moment(item?.date).isSameOrAfter(
              moment(new Date()).startOf('day'),
              'day'
            )
        )?.points;
      setDueDate(date ? moment(new Date(date)).format('MM/DD/YYYY') : '');
      setDueDatePoint(points);
    }
  }, [
    getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course
      ?.dueDates,
  ]);

  return (
    <div className='card card-stretch'>
      <LoaderContent
        loading={getGradesPercentagePointsByCourseQuery.isLoading}
        loaderType='twoSection'
      />
      {getGradesPercentagePointsByCourseQuery.isSuccess && (
        <>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-gray-800'>
                {/* Course Result */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_RESULT',
                })}
              </h3>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-5'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Course Grade */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_RESULT.COURSE_GRADE',
                })}
              </label>
              <div className='col-lg-8'>
                <div
                  className={`badge badge-${
                    GRADE_COLOR[
                      getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                        ?.grade?.name
                    ]
                  } border px-5 fs-3 fw-bold rounded-pill`}
                >
                  {getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                    ?.grade?.name === '-'
                    ? 'No grade available'
                    : getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                        ?.grade?.name}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Percentage */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_RESULT.PERCENTAGE',
                })}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {
                    getGradesPercentagePointsByCourseQuery?.data?.ResultObject
                      ?.averagePercentage
                  }
                  %
                </span>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Points Completed */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_RESULT.POINTS_COMPLETED',
                })}
              </label>
              <div className='col-lg-8'>
                <div className='d-flex flex-row w-100 me-2'>
                  <div className='d-flex flex-fill flex-stack'>
                    <span className='me-2 fw-bolder fs-6 text-dark'>
                      {
                        // getCourseByCourseInvitedIdQuery?.data?.ResultObject
                        //   ?.course?.isDiplomaEnglishCourse === true
                        //   ? diplomaPoint?.totalPointSum
                        //   :
                        getGradesPercentagePointsByCourseQuery?.data
                          ?.ResultObject?.totalPointSum
                      }{' '}
                      of{' '}
                      {
                        getGradesPercentagePointsByCourseQuery?.data
                          ?.ResultObject?.course?.pointsNeededToCompleteCourse
                      }
                    </span>
                  </div>
                  <div className='progress flex-fill align-self-center h-10px w-75'>
                    <div
                      className='progress-bar bg-info'
                      role='progressbar'
                      style={{
                        width: `${usePercentageCounter(
                          Number(
                            getGradesPercentagePointsByCourseQuery?.data
                              ?.ResultObject?.totalPointSum
                          ),
                          Number(
                            getGradesPercentagePointsByCourseQuery?.data
                              ?.ResultObject?.course
                              ?.pointsNeededToCompleteCourse
                          )
                        )}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-0'>
              <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                {/* Due Date */}
                {formatMessage({
                  id: 'COURSE.RATINGS.COURSE_RESULT.DUE_DATE',
                })}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
                    ?.isDiplomaEnglishCourse === true ? (
                    <div className='badge badge-light fs-5 fw-bold border rounded-pill px-5'>
                      {
                        getGradesPercentagePointsByCourseQuery?.data
                          ?.ResultObject?.deadlineDate
                      }
                    </div>
                  ) : (
                    <div>Ongoing</div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoursePointCounting;
