import React from 'react';

const FieldsErrorText = (props: any) => {
  const { touched, errors } = props;
  return (
    <>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{errors}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default FieldsErrorText;
