import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import EnrollmentFooter from './EnrollmentFooter';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import HighlightedIssuesText from '../enrollmenthooks/HighlightedIssuesText';
import FieldsErrorText from '../enrollmenthooks/FieldsErrorText';
import {
  CheckBoxComponents,
  PhoneNumberComponents,
  TextFieldComponents,
} from 'hooks/formikFormHook';

const Enrollment_Section3 = (props: any) => {
  const { setStep, step, enrollmentData, fieldReportedIssue } = props;
  const tabRef: any = useRef(null);
  const saveEnrollmentGuardianQuery: any = usePostQuery(
    'saveEnrollmentGuardian',
    '/enrollment/add',
    {}
  );

  const [hasFieldError, setHasFieldError] = useState<any>('');

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const navigate = useNavigate();

  useEffect(() => {
    if (enrollmentData) {
      formik.setFieldValue(
        'Guardian_Firstname',
        enrollmentData?.Guardian_Firstname?.value
          ? enrollmentData?.Guardian_Firstname?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Lastname',
        enrollmentData?.Guardian_Lastname?.value
          ? enrollmentData?.Guardian_Lastname?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Address',
        enrollmentData?.Guardian_Address?.value
          ? enrollmentData?.Guardian_Address?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Number',
        enrollmentData?.Guardian_Number?.value
          ? enrollmentData?.Guardian_Number?.value
          : ''
      );
      formik.setFieldValue(
        'Guardian_Relationship',
        enrollmentData?.Guardian_Relationship?.value
          ? enrollmentData?.Guardian_Relationship?.value
          : ''
      );
      formik.setFieldValue(
        'Why_Student_Enroll_HopeCity',
        enrollmentData?.Why_Student_Enroll_HopeCity?.value
          ? enrollmentData?.Why_Student_Enroll_HopeCity?.value
          : ''
      );
      formik.setFieldValue(
        'Hear_About_HopeCity',
        enrollmentData?.Hear_About_HopeCity?.value
          ? enrollmentData?.Hear_About_HopeCity?.value
          : ''
      );
      formik.setFieldValue(
        'Acknowledge_Name',
        enrollmentData?.Acknowledge_Name?.value
          ? enrollmentData?.Acknowledge_Name?.value
          : ''
      );
      formik.setFieldValue(
        'Legal_Guardian_Of_Student',
        enrollmentData?.Legal_Guardian_Of_Student?.value
          ? enrollmentData?.Legal_Guardian_Of_Student?.value
          : false
      );
      formik.setFieldValue(
        'Certify_Information',
        enrollmentData?.Certify_Information?.value
          ? enrollmentData?.Certify_Information?.value
          : false
      );
      formik.setFieldValue(
        'UserEmail',
        enrollmentData?.Email ? enrollmentData?.Email : ''
      );
      formik.setFieldValue(
        'EnrollmentID',
        enrollmentData?.EnrollmentID ? enrollmentData?.EnrollmentID : undefined
      );
    }
  }, [enrollmentData]);

  const initialValues = {
    Guardian_Firstname: '',
    Guardian_Lastname: '',
    Guardian_Address: '',
    Guardian_Number: '',
    Guardian_Relationship: '',
    Why_Student_Enroll_HopeCity: '',
    Hear_About_HopeCity: '',
    Acknowledge_Name: '',
    Legal_Guardian_Of_Student: false,
    Certify_Information: false,
    ButtonAction: '',
    Status: 1,
    MailSend: true,
    UserEmail: '',
    Step: 3,
  };

  const formvalidationSchema = Yup.object().shape({
    Guardian_Firstname: Yup.string().required('Please enter first name'),
    Guardian_Lastname: Yup.string().required('Please enter last name'),
    Guardian_Address: Yup.string().required('Please enter address'),
    Guardian_Number: Yup.string().required('Please enter number'),
    Guardian_Relationship: Yup.string().required(
      `Please enter guardian's relationship to student`
    ),
    Why_Student_Enroll_HopeCity: Yup.string().required(
      'Please enter student enroll'
    ),
    Hear_About_HopeCity: Yup.string().required('Please enter about'),
    Acknowledge_Name: Yup.string().required('Please enter name'),
    Legal_Guardian_Of_Student: Yup.bool().oneOf(
      [true],
      'You must check this box.'
    ),
    Certify_Information: Yup.bool().oneOf([true], 'You must check this box.'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        values.MailSend = true;
        saveEnrollmentGuardianQuery.mutateAsync(values);
      } else {
        values.Status = enrollmentData?.Status ? enrollmentData?.Status : 1;
        values.MailSend = false;
        saveEnrollmentGuardianQuery.mutateAsync(values);
      }
    },
  });

  useEffect(() => {
    if (saveEnrollmentGuardianQuery.isSuccess) {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        setStep(0);
      } else {
        navigate('/account');
      }
    }
  }, [saveEnrollmentGuardianQuery.isSuccess]);

  const onGuardianInformationReset = () => {
    formik.resetForm();
  };

  const onGuardianInformationSave = (actionName: any) => {
    formik.setFieldValue('ButtonAction', actionName);
  };

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div>
      <Loader loading={saveEnrollmentGuardianQuery.isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <div className='card mt-3 mb-3' ref={tabRef}>
          <h1 className='card-header align-items-center bg-light'>
            Guardian Information - Información del tutor
          </h1>
          <div className='card-body p-6'>
            <div
              className={
                hasFieldError?.includes('Guardian_Firstname')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian First Name / Primer nombre del Tutor{' '}
                <span className='text-danger'> * </span>
              </label>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Firstname'
                placeholder='Guardian first name'
                onChange={() => {
                  removeHightlight('Guardian_Firstname');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Firstname}
                errors={formik.errors.Guardian_Firstname}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Lastname')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian Last Name / Apellido del Tutor
                <span className='text-danger'> * </span>
              </label>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Lastname'
                placeholder='Guardian last name'
                onChange={() => {
                  removeHightlight('Guardian_Lastname');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Lastname}
                errors={formik.errors.Guardian_Lastname}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Address')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian Home Address / Dirección de la casa del tutor
                <span className='text-danger'> * </span>
              </label>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Address'
                placeholder='Your home address'
                onChange={() => {
                  removeHightlight('Guardian_Address');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Address}
                errors={formik.errors.Guardian_Address}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Number')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Guardian WhatsApp Number with country phone entrance{' '}
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                Número de WhatsApp del tutor con la entrada telefónica de su
                pais
              </p>
              <div
                className={`input-group whatsapp-number
                             ${
                               formik.touched.Guardian_Number &&
                               formik.errors.Guardian_Number &&
                               'error-border'
                             }
                             ${
                               formik.touched.Guardian_Number &&
                               !formik.errors.Guardian_Number &&
                               'success-border'
                             }
                             `}
              >
                <PhoneNumberComponents
                  formik={formik}
                  fieldName={'Guardian_Number'}
                  onChange={() => {
                    removeHightlight('Guardian_Number');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Guardian_Number}
                  errors={formik.errors.Guardian_Number}
                />
              </div>
            </div>
            <div
              className={
                hasFieldError?.includes('Legal_Guardian_Of_Student')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Are you the parent/legal guardian of student? (All
                responsibility of schooling and care of student is your
                responsibility) <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                ¿Es usted el padre/tutor legal del estudiante? (Toda la
                responsabilidad de la educación y el cuidado del estudiante es
                su responsabilidad)
              </p>

              <div className='form-check mt-4'>
                <CheckBoxComponents
                  formik={formik}
                  fieldName='Legal_Guardian_Of_Student'
                  onChange={() => {
                    removeHightlight('Legal_Guardian_Of_Student');
                  }}
                />
                <label className='form-label fw-normal'>Yes</label>
              </div>
              <FieldsErrorText
                touched={formik.touched.Legal_Guardian_Of_Student}
                errors={formik.errors.Legal_Guardian_Of_Student}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Guardian_Relationship')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                What is the Guardian's Relationship to Student{' '}
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                ¿Cuál es la relación del tutor con el estudiante?
              </p>
              <TextFieldComponents
                formik={formik}
                fieldName='Guardian_Relationship'
                placeholder='Your answer'
                onChange={() => {
                  removeHightlight('Guardian_Relationship');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Guardian_Relationship}
                errors={formik.errors.Guardian_Relationship}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Why_Student_Enroll_HopeCity')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Why would you like your student to enroll in Hope City School?{' '}
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                ¿Por qué le gustaría que su estudiante se inscriba en la escuela
                Hope City?
              </p>
              <TextFieldComponents
                formik={formik}
                fieldName='Why_Student_Enroll_HopeCity'
                placeholder='Your answer'
                onChange={() => {
                  removeHightlight('Why_Student_Enroll_HopeCity');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Why_Student_Enroll_HopeCity}
                errors={formik.errors.Why_Student_Enroll_HopeCity}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Hear_About_HopeCity')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                How did you hear about Hope City school?{' '}
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                ¿Cómo se enteró de la escuela Hope City? *
              </p>
              <TextFieldComponents
                formik={formik}
                fieldName='Hear_About_HopeCity'
                placeholder='Your answer'
                onChange={() => {
                  removeHightlight('Hear_About_HopeCity');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Hear_About_HopeCity}
                errors={formik.errors.Hear_About_HopeCity}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Certify_Information')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                I certify all information is true:
              </label>
              <p className='form-label'>
                This is to certify that all transcripts/report cards submitted
                on behalf of my student are accurate and true. I agree that it
                is my responsibility to ensure that all documents submitted are
                not altered or falsified in any way. I attest that there have
                been no changes that alter the authenticity of the documents and
                verify that my student has received the grades with academic
                integrity. I am aware that if any documents are found to be
                forged, my student will be immediately expelled from Hope City
                Private School and will no longer be eligible to re-enroll. This
                document will remain in effect for the duration of a student's
                enrollment at Hope City School.{' '}
                <span className='text-danger'> * </span>
              </p>

              <p className='form-label fw-bold'>
                Certifico que toda la información es verdadera:
              </p>
              <p className='form-label'>
                Esto es para certificar que todas los expedientes académicos/
                boletas de calificaciones presentadas en nombre de mi estudiante
                sean precisos y verdaderos. Estoy de acuerdo en que es mi
                responsabilidad asegurarme de que todos los documentos
                presentados no sean alterados o falsificados de ninguna manera.
                Doy fe de que no ha habido modificaciones que alteren la
                autenticidad de los documentos y verifico que mi alumno ha
                recibido las calificaciones con integridad académica. Soy
                consciente de que si se descubre que algún documento es
                falsificado, mi estudiante será expulsado inmediatamente de Hope
                City Private School y ya no será elegible para volver a
                inscribirse. Este documento permanecerá vigente durante toda la
                duración de la inscripción de los estudiantes en Hope City
                School.
              </p>
              <div className='form-check mt-2'>
                <CheckBoxComponents
                  formik={formik}
                  fieldName='Certify_Information'
                  onChange={() => {
                    removeHightlight('Certify_Information');
                  }}
                />
                <label className='form-label'>I Agree / Estoy de acuerdo</label>
              </div>

              <FieldsErrorText
                touched={formik.touched.Certify_Information}
                errors={formik.errors.Certify_Information}
              />
            </div>
            <div
              className={
                hasFieldError?.includes('Acknowledge_Name')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <div className='form-label fw-bold'>
                Guardian's Name (Digital Signature) / Nombre del tutor (firma
                digital)
                <span className='text-danger'> * </span>
              </div>
              <TextFieldComponents
                formik={formik}
                fieldName='Acknowledge_Name'
                placeholder='Your answer'
                onChange={() => {
                  removeHightlight('Acknowledge_Name');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Acknowledge_Name}
                errors={formik.errors.Acknowledge_Name}
              />
            </div>

            <HighlightedIssuesText formik={formik} />
          </div>
        </div>
        <EnrollmentFooter
          isBackButtonShow={true}
          isSubmitButtonShow={true}
          setStep={setStep}
          step={step}
          onFormReset={onGuardianInformationReset}
          onSaveForm={onGuardianInformationSave}
        />
      </form>
    </div>
  );
};

export default Enrollment_Section3;
