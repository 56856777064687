import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_3_1 = (props: any) => {
  const {
    formik,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
    formType,
  } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Foreign_Language_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          Foreign language or languages: How will your student meet
          requirements?
        </label>
        <p className='form-label fw-bold'>
          Idioma o idiomas extranjeros: ¿Cómo cumplirá su estudiante con los
          requisitos?.
        </p>
        <p className='form-label mb-2'>
          Foreign language or languages requirements: (beginning not later than
          grade 7) understanding, speaking, reading, and writing the particular
          language.
        </p>
        <p className='form-label mb-2'>
          Requisitos de Idioma o idiomas extranjeros: (comenzando a más tardar
          en el grado 7) comprender, hablar, leer y escribir el idioma en
          particular
        </p>
        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Foreign_Language_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('Foreign_Language_Requirement_Subject');
                      onHandleRquirementSubject(
                        'Foreign_Language_Requirement_Subject',
                        data.Value,
                        formType
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Foreign_Language_Requirement_Subject}
          errors={formik.errors.Foreign_Language_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_3_1;
