import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { get, post } from '../../helpers/api-helper';
import actionTypes from './actionTypes';

// function* fetchClassroom(action: any) {
//   try {
//     //@ts-ignore
//     const data = yield call(post, 'liveclass/getliveclassscheduleid');

//     yield put({
//       type: actionTypes.GET_SUCCEED,
//       payload: { data: data?.ResultObject ?? [] },
//     });
//   } catch (error) {
//     yield put({ type: actionTypes.GET_FAILED, payload: { error } });
//   }
// }
function* fetchClassRoomById(action: any) {
  try {
    //@ts-ignore
    const data = yield call(
      post,
      'liveclass/getliveclassscheduleid',
      action.payload
    );

    yield put({
      type: actionTypes.GET_JOIN_LIVE_CLASS_SCHEDULE_ID_SUCCEED,
      payload: { data: data?.ResultObject ?? [] },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_JOIN_LIVE_CLASS_SCHEDULE_ID_FAILED,
      payload: { error },
    });
  }
}
function* fetchJoinClassRoomById(action: any) {
  try {
    //@ts-ignore
    const data = yield call(
      post,
      'call/getLiveClassJoiningURL',
      action.payload
    );

    yield put({
      type: actionTypes.GET_JOIN_LIVE_CLASS_ID_SUCCEED,
      payload: { data: data?.ResultObject ?? [] },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_JOIN_LIVE_CLASS_ID_FAILED,
      payload: { error },
    });
  }
}
export default function* classroomSaga() {
  yield all([
    //takeEvery(actionTypes.GET_REQUESTED, fetchLessons),
    takeEvery(
      actionTypes.GET_JOIN_LIVE_CLASS_SCHEDULE_ID_REQUESTED,
      fetchClassRoomById
    ),
    takeEvery(
      actionTypes.GET_JOIN_LIVE_CLASS_ID_REQUESTED,
      fetchJoinClassRoomById
    ),
  ]);
}
