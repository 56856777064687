import { useGetQuery } from 'hooks/reactQueryHelper';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InstantLiveClass from './InstantLiveClass';
import ScheduleLiveClass from './ScheduleLiveClass';
import Lesson from './Lesson';
import Grades from './Grades';

const Assignment = () => {
  const { courseInviteId } = useParams();

  const getLessonFromCourse: any = useGetQuery(
    'getLessonFromCourse',
    `/lessons/getLessonFromCourse`,
    {}
  );

  const getScheduleLiveClassByCourse: any = useGetQuery(
    'getScheduleLiveClassByCourse',
    `/course/getScheduleLiveClassByCourse`,
    {}
  );

  const getGradesPercentagePointsByCourseQuery: any = useGetQuery(
    'getGradesPercentagePointsByCourseQuery',
    `/course/getGradesPercentagePointsByCourse`
  );

  const getAILesson: any = useGetQuery(
    'getAILesson',
    `/lessons/getAILesson`,
    {}
  );

  const getCompletedLessonQuery: any = useGetQuery(
    'getCompletedLesson',
    '/course/getCompletedLesson'
  );

  const courseId =
    getGradesPercentagePointsByCourseQuery?.data?.ResultObject?._id;

  useEffect(() => {
    getCompletedLessonQuery.mutate({ params: { id: courseId } });
  }, []);

  useEffect(() => {
    getLessonFromCourse.mutateAsync({ params: { id: courseInviteId } });
    getScheduleLiveClassByCourse.mutateAsync({
      params: { id: courseInviteId },
    });
    getGradesPercentagePointsByCourseQuery.mutateAsync({
      params: { id: courseInviteId },
    });
  }, []);

  return (
    <div className='container'>
      <div className='row g-5'>
        <div className='col-sm-6'>
          <Lesson
            courseInviteId={courseInviteId}
            getLessonFromCourse={getLessonFromCourse}
            getGradesPercentagePointsByCourseQuery={
              getGradesPercentagePointsByCourseQuery
            }
          />
        </div>
        <div className='col-sm-6'>
          <InstantLiveClass courseInviteId={courseInviteId} />
        </div>
        <div className='col-sm-6'>
          <Grades
            getGradesPercentagePointsByCourseQuery={
              getGradesPercentagePointsByCourseQuery
            }
            getScheduleLiveClassByCourse={getScheduleLiveClassByCourse}
          />
        </div>
        <div className='col-sm-6'>
          <ScheduleLiveClass
            courseInviteId={courseInviteId}
            getScheduleLiveClassByCourse={getScheduleLiveClassByCourse}
            getGradesPercentagePointsByCourseQuery={
              getGradesPercentagePointsByCourseQuery
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Assignment;
