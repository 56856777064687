import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import EnrollmentFooter from './EnrollmentFooter';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import {
  extractNumericPart,
  removeEmptyStringProperties,
} from '../enrollmenthooks/EnrollmentHooks';
import HighlightedIssuesText from '../enrollmenthooks/HighlightedIssuesText';
import FieldsErrorText from '../enrollmenthooks/FieldsErrorText';
import {
  CheckBoxComponents,
  PhoneNumberComponents,
  TextFieldComponents,
} from 'hooks/formikFormHook';

const Enrollment_Section2 = (props: any) => {
  const { gradeValue, setStep, step, enrollmentData, fieldReportedIssue } =
    props;

  const saveEnrollmentResponseQuery: any = usePostQuery(
    'saveEnrollmentResponse',
    '/enrollment/add',
    {}
  );
  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (enrollmentData) {
      formik.setFieldValue(
        'Student_Enrolled_Grade_Local_School',
        enrollmentData?.Student_Enrolled_Grade_Local_School?.value
          ? enrollmentData?.Student_Enrolled_Grade_Local_School?.value
          : false
      );

      // formik.setFieldValue(
      //   'Teacher_Number',
      //   enrollmentData?.Teacher_Number?.value
      //     ? enrollmentData?.Teacher_Number?.value
      //     : ''
      // );

      // formik.setFieldValue(
      //   'Teacher_Name',
      //   enrollmentData?.Teacher_Name?.value
      //     ? enrollmentData?.Teacher_Name?.value
      //     : ''
      // );

      formik.setFieldValue(
        'Status',
        enrollmentData?.Status ? enrollmentData?.Status : 0
      );
      formik.setFieldValue(
        'EnrollmentID',
        enrollmentData?.EnrollmentID ? enrollmentData?.EnrollmentID : undefined
      );
    }
  }, [enrollmentData]);

  const initialValues = {
    Student_Enrolled_Grade_Local_School: false,
    Teacher_Number: '',
    Teacher_Name: '',
    ButtonAction: '',
    Status: 0,
    Step: 2,
  };

  const formvalidationSchema = Yup.object().shape({
    // Student_Enrolled_Grade_Local_School: Yup.string().required(
    //   'Please select an option'
    // ),
    Student_Enrolled_Grade_Local_School: Yup.bool().oneOf(
      [true],
      'You must check this box.'
    ),
    // Teacher_Number: Yup.string().required('Please enter number'),
    // Teacher_Name: Yup.string().required('Please enter teacher name'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      await removeEmptyStringProperties(values);
      saveEnrollmentResponseQuery.mutateAsync(values);
    },
  });

  useEffect(() => {
    if (saveEnrollmentResponseQuery.isSuccess) {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        setStep(3);
      }
      if (formik?.values?.ButtonAction == 'Save') {
        navigate('/account');
      }
    }
  }, [saveEnrollmentResponseQuery.isSuccess]);

  const onFormResponseReset = () => {
    formik.resetForm();
  };

  const onFormResponseSave = (actionName: any) => {
    formik.setFieldValue('ButtonAction', actionName);
  };

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError ? hasError : null);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className='mt-5'>
      <Loader loading={saveEnrollmentResponseQuery.isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <div className='card mb-5'>
          <h5 className='card-header align-items-center bg-light'>
            {gradeValue != 0 && `${gradeValue} Grade`}{' '}
            {gradeValue == 0 && 'Kindergarten'} /{' '}
            {gradeValue != 0 && `${extractNumericPart(gradeValue)}° Grado`}{' '}
            {gradeValue == 0 && 'Preescolar'}
          </h5>
          <div className='card-body'>
            <div
              className={
                hasFieldError?.includes('Student_Enrolled_Grade_Local_School')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                Hope City will transfer your grades from your local school to
                our USA school. Is your student enrolled in{' '}
                {gradeValue != 0 && `${gradeValue} Grade`}{' '}
                {gradeValue == 0 && 'Kindergarten'} in a local school?
              </label>
              <p className='form-label fw-bold'>
                Hope City transferirá sus calificaciones de su escuela local a
                nuestra escuela en EE. UU. ¿Está su estudiante matriculado en
                tercer{' '}
                {gradeValue != 0 && `${extractNumericPart(gradeValue)}° Grado`}{' '}
                {gradeValue == 0 && 'Preescolar'} en una escuela local?
              </p>
              <div className='form-check mt-2'>
                <CheckBoxComponents
                  formik={formik}
                  fieldName='Student_Enrolled_Grade_Local_School'
                  onChange={() => {
                    removeHightlight('Student_Enrolled_Grade_Local_School');
                  }}
                />
                <label className='form-label fw-normal'>Yes / Si</label>
              </div>
              <FieldsErrorText
                touched={formik.touched.Student_Enrolled_Grade_Local_School}
                errors={formik.errors.Student_Enrolled_Grade_Local_School}
              />
            </div>

            {/* <div
              className={
                hasFieldError?.includes('Teacher_Number')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                We would like to give your English teacher at your local school
                a free Hope City account. What is your English teacher’s
                WhatsApp Number? <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                Nos gustaría darle a su profesor de inglés de su escuela local
                una cuenta gratuita de Hope City. ¿Cuál es el número de WhatsApp
                de tu profesor de inglés?
              </p>
              <div
                className={`input-group whatsapp-number
                             ${
                               formik.touched.Teacher_Number &&
                               formik.errors.Teacher_Number &&
                               'error-border'
                             }
                             ${
                               formik.touched.Teacher_Number &&
                               !formik.errors.Teacher_Number &&
                               'success-border'
                             }
                             `}
              >
                <PhoneNumberComponents
                  formik={formik}
                  fieldName={'Teacher_Number'}
                  onChange={() => {
                    removeHightlight('Teacher_Number');
                  }}
                />
                <FieldsErrorText
                  touched={formik.touched.Teacher_Number}
                  errors={formik.errors.Teacher_Number}
                />
              </div>
            </div>

            <div
              className={
                hasFieldError?.includes('Teacher_Name')
                  ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
                  : 'p-3'
              }
            >
              <label className='form-label fw-bold'>
                What is your English teacher’s name?
                <span className='text-danger'> * </span>
              </label>
              <p className='form-label fw-bold'>
                ¿Cómo se llama tu profesora de inglés?
              </p>
              <TextFieldComponents
                formik={formik}
                fieldName='Teacher_Name'
                placeholder='Your teacher name'
                onChange={() => {
                  removeHightlight('Teacher_Name');
                }}
              />
              <FieldsErrorText
                touched={formik.touched.Teacher_Name}
                errors={formik.errors.Teacher_Name}
              />
            </div> */}

            <HighlightedIssuesText formik={formik} />
          </div>
        </div>
        <EnrollmentFooter
          isBackButtonShow={true}
          setStep={setStep}
          step={step}
          onFormReset={onFormResponseReset}
          onSaveForm={onFormResponseSave}
        />
      </form>
    </div>
  );
};

export default Enrollment_Section2;
