export const updateRequirementField = (
  fieldName: string,
  formikValue: string,
  formType: number | null,
  setWarningModel: React.Dispatch<React.SetStateAction<boolean>>,
  setRequirementFieldData: React.Dispatch<React.SetStateAction<string>>,
  formik: any,
  rquirementFill: Array<{ [key: string]: boolean }>
) => {
  const updateArray = [...rquirementFill];

  // Handle specific field updates based on formType
  const updateFieldBasedOnFormType = (targetFieldName: string) => {
    const selectedFieldIndex = updateArray.findIndex(
      (item) => targetFieldName in item
    );

    if (selectedFieldIndex !== -1) {
      updateArray[selectedFieldIndex] = { [targetFieldName]: false };
    } else {
      updateArray.push({ [targetFieldName]: false });
    }
  };

  if (formType === 1) {
    updateFieldBasedOnFormType('Health_Requirement_Subject');
  }

  if (formType === 2) {
    updateFieldBasedOnFormType('Foreign_Language_Requirement_Subject');
  }

  // Handle the main field update

  if (fieldName != '') {
    const existingObjectIndex = updateArray.findIndex(
      (item) => fieldName in item
    );

    if (formikValue === 'No') {
      formik.setFieldValue(fieldName, '');
      setWarningModel(true);
      setRequirementFieldData(fieldName);

      if (existingObjectIndex !== -1) {
        updateArray[existingObjectIndex] = { [fieldName]: true };
      } else {
        updateArray.push({ [fieldName]: true });
      }
    } else {
      if (existingObjectIndex !== -1) {
        updateArray[existingObjectIndex] = { [fieldName]: false };
      } else {
        updateArray.push({ [fieldName]: false });
      }
    }
  }
  return updateArray;
};
