import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { Form, FormTextProps } from 'react-bootstrap';

const FormikErrorText: React.FC<FormTextProps & { name: string }> = (props) => {
  const { errors, touched } = useFormikContext();
  const { name, ...restProps } = props;

  const error = getIn(errors, name);
  const hasError = error && getIn(touched, name);

  return (
    hasError && (
      <Form.Text {...restProps} className='text-danger'>
        {error}
      </Form.Text>
    )
  );
};

export default FormikErrorText;
