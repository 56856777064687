import React from 'react';

const K12_Science_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>Science</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>- Learning Chemistry</li>
          <li>- Learning Physics</li>
          <li>- Learning Biology</li>
          <li>- Learning Philosophy </li>
          <li>- Earth Science: Geology and Plate Tectonics</li>
          <li>- Ecology, Food Webs, and Environmental Sustainability</li>
          <li>- Advanced Physics: Energy and Waves</li>
          <li>- Genetics, DNA, and Biotechnology</li>
          <li>- Environmental Science and Conservation</li>
          <li>
            - Interdisciplinary Science: Climate Change and Sustainability
          </li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. Aquí hay algunos ejemplos de cosas que su escuela
          local puede enseñar para cumplir con el requisito; no las exigimos
          todas.
        </h5>
        <ul className='list-unstyled form-label'>
          <li>- Aprender acerca de química</li>
          <li>- Aprender acerca de física</li>
          <li>- Aprender acerca de biología</li>
          <li>- Aprender acerca de filosofía</li>
          <li>- Ciencias de la Tierra: Geología y Placas Tectónica</li>
          <li>- Ecología, círculo alimenticio y Sostenibilidad Ambiental</li>
          <li>- Física Avanzada: Energía y Ondas</li>
          <li>- Genética, ADN y Biotecnología</li>
          <li>- Ciencia Ambiental y Conservación</li>
          <li>
            - Ciencia Interdisciplinaria: Cambio Climático y Sostenibilidad
          </li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, un padre o tutor
          puede enseñarle al estudiante esta materia en casa a través de videos
          de YouTube y material en línea. Desafortunadamente, si no se cumplen
          estos requisitos para esta materia escolar, no podremos inscribir a su
          estudiante en Hope City.
        </div>
      </div>
    </div>
  );
};

export default K12_Science_Requirement;
