import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Col,
  Button,
  Card,
  Container,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { BsFolder, BsTrash } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';
import Swal from 'sweetalert2';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteQuery,
  useGetQuery,
  usePostQuery,
} from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import { jwtTokenDecode } from 'hooks/tokenHook';
import Loader from 'app/components/Loader';
import { getAxiosInstance } from 'helpers/api-helper';
import useIntlCustom from 'hooks/useIntlCustom';

const TeacherFolderList: React.FC<any> = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    setFolderDrag,
    folderDrag,
    folderDataList,
    setFolderDataList,
    setFolderDragIndex,
    folderDragIndex,
    setCourseList,
    setFolderParentID,
    onFolderClick,
    setDragFolderID,
    dragFolderID,
    selectedFolderId,
    setTreeData,
    setDragType,
    dragType,
    treeData,
    courseDataID,
    setBreadcrumbs,
    getBreadcrumb,
    drag,
    setDrag,
    UserID,
    getRecord,
    setModalFolderShow,
  } = props;

  const updateByOrderFolder: any = usePostQuery(
    'updateByOrderFolder',
    '/folder/updateByOrder',
    {}
  );

  useEffect(() => {
    if (updateByOrderFolder.isSuccess) {
      setTreeData(updateByOrderFolder?.data?.ResultObject);
    }
  }, [updateByOrderFolder.isSuccess]);

  const onSortEnd = async (data: any) => {
    const { oldIndex, newIndex } = data;
    let newArray: any = arrayMove(folderDataList, oldIndex, newIndex);
    setFolderDataList(newArray);
    const folderIds = newArray.map((ele: any) => ele._id);
    const userId = UserID;

    updateByOrderFolder.mutate({ folderIds, userId });
    setFolderDrag(false);
  };
  return (
    <>
      {updateByOrderFolder?.isLoading && (
        <Loader loading={updateByOrderFolder?.isLoading} />
      )}
      <SortableList
        axis='xy'
        onSortEnd={onSortEnd}
        onSortStart={(res: any) => {
          setFolderDrag(true);
          setFolderDragIndex(res.index);
        }}
      >
        {folderDataList?.map((item: any, index: any) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            dataItem={item}
            setFolderDataList={setFolderDataList}
            setCourseList={setCourseList}
            setFolderParentID={setFolderParentID}
            onFolderClick={onFolderClick}
            setDragFolderID={setDragFolderID}
            dragFolderID={dragFolderID}
            selectedFolderId={selectedFolderId}
            setTreeData={setTreeData}
            setDragType={setDragType}
            dragType={dragType}
            treeData={treeData}
            courseDataID={courseDataID}
            setBreadcrumbs={setBreadcrumbs}
            getBreadcrumb={getBreadcrumb}
            drag={drag}
            setDrag={setDrag}
            UserID={UserID}
            getRecord={getRecord}
          />
        ))}
        <Col lg={4} className='sortable-item'>
          <div className='card card-flush shadow-sm h-100'>
            <div className='card-body '>
              <button
                className='d-flex flex-column justify-content-center align-items-center py-5 btn btn-light fw-bold w-100 h-100'
                onClick={() => setModalFolderShow(true)}
              >
                <AiOutlinePlus />
                {/* New Folder */}{' '}
                {formatMessage({
                  id: 'TEACHER.NEW_FOLDER',
                })}
              </button>
            </div>
          </div>
        </Col>
      </SortableList>
    </>
  );
};

export default TeacherFolderList;

const SortableList: any = SortableContainer(({ children }: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <>
      <Row className='g-5'>
        <h3>
          {formatMessage({
            id: 'FOLDER',
          })}
        </h3>
        {children}
      </Row>
    </>
  );
});

const SortableItem: any = SortableElement((props: any) => {
  const {
    dataItem,
    folderDrag,
    setFolderDrag,
    setCourseDataID,
    courseDataID,
    setCourseList,
    setFolderDataList,
    setFolderParentID,
    onFolderClick,
    setDragFolderID,
    dragFolderID,
    selectedFolderId,
    setTreeData,
    setDragType,
    dragType,
    treeData,
    setBreadcrumbs,
    getBreadcrumb,
    drag,
    setDrag,
    UserID,
    getRecord,
  } = props;

  const updateFolderParentID: any = usePostQuery(
    'updateFolderParentID',
    '/folder/updateFolderParentID',
    {}
  );

  useEffect(() => {
    if (updateFolderParentID.isSuccess) {
      getRecord(selectedFolderId ? selectedFolderId : null);
      setTreeData(updateFolderParentID?.data?.ResultObject?.folders);
    }
  }, [updateFolderParentID.isSuccess]);

  const saveFolder = async (folderID: any, parentID: any) => {
    setFolderParentID(folderID);
    const folderSaveData = {
      parentID: parentID,
      folderID: folderID,
      userID: UserID,
    };
    updateFolderParentID.mutate(folderSaveData);
  };

  const onFolderDragStart = (event: any, id: any, type: any) => {
    setDragType(type);
    setDragFolderID(id);
  };

  const onFolderDrag = (event: any) => {
    event.preventDefault();
  };

  const onFolderDragEnd = (event: any) => {
    setDragType(null);
    setDragFolderID(null);
  };

  const onHandleFolderDragOver = (event: any) => {
    event.preventDefault();
  };

  const onHandleFolderDrop = async (event: any, id: any) => {
    event.preventDefault();
    if (dragType == 'Folder' && id != dragFolderID) {
      const sourceNodeId = event.dataTransfer.getData('nodeId');
      const isParentToChildDrop = await isDescendant(id, dragFolderID);
      if (isParentToChildDrop) {
        saveFolder(dragFolderID, id);
      }
    }
    if (dragType == 'Course') {
      onSaveCourse(id, selectedFolderId, courseDataID);
    }
  };

  const isDescendant = (parentId: string | null, childId: string): boolean => {
    const parentFolder = treeData.find(
      (folder: any) => folder._id === parentId
    );
    if (!parentFolder) {
      return false;
    }

    if (parentFolder.ParentID === childId) {
      return false;
    }

    if (parentFolder.ParentID === null) {
      return true;
    }

    return isDescendant(parentFolder.ParentID, childId);
  };

  const onSaveCourse = (id: any, folderID: any, courseID: any) => {
    const saveValue = {
      CourseID: courseID,
      folderID: id,
    };
    getAxiosInstance()
      .post('/folder/saveFolderCourse', saveValue)
      .then((res) => {
        getRecord(folderID ? folderID : null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Col lg={4} data-items={dataItem}>
        <div className='card card-flush shadow-sm h-100'>
          <div className='card-body d-flex flex-column justify-content-center align-items-center'>
            <button
              className='btn btn-light h-100 w-100'
              onClick={() => {
                onFolderClick(dataItem._id),
                  getRecord(dataItem._id),
                  setBreadcrumbs([
                    { ID: null, FolderName: 'Home' },
                    ...getBreadcrumb(dataItem._id),
                  ]),
                  setFolderParentID(dataItem._id);
              }}
              draggable='true'
              onDragStart={(event) => {
                onFolderDragStart(event, dataItem?._id, 'Folder'),
                  event.dataTransfer.setData('nodeId', dataItem?._id);
              }}
              onDragEnd={(event) => onFolderDragEnd(event)}
              onDragOver={(event) => onHandleFolderDragOver(event)}
              onDrop={(event) => onHandleFolderDrop(event, dataItem?._id)}
              onDrag={(event) => onFolderDrag(event)}
              onMouseUp={(event) => {
                if (drag) {
                  onHandleFolderDrop(event, dataItem?._id);
                }
              }}
            >
              <BsFolder size={20} /> {dataItem && dataItem.FolderName}
            </button>
          </div>
        </div>
      </Col>
    </>
  );
});
