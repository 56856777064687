import { memo, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toAbsoluteUrl } from 'theme/helpers';
import { useState } from 'react';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import LoaderNew from 'app/components/LoaderNew';
import useIntlCustom from 'hooks/useIntlCustom';

const OpenWaitingRoomForIOS = ({ show, link, onHide }: any) => {
  const { formatMessage } = useIntlCustom();
  const [copyButtonTitle, setCopyButtonTitle] = useState('Copy');
  const generateShortUrlQuery: any = usePostQuery(
    'shortURLGenerate',
    '/shortURL/generate',
    {}
  );

  useEffect(() => {
    generateShortUrlQuery.mutate({ originalUrl: link });
  }, []);

  const copyUrl = useCallback(() => {
    setTimeout(() => {
      navigator.clipboard.writeText(generateShortUrlQuery?.data?.shortUrl);
      setCopyButtonTitle('Copied');
    }, 100);
  }, [generateShortUrlQuery?.data?.shortUrl]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='sm'
    >
      <button
        type='button'
        className='btn-close position-absolute top-0 end-0 m-5 z-index-3'
        aria-label='Close'
        onClick={() => onHide()}
      />
      <Modal.Body>
        <>
          <LoaderNew isFull={false} loading={generateShortUrlQuery.isLoading} />
          <div className='card border-0'>
            <div className='card-body'>
              <h3 className='card-title'>
                {formatMessage({
                  id: 'BROWSER_NOT_SUPPORTED_MSG',
                })}
              </h3>
              <h6 className='card-subtitle text-muted mb-2'>
                {formatMessage({
                  id: 'COURSE.WAITING.PAST_LINK_MSG',
                })}
              </h6>
              <div className='d-flex justify-content-between align-items-center bg-dark p-2 rounded mb-2'>
                <span className='text-muted ps-2 text-truncate'>
                  {generateShortUrlQuery?.data?.shortUrl}
                </span>
                <button
                  type='button'
                  className={`btn btn-sm  ${
                    copyButtonTitle === 'Copy' ? 'btn-primary' : 'btn-success'
                  }`}
                  onClick={copyUrl}
                >
                  {formatMessage({
                    id: copyButtonTitle,
                  })}
                </button>
              </div>

              <div className='d-flex justify-content-between align-items-center  p-2 rounded'>
                <div>
                  <img
                    src={toAbsoluteUrl('/media/icons/browsers/Edge_Logo.svg')}
                    alt='Edge'
                    className='h-50px w-50px'
                  />
                </div>
                <div>
                  <img
                    src={toAbsoluteUrl(
                      '/media/icons/browsers/Firefox_logo.svg'
                    )}
                    alt='Firefox'
                    className='h-50px w-50px'
                  />
                </div>

                <div>
                  <img
                    src={toAbsoluteUrl(
                      '/media/icons/browsers/Google_Chrome_logo.svg'
                    )}
                    alt='Google Chrome'
                    className='h-50px w-50px'
                  />
                </div>

                <div>
                  <img
                    src={toAbsoluteUrl('/media/icons/browsers/Opera_logo.svg')}
                    alt='Opera'
                    className='h-50px w-50px'
                  />
                </div>

                <div>
                  <img
                    src={toAbsoluteUrl('/media/icons/browsers/Safari_logo.svg')}
                    alt='Safari'
                    className='h-50px w-50px'
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default memo(OpenWaitingRoomForIOS);
