/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../theme/helpers';
import {
  getLayoutFromLocalStorage,
  ILayout,
  LayoutSetup,
} from '../../../theme/layout/core';
import { connect, ConnectedProps } from 'react-redux';
import createNotification from '../../../helpers/alert';
import { useDispatch } from 'react-redux';
import {
  studentDelete,
  studentGet,
  studentGetById,
} from '../../../store/student/actions';
import StudentEditModel from './StudentEditModel';
import StudentDeleteModel from './StudentDeleteModel';

const StudentsPage: React.FC = (props: any) => {
  const {
    success,
    error,
    studentlist,
    onStudentDelete,
    studentDetail,
    onStudentGetById,
  } = props;

  const [studentData, setStudentData] = useState([]);
  const [deleteID, setDeleteID] = useState('');
  const [studentDeleteID, setStudentDeleteID] = useState('');
  const [deleteModel, setDeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editID, setEditID] = useState('');
  const [studentEditID, setStudentEditID] = useState('');

  useEffect(() => {
    props.studentgetReq();
  }, []);

  useEffect(() => {
    props.studentgetReq();
  }, [success]);

  useEffect(() => {
    if (studentlist?.length > 0) {
      setStudentData(studentlist);
    }
  }, [studentlist]);

  useEffect(() => {
    setEditID(editID);
  }, [success]);

  const handleEdit = (id: any, stdId: any) => {
    setEditModel(true);
    setEditID(id);
    setStudentEditID(stdId);
  };

  const handleDelete = (id: any, stdId: any) => {
    setDeleteModel(true);
    setDeleteID(id);
    setStudentDeleteID(stdId);
  };

  const handleDeleteRecord = () => {
    const data = {
      userID: deleteID,
      studentId: studentDeleteID,
    };

    onStudentDelete(data);
    setDeleteModel(false);
  };

  const handleEditRecord = () => {
    const editData = {
      userID: editID,
      studentId: studentEditID,
    };
    onStudentGetById(editData);
    setEditModel(false);
  };

  const handleCloseDeleteModel = () => {
    setDeleteModel(false);
  };

  const handleCloseEditModel = () => {
    setEditModel(false);
  };

  return (
    <>
      <div className='card mb-10'>
        {/* begin::Body Table */}
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Students Data</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-140px'>Email</th>
                  <th className='min-w-120px'>Active ? </th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {studentData.map((result: any) => {

                  return (
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              src={toAbsoluteUrl('/media/avatars/300-14.jpg')}
                              alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary fs-6'
                            >
                              {result?.UserId?.FirstName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {result?.UserId?.LastName}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block fs-6'
                        >
                          {result?.UserId?.Email}
                        </a>
                      </td>
                      <td className='text-end'>
                        <span className='badge badge-light-success'>
                          {result?.UserId?.IsActive ? 'Yes' : 'No'}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          {/* <a
                                                        href='#'
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen019.svg'
                                                            className='svg-icon-3'
                                                        />
                                                    </a> */}
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => {
                              handleEdit(result?.UserId?._id, result._id);
                            }}
                            data-bs-toggle='modal'
                            data-bs-target='#Edit_Modal_scrollable'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => {
                              handleDelete(result?.UserId?._id, result._id);
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {/* End::Body Table */}
      </div>
      <StudentDeleteModel
        deleteModel={deleteModel}
        handleDeleteRecord={handleDeleteRecord}
        handleCloseDeleteModel={handleCloseDeleteModel}
      />
      <StudentEditModel
        editModel={editModel}
        handleEditRecord={handleEditRecord}
        handleCloseEditModel={handleCloseEditModel}
        studentDetail={studentDetail}
      />
    </>
  );
};
// export default (StudentsPage);

const mapStateToProps = (state: any) => {
  return {
    loading: state.student.loading,
    error: state.student.error,
    success: state.student.success,
    studentlist: state.student.studentlist,
    studentDetail: state.student.studentDetail,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  studentgetReq: () => dispatch(studentGet()),
  onStudentDelete: (params: any) => dispatch(studentDelete(params)),
  onStudentGetById: (params: any) => dispatch(studentGetById(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsPage);
