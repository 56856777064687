import React from 'react';

const SchoolRequirementsK6 = (props: any) => {
  const { formType } = props;
  return (
    <>
      <div className='p-3'>
        <label className='form-label fw-bold'>
          K-6 School Requirements: / Requisitos escolares K-6:
        </label>
        <p className='form-label fw-normal'>
          (Please contact us if these subjects/courses do not appear on your
          student's transcript from local school.){' '}
        </p>

        <p className='form-label fw-normal'>
          (Comuníquese con nosotros si estas materias/cursos no aparecen en el
          expediente académico de su estudiante de la escuela local).
        </p>

        <p className='form-label mt-5 fw-bold'>English: </p>
        <p className='form-label fw-normal'>
          Literature and language, speaking, reading, listening, spelling,
          handwriting, and composition.
        </p>

        <p className='form-label mt-5 fw-bold'>Mathematics: </p>

        <p className='form-label fw-normal'>
          Concepts, operational skills, and problem solving.
        </p>

        <p className='form-label mt-5 fw-bold'>Social sciences:</p>
        <p className='form-label fw-normal'>
          Anthropology, economics, geography, history, political science,
          psychology, and sociology, (Age/developmentally appropriate).
        </p>

        <p className='form-label mt-5 fw-bold'>Science: </p>
        <p className='form-label fw-normal'>
          Including the biological and physical aspects, with emphasis on the
          processes of experimental inquiry and on the place of humans in
          ecological systems.
        </p>

        <p className='form-label mt-5 fw-bold'>Visual and performing arts: </p>
        <p className='form-label fw-normal'>
          Including instruction in the subjects of dance, music, theatre, and
          visual arts, aimed at the development of aesthetic appreciation and
          the skills of creative expression.
        </p>

        <p className='form-label mt-5 fw-bold'>Health: </p>
        <p className='form-label fw-normal'>
          Including instruction in the principles and practices of individual,
          family, and community health.
        </p>

        <p className='form-label mt-5 fw-bold'>Physical education: </p>
        <p className='form-label fw-normal'>
          Emphasis upon the physical activities for the pupils that may be
          conducive to health and vigor of body and mind, for a total period of
          time of not less than 200 minutes each 10 schooldays, exclusive of
          recesses and the lunch period.
        </p>

        <p className='form-label mt-5 mb-5 fw-bold'>----</p>

        <p className='form-label mt-5 fw-bold'>Inglés: </p>
        <p className='form-label fw-normal'>
          Literatura y lenguaje, hablar, leer, escuchar, ortografía, escritura y
          composición.
        </p>

        <p className='form-label mt-5 fw-bold'>Matemáticas: </p>
        <p className='form-label fw-normal'>
          Conceptos, habilidades operativas y resolución de problemas.
        </p>

        <p className='form-label mt-5 fw-bold'>Ciencias Sociales:</p>
        <p className='form-label fw-normal'>
          Antropología, economía, geografía, historia, ciencias políticas,
          psicología y sociología (Edad/apropiado para el desarrollo).
        </p>

        <p className='form-label mt-5 fw-bold'>Ciencia: </p>
        <p className='form-label fw-normal'>
          Incluyendo los aspectos biológicos y físicos, con énfasis en los
          procesos de indagación experimental y en el lugar de los humanos en
          los sistemas ecológicos.
        </p>

        <p className='form-label mt-5 fw-bold'>Artes visuales y escénicas: </p>
        <p className='form-label fw-normal'>
          Incluyendo instrucción en las materias de danza, música, teatro y
          artes visuales, dirigidas al desarrollo de la apreciación estética y
          las habilidades de expresión creativa.
        </p>

        <p className='form-label mt-5 fw-bold'>Salud: </p>
        <p className='form-label fw-normal'>
          Incluyendo instrucción en los principios y prácticas de salud
          individual, familiar y comunitaria.
        </p>

        <p className='form-label mt-5 fw-bold'>Educación Física: </p>
        <p className='form-label fw-normal'>
          Énfasis en las actividades físicas para los alumnos que puedan ser
          conducentes a la salud y el vigor del cuerpo y la mente, por un
          período total de tiempo no menor de 200 minutos cada 10 días
          escolares, sin incluir los recreos y el período de almuerzo.
        </p>
      </div>
    </>
  );
};

const SchoolRequirementsK12 = (props: any) => {
  const { formType } = props;
  return (
    <>
      <div className='p-3'>
        <label className='form-label fw-bold'>
          {' '}
          K-12 Grade School Requirements / Requisitos escolares 7-12:
        </label>
        <p className='form-label fw-normal'>
          (Please contact us if these subjects/courses do not appear on your
          student's transcript from local school.){' '}
        </p>

        <p className='form-label fw-normal'>
          (Comuníquese con nosotros si estas materias/cursos no aparecen en el
          expediente académico de su estudiante de la escuela local).
        </p>

        <p className='form-label mt-5 fw-bold'>English: </p>
        <p className='form-label fw-normal'>
          Literature, language, composition, reading, listening, and speaking.
        </p>

        <p className='form-label mt-5 fw-bold'>Social sciences: </p>

        <p className='form-label fw-normal'>
          Anthropology, economics, geography, history, political science,
          psychology, and sociology. (Age/Developmentally appropriate material.)
        </p>

        <p className='form-label mt-5 fw-bold'>
          Foreign language or languages:
        </p>
        <p className='form-label fw-normal'>
          (beginning not later than grade 7) understanding, speaking, reading,
          and writing the particular language.
        </p>

        <p className='form-label mt-5 fw-bold'>Physical education: </p>
        <p className='form-label fw-normal'>
          Shall be required to attend upon the courses of physical education for
          a total period of time of not less than 400 minutes each 10 school
          days. Examples of what you see in PE are: doing physical exercises,
          learning and practice of sports.
        </p>

        <p className='form-label mt-5 fw-bold'>Science:</p>
        <p className='form-label fw-normal'>
          Including the physical and biological aspects, with emphasis on basic
          concepts, theories, and processes of scientific investigation and on
          the place of humans in ecological systems, and with appropriate
          applications of the interrelation and interdependence of the sciences.
        </p>

        <p className='form-label mt-5 fw-bold'>Mathematics: </p>
        <p className='form-label fw-normal'>
          Including instruction designed to develop mathematical understandings,
          operational skills, and insight into problem-solving procedures.
        </p>

        <p className='form-label mt-5 fw-bold'>Visual and performing arts: </p>
        <p className='form-label fw-normal'>
          Dance, music, theater, and visual arts, with emphasis upon development
          of aesthetic appreciation and the skills of creative expression.
        </p>

        <p className='form-label mt-5 fw-bold'>Applied arts: </p>
        <p className='form-label fw-normal'>
          Consumer and homemaking education, industrial arts, general business
          education, or general agriculture.
        </p>

        <p className='form-label mt-5 mb-5 fw-bold'>----</p>

        <p className='form-label mt-5 fw-bold'>Inglés: </p>
        <p className='form-label fw-normal'>
          Literatura y lenguaje, hablar, leer, escuchar, ortografía, escritura y
          composición.
        </p>

        <p className='form-label mt-5 fw-bold'>Ciencias Sociales: </p>
        <p className='form-label fw-normal'>
          Antropología, economía, geografía, historia, ciencias políticas,
          psicología y sociología. (Edad/material apropiado para el desarrollo).
        </p>

        <p className='form-label mt-5 fw-bold'>Lengua o lenguas extranjeras:</p>
        <p className='form-label fw-normal'>
          (comenzando a más tardar en el grado 7) comprender, hablar, leer y
          escribir el idioma en particular.
        </p>

        <p className='form-label mt-5 fw-bold'>Educación Física:</p>
        <p className='form-label fw-normal'>
          Se requerirá que asista a los cursos de educación física por un
          período total de tiempo no menor a 400 minutos cada 10 días lectivos.
          Ejemplos de lo que ves en Educación Física son: hacer ejercicios
          físicos, aprender y practicar deportes.
        </p>

        <p className='form-label mt-5 fw-bold'>Ciencia: </p>
        <p className='form-label fw-normal'>
          Incluyendo los aspectos físicos y biológicos, con énfasis en conceptos
          básicos, teorías y procesos de investigación científica y en el lugar
          del ser humano en los sistemas ecológicos, y con aplicaciones
          apropiadas de la interrelación e interdependencia de las ciencias.
        </p>

        <p className='form-label mt-5 fw-bold'>Matemáticas: </p>
        <p className='form-label fw-normal'>
          Incluye instrucción diseñada para desarrollar la comprensión
          matemática, las habilidades operativas y la comprensión de los
          procedimientos de resolución de problemas.
        </p>

        <p className='form-label mt-5 fw-bold'>Artes visuales y escénicas: </p>
        <p className='form-label fw-normal'>
          Danza, música, teatro y artes visuales, con énfasis en el desarrollo
          de la apreciación estética y las habilidades de expresión creativa.
        </p>

        <p className='form-label mt-5 fw-bold'>Artes Aplicadas: </p>
        <p className='form-label fw-normal'>
          Educación para el consumidor y tareas del hogar, artes industriales,
          educación empresarial general o agricultura general.
        </p>
      </div>
    </>
  );
};

export { SchoolRequirementsK6, SchoolRequirementsK12 };
