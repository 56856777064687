import React, { FC, useEffect } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import GoogleMeetEarnPoint from './GoogleMeetEarnPoint';
import { useIntl } from 'react-intl';
import { useAuth } from 'app/modules/auth';
import { useNavigate, useParams } from 'react-router-dom';
import useIntlCustom from 'hooks/useIntlCustom';

const GoogleMeetEarnPointWrapper: FC = () => {
  let { scheduleGoogleMeetId } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntlCustom();
  const { auth } = useAuth();

  useEffect(() => {
    if (!auth?.token) {
      navigate(`/login?scheduleGoogleMeetId=${scheduleGoogleMeetId}`);
    }
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {formatMessage({ id: 'VOCABULARY.FORM.TITLE' })}
      </PageTitle>
      <GoogleMeetEarnPoint />
    </>
  );
};

export default GoogleMeetEarnPointWrapper;
