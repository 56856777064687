import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toAbsoluteUrl } from 'theme/helpers';

const RequiredCompletedLessonWarnPopup = ({ show, msg, onHide }: any) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <button
        type='button'
        className='btn-close position-absolute top-0 end-0 m-5 z-index-3'
        aria-label='Close'
        onClick={(e) => {
          e.stopPropagation();
          onHide();
        }}
      />
      <Modal.Body>
        <div className='text-center'>
          <img
            src={toAbsoluteUrl('/media/images/lock.gif')}
            // className='img-fluid'
            alt=''
            height={150}
          />
          <div className='fw-bold h2'>{msg}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(RequiredCompletedLessonWarnPopup);
