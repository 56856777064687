import { useAuth } from 'app/modules/auth';
import { getSubscriptionStatusCall } from 'hooks/subscriptionCheck';
import React, { useEffect } from 'react';

const SubscriptionCheck = ({ children }: any) => {
  const {
    auth,
    authSubscription,
    saveAuthSubscription,
    setCurrentUser,
    currentUser,
  } = useAuth();
  useEffect(() => {
    if (auth && auth?.token) {
      getSubscriptionStatusCall().then((res) => {
        saveAuthSubscription(res?.ResultObject);
      });
    }
  }, [auth]);

  return authSubscription && <>{children}</>;
};

export default SubscriptionCheck;
