import { createSagaActionTypes } from 'store/utils';

// WARING: DON'T EDIT THIS FILE
export default createSagaActionTypes('classroom', 'action', [
  'get_requested',
  'get_succeed',
  'get_failed',

  'Get_Join_Live_Class_Schedule_ID_Requested',
  'Get_Join_Live_Class_Schedule_ID_succeed',
  'Get_Join_Live_Class_Schedule_ID_failed',

  'get_join_requested',
  'get_join_succeed',
  'get_join_failed',

  'Get_Join_Live_Class_ID_Requested',
  'Get_Join_Live_Class_ID_succeed',
  'Get_Join_Live_Class_ID_failed',
] as const);
