import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikConfig } from 'formik';
import { Alert, Button, Form, Modal as BModal, Spinner } from 'react-bootstrap';
import FormikErrorText from 'app/components/palette/FormikErrorText';
import PhoneInput, {
  isValidPhoneNumber,
  Value,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type T = {
  phNumber: string;
};

type PropsType<T> = {
  initialValues?: T;
  visible: boolean;
  title: string;
  okText?: string;
  updateText?: string;
  cancelText?: string;
  loading?: boolean;
  onSubmit: FormikConfig<T>['onSubmit'];
  onCancel: () => void;
  errorMessage?: any;
};

const validationSchema = Yup.object({
  phNumber: Yup.string()
    .trim()
    .defined('Please enter phone number.')
    .test('isValidPhoneNumber', `Please enter a valid phone number`, (value) =>
      value ? isValidPhoneNumber(value) : false
    ),
});

const WhatsAppConnectPopup: React.FC<React.PropsWithChildren<PropsType<T>>> = (
  props
) => {
  const {
    title,
    visible = true,
    okText = 'Submit',
    cancelText = 'Cancel',
    updateText = 'Save',
    loading = false,
    onSubmit,
    initialValues: oldInitialValues,
    errorMessage = '',
    onCancel = () => null,
  } = props;

  const initialValues = {
    phNumber: '',
    ...(oldInitialValues || {}),
  };

  const text = oldInitialValues?.phNumber ? updateText : okText;

  const disabled = loading;

  return (
    <BModal show={visible} backdrop='static' keyboard={false} onHide={onCancel}>
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <BModal.Body>
              {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
              <Form.Group
                className='mb-3'
                controlId='phNumber'
                aria-disabled={disabled}
              >
                <Form.Label required>WhatsApp Number</Form.Label>
                <PhoneInput
                  placeholder='Enter phone number'
                  value={values.phNumber}
                  onChange={(value: Value) => {
                    setFieldValue('phNumber', value || '');
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.phNumber && errors.phNumber !== undefined}
                  containerComponent={Form.Group}
                  inputComponent={Form.Control}
                  disabled={disabled}
                />
                <FormikErrorText name='phNumber' />
              </Form.Group>
            </BModal.Body>
            <BModal.Footer>
              <Button variant='primary' type='submit' disabled={disabled}>
                {loading ? <Spinner size='sm' /> : text}
              </Button>
              <Button
                variant='secondary'
                type='button'
                disabled={disabled}
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            </BModal.Footer>
          </Form>
        )}
      </Formik>
    </BModal>
  );
};

export default WhatsAppConnectPopup;
