import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useCountdown } from 'hooks/useCountDown';
import { memo, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import LoaderNew from 'app/components/LoaderNew';
import createNotification from 'helpers/alert';
import { useParams } from 'react-router-dom';
import { toAbsoluteUrl } from 'theme/helpers';
import useIntlCustom from 'hooks/useIntlCustom';
import Video from 'app/components/Video';

const WaitingClassRoom = () => {
  const { formatMessage } = useIntlCustom();
  let { courseInviteId, scheduleGoogleMeetId } = useParams();
  const [openURL, setOpenURL] = useState(false);

  const joinScheduleGoogleMeetQuery: any = usePostQuery(
    'joinScheduleGoogleMeet',
    '/scheduleGoogleMeet/joinScheduleGoogleMeet',
    {}
  );

  const getWaitingRoomFromScheduleGoogleMeet: any = useGetQuery(
    'getWaitingRoomFromScheduleGoogleMeet',
    `/scheduleGoogleMeet/getWaitingRoomFromScheduleGoogleMeet`,
    {}
  );

  useEffect(() => {
    getWaitingRoomFromScheduleGoogleMeet.mutate({
      params: {
        courseInviteId: courseInviteId,
        scheduleGoogleMeetId: scheduleGoogleMeetId,
      },
    });
  }, []);

  useEffect(() => {
    if (getWaitingRoomFromScheduleGoogleMeet?.error) {
      createNotification(
        'warning',
        getWaitingRoomFromScheduleGoogleMeet?.error?.response?.data
          ?.ResultMessage
      );
      history.back();
    }
  }, [getWaitingRoomFromScheduleGoogleMeet?.error]);

  useEffect(() => {
    if (
      getWaitingRoomFromScheduleGoogleMeet?.isSuccess &&
      getWaitingRoomFromScheduleGoogleMeet?.data?.ResultObject?.isStart &&
      !openURL
    ) {
      joinScheduleGoogleMeet();
    } else if (
      getWaitingRoomFromScheduleGoogleMeet?.isSuccess &&
      !getWaitingRoomFromScheduleGoogleMeet?.data?.ResultObject
    ) {
      createNotification('warning', 'Live class has been ended');
      history.back();
    }
  }, [getWaitingRoomFromScheduleGoogleMeet?.isSuccess]);

  const joinScheduleGoogleMeet = () => {
    const formData: any = {
      scheduleGoogleMeetId: scheduleGoogleMeetId,
      courseInviteId: courseInviteId,
    };

    joinScheduleGoogleMeetQuery.mutate(formData);

    setOpenURL(true);
    window.location.href =
      getWaitingRoomFromScheduleGoogleMeet?.data?.ResultObject?.scheduleGoogleMeetId?.link;
  };

  const StartCountdownTimer = ({ startTime }: any) => {
    const [days, hours, minutes, seconds] = useCountdown(new Date(startTime));

    if (days + hours + minutes + seconds <= 0 && !openURL) {
      joinScheduleGoogleMeet();
    }
    return (
      <div className='row justify-content-center'>
        {days > 0 && (
          <>
            <div className='col-xl-2 col-lg-2 col-md-2 col-12 p-0'>
              <label className='btn btn-outline btn-outline-dashed  d-flex align-items-center justify-content-center'>
                <span className='d-block fw-semibold text-center'>
                  <span className='text-light fw-bold d-block fs-m1 mb-2'>
                    {days}
                  </span>
                  <span className='fw-semibold fs-6 text-light'>
                    {formatMessage({
                      id: 'COURSE.DAYS',
                    })}
                  </span>
                </span>
              </label>
            </div>
            <div className='col-xl-1 col-md-1 d-flex  align-items-center justify-content-center p-0'>
              {' '}
              <div className='fs-m1 text-light fw-bold fadeinout'> : </div>
            </div>
          </>
        )}
        {hours > 0 && (
          <>
            <div className='col-xl-2 col-lg-2 col-md-2 col-12 p-0'>
              <label className='btn btn-outline btn-outline-dashed  d-flex align-items-center justify-content-center'>
                <span className='d-block fw-semibold text-center'>
                  <span className='text-light fw-bold d-block fs-m1 mb-2'>
                    {hours}
                  </span>
                  <span className='fw-semibold fs-6 text-light'>
                    {formatMessage({
                      id: 'COURSE.HOURS',
                    })}
                  </span>
                </span>
              </label>
            </div>
            <div className='col-xl-1 col-md-1 d-flex align-items-center justify-content-center p-0'>
              <div className='fs-m1 text-light fw-bold fadeinout'>:</div>
            </div>
          </>
        )}
        {minutes > 0 && (
          <>
            <div className='col-xl-2 col-lg-2 col-md-2 col-12 p-0'>
              <label className='btn btn-outline btn-outline-dashed  d-flex align-items-center justify-content-center'>
                <span className='d-block fw-semibold text-center'>
                  <span className='text-light fw-bold d-block fs-m1 mb-2'>
                    {minutes}
                  </span>
                  <span className='text-light fw-semibold fs-6'>
                    {formatMessage({
                      id: 'COURSE.MINUTES',
                    })}
                  </span>
                </span>
              </label>
            </div>
            <div className='col-xl-1 col-md-1 d-flex align-items-center justify-content-center p-0'>
              <div className='fs-m1 text-light fw-bold fadeinout'>:</div>
            </div>
          </>
        )}
        {seconds > 0 && (
          <>
            <div className='col-xl-2 col-lg-2 col-md-2 col-12 p-0'>
              <label className='btn btn-outline btn-outline-dashed  d-flex align-items-center justify-content-center'>
                <span className='d-block fw-semibold text-center'>
                  <span className='text-light fw-bold d-block fs-m1 mb-2'>
                    {seconds}
                  </span>
                  <span className='text-light fw-semibold fs-6'>
                    {formatMessage({
                      id: 'COURSE.SECONDS',
                    })}
                  </span>
                </span>
              </label>
            </div>
          </>
        )}
      </div>
    );
  };

  const EndClassRoomCountdownTimer = ({ startTime, endTime }: any) => {
    const [days, hours, minutes, seconds] = useCountdown(new Date(endTime));
    if (days + hours + minutes + seconds <= 0) {
      createNotification(
        'warning',
        formatMessage({
          id: 'COURSE.CLASS_HAS_BEEN_ENDED',
        })
      );
      history.back();
    }
    return (
      <>
        {days + hours + minutes + seconds >= 0 && (
          <StartCountdownTimer startTime={startTime} />
        )}
      </>
    );
  };

  return (
    <>
      {getWaitingRoomFromScheduleGoogleMeet.isLoading || openURL ? (
        <LoaderNew loading={true} />
      ) : (
        !getWaitingRoomFromScheduleGoogleMeet?.data?.ResultObject?.isStart && (
          <Row className='justify-content-center'>
            {/* <Col xl={4} lg={12} md={4}>
              <Video
                src={toAbsoluteUrl('/media/video/waitingRoom.mp4')}
                autoPlay={'autoplay'}
                loop
                muted
                playsInline={'playsinline'}
                preload='auto'
                className=' rounded h-100'
                controls={true}
                poster={toAbsoluteUrl('/media/icons/loader1_2.gif')}
              />
            </Col> */}
            <Col
              xl={8}
              lg={12}
              md={8}
              className='bgi-size-cover bgi-position-center p-10 rounded'
              style={{
                backgroundImage: `url("/media/misc/auth-bg.png")`,
              }}
            >
              <div className='row stepper stepper-pills stepper-column stepper-multistep g-5 '>
                <div className='col-12 col-lg-12 col-md-4  '>
                  <div className='d-flex justify-content-center align-items-center p-3'>
                    <i className='fa fa-clock fa-spin fa-light-clock fa-1x'></i>
                  </div>
                </div>
                <div className='col-12 col-lg-12 col-md-8  '>
                  <div className='stepper-nav'>
                    <div className='stepper-item' data-kt-stepper-element='nav'>
                      <div className='stepper-wrapper'>
                        <div className='stepper-icon rounded-3'>
                          <i className='stepper-check fas fa-check'></i>
                          <span className='stepper-number'>
                            <i className='fa-regular fa-handshake text-white fs-3'></i>
                          </span>
                        </div>
                        <div className='stepper-label'>
                          <h3 className='stepper-title fs-2'>
                            {formatMessage({
                              id: 'COURSE.COURSE_NAME',
                            })}
                          </h3>
                          <div className='stepper-desc fw-normal'>
                            {
                              getWaitingRoomFromScheduleGoogleMeet?.data
                                ?.ResultObject?.scheduleGoogleMeetId?.name
                            }
                          </div>
                        </div>
                      </div>
                      <div className='stepper-line h-40px'></div>
                    </div>
                    <div className='stepper-item' data-kt-stepper-element='nav'>
                      <div className='stepper-wrapper'>
                        <div className='stepper-icon rounded-3'>
                          <i className='stepper-check fas fa-check'></i>
                          <span className='stepper-number'>
                            {' '}
                            <i className='fa fa-thin fa-calendar-days text-white fs-3'></i>
                          </span>
                        </div>

                        <div className='stepper-label'>
                          <h3 className='stepper-title'>
                            {formatMessage({
                              id: 'COURSE.LIVE_CLASS_START_TIME',
                            })}
                          </h3>
                          <div className='stepper-desc fw-normal'>
                            {moment(
                              getWaitingRoomFromScheduleGoogleMeet?.data
                                ?.ResultObject?.date
                            ).format('MM/DD/YYYY hh:mm A')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <EndClassRoomCountdownTimer
                    startTime={
                      getWaitingRoomFromScheduleGoogleMeet?.data?.ResultObject
                        ?.date
                    }
                    endTime={
                      getWaitingRoomFromScheduleGoogleMeet?.data?.ResultObject
                        ?.endTime
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        )
      )}
    </>
  );
};

export default memo(WaitingClassRoom);
