import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikConfig } from 'formik';
import { Alert, Button, Form, Modal as BModal, Spinner } from 'react-bootstrap';
import FormikErrorText from 'app/components/palette/FormikErrorText';

// const MAX_ID_LENGTH = 15;

type T = {
  igUsername: string;
};

type PropsType<T> = {
  initialValues?: T;
  visible: boolean;
  title: string;
  okText?: string;
  updateText?: string;
  cancelText?: string;
  loading?: boolean;
  onSubmit: FormikConfig<T>['onSubmit'];
  onCancel: () => void;
  errorMessage?: any;
};

const validationSchema = Yup.object({
  igUsername: Yup.string()
    .trim()
    .defined('Please enter Instagram username.')
    .test('isValidUsername', 'Please enter valid instagram username', (value) =>
      value ? /^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/.test(value) : false
    ),
});

const InstagramConnectPopup: React.FC<React.PropsWithChildren<PropsType<T>>> = (
  props
) => {
  const {
    title,
    visible = true,
    okText = 'Submit',
    cancelText = 'Cancel',
    updateText = 'Save',
    loading = false,
    onSubmit,
    initialValues: oldInitialValues,
    errorMessage = '',
    onCancel = () => null,
  } = props;

  const initialValues = {
    igUsername: '',
    ...(oldInitialValues || {}),
  };

  const text = oldInitialValues?.igUsername ? updateText : okText;

  const disabled = loading;

  return (
    <BModal show={visible} backdrop='static' keyboard={false} onHide={onCancel}>
      <BModal.Header closeButton>
        <BModal.Title>{title}</BModal.Title>
      </BModal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={onSubmit}
      >
        {({ touched, errors, getFieldProps, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <BModal.Body>
              {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
              <Form.Group
                className='mb-3'
                controlId='igUsername'
                aria-disabled={disabled}
              >
                <Form.Label required>Instagram Username</Form.Label>
                <Form.Control
                  {...getFieldProps('igUsername')}
                  alt='Instagram username'
                  placeholder='Enter your instagram username'
                  autoComplete='off'
                  disabled={disabled}
                  isInvalid={
                    touched.igUsername && errors.igUsername !== undefined
                  }
                />
                <FormikErrorText name='igUsername' />
              </Form.Group>
            </BModal.Body>
            <BModal.Footer>
              <Button variant='primary' type='submit' disabled={disabled}>
                {loading ? <Spinner size='sm' /> : text}
              </Button>
              <Button
                variant='secondary'
                type='button'
                disabled={disabled}
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            </BModal.Footer>
          </Form>
        )}
      </Formik>
    </BModal>
  );
};

export default InstagramConnectPopup;
