import React, { FC } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import StartLesson from './StartLesson';

const StartLessonPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Comenzar lección</PageTitle>
      <StartLesson />
    </>
  );
};

export default StartLessonPageWrapper;
