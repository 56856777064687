import React from 'react';
import { Card } from 'react-bootstrap';
import EditResponseAndSaveButton from '../enrollmenthooks/EditResponseAndSaveButton';

const ReEnrollmentFormHeader = (props: any) => {
  const { step, setStep } = props;
  return (
    <>
      <Card className='text-center mt-5 p-3'>
        <Card.Body>
          <h1>2023 Re-enrollment </h1>
          {step == 0 && (
            <>
              <div className='fs-5'>Thank you for your interest. </div>
              <div className='fs-5'>
                Someone will be contacting you shortly.{' '}
              </div>

              <EditResponseAndSaveButton setStep={setStep} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ReEnrollmentFormHeader;
