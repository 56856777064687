import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import { get, post } from "../../helpers/api-helper";
import { aiActivityImageFailure, aiActivityImageGetSuccess, aiActivityImageSaveSuccess } from "./actions";
import { AI_ACTIVITY_IMAGE_DETAILS_GET, AI_ACTIVITY_IMAGE_DETAILS_SAVE } from "./actionTypes";


const aiActivityImageGetRequest = (data: any) => post("aiactivityimage/getall", data)
const aiActivityImageSaveRequest = (data: any) => post("aiactivityimage/save", data)

function* aiActivityImageGetListSaga(action: any) {
    try {
        const response: { ResultCode: string; ResultMessage: string; ResultObject: any; } = yield call(aiActivityImageGetRequest, {
            page: action.payload.page,
            limit: action.payload.limit,
            search: action.payload.search,
            sort: action.payload.sort
        });
        if (response.ResultCode !== 'SUCCESS') {
            yield put(aiActivityImageFailure({ error: response.ResultCode }));
        } else {
            yield put(aiActivityImageGetSuccess(response));
        }
    } catch (error: any) {
        yield put(aiActivityImageFailure({ error: error }));
    }
}


function* aiActivityImageSaveSaga(action: any) {
    try {
        const response: { ResultCode: string; ResultMessage: string; ResultObject: any; } = yield call(aiActivityImageSaveRequest, {
            User_ID: action.payload.User_ID,
            Lesson_ID: action.payload.Lesson_ID,
            // FullName: action.payload.FullName,
            Sentance: action.payload.Sentance,
            AI_Activity_Image: action.payload.AI_Activity_Image,
        });
        if (response.ResultCode !== 'SUCCESS') {
            yield put(aiActivityImageFailure(response));
        } else {
            yield put(aiActivityImageSaveSuccess(response));
        }
    } catch (error: any) {
        yield put(aiActivityImageFailure({ error: error }));
    }
}

function* authSaga() {
    yield all([takeLatest(AI_ACTIVITY_IMAGE_DETAILS_GET, aiActivityImageGetListSaga)]);
    yield all([takeLatest(AI_ACTIVITY_IMAGE_DETAILS_SAVE, aiActivityImageSaveSaga)]);
}

export default authSaga;
