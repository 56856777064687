import { Form as BForm } from 'react-bootstrap';
import { useFormikContext, ErrorMessage } from 'formik';
import ImageUpload from 'app/components/ImageUpload';
import useIntlCustom from 'hooks/useIntlCustom';

const Step1 = () => {
  const { formatMessage } = useIntlCustom();
  const {
    errors,
    touched,
    values,
    submitForm,
    getFieldProps,
    handleChange,
    handleBlur,
  }: any = useFormikContext();
  return (
    <>
      <BForm.Group className='mb-7 '>
        <BForm.Label className='fw-bold fs-2'>
          {/* School Name */}
          {formatMessage({
            id: 'TEACHER.SCHOOL_NAME',
          })}
          <span style={{ color: 'red' }}>*</span>
        </BForm.Label>
        <BForm.Control
          type='text'
          placeholder={formatMessage({
            id: 'TEACHER.SCHOOL_NAME.PLACEHOLDER',
          })}
          {...getFieldProps('schoolName')}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`rounded-1 form-control-sm  mb-3 mb-lg-0 ${
            errors.schoolName && touched.schoolName
              ? 'border border-danger'
              : ''
          }`}
        />
        {touched.schoolName && errors.schoolName && (
          <BForm.Text className='text-danger'>
            <ErrorMessage name='schoolName' />
          </BForm.Text>
        )}
      </BForm.Group>
      <BForm.Group className='mb-7'>
        <BForm.Label className='fw-bold fs-2'>
          {/* Upload School Logo */}
          {formatMessage({
            id: 'TEACHER.UPLOAD_SCHOOL_LOGO',
          })}
        </BForm.Label>
        <div className='w-125px pt-2'>
          {' '}
          <ImageUpload entityLabel='schoolLogo' name='schoolLogo' />{' '}
        </div>
      </BForm.Group>
    </>
  );
};

export default Step1;
