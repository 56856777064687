/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';
import AddNewFolder from './Teacher-AddNewFolder';
import Swal from 'sweetalert2';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { useNavigate } from 'react-router-dom';
import { useDeleteQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import { jwtTokenDecode } from 'hooks/tokenHook';
import Loader from 'app/components/Loader';

import TeacherFolderBreadcrumbs from '../Teacher-FolderStructure/Teacher-FolderBreadcrumbs';
import { KTSVG } from 'theme/helpers';
import UpsertCourseAsTeacherModal from '../UpsertCourseAsTeacherModal';
import useIntlCustom from 'hooks/useIntlCustom';

const TeacherCourseList: React.FC<any> = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    drag,
    setDrag,
    dragIndex,
    setDragIndex,
    courseList,
    setCourseList,
    courseDataID,
    setCourseDataID,
    breadcrumbs,
    onFolderClick,
    getBreadcrumb,
    setBreadcrumbs,
    selectedFolderId,
    getRecord,
    setDragType,
    dragType,
    UserID,
    setFolderDataList,
    setTreeData,
    getAllFolderAndCourseQuery,
    handleTrashData,
    modalFolderShow,
    setModalFolderShow,
  } = props;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const [modalShow, setModalShow] = React.useState(false);
  const [modalAddCourseShow, setModalAddCourseShow] = React.useState(false);

  const deleteCourseQuery: any = useDeleteQuery('deleteCourse', '/course');
  const updateByOrderCourse: any = usePostQuery(
    'updateByOrderCourse',
    '/course/updateByOrder',
    {}
  );
  const deleteFolder: any = usePostQuery(
    'deleteFolder',
    '/folder/deleteNewFolder',
    {}
  );

  useEffect(() => {
    if (deleteCourseQuery.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: 'Your course has been deactivated successfully.',
      });
    }
  }, [deleteCourseQuery.isSuccess]);

  useEffect(() => {
    if (deleteFolder.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: 'Your folder has been trash successfully.',
      });
      setFolderDataList(deleteFolder?.data?.ResultObject?.foldersData);
      setTreeData(deleteFolder?.data?.ResultObject?.treeFoldersData);
    }
  }, [deleteFolder.isSuccess]);

  const handleConfirmation = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Course will be deactivated!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedItems = [...courseList];
        updatedItems.splice(dragIndex, 1);
        if (deleteCourseQuery.isSuccess) {
          Swal.fire(
            'Deactivate Course!',
            'Your Course has been deactivated.',
            'success'
          );
        }
        setCourseList(updatedItems);
        deleteCourseQuery.mutateAsync({
          params: { id: courseList[dragIndex]?._id },
        });
      }
    });
  };

  const handleSortStart = (props: any) => {
    const { node, index, collection } = props;
    document.body.style.cursor = 'move';
    const courseId = node.getAttribute('data-id');
    setCourseDataID(courseId);
    setDrag(true);
    setDragIndex(index);
    setDragType('Course');
  };

  const handleSortEnd = async (props: any) => {
    const { oldIndex, newIndex, node } = props;
    document.body.style.cursor = 'auto';
    setDragType(null);
    setCourseDataID(null);
    let newArray: any = arrayMove(courseList, oldIndex, newIndex);
    setCourseList(newArray);
    const courseIds = newArray.map((ele: any) => ele._id);

    updateByOrderCourse.mutate({ courseIds });
    setDrag(false);
  };

  const onHandleTrashDrop = async (event: any) => {
    event.preventDefault();
    const folderId = event.dataTransfer.getData('nodeId');
    handleFolderConfirmation(folderId);
  };

  const onTrashDrag = (event: any) => {
    event.preventDefault();
  };

  const handleFolderConfirmation = (folderId: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Folder will be delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteFolder.mutate({
          folderID: folderId,
          UserID: UserID,
          parentId: selectedFolderId ? selectedFolderId : null,
        });
      }
    });
  };

  const onReloadCourse = () => {
    setModalAddCourseShow(false);
    getRecord(selectedFolderId);
  };
  return (
    <>
      {(deleteCourseQuery?.isLoading ||
        updateByOrderCourse?.isLoading ||
        deleteFolder?.isLoading) && (
        <Loader
          loading={
            deleteCourseQuery?.isLoading ||
            updateByOrderCourse?.isLoading ||
            deleteFolder?.isLoading
          }
        />
      )}
      {modalAddCourseShow && (
        <UpsertCourseAsTeacherModal
          show={modalAddCourseShow}
          onHide={() => setModalAddCourseShow(false)}
          onReload={() => onReloadCourse()}
          selectedFolderId={selectedFolderId}
        />
      )}
      <AddNewFolder show={modalShow} onHide={() => setModalShow(false)} />
      <Row className='g-5 pb-5'>
        <Col>
          <div className='card card-flush p-0 shadow-sm'>
            <div className='card-body py-5 d-flex gap-5'>
              <button
                className='btn  btn-light fw-bold w-100'
                onClick={() => setModalAddCourseShow(true)}
              >
                <AiOutlinePlus />
                {/* New Course */}{' '}
                {formatMessage({
                  id: 'TEACHER.NEW_COURSE',
                })}
              </button>
              <button
                className='btn  btn-light fw-bold w-100'
                onMouseUp={() => {
                  if (drag) {
                    handleConfirmation();
                  }
                }}
                onDrop={(event) => onHandleTrashDrop(event)}
                onDragOver={(event) => onTrashDrag(event)}
                onClick={handleTrashData}
              >
                <BsTrash />
                {/* Trash */}{' '}
                {formatMessage({
                  id: 'TRASH',
                })}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <TeacherFolderBreadcrumbs
        breadcrumbs={breadcrumbs}
        onFolderClick={onFolderClick}
        getBreadcrumb={getBreadcrumb}
        setBreadcrumbs={setBreadcrumbs}
        selectedFolderId={selectedFolderId}
        getRecord={getRecord}
      />
      <SortableList
        axis='xy'
        onSortStart={handleSortStart}
        onSortEnd={handleSortEnd}
        helperClass='sortable-helper'
      >
        {courseList?.map((item: any, index: any) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            dataItem={item}
            drag={drag}
            setDrag={setDrag}
            setCourseDataID={setCourseDataID}
            courseDataID={courseDataID}
            setDragType={setDragType}
            dragType={dragType}
          />
        ))}

        <Col lg={4} className='sortable-item'>
          <div className='card card-flush shadow-sm h-100'>
            <div className='card-body '>
              <button
                className=' d-flex flex-column justify-content-center align-items-center py-5 btn btn-light fw-bold w-100 h-100'
                onClick={() => setModalAddCourseShow(true)}
              >
                <AiOutlinePlus />
                {/* New Course */}{' '}
                {formatMessage({
                  id: 'TEACHER.NEW_COURSE',
                })}
              </button>
            </div>
          </div>
        </Col>
      </SortableList>
    </>
  );
};
export default TeacherCourseList;

const SortableList: any = SortableContainer(({ children, setDrag }: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <Row className='g-5 sortable-list'>
      <h3>
        {formatMessage({
          id: 'COURSE',
        })}
      </h3>
      {children}
    </Row>
  );
});

const SortableItem: any = SortableElement((props: any) => {
  const { dataItem, setCourseDataID, setDragType } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const navigate = useNavigate();

  const onClickAssignment = (courseId: any) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate(`/course/${courseId}/student-course-details`);
    }, 1000);
  };

  const onDragStarts = (id: any, type: any) => {
    setCourseDataID(id);
    setDragType(type);
  };

  const onDragends = () => {
    setCourseDataID(null);
    setDragType(null);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const onCopy = (id: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(`${location.origin}/course/${id}/invite`)
        .then(() => {
          Toast.fire({
            icon: 'success',
            title: 'copied',
          });
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    } else {
      alert('Your browser does not support copying to clipboard.');
    }
  };

  return (
    <>
      <Col
        lg={12}
        md={6}
        xl={4}
        data-items={dataItem}
        data-id={dataItem?._id}
        className='sortable-item'
      >
        <div className='card card-flush shadow-sm h-100'>
          <div className='card-body d-flex flex-column py-5'>
            {dataItem?.name && (
              <h3 className='card-title m-0'>{dataItem?.name}</h3>
            )}
            <div className='pt-4'>
              <div>
                <div className='fs-6 text-gray-800 fw-bold'>School Name</div>
                <div className='fw-semibold text-gray-400'>
                  {' '}
                  {dataItem?.schoolName}
                </div>
              </div>
              <div>
                <div className='fs-6 text-gray-800 fw-bold'>Language Level</div>
                <div className='fw-semibold text-gray-400'>
                  {' '}
                  {dataItem?.languageLevel?.Level_Name}
                </div>
              </div>
              <div className=''>
                <div className='fs-6 text-gray-800 fw-bold'>
                  Target Language
                </div>
                <div className='fw-semibold text-gray-400'>
                  {' '}
                  {dataItem?.targetLanguage?.Language_Name}
                </div>
              </div>
              <div className=''>
                <div className='fs-6 text-gray-800 fw-bold'>Points Needed</div>
                <div className='fw-semibold text-gray-400'>
                  {' '}
                  {dataItem?.pointsNeededToCompleteCourse}
                </div>
              </div>
            </div>
            <div className=''>
              <div className='w-100 d-flex flex-column gap-2'>
                <button
                  disabled={isLoading}
                  className={`btn btn-lg btn-secondary w-100`}
                  onClick={() => onCopy(dataItem?._id)}
                >
                  Copy Invite Link
                </button>

                <button
                  disabled={isLoading}
                  className={`btn btn-lg btn-success  w-100`}
                  onClick={(e) => {
                    navigate(`/teacher/${dataItem?._id}/view`);
                  }}
                  draggable='true'
                  onDragStart={() => {
                    onDragStarts(dataItem?._id, 'Course');
                  }}
                  onDragEnd={onDragends}
                >
                  <span className='pe-none'>
                    Go
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-4 me-1'
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
});
