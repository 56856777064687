import React, { FC } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import ContactUs from '../contactUs/ContactUs';

const ContactUsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Contactate con nosotros</PageTitle>
      <ContactUs />
    </>
  );
};

export default ContactUsWrapper;
