import usePercentageCounter from 'hooks/Calculate';
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import { GRADE_COLOR } from 'shared/constants';
import useIntlCustom from 'hooks/useIntlCustom';

const AchieveGrade = ({ joinDate, course, gradesPercentagePoints }: any) => {
  const { formatMessage } = useIntlCustom();

  const [diplomaDueDate, setDiplomaDueDate] = useState<any>();

  const calculateDiplomaDueDate = () => {
    if (moment().isSameOrBefore(moment(joinDate).set('month', 12))) {
      if (
        moment(joinDate).isSame(moment(), 'months') &&
        moment().month() === 11
      ) {
        setDiplomaDueDate(moment().endOf('month'));
      } else if (moment(joinDate).isSame(moment(), 'months')) {
        setDiplomaDueDate(moment().endOf('month').add(1, 'M'));
      } else {
        setDiplomaDueDate(moment().endOf('month'));
      }
    }
  };

  useEffect(() => {
    const progressBar: any = document.getElementById('progressBar');

    if (course?.isDiplomaEnglishCourse) {
      calculateDiplomaDueDate();

      progressBar.style.height = `${
        document.getElementById('progress-bar-title-parent')?.clientHeight
      }px`;
    } else {
      progressBar.style.height = `20px`;
    }
  }, []);

  return (
    <div className='d-flex justify-content-around position-absolute gap-2 z-index-1 w-100 p-2'>
      {gradesPercentagePoints?.grade?.name &&
        gradesPercentagePoints?.averagePercentage > 0 && (
          <div
            className={`badge badge-${
              GRADE_COLOR[
                gradesPercentagePoints?.grade?.name
                  ? gradesPercentagePoints?.grade?.name
                  : 'F'
              ]
            } p-3 fs-5 fw-bold rounded-pill`}
          >
            <div className='d-flex flex-column gap-2 '>
              <span className='fs-1 fw-bolder'>
                {gradesPercentagePoints?.grade?.name
                  ? gradesPercentagePoints?.grade?.name
                  : 'F'}
              </span>
              <span className='fs-5'>
                {gradesPercentagePoints?.averagePercentage
                  ? gradesPercentagePoints?.averagePercentage
                  : '0'}
                %
              </span>
            </div>
          </div>
        )}

      <div className='w-100 d-flex align-items-center justify-content-center'>
        <div className='w-100 position-relative'>
          <ProgressBar
            variant='primary'
            now={usePercentageCounter(
              Number(gradesPercentagePoints?.totalPointSum),
              Number(
                gradesPercentagePoints?.course?.pointsNeededToCompleteCourse
              )
            )}
            visuallyHidden
            id='progressBar'
          />
          <div
            className='position-absolute top-50 start-50 translate-middle w-100 fs-8 px-2 py-1 d-flex justify-content-center align-items-center text-center'
            id='progress-bar-title-parent'
          >
            <div className='fw-bold' id='progress-bar-title'>
              {gradesPercentagePoints?.totalPointSum} of{' '}
              {gradesPercentagePoints?.course?.pointsNeededToCompleteCourse}{' '}
              {course?.isDiplomaEnglishCourse && diplomaDueDate && (
                <>
                  (
                  {formatMessage({
                    id: 'COURSE.EXPIRATION_IN_X_DAYS',
                    values: {
                      param1: moment(diplomaDueDate).diff(new Date(), 'days'),
                    },
                  })}
                  )
                </>
              )}
            </div>{' '}
          </div>
        </div>
      </div>
      {course?.badge && (
        <div className='d-flex align-items-center justify-content-center'>
          <img
            src={course?.badge}
            alt='Edge'
            className='rounded h-50px w-50px'
          />
        </div>
      )}
    </div>
  );
};

export default AchieveGrade;
