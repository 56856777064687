import {
  API_ERROR,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  USER_CHECK_TOKEN_VALID_OR_NOT,
  USER_CHECK_TOKEN_VALID_OR_NOT_SUCCESS,
  USER_CHECK_TOKEN_VALID_OR_NOT_ERROR,
} from './actionTypes';
import { AuthActions, AuthState } from './types';

const initialState: any = {
  pending: false,
  token: '',
  error: null,
  success: '',
  loading: false,
  validtokenloading: null,
  isTokenValid: '',
  tokenData: '',
  isNotValid: '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        token: action.payload.token,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        error: null,
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        token: '',
      };
    case USER_CHECK_TOKEN_VALID_OR_NOT:
      return {
        ...state,
        validtokenloading: true,
        isTokenValid: null,
      };
    case USER_CHECK_TOKEN_VALID_OR_NOT_SUCCESS:
      return {
        ...state,
        validtokenloading: false,
        tokenData: action.payload.tokenData,
      };
    case USER_CHECK_TOKEN_VALID_OR_NOT_ERROR:
      return {
        ...state,
        isTokenValid: false,
        //isNotValid: action.payload.isNotValid
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
