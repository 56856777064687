import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useGetQuery, usePostQuery, usePutQuery } from 'hooks/reactQueryHelper';
import { Step, Stepper } from 'react-form-stepper';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

import * as Yup from 'yup';
import {
  Formik,
  FormikConfig,
  Field,
  Form,
  FieldArray,
  ErrorMessage,
} from 'formik';
import { Button, Form as BForm } from 'react-bootstrap';
import LoaderNew from 'app/components/LoaderNew';
import useIntlCustom from 'hooks/useIntlCustom';

export const GET_ALL_LANGUAGE = `/language/getAllLanguages`;
export const GET_ALL_LEVEL = `/level/getAll`;

const ageGroupOptions: any = [
  { value: '4-12', label: '4-12', minAge: 4, maxAge: 12 },
  { value: '13-17', label: '13-17', minAge: 13, maxAge: 17 },
  { value: '18+', label: '18+', minAge: 18, maxAge: 200 },
];

const UpsertCourseAsTeacherModal = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const { onReload, data, selectedFolderId } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: '',
    },
  ]);

  // const getCourseQuery: any = useGetQuery('getCourse', '/course/getById');
  const addCourseQuery: any = usePostQuery('addCourse', '/course', {});
  const updateCourseQuery: any = usePutQuery('updateCourse', '/course');
  const getAllLanguageDataQuery: any = useGetQuery(
    'getAllLanguageDataQuery',
    `/language/getAllLanguages`,
    {}
  );
  const getAllLevelDataQuery: any = useGetQuery(
    'getAllLevelDataQuery',
    `/level/getAll`,
    {}
  );

  useEffect(() => {
    getAllLanguageDataQuery.mutate({});
    getAllLevelDataQuery.mutate({});
  }, []);

  const validationSchema = [
    Yup.object({
      id: Yup.string(),
      schoolName: Yup.string()
        .trim()
        .required(
          formatMessage({
            id: 'TEACHER.SCHOOL_NAME.ERROR.MSG',
          })
        ),
      schoolLogo: Yup.mixed()
        .nullable()
        .test('fileFormat', 'Please select valid image ', (value) => {
          if (value && value.type) {
            return (
              value &&
              ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
                value.type
              )
            );
          } else {
            return true;
          }
        }),
    }),
    Yup.object({
      courseName: Yup.string()
        .trim()
        .required(
          formatMessage({
            id: 'TEACHER.COURSE_NAME.ERROR.MSG',
          })
        ),
    }),
    Yup.object({
      targetLanguage: Yup.object()
        .nullable()
        .required(
          formatMessage({
            id: 'PLEASE_SELECT_LANGUAGE.ERROR.MSG',
          })
        ),
      languageLevel: Yup.object()
        .nullable()
        .required(
          formatMessage({
            id: 'PLEASE_SELECT_LEVEL.ERROR.MSG',
          })
        ),
    }),
    Yup.object({
      pointsNeededToCompleteCourse: Yup.number()
        .nullable()
        .min(0, 'Min value 0.')
        .required(
          formatMessage({
            id: 'TEACHER.POINTS_NEEDED_TO_COMPLETE_COURSE.ERROR.MSG',
          })
        ),
    }),
  ];

  let initialValues = {
    id: data?.ResultObject?._id,
    schoolName: data?.ResultObject?.schoolName,
    schoolLogo: data?.ResultObject?.schoolLogo
      ? data?.ResultObject?.schoolLogo
      : '',
    courseName: data?.ResultObject?.name,
    targetLanguage: data?.ResultObject?.targetLanguage
      ? {
          ...data?.ResultObject?.targetLanguage,
          value: data?.ResultObject?.targetLanguage?._id,
          label: data?.ResultObject?.targetLanguage?.Language_Name,
        }
      : '',
    languageLevel: data?.ResultObject?.languageLevel
      ? {
          ...data?.ResultObject?.languageLevel,
          label: data?.ResultObject?.languageLevel?.Level_Name,
          value: data?.ResultObject?.languageLevel?._id,
        }
      : '',
    folderID: selectedFolderId ? selectedFolderId : '',
    pointsNeededToCompleteCourse:
      data?.ResultObject?.pointsNeededToCompleteCourse,
    gradingRubric: {
      A: {
        name: 'A',
        min: 90,
        max: 100,
      },
      B: {
        name: 'B',
        min: 80,
        max: 89,
      },
      C: {
        name: 'C',
        min: 70,
        max: 79,
      },
      D: {
        name: 'D',
        min: 60,
        max: 69,
      },
      F: {
        name: 'F',
        min: 1,
        max: 59,
      },
    },
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const onSubmit = (values: any, { setSubmitting }: any) => {
    if (activeStep <= 2) {
      setSubmitting(false);
      handleNext();
    } else {
      let formData: any = new FormData();
      formData.append('_id', values?.id);
      formData.append('schoolName', values?.schoolName);
      formData.append('schoolLogo', values.schoolLogo);
      formData.append('name', values?.courseName);
      formData.append('targetLanguage', values?.targetLanguage.value);
      formData.append('languageLevel', values?.languageLevel.value);
      formData.append(
        'pointsNeededToCompleteCourse',
        values?.pointsNeededToCompleteCourse
      );
      formData.append('folderID', values.folderID);
      formData.append('grade', 'N/A');
      formData.append(
        'ageOfGroup',
        JSON.stringify(
          ageGroupOptions.map(({ minAge, maxAge }: any) => ({ minAge, maxAge }))
        )
      );
      let gradingRubric: any = Object.values(values?.gradingRubric);
      formData.append('gradingRubric', JSON.stringify(gradingRubric));

      if (values.id) {
        updateCourseQuery.mutateAsync(formData);
      } else {
        addCourseQuery.mutateAsync(formData);
      }
    }
  };
  useEffect(() => {
    if (addCourseQuery?.isSuccess || updateCourseQuery?.isSuccess) {
      onReload();
    }
  }, [addCourseQuery?.isSuccess, updateCourseQuery?.isSuccess]);

  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <button
        type='button'
        className='btn-close position-absolute top-0 end-0 m-5 z-index-3'
        aria-label='Close'
        onClick={() => props.onHide()}
      ></button>
      <Modal.Body>
        <LoaderNew
          isFull={false}
          loading={
            getAllLanguageDataQuery.isLoading ||
            getAllLevelDataQuery.isLoading ||
            addCourseQuery.isLoading ||
            updateCourseQuery.isLoading
          }
        />
        <div className='m-5'>
          <Stepper
            activeStep={activeStep}
            steps={steps}
            styleConfig={{
              activeBgColor: '#0055ffb3',
              activeTextColor: '#ffffff',
              completedBgColor: '#50cd89',
              completedTextColor: '#ffffff',
              inactiveBgColor: '#e0e0e0',
              inactiveTextColor: '#ffffff',
              size: '3rem',
              circleFontSize: '1.5rem',
              labelFontSize: '0.875rem',
              borderRadius: '50%',
              fontWeight: '500',
            }}
            connectorStateColors={false}
            hideConnectors={false}
          />
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema[activeStep]}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, handleSubmit }: any) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <div style={{ minHeight: '350px' }}>
                  {(() => {
                    switch (activeStep) {
                      case 0:
                        return <Step1 />;
                      case 1:
                        return <Step2 />;
                      case 2:
                        return (
                          <Step3
                            getAllLanguageDataQuery={getAllLanguageDataQuery}
                            getAllLevelDataQuery={getAllLevelDataQuery}
                          />
                        );
                      case 3:
                        return <Step4 />;
                      default:
                        return null;
                    }
                  })()}
                </div>
                <footer
                  className={`d-flex align-items-center ${
                    activeStep > 0
                      ? 'justify-content-between'
                      : 'justify-content-end'
                  }`}
                >
                  {activeStep > 0 && (
                    <Button onClick={() => handleBack()}>
                      {formatMessage({
                        id: 'PREVIOUS',
                      })}
                    </Button>
                  )}

                  <Button
                    type='submit'
                    // disabled={isSubmitting}
                  >
                    {activeStep === steps.length - 1
                      ? `${
                          values?.id
                            ? formatMessage({
                                id: 'TEACHER.UPDATE_COURSE',
                              })
                            : formatMessage({
                                id: 'TEACHER.CREATE_COURSE',
                              })
                        }`
                      : formatMessage({
                          id: 'NEXT',
                        })}
                  </Button>
                </footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpsertCourseAsTeacherModal;
