import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { HeaderUserMenu } from '../../../partials';
import { useAuth } from '../../../../app/modules/auth';

const SidebarLogo = () => {
  const { auth } = useAuth();
  const { config } = useLayout();
  config.app?.sidebar?.default?.minimize?.desktop?.default;
  const profilePic = auth?.data?.profilePic;

  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');

  return (
    <>
      <div
        className='d-flex align-item-center px-4 px-lg-6 py-3 py-lg-8 bg-light bg-gradient bg-md-white w-100'
        id='kt_app_sidebar_logo'
      >
        <div className='d-none d-lg-block'>
          <Link to='/course'>
            {config.layoutType === 'dark-sidebar' ? (
              <img
                alt='Hope City School Logo'
                src={toAbsoluteUrl(
                  '/media/logos/hope_city_school_logo_default.png'
                )}
                className='h-25px h-lg-50px theme-light-show'
              />
            ) : (
              <>
                <img
                  alt='Hope City School Logo'
                  src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
                  className='h-25px h-lg-50px theme-light-show'
                />
                <img
                  alt='Hope City School Logo'
                  src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
                  className='h-25px h-lg-50px theme-dark-show'
                />
              </>
            )}
            <img
              alt='Hope City School Logo'
              src={toAbsoluteUrl('/media/logos/hope_city_school_logo.png')}
              className='h-25px h-lg-50px theme-dark-show'
            />
          </Link>
        </div>
        <div className='d-flex align-item-center text-center justify-content-center justify-content-lg-end mt-1 w-100'>
          <div
            className='cursor-pointer position-relative symbol symbol-circle symbol-100px symbol-md-50px w-auto'
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            {profilePic !== null && profilePic !== undefined ? (
              <img src={profilePic} alt='user' className='object-fit-cover' />
            ) : (
              <img src={defaultImageUrl} alt='' className='object-fit-cover' />
            )}

            {/* <div className='position-absolute rounded-circle bg-success start-100 top-100 h-8px w-8px ms-n3 mt-n3'></div> */}
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </>
  );
};

export { SidebarLogo };
