import React, { useEffect, useState } from 'react';
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import { ImHome } from 'react-icons/im';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import TeacherTreeViewFolder from '../Teacher-FolderStructure/Teacher-FolderView';
import TeacherAddNewFolder from './Teacher-AddNewFolder';
import useIntlCustom from 'hooks/useIntlCustom';

const TeacherCourseFolderList: React.FC<any> = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    drag,
    courseList,
    setCourseList,
    courseID,
    courseDataID,
    setFolderDataList,
    setFolderParentID,
    folderParentID,
    getBreadcrumb,
    setBreadcrumbs,
    setTreeData,
    treeData,
    setSelectedFolderId,
    onFolderClick,
    selectedFolderId,
    setDragType,
    dragType,
    dragFolderID,
    setDragFolderID,
    breadcrumbs,
    UserID,
    getAllFolderAndCourseQuery,
    handleHomeData,
    restoreQuery,
    modalFolderShow,
    setModalFolderShow,
  } = props;

  const [folderName, setFolderName] = useState<any>();
  const [folderDataID, setFolderDataID] = useState<any>(null);

  const getAllSelectedFolder: any = usePostQuery(
    'getAllSelectedFolder',
    '/folder/getAllNewFolder',
    {}
  );
  // const getAllSelectedFolderCourse: any = usePostQuery(
  //   'getAllSelectedFolderCourse',
  //   '/folder/getAllCourse',
  //   {}
  // );
  const updateFolderParentID: any = usePostQuery(
    'updateFolderParentID',
    '/folder/updateFolderParentID',
    {}
  );
  const saveFolderCourse: any = usePostQuery(
    'saveFolderCourse',
    '/folder/saveFolderCourse',
    {}
  );

  useEffect(() => {
    if (UserID) {
      getAllData();
      setBreadcrumbs([{ ID: null, FolderName: 'Home' }]);
    }
  }, [UserID]);

  useEffect(() => {
    if (restoreQuery.isSuccess) {
      setTreeData(getAllSelectedFolder?.data?.treeFoldersData);
      setFolderDataList(getAllSelectedFolder?.data?.foldersData);
    }
  }, [restoreQuery.isSuccess]);

  useEffect(() => {
    if (getAllSelectedFolder.isSuccess) {
      setTreeData(getAllSelectedFolder?.data?.treeFoldersData);
      setFolderDataList(getAllSelectedFolder?.data?.foldersData);
    }
  }, [getAllSelectedFolder.isSuccess]);

  useEffect(() => {
    if (getAllFolderAndCourseQuery.isSuccess) {
      setCourseList(getAllFolderAndCourseQuery?.data?.ResultObject?.CourseData);
      setFolderDataList(
        getAllFolderAndCourseQuery?.data?.ResultObject?.FolderData
      );
    }
  }, [getAllFolderAndCourseQuery.isSuccess]);

  useEffect(() => {
    if (saveFolderCourse.isSuccess) {
      getRecord(folderDataID);
    }
  }, [saveFolderCourse.isSuccess]);

  useEffect(() => {
    if (updateFolderParentID.isSuccess) {
      getRecord(selectedFolderId ? selectedFolderId : null);
      setTreeData(updateFolderParentID?.data?.ResultObject?.folders);
    }
  }, [updateFolderParentID.isSuccess]);

  const getAllData = () => {
    getAllSelectedFolder.mutate({
      userId: UserID,
      parentId: selectedFolderId ? selectedFolderId : null,
    });
  };

  const getRecord = async (folderID: any) => {
    onFolderClick(null);
    setBreadcrumbs([{ ID: null, FolderName: 'Home' }]);
    setFolderDataID(null);
    getAllFolderAndCourseQuery.mutate({ folderID: folderID, UserID: UserID });
    handleHomeData();
  };

  const handleDragOver = async (event: any) => {
    event.preventDefault();

    var x = document.getElementsByClassName('folder-home');
    [].forEach.call(x, function (el: any) {
      el.classList.add('folder-home-drag');
    });
  };

  const handleDragEnd = (event: any) => {
    var x = document.getElementsByClassName('folder-home');
    [].forEach.call(x, function (el: any) {
      el.classList.remove('folder-home-drag');
    });
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();

    var x = document.getElementsByClassName('folder-home');
    [].forEach.call(x, function (el: any) {
      el.classList.remove('folder-home-drag');
    });

    if (dragType == 'Folder') {
      saveFolder(dragFolderID, null);
    }

    if (dragType == 'Course') {
      const saveValue = {
        CourseID: courseDataID,
        folderID: null,
      };

      saveFolderCourse.mutate(saveValue);
    }
  };

  const saveFolder = async (folderID: any, parentID: any) => {
    setFolderParentID(folderID);
    const folderSaveData = {
      parentID: parentID,
      folderID: folderID,
      userID: UserID,
    };

    updateFolderParentID.mutate(folderSaveData);
  };

  return (
    <>
      {(getAllSelectedFolder?.isLoading ||
        getAllFolderAndCourseQuery?.isLoading ||
        updateFolderParentID.isLoading ||
        saveFolderCourse.isLoading) && (
        <Loader
          loading={
            getAllSelectedFolder?.isLoading ||
            getAllFolderAndCourseQuery?.isLoading ||
            updateFolderParentID.isLoading ||
            saveFolderCourse.isLoading
          }
        />
      )}
      <TeacherAddNewFolder
        show={modalFolderShow}
        onHide={() => setModalFolderShow(false)}
        setFolderName={setFolderName}
        folderName={folderName}
        getAllData={getAllData}
        selectedFolderId={selectedFolderId}
        UserID={UserID}
      />
      <div
        className='card card-custom card-stretch shadow mb-5 p-5'
        style={{
          minHeight: 'calc(100vh - 180px)',
        }}
      >
        <div className='card-body p-0'>
          <button
            className='btn  btn-light fw-bold w-100'
            onClick={() => setModalFolderShow(true)}
          >
            <MdOutlineCreateNewFolder />
            {/* New Folder */}{' '}
            {formatMessage({
              id: 'TEACHER.NEW_FOLDER',
            })}
          </button>
          <button
            className='btn  btn-light fw-bold w-100 mt-2 p-5 folder-home folder-home-hover'
            onClick={() => getRecord(null)}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e)}
            onDragLeaveCapture={(e) => handleDragEnd(e)}
            onMouseUp={(e) => {
              if (drag) {
                handleDrop(e);
              }
            }}
          >
            <ImHome /> Home
          </button>
          <div className='mt-5'>
            <TeacherTreeViewFolder
              courseID={courseID}
              setCourseList={setCourseList}
              courseList={courseList}
              treeData={treeData}
              courseDataID={courseDataID}
              setFolderDataID={setFolderDataID}
              folderDataID={folderDataID}
              setFolderDataList={setFolderDataList}
              folderParentID={folderParentID}
              getBreadcrumb={getBreadcrumb}
              setBreadcrumbs={setBreadcrumbs}
              setSelectedFolderId={setSelectedFolderId}
              onFolderClick={onFolderClick}
              selectedFolderId={selectedFolderId}
              setDragType={setDragType}
              dragType={dragType}
              setTreeData={setTreeData}
              setFolderParentID={setFolderParentID}
              dragFolderID={dragFolderID}
              setDragFolderID={setDragFolderID}
              breadcrumbs={breadcrumbs}
              drag={drag}
              UserID={UserID}
              getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default TeacherCourseFolderList;
