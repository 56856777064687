import React, { useEffect, useRef, useState, useMemo } from 'react';
import Section7_12_1 from '../sections7_12/Section7_12_1';
import Section7_12_2 from '../sections7_12/Section7_12_2';
import Section7_12_3 from '../sections7_12/Section7_12_3';
import Section7_12_4 from '../sections7_12/Section7_12_4';
import Section7_12_5 from '../sections7_12/Section7_12_5';
import Section7_12_6 from '../sections7_12/Section7_12_6';
import Section7_12_7 from '../sections7_12/Section7_12_7';
import Section7_12_8 from '../sections7_12/Section7_12_8';
import Section7_12_9 from '../sections7_12/Section7_12_9';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import RegistrationFormFooter from '../../RegistrationFormFooter';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { removeEmptyStringProperties } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import Section7_12_3_1 from '../sections7_12/Section7_12_3_1';
import HighlightedIssuesText from 'app/pages/enrollmenthooks/HighlightedIssuesText';
import WarningTextModel from '../../WarningTextModel';
import { updateRequirementField } from 'app/pages/enrollmenthooks/UpdateRequirementField';

const Step1 = (props: any) => {
  const {
    setStep,
    setGrade,
    step,
    fromK12Data,
    getByIdFormQuery,
    formType,
    fieldReportedIssue,
    setFormType,
  } = props;

  const saveFormStep1Query: any = usePostQuery(
    'saveFormStep1',
    '/enrollmentregister/save',
    {}
  );

  const navigate = useNavigate();

  const [schoolGarde, setSchoolGarde] = useState<any>('');

  const initialValues: any = {
    Email: '',
    Student_Firstname: '',
    Student_Lastname: '',
    Student_ID_Number: '',
    Guardian_Firstname: '',
    Guardian_Lastname: '',
    Legal_Rights_Of_Student: '',
    Schooldays_In_The_School_Year: '',
    I_Agree: false,
    Student_Photo: [],
    English_Requirement_Subject: '',
    Math_Requirement_Subject: '',
    Foreign_Language_Requirement_Subject: '',
    Social_Science_Requirement_Subject: '',
    Science_Requirement_Subject: '',
    Visual_Arts_Requirement_Subject: '',
    Health_Requirement_Subject: '',
    Physical_Education_Requirement_Subject: '',
    School_Grade: '',
    // FormType: formType,
    ButtonAction: '',
    Status: 0,
  };

  useEffect(() => {
    if (fromK12Data) {
      formik.setFieldValue(
        'Email',
        fromK12Data?.Email ? fromK12Data?.Email : ''
      );
      formik.setFieldValue(
        'Student_Firstname',
        fromK12Data?.Student_Firstname ? fromK12Data?.Student_Firstname : ''
      );
      formik.setFieldValue(
        'Student_Lastname',
        fromK12Data?.Student_Lastname ? fromK12Data?.Student_Lastname : ''
      );
      formik.setFieldValue(
        'Student_ID_Number',
        fromK12Data?.StudentID ? fromK12Data?.StudentID : ''
      );
      formik.setFieldValue(
        'Guardian_Firstname',
        fromK12Data?.Guardian_Firstname ? fromK12Data?.Guardian_Firstname : ''
      );
      formik.setFieldValue(
        'Guardian_Lastname',
        fromK12Data?.Guardian_Lastname ? fromK12Data?.Guardian_Lastname : ''
      );
      formik.setFieldValue(
        'Legal_Rights_Of_Student',
        fromK12Data?.Legal_Rights_Of_Student?.value
          ? fromK12Data?.Legal_Rights_Of_Student?.value
          : ''
      );
      formik.setFieldValue(
        'Schooldays_In_The_School_Year',
        fromK12Data?.Schooldays_In_The_School_Year?.value
          ? fromK12Data?.Schooldays_In_The_School_Year?.value
          : ''
      );
      formik.setFieldValue(
        'I_Agree',
        fromK12Data?.I_Agree?.value ? fromK12Data?.I_Agree?.value : false
      );
      formik.setFieldValue(
        'Student_Photo',
        fromK12Data?.Student_Photo?.value
          ? fromK12Data?.Student_Photo?.value.split()
          : []
      );
      formik.setFieldValue(
        'English_Requirement_Subject',
        fromK12Data?.English_Requirement_Subject?.value
          ? fromK12Data?.English_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Math_Requirement_Subject',
        fromK12Data?.Math_Requirement_Subject?.value
          ? fromK12Data?.Math_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Foreign_Language_Requirement_Subject',
        fromK12Data?.Foreign_Language_Requirement_Subject?.value
          ? fromK12Data?.Foreign_Language_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Science_Requirement_Subject',
        fromK12Data?.Science_Requirement_Subject?.value
          ? fromK12Data?.Science_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Social_Science_Requirement_Subject',
        fromK12Data?.Social_Science_Requirement_Subject?.value
          ? fromK12Data?.Social_Science_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Visual_Arts_Requirement_Subject',
        fromK12Data?.Visual_Arts_Requirement_Subject?.value
          ? fromK12Data?.Visual_Arts_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Health_Requirement_Subject',
        fromK12Data?.Health_Requirement_Subject?.value
          ? fromK12Data?.Health_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'Physical_Education_Requirement_Subject',
        fromK12Data?.Physical_Education_Requirement_Subject?.value
          ? fromK12Data?.Physical_Education_Requirement_Subject?.value
          : ''
      );
      formik.setFieldValue(
        'School_Grade',
        fromK12Data?.School_Grade?.value ? fromK12Data?.School_Grade?.value : ''
      );
      formik.setFieldValue(
        'Status',
        fromK12Data?.Status ? fromK12Data?.Status : 0
      );
    }
  }, [fromK12Data]);

  const isDisplayFieldForm12: Boolean = useMemo(() => {
    if (schoolGarde) {
      const checkFormStatus = parseInt(schoolGarde.match(/\d+/)[0]);
      if (checkFormStatus >= 7 && checkFormStatus <= 12) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [schoolGarde]);

  const isDisplayFieldForm6: Boolean = useMemo(() => {
    if (schoolGarde) {
      const checkFormStatus = parseInt(schoolGarde.match(/\d+/)[0]);
      if (checkFormStatus >= 0 && checkFormStatus <= 6) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [schoolGarde]);

  const formvalidationSchema = Yup.object().shape({
    Guardian_Firstname: Yup.string().required(
      'Please enter guardian first name'
    ),
    Guardian_Lastname: Yup.string().required('Please enter guardian last name'),
    Legal_Rights_Of_Student: Yup.string().required('Please enter your answer'),
    Schooldays_In_The_School_Year: Yup.string().required(
      'Please select any option'
    ),
    Student_Photo: Yup.array()
      .required('Please upload a student photo')
      .min(1, 'Please upload a student photo'),
    English_Requirement_Subject: Yup.string().required(
      'Please select any option'
    ),
    Math_Requirement_Subject: Yup.string().required('Please select any option'),
    Foreign_Language_Requirement_Subject:
      isDisplayFieldForm12 && Yup.string().required('Please select any option'),
    Social_Science_Requirement_Subject: Yup.string().required(
      'Please select any option'
    ),
    Science_Requirement_Subject: Yup.string().required(
      'Please select any option'
    ),
    Visual_Arts_Requirement_Subject: Yup.string().required(
      'Please select any option'
    ),
    Health_Requirement_Subject:
      isDisplayFieldForm6 && Yup.string().required('Please select any option'),
    Physical_Education_Requirement_Subject: Yup.string().required(
      'Please select any option'
    ),
    School_Grade: Yup.string().required('Please select any option'),
    I_Agree: Yup.bool().oneOf([true], 'You must check this box.'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      await removeEmptyStringProperties(values);
      const checkFormStatus = parseInt(
        formik.values.School_Grade?.match(/\d+/)[0]
      );
      if (checkFormStatus >= 0 && checkFormStatus <= 6) {
        values.FormType = 2;
      } else {
        values.FormType = 1;
      }
      saveFormStep1Query.mutateAsync(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (saveFormStep1Query.isSuccess) {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        if (
          formik.values.School_Grade == '10th' ||
          formik.values.School_Grade == '11th' ||
          formik.values.School_Grade == '12th'
        ) {
          setStep(2);
        } else {
          setStep(3);
        }
      }
      if (formik?.values?.ButtonAction == 'Save') {
        navigate('/account');
      }
    }
  }, [saveFormStep1Query.isSuccess]);

  const onFormInformationSave = (actionName: any) => {
    formik.setFieldValue('ButtonAction', actionName);
  };

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');

  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  useEffect(() => {
    if (formik.values.School_Grade) {
      setSchoolGarde(formik.values.School_Grade);
      setGrade(formik.values.School_Grade);
    }
  }, [formik.values.School_Grade]);

  const [showWarningModel, setShowWarningModel] = useState(false);
  const [requirementFieldData, setRequirementFieldData] = useState('');
  const [rquirementFill, setRquirementFill] = useState<any>([]);

  useEffect(() => {
    if (formType == 1) {
      formik.setFieldValue('Health_Requirement_Subject', '');
      onHandleRquirementSubject('Health_Requirement_Subject', 'Yes', formType);
    }
    if (formType == 2) {
      formik.setFieldValue('Foreign_Language_Requirement_Subject', '');
      onHandleRquirementSubject(
        'Foreign_Language_Requirement_Subject',
        'Yes',
        formType
      );
    }
  }, [formType]);

  const hasTrueValue = useMemo(() => {
    return rquirementFill.length > 0
      ? rquirementFill?.some((item: any) => Object.values(item)[0] === true)
      : false;
  }, [rquirementFill]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  const onHandleRquirementSubject = (
    fieldName: string,
    formikValue: string,
    formType: number | null
  ) => {
    setRquirementFill((prevArray: any) =>
      updateRequirementField(
        fieldName,
        formikValue,
        formType,
        setShowWarningModel,
        setRequirementFieldData,
        formik,
        prevArray
      )
    );
  };

  return (
    <div>
      <Loader loading={saveFormStep1Query.isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <Section7_12_9
          formik={formik}
          formType={formType}
          hasFieldError={hasFieldError}
          removeHightlight={removeHightlight}
        />
        <Section7_12_1
          formik={formik}
          hasFieldError={hasFieldError}
          removeHightlight={removeHightlight}
        />
        <Card className='mt-5'>
          <Card.Body className='p-6' ref={tabRef}>
            <Section7_12_2
              formik={formik}
              formK12={isDisplayFieldForm12}
              formK6={isDisplayFieldForm6}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
              onHandleRquirementSubject={onHandleRquirementSubject}
            />
            <Section7_12_3
              formik={formik}
              formK12={isDisplayFieldForm12}
              formK6={isDisplayFieldForm6}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
              onHandleRquirementSubject={onHandleRquirementSubject}
            />
            {isDisplayFieldForm12 && (
              <Section7_12_3_1
                formik={formik}
                hasFieldError={hasFieldError}
                removeHightlight={removeHightlight}
                onHandleRquirementSubject={onHandleRquirementSubject}
                formType={formType}
              />
            )}
            <Section7_12_4
              formik={formik}
              formK12={isDisplayFieldForm12}
              formK6={isDisplayFieldForm6}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
              onHandleRquirementSubject={onHandleRquirementSubject}
            />
            <Section7_12_5
              formik={formik}
              formK12={isDisplayFieldForm12}
              formK6={isDisplayFieldForm6}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
              onHandleRquirementSubject={onHandleRquirementSubject}
            />
            <Section7_12_6
              formik={formik}
              formK12={isDisplayFieldForm12}
              formK6={isDisplayFieldForm6}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
              onHandleRquirementSubject={onHandleRquirementSubject}
            />
            {isDisplayFieldForm6 && (
              <Section7_12_7
                formik={formik}
                hasFieldError={hasFieldError}
                removeHightlight={removeHightlight}
                onHandleRquirementSubject={onHandleRquirementSubject}
                formType={formType}
              />
            )}
            <Section7_12_8
              formik={formik}
              formK12={isDisplayFieldForm12}
              formK6={isDisplayFieldForm6}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
              onHandleRquirementSubject={onHandleRquirementSubject}
            />

            {hasTrueValue ? (
              <div className='fv-plugins-message-container p-3'>
                <div className='fv-help-block'>
                  <h5 className='text-danger'>
                    Por favor revise el formulario y solucione este requisito.
                    asuntos
                  </h5>
                </div>
              </div>
            ) : (
              <HighlightedIssuesText formik={formik} />
            )}
          </Card.Body>
        </Card>

        <RegistrationFormFooter
          IsBackButton={false}
          setStep={setStep}
          step={step}
          onSaveForm={onFormInformationSave}
          hasTrueValue={hasTrueValue}
        />

        <WarningTextModel
          showWarningModel={showWarningModel}
          setShowWarningModel={setShowWarningModel}
          requirement={requirementFieldData}
          formType={formType}
        />
      </form>
    </div>
  );
};

export default Step1;
