import LoaderContent from 'app/components/ContentLoader';
import useIntlCustom from 'hooks/useIntlCustom';
import moment from 'moment';
const GPAdetails = ({ getMonthlyGpaByCourseInviteId }: any) => {
  const { formatMessage } = useIntlCustom();
  console.log(
    'getMonthlyGpaByCourseInviteId',
    getMonthlyGpaByCourseInviteId?.data?.ResultObject?.monthWisePercentages
  );
  return (
    <div className='card card-stretch'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0 text-gray-800'>
            {/* Gpa Details */}
            {formatMessage({
              id: 'COURSE.RATINGS.GPA_DETAILS',
            })}
          </h3>
        </div>
      </div>

      <div className='card-body'>
        <LoaderContent
          loading={getMonthlyGpaByCourseInviteId.isLoading}
          loaderType='tableSection'
        />
        {getMonthlyGpaByCourseInviteId.isSuccess && (
          <>
            <div className='table-responsive'>
              <table className='table table-row-dashed border-gray-300 align-middle gy-3'>
                <thead>
                  <tr className='fw-bold fs-5 text-gray-800'>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.MONTH_YEAR',
                      })}
                    </th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.GRADE',
                      })}
                    </th>

                    <th>GPA</th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.COMPLETED_POINTS',
                      })}
                    </th>
                    <th>
                      {formatMessage({
                        id: 'COURSE.RATINGS.ASSIGNMENT_DETAILS.GRADE',
                      })}
                    </th>
                  </tr>
                </thead>
                <tbody className='fs-6'>
                  {getMonthlyGpaByCourseInviteId?.data?.ResultObject?.map(
                    (item: any) => (
                      <>
                        <tr>
                          <td>
                            {moment()
                              .set('month', item?._id?.month - 1)
                              .set('year', item?._id?.year)
                              .format('MMM YYYY')
                              .toString()}
                          </td>
                          <td>{item?.details?.totalPercentageSum}%</td>
                          <td>{Math.round(item?.details?.GPA)}</td>
                          <td>{item?.details?.totalPointSum}</td>
                          <td>
                            {item?.details?.grade?.name
                              ? item?.details?.grade?.name
                              : '-'}
                          </td>
                        </tr>
                      </>
                    )
                  )}

                  {!getMonthlyGpaByCourseInviteId.isLoading &&
                    getMonthlyGpaByCourseInviteId?.data?.ResultObject.length ===
                      0 && (
                      <tr>
                        <td colSpan={99} className='text-center text-muted'>
                          No Data found
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GPAdetails;
