/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import styles from 'app/components/AdModal/AdModal.module.scss';

import { useAd } from 'app/components/AdModal/AdProvider';
import RandomAdvertise from 'app/components/RandomAdvertise';
import useIntlCustom from 'hooks/useIntlCustom';

const AdSection: React.FC<any> = () => {
  const { formatMessage } = useIntlCustom();

  const { showAd, onCloseAd } = useAd();

  const [isAdEnded, setIsAdEnded] = React.useState(false);

  const totalSec = 10;

  const [progress, setProgress] = React.useState(1);
  const seconds = Math.floor((progress / 100) * totalSec);

  const value = progress;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress: any) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setIsAdEnded(true);

          return 100;
        }

        const t = prevProgress + 100 / totalSec;

        return t >= 100 ? 100 : t;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSec]);

  const onCloseModal = () => {
    onCloseAd();
  };

  return showAd ? (
    <>
      <div
        className='container position-absolute h-100 bg-white fade-out p-2'
        style={{ zIndex: 99 }}
        id='advertiseLesson'
      >
        <div className='w-100 h-100 position-relative d-flex flex-column justify-content-center gap-2'>
          <div>
            <button
              className='btn btn-primary btn-sm w-100'
              onClick={() => {
                onCloseModal();
              }}
              disabled={!isAdEnded}
            >
              {formatMessage({
                id: 'CONTINUE',
              })}
            </button>
            {!isAdEnded && (
              <div className='ad-progressbar'>
                <React.Fragment>
                  <progress
                    id={styles.progress}
                    max='100'
                    value={100 - value}
                    style={{
                      borderRadius: '10px',
                      backgroundColor: '#fff',
                    }}
                  >
                    Progress
                  </progress>
                  <div className={styles.timer + ` text-left`}>
                    {`${totalSec - seconds}`}{' '}
                    {formatMessage({
                      id: 'SECONDS',
                    })}
                  </div>
                </React.Fragment>
              </div>
            )}
          </div>
          <RandomAdvertise showAd={showAd} />
        </div>
      </div>
    </>
  ) : null;
};

export default React.memo(AdSection);
