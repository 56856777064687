import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { AiFillRightCircle } from 'react-icons/ai';
import LoadingOverlay from 'react-loading-overlay-ts';
import FooterPage from '../activityfooter/FooterPage';
import HeaderPage from '../activityheader/HeaderActivityPage';
import { useNavigate, useParams } from 'react-router-dom';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { imageUpload } from 'store/profileimage/actions';
import { aiImageGenerateSuccess } from 'store/actions';
import Loader from 'app/components/Loader';
import { useAuth } from 'app/modules/auth';
import { usePostQuery } from 'hooks/reactQueryHelper';

const VideoSection: React.FC<any> = (props: any) => {
  const {
    setVideoSectionShow,
    setTapITActivityShow,
    singleLessonData,
    setSectionType,
    sectionType,
    setStartLessonShow,
    loading,
    sectionTotalLength,
    sectionIndex,
    setSectionIndex,
    setFinishLessonShow,
    setSingleLessonData,
    nativeLang,
    targetLang,
    tapITActivityShow,
    setPickWordActivityShow,
    profileImage,
    setProfileImage,
    userID,
    trueAnswerCount,
    setTrueAnswerCount,
    aiActivityDefaultText,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState('');
  const [nextUrl, setNextUrl] = useState('');
  const [lessonData, setLessonData] = useState<any>();
  const [loadingData, setLoadingData] = useState(false);
  const [muteVoice, setMuteVoice] = useState(false);
  const [playButton, setPlayButton] = useState(false);
  const [urlHash, setUrlHash] = useState(undefined);
  const [aiData, setAIData] = useState<any>();
  const [isVideoLoading, setVideoIsLoading] = useState(true);
  const [aiProfileImageLoader, setAIProfileImageLoader] = useState(false);
  const [aiProfileImageValidation, setAIProfileImageValidation] =
    useState(false);
  const [profileImageError, setProfileImageError] = useState(false);
  const handleLoadedData = () => {
    setVideoIsLoading(false);
  };

  const { imageloading, error, success, imageURL } = useSelector(
    (state: any) => state.profileimage
  );
  const { aiImageUrlloading, aiImageError, aiImageSuccess, aiImageUrl } =
    useSelector((state: any) => state.openai);

  const { courseInviteId, id } = useParams();
  const { currentUser } = useAuth();

  const addUserLessonLog: any = usePostQuery(
    'addUserLessonLog',
    '/userLessonsLogs/upsertUserLessonsLog',
    {}
  );

  const handleURLPlay = () => {
    setVideoUrl('');
  };

  useEffect(() => {
    setVideoUrl('');
    if (
      sectionTotalLength != undefined &&
      singleLessonData != null &&
      singleLessonData != undefined &&
      singleLessonData != ''
    ) {
      if (sectionIndex <= sectionTotalLength) {
        let dataSection = singleLessonData.Activity_Details[sectionIndex];
        var data = singleLessonData?.Activity_Details?.filter(
          (x: any) => x.Section == dataSection.Section
        )[0];
        if (sectionType == 'AISection') {
          setAIData(
            data?.HeadText?.filter((x: any) => x.Language_Code == nativeLang)[0]
          );
        }
        setFinishLessonShow(false);
        setVideoUrl(data?.Video_Url);
        setUrlHash(dataSection.Section);
        setLessonData(data);
        setSectionType(dataSection.Section);
      } else {
        setSectionType('');
        setVideoUrl('');
        // setSingleLessonData("")
        setVideoSectionShow(false);
        setFinishLessonShow(true);
      }
    }
  }, [singleLessonData]);

  useEffect(() => {
    if (imageURL != '') {
      setProfileImage(imageURL);
      setAIProfileImageValidation(false);
    }
  }, [imageURL]);

  useEffect(() => {
    if (imageloading) {
      setAIProfileImageLoader(true);
    }
    if (!imageloading) {
      setAIProfileImageLoader(false);
    }
  }, [imageloading]);

  useEffect(() => {
    setProfileImage(profileImage);
  }, [profileImage]);

  const handleVideoNextButton = () => {
    setVideoSectionShow(false);
    setTapITActivityShow(true);
  };

  const handleAINextButton = () => {
    if (profileImage) {
      setVideoSectionShow(false);
      setPickWordActivityShow(true);
      setProfileImageError(false);
      dispatch(aiImageGenerateSuccess(profileImage));
    } else {
      setProfileImageError(true);
    }
  };

  const handlePlay = (event: any) => {
    setPlayButton(true);
    var datas: any = document.getElementById('video-tag');
    datas.play();
    setMuteVoice(!muteVoice);
    if (datas.muted) {
      datas.muted = false;
    } else {
      datas.muted = true;
    }
    setTimeout(() => {
      setPlayButton(false);
    }, 500);
  };
  const handleClick = () => {
    var datas: any = document.getElementById('video-tag');
    setPlayButton(false);
    setMuteVoice(!muteVoice);
    if (datas.muted) {
      datas.muted = false;
    } else {
      datas.muted = true;
    }
  };

  const lesson = singleLessonData?._id || undefined;

  let storageValue: any = localStorage.getItem('kt-auth-react-v');
  const parseStringValue = JSON.parse(storageValue);

  // useEffect(() => {
  //   setProfileImage(parseStringValue?.data?.profilePic)
  // }, [parseStringValue])

  const handleChnageImage = (event: any) => {
    if (event.target.files[0].size <= 2097152) {
      dispatch(imageUpload({ file: event.target.files[0], UserID: userID }));
    } else {
      setAIProfileImageValidation(true);
    }
  };

  const handleSaveUserLog = () => {
    const formData = {
      StudentId: currentUser?._id,
      UserId: currentUser?.UserId?._id,
      LessonId: id,
    };
    if (formData) {
      addUserLessonLog.mutate(formData);
    }
  };

  return (
    <>
      <Loader loading={lessonData ? false : true} />
      {/* <Loader loading={loading} /> */}
      {/* <Loader loading={true} /> */}
      <>
        <div
          className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'
          id='app_mobile_video_fullscreen'
        >
          <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
            {sectionType == 'QuizSection' ? (
              <div className='container layout_container'>
                {/* <h1>{lessonData?.Quiz_Title}</h1> */}
                <div className='row'>
                  <div className='col-12'>
                    <div className='w-100 position-relative d-flex flex-column'>
                      <div className='row d-grid gap-3'>
                        <div className='col-12 mx-auto'>
                          <div className='w-100 head-content'>
                            <ProgressBar animated now={5} />
                          </div>
                        </div>
                        <div className='col-12 mx-auto'>
                          <div className='w-100 z-10 d-grid gap-1 text-black'></div>
                          <div className='quiz-home-box'>
                            <div className='text-quiz text-center'>
                              {/* {lessonData?.Quiz_Title} */}
                              Graded Quiz
                            </div>
                            <h5 className='text-center p-3 lh-lg'>
                              {/* {lessonData?.Quiz_Discription} */}
                              it's time to put what you learned to the test.
                              Start the quiz when you are ready!
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footertag-overlay-buttons d-flex justify-content-end'>
                  <button
                    className='btn btn-primary p-2 rounded-pill'
                    onClick={() => handleVideoNextButton()}
                  >
                    Start Quiz
                    <AiFillRightCircle size={25} />
                  </button>
                </div>
              </div>
            ) : sectionType == 'AISection' ? (
              <>
                <div
                  className='container position-relative'
                  style={{
                    backgroundImage: `url("${lessonData?.AI_Background_Image_Slide1}")`,
                    backgroundSize: '100% 100%',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div className='row'>
                    <div className='col-12'>
                      <div className='w-100 position-relative d-flex flex-column'>
                        <div className='row d-grid gap-3'>
                          <div className='col-12 mx-auto'>
                            <div className='w-100 head-content'>
                              <HeaderPage />
                              <div className='progress-bar mt-3'>
                                <ProgressBar animated now={10} />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 mx-auto'>
                            <div className='w-100 z-10 p-2'>
                              <div
                                className='card rounded-5'
                                style={{ backgroundColor: '#f0f0f0' }}
                              >
                                <div className='card-title text-center ai-photo-head-text'>
                                  {aiActivityDefaultText?.HeadText}
                                </div>
                              </div>
                              <div
                                className='card rounded-5 mt-3'
                                style={{ backgroundColor: 'rgb(255 213 194)' }}
                              >
                                <div className='card-body ai-card'>
                                  <p className='card-text text-center fw-bold fs-5'>
                                    {aiActivityDefaultText?.PhotoHeadText}
                                  </p>
                                  {/* <p className="card-text text-center fw-bold fs-5">Upload a Photo of Yourself</p> */}
                                  <LoadingOverlay
                                    active={aiProfileImageLoader}
                                    spinner
                                    className='spinner-animation'
                                  >
                                    <img
                                      src={
                                        profileImage
                                          ? profileImage
                                          : 'https://via.placeholder.com/150?text=NA'
                                      }
                                      className='card-img-top rounded-2'
                                      // style={{ minHeight: "150px", maxHeight: "200px" }}
                                    />
                                  </LoadingOverlay>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-12 text-center'>
                                <input
                                  className='form-control'
                                  type='file'
                                  id='formFile'
                                  onChange={(e) => handleChnageImage(e)}
                                />
                                {aiProfileImageValidation && (
                                  <p style={{ color: 'red' }}>Less then 2MB</p>
                                )}
                                {profileImageError && (
                                  <p style={{ color: 'red' }}>
                                    Please upload image
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='footertag-overlay-buttons d-flex justify-content-end'>
                    <button
                      className='btn btn-primary p-2 rounded-pill'
                      onClick={() => handleAINextButton()}
                    >
                      Next
                      <AiFillRightCircle size={25} />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='position-relative h-100'>
                  <div className='embed-responsive embed-responsive-16by9'>
                    <div className='d-flex align-items-center justify-content-center'>
                      {videoUrl != '' ? (
                        <>
                          {isVideoLoading && (
                            <div className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'>
                              <div className='spinner-border' role='status'>
                                <span className='visually-hidden'>
                                  Loading...
                                </span>
                              </div>
                            </div>
                          )}
                          <HeaderPage />
                          <video
                            onClick={handlePlay}
                            loop
                            autoPlay
                            muted
                            playsInline
                            onLoadedData={handleLoadedData}
                            preload='auto'
                            id='video-tag'
                            className=''
                          >
                            <source src={videoUrl} type='video/mp4' />
                            Your browser does not support the video tag.
                          </video>
                          <div
                            className={
                              playButton ? 'video__play_btn' : 'video__play_btn'
                            }
                          >
                            <button className='btn-play' onClick={handleClick}>
                              {muteVoice ? (
                                <MdVolumeUp color='white' size={25} />
                              ) : (
                                <MdVolumeOff color='white' size={25} />
                              )}
                            </button>
                          </div>
                          <FooterPage
                            urlHash={urlHash}
                            lesson={lesson}
                            handleVideoNextButton={handleVideoNextButton}
                            sectionType={sectionType}
                          />
                        </>
                      ) : (
                        <>
                          <LoadingOverlay
                            active={true}
                            spinner
                            className='video-section-loading-overlay'
                          >
                            <HeaderPage />
                            <FooterPage
                              urlHash={urlHash}
                              lesson={lesson}
                              handleVideoNextButton={handleVideoNextButton}
                              sectionType={sectionType}
                            />
                          </LoadingOverlay>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* } */}
      </>
    </>
  );
};

export default VideoSection;
