import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_8 = (props: any) => {
  const {
    formik,
    formK12,
    formK6,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
  } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Physical_Education_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          PHYSICAL EDUCATION (PE): How will your student meet the requirements?
          <span className='text-danger'> * </span>
        </label>
        <p className='form-label fw-bold'>
          EDUCACIÓN FÍSICA (EF): ¿Cómo cumplirá su estudiante con los
          requisitos?
        </p>
        {formK12 && (
          <>
            <p className='form-label mb-2'>
              Physical Education: All pupils, except pupils excused or exempted
              pursuant to Section 51241, shall be required to attend upon the
              courses of physical education for a total period of time of not
              less than 400 minutes each 10 schooldays. Examples of what you see
              in PE are: doing physical exercises, learning and practice of
              sports.
            </p>
            <p className='form-label mb-2'>
              Educación Física: Todos los alumnos, excepto los alumnos excusados
              ​​o exentos de conformidad con la Sección 51241, deberán asistir a
              los cursos de educación física por un período total de no menos de
              400 minutos cada 10 días escolares. Ejemplos de lo que se ve en
              Educación Física son: hacer ejercicios físicos, aprender y
              practicar deportes.
            </p>
          </>
        )}{' '}
        {formK6 && (
          <>
            <p className='form-label mb-2'>
              Physical Education: With emphasis upon the physical activities for
              the pupils that may be conducive to health and vigor of body and
              mind, for a total period of time of not less than 200 minutes each
              10 school days, exclusive of recesses and the lunch period.
              Examples of what you see in PE are: doing physical exercises,
              learning and practice of sports.
            </p>
            <p className='form-label mb-2'>
              Educación Física: Con énfasis en las actividades físicas para los
              alumnos que puedan ser conducentes a la salud y vigor de cuerpo y
              mente, por un período total de tiempo no menor de 200 minutos cada
              10 días escolares, sin incluir los recreos y el almuerzo. Ejemplos
              de lo que ves en Educación Física son: hacer ejercicios físicos,
              aprender y practicar deportes.
            </p>
          </>
        )}
        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Physical_Education_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight(
                        'Physical_Education_Requirement_Subject'
                      );
                      onHandleRquirementSubject(
                        'Physical_Education_Requirement_Subject',
                        data.Value,
                        null
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Physical_Education_Requirement_Subject}
          errors={formik.errors.Physical_Education_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_8;
