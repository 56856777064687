import React from 'react';
import { Card } from 'react-bootstrap';
import GradeSubmissionFormFooter from '../GradeSubmissionFormFooter';

const Section2 = (props: any) => {
  const { formType, setStep, step, stepper } = props;
  return (
    <>
      <div>
        <Card className='mt-5'>
          <Card.Body className='p-6'>
            <div className='p-3'>
              <label className='form-label'>Wrong Grade Submission Link </label>
              {formType == 2 && (
                <p className='form-label fw-normal'>
                  If your student is in 7th, 8th, 9th, 10th, 11th, or 12th
                  grade, this is the wrong grade submission form. Please contact
                  our staff to get the correct link.
                </p>
              )}
              {formType == 1 && (
                <p className='form-label fw-normal'>
                  If your student is in K, 1st, 2nd, 3rd, 4th, 5th, 6th grade,
                  this is the wrong grade submission form. Please contact our
                  staff to get the correct link.
                </p>
              )}
            </div>
          </Card.Body>
        </Card>
        <GradeSubmissionFormFooter
          IsBackButton={true}
          setStep={setStep}
          step={step}
          stepper={stepper}
          formType={formType}
        />
      </div>
    </>
  );
};

export default Section2;
