import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import RegistrationFormFooter from '../../RegistrationFormFooter';
import Section7_12_10 from '../sections7_12/Section7_12_10';
import Section7_12_11 from '../sections7_12/Section7_12_11';
import Section7_12_12 from '../sections7_12/Section7_12_12';
import Section7_12_13 from '../sections7_12/Section7_12_13';
import Section7_12_14 from '../sections7_12/Section7_12_14';
import Section7_12_15 from '../sections7_12/Section7_12_15';
import Section7_12_16 from '../sections7_12/Section7_12_16';
import { usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { removeEmptyStringProperties } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import HighlightedIssuesText from 'app/pages/enrollmenthooks/HighlightedIssuesText';

const Step2 = (props: any) => {
  const {
    setStep,
    grade,
    setIsFinishYear,
    step,
    fromK12Data,
    formType,
    finishYear,
    fieldReportedIssue,
  } = props;
  const saveFormStep2Query: any = usePostQuery(
    'saveFormStep2',
    '/enrollmentregister/save',
    {}
  );

  const IsFormK12: Boolean = formType == 1 ? true : false;
  const IsFormK6: Boolean = formType == 2 ? true : false;

  const navigate = useNavigate();

  const initialValues: any = {
    FormType: formType,
    ButtonAction: '',
    Status: 0,
  };

  if (IsFormK12) {
    initialValues.Finish_High_School_Year_Question = '';
  }

  useEffect(() => {
    if (fromK12Data) {
      {
        IsFormK12 &&
          formik.setFieldValue(
            'Finish_High_School_Year_Question',
            fromK12Data?.Finish_High_School_Year_Question?.value
              ? fromK12Data?.Finish_High_School_Year_Question?.value
              : ''
          );
      }
      formik.setFieldValue(
        'Status',
        fromK12Data?.Status ? fromK12Data?.Status : 0
      );
    }
  }, [fromK12Data]);

  const formvalidationSchema = Yup.object().shape({
    Finish_High_School_Year_Question:
      IsFormK12 && Yup.string().required('Please select any option'),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: formvalidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      console.log(values);
      await removeEmptyStringProperties(values);
      saveFormStep2Query.mutateAsync(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.Finish_High_School_Year_Question) {
      setIsFinishYear(
        formik.values.Finish_High_School_Year_Question == 'Yes' ? true : false
      );
    }
  }, [formik.values.Finish_High_School_Year_Question]);

  useEffect(() => {
    if (saveFormStep2Query.isSuccess) {
      if (
        formik?.values?.ButtonAction == 'Next' ||
        formik?.values?.ButtonAction == 'Submit'
      ) {
        setStep(3);
      }
      if (formik?.values?.ButtonAction == 'Save') {
        navigate('/account');
      }
    }
  }, [saveFormStep2Query.isSuccess]);

  const onFormInformationSave = (actionName: any) => {
    formik.setFieldValue('ButtonAction', actionName);
  };

  const tabRef: any = useRef(null);
  const [hasFieldError, setHasFieldError] = useState<any>('');
  useEffect(() => {
    let hasError =
      fieldReportedIssue &&
      fieldReportedIssue.map((field: any) => field.FieldName);
    setHasFieldError(hasError);
  }, [fieldReportedIssue]);

  useEffect(() => {
    if (hasFieldError?.length > 0 && tabRef.current) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasFieldError]);

  const removeHightlight = (key: string) => {
    setHasFieldError((prev: any[]) => {
      if (Array.isArray(prev)) {
        const fields = [...prev];
        console.log(fields);
        const index = fields.findIndex((field) => key === field);
        if (index > -1) {
          fields.splice(index, 1);
        }
        return fields;
      } else {
        return prev;
      }
    });
  };

  return (
    <>
      <Loader loading={saveFormStep2Query.isLoading} />
      <form onSubmit={formik.handleSubmit} encType='multipart/form-data'>
        <Card className='mt-5'>
          <Card.Body className='p-6' ref={tabRef}>
            <Section7_12_16
              formik={formik}
              hasFieldError={hasFieldError}
              removeHightlight={removeHightlight}
            />
            <HighlightedIssuesText formik={formik} />
          </Card.Body>
        </Card>
        <RegistrationFormFooter
          IsBackButton={true}
          setStep={setStep}
          step={step}
          grade={grade}
          onSaveForm={onFormInformationSave}
        />
      </form>
    </>
  );
};

export default Step2;
