import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_5 = (props: any) => {
  const {
    formik,
    formK12,
    formK6,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
  } = props;

  return (
    <>
      <div
        className={
          hasFieldError?.includes('Science_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          SCIENCE REQUIREMENTS: How will your student meet the requirements?
          <span className='text-danger'> * </span>
        </label>
        <p className='form-label fw-bold'>
          REQUISITOS DE CIENCIAS: ¿Cómo cumplirá su estudiante con los
          requisitos?
        </p>

        {formK12 && (
          <>
            <p className='form-label mb-2'>
              Science Requirements: Including the physical and biological
              aspects, with emphasis on basic concepts, theories, and processes
              of scientific investigation and on the place of humans in
              ecological systems, and with appropriate applications of the
              interrelation and interdependence of the sciences.
            </p>
            <p className='form-label mb-2'>
              Requisitos de Ciencias: Incluidos los aspectos físicos y
              biológicos, con énfasis en los conceptos básicos, teorías y
              procesos de investigación científica y en el lugar de los humanos
              en los sistemas ecológicos, y con aplicaciones apropiadas de la
              interrelación e interdependencia de las ciencias.
            </p>
          </>
        )}
        {formK6 && (
          <>
            <p className='form-label mb-2'>
              Science Requirements: Including the biological and physical
              aspects, with emphasis on the processes of experimental inquiry
              and on the place of humans in ecological systems.
            </p>
            <p className='form-label mb-2'>
              Requisitos de Ciencias: Incluyendo los aspectos biológicos y
              físicos, con énfasis en los procesos de investigación experimental
              y en el lugar de los humanos en los sistemas ecológicos.
            </p>
          </>
        )}

        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Science_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('Science_Requirement_Subject');
                      onHandleRquirementSubject(
                        'Science_Requirement_Subject',
                        data?.Value,
                        null
                      );
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Science_Requirement_Subject}
          errors={formik.errors.Science_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_5;
