import { createSagaActionTypes } from 'store/utils';

// WARING: DON'T EDIT THIS FILE
export default createSagaActionTypes('lessons', 'action', [
  'get_requested',
  'get_succeed',
  'get_failed',

  'details_requested',
  'details_succeed',
  'details_failed',
  'clear_lesson'
] as const);
