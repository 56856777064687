import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import { BsFolder } from 'react-icons/bs';
import AddNewFolder from './AddNewFolder';

import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'; //https://stackblitz.com/edit/react-sortable-hoc-grid?file=index.js,Hello.js
import { log } from 'console';
import { useMutation } from 'react-query';
import { getAxiosInstance, post } from 'helpers/api-helper';
import { usePostQuery } from 'hooks/reactQueryHelper';

const ClassRoomFolderList: React.FC<any> = (props: any) => {
  const {
    drag,
    setDrag,
    dragIndex,
    setDragIndex,
    folder,
    setFolder,
    classList,
    setClassList,
    getFolder,
  } = props;
  const [modalShow, setModalShow] = React.useState(false);

  const onSortEnd = async (data: any) => {
    const { oldIndex, newIndex } = data;
    let newArray: any = arrayMove(folder, oldIndex, newIndex);
    setFolder(newArray);
    const folderIds = newArray.map((ele: any) => ele._id);
    try {
      const response = await getAxiosInstance().post(
        '/folder/updateOrderbyFolder',
        {
          folderIds,
        }
      );
    } catch (error) {
      console.error('Error updating folder order:', error);
    }
  };

  return (
    <>
      <AddNewFolder
        show={modalShow}
        onHide={() => setModalShow(false)}
        getFolder={getFolder}
        folder={folder}
        onSortEnd={onSortEnd}
        setFolder={setFolder}
      />
      <div
        className='card card-custom card-stretch shadow mb-5 p-5'
        style={{
          minHeight: 'calc(100vh - 180px)',
        }}
      >
        <div className='card-body p-0'>
          <button
            className='btn  btn-light fw-bold w-100'
            onClick={() => setModalShow(true)}
          >
            <MdOutlineCreateNewFolder /> New Folder
          </button>

          <SortableList
            axis='y'
            onSortEnd={onSortEnd}
            onSortStart={(res: any) => {
              // console.log("onSortStart", res);
              // setDragIndex(res.index)
            }}
          >
            {folder &&
              folder.map((item: any, index: any) => (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  dataItem={item}
                  drag={drag}
                  dragIndex={dragIndex}
                  setDragIndex={setDragIndex}
                  classList={classList}
                  setClassList={setClassList}
                />
              ))}
          </SortableList>
        </div>
      </div>
    </>
  );
};
export default ClassRoomFolderList;

const SortableList: any = SortableContainer(({ children }: any) => (
  <div className='d-flex flex-column gap-1 mt-5'>{children}</div>
));

const SortableItem: any = SortableElement(
  ({
    index,
    dataItem,
    drag,
    dragIndex,
    setDragIndex,
    classList,
    setClassList,
  }: any) => {
    return (
      <button
        className='btn btn-active-light d-flex justify-content-start align-items-center fs-5 rounded cursor-move'
        onMouseUp={() => {
          if (drag) {
            if (dragIndex != null) {
              const updatedItems = [...classList];
              updatedItems.splice(dragIndex, 1);
              setClassList(updatedItems);
              setDragIndex(null);
            }
          }
        }}
      >
        <BsFolder />
        <span className='col-10 text-truncate text-start ms-2'>
          {dataItem.folderName}
        </span>
      </button>
    );
  }
);
