import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Confetti from 'react-dom-confetti';
import { toast } from 'react-toastify';
import { BsStopFill } from 'react-icons/bs';
import FooterActivityPage from '../activityfooter/FooterActivityPage';
import { BsMicFill } from 'react-icons/bs';
import { IoStop } from 'react-icons/io5';
import { usePostQuery } from 'hooks/reactQueryHelper';
import successSound from '../../../theme/assets/audio/successSound.mp3';

declare global {
  interface Window {
    webkitSpeechRecognition: any;
  }
}

const SayItActivity: React.FC<any> = (props: any) => {
  const {
    singleLessonData,
    setWriteITActivityShow,
    setSayITActivityShow,
    sectionType,
    setTapITActivityShow,
    setPrevLastIndexQuestion,
    prevLastIndexQuestion,
    nativeLang,
    targetLang,
    trueAnswerCount,
    setTrueAnswerCount,
  } = props;

  const addExceptionLog: any = usePostQuery(
    'addExceptionLog',
    '/exceptionlog/add',
    {}
  );

  const [audioWave, setAudioWave] = useState(false);
  const [tapToRecord, setTapToRecord] = useState(true);
  const [reTapToRecord, setReTapToRecord] = useState(false);
  const [sayITTranscriptText, setSayITTranscriptText] = useState('');
  const [answerCount, setAnswerCount] = useState(0);
  const [animationShow, setAnimationShow] = useState(false);
  const [sayITIndex, setSayItIndex] = useState(0);
  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("es");
  const [checkIndex, setCheckIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [playButtonShow, setPlayButtonShow] = useState(false);
  const [audioPlay, setAudioPlay] = useState(false);
  const [nextButtonShow, setNextButtonShow] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showAnswerText, setShowAnswerText] = useState('');
  const [playButtonLoader, setPlayButtonLoader] = useState(false);

  var sayITQuestionsLenght: number;

  var data = singleLessonData.Activity_Details.filter(
    (x: any) => x.Section == sectionType
  )[0];
  // const [data, setData] = useState(singleLessonData.Activity_Details.filter((x: any) => (x.Section == sectionType))[0])

  useEffect(() => {}, [singleLessonData]);

  const [config, setConfig] = useState<any>({
    angle: 90,
    spread: '60',
    startVelocity: '41',
    elementCount: '40',
    dragFriction: '0.13',
    duration: 1000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '414px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  });

  const [interTranscript, setInterTranscript] = useState('');
  const [finalTranscript, setFinalTranscript] = useState('');

  const recognitionRef: any = useRef(null);
  const mediaStreamRef: any = useRef(null);

  useEffect(() => {
    setInterTranscript(interTranscript);
  }, [interTranscript]);

  // const SpeechRecognition =
  //     window.SpeechRecognition || window.webkitSpeechRecognition;
  // const recognition = new SpeechRecognition();

  const startSpeechRecognition = () => {
    var userAgent = navigator.userAgent;
    let browswerName: any;

    if (userAgent.indexOf('Chrome') > -1) {
      browswerName = 'Google Chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      browswerName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Safari') > -1) {
      browswerName = 'Apple Safari';
    } else if (
      userAgent.indexOf('Opera') > -1 ||
      userAgent.indexOf('OPR') > -1
    ) {
      browswerName = 'Opera';
    } else if (userAgent.indexOf('Edge') > -1) {
      browswerName = 'Microsoft Edge';
    } else if (userAgent.indexOf('Trident') > -1) {
      browswerName = 'Internet Explorer';
    } else {
      browswerName = 'Unknown browser';
    }

    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const folderSaveData = {
        message: `speech recognition API supported in ${browswerName} `,
        name: 'SpeechRecognition',
      };
      addExceptionLog.mutate(folderSaveData);
    } else {
      const folderSaveData = {
        message: `speech recognition API not supported in ${browswerName}`,
        name: 'SpeechRecognition',
      };
      addExceptionLog.mutate(folderSaveData);
    }

    recognitionRef.current = new ((window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition ||
      (window as any).mozSpeechRecognition ||
      (window as any).msSpeechRecognition)();

    // recognition.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = targetLang;

    recognitionRef.current.onresult = (event: any) => {
      if (event.results[0].isFinal) {
        const folderSaveData = {
          message: `onresult recognition is ${
            recognitionRef.current ? recognitionRef.current : 'none'
          } and event is ${event.results[0][0].transcript} in  ${browswerName}`,
          name: 'SpeechRecognition',
        };
        addExceptionLog.mutate(folderSaveData);
        setFinalTranscript(event.results[0][0].transcript);
      }
      setInterTranscript(event.results[0][0].transcript);
    };

    recognitionRef.current.onend = (event: any) => {
      const folderSaveData = {
        message: `onend recognition is ${
          recognitionRef.current ? recognitionRef.current : 'none'
        } in  ${browswerName}`,
        name: 'SpeechRecognition',
      };

      addExceptionLog.mutate(folderSaveData);
      recognitionRef.current.stop();
      // setAudioWave(false);
      stopSpeechRecognition();

      if (/iPhone/i.test(userAgent) && browswerName === 'Unknown browser') {
        if (mediaStreamRef.current) {
          mediaStreamRef.current
            .getTracks()
            .forEach((track: any) => track.stop());
        }
      } else {
        console.log('Not Facebook Messenger on Android or iPhone');
      }
    };

    recognitionRef.current.onerror = (event: any) => {
      const folderSaveData = {
        message: `onerror recognition is ${
          recognitionRef.current ? recognitionRef.current : 'none'
        } in  ${browswerName} and error is ${event.error}`,
        name: 'SpeechRecognition',
      };
      addExceptionLog.mutate(folderSaveData);

      // setAudioWave(false);

      if (/iPhone/i.test(userAgent) && browswerName === 'Unknown browser') {
        if (mediaStreamRef.current) {
          mediaStreamRef.current
            .getTracks()
            .forEach((track: any) => track.stop());
        }
      } else {
        setAudioWave(false);
      }
    };

    if (/iPhone/i.test(userAgent) && browswerName === 'Unknown browser') {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaStreamRef.current = stream;
          recognitionRef.current.start();
        })
        .catch((error) => {
          console.error('Microphone access denied:', error);
        });
    } else {
      recognitionRef.current.start();
    }
  };

  useEffect(() => {
    if (finalTranscript && finalTranscript != '') {
      setSayITTranscriptText(finalTranscript);
    }
  }, [finalTranscript]);

  const stopSpeechRecognition = async () => {
    if (recognitionRef.current) {
      recognitionRef.current?.stop();
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track: any) => track.stop());
    }

    // if (rec) {
    //   rec?.stop();
    // }

    // if (recognitionFun) {
    //   recognitionFun?.stop();
    // }
    // if (rec) {
    //   rec?.stop();
    // }

    // if (mediaStream?.readyState == "live") {
    //   mediaStream.stop();
    // }

    setSayITTranscriptText('');
    setFinalTranscript('');
    setTapToRecord(false);
    setNextButtonShow(true);
    setAudioWave(false);
    setTimeout(() => {
      setTapToRecord(true);
    }, 200);
  };

  const showErrorMessage = () => {
    toast.error('Try Again !', { autoClose: 1000 });
  };
  const showIncorrectMessage = () => {
    toast.dismiss();
    toast.error('In Correct !', { autoClose: 1000 });
  };

  useEffect(() => {
    if (animationShow) {
      setTrueAnswerCount(trueAnswerCount + 1);
    }
  }, [animationShow]);

  useEffect(() => {
    if (prevLastIndexQuestion) {
      setSayItIndex(sayITQuestionsLenght);
    }
  }, [prevLastIndexQuestion]);

  useEffect(() => {
    if (sayITTranscriptText !== '') {
      setShowAnswerText(sayITTranscriptText);
      setShowAnswer(true);
      setTimeout(() => {
        setShowAnswer(false);
      }, 1000);
      handleSayITQuestionsAnswer(sayITTranscriptText);

      // resetTranscript();
      setInterTranscript('');
      stopSpeechRecognition();
    }
  }, [sayITTranscriptText]);

  // useEffect(() => {
  //   debugger
  //   var answerLength = answer.split(' ').length;
  //   var interimTranscriptLength = interimTranscript.split(' ').length;
  //   debugger
  //   if (answerLength == interimTranscriptLength && interimTranscript != '') {
  //     debugger
  //     return setSayITTranscriptText(interimTranscript);
  //   }
  //   debugger
  //   if (finalTranscript) {
  //     debugger
  //     return setSayITTranscriptText(finalTranscript);
  //   }
  // }, [interimTranscript]);

  // useEffect(() => {
  //   console.log("Got final result:", finalTranscript);
  //   if (finalTranscript != "") {
  //     setSayITTranscriptText(finalTranscript)
  //   }
  // }, [finalTranscript]);

  const handleSayITQuestionsAnswer = (event: any) => {
    setAnswerCount(answerCount + 1);

    const ansText = event.trim().replace('.', '');
    const questionAnswer = ansText.toLowerCase();
    const matchAnswer = answer.toLowerCase().trim().replace('.', '');

    const words1 = questionAnswer.split(' ');
    const words2 = matchAnswer.split(' ');
    var answerLength = answer.split(' ').length;
    let match = false;

    if (answerLength == 1) {
      if (words2.toString().includes(words1.toString())) {
        match = true;
      }
    } else {
      let specificWords = [];
      specificWords = words1.map((word: any) => word.trim());
      // Search for each specific word in both arrays
      for (let i = 0; i < specificWords.length; i++) {
        if (words2.includes(specificWords[i])) {
          match = true;
          break;
        }
      }
    }

    if (match || answerCount == 1) {
      if (answerCount === 1 || !match) {
        if (!match) {
          if (checkIndex !== sayITQuestionsLenght) {
            // showIncorrectMessage();
            setAnswerCount(0);
            setTimeout(() => {
              setShowAnswer(false);
              setTapToRecord(true);
              toast.dismiss();
              setNextButtonShow(false);
              setSayItIndex(checkIndex + 1);
            }, 1000);
            setAudioWave(false);

            ////SpeechRecognition.stopListening();
            stopSpeechRecognition();
          }
          if (checkIndex == sayITQuestionsLenght) {
            setPrevLastIndexQuestion(false);
            // showIncorrectMessage();

            setAnswerCount(0);
            setAudioWave(false);
            setTimeout(() => {
              toast.dismiss();
              setNextButtonShow(false);
              setWriteITActivityShow(true);
              setSayITActivityShow(false);
              setTapToRecord(true);
              setShowAnswer(false);
            }, 1000);

            ////SpeechRecognition.stopListening();
            stopSpeechRecognition();
          }
        }
      }
      if (match) {
        const audioSuccess: any = document.getElementById('audioSuccess');
        audioSuccess.play();
        if (checkIndex == sayITQuestionsLenght) {
          setPrevLastIndexQuestion(false);
          setAnimationShow(true);

          setAnswerCount(0);
          setAudioWave(false);
          toast.dismiss();
          setTimeout(() => {
            setNextButtonShow(false);
            setWriteITActivityShow(true);
            setSayITActivityShow(false);
            setAnimationShow(false);
            setTapToRecord(true);
            setShowAnswer(false);
          }, 1000);

          ////SpeechRecognition.stopListening();
          stopSpeechRecognition();
        }
        if (checkIndex != sayITQuestionsLenght) {
          setAnimationShow(true);

          setAnswerCount(0);
          setAudioWave(false);
          toast.dismiss();
          setTimeout(() => {
            setNextButtonShow(false);
            setSayItIndex(checkIndex + 1);
            setAnimationShow(false);
            setTapToRecord(true);
            setShowAnswer(false);
          }, 1000);

          ////SpeechRecognition.stopListening();
          stopSpeechRecognition();
        }
      }
    } else {
      // showErrorMessage();
      setNextButtonShow(true);
      // setReTapToRecord(true)

      ////SpeechRecognition.stopListening();
      stopSpeechRecognition();

      setAudioWave(false);
    }
  };

  const listenContinuously = (index: any, questionAnswer: any) => {
    toast.dismiss();

    setCheckIndex(index);
    setAnswer(questionAnswer);
    // setTapToRecord(true)
    setTimeout(() => {
      setTapToRecord(false);
      setReTapToRecord(false);
      setPlayButtonLoader(true);
    }, 400);
    setAudioWave(true);

    // SpeechRecognition.startListening({
    //   continuous: true,
    //   language: targetLang,
    // });

    startSpeechRecognition();
  };
  const onPronounce = () => {
    setPlayButtonShow(true);
    const idata: any = document.getElementById('sayit-audio-title');
    idata.playbackRate = 0.6;
    idata.play();
    var count = 1;
    idata.addEventListener(
      'ended',
      function (this: any) {
        this.currentTime = 0;
        if (count <= 2) {
          this.play();
        }
        if (count == 3) {
          setPlayButtonShow(false);
        }
        count++;
      },
      false
    );
  };

  const onPronounceStop = () => {
    const idatas: any = document.getElementById('sayit-audio-title');
    idatas.pause();
    idatas.currentTime = 0;
    setPlayButtonShow(false);
  };

  const onPronounceMain = (pronounceAudioId: string) => {
    var pronounceAudio: any = document.getElementById(pronounceAudioId);
    pronounceAudio.currentTime = 0;
    pronounceAudio.play();
  };

  const onAudioPlay = () => {
    setAudioPlay(!audioPlay);
    if (audioPlay) {
      onPronounceStop();
    } else {
      onPronounce();
    }
  };

  const onPreviousButton = () => {
    setTrueAnswerCount(trueAnswerCount - 1);
    setTapToRecord(true);
    setReTapToRecord(false);

    ////SpeechRecognition.stopListening();
    stopSpeechRecognition();

    setAudioWave(false);
    setShowAnswer(false);
    if (sayITIndex != 0) {
      setSayItIndex(sayITIndex - 1);
    } else {
      setSayITActivityShow(false);
      setTapITActivityShow(true);
      setPrevLastIndexQuestion(true);
    }
  };

  const handleVideoNextButton = () => {
    setTapToRecord(true);
    setReTapToRecord(false);
    setShowAnswer(false);
    setPrevLastIndexQuestion(false);
    setTrueAnswerCount(trueAnswerCount + 1);

    if (sayITQuestionsLenght != sayITIndex) {
      setSayItIndex(sayITIndex + 1);
      setNextButtonShow(false);
      setAudioWave(false);

      ////SpeechRecognition.stopListening();
      stopSpeechRecognition();

      setAnswerCount(0);
      toast.dismiss();
    } else {
      setWriteITActivityShow(true);
      setSayITActivityShow(false);
      setNextButtonShow(false);
      setAudioWave(false);

      ////SpeechRecognition.stopListening();
      stopSpeechRecognition();

      toast.dismiss();
    }
  };

  const OnStopRecording = () => {
    setTapToRecord(false);
    setNextButtonShow(true);

    ////SpeechRecognition.stopListening();
    stopSpeechRecognition();

    setAudioWave(false);
    setTimeout(() => {
      // setReTapToRecord(true)
      setTapToRecord(true);
    }, 200);
  };

  useEffect(() => {
    const hideAddressBar = () => {
      window.scrollTo(0, 1);
    };
    setTimeout(hideAddressBar, 0);
  }, []);

  return (
    <>
      {/* <AudioFeature /> */}
      {/* <ExampleSpeechRecognition /> */}
      {/* <SpeechToTextCom /> */}
      {data?.Say_IT?.Questions?.map((result: any, index: any) => {
        sayITQuestionsLenght = data?.Say_IT?.Questions?.length - 1;
        if (index == sayITIndex) {
          let langQuestionData = result?.Question?.Language?.filter(
            (x: any) => x.Language_Code == nativeLang
          )[0];
          let langQuestionDataTarget = result?.Question?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let langAnswerData = result?.Answer?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let questionAnswer = langAnswerData?.Answer;
          var checkTargetLanguage =
            targetLang == 'ja' || targetLang == 'zh-CN' || targetLang == 'ar'
              ? true
              : false;
          var checkNativeLanguage =
            nativeLang == 'ja' || nativeLang == 'zh-CN' || nativeLang == 'ar'
              ? true
              : false;
          return (
            <div
              className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'
              key={index}
            >
              <audio controls id='audioSuccess' preload='auto' hidden>
                <source src={successSound} type='audio/mp3' />
              </audio>
              {langAnswerData?.Audio_Answer && (
                <audio controls id='pronounceAudio' preload='auto' hidden>
                  <source src={langAnswerData?.Audio_Answer} type='audio/mp3' />
                </audio>
              )}
              <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
                <div className='container layout_container'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='w-100 position-relative d-flex flex-column'>
                        <div className='row d-grid gap-3'>
                          <div className='col-12 mx-auto'>
                            <div className='w-100'>
                              <div className='progress-bar mt-3'>
                                <ProgressBar
                                  animated
                                  now={
                                    index == 0
                                      ? 40
                                      : index == 1
                                      ? 50
                                      : index == 2
                                      ? 60
                                      : 70
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {sectionType == 'ConversationSection' ? (
                            <div className='col-10 mx-auto'>
                              <div className='w-100 z-10 d-grid gap-1 text-black'>
                                <div className='gap-2 text-center justify-content-between align-items-center'>
                                  <div className='d-grid gap-2'>
                                    <span
                                      className={`native-lang-text ${
                                        index == 0
                                          ? 'd-block'
                                          : index == 1
                                          ? 'd-none'
                                          : 'd-block'
                                      }`}
                                    >
                                      {langQuestionData?.Title}
                                    </span>
                                    <div
                                      className={`${
                                        index == 0
                                          ? 'bg-primary'
                                          : index == 1
                                          ? 'bcg-grey'
                                          : 'bg-primary'
                                      } gap-3 sayit-ques-ans-box`}
                                    >
                                      <div
                                        className='noselect d-flex'
                                        onClick={() =>
                                          index != 1 && onAudioPlay()
                                        }
                                      >
                                        <div className='d-flex align-items-center'>
                                          {index == 0 && (
                                            <span>
                                              {playButtonShow ? (
                                                <button
                                                  id='Mouse'
                                                  value='Mouse'
                                                  type='button'
                                                  className='bg-white sayit-ques-ans-btn'
                                                  onClick={onPronounceStop}
                                                >
                                                  <BsStopFill size={25} />{' '}
                                                </button>
                                              ) : (
                                                <button
                                                  id='Mouse'
                                                  value='Mouse'
                                                  type='button'
                                                  className='bg-white sayit-ques-ans-btn'
                                                  onClick={onPronounce}
                                                >
                                                  <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='25'
                                                    fill='currentColor'
                                                    className='bi bi-volume-up'
                                                    viewBox='0 0 16 16'
                                                  >
                                                    <path d='M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
                                                    <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z' />
                                                    <path d='M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z' />
                                                  </svg>
                                                </button>
                                              )}
                                              <audio
                                                controls
                                                id='sayit-audio-title'
                                                preload='auto'
                                                hidden
                                              >
                                                <source
                                                  src={
                                                    langAnswerData?.Audio_Answer
                                                  }
                                                />
                                              </audio>
                                            </span>
                                          )}
                                        </div>
                                        <div className='sayit-ques-ans-text text-white'>
                                          {langQuestionDataTarget?.Title}
                                        </div>
                                        <div className='Confetti-div'>
                                          <Confetti
                                            active={animationShow}
                                            config={config}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <span
                                      className={`native-lang-text ${
                                        index == 0
                                          ? 'd-none'
                                          : index == 1
                                          ? 'd-block'
                                          : 'd-block'
                                      }`}
                                    >
                                      {langQuestionData?.Title1}
                                    </span>
                                    <div
                                      className={`${
                                        index == 0
                                          ? 'bcg-grey'
                                          : index == 1
                                          ? 'bg-primary'
                                          : 'bg-primary'
                                      } gap-3 sayit-ques-ans-box-2`}
                                    >
                                      <div
                                        className='noselect d-flex'
                                        onClick={() =>
                                          index != 0 && onAudioPlay()
                                        }
                                      >
                                        <div className='d-flex align-items-center'>
                                          {index != 0 && (
                                            <span>
                                              {playButtonShow ? (
                                                <button
                                                  id='Mouse'
                                                  value='Mouse'
                                                  type='button'
                                                  className='bg-white sayit-ques-ans-btn'
                                                  onClick={onPronounceStop}
                                                >
                                                  <BsStopFill size={25} />{' '}
                                                </button>
                                              ) : (
                                                <button
                                                  id='Mouse'
                                                  value='Mouse'
                                                  type='button'
                                                  className='bg-white sayit-ques-ans-btn'
                                                  onClick={onPronounce}
                                                >
                                                  <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='25'
                                                    fill='currentColor'
                                                    className='bi bi-volume-up'
                                                    viewBox='0 0 16 16'
                                                  >
                                                    <path d='M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
                                                    <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z' />
                                                    <path d='M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z' />
                                                  </svg>
                                                </button>
                                              )}
                                              <audio
                                                controls
                                                id='sayit-audio-title'
                                                preload='auto'
                                                hidden
                                              >
                                                <source
                                                  src={
                                                    langAnswerData?.Audio_Answer
                                                  }
                                                />
                                              </audio>
                                            </span>
                                          )}
                                        </div>
                                        <div className='sayit-ques-ans-text text-white'>
                                          {langQuestionDataTarget?.Title1}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='col-10 mx-auto'>
                              <div className='w-100 z-10 d-grid gap-1 text-black'>
                                <div className='title-box'>
                                  <div className='d-flex gap-2 text-center justify-content-between align-items-center'>
                                    <div className='volume_logo_label'>
                                      <button
                                        id='Mouse'
                                        value='Mouse'
                                        type='button'
                                        className='bg-white'
                                        onClick={() =>
                                          onPronounceMain('pronounceAudio')
                                        }
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='25'
                                          height='25'
                                          fill='#000000'
                                          className='bi bi-volume-up'
                                          viewBox='0 0 16 16'
                                        >
                                          <path d='M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
                                          <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z' />
                                          <path d='M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z' />
                                        </svg>
                                      </button>
                                      <audio
                                        controls
                                        id='audio-play'
                                        preload='auto'
                                        hidden
                                      >
                                        <source
                                          src={langAnswerData?.Audio_Answer}
                                        />
                                      </audio>
                                    </div>
                                    <div>
                                      {checkTargetLanguage ? (
                                        <div>
                                          <h3>
                                            {
                                              langQuestionDataTarget?.Title_Roman_Language
                                            }
                                          </h3>
                                          <h4>
                                            {langQuestionDataTarget?.Title}
                                          </h4>
                                        </div>
                                      ) : (
                                        <h1>{langQuestionDataTarget?.Title}</h1>
                                      )}
                                    </div>
                                    <div>{langQuestionDataTarget?.Type}</div>
                                  </div>
                                  <div>
                                    <div className='text-center'>
                                      {langQuestionDataTarget?.Description}
                                    </div>
                                  </div>
                                </div>
                                <div className='image-box-center'>
                                  <div className='main_img'>
                                    <img
                                      src={langQuestionData?.Image}
                                      alt='Hope City School'
                                      className='question-img'
                                    />
                                    <div className='Confetti-div'>
                                      <Confetti
                                        active={animationShow}
                                        config={config}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className='sayit-play-stop-box'>
                            <div className='hold-record-text'>
                              {tapToRecord && <span>Tap to record voice</span>}
                              {reTapToRecord && (
                                <span>Re-Tap to record voice</span>
                              )}
                            </div>
                            <div className='col-12 mx-auto'>
                              {audioWave ? (
                                <div className='sayit-stop-btn'>
                                  <button
                                    onClick={() => {
                                      OnStopRecording();
                                    }}
                                  >
                                    <IoStop size={40} color={'white'} />
                                  </button>
                                </div>
                              ) : (
                                <div className='sayit-play-btn'>
                                  <button
                                    onClick={() => {
                                      listenContinuously(index, questionAnswer);
                                    }}
                                  >
                                    <BsMicFill size={40} color={'white'} />
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className='text-center'>
                              {/* {interimTranscript} */}
                              {interTranscript}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <FooterActivityPage
                  nextButton={nextButtonShow}
                  previousButton={true}
                  onPreviousButton={onPreviousButton}
                  handleVideoNextButton={handleVideoNextButton}
                  urlHash={result?.Question_ID || undefined}
                  lesson={singleLessonData?._id || undefined}
                />
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default SayItActivity;
