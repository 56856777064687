import LoaderContent from 'app/components/ContentLoader';
import LoaderNew from 'app/components/LoaderNew';
import usePercentageCounter from 'hooks/Calculate';
import { useDeleteQuery, useGetQuery } from 'hooks/reactQueryHelper';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Swal from 'sweetalert2';

const Grades = ({ getCourseByCourseInvitedIdQuery }: any) => {
  const { courseInviteId } = useParams();
  const navigate = useNavigate();
  const [dueDate, setDueDate] = useState<any>();
  const [dueDatePoint, setDueDatePoint] = useState<any>();
  const [diplomaPoint, setDiplomaPoint] = useState<any>();

  const getAssignmentByCourseInviteId: any = useGetQuery(
    'getAssignmentByCourseInviteId',
    `/course/getAssignmentByCourseInviteId`
  );

  const getGradesPercentagePointsByCourseQuery: any = useGetQuery(
    'getGradesPercentagePointsByCourseQuery',
    `/course/getGradesPercentagePointsByCourse`
  );
  const deleteEnrolledCourseQuery: any = useDeleteQuery(
    'deleteEnrolledCourse',
    '/course/enrolled/delete'
  );

  const getGradesPercentagePointsByDiplomaCourseQuery = useGetQuery(
    'getGradesPercentagePointsByDiplomaCourseQuery',
    `/course/getGradesPercentagePointsByDiplomaCourse`
  );

  useEffect(() => {
    getGradesPercentagePointsByCourseQuery.mutateAsync({
      params: { id: courseInviteId },
    });
    getGradesPercentagePointsByDiplomaCourseQuery.mutateAsync({
      params: { id: courseInviteId },
    });
    getAssignmentByCourseInviteId.mutateAsync({
      params: { id: courseInviteId },
    });
  }, []);

  console.log(
    'getGradesPercentagePointsByDiplomaCourseQuery',
    getGradesPercentagePointsByDiplomaCourseQuery
  );

  useEffect(() => {
    if (getGradesPercentagePointsByDiplomaCourseQuery?.isSuccess) {
      setDiplomaPoint(
        getGradesPercentagePointsByDiplomaCourseQuery?.data?.ResultObject
      );
    }
  }, [getGradesPercentagePointsByDiplomaCourseQuery?.isSuccess]);

  useEffect(() => {
    if (
      getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course
        ?.dueDates?.length > 0
    ) {
      let date =
        getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course?.dueDates?.find(
          (item: any) =>
            moment(item?.date).isSameOrAfter(
              moment(new Date()).startOf('day'),
              'day'
            )
        )?.date;
      if (
        getCourseByCourseInvitedIdQuery.data.ResultObject.course
          .isDiplomaEnglishCourse === true
      ) {
        const nextMonthLastDate = moment().utc().add(1, 'month').endOf('month');
        date = nextMonthLastDate.format('YYYY-MM-DD');
      }
      let points =
        getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course?.dueDates?.find(
          (item: any) =>
            moment(item?.date).isSameOrAfter(
              moment(new Date()).startOf('day'),
              'day'
            )
        )?.points;
      setDueDate(date ? moment(new Date(date)).format('MM/DD/YYYY') : '');
      setDueDatePoint(points);
    }
  }, [
    getGradesPercentagePointsByCourseQuery?.data?.ResultObject?.course
      ?.dueDates,
  ]);

  useEffect(() => {
    if (deleteEnrolledCourseQuery.isSuccess) {
      Swal.fire(
        'Deactivate Course!',
        'Your Course has been deactivated.',
        'success'
      ).then((result) => {
        navigate('/course');
      });
    }
  }, [deleteEnrolledCourseQuery.isSuccess]);

  const handleConfirmation = () => {
    if (
      getCourseByCourseInvitedIdQuery.data.ResultObject.course
        .isDiplomaEnglishCourse === true
    ) {
      Swal.fire('Warning', 'Please UnEnroll', 'warning');
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Course will be deactivated!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3e97ff',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteEnrolledCourseQuery.mutateAsync({
            params: {
              id: courseInviteId,
            },
          });
        }
      });
    }
  };

  return (
    <div className='row'>
      <LoaderNew loading={deleteEnrolledCourseQuery?.isLoading} isFull={true} />
      <div className='col-12'>
        <div className='row'>
          <div className='col-md-7 col-12 mb-5'>
            <div className='row h-md-100'>
              <div className='col-12 mb-5'>
                <div className='card h-md-100'>
                  <LoaderContent
                    loading={getCourseByCourseInvitedIdQuery.isLoading}
                    loaderType='twoSection'
                  />
                  {getCourseByCourseInvitedIdQuery.isSuccess && (
                    <>
                      <div className='card-header'>
                        <div className='card-title m-0'>
                          <h3 className='fw-bolder text-gray-800'>
                            Course Details
                          </h3>
                        </div>
                        <div className='align-self-center'>
                          <button
                            type='button'
                            className='btn btn-light-danger'
                            onClick={handleConfirmation}
                          >
                            Delete Course
                          </button>
                        </div>
                      </div>
                      <div className='card-body p-9'>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Teacher
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.createdBy?.FirstName
                              }{' '}
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.createdBy?.LastName
                              }
                            </span>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            School
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.schoolName
                              }
                            </span>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Course Name
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.name
                              }
                            </span>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Grade
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.grade
                              }
                            </span>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Target Language
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.targetLanguage
                                  ?.Language_Name
                              }
                            </span>
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Language Level
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getCourseByCourseInvitedIdQuery?.data
                                  ?.ResultObject?.course?.languageLevel
                                  ?.Level_Name
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5 col-12 mb-5'>
            <div className='row h-100 mb-3 mb-xl-3 mb-xxl-0 d-flex align-content-between flex-wrap'>
              <div className='col-12 mb-5'>
                <div className='card'>
                  <LoaderContent
                    loading={getGradesPercentagePointsByCourseQuery.isLoading}
                    loaderType='twoSection'
                  />
                  {getGradesPercentagePointsByCourseQuery.isSuccess && (
                    <>
                      <div className='card-header'>
                        <div className='card-title m-0'>
                          <h3 className='fw-bolder m-0 text-gray-800'>
                            Course Result
                          </h3>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-5'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Course Grade
                          </label>
                          <div className='col-lg-8'>
                            <div
                              className={`${
                                getGradesPercentagePointsByCourseQuery?.data
                                  ?.ResultObject?.grade?.name === 'A'
                                  ? 'badge badge-success border px-5'
                                  : getGradesPercentagePointsByCourseQuery?.data
                                      ?.ResultObject?.grade?.name === 'B'
                                  ? 'badge badge-primary border px-5'
                                  : getGradesPercentagePointsByCourseQuery?.data
                                      ?.ResultObject?.grade?.name === 'C'
                                  ? 'badge badge-warning border px-5'
                                  : getGradesPercentagePointsByCourseQuery?.data
                                      ?.ResultObject?.grade?.name === 'D'
                                  ? 'badge badge-info border px-5'
                                  : getGradesPercentagePointsByCourseQuery?.data
                                      ?.ResultObject?.grade?.name === 'F'
                                  ? 'badge badge-danger border px-5'
                                  : ''
                              } fs-3 fw-bold rounded-pill`}
                            >
                              {getGradesPercentagePointsByCourseQuery?.data
                                ?.ResultObject?.grade?.name === '-'
                                ? 'No grade available'
                                : getGradesPercentagePointsByCourseQuery?.data
                                    ?.ResultObject?.grade?.name}
                            </div>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Percentage
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {
                                getGradesPercentagePointsByCourseQuery?.data
                                  ?.ResultObject?.averagePercentage
                              }
                              %
                            </span>
                          </div>
                        </div>
                        <div className='row mb-6'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Points Completed
                          </label>
                          <div className='col-lg-8'>
                            <div className='d-flex flex-row w-100 me-2'>
                              <div className='d-flex flex-fill flex-stack'>
                                <span className='me-2 fw-bolder fs-6 text-dark'>
                                  {getCourseByCourseInvitedIdQuery?.data
                                    ?.ResultObject?.course
                                    ?.isDiplomaEnglishCourse === true
                                    ? diplomaPoint?.totalPointSum
                                    : getGradesPercentagePointsByCourseQuery
                                        ?.data?.ResultObject
                                        ?.totalPointSum}{' '}
                                  of{' '}
                                  {
                                    getGradesPercentagePointsByCourseQuery?.data
                                      ?.ResultObject?.course
                                      ?.pointsNeededToCompleteCourse
                                  }
                                </span>
                              </div>
                              <div className='progress flex-fill align-self-center h-10px w-75'>
                                <div
                                  className='progress-bar bg-info'
                                  role='progressbar'
                                  style={{
                                    width: `${usePercentageCounter(
                                      Number(
                                        getGradesPercentagePointsByCourseQuery
                                          ?.data?.ResultObject?.totalPointSum
                                      ),
                                      Number(dueDatePoint)
                                    )}%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <label className='col-lg-4 fs-6 fw-bold text-gray-600'>
                            Due Date
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {getCourseByCourseInvitedIdQuery?.data
                                ?.ResultObject?.course
                                ?.isDiplomaEnglishCourse === true ? (
                                <div className='badge badge-light fs-5 fw-bold border rounded-pill px-5'>
                                  {diplomaPoint?.deadlineDate}
                                </div>
                              ) : (
                                <div>Ongoing</div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='col-12 mb-5'>
                <div className='card'>
                  <LoaderContent
                    loading={getCourseByCourseInvitedIdQuery.isLoading}
                  />
                  {getCourseByCourseInvitedIdQuery?.isSuccess && (
                    <>
                      <div className='card-header'>
                        <div className='card-title m-0'>
                          <h3 className='fw-bolder m-0 text-gray-800'>
                            Grading Scale
                          </h3>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='d-flex align-items-center mb-5'>
                          <span className='bullet bullet-vertical h-25px bg-success'></span>
                          <div className='flex-grow-1 mx-5'>
                            <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              A
                            </div>
                          </div>
                          <span className='badge badge-light-success fs-6 fw-bold'>
                            {getCourseByCourseInvitedIdQuery?.isSuccess && (
                              <>
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'A'
                                    )?.min
                                  }
                                </>
                                {' - '}
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'A'
                                    )?.max
                                  }
                                  %
                                </>
                              </>
                            )}
                          </span>
                        </div>
                        {/* // Grade B */}
                        <div className='d-flex align-items-center mb-5'>
                          <span className='bullet bullet-vertical h-25px bg-primary'></span>
                          <div className='flex-grow-1 mx-5'>
                            <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              B
                            </div>
                          </div>
                          <span className='badge badge-light-primary fs-6 fw-bold'>
                            {getCourseByCourseInvitedIdQuery?.isSuccess && (
                              <>
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'B'
                                    )?.min
                                  }
                                  %
                                </>
                                {' - '}
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'B'
                                    )?.max
                                  }
                                  %
                                </>
                              </>
                            )}
                          </span>
                        </div>
                        {/* // Grade C */}
                        <div className='d-flex align-items-center mb-5'>
                          <span className='bullet bullet-vertical h-25px bg-warning'></span>
                          <div className='flex-grow-1 mx-5'>
                            <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              C
                            </div>
                          </div>
                          <span className='badge badge-light-warning fs-6 fw-bold'>
                            {getCourseByCourseInvitedIdQuery?.isSuccess && (
                              <>
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'C'
                                    )?.min
                                  }
                                  %
                                </>
                                {' - '}
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'C'
                                    )?.max
                                  }
                                  %
                                </>
                              </>
                            )}
                          </span>
                        </div>
                        {/* // Grade D */}
                        <div className='d-flex align-items-center mb-5'>
                          <span className='bullet bullet-vertical h-25px bg-info'></span>
                          <div className='flex-grow-1 mx-5'>
                            <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              D
                            </div>
                          </div>
                          <span className='badge badge-light-info fs-6 fw-bold'>
                            {getCourseByCourseInvitedIdQuery?.isSuccess && (
                              <>
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'D'
                                    )?.min
                                  }
                                  %
                                </>
                                {' - '}
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'D'
                                    )?.max
                                  }
                                  %
                                </>
                              </>
                            )}
                          </span>
                        </div>
                        {/* // Grade F */}
                        <div className='d-flex align-items-center mb-0'>
                          <span className='bullet bullet-vertical h-25px bg-danger'></span>
                          <div className='flex-grow-1 mx-5'>
                            <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              F
                            </div>
                          </div>
                          <span className='badge badge-light-danger fs-6 fw-bold'>
                            {' '}
                            {getCourseByCourseInvitedIdQuery?.isSuccess && (
                              <>
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'F'
                                    )?.min
                                  }
                                  %
                                </>
                                {' - '}
                                <>
                                  {
                                    getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric?.find(
                                      (item: any) => item?.name == 'F'
                                    )?.max
                                  }
                                  %
                                </>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 mb-3'>
        <div className='card mb-5'>
          <div className='card-header border-0 cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-gray-800'>
                Assignment Details
              </h3>
            </div>
          </div>
          <div className='card-body border-top px-9 pt-3 pb-4'>
            <LoaderContent
              loading={getAssignmentByCourseInviteId.isLoading}
              loaderType='tableSection'
            />
            {getAssignmentByCourseInviteId.isSuccess && (
              <>
                <div className='table-responsive'>
                  <table className='table table-row-dashed border-gray-300 align-middle gy-3'>
                    <thead>
                      <tr className='fw-bold fs-5 text-gray-800'>
                        <th>Date</th>
                        <th>Points</th>
                        <th>Grade</th>
                        <th>Type</th>
                        <th>Assignment</th>
                      </tr>
                    </thead>
                    <tbody className='fs-6'>
                      {getAssignmentByCourseInviteId?.data?.ResultObject?.map(
                        (item: any) => (
                          <>
                            <tr>
                              <td>
                                {moment(item?.submittedDate).format(
                                  'MM/DD/YYYY'
                                )}
                              </td>
                              <td>{item?.point}</td>
                              <td>{item?.percentage}%</td>
                              <td>
                                {item?.lessonId?.Lesson_Name && <>Lesson</>}
                                {item?.liveClass?.name && <>Live class</>}
                                {item?.instantClass?.videoName && (
                                  <>Instant class</>
                                )}
                              </td>
                              <td>
                                {item?.lessonId?.Lesson_Name && (
                                  <span title={item?.lessonId?.Lesson_Name}>
                                    {item?.lessonId?.Lesson_Name}
                                    <a
                                      href={`/course/${courseInviteId}/start-lesson/${item?.lessonId?._id}`}
                                    >
                                      {' '}
                                      Redo
                                    </a>
                                  </span>
                                )}

                                {item?.liveClass?.name && (
                                  <span title={item?.liveClass?.name}>
                                    {item?.liveClass?.name}
                                  </span>
                                )}

                                {item?.instantClass?.videoName && (
                                  <span title={item?.instantClass?.videoName}>
                                    {item?.instantClass?.videoName}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                      {getAssignmentByCourseInviteId.isSuccess &&
                        getAssignmentByCourseInviteId?.data?.ResultObject
                          ?.length === 0 && (
                          <tr>
                            <td colSpan={99} className='text-center'>
                              No data available
                            </td>
                          </tr>
                        )}
                      {getAssignmentByCourseInviteId.isLoading && (
                        <tr>
                          <td colSpan={99} className='text-center'>
                            Loading...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grades;
