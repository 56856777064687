import React from 'react';
import { Form as BForm } from 'react-bootstrap';
import { useFormikContext, ErrorMessage } from 'formik';
import useIntlCustom from 'hooks/useIntlCustom';

const Step2 = () => {
  const { formatMessage } = useIntlCustom();
  const {
    errors,
    touched,
    values,
    submitForm,
    getFieldProps,
    handleChange,
    handleBlur,
  }: any = useFormikContext();
  return (
    <BForm.Group className='mb-7'>
      <BForm.Label className='fw-bold fs-2'>
        {formatMessage({
          id: 'TEACHER.COURSE_NAME',
        })}
        {/* Course Name */}
        <span style={{ color: 'red' }}>*</span>
      </BForm.Label>
      <BForm.Control
        type='text'
        placeholder={formatMessage({
          id: 'TEACHER.COURSE_NAME.PLACEHOLDER',
        })}
        {...getFieldProps('courseName')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`rounded-1 form-control-sm mb-3 mb-lg-0 ${
          errors.courseName && touched.courseName ? 'border border-danger' : ''
        }`}
      />
      <div className='form-text m-0'>ie. English 101 (5th grade)</div>
      {touched.courseName && errors.courseName && (
        <BForm.Text className='text-danger'>
          <ErrorMessage name='courseName' />
        </BForm.Text>
      )}
    </BForm.Group>
  );
};

export default Step2;
