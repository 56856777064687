import React from 'react';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_16 = (props: any) => {
  const { formik, hasFieldError, removeHightlight } = props;
  return (
    <>
      <div
        className={
          hasFieldError?.includes('Finish_High_School_Year_Question')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fd-bolder'>
          Is your student a senior and finishing high school this year? ¿Su
          estudiante es un estudiante de último año y está terminando la escuela
          secundaria este año? <span className='text-danger'> * </span>
        </label>
        <div className='form-check mt-2'>
          <RadioButtonComponents
            formik={formik}
            fieldName={'Finish_High_School_Year_Question'}
            dataValue={'Yes'}
            onChange={() => {
              removeHightlight('Finish_High_School_Year_Question');
            }}
          />
          <label className='form-label fw-normal'>
            YES, my student is a senior and will finish high school this year.
            SÍ, mi estudiante es un estudiante de último año y terminará la
            escuela secundaria este año.
          </label>
        </div>
        <div className='form-check'>
          <RadioButtonComponents
            formik={formik}
            fieldName={'Finish_High_School_Year_Question'}
            dataValue={'No'}
            onChange={() => {
              removeHightlight('Finish_High_School_Year_Question');
            }}
          />
          <label className='form-label fw-normal'>
            NO, my student is not a senior. NO, mi estudiante no es un
            estudiante de último año.
          </label>
        </div>
        <FieldsErrorText
          touched={formik.touched.Finish_High_School_Year_Question}
          errors={formik.errors.Finish_High_School_Year_Question}
        />
      </div>
    </>
  );
};

export default Section7_12_16;
