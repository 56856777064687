import LoaderContent from 'app/components/ContentLoader';
import { useCountdown } from 'hooks/useCountDown';
import useOperatingSystem from 'hooks/useOperatingSystem';
import {
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { toAbsoluteUrl } from 'theme/helpers';
import RequiredCompletedLessonNotify from '../../../../components/Course/RequiredCompletedLessonNotify';
import OpenWaitingRoomForIOS from '../../../../components/Course/OpenWaitingRoomForIOS';
import createNotification from 'helpers/alert';
import { useAuth } from 'app/modules/auth';
import { getJwtToken } from 'hooks/tokenHook';
import { useNavigate } from 'react-router-dom';
import { useGetQuery } from 'hooks/reactQueryHelper';
import moment from 'moment';
import useIntlCustom from 'hooks/useIntlCustom';

const ScheduleLiveClass = ({
  getScheduleLiveClassByCourse,
  getGradesPercentagePointsByCourseQuery,
  courseInviteId,
}: any) => {
  const { formatMessage } = useIntlCustom();
  const navigate = useNavigate();
  const { authSubscription } = useAuth();
  let os = useOperatingSystem();
  const [
    modalRequiredCompletedLessonNotify,
    setModalRequiredCompletedLessonNotify,
  ] = useState({
    show: false,
    msg: null,
  });

  const [modalOpenWaitingRoomForIOS, setModalOpenWaitingRoomForIOS] = useState({
    show: false,
    link: null,
  });

  const [liveClassesAttended, setLiveClassesAttended] = useState(0);

  const getCompletedLiveClasses: any = useGetQuery(
    'getCompletedLiveClasses',
    `/course/getCompletedLiveClasses`
  );
  useEffect(() => {
    getCompletedLiveClasses.mutateAsync({
      params: {
        id: courseInviteId,
      },
    });

    if (getCompletedLiveClasses.isSuccess) {
      const attendedClassesThisWeek =
        getCompletedLiveClasses.data?.ResultObject?.filter((ele: any) =>
          moment(ele?.submittedDate).isSameOrAfter(moment().startOf('week'))
        );

      setLiveClassesAttended(attendedClassesThisWeek.length);
    }
  }, []);

  const generateWaitingUrl = (liveClassRoomId: any) => {
    let protocol = window.location.protocol;
    let host = window.location.host;
    return `${protocol}//${host}/authentication/waitingRoom/${getJwtToken()}?courseInviteId=${courseInviteId}&liveClassRoomId=${liveClassRoomId}`;
  };

  const handleJoinClass: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (getScheduleLiveClassByCourse?.data?.ResultObject?.isRestricted) {
      setModalRequiredCompletedLessonNotify((prev): any => ({
        ...prev,
        show: true,
        msg: formatMessage({
          id:
            Number(
              getScheduleLiveClassByCourse?.data?.ResultObject
                ?.requiredLessonForLiveClass
            ) === Number(1)
              ? 'COURSE.REQUIRED_LESSON.POINT.MSG'
              : 'COURSE.REQUIRED_LESSON.POINTS.MSG',
          values: {
            param1:
              getScheduleLiveClassByCourse?.data?.ResultObject
                ?.requiredLessonForLiveClass,
          },
        }),
      }));
    } else if (
      authSubscription?.isPremium === false &&
      liveClassesAttended >=
        getGradesPercentagePointsByCourseQuery?.data?.ResultObject
          ?.freeLiveClass
    ) {
      createNotification(
        'warning',
        `You have reached your limit for live classes this week with a free
          account. Check back next week or upgrade to premium to attend more
          classes.`
      );
    } else {
      if (os === 'iOS') {
        let url = generateWaitingUrl(
          getScheduleLiveClassByCourse?.data?.ResultObject?.liveClassRoomId
        );

        setModalOpenWaitingRoomForIOS((prev): any => ({
          ...prev,
          show: true,
          link: url,
        }));
      } else if (os === 'Android') {
        let url = generateWaitingUrl(
          getScheduleLiveClassByCourse?.data?.ResultObject?.liveClassRoomId
        );
        // window.open(`intent:${url}#Intent;end`);
        window.location.href = `intent:${url}#Intent;end`;
      } else {
        navigate(
          `/course/${courseInviteId}/waiting-class-room/${getScheduleLiveClassByCourse?.data?.ResultObject?.liveClassRoomId}`
        );
      }
    }
  };

  const onModalRequiredCompletedLessonNotifyHide = useCallback(() => {
    setModalRequiredCompletedLessonNotify((prev): any => ({
      ...prev,
      show: false,
      msg: null,
    }));
  }, []);

  const onModalOpenWaitingRoomForIOSHide = useCallback(() => {
    setModalOpenWaitingRoomForIOS((prev): any => ({
      ...prev,
      show: false,
      link: null,
    }));
  }, []);

  const EndClassRoomCountdownTimer = ({ startTime, endTime }: any) => {
    const [days, hours, minutes, seconds] = useCountdown(new Date(endTime));
    return (
      <>
        {days + hours + minutes + seconds <= 0 ? (
          <div className='card-title text-center py-5 text-light'>
            {formatMessage({
              id: 'COURSE.CLASS_HAS_BEEN_ENDED',
            })}
          </div>
        ) : (
          <StartCountdownTimer startTime={startTime} />
        )}
      </>
    );
  };

  const StartCountdownTimer = ({ startTime }: any) => {
    const [days, hours, minutes, seconds] = useCountdown(new Date(startTime));

    return (
      <>
        <div className='row justify-content-center gap-2 w-100'>
          <div className='text-light pt-1 fw-semibold fs-6 text-center'>
            {days + hours + minutes + seconds <= 0 && (
              <div className='col-12 p-0'>
                <span className='d-block text-white fw-semibold text-center'>
                  {formatMessage({
                    id: 'COURSE.CLASS_HAS_BEEN_STARTED',
                  })}
                </span>
              </div>
            )}
          </div>
          {days > 0 && (
            <>
              <div className='col-2 p-0'>
                <label className='rounded border border-outline border-dashed d-flex align-items-center justify-content-center p-2'>
                  <span className='d-block fw-semibold text-center'>
                    <span className='text-light fw-bold d-block fs-6 mb-2'>
                      {days}
                    </span>
                    <span className='fw-semibold fs-8 text-light'>
                      {formatMessage({
                        id: 'COURSE.DAYS',
                      })}
                    </span>
                  </span>
                </label>
              </div>
            </>
          )}
          {hours > 0 && (
            <>
              <div className='col-2 p-0'>
                <label className='rounded border border-outline border-dashed d-flex align-items-center justify-content-center p-2'>
                  <span className='d-block fw-semibold text-center'>
                    <span className='text-light fw-bold d-block fs-6 mb-2'>
                      {hours}
                    </span>
                    <span className='fw-semibold fs-8 text-light'>
                      {formatMessage({
                        id: 'COURSE.HOURS',
                      })}
                    </span>
                  </span>
                </label>
              </div>
            </>
          )}
          {minutes > 0 && (
            <>
              <div className='col-2 p-0'>
                <label className='rounded border border-outline border-dashed d-flex align-items-center justify-content-center p-2'>
                  <span className='d-block fw-semibold text-center'>
                    <span className='text-light fw-bold d-block fs-6 mb-2'>
                      {minutes}
                    </span>
                    <span className='text-light fw-semibold fs-8'>
                      {formatMessage({
                        id: 'COURSE.MINUTES',
                      })}
                    </span>
                  </span>
                </label>
              </div>
            </>
          )}
          {seconds > 0 && (
            <div className='col-2 p-0'>
              <label className='rounded border border-outline border-dashed d-flex align-items-center justify-content-center p-2'>
                <span className='d-block fw-semibold text-center'>
                  <span className='text-light fw-bold d-block fs-6 mb-2'>
                    {seconds}
                  </span>
                  <span className='text-light fw-semibold fs-8'>
                    {formatMessage({
                      id: 'COURSE.SECONDS',
                    })}
                  </span>
                </span>
              </label>
            </div>
          )}
        </div>
        <div className='text-center w-100 py-5'>
          <button
            type='button'
            className='btn btn-light btn-sm text-primary'
            onClick={handleJoinClass}
          >
            {formatMessage({
              id: 'COURSE.JOIN_THE_WAITING_ROOM',
            })}
          </button>
        </div>
      </>
    );
  };
  return (
    <div className='card card-flush shadow h-100 '>
      {modalRequiredCompletedLessonNotify.show && (
        <RequiredCompletedLessonNotify
          show={modalRequiredCompletedLessonNotify.show}
          onHide={onModalRequiredCompletedLessonNotifyHide}
          msg={modalRequiredCompletedLessonNotify.msg}
        />
      )}
      {modalOpenWaitingRoomForIOS.show && (
        <OpenWaitingRoomForIOS
          show={modalOpenWaitingRoomForIOS.show}
          onHide={onModalOpenWaitingRoomForIOSHide}
          link={modalOpenWaitingRoomForIOS.link}
        />
      )}
      <LoaderContent
        loading={getScheduleLiveClassByCourse?.isLoading}
        loaderType='imageSection'
      />
      {getScheduleLiveClassByCourse?.isSuccess &&
        getScheduleLiveClassByCourse?.data?.ResultObject?.liveClass && (
          <div
            className='bgi-size-cover bgi-position-center rounded h-100 p-3'
            style={{
              backgroundImage: `url("/media/misc/auth-bg.png")`,
            }}
          >
            <div className='d-flex flex-column justify-content-around align-items-center h-100 w-100 gap-5'>
              <div className='fw-bold fs-3 text-center text-light'>
                Clase en Vivo
              </div>
              <EndClassRoomCountdownTimer
                startTime={
                  getScheduleLiveClassByCourse?.data?.ResultObject
                    ?.scheduleDateTime
                }
                endTime={
                  getScheduleLiveClassByCourse?.data?.ResultObject?.endDateTime
                }
              />
            </div>
          </div>
        )}
      {getScheduleLiveClassByCourse?.isSuccess &&
        (!getScheduleLiveClassByCourse?.data?.ResultObject?.liveClass ||
          !getScheduleLiveClassByCourse?.data?.ResultObject
            ?.liveClassContent) && (
          <>
            <div className='card-body'>
              <div className='d-flex align-items-center justify-center h-100'>
                <div className='text-center w-100'>
                  <img
                    src={toAbsoluteUrl('/media/images/NoLiveClassSchedule.jpg')}
                    className='mh-125px mh-md-300px img-fluid'
                    alt={formatMessage({
                      id: 'COURSE.LIVE_CLASS_NOT_AVAILABLE_MESSAGE',
                    })}
                  />
                  <div className='w-100 text-center mx-auto mt-10'>
                    <h3 className='lh-lg'>
                      {formatMessage({
                        id: 'COURSE.LIVE_CLASS_NOT_AVAILABLE_MESSAGE',
                      })}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default memo(ScheduleLiveClass);
