import React, { FC, useState } from 'react';
import { PageTitle } from '../../../theme/layout/core';
import ClassRoom from './ClassRoom';

const ClassRoomWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Aula</PageTitle>
      <ClassRoom />
    </>
  );
};

export default ClassRoomWrapper;
