import useIntlCustom from 'hooks/useIntlCustom';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';

const RegistrationFormFooter = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const {
    IsBackButton,
    setStep,
    step,
    grade,
    onSaveForm,
    isSubmitButton,
    hasTrueValue,
  } = props;

  const backStep = useMemo(() => {
    return grade == '12th' || grade == '11th' || grade == '10th' ? 1 : 2;
  }, [grade]);

  return (
    <div className='mt-3'>
      {IsBackButton && (
        <Button
          type='button'
          className='btn btn-secondary float-start'
          style={{ marginRight: '10px' }}
          onClick={() => setStep(step - backStep)}
        >
          {formatMessage({
            id: 'BACK',
          })}
        </Button>
      )}

      <Button
        className='float-start btn btn-primary float-end'
        type='submit'
        style={{ marginRight: '10px' }}
        onClick={() => {
          onSaveForm(isSubmitButton ? 'Submit' : 'Next');
        }}
        disabled={hasTrueValue}
      >
        {isSubmitButton
          ? formatMessage({
              id: 'SUBMIT',
            })
          : formatMessage({
              id: 'NEXT',
            })}{' '}
      </Button>
    </div>
  );
};

export default RegistrationFormFooter;
