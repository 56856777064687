import React, { FC, useState } from 'react';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import FooterDropdown from './FooterDropdown';

const FooterActivityPage: React.FC<any> = (props: any) => {
  const {
    nextButton,
    onPreviousButton,
    handleVideoNextButton,
    previousButton,
    urlHash,
    lesson,
    showImageActivity,
  } = props;

  return (
    <>
      <div
        className={
          showImageActivity
            ? 'footer-bottom-show-image-activity'
            : 'footer-bottom-activity'
        }
      >
        {previousButton && (
          <div className='prev-button-activity'>
            <button onClick={onPreviousButton} className='btn p-1 me-3'>
              <AiFillLeftCircle size={33} color={'white'} />
            </button>
          </div>
        )}
        <div
          className={`dropup ${
            previousButton ? 'flag-button-activity' : 'flag-button-home'
          }`}
        >
          {/* <div className='dropup flag-button-activity'> */}
          <FooterDropdown urlHash={urlHash} lesson={lesson} />
        </div>

        {nextButton && (
          <div className='ms-auto'>
            <button
              className='btn btn-primary p-2 rounded-pill'
              onClick={() => handleVideoNextButton()}
            >
              Next <AiFillRightCircle size={25} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FooterActivityPage;
