import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { usePostQuery } from 'hooks/reactQueryHelper';
import useIntlCustom from 'hooks/useIntlCustom';
import useToaster from 'hooks/useToaster';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'theme/helpers';

export function ForgotPassword() {
  const navigate = useNavigate();
  const { successToaster, warningToaster } = useToaster();
  const { formatMessage } = useIntlCustom();

  const initialValues = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is incorrect')
      .required('Please enter email.'),
  });

  const forgotPasswordQuery: any = usePostQuery(
    'forgotPasswordQuery',
    `/forgot-password`
  );

  console.log('forgotPasswordQuery', forgotPasswordQuery);

  const formik: any = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      forgotPasswordQuery.mutateAsync({
        email: values.email,
      });
    },
  });

  useEffect(() => {
    if (forgotPasswordQuery.isSuccess && !!forgotPasswordQuery?.data?.ok) {
      successToaster(forgotPasswordQuery?.data?.message);
    }

    if (forgotPasswordQuery.isSuccess && !!!forgotPasswordQuery?.data?.ok) {
      warningToaster(forgotPasswordQuery?.data?.message);
    }
  }, [forgotPasswordQuery.isSuccess]);

  if (forgotPasswordQuery.isSuccess && !!forgotPasswordQuery?.data?.ok) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <div className='fw-bold fs-1 p-5 text-center'>
          <img
            src={toAbsoluteUrl('/media/svg/submit-successfully.svg')}
            alt='Edge'
            className='h-100px w-100px'
          />
          <div className='mt-2'>{forgotPasswordQuery?.data?.message}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>
            {formatMessage({
              id: 'AUTH.FORGOT.PASSWORD',
            })}
            ?
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {formatMessage({
              id: 'AUTH.FORGOT.PASSWORD.SUB_TITLE',
            })}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-7'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
            {formatMessage({
              id: 'EMAIL',
            })}
          </label>
          <input
            type='email'
            placeholder='Enter your email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-stack flex-row-reverse'>
          <button
            type='submit'
            className='btn btn-primary me-2 flex-shrink-0 w-100'
            disabled={!!forgotPasswordQuery.isLoading}
          >
            {!forgotPasswordQuery.isLoading && (
              <span className='indicator-label'>
                {formatMessage({
                  id: 'AUTH.FORGOT.PASSWORD',
                })}
              </span>
            )}
            {forgotPasswordQuery.isLoading && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </button>
        </div>

        {/* end::Form group */}
      </form>
      <div className='d-flex flex-stack justify-content-center p-2'>
        <Link to='/login2'>
          {formatMessage({
            id: 'AUTH.BACK.LOGIN',
          })}
        </Link>
      </div>
    </>
  );
}
