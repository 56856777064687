import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import {
  studentDeleteFailure,
  studentDeleteSuccess,
  studentGetFailure,
  studentGetSuccess,
  studentGetByIdSuccess,
  studentGetByIdFailure,
} from './actions';
import { STUDENT_DELETE, STUDENT_GET, STUDENT_GET_BY_ID } from './actionTypes';
import { get, post } from '../../helpers/api-helper';
import axios from 'axios';

const studentGetListRequest = (data: any) => get('user/studentgetall', data);
const studentDeleterequest = (data: any) => post('user/usersdelete', data);
const studentGetByIdRequest = (data: any) => post('user/studentgetbyid', data);

function* studentGetListSaga(action: any) {
  try {
    const response: {
      token: string;
      RoleID: string;
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentGetListRequest, {});
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentGetFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentGetSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(studentGetFailure({ error: error }));
  }
}

function* studentDeleteSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentDeleterequest, {
      userId: action.payload.userID,
      studentId: action.payload.studentId,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentDeleteFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentDeleteSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(studentDeleteFailure({ error: error }));
  }
}

function* studentGetByIdSaga(action: any) {
  try {
    const response: {
      ResultCode: string;
      ResultMessage: string;
      ResultObject: any;
    } = yield call(studentGetByIdRequest, {
      userId: action.payload.userID,
      studentId: action.payload.studentId,
    });
    if (response.ResultCode !== 'SUCCESS') {
      yield put(studentGetByIdFailure({ error: response.ResultMessage }));
    } else {
      yield put(studentGetByIdSuccess({ success: response.ResultObject }));
    }
  } catch (error: any) {
    yield put(studentGetByIdFailure({ error: error }));
  }
}

function* authSaga() {
  yield all([takeLatest(STUDENT_GET, studentGetListSaga)]);
  yield all([takeLatest(STUDENT_DELETE, studentDeleteSaga)]);
  yield all([takeEvery(STUDENT_GET_BY_ID, studentGetByIdSaga)]);
}

export default authSaga;
