import { DateTime } from 'luxon';

export type FormattedLesson = FormattedData<
  Lesson,
  { relativeDuration: string }
>;

export function format(lesson: any): FormattedLesson {
  return {
    _original: lesson,
    id: lesson?._id as string,
    name: (lesson?.Lesson_Name || '-') as string,
    level: (lesson?.Lesson_Level || '-') as string,
    description: (lesson?.Lesson_Decsription || '-') as string,
    relativeDuration: DateTime.fromISO(lesson.createdAt).toRelative() || '',
  };
}

export function formatList(list: any[]) {
  return list.map(format);
}
