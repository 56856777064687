import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../theme/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../theme/assets/ts/_utils';
import { WithChildren } from '../../theme/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import StudentsPageWrapper from '../pages/students/StudentsPageWrapper';

import StartLessonPageWrapper from '../pages/lesson/StartLessonPageWrapper';
import { SocialChannelWrapper } from '../pages/social-channel/SocialChannelWrapper';
import ClassRoomWrapperNew from '../pages/classroomNew/ClassRoomWrapper';
import { LiveClass } from '../pages/liveclass/LiveClass';
import AddNewClassWrapper from 'app/pages/AddNewClass/AddNewClassWrapper';
import CourseDetails from 'app/pages/teacher/CourseDetails';
import StudentCourse from 'app/pages/course/Student';
import TeacherWrapper from 'app/pages/teacher/TeacherWrapper';
// import Course from 'app/pages/course';
import EnrollmentWrapper from 'app/pages/enrollment/EnrollmentWrapper';
import GradeSubmissionFormK7_12 from 'app/pages/enrollmentgradesubmission/GradeSubmissionFormK7_12';
import GradeSubmissionFormK6 from 'app/pages/enrollmentgradesubmission/GradeSubmissionFormK6';
import UnEnrollmentForm from 'app/pages/enrollment/UnEnrollmentForm';
import ContactUsWrapper from 'app/pages/contactUs/ContactUsWrapper';
import StartLessonGetById from 'app/pages/lesson/StartLessonGetById';
import SocialChannel from 'app/components/SocialChannel';
import ExplanationForm from 'app/pages/enrollment/ExplanationForm';
import ReEnrollmentForm from 'app/pages/reenrollment/ReEnrollmentForm';
import RegistrationForm from 'app/pages/enrollmentregistartion/registrationform7_12/RegistrationForm';
import Course from 'app/pages/courseNewLayout';
import Grades from 'app/pages/courseNewLayout/Grade';
import WaitingClassRoom from 'app/pages/courseNewLayout/WaitingClassRoom';
import GradeSubmissionForm from 'app/pages/enrollmentgradesubmission/GradeSubmissionForm';
import PrivacyPolicy from 'app/pages/privacy-policy/PrivacyPolicy';

const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));

const DashboardWrapper = lazy(() =>
  import('app/pages/dashboard/DashboardWrapper').then((module) => ({
    default: module.DashboardWrapper,
  }))
);

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='' element={<Navigate to='/dashboard' />} /> */}
        <Route path='' element={<Navigate to='/course' />} />
        {/* <Route path='' element={<Navigate to='/account/overview' />} /> */}
        {/* Pages */}
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <DashboardWrapper />
            </SuspensedView>
          }
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='students' element={<StudentsPageWrapper />} />

        {/* <Route
          path='teacherinvitation'
          element={<TeacherInvitationAccount />}
        /> */}
        <Route path='start-lesson' element={<StartLessonPageWrapper />} />

        <Route
          path='course/:courseInviteId/start-lesson/:id'
          element={<StartLessonGetById />}
        />

        <Route path='social-channel' element={<SocialChannel />} />
        <Route path='live-class' element={<LiveClass />} />
        <Route path='class-room-new' element={<ClassRoomWrapperNew />} />

        <Route path='course' element={<Course />} />

        <Route path='teacher/:courseId/view' element={<CourseDetails />} />
        {/* 
        <Route
          path='course/:courseInviteId/student-course-details'
          element={<StudentCourse />}
        /> */}

        <Route
          path='course/:courseInviteId/student-course-details'
          element={<Grades />}
        />

        <Route
          path='course/:courseInviteId/student-course-details/:tabIndex'
          element={<StudentCourse />}
        />

        <Route path='contact-us' element={<ContactUsWrapper />} />
        <Route path='social-channel-old' element={<SocialChannelWrapper />} />
        <Route path='live-class' element={<LiveClass />} />
        <Route path='add-new-class' element={<AddNewClassWrapper />} />
        {/* <Route
          path='course/:courseInviteId/waiting-class-room/:liveClassRoomId'
          element={<WaitingClassRoom />}
        /> */}

        <Route
          path='course/:courseInviteId/waiting-class-room/:scheduleGoogleMeetId'
          element={<WaitingClassRoom />}
        />

        <Route path='teacher' element={<TeacherWrapper />} />

        {/* <Route path='teacher/course/new' element={<UpsertCourse />} /> */}

        <Route path='enrollment/form' element={<EnrollmentWrapper />} />
        <Route path='enrollment/register' element={<RegistrationForm />} />
        <Route
          path='enrollment/gradesubmission'
          element={<GradeSubmissionForm />}
        />
        <Route
          path='enrollment/grade-submission-formK12'
          element={<GradeSubmissionFormK7_12 />}
        />
        <Route
          path='enrollment/grade-submission-formk6'
          element={<GradeSubmissionFormK6 />}
        />

        <Route path='unenrollment' element={<UnEnrollmentForm />} />
        <Route path='re-enrollment' element={<ReEnrollmentForm />} />
        <Route path='explanation-form' element={<ExplanationForm />} />

        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
