import React from 'react';

const K6_Health_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>HEALTH</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Learning about mental and emotional health, food and nutrition
          </li>
          <li> - Learning about sexual health, violence prevention </li>
          <li>
            - Appreciation, respect for, and care of the human body and its
            vital organs, health misconceptions and myths; effects of exercise
            on the body systems. physical activity, safety and injury
            prevention, sexual health, substance abuse and addictions.
          </li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. A continuación, se muestran algunos ejemplos de
          temas relacionados que podrían aplicarse al requisito:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Aprender sobre salud mental y emocional, alimentación y nutrición
          </li>
          <li>- Aprender sobre salud sexual, prevención de la violencia</li>
          <li>
            - El aprecio, el respeto y el cuidado del cuerpo humano y sus
            órganos vitales, los conceptos erróneos y los mitos sobre la salud;
            Efectos del ejercicio en los sistemas corporales. actividad física,
            seguridad y prevención de lesiones, salud sexual, abuso de
            sustancias y adicciones.
          </li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, desafortunadamente
          no podremos inscribir a su estudiante en Hope City. Sin embargo, si un
          padre o tutor puede enseñarle al estudiante esta materia en casa a
          través de videos de youtube y material en línea, entonces se cumplirá
          con este requisito del curso y podrá inscribirse en Hope City. Por
          favor confírmanos si su escuela o usted puede cumplir con este
          requisito.
        </div>
      </div>
    </div>
  );
};

export default K6_Health_Requirement;
