import { subjectRequirementValue } from 'app/pages/enrollmenthooks/EnrollmentHooks';
import FieldsErrorText from 'app/pages/enrollmenthooks/FieldsErrorText';
import { RadioButtonComponents } from 'hooks/formikFormHook';

const Section7_12_7 = (props: any) => {
  const {
    formik,
    hasFieldError,
    removeHightlight,
    onHandleRquirementSubject,
    formType,
  } = props;
  return (
    <>
      <div
        className={
          hasFieldError?.includes('Health_Requirement_Subject')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          Health / Salud <span className='text-danger'> * </span>
        </label>
        <p className='form-label mb-2'>
          Health Requirements: Including instruction in the principles and
          practices of individual, family, and community health. Examples of
          what you see in Health are: Hands Washing, personal hygiene, keeping
          your house clean, trash treatment, etc.
        </p>
        <p className='form-label mb-2'>
          Requisitos de salud: incluida la instrucción en los principios y
          prácticas de la salud individual, familiar y comunitaria. Ejemplos de
          lo que ves en Salud, aunque no haya una materia llamada Salud, son:
          Lavado de manos, higiene personal, mantener tu casa limpia,
          tratamiento de basura, etc.
        </p>

        <div className='mt-4'>
          {subjectRequirementValue?.map((data: any, index: any) => {
            return (
              <>
                <div className='form-check'>
                  <RadioButtonComponents
                    formik={formik}
                    fieldName={'Health_Requirement_Subject'}
                    dataValue={data?.Value}
                    onChange={() => {
                      removeHightlight('Health_Requirement_Subject');
                      onHandleRquirementSubject('Health_Requirement_Subject', data.Value, formType);
                    }}
                  />
                  <label className='form-label fw-normal'>
                    {data?.Label_English} / {data?.Label_Spanish}
                  </label>
                </div>
              </>
            );
          })}
        </div>
        <FieldsErrorText
          touched={formik.touched.Health_Requirement_Subject}
          errors={formik.errors.Health_Requirement_Subject}
        />
      </div>
    </>
  );
};

export default Section7_12_7;
