import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form } from 'formik';
import { Form as BForm } from 'react-bootstrap';
import ImageUpload from '../../../../../src/app/components/ImageUpload';
import { toAbsoluteUrl } from 'theme/helpers';
import * as Yup from 'yup';
import { getAxiosInstance } from 'helpers/api-helper';
import { useQuery } from 'react-query';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { useAuth } from 'app/modules/auth';
import Loader from 'app/components/Loader';
import TranslateInSpanish from 'app/components/TranslateInSpanish';
import generateBirthYears from 'hooks/useGenerateBirthYears';
import FormikErrorText from 'app/components/palette/FormikErrorText';

const validationSchema = Yup.object({
  FirstName: Yup.string().trim().required('Please enter first name.'),
  LastName: Yup.string().trim().required('Please enter last name.'),
  BirthYear: Yup.string().required('Please select year of birth.'),
  Native_Language: Yup.string().required('Please select native language.'),
  Country: Yup.string().required('Please select country.'),
  Email: Yup.string()
    .required('Please enter email.')
    .email('Please enter valid email address.'),
  ProfilePic: Yup.mixed()
    .nullable()
    .test('fileFormat', 'Please select valid image ', (value) => {
      if (value && value.type) {
        return (
          value &&
          ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
            value.type
          )
        );
      } else {
        return true;
      }
    }),
});
const UPDATE_URL = `/user/updateProfileWithSpecificField`;
const CompleteProfile = (props: any) => {
  const { auth, saveAuth, currentUser, setCurrentUser } = useAuth();
  const updateStudentDataQuery: any = usePostQuery(
    'updateProfileWithSpecificField',
    UPDATE_URL,
    {}
  );

  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');
  let initialValues = {
    FirstName: '',
    LastName: '',
    BirthYear: '',
    Native_Language: '',
    Country: '',
    Email: '',
    ProfilePic: defaultImageUrl,
  };

  const language = useQuery('getLanguage', {
    queryFn: () => getAxiosInstance().post(`/language/getAllForGrid`),
    // enabled: isEditing
  });

  const country = useQuery('getCountry', {
    queryFn: () => getAxiosInstance().get(`/country/getAllCountry`),
    // enabled: isEditing
  });

  const languageList = language?.data?.data?.ResultObject?.data ?? [];
  const countryList = country?.data?.data?.ResultObject ?? [];
  const onSubmit = (values: any) => {
    const formData = new FormData();
    formData.append('FirstName', values?.FirstName);
    formData.append('LastName', values?.LastName);
    formData.append('Email', values?.Email);
    formData.append('ProfilePic', values?.ProfilePic ? values?.ProfilePic : '');

    formData.append('BirthYear', values?.BirthYear);
    formData.append('Native_Language', values?.Native_Language);
    formData.append('Country', values?.Country);

    if (formData) {
      updateStudentDataQuery.mutate(formData);
    }
  };

  useEffect(() => {
    if (updateStudentDataQuery.isSuccess) {
      setCurrentUser(updateStudentDataQuery?.data?.ResultObject);

      let authData = {
        ...auth,
        data: {
          ...auth?.data,
          level: updateStudentDataQuery?.data?.ResultObject?.Level,
          firstName:
            updateStudentDataQuery?.data?.ResultObject?.UserId?.FirstName,
          lastName:
            updateStudentDataQuery?.data?.ResultObject?.UserId?.LastName,
          profilePic:
            updateStudentDataQuery?.data?.ResultObject?.UserId?.ProfilePic,
        },
      };
      saveAuth(authData);
      props.onHide();
    }
  }, [updateStudentDataQuery.isSuccess]);
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      mw-650px
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          getFieldProps,
          handleSubmit,
          setFieldValue,
        }) => {
          useEffect(() => {
            if (currentUser) {
              setFieldValue(
                'FirstName',
                currentUser?.UserId?.FirstName
                  ? currentUser?.UserId?.FirstName
                  : ''
              );
              setFieldValue(
                'LastName',
                currentUser?.UserId?.LastName
                  ? currentUser?.UserId?.LastName
                  : ''
              );
              setFieldValue(
                'Email',
                currentUser?.UserId?.Email ? currentUser?.UserId?.Email : ''
              );
              setFieldValue(
                'BirthYear',
                currentUser?.BirthYear ? currentUser?.BirthYear : ''
              );
              setFieldValue(
                'Native_Language',
                currentUser?.Native_Language?.Language_Name
                  ? currentUser?.Native_Language?._id
                  : ''
              );
              setFieldValue(
                'Country',
                currentUser?.Country?.Country_Name
                  ? currentUser?.Country?._id
                  : ''
              );
              setFieldValue(
                'ProfilePic',
                currentUser?.UserId?.ProfilePic
                  ? currentUser?.UserId?.ProfilePic
                  : ''
              );
            }
          }, [currentUser]);
          return (
            <>
              <Loader loading={updateStudentDataQuery?.isLoading} />
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton className='border-0'>
                  <Modal.Title>Completa Tu Perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row className='justify-content-start'>
                      <Col xs lg='12'>
                        <div className='card-body p-9'>
                          <BForm.Group className='row mb-7'>
                            <BForm.Label className='col-lg-4 fw-bold text-muted'>
                              Nombre de pila
                            </BForm.Label>
                            <div className='col-lg-8'>
                              <>
                                <BForm.Control
                                  {...getFieldProps('FirstName')}
                                  type='text'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                    errors.FirstName && touched.FirstName
                                      ? 'border border-danger'
                                      : ''
                                  }`}
                                />
                                {touched.FirstName && errors.FirstName && (
                                  <BForm.Text className='text-danger'>
                                    <FormikErrorText name='FirstName' />
                                  </BForm.Text>
                                )}
                              </>
                            </div>
                          </BForm.Group>
                          <BForm.Group className='row mb-7'>
                            <BForm.Label className='col-lg-4 fw-bold text-muted'>
                              Apellido
                            </BForm.Label>
                            <div className='col-lg-8'>
                              <BForm.Control
                                {...getFieldProps('LastName')}
                                type='text'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                  errors.LastName && touched.LastName
                                    ? 'border border-danger'
                                    : ''
                                }`}
                              />
                              {touched.LastName && errors.LastName && (
                                <BForm.Text className='text-danger'>
                                  <FormikErrorText name='LastName' />
                                </BForm.Text>
                              )}
                            </div>
                          </BForm.Group>
                          <BForm.Group className='row mb-7'>
                            <BForm.Label className='col-lg-4 fw-bold text-muted'>
                              Año de nacimiento
                            </BForm.Label>
                            <div className='col-lg-8'>
                              <BForm.Control
                                {...getFieldProps('BirthYear')}
                                as='select'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                  errors.BirthYear && touched.BirthYear
                                    ? 'border border-danger'
                                    : ''
                                }`}
                              >
                                <option key={''} value={''}>
                                  {'Select'}
                                </option>
                                {generateBirthYears()?.map(
                                  (val: any, index: any) => (
                                    <option key={index} value={val}>
                                      {val}
                                    </option>
                                  )
                                )}
                              </BForm.Control>
                              {touched.BirthYear && errors.BirthYear && (
                                <BForm.Text className='text-danger'>
                                  <FormikErrorText name='BirthYear' />
                                </BForm.Text>
                              )}
                            </div>
                          </BForm.Group>
                          <BForm.Group className='row mb-7'>
                            <BForm.Label className='col-lg-4 fw-bold text-muted'>
                              Lengua materna
                            </BForm.Label>
                            <div className='col-lg-8'>
                              <BForm.Control
                                {...getFieldProps('Native_Language')}
                                as='select'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                  errors.Native_Language &&
                                  touched.Native_Language
                                    ? 'border border-danger'
                                    : ''
                                }`}
                              >
                                <option key={''} value={''}>
                                  {'Select'}
                                </option>
                                {languageList.map((option: any, index: any) => (
                                  <option
                                    key={Math.random()}
                                    value={option._id}
                                  >
                                    <TranslateInSpanish
                                      text={option.Language_Name}
                                    />
                                  </option>
                                ))}
                              </BForm.Control>
                              {touched.Native_Language &&
                                errors.Native_Language && (
                                  <BForm.Text className='text-danger'>
                                    <FormikErrorText name='Native_Language' />
                                  </BForm.Text>
                                )}
                            </div>
                          </BForm.Group>
                          <BForm.Group className='row mb-7'>
                            <BForm.Label className='col-lg-4 fw-bold text-muted'>
                              País
                            </BForm.Label>
                            <div className='col-lg-8'>
                              <BForm.Control
                                {...getFieldProps('Country')}
                                as='select'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`text-capitalize form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                  errors.Country && touched.Country
                                    ? 'border border-danger'
                                    : ''
                                }`}
                              >
                                <option key={''} value={''}>
                                  {'Select'}
                                </option>
                                {countryList?.map((option: any, index: any) => (
                                  <option key={index} value={option._id}>
                                    <TranslateInSpanish
                                      text={option.Country_Name}
                                    />
                                  </option>
                                ))}
                              </BForm.Control>
                              {touched.Country && errors.Country && (
                                <BForm.Text className='text-danger'>
                                  <FormikErrorText name='Country' />
                                </BForm.Text>
                              )}
                            </div>
                          </BForm.Group>
                          <BForm.Group className='row mb-7'>
                            <BForm.Label className='col-lg-4 fw-bold text-muted'>
                              Correo electrónico
                            </BForm.Label>
                            <div className='col-lg-8'>
                              <BForm.Control
                                {...getFieldProps('Email')}
                                type='email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                  errors.Email && touched.Email
                                    ? 'border border-danger'
                                    : ''
                                }`}
                              />
                              {touched.Email && errors.Email && (
                                <BForm.Text className='text-danger'>
                                  <FormikErrorText name='Email' />
                                </BForm.Text>
                              )}
                            </div>
                          </BForm.Group>
                          <div className='row mb-10'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              Foto de perfil
                            </label>
                            <div className='col-lg-8'>
                              <div className='w-125px'>
                                {' '}
                                <ImageUpload
                                  entityLabel='Student'
                                  name='ProfilePic'
                                />{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='primary' type='submit'>
                    Actualizar
                  </Button>
                  <Button variant='secondary' onClick={props.onHide}>
                    saltar
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CompleteProfile;
