import LoaderNew from 'app/components/LoaderNew';
import { useGetQuery } from 'hooks/reactQueryHelper';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ShortUrl = () => {
  const { shortenId } = useParams();

  const redirectShortenUrl: any = useGetQuery(
    'shorten',
    `/shortURL/${shortenId}`
  );

  useEffect(() => {
    redirectShortenUrl.mutate({
      id: shortenId,
    });
  }, []);

  useEffect(() => {
    if (redirectShortenUrl.isSuccess && redirectShortenUrl.data) {
      window.location.replace(redirectShortenUrl.data);
    }

    if (redirectShortenUrl.isError) {
      window.location.replace(`${location.origin}/error/404`);
    }
  }, [redirectShortenUrl]);

  return (
    <>
      <LoaderNew isFull loading={redirectShortenUrl.isLoading} />
    </>
  );
};

export default ShortUrl;
