import { useGetQuery } from 'hooks/reactQueryHelper';
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { toAbsoluteUrl } from 'theme/helpers';
import Cookies from 'js-cookie';
import Confetti from 'react-dom-confetti';
import { useAuth } from 'app/modules/auth';

const StrickCounter = () => {
  const { auth, learningStreakDaysUpdated, setLearningStreakDaysUpdated } =
    useAuth();
  const [strickCount, setStrickCount] = React.useState<number>(0);
  const [weekdays, setWeekdays] = React.useState<any>([]);
  const [animationShow, setAnimationShow] = React.useState(false);
  const config: any = {
    angle: 90,
    spread: '60',
    startVelocity: '41',
    elementCount: '500',
    dragFriction: '0.13',
    duration: 2000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '414px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };
  const getLearningStreakDaysCountQuery = useGetQuery(
    'getLearningStreakDaysCountQuery',
    '/userLessonsLogs/getLearningStreakDaysCount'
  );

  const playAudio = React.useCallback(() => {
    const audio = new Audio('/media/audio/successSound.wav');
    audio.play();
  }, []);

  useEffect(() => {
    if (auth && auth.token) {
      getLearningStreakDaysCountQuery.mutate({});
    }
  }, []);

  useEffect(() => {
    if (auth && auth.token && learningStreakDaysUpdated) {
      getLearningStreakDaysCountQuery.mutate({});
      setLearningStreakDaysUpdated(false);
    }
  }, [learningStreakDaysUpdated]);

  useEffect(() => {
    if (getLearningStreakDaysCountQuery.isSuccess) {
      setStrickCount(
        getLearningStreakDaysCountQuery?.data?.ResultObject?.streakCounter
      );
      setWeekdays(
        getLearningStreakDaysCountQuery?.data?.ResultObject?.streakDate
      );
    }
  }, [getLearningStreakDaysCountQuery]);

  useEffect(() => {
    if (
      getLearningStreakDaysCountQuery.isSuccess &&
      getLearningStreakDaysCountQuery?.data?.ResultObject?.streakDate
    ) {
      setAnimationShow(false);
      getLearningStreakDaysCountQuery?.data?.ResultObject?.streakDate?.forEach(
        (element: { date: string; isStrickChecked: boolean }) => {
          if (
            element.date === moment().format('YYYY-MM-DD') &&
            element.isStrickChecked
          ) {
            if (Cookies.get('strickCrackers') === undefined) {
              setAnimationShow(true);
              playAudio();
              Cookies.set('strickCrackers', 'true', {
                expires: moment().endOf('day').toDate(),
                path: '',
              });
            }
          }
        }
      );
    }
  }, [getLearningStreakDaysCountQuery]);

  return (
    <>
      <div className='w-100 d-block'>
        <div className='d-flex align-items-center flex-column w-100 mb-3 mb-lg-6'>
          <div className='d-flex justify-content-between fw-bolder fs-6 text-gray-800 w-100 mt-auto mb-3'>
            <div className='card text-center bg-light rounded mb-5 w-100'>
              <div className='card-body p-3 d-flex flex-wrap align-items-center bg-light-warning rounded border border-warning'>
                <div className='d-flex flex-center me-5 pt-2'>
                  <img
                    src={toAbsoluteUrl('/media/images/learning_streak.png')}
                    className='mh-100px img-fluid'
                    alt='Racha de Aprendizaje'
                  />
                </div>
                <div className='d-flex flex-column content-justify-center flex-row-fluid flex-fill'>
                  <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                    <span className='text-gray-800 pt-1 fw-semibold fs-6 pb-3'>
                      Racha de Aprendizaje
                    </span>

                    <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 p-1'>
                      {strickCount} Dias
                    </span>
                  </div>
                  <div
                    className='d-flex flex-row flex-wrap align-items-center justify-content-between rounded-2 p-2 '
                    style={{ background: '#f7e1ca6e' }}
                  >
                    {weekdays?.map((item: any, index: any) => {
                      return (
                        <div
                          className='d-flex flex-column flex-wrap mx-1'
                          key={index}
                        >
                          <div className='fs-5 lh-1'>
                            {item.isStrickChecked ? (
                              <i className='fa-solid fa-circle-check fa-beat text-success'></i>
                            ) : (
                              '•'
                            )}
                          </div>
                          <div className=''>
                            {moment(item.date).format('dd').charAt(0)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='Confetti-div'>
        <Confetti active={animationShow} config={config} />
      </div>
    </>
  );
};

export default memo(StrickCounter);
