import GoogleMeetVocabularyForm from 'app/components/GoogleMeet/GoogleMeetVocabularyForm';
import GoogleMeetVocabularyVerifiedFormMessage from 'app/components/GoogleMeet/GoogleMeetVocabularyVerifiedFormMessage';
import GoogleMeetVocabularyVerifiedFormSubmittedMessage from 'app/components/GoogleMeet/GoogleMeetVocabularyVerifiedFormSubmittedMessage';
import LoaderNew from 'app/components/LoaderNew';
import { useAuth } from 'app/modules/auth';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const GoogleMeetEarnPoint = () => {
  let { scheduleGoogleMeetId } = useParams();
  const { auth, learningStreakDaysUpdated, setLearningStreakDaysUpdated } =
    useAuth();

  const isVerifyVocabularyWordsQuery: any = useGetQuery(
    'isVerifyVocabularyWords' + Date.now(),
    `/scheduleGoogleMeet/isVerifyVocabularyWords?t=${new Date().getTime()}`,
    {}
  );

  const verifyVocabularyWordsQuery: any = usePostQuery(
    'verifyVocabularyWords',
    '/scheduleGoogleMeet/verifyVocabularyWords',
    {}
  );

  useEffect(() => {
    if (verifyVocabularyWordsQuery.isSuccess && !learningStreakDaysUpdated) {
      setLearningStreakDaysUpdated(true);
    }
  }, [verifyVocabularyWordsQuery.isSuccess]);

  useEffect(() => {
    if (auth && auth.token) {
      isVerifyVocabularyWordsQuery.mutateAsync({
        params: { scheduleGoogleMeetId: scheduleGoogleMeetId },
      });
    }
  }, []);

  if (
    isVerifyVocabularyWordsQuery?.data &&
    isVerifyVocabularyWordsQuery?.data?.ResultObject.isVerifyVocabularyWords ===
      false
  ) {
    // Render content for logged-in users
    return verifyVocabularyWordsQuery?.isSuccess ? (
      <GoogleMeetVocabularyVerifiedFormSubmittedMessage
        point={verifyVocabularyWordsQuery?.data?.ResultObject?.point}
      />
    ) : (
      <GoogleMeetVocabularyForm
        scheduleGoogleMeetId={scheduleGoogleMeetId}
        verifyVocabularyWordsQuery={verifyVocabularyWordsQuery}
        shuffleWord={isVerifyVocabularyWordsQuery?.data?.ResultObject?.shuffle}
      />
    );
  } else if (
    isVerifyVocabularyWordsQuery?.data &&
    isVerifyVocabularyWordsQuery?.data?.ResultObject
  ) {
    // Render content for non-logged-in users
    return <GoogleMeetVocabularyVerifiedFormMessage />;
  } else {
    // Fallback content if none of the conditions match
    return <LoaderNew loading={isVerifyVocabularyWordsQuery?.isLoading} />;
  }
};

export default GoogleMeetEarnPoint;
