import EnrollmentFormImageList from 'app/components/EnrollmentFormImageList';
import clsx from 'clsx';
import { Field, FieldArray } from 'formik';
import { usePostQuery } from 'hooks/reactQueryHelper';
import useIntlCustom from 'hooks/useIntlCustom';
import React, { useEffect, useState } from 'react';
import { getCurrentYear } from '../enrollmenthooks/EnrollmentHooks';
// import { BsFiletypePdf } from 'react-icons/bs';
// import { MdRemoveCircle } from 'react-icons/md';

const GradeSubmissionFormDocumentUpload = (props: any) => {
  const { formik, hasFieldError } = props;
  const { formatMessage } = useIntlCustom();

  const headers = { 'Content-Type': 'multipart/form-data' };
  const multipleDocumentQueryQuery: any = usePostQuery(
    'multipleDocumentQuery',
    '/upload/add/multipleDocument',
    { headers }
  );
  const supportedFormats = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'text/plain',
    'application/pdf',
    'application/msword',
  ];

  const [documentErrorText, setDocumentErrorText] = useState<any>('');
  const [documentError, setDocumentError] = useState<any>(false);

  const onUpload = (event: any) => {
    const files = event.currentTarget.files;

    if (files?.length > 0) {
      const formData = new FormData();
      for (const file of files) {
        // if (file.size <= 10485760) {
        if (supportedFormats.includes(file.type)) {
          formData.append('files', file);
        } else {
          setDocumentError(true);
          setDocumentErrorText('Only JPEG, JPG,PNG and PDF file are allowed');
        }
        // } else {
        // setDocumentError(true);
        // setDocumentErrorText('File size is too large (maximum 10 MB)');
        // }
      }
      multipleDocumentQueryQuery.mutateAsync(formData);
    } else {
      setDocumentError(true);
      setDocumentErrorText('Please upload a document');
      formik.setFieldValue('Student_School_Transcript_Subject_Document', '');
    }
  };

  useEffect(() => {
    if (multipleDocumentQueryQuery.isSuccess) {
      setDocumentError(false);
      formik.setFieldValue('Student_School_Transcript_Subject_Document', [
        ...formik.values.Student_School_Transcript_Subject_Document,
        ...multipleDocumentQueryQuery?.data,
      ]);
      setDocumentErrorText('');
    }
  }, [multipleDocumentQueryQuery.isSuccess]);

  const deleteImageItem = (index: number) => {
    const newArray = [
      ...formik.values.Student_School_Transcript_Subject_Document,
    ];
    newArray.splice(index, 1);
    formik.setFieldValue('Student_School_Transcript_Subject_Document', [
      ...newArray,
    ]);
  };
  return (
    <>
      <div
        className={
          hasFieldError?.includes('Student_School_Transcript_Subject_Document')
            ? 'p-3 bg-light-danger border border-2 border-danger rounded-2 mb-1'
            : 'p-3'
        }
      >
        <label className='form-label fw-bold'>
          Please upload a school transcript that shows your student's completed
          subjects for {getCurrentYear}.{' '}
          <span className='text-danger'> * </span>
        </label>
        <p className='form-label fw-bold'>
          Adjunte/Suba la boleta escolar que muestre las materias completadas de
          su estudiante para {getCurrentYear}.
        </p>

        <EnrollmentFormImageList
          imageItems={
            formik?.values?.Student_School_Transcript_Subject_Document
          }
          deleteImageItem={deleteImageItem}
        />

        <div className='position-relative d-flex align-items-center mt-2'>
          <label
            htmlFor='hiddenBtn'
            className='btn btn-primary btn-sm'
            id='chooseBtn'
            aria-disabled
          >
            {multipleDocumentQueryQuery.isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>{' '}
                {formatMessage({
                  id: 'UPLOADING',
                })}
                ...
              </>
            ) : (
              formatMessage({
                id: 'ADD',
              })
            )}
          </label>
          <input
            disabled={multipleDocumentQueryQuery.isLoading}
            id='hiddenBtn'
            hidden
            type='file'
            autoComplete='off'
            multiple={true}
            accept='.jpg, .jpeg, .png, .pdf, .doc, .txt'
            onChange={(event: any) => {
              onUpload(event);
            }}
          />
        </div>

        {!multipleDocumentQueryQuery.isLoading &&
          formik.touched.Student_School_Transcript_Subject_Document &&
          formik.errors.Student_School_Transcript_Subject_Document && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                {!documentErrorText && (
                  <span role='alert'>
                    {formik.errors.Student_School_Transcript_Subject_Document}
                  </span>
                )}
              </div>
            </div>
          )}

        {!multipleDocumentQueryQuery.isLoading && documentError && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{documentErrorText}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GradeSubmissionFormDocumentUpload;
