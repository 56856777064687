import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Confetti from 'react-dom-confetti';
import FooterActivityPage from '../activityfooter/FooterActivityPage';
import { toAbsoluteUrl } from 'theme/helpers';
import Loader from 'app/components/Loader';
import AdSection from './ad-section';
import successSound from '../../../theme/assets/audio/successSound.mp3';

const TapItActivity: React.FC<any> = (props: any) => {
  const {
    setTapITActivityShow,
    setSayITActivityShow,
    singleLessonData,
    sectionType,
    setWriteITActivityShow,
    setResultAnswerCount,
    resultAnswerCount,
    setVideoSectionShow,
    prevLastIndexQuestion,
    setPrevLastIndexQuestion,
    setSectionIndex,
    nativeLang,
    targetLang,
    sectionIndex,
    setQuestionResultList,
    questionResultList,
    quizQuestionCount,
    setQuizQuestionCount,
    trueAnswerCount,
    setTrueAnswerCount,
    setPickWordActivityShow,
  } = props;

  const [animationShow, setAnimationShow] = useState(false);
  const [tapitIndex, setTapItIndex] = useState(0);
  const [balnkValue, setBlankValue] = useState('');

  const defaultImageUrl = toAbsoluteUrl('/media/svg/avatars/blank.svg');

  var tapITQuestionsLenght: number;

  var data = singleLessonData?.Activity_Details?.filter(
    (x: any) => x.Section == sectionType
  )[0];

  let userAgentData = navigator.userAgent;
  let regexp =
    /android|webOS|BlackBerry|Windows Phone|iPod|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(userAgentData);

  const checkWidthMobileDevice = window.matchMedia('(max-width: 768px)');
  let checkWidthMobileMediaQuery: any;

  checkWidthMobileDevice.addEventListener('change', (event) => {
    console.log(window.innerWidth);
    if (event.matches) {
      checkWidthMobileMediaQuery = true;
    } else {
      checkWidthMobileMediaQuery = false;
    }
  });

  const [config, setConfig] = useState<any>({
    angle: 90,
    spread: '60',
    startVelocity: '41',
    elementCount: '40',
    dragFriction: '0.13',
    duration: 1000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '414px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  });

  useEffect(() => {
    var buttons = document.querySelectorAll('button');
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].blur();
    }
  }, []);

  useEffect(() => {
    if (prevLastIndexQuestion) {
      setTapItIndex(tapITQuestionsLenght);
      setPrevLastIndexQuestion(false);
    }
  }, [prevLastIndexQuestion]);

  const handleTapITQuestionsAnswer = async (
    event: any,
    index: any,
    questionsAnswer: any,
    grammarANswer: any,
    audioTagId: any
  ) => {
    if (sectionType != 'QuizSection') {
      const audioSuccess: any = document.getElementById('audioSuccess');

      const audio: any = document.getElementById(audioTagId);
      await audio.play();
      if (event.target.value === questionsAnswer) {
        await audioSuccess.play();
        if (index !== tapITQuestionsLenght) {
          setTimeout(() => {
            setTapItIndex(index + 1);
          }, 1000);
        }
        if (index == tapITQuestionsLenght) {
          // setTapITActivityShow(false);
          if (sectionType == 'QuizSection') {
            setTimeout(() => {
              setTapITActivityShow(false);
              setWriteITActivityShow(true);
            }, 1000);
          } else if (sectionType == 'GrammarSection') {
            setTimeout(() => {
              setTapITActivityShow(false);
              setWriteITActivityShow(true);
            }, 1000);
          } else {
            setTimeout(() => {
              // do not change this comment code
              // say it activity hide in mobile and web disply
              // setSayITActivityShow(true);
              // if (isMobileDevice && (!isIPhoneUser || !isAndroidUser)) {
              if (isMobileDevice) {
                setTapITActivityShow(false);
                setWriteITActivityShow(true);
              } else {
                setTapITActivityShow(false);
                setSayITActivityShow(true);
              }
            }, 1000);
          }
        }
        setBlankValue(grammarANswer);
        setAnimationShow(true);
        event.target.classList.add('btn-green');
        setTimeout(() => {
          setBlankValue('');
          setAnimationShow(false);
          event.target.classList.add('btn-disable');
        }, 1000);
      } else {
        event.target.classList.add('btn-red');
        setTimeout(() => {
          event.target.classList.remove('btn-red');
          event.target.classList.add('btn-disable');
        }, 1000);
      }
    } else {
      setTrueAnswerCount(trueAnswerCount + 1);
      if (event.target.value === questionsAnswer) {
        var x = document.getElementsByClassName('option-btn');
        [].forEach.call(x, function (el: any) {
          el.classList.add('btn-disable');
        });
        setQuizQuestionCount((quizQuestionCount: any) => quizQuestionCount + 1);
        setTimeout(() => {
          const questionList = {
            Question: `Pregunta - ${quizQuestionCount}`,
            Answer: true,
          };
          setQuestionResultList([...questionResultList, questionList]);
        }, 1000);
        setResultAnswerCount(resultAnswerCount + 1);
        if (index !== tapITQuestionsLenght) {
          setTimeout(() => {
            setTapItIndex(index + 1);
          }, 1000);
        } else {
          setTimeout(() => {
            setTapITActivityShow(false);
            setWriteITActivityShow(true);
          }, 1000);
        }
      } else {
        var x = document.getElementsByClassName('option-btn');
        [].forEach.call(x, function (el: any) {
          el.classList.add('btn-disable');
        });
        setTimeout(() => {
          setQuizQuestionCount(
            (quizQuestionCount: any) => quizQuestionCount + 1
          );
          const questionList = {
            Question: `Pregunta - ${quizQuestionCount}`,
            Answer: false,
          };
          setQuestionResultList([...questionResultList, questionList]);
        }, 1000);
        if (index !== tapITQuestionsLenght) {
          setTimeout(() => {
            setTapItIndex(index + 1);
          }, 1000);
        } else {
          setTimeout(() => {
            setTapITActivityShow(false);
            setWriteITActivityShow(true);
          }, 1000);
        }
      }
    }
  };

  const onPronounce = async (pronounceAudioId: any) => {
    var pronounceAudio: any = document.getElementById(pronounceAudioId);
    pronounceAudio.currentTime = 0;
    pronounceAudio.play();
  };

  useEffect(() => {
    if (animationShow) {
      setTrueAnswerCount(trueAnswerCount + 1);
    }
  }, [animationShow]);

  const onPreviousButton = (e: any) => {
    setTapItIndex(tapitIndex - 1);
    if (tapitIndex != 0) {
      setTrueAnswerCount(trueAnswerCount - 1);
    } else {
      if (sectionType != 'AISection') {
        setSectionIndex(sectionIndex);
        setVideoSectionShow(true);
        setTapITActivityShow(false);
      } else {
        setPickWordActivityShow(true);
        setTapITActivityShow(false);
      }
    }
  };

  const onTouchEnd = (event: any) => {
    var x = document.getElementsByClassName('option-btn');
    [].forEach.call(x, function (el: any) {
      el.classList.remove('btn-hover');
    });
  };

  return (
    <>
      <Loader loading={data ? false : true} />

      {data?.Tap_IT?.Questions?.map((key: any, index: any) => {
        tapITQuestionsLenght = data?.Tap_IT.Questions.length - 1;
        if (index == tapitIndex) {
          // tapITQuestionsLenght = data?.Tap_IT.Questions.length - 1
          let langQuestionData = key?.Question?.Language?.filter(
            (x: any) => x.Language_Code == nativeLang
          )[0];
          let langQuestionDataTarget = key?.Question?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let langAnswerData = key?.Answer?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let questionsAnswer = langQuestionDataTarget?.Answer;
          var checkTargetLanguage =
            targetLang == 'ja' || targetLang == 'zh-CN' || targetLang == 'ar'
              ? true
              : false;
          var checkNativeLanguage =
            nativeLang == 'ja' || nativeLang == 'zh-CN' || nativeLang == 'ar'
              ? true
              : false;
          // audioAutoPaly();
          return (
            <div
              className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'
              key={index}
            >
              <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
                <AdSection />
                <audio controls id='audioSuccess' preload='auto' hidden>
                  <source src={successSound} type='audio/mp3' />
                </audio>
                {langQuestionData?.Audio_Title && (
                  <audio controls id='pronounceAudio' preload='auto' hidden>
                    <source
                      src={langQuestionData?.Audio_Title}
                      type='audio/mp3'
                    />
                  </audio>
                )}

                <div className='container layout_container'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='w-100 position-relative d-flex flex-column'>
                        <div className='row d-grid gap-3'>
                          <div className='col-12 mx-auto'>
                            <div className='w-100'>
                              <div className='progress-bar mt-3'>
                                <ProgressBar
                                  animated
                                  now={
                                    isMobileDevice
                                      ? index == 0
                                        ? 15
                                        : index == 1
                                        ? 30
                                        : index == 2
                                        ? 45
                                        : 50
                                      : index == 0
                                      ? 10
                                      : index == 1
                                      ? 20
                                      : index == 2
                                      ? 30
                                      : 50
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {langQuestionData?.Image != '' ? (
                            <div className='col-10 mx-auto'>
                              <div className='w-100 z-10 d-grid gap-1 text-black'>
                                <div className='title-box'>
                                  <div className='d-flex gap-2 text-center justify-content-between align-items-center'>
                                    <div className='volume_logo_label'>
                                      <button
                                        id='Mouse'
                                        value='Mouse'
                                        type='button'
                                        className='bg-white'
                                        onClick={() =>
                                          onPronounce('pronounceAudio')
                                        }
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='25'
                                          height='25'
                                          fill='#000000'
                                          className='bi bi-volume-up'
                                          viewBox='0 0 16 16'
                                        >
                                          <path d='M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
                                          <path d='M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z' />
                                          <path d='M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z' />
                                        </svg>
                                      </button>
                                    </div>
                                    <div>
                                      {checkNativeLanguage ? (
                                        <div>
                                          <h3>
                                            {
                                              langQuestionData?.Title_Roman_Language
                                            }
                                          </h3>
                                          <h4>{langQuestionData?.Title}</h4>
                                        </div>
                                      ) : (
                                        <h1>{langQuestionData?.Title}</h1>
                                      )}
                                    </div>
                                    <div>{langQuestionData?.Type}</div>
                                  </div>
                                  <div>
                                    <div className='text-center'>
                                      {langQuestionData?.Description}
                                    </div>
                                  </div>
                                </div>
                                <div className='image-box-center'>
                                  <div className='main_img'>
                                    <img
                                      src={
                                        langQuestionData?.Image
                                          ? langQuestionData?.Image
                                          : defaultImageUrl
                                      }
                                      alt='Hope City School'
                                      className='img-fluid'
                                    />

                                    <div className='Confetti-div'>
                                      <Confetti
                                        active={animationShow}
                                        config={config}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='col-10 mx-auto'>
                              <div className='w-100 z-10 d-grid gap-1 text-black pt-5 pb-5'>
                                <div className='d-flex gap-2 text-center justify-content-between align-items-center'>
                                  <div className='blank-question-box'>
                                    <div className='blank-questions-text-box'>
                                      <h1>
                                        {' '}
                                        {langQuestionData?.Title}{' '}
                                        <span>
                                          {balnkValue
                                            ? `${balnkValue}.`
                                            : '_________.'}
                                        </span>
                                      </h1>
                                      <div className='Confetti-div'>
                                        <Confetti
                                          active={animationShow}
                                          config={config}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='col-12 option-box'>
                            <div className='w-100'>
                              <div className='d-flex justify-content-evenly py-2 gap-3 option-btn-padding'>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option1'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark w-100 border btn-lg rounded-pill option-btn'
                                    onTouchEnd={(event) => {
                                      onTouchEnd(event);
                                    }}
                                    onClick={(event) =>
                                      handleTapITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        langAnswerData?.Option1,
                                        'audioOption1'
                                      )
                                    }
                                  >
                                    {checkTargetLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option1_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerData?.Option1}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerData?.Option1}</>
                                    )}
                                    {/* {langAnswerData?.Option1} */}
                                    {/* <MdOutlineVolumeUp /> */}
                                  </button>
                                  <audio
                                    controls
                                    id='audioOption1'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option1}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option2'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark w-100 border btn-lg rounded-pill option-btn'
                                    onTouchEnd={(event) => {
                                      onTouchEnd(event);
                                    }}
                                    onClick={(event) =>
                                      handleTapITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        langAnswerData?.Option2,
                                        'audioOption2'
                                      )
                                    }
                                  >
                                    {/* {langAnswerData?.Option2} */}
                                    {checkTargetLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option2_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerData?.Option2}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerData?.Option2}</>
                                    )}
                                  </button>
                                  <audio
                                    controls
                                    id='audioOption2'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option2}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                              </div>
                              <div className='d-flex justify-content-evenly py-2 gap-3 option-btn-padding option-box-2'>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option3'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark w-100 border btn-lg rounded-pill option-btn'
                                    onTouchEnd={(event) => {
                                      onTouchEnd(event);
                                    }}
                                    onClick={(event) =>
                                      handleTapITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        langAnswerData?.Option3,
                                        'audioOption3'
                                      )
                                    }
                                  >
                                    {/* {langAnswerData?.Option3} */}
                                    {checkTargetLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option3_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerData?.Option3}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerData?.Option3}</>
                                    )}
                                  </button>
                                  <audio
                                    controls
                                    id='audioOption3'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option3}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option4'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark w-100 border btn-lg rounded-pill option-btn'
                                    onTouchEnd={(event) => {
                                      onTouchEnd(event);
                                    }}
                                    onClick={(event) =>
                                      handleTapITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        langAnswerData?.Option4,
                                        'audioOption4'
                                      )
                                    }
                                  >
                                    {/* {langAnswerData?.Option4} */}
                                    {checkTargetLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option4_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerData?.Option4}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerData?.Option4}</>
                                    )}
                                  </button>
                                  <audio
                                    controls
                                    id='audioOption4'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option4}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <FooterActivityPage
                  nextButton={false}
                  onPreviousButton={onPreviousButton}
                  previousButton={sectionType != 'QuizSection' ? true : false}
                  urlHash={key?.Question_ID || undefined}
                  lesson={singleLessonData?._id || undefined}
                />
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default TapItActivity;
