import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from '../theme/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core';
import { MasterInit } from '../theme/layout/MasterInit';
import { AuthInit, useAuth } from './modules/auth';
import PreRequiredDataCheck from './components/PreRequiredDataCheck';
import SubscriptionCheck from './components/SubscriptionCheck';
import useJwt from 'hooks/useJwt';

const App = () => {
  const { isTokenValid } = useJwt();
  const { logout } = useAuth();

  useEffect(() => {
    if (!isTokenValid()) {
      logout();
    }
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <>
              <Outlet />
              <SubscriptionCheck>
                <PreRequiredDataCheck>
                  <MasterInit />
                </PreRequiredDataCheck>
              </SubscriptionCheck>
            </>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
