import React, { FC, useState } from 'react';
import { AiFillRightCircle } from 'react-icons/ai';

import FooterDropdown from './FooterDropdown';

const FooterPage: React.FC<any> = (props: any) => {
  const { handleVideoNextButton, sectionType, lesson, urlHash } = props;

  return (
    <>
      <div className='footertag-overlay-buttons d-flex justify-content-between'>
        <div className='dropup flag-button-home'>
          <FooterDropdown urlHash={urlHash} lesson={lesson} />
        </div>
        <button
          className='btn btn-primary p-2 rounded-pill'
          onClick={() => handleVideoNextButton()}
        >
          {sectionType == 'ConversationSection'
            ? 'Video Conversation'
            : sectionType == 'VocabularySection'
            ? 'Video Vocabulary'
            : sectionType == 'GrammarSection'
            ? 'Video Grammar'
            : ''}{' '}
          <AiFillRightCircle size={25} />
        </button>
      </div>
    </>
  );
};

export default FooterPage;
