import { memo, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useGetQuery, usePostQuery, usePutQuery } from 'hooks/reactQueryHelper';
import moment from 'moment';
import { MdOutlineLiveTv } from 'react-icons/md';
import { BsBook } from 'react-icons/bs';
export const GET_ALL_LANGUAGE = `/language/getAllLanguages`;
export const GET_ALL_LEVEL = `/level/getAll`;

const ViewStudentGradePointsModal = ({ show, data, onHide }: any) => {
  const getAssignmentByCourseId: any = useGetQuery(
    'getAssignmentByCourseId',
    `/course/getAssignmentByCourseId`
  );

  useEffect(() => {
    getAssignmentByCourseId.mutateAsync({
      params: { id: data?.course?._id, userId: data?.user?._id },
    });
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Assignment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table table-row-dashed border-gray-300 align-middle gy-3'>
            <thead>
              <tr className='fw-bolder fs-6 text-light bg-primary text-nowrap'>
                <th className='ps-2'>Date</th>
                <th>Points</th>
                <th>Grade</th>
                <th>Type</th>
                <th>Assignment</th>
              </tr>
            </thead>
            <tbody className='fs-6'>
              {getAssignmentByCourseId?.data?.ResultObject?.map((item: any) => (
                <tr>
                  <td>{moment(item?.submittedDate).format('MM/DD/YYYY')}</td>
                  <td>{item?.point}</td>
                  <td>{item?.percentage}%</td>
                  <td>
                    {item?.lessonId?.Lesson_Name && <>Lesson</>}
                    {item?.liveClass?.name && <>Live class</>}
                    {item?.instantClass?.videoName && <>Instant class</>}
                  </td>
                  <td className='text-nowrap d-flex align-items-center gap-1'>
                    {item?.lessonId?.Lesson_Name && (
                      <>
                        <BsBook className='text-primary fw-bold' />
                        <div>{item?.lessonId?.Lesson_Name}</div>
                      </>
                    )}

                    {item?.liveClass?.name && (
                      <>
                        <MdOutlineLiveTv className='text-primary fw-bold' />
                        <div>{item?.liveClass?.name}</div>
                      </>
                    )}

                    {item?.instantClass?.videoName && (
                      <>
                        <MdOutlineLiveTv className='text-primary fw-bold' />
                        <div>{item?.instantClass?.videoName}</div>
                      </>
                    )}
                  </td>
                </tr>
              ))}
              {getAssignmentByCourseId.isSuccess &&
                getAssignmentByCourseId?.data?.ResultObject?.length === 0 && (
                  <tr>
                    <td colSpan={99} className='text-center text-muted'>
                      No data available
                    </td>
                  </tr>
                )}
              {getAssignmentByCourseId.isLoading && (
                <tr>
                  <td colSpan={99} className='text-center text-muted'>
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ViewStudentGradePointsModal);
