import { IMAGE_UPLOAD, IMAGE_UPLOAD_FAILURE, IMAGE_UPLOAD_SUCCESS } from "./actionTypes"

const initialState: any = {
    imageloading: false,
    error: "",
    success: "",
    imageURL: "",
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case IMAGE_UPLOAD:
            return {
                ...state,
                imageloading: true,
                success: "",
                error: "",
                imageURL: ""
            }
        case IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                imageURL: action.payload,
                imageloading: false,
            }
        case IMAGE_UPLOAD_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }

        default:
            return {
                ...state
            }
    }
}
export default reducer
