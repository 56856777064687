import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderNew from '../LoaderNew';
import useIntlCustom from 'hooks/useIntlCustom';

const CompletedCourseNotificationModal = (props: any) => {
  const { courseInviteId } = useParams();
  const { formatMessage } = useIntlCustom();
  const { onHide } = props;

  const getSimilarCourseQuery: any = useGetQuery(
    'getSimilarCourse',
    `/course/getSimilarCourse`,
    {}
  );

  useEffect(() => {
    if (props.show) {
      getSimilarCourseQuery.mutateAsync({
        params: { courseInviteId: courseInviteId },
      });
    }
  }, [props.show]);

  const addCourseInviteQuery: any = usePostQuery(
    'addCourseInviteByCourseIdQuery',
    `/course/addCourseInviteByCourseId`,
    {}
  );

  useEffect(() => {
    if (addCourseInviteQuery.isSuccess) {
      // navigate(`/course/${courseID}/student-course-details`);
      onHide();
    }
  }, [addCourseInviteQuery.isSuccess]);

  const onContinue = () => {
    addCourseInviteQuery.mutate({
      courseId: getSimilarCourseQuery?.data?.ResultObject?._id,
    });
  };

  return (
    <Modal {...props} backdrop='static' keyboard={false}>
      <Modal.Header closeButton className='border-0' />
      <Modal.Body>
        <LoaderNew
          loading={
            getSimilarCourseQuery?.isLoading || addCourseInviteQuery?.isLoading
          }
          isFull={false}
        />
        <div className='card card-xl-stretch mb-xl-8 border-secondary border'>
          <div className='card-body '>
            <div className='card text-center bg-light rounded'>
              <div className='card-body'>
                <div className='card-body text-center'>
                  {' '}
                  <img
                    src='https://img.icons8.com/bubbles/200/000000/trophy.png'
                    alt=''
                  />
                  <h3>
                    {' '}
                    {formatMessage({
                      id: 'COMPLETED_COURSE_NOTIFICATION_MODAL.CONGRATULATIONS',
                    })}{' '}
                    {/* CONGRATULATIONS! */}
                  </h3>
                  <p className='fs-4'>
                    {' '}
                    {formatMessage({
                      id: 'COMPLETED_COURSE_NOTIFICATION_MODAL.FINISH_COURSE',
                    })}{' '}
                    {/* You have finish the course. */}
                  </p>{' '}
                  <p className='fs-4'>
                    {formatMessage({
                      id: 'COMPLETED_COURSE_NOTIFICATION_MODAL.CONTENT',
                    })}{' '}
                    <b>{getSimilarCourseQuery?.data?.ResultObject?.name}</b>?
                  </p>{' '}
                  <button
                    className='btn btn-primary continue fs-3'
                    onClick={() => onContinue()}
                  >
                    Yes
                  </button>
                  <button
                    style={{ marginLeft: '5px' }}
                    className='btn btn-light continue fs-3'
                    onClick={onHide}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompletedCourseNotificationModal;
