import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../theme/helpers';
import AuthHeader from '../components/AuthHeader';
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import useIntlCustom from 'hooks/useIntlCustom';

const Login: React.FC = () => {
  const { formatMessage } = useIntlCustom();
  const [searchParams] = useSearchParams();
  const courseInviteId = searchParams.get('courseInviteId');
  const isDiplomaStudent = searchParams.get('isDiploma');
  const scheduleGoogleMeetId = searchParams.get('scheduleGoogleMeetId');

  const [signUpModal, setSignUpModal] = useState(false);

  return (
    <>
      <AuthHeader />
      <div>
        <div className='card-body'>
          <div className='text-start mb-10'>
            <h1
              className='text-dark mb-3 fs-3x'
              data-kt-translate='sign-in-title'
            >
              {formatMessage({
                id: 'AUTH.LOGIN.CREATE_ACCOUNT',
              })}
              {/* Create Account */}
            </h1>
          </div>
          <div className='row g-3 my-9'>
            <div className='col-md-12'>
              <button
                onClick={() => setSignUpModal(true)}
                className='btn btn-flex shadow-lg hover-elevate-down btn-hover btnGrey btn-text-white btn-active-color-light-white flex-center text-nowrap w-100'
              >
                {formatMessage({
                  id: 'AUTH.LOGIN.SIGN_UP',
                })}
                {/* Sign up */}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='card-body'>
          <div className='text-start mb-10'>
            <h1
              className='text-dark mb-3 fs-3x'
              data-kt-translate='sign-in-title'
            >
              {formatMessage({
                id: 'AUTH.LOGIN.SIGN_IN',
              })}
              {/* Sign In */}
            </h1>
            <div
              className='text-gray-400 fw-semibold fs-6'
              data-kt-translate='general-desc'
            >
              {formatMessage({
                id: 'AUTH.LOGIN.WELCOME_TO_HOPE_CITY_SCHOOL',
              })}{' '}
              !{/* Welcome To Hope City School ! */}
            </div>
          </div>
          <div className='row g-3 my-9'>
            <div className='col-md-12'>
              <a
                href={
                  isDiplomaStudent
                    ? `https://m.me/hopecityeducation?ref=signup-diploma`
                    : scheduleGoogleMeetId
                    ? `https://m.me/hopecityeducation?ref=signup-google-meet--${scheduleGoogleMeetId}`
                    : `https://m.me/hopecityeducation?ref=signup${
                        courseInviteId ? '--' + courseInviteId : ''
                      }`
                }
                className='btn btn-flex btn-shadow hover-elevate-down btn-hover color-2 btn-text-white btn-active-color-light-white flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/social-logos/facebook.svg')}
                  className='h-25px me-3'
                />
                {formatMessage({
                  id: 'AUTH.LOGIN.LOGIN_WITH_FACEBOOK',
                })}
                {/* Login with Facebook */}
              </a>
            </div>
          </div>
          <div className='separator separator-content my-14'>
            <span className='w-125px text-gray-500 fw-semibold fs-7'>
              {formatMessage({
                id: 'AUTH.LOGIN.OR_WITH_EMAIL',
              })}
              {/* Or with email */}
            </span>
          </div>
          <div className='row g-3 my-9'>
            <a
              href={
                courseInviteId
                  ? `login2?${`courseInviteId=${courseInviteId}`}`
                  : scheduleGoogleMeetId
                  ? `login2?${`scheduleGoogleMeetId=${scheduleGoogleMeetId}`}`
                  : '/login2'
              }
              className='btn btn-flex shadow-lg hover-elevate-down btn-hover btnGrey btn-text-white btn-active-color-light-white flex-center text-nowrap w-100'
            >
              <div className='col-md-12'>
                {formatMessage({
                  id: 'AUTH.LOGIN.LOGIN_WITH_EMAIL',
                })}
                {/* Login with Email */}
              </div>
            </a>
          </div>
        </div>
      </div>

      <Modal
        show={signUpModal}
        onHide={() => setSignUpModal(false)}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <h5>
            {formatMessage({
              id: 'AUTH.LOGIN.SIGN_UP',
            })}
            {/* Sign up */}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body'>
            <div className='row g-3 my-9'>
              <div className='col-md-12'>
                <a
                  href={
                    isDiplomaStudent
                      ? `https://m.me/hopecityeducation?ref=signup-diploma`
                      : scheduleGoogleMeetId
                      ? `https://m.me/hopecityeducation?ref=signup-google-meet--${scheduleGoogleMeetId}`
                      : `https://m.me/hopecityeducation?ref=signup${
                          courseInviteId ? '--' + courseInviteId : ''
                        }`
                  }
                  className='btn btn-flex btn-shadow hover-elevate-down btn-hover color-2 btn-text-white btn-active-color-light-white flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/social-logos/facebook.svg')}
                    className='h-25px me-3'
                  />
                  {formatMessage({
                    id: 'AUTH.LOGIN.SIGN_UP_WITH_FACEBOOK',
                  })}
                  {/* Sign up with Facebook */}
                </a>
              </div>
            </div>
            <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>
                {formatMessage({
                  id: 'AUTH.LOGIN.OR_WITH_EMAIL',
                })}
                {/* Or with email */}
              </span>
            </div>
            <div className='row g-3 my-9'>
              <div className='col-ms-12'>
                <a
                  href={
                    isDiplomaStudent
                      ? `/signup?isDiploma=true`
                      : courseInviteId
                      ? `/signup?courseInviteId=${courseInviteId}`
                      : scheduleGoogleMeetId
                      ? `/signup?scheduleGoogleMeetId=${scheduleGoogleMeetId}`
                      : '/signup'
                  }
                  className='btn btn-flex shadow-lg hover-elevate-down btn-hover btnGrey btn-text-white btn-active-color-light-white flex-center text-nowrap w-100'
                >
                  {formatMessage({
                    id: 'AUTH.LOGIN.SIGN_UP_WITH_EMAIL',
                  })}
                  {/* Sign up with Email */}
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
