import React from 'react';

const K6_SocialScience_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>Social Science</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>- Learning Geography</li>
          <li>- Learning History</li>
          <li>- Learning Civics</li>
          <li>- Discovering Different Cultures Around the World.</li>
          <li>- Introduction to Money and Saving.</li>
          <li>- Exploring Continents and Oceans</li>
          <li>- Famous Figures and Events in History</li>
          <li>- Understanding Emotions and Feelings</li>
          <li>- Community and Neighborhoods</li>
          <li>
            - Learning about American Government, US History, and California
            Government
          </li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. A continuación, se muestran algunos ejemplos de
          temas relacionados que podrían aplicarse al requisito:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>- Aprender acerca de geografía</li>
          <li>- Aprender acerca de historia</li>
          <li>- Aprender acerca de educación cívica</li>
          <li>- Descubrir diferentes culturas alrededor del mundo.</li>
          <li>- Introducción al Dinero y al Ahorro.</li>
          <li>- Explorando continentes y océanos</li>
          <li>- Personajes y eventos famosos de la historia</li>
          <li>- Comprender las emociones y los sentimientos</li>
          <li>- Comunidad y Vecindarios</li>
          <li>
            - Aprender sobre el gobierno estadounidense, la historia de los EE.
            UU. y el gobierno de California
          </li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, desafortunadamente
          no podremos inscribir a su estudiante en Hope City. Sin embargo, si un
          padre o tutor puede enseñarle al estudiante esta materia en casa a
          través de videos de youtube y material en línea, entonces se cumplirá
          con este requisito del curso y podrá inscribirse en Hope City. Por
          favor confírmanos si su escuela o usted puede cumplir con este
          requisito.
        </div>
      </div>
    </div>
  );
};

export default K6_SocialScience_Requirement;
