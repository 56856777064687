import React from 'react';

const K6_Foreign_Requirement = () => {
  return (
    <div>
      <h1 className='text-center'>Foreign Language</h1>
      <div>
        <h5>
          There is a good chance your local school is meeting the requirements
          for this school course. Here's some examples of related topics that
          could apply to the requirement:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Discuss various techniques and approaches for effectively learning
            a foreign language.
          </li>
          <li>
            - Learn about the culture of countries where the language is spoken,
            including customs, traditions, and etiquette.
          </li>
          <li>
            - Break down language learning into listening, speaking, reading,
            and writing, and delve into strategies for enhancing each skill.
          </li>

          <li>
            - Study the advantages and challenges of being bilingual or
            multilingual and how it affects cognitive functions.
          </li>
          <li>
            - Dive into learning a specific language, such as Spanish, French,
            Mandarin, or any other language of interest.
          </li>
        </ul>
        <div className='form-label'>
          If your local school doesn’t meet this requirement, unfortunately we
          will not be able to enroll your student in Hope City. However, if a
          parent or guardian can teach the student this subject at home through
          youtube videos and online material, then this course requirement will
          be met and you can enroll in Hope City.
        </div>
      </div>

      <h1>—</h1>

      <div>
        <h5>
          Es muy probable que su escuela local cumpla con los requisitos para
          este curso escolar. A continuación, se muestran algunos ejemplos de
          temas relacionados que podrían aplicarse al requisito:
        </h5>
        <ul className='list-unstyled form-label'>
          <li>
            - Discutir diversas técnicas y enfoques para aprender efectivamente
            un idioma extranjero.
          </li>
          <li>
            - Conocer la cultura de los países donde se habla el idioma,
            incluyendo costumbres, tradiciones y etiqueta.
          </li>
          <li>
            - Desglosar el aprendizaje del idioma en escuchar, hablar, leer y
            escribir, y adentrarse en estrategias para mejorar cada habilidad.
          </li>
          <li>
            - Estudiar las ventajas y desafíos de ser bilingüe o multilingüe y
            cómo afecta a las funciones cognitivas.
          </li>
          <li>
            - Sumergirse en el aprendizaje de un idioma específico, como
            español, francés, mandarín u otro idioma de interés.
          </li>
        </ul>
        <div className='form-label'>
          Si su escuela local no cumple con este requisito, desafortunadamente
          no podremos inscribir a su estudiante en Hope City. Sin embargo, si un
          padre o tutor puede enseñarle al estudiante esta materia en casa a
          través de videos de youtube y material en línea, entonces se cumplirá
          con este requisito del curso y podrá inscribirse en Hope City. Por
          favor confírmanos si su escuela o usted puede cumplir con este
          requisito.
        </div>
      </div>
    </div>
  );
};

export default K6_Foreign_Requirement;
