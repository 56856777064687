import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FormattedLesson } from './Lesson.utils';
import { useAuth } from 'app/modules/auth';
import { usePostQuery } from 'hooks/reactQueryHelper';
import { useMutation, useQuery } from 'react-query';
import { getAxiosInstance } from 'helpers/api-helper';

export type PropsType = {
  loading?: boolean;
  lesson?: FormattedLesson;
  onStart: (id: Lesson['id']) => void;
};

const LessonCard: React.FC<PropsType> = (props) => {
  const { loading, lesson, onStart } = props;
  const { currentUser } = useAuth();

  const addUserLessonLog: any = usePostQuery(
    'addUserLessonLog',
    '/userLessonsLogs/upsertUserLessonsLog',
    {}
  );

  if (loading || !lesson) return null;

  const handleClick = (e: any) => {
    e.preventDefault();
    const formData = {
      StudentId: currentUser?._id,
      UserId: currentUser?.UserId?._id,
      LessonId: lesson?._original?._id,
    };
    if (formData) {
      addUserLessonLog.mutate(formData);
    }
    onStart(lesson.id);
  };

  const disabled = loading;

  return (
    <Card>
      <Card.Body className='col-md-4'>
        <Row>
          <Col>
            <Card.Title
              style={{ textOverflow: 'ellipsis', textAlign: 'center' }}
              as={'h1'}
            >
              {lesson.name}
            </Card.Title>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              as='a'
              href='#'
              variant='primary'
              size='sm'
              className='w-100 mt-5'
              disabled={disabled}
              onClick={handleClick}
            >
              Comenzar lección
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

LessonCard.defaultProps = {
  loading: false,
};

export default LessonCard;
