import React, { useCallback, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';

import Assignment from './Assignment';
import Grades from './Grade';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from 'theme/layout/core';
import LoaderNew from 'app/components/LoaderNew';
import useIntlCustom from 'hooks/useIntlCustom';
import CompletedCourseNotificationModal from 'app/components/CompletedCourseNotificationModal';

const StudentCourse = () => {
  const { formatMessage } = useIntlCustom();
  const navigate = useNavigate();
  const { courseInviteId, tabIndex } = useParams();
  const [courseData, setCourseData] = useState<any>({});
  const [navSelected, setNavSelected] = useState(1);
  const [congratulationModel, setCongratulationModel] = useState(false);

  const checkPopupShowQuery: any = usePostQuery(
    'checkPopupShow',
    `/courseinvite/checkpopupshow`,
    {}
  );
  const getCourseByCourseInvitedIdQuery: any = useGetQuery(
    'getCourseByCourseInvitedIdQuery',
    '/course/getCourseByCourseInvitedId'
  );

  useEffect(() => {
    if (getCourseByCourseInvitedIdQuery) {
      getCourseByCourseInvitedIdQuery.mutateAsync({
        params: { id: courseInviteId },
      });
    }
  }, []);

  useEffect(() => {
    if (getCourseByCourseInvitedIdQuery.isSuccess) {
      if (getCourseByCourseInvitedIdQuery?.data?.ResultObject?.courseInvite) {
        checkPopupShowQuery.mutate({ courseInviteId: courseInviteId });
      } else {
        navigate(`/course`);
      }
    }
  }, [getCourseByCourseInvitedIdQuery.isSuccess]);

  useEffect(() => {
    if (tabIndex) {
      setNavSelected(Number(tabIndex));
    }
  }, [tabIndex]);

  useEffect(() => {
    if (checkPopupShowQuery.isSuccess) {
      setCongratulationModel(checkPopupShowQuery?.data?.ResultObject?.show);
    }
  }, [checkPopupShowQuery.isSuccess]);

  const handleSelect = useCallback((eventKey: any) => {
    setNavSelected(eventKey);
  }, []);

  const onHideCongratulationModal = () => {
    setCongratulationModel(false);
    navigate(`/course`);
  };

  return (
    <>
      <LoaderNew
        loading={
          getCourseByCourseInvitedIdQuery?.isLoading ||
          checkPopupShowQuery?.isLoading
        }
        isFull={true}
      />
      <PageTitle breadcrumbs={[]}>
        <h3 className='d-flex align-items-center'>
          <span className='card-label fw-bold fs-1 text-gray-800'>
            {getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course
              ?.name &&
              getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.name}
          </span>
          {getCourseByCourseInvitedIdQuery?.data?.ResultObject?.courseInvite
            ?.isCompleted && (
            <span className='ms-2 badge bg-success'>
              {formatMessage({
                id: 'COURSE.COMPLETED',
              })}
            </span>
          )}
        </h3>
      </PageTitle>

      {congratulationModel && (
        <CompletedCourseNotificationModal
          show={congratulationModel}
          onHide={onHideCongratulationModal}
          courseData={checkPopupShowQuery?.data?.ResultObject?.similerCourese}
        />
      )}
      {getCourseByCourseInvitedIdQuery?.isSuccess &&
        checkPopupShowQuery?.isSuccess && (
          <>
            <div className='mb-10'>
              <Nav
                variant='pills'
                defaultActiveKey={navSelected}
                onSelect={handleSelect}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey='1'
                    title={formatMessage({
                      id: 'COURSE.ASSIGNMENT',
                    })}
                    className='fs-5 fw-bold'
                  >
                    {formatMessage({
                      id: 'COURSE.ASSIGNMENT',
                    })}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='2'
                    title={formatMessage({
                      id: 'COURSE.GRADES',
                    })}
                    className='fs-5 fw-bold'
                  >
                    {formatMessage({
                      id: 'COURSE.GRADES',
                    })}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            {navSelected == 1 && <Assignment />}
            {navSelected == 2 && (
              <Grades
                getCourseByCourseInvitedIdQuery={
                  getCourseByCourseInvitedIdQuery
                }
              />
            )}
          </>
        )}
    </>
  );
};

export default StudentCourse;
