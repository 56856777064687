import LoaderNew from 'app/components/LoaderNew';
import { useDeleteQuery, useGetQuery } from 'hooks/reactQueryHelper';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageTitle } from 'theme/layout/core';
import _ from 'lodash';
import { toAbsoluteUrl } from 'theme/helpers';
import Swal from 'sweetalert2';
import usePercentageCounter from 'hooks/Calculate';
import UpsertCourseAsTeacherModal from './UpsertCourseAsTeacherModal';
import ViewStudentGradePointsModal from './ViewStudentGradePointsModal';
import ProgressBar from 'react-bootstrap/ProgressBar';

const CourseDetails = () => {
  let letterGradeColors: any = {
    A: 'badge-success',
    B: 'badge-primary',
    C: 'badge-warning',
    D: 'badge-info',
    F: 'badge-danger',
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const { courseId } = useParams();
  const [modalUpdateCourseShow, setModalUpdateCourseShow] = useState(false);

  const [modalStudentGradePointShow, setModalStudentGradePointShow] = useState({
    data: null,
    show: false,
  });
  const deleteInvitationQuery: any = useDeleteQuery(
    'deleteInvitation',
    '/course/invite/delete'
  );

  const getCourseQuery: any = useGetQuery('getCourse', '/course/getById');
  const getInvitesQuery: any = useGetQuery('getInvites', '/course/invite');
  const getCompletedLessonQuery: any = useGetQuery(
    'getCompletedLesson',
    '/course/getCompletedLesson'
  );

  useEffect(() => {
    getCourseQuery.mutate({ params: { id: courseId } });
    getInvitesQuery.mutate({ params: { id: courseId } });
    getCompletedLessonQuery.mutate({ params: { id: courseId } });
  }, []);

  const ageGroupFormate = (item: any) => {
    if (item?.maxAge == 200) {
      return item?.minAge + '+';
    }
    return item?.minAge + '-' + item?.maxAge;
  };

  useEffect(() => {
    if (deleteInvitationQuery.isSuccess) {
      Swal.fire(
        'Delete Enrolled Student!',
        'Your Enrolled Student has been deleted.',
        'success'
      ).then((result) => {
        getInvitesQuery.mutate({ params: { id: courseId } });
      });
    }
  }, [deleteInvitationQuery.isSuccess]);

  const onShowViewStudentGradePointsModal = React.useCallback((item: any) => {
    setModalStudentGradePointShow((prev) => ({
      ...prev,
      data: item,
      show: true,
    }));
  }, []);
  const onHideViewStudentGradePointsModal = React.useCallback(() => {
    setModalStudentGradePointShow((prev) => ({
      ...prev,
      show: false,
    }));
  }, []);

  const onReloadCourse = () => {
    setModalUpdateCourseShow(false);
    getCourseQuery.mutate({ params: { id: courseId } });
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>Details</PageTitle>
      <LoaderNew
        loading={deleteInvitationQuery.isLoading}
        isFull={true}
        className='card'
      />
      <div className='row g-10'>
        <div className='card card-custom card-flush'>
          <div className='card-body'>
            <h3 className='card-title'>Invite Link</h3>
            <h6 className='card-subtitle mb-2 text-muted'>
              Invite students to join your classroom
            </h6>
            <div className='d-flex justify-content-between align-items-center bg-dark p-2 rounded'>
              <span className='text-muted ps-2 text-truncate'>{`${location.origin}/course/${courseId}/invite`}</span>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard
                      .writeText(`${location.origin}/course/${courseId}/invite`)
                      .then(() => {
                        Toast.fire({
                          icon: 'success',
                          title: 'copied',
                        });
                      })
                      .catch((err) => {
                        console.error('Failed to copy: ', err);
                      });
                  } else {
                    alert(
                      'Your browser does not support copying to clipboard.'
                    );
                  }
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>

        <div className='card card-custom card-flush'>
          {modalStudentGradePointShow.show && (
            <ViewStudentGradePointsModal
              show={modalStudentGradePointShow.show}
              data={modalStudentGradePointShow.data}
              onHide={onHideViewStudentGradePointsModal}
            />
          )}
          <div className='card-body '>
            <h3 className='card-title mb-5'>Enrolled Students</h3>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr className='fw-bolder fs-6 text-light bg-primary text-nowrap'>
                    <th className='ps-2' style={{ width: '10%' }}>
                      First Name
                    </th>
                    <th style={{ width: '10%' }}>Last Name</th>
                    <th style={{ width: '10%' }}>Percentage</th>
                    <th style={{ width: '20%' }}>Letter Grade</th>
                    <th style={{ width: '40%' }}>Progress</th>
                    <th style={{ width: '10%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {getInvitesQuery?.data?.ResultObject?.results?.map(
                    (item: any) => (
                      <tr>
                        <td className='ps-2 align-middle'>
                          {item?.user?.FirstName}
                        </td>
                        <td className='align-middle'>{item?.user?.LastName}</td>
                        <td className='align-middle'>
                          {item?.averagePercentage
                            ? item?.averagePercentage
                            : '-'}
                        </td>
                        <td className='align-middle'>
                          <span
                            className={`badge ${
                              letterGradeColors[item?.grade?.name]
                            } border px-5`}
                          >
                            {item?.grade?.name?.toString()}
                          </span>
                        </td>
                        <td className='align-middle'>
                          <div className='position-relative'>
                            <ProgressBar
                              variant='primary'
                              now={usePercentageCounter(
                                Number(item?.totalPointSum),
                                Number(
                                  item?.course?.pointsNeededToCompleteCourse
                                )
                              )}
                              visuallyHidden
                              className='h-20px '
                            />
                            <div className='position-absolute top-50 start-50 translate-middle fw-bold'>
                              {item?.totalPointSum} of{' '}
                              {item?.course?.pointsNeededToCompleteCourse}
                            </div>
                          </div>
                        </td>
                        <td className='d-flex'>
                          <button
                            type='button'
                            className='btn btn-sm btn-primary'
                            onClick={() =>
                              setModalStudentGradePointShow((prev) => ({
                                ...prev,
                                data: item,
                                show: true,
                              }))
                            }
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                  {getInvitesQuery.isSuccess &&
                    getInvitesQuery?.data?.ResultObject?.results?.length ===
                      0 && (
                      <tr>
                        <td colSpan={99} className='text-center text-muted'>
                          No data available
                        </td>
                      </tr>
                    )}
                  {getInvitesQuery.isLoading && (
                    <tr>
                      <td colSpan={99} className='text-center text-muted'>
                        Loading...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='card card-custom card-flush relative'>
          {modalUpdateCourseShow && (
            <UpsertCourseAsTeacherModal
              show={modalUpdateCourseShow}
              onHide={() => setModalUpdateCourseShow(false)}
              onReload={() => onReloadCourse()}
              data={getCourseQuery.data}
            />
          )}
          <LoaderNew
            loading={getCourseQuery.isLoading}
            isFull={false}
            className='card'
          />

          <div className='card-body '>
            <div className='d-flex justify-content-between align-items-center mb-5'>
              <h3 className='card-title'>Course Details</h3>
              <div>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => setModalUpdateCourseShow(true)}
                >
                  Edit Class
                </button>
              </div>
            </div>
            <div className='row'>
              <dt className='col-3'>Teacher Name</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.createdBy?.FirstName}{' '}
                {getCourseQuery?.data?.ResultObject?.createdBy?.LastName}
              </dd>

              <dt className='col-3'>Course Name</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.name}
              </dd>

              <dt className='col-3'>Grade</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.grade}
              </dd>

              <dt className='col-3'>Target Language</dt>
              <dd className='col-9'>
                {
                  getCourseQuery?.data?.ResultObject?.targetLanguage
                    ?.Language_Name
                }
              </dd>

              <dt className='col-3'>Language Level</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.languageLevel?.Level_Name}
              </dd>

              <dt className='col-3'>Age Group</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.ageOfGroup
                  ?.map((item: any) => ageGroupFormate(item))
                  ?.toString()}
              </dd>

              {/* <dt className='col-3'>Self-paced Lessons</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.selfPacedLessons?.toString()}
              </dd>

              <dt className='col-3'>Live Classes</dt>
              <dd className='col-9'>
                {getCourseQuery?.data?.ResultObject?.liveClasses?.toString()}
              </dd> */}

              <dt className='col-3'>Points Needed</dt>
              <dd className='col-9'>
                {
                  getCourseQuery?.data?.ResultObject
                    ?.pointsNeededToCompleteCourse
                }
              </dd>

              {/* <dt className='col-3'>Due Dates</dt>
              <dd className='col-9'>
                <ul>
                  {getCourseQuery?.data?.ResultObject?.dueDates?.map(
                    (item: any) => (
                      <li>
                        {' '}
                        {moment(item?.date).format('MM/DD/YYYY')} -{' '}
                        {item?.points} points
                      </li>
                    )
                  )}
                </ul>
              </dd> */}

              <dt className='col-3'>Grading Rubric</dt>
              <dd className='col-9'>
                <ul>
                  {getCourseQuery?.data?.ResultObject?.gradingRubric?.map(
                    (item: any) => (
                      <li>
                        {' '}
                        {item?.name} : {item?.min}% - {item?.max}%
                      </li>
                    )
                  )}
                </ul>
              </dd>

              <dt className='col-3'>School Name</dt>
              <dd className='col-9'>
                <img
                  alt='School Logo'
                  src={
                    getCourseQuery?.data?.ResultObject?.schoolLogo
                      ? getCourseQuery?.data?.ResultObject?.schoolLogo
                      : toAbsoluteUrl('/media/svg/avatars/blank.svg')
                  }
                  className='h-50px me-2'
                />
                {getCourseQuery?.data?.ResultObject?.schoolName}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
