import React, { useEffect, useState } from 'react';
import { useGetQuery, usePostQuery } from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import { useNavigate } from 'react-router-dom';
import Stepper from '../stepper/Stepper';
import Enrollment_Section1 from './Enrollment_Section1';
import Enrollment_Section2 from './Enrollment_Section2';
import Enrollment_Section3 from './Enrollment_Section3';
import EnrollmentFormHeader from './EnrollmentFormHeader';

const Enrollment = () => {
  const getByIdEnrollmentAllQuery: any = useGetQuery(
    'getByIdEnrollmentAll',
    '/enrollment/getbyid',
    {}
  );
  const getUserDataQuery: any = usePostQuery(
    'getUserData',
    'user/studentGetById',
    {}
  );

  const getEnrollmentReportQuery: any = useGetQuery(
    'getEnrollmentReport',
    '/enrollment/report/get',
    {}
  );

  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [gradeValue, setGradeValue] = useState('');
  const [informationData, setInformationData] = useState<any>('');
  const [gradeData, setGradeData] = useState('');
  const [guardianData, setGuardianData] = useState('');
  const [enrollmentData, setEnrollmentData] = useState<any>('');
  const [stepper, setStepper] = useState(3);
  const [errorField, setErrorField] = useState<any>('');
  const [fieldReportedIssue, setFieldReportedIssue] = useState<any>('');

  useEffect(() => {
    getUserDataQuery.mutateAsync();
    getEnrollmentReportQuery.mutateAsync();
  }, []);

  useEffect(() => {
    if (getEnrollmentReportQuery?.isSuccess) {
      setErrorField(getEnrollmentReportQuery?.data?.ResultObject);
      setFieldReportedIssue(
        getEnrollmentReportQuery?.data?.ResultObject?.fieldReportedIssue
      );
    }
  }, [getEnrollmentReportQuery?.isSuccess]);

  useEffect(() => {
    getByIdEnrollmentAllQuery.mutateAsync();
  }, [step]);

  useEffect(() => {
    if (getByIdEnrollmentAllQuery.isSuccess) {
      const data = getByIdEnrollmentAllQuery?.data?.ResultObject;
      setEnrollmentData({ ...enrollmentData, ...data });
    }
  }, [getByIdEnrollmentAllQuery.isSuccess]);

  return (
    <>
      <Loader loading={getByIdEnrollmentAllQuery.isLoading} />
      <div>
        <EnrollmentFormHeader setStep={setStep} step={step} />
        {step != 0 && (
          <Stepper setStep={setStep} step={step} stepper={stepper} />
        )}
        {step == 1 && (
          <Enrollment_Section1
            setStep={setStep}
            step={step}
            setGradeValue={setGradeValue}
            setInformationData={setInformationData}
            informationData={informationData}
            enrollmentData={enrollmentData}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
        {step == 2 && (
          <Enrollment_Section2
            setStep={setStep}
            step={step}
            gradeValue={gradeValue}
            setGradeData={setGradeData}
            gradeData={gradeData}
            enrollmentData={enrollmentData}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
        {step == 3 && (
          <Enrollment_Section3
            setStep={setStep}
            step={step}
            setGuardianData={setGuardianData}
            guardianData={guardianData}
            enrollmentData={enrollmentData}
            fieldReportedIssue={fieldReportedIssue}
          />
        )}
      </div>
    </>
  );
};

export default Enrollment;
