import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';
import { Button } from 'react-bootstrap';

const ReEnrollmentFormFooter = (props: any) => {
  const { formatMessage } = useIntlCustom();
  const { isBackButton, setStep, step, isSubmitButton } = props;

  return (
    <>
      <div className='mt-3'>
        <Button
          type='submit'
          className='btn btn-primary float-end'
          style={{ marginLeft: '10px' }}
        >
          {isSubmitButton
            ? formatMessage({
                id: 'SUBMIT',
              })
            : formatMessage({
                id: 'NEXT',
              })}
        </Button>
        <Button type='button' className='btn btn-secondary float-end'>
          {formatMessage({
            id: 'CANCEL',
          })}
        </Button>
        {isBackButton && (
          <Button
            type='button'
            className='btn btn-secondary float-start'
            onClick={() => setStep(step - 1)}
          >
            {formatMessage({
              id: 'BACK',
            })}
          </Button>
        )}
      </div>
    </>
  );
};

export default ReEnrollmentFormFooter;
