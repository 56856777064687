import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Confetti from 'react-dom-confetti';
import { FaPlay, FaPause } from 'react-icons/fa';
import FooterActivityPage from '../activityfooter/FooterActivityPage';
import successSound from '../../../theme/assets/audio/successSound.mp3';

const ListenItActivity: React.FC<any> = (props: any) => {
  const {
    singleLessonData,
    setListenITActivityShow,
    setSectionType,
    setVideoSectionShow,
    sectionType,
    setResultShow,
    setResultAnswerCount,
    resultAnswerCount,
    setWriteITActivityShow,
    setSectionIndex,
    sectionIndex,
    nativeLang,
    targetLang,
    setPrevLastIndexQuestion,
    setQuestionResultList,
    questionResultList,
    quizQuestionCount,
    setQuizQuestionCount,
    setAiImageShowActivityShow,
    trueAnswerCount,
    setTrueAnswerCount,
  } = props;

  var listenITQuestionsLenght: number;

  const [animationShow, setAnimationShow] = useState(false);
  const [listenItAudioPlay, setListenItAudioPlay] = useState(false);

  var data = singleLessonData?.Activity_Details?.filter(
    (x: any) => x.Section == sectionType
  )[0];

  // const [data, setData] = useState(singleLessonData.Activity_Details.filter((x: any) => (x.Section == sectionType)))

  const [listenITIndex, setListenITIndex] = useState(0);
  // const [nativeLang, setNativeLang] = useState("en");
  // const [targetLang, setTargetLang] = useState("es");

  const [config, setConfig] = useState<any>({
    angle: 90,
    spread: '60',
    startVelocity: '41',
    elementCount: '40',
    dragFriction: '0.13',
    duration: 1000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '414px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  });

  const handleListenITQuestionsAnswer = (
    event: any,
    index: any,
    questionsAnswer: any,
    audioTagId: any
  ) => {
    const optionsAudioPlay: any = document.getElementById(audioTagId);
    if (sectionType !== 'QuizSection') {
      optionsAudioPlay.play();
    }
    const audioSuccess: any = document.getElementById('audioSuccess');
    if (event.target.value === questionsAnswer) {
      if (index !== listenITQuestionsLenght) {
        if (sectionType == 'VocabularySection') {
          audioSuccess.play();
          setAnimationShow(true);
          event.target.classList.add('btn-green');
          setTimeout(() => {
            setAnimationShow(false);
            event.target.classList.add('btn-disable');
          }, 1000);
        }
        setTimeout(() => {
          setListenITIndex(index + 1);
        }, 1000);
      }
      if (index == listenITQuestionsLenght) {
        if (
          sectionType == 'VocabularySection' ||
          sectionType == 'AISection' ||
          sectionType !== 'QuizSection'
        ) {
          audioSuccess.play();
          setTimeout(() => {
            setSectionIndex(sectionIndex + 1);
          }, 500);
          setTimeout(() => {
            setListenITActivityShow(false);
            if (sectionType == 'AISection') {
              setAiImageShowActivityShow(true);
            } else {
              setVideoSectionShow(true);
            }

            // setSectionType("ConversationSection")
          }, 1000);
          setAnimationShow(true);
          event.target.classList.add('btn-green');
          setTimeout(() => {
            setAnimationShow(false);
            event.target.classList.add('btn-disable');
          }, 1000);
        }
        if (sectionType == 'QuizSection') {
          var x = document.getElementsByClassName('option-btn');
          [].forEach.call(x, function (el: any) {
            el.classList.add('btn-disable');
          });
          setQuizQuestionCount(
            (quizQuestionCount: any) => quizQuestionCount + 1
          );
          setTimeout(() => {
            const questionList = {
              Question: `Pregunta - ${quizQuestionCount}`,
              Answer: true,
            };
            setQuestionResultList([...questionResultList, questionList]);
          }, 100);
          setResultAnswerCount(resultAnswerCount + 1);
          setTimeout(() => {
            setListenITActivityShow(false);
            setResultShow(true);
            // setSectionType("VocabularySection")
          }, 1000);
        }
      }
    } else {
      if (sectionType != 'QuizSection') {
        event.target.classList.add('btn-red');
        setTimeout(() => {
          event.target.classList.remove('btn-red');
          event.target.classList.add('btn-disable');
        }, 1000);
      } else {
        var x = document.getElementsByClassName('option-btn');
        [].forEach.call(x, function (el: any) {
          el.classList.add('btn-disable');
        });
        setQuizQuestionCount((quizQuestionCount: any) => quizQuestionCount + 1);
        setTimeout(() => {
          const questionList = {
            Question: `Pregunta - ${quizQuestionCount}`,
            Answer: false,
          };
          setQuestionResultList([...questionResultList, questionList]);
        }, 100);
        setTimeout(() => {
          setListenITActivityShow(false);
          setResultShow(true);
          // setSectionType("VocabularySection")
        }, 1000);
        setTrueAnswerCount(trueAnswerCount + 1);
      }
    }
  };

  const onListenAudioPlay = (listenAudio: any) => {
    const audioPlay: any = document.getElementById('listen-audio-play');
    audioPlay.play();
    setListenItAudioPlay(true);
    audioPlay.addEventListener('ended', function () {
      setListenItAudioPlay(false);
    });
  };

  useEffect(() => {
    if (animationShow) {
      setTrueAnswerCount(trueAnswerCount + 1);
    }
  }, [animationShow]);

  const onPreviousButton = () => {
    setTrueAnswerCount(trueAnswerCount - 1);

    if (listenITIndex != 0) {
      setListenITIndex(listenITIndex - 1);
    } else {
      // setSectionIndex(0)
      setPrevLastIndexQuestion(true);
      setListenITActivityShow(false);
      setWriteITActivityShow(true);
    }
  };
  return (
    <>
      {data?.Listen_IT?.Questions.map((key: any, index: any) => {
        listenITQuestionsLenght = data?.Listen_IT?.Questions.length - 1;
        if (index == listenITIndex) {
          let langQuestionData = key?.Question?.Language?.filter(
            (x: any) => x.Language_Code == nativeLang
          )[0];
          let langAnswerData = key?.Answer?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];

          // if (langAnswerData) {
          //   handlePreLoadAllAudio(langAnswerData);
          // }
          let langQuestionDataTarget = key?.Question?.Language?.filter(
            (x: any) => x.Language_Code == targetLang
          )[0];
          let langAnswerDataNative = key?.Answer?.Language?.filter(
            (x: any) => x.Language_Code == nativeLang
          )[0];
          let questionsAnswer = langQuestionData?.Answer;
          var checkTargetLanguage =
            targetLang == 'ja' || targetLang == 'zh-CN' || targetLang == 'ar'
              ? true
              : false;
          var checkNativeLanguage =
            nativeLang == 'ja' || nativeLang == 'zh-CN' || nativeLang == 'ar'
              ? true
              : false;
          return (
            <div
              className='start-lesson-body p-4 start_lesson_body_mobile_video h-100'
              key={index}
            >
              <audio controls id='audioSuccess' preload='auto' hidden>
                <source src={successSound} type='audio/mp3' />
              </audio>
              <div className='start-lesson-main position-relative start_lesson_main_mobile_video'>
                <div className='container layout_container'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='w-100 position-relative d-flex flex-column'>
                        <div className='row d-grid gap-3'>
                          <div className='col-12 mx-auto'>
                            <div className='w-100'>
                              <div className='progress-bar mt-3'>
                                <ProgressBar
                                  animated
                                  now={
                                    listenITIndex == 0
                                      ? 80
                                      : listenITIndex
                                      ? 90
                                      : 95
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-10 mx-auto'>
                            <div className='w-100 z-10 d-grid gap-1 text-black'>
                              <div className='title-box'>
                                <div className='d-flex gap-2 text-center justify-content-between align-items-center'>
                                  <div className='sound-question-speaker-btn'>
                                    <button
                                      id='Mouse'
                                      value='Mouse'
                                      type='button'
                                      onClick={() =>
                                        onListenAudioPlay(
                                          langQuestionDataTarget?.AudioText_Audio
                                        )
                                      }
                                    >
                                      {listenItAudioPlay ? (
                                        <span>
                                          <FaPause size={60} color='white' />
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            paddingLeft: '15px',
                                            paddingTop: '10px',
                                          }}
                                        >
                                          <FaPlay size={60} color='white' />
                                        </span>
                                      )}
                                    </button>
                                    <audio
                                      controls
                                      id='listen-audio-play'
                                      preload='auto'
                                      hidden
                                    >
                                      <source
                                        src={
                                          langQuestionDataTarget?.AudioText_Audio
                                        }
                                      />
                                    </audio>
                                    <div className='Confetti-div'>
                                      <Confetti
                                        active={animationShow}
                                        config={config}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 option-box'>
                            <div className='w-100 py-2 footer_box_ab'>
                              <div className='d-flex justify-content-evenly py-2 gap-3 option-btn-padding'>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option1'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark border w-100 btn-lg rounded-pill option-btn'
                                    onClick={(event) =>
                                      handleListenITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        'audioOption1'
                                      )
                                    }
                                  >
                                    {checkNativeLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option1_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerDataNative?.Option1}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerDataNative?.Option1}</>
                                    )}
                                    {/* {langAnswerDataNative?.Option1} */}
                                  </button>
                                  <audio
                                    controls
                                    id='audioOption1'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option1}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option2'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark w-100 btn-lg rounded-pill option-btn'
                                    onClick={(event) =>
                                      handleListenITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        'audioOption2'
                                      )
                                    }
                                  >
                                    {checkNativeLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option2_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerDataNative?.Option2}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerDataNative?.Option2}</>
                                    )}
                                    {/* {langAnswerDataNative?.Option2} */}
                                  </button>
                                  <audio
                                    controls
                                    id='audioOption2'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option2}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                              </div>
                              <div className='d-flex justify-content-evenly py-2 gap-3 option-btn-padding option-box-2'>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option3'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark border w-100 btn-lg rounded-pill option-btn'
                                    onClick={(event) =>
                                      handleListenITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        'audioOption3'
                                      )
                                    }
                                  >
                                    {checkNativeLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option3_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerDataNative?.Option3}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerDataNative?.Option3}</>
                                    )}
                                    {/* {langAnswerDataNative?.Option3} */}
                                  </button>

                                  <audio
                                    controls
                                    id='audioOption3'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option3}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                                <div className='w-100'>
                                  <button
                                    id='Balloon'
                                    value='option4'
                                    type='button'
                                    className='option-btn btn-hover'
                                    // className='btn btn-outline-dark w-100 border btn-lg rounded-pill option-btn'
                                    // onClick={(event) => handleListenITQuestionsAnswer(event, index, questionsAnswer, index == 0 ? langAnswerDataNative.Audio_Option4 : index == 1 ? langAnswerData.Audio_Option4 : index == 2 ? langAnswerDataNative.Audio_Option4 : langAnswerData.Audio_Option4)}
                                    onClick={(event) =>
                                      handleListenITQuestionsAnswer(
                                        event,
                                        index,
                                        questionsAnswer,
                                        'audioOption4'
                                      )
                                    }
                                  >
                                    {checkNativeLanguage ? (
                                      <>
                                        <div className='btn-option-transperant'>
                                          {
                                            langAnswerData?.Option4_Roman_Language
                                          }
                                        </div>
                                        <div className='btn-option-transperant'>
                                          {langAnswerDataNative?.Option4}
                                        </div>
                                      </>
                                    ) : (
                                      <>{langAnswerDataNative?.Option4}</>
                                    )}
                                    {/* {index == 0 ? langAnswerDataNative.Option4 : index == 1 ? langAnswerData.Option4 : index == 2 ? langAnswerDataNative.Option4 : langAnswerData.Option4} */}
                                    {/* {langAnswerDataNative?.Option4} */}
                                  </button>

                                  <audio
                                    controls
                                    id='audioOption4'
                                    preload='auto'
                                    hidden
                                  >
                                    <source
                                      src={langAnswerData?.Audio_Option4}
                                      type='audio/mp3'
                                    />
                                  </audio>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <FooterActivityPage
                  nextButton={false}
                  onPreviousButton={onPreviousButton}
                  previousButton={sectionType != 'QuizSection' ? true : false}
                  urlHash={key?.Question_ID || undefined}
                  lesson={singleLessonData?._id || undefined}
                />
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default ListenItActivity;
