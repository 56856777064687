import { Link } from 'react-router-dom';
const AuthHeader: React.FC<{}> = (props: any) => {
  return (
    <div className='d-flex flex-stack py-2'>
      <div className='me-2'></div>
      <div className='m-0'>
        {/* <span
          className='text-gray-400 fw-bold fs-5 me-2'
          data-kt-translate='sign-in-head-desc'
        >
          Not a Member yet?
        </span>
        <Link
          to='/signup'
          className='link-primary fw-bold fs-5'
          data-kt-translate='sign-in-head-link'
        >
          Sign Up
        </Link> */}
      </div>
    </div>
  );
};

export default AuthHeader;
