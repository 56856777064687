/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TeacherCourseFolderList from './Teacher-CourseFolderList';
import TeacherCourseList from './Teacher-CourseList';
import {
  useDeleteQuery,
  useGetQuery,
  usePostQuery,
} from 'hooks/reactQueryHelper';
import Loader from 'app/components/Loader';
import TeacherFolderList from '../Teacher-FolderStructure/Teacher-FolderList';
import { useAuth } from 'app/modules/auth';
import TrashCourseList from './Trash-CourseList';
import Swal from 'sweetalert2';
import TrashFolderList from '../Teacher-FolderStructure/Trash-FolderList';

const TeacherCourse: React.FC = () => {
  const [drag, setDrag] = useState<any>(false);
  const [dragIndex, setDragIndex] = useState<any>(null);
  const [courseID, setCouresID] = useState<any>();
  const [courseDataID, setCourseDataID] = useState<any>();
  const [folderDrag, setFolderDrag] = useState<any>(false);
  const [folderDragIndex, setFolderDragIndex] = useState<any>(null);

  const [courseList, setCourseList] = useState([]);
  const [folderDataList, setFolderDataList] = useState([]);

  const [trashFolderList, setTrashFolderList] = useState([]);
  const [trashCourseList, setTrashCourseList] = useState([]);

  const [treeData, setTreeData] = useState<any>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([
    { ID: null, FolderName: 'Home' },
  ]);

  const [folderParentID, setFolderParentID] = useState<any>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const [dragFolderID, setDragFolderID] = useState<any>(null);
  const [dragType, setDragType] = useState<any>(null);

  const [isDelete, setIsDelete] = useState<any>(false);
  const [selectData, setSelectData] = useState<any>(null);

  const [UserID, setUserID] = useState<any>();

  const [selectedCourseIds, setSelectedCourseIds] = useState<any>([]);
  const [selectedFolder, setSelectedFolder] = useState<any>([]);

  const [modalFolderShow, setModalFolderShow] = React.useState(false);

  const getAllFolderAndCourseQuery: any = usePostQuery(
    'getAllFolderAndCourseQuery',
    '/folder/getAllCourse',
    {}
  );

  const getFolderList = useGetQuery(
    'getFolderList',
    '/course/listOfTrashData',
    {}
  );

  const deleteFolderQuery = useDeleteQuery(
    'deleteTrash',
    `/course/deleteTrashData`
  );

  const restoreQuery: any = usePostQuery(
    'restoreQuery',
    `/course/restoreData`,
    {}
  );

  let localStorageData: any = localStorage.getItem('kt-auth-react-v');
  const uID = JSON.parse(localStorageData);
  const { auth } = useAuth();

  const userId = auth?.data?.userID;

  useEffect(() => {
    if (uID) {
      setUserID(uID?.data?.userID);
      getRecord(null);
    }
  }, [localStorageData]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const handleTrashData = async () => {
    setBreadcrumbs([{ ID: null, FolderName: 'Trash' }]);
    setIsDelete(true);
    await getFolderList.mutate({ params: { userId: userId } });
    setSelectData(getFolderList?.data);
  };

  const handleDeleteConfirmation = (id: any) => {
    Swal.fire({
      title: 'Are you sure want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          deleteFolderQuery.mutateAsync({
            params: { id: id },
          });
        }
      }
    });
  };

  // useEffect(() => {
  //   getRecord(null);
  // }, []);

  useEffect(() => {
    if (getAllFolderAndCourseQuery.isSuccess) {
      setCourseList(getAllFolderAndCourseQuery?.data?.ResultObject?.CourseData);
      setFolderDataList(
        getAllFolderAndCourseQuery?.data?.ResultObject?.FolderData
      );
    }
  }, [getAllFolderAndCourseQuery.isSuccess]);

  useEffect(() => {
    if (getFolderList.isSuccess) {
      setTrashFolderList(
        getFolderList?.data?.ResultObject?.TrashData?.FolderTrashData
      );
      setTrashCourseList(
        getFolderList?.data?.ResultObject?.TrashData?.CourseTrashData
      );
    }
  }, [getFolderList.isSuccess]);

  useEffect(() => {
    if (dragIndex != null) {
      const findCouresID: any = courseList.filter((data: any, index: any) => {
        return index == dragIndex;
      })[0];
      setCouresID(findCouresID ? findCouresID._id : null);
    }
  }, [dragIndex]);

  const getRecord = async (folderID: any) => {
    getAllFolderAndCourseQuery.mutate({
      folderID: folderID,
      UserID: uID?.data?.userID,
    });
  };

  const handleHomeData = () => {
    setBreadcrumbs([{ ID: null, FolderName: 'Home' }]);
    setIsDelete(false);
    setSelectData(getAllFolderAndCourseQuery?.data);
  };

  const getBreadcrumb = (nodeId: string): string[] => {
    const node = treeData?.find((item: any) => item._id === nodeId);
    if (!node) return [];
    const data = { ID: node._id, FolderName: node.FolderName };
    const breadcrumbsArray: any = [];
    const breadcrumbsData = [...breadcrumbsArray, data];
    if (node.ParentID) {
      return [...getBreadcrumb(node.ParentID), ...breadcrumbsData];
    }
    return breadcrumbsData;
  };

  const onFolderClick = (folderId: string) => {
    setSelectedFolderId(folderId);
  };

  const [isButtonVisible, setButtonVisibility] = useState(false);

  const handleCheckboxChange = (Ids: any, event: any) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedCourseIds((prevIds: any) => [...prevIds, Ids]);
    } else {
      setSelectedCourseIds((prevIds: any) =>
        prevIds.filter((id: any) => id !== Ids)
      );
    }
  };

  const handleSelectFolder = (Ids: any, event: any) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedFolder((prevIds: any) => [...prevIds, Ids]);
    } else {
      setSelectedFolder((prevIds: any) =>
        prevIds.filter((id: any) => id !== Ids)
      );
    }
  };

  useEffect(() => {
    setButtonVisibility(selectedFolder.length > 0);
  }, [selectedFolder]);

  useEffect(() => {
    setButtonVisibility(selectedCourseIds.length > 0);
  }, [selectedCourseIds]);

  const handleDeleteMany = () => {
    Swal.fire({
      title: 'Are you sure want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3e97ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteFolderQuery.mutateAsync({
          params: { CourseId: selectedCourseIds, FolderId: selectedFolder },
        });
      }
    });
  };

  const handleRestore = () => {
    if (selectedCourseIds && selectedFolder) {
      restoreQuery.mutateAsync({
        CourseId: selectedCourseIds,
        FolderId: selectedFolder,
      });
    }
  };

  useEffect(() => {
    if (restoreQuery.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: 'Your course has been activated successfully.',
      });
      getFolderList.mutateAsync({ params: { userId: userId } });
      setSelectData(getAllFolderAndCourseQuery?.data);
    }
  }, [restoreQuery.isSuccess]);

  useEffect(() => {
    if (deleteFolderQuery?.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: 'Your course has been deleted successfully.',
      });
      getFolderList.mutateAsync({ params: { userId: userId } });
    }
  }, [deleteFolderQuery?.isSuccess]);

  return (
    <>
      {(getAllFolderAndCourseQuery?.isLoading ||
        getFolderList?.isLoading ||
        restoreQuery?.isLoading ||
        deleteFolderQuery?.isLoading) && (
        <Loader
          loading={
            getAllFolderAndCourseQuery?.isLoading ||
            restoreQuery?.isLoading ||
            getFolderList?.isLoading ||
            deleteFolderQuery?.isLoading
          }
        />
      )}
      <Container fluid>
        <Row className='g-5'>
          <Col lg={3}>
            <TeacherCourseFolderList
              drag={drag}
              setDrag={setDrag}
              dragIndex={dragIndex}
              setDragIndex={setDragIndex}
              courseList={courseList}
              setCourseList={setCourseList}
              courseID={courseID}
              courseDataID={courseDataID}
              setCourseDataID={setCourseDataID}
              setFolderDataList={setFolderDataList}
              folderDataList={folderDataList}
              setFolderParentID={setFolderParentID}
              folderParentID={folderParentID}
              getBreadcrumb={getBreadcrumb}
              setBreadcrumbs={setBreadcrumbs}
              setTreeData={setTreeData}
              treeData={treeData}
              setSelectedFolderId={setSelectedFolderId}
              onFolderClick={onFolderClick}
              selectedFolderId={selectedFolderId}
              setDragType={setDragType}
              dragType={dragType}
              dragFolderID={dragFolderID}
              setDragFolderID={setDragFolderID}
              breadcrumbs={breadcrumbs}
              UserID={UserID}
              getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
              handleHomeData={handleHomeData}
              restoreQuery={restoreQuery}
              getFolderList={getFolderList}
              modalFolderShow={modalFolderShow}
              setModalFolderShow={setModalFolderShow}
            />
          </Col>
          <Col lg={9}>
            {isDelete === true ? (
              <TrashCourseList
                breadcrumbs={breadcrumbs}
                getFolderList={getFolderList}
                trashCourseList={trashCourseList}
                setTrashCourseList={setTrashCourseList}
                setBreadcrumbs={setBreadcrumbs}
                getBreadcrumb={getBreadcrumb}
                handleTrashData={handleTrashData}
                deleteFolderQuery={deleteFolderQuery}
                setButtonVisibility={setButtonVisibility}
                isButtonVisible={isButtonVisible}
                selectedCourseIds={selectedCourseIds}
                setSelectedCourseIds={setSelectedCourseIds}
                handleCheckboxChange={handleCheckboxChange}
                handleDeleteMany={handleDeleteMany}
                handleRestore={handleRestore}
                getRecord={getRecord}
              />
            ) : (
              <TeacherCourseList
                drag={drag}
                setDrag={setDrag}
                dragIndex={dragIndex}
                setIsDelete={setIsDelete}
                setSelectData={setSelectData}
                handleTrashData={handleTrashData}
                setDragIndex={setDragIndex}
                courseList={courseList}
                setCourseList={setCourseList}
                setCourseDataID={setCourseDataID}
                courseDataID={courseDataID}
                setFolderDataList={setFolderDataList}
                folderDataList={folderDataList}
                breadcrumbs={breadcrumbs}
                onFolderClick={onFolderClick}
                getBreadcrumb={getBreadcrumb}
                setBreadcrumbs={setBreadcrumbs}
                selectedFolderId={selectedFolderId}
                getRecord={getRecord}
                setDragType={setDragType}
                dragType={dragType}
                dragFolderID={dragFolderID}
                UserID={UserID}
                setTreeData={setTreeData}
                getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
              />
            )}

            <Row>
              {isDelete === true ? (
                <TrashFolderList
                  breadcrumbs={breadcrumbs}
                  getFolderList={getFolderList}
                  handleTrashData={handleTrashData}
                  getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
                  trashFolderList={trashFolderList}
                  setTrashFolderList={setTrashFolderList}
                  setBreadcrumbs={setBreadcrumbs}
                  getBreadcrumb={getBreadcrumb}
                  deleteFolderQuery={deleteFolderQuery}
                  handleDeleteConfirmation={handleDeleteConfirmation}
                  setButtonVisibility={setButtonVisibility}
                  isButtonVisible={isButtonVisible}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  handleSelectFolder={handleSelectFolder}
                  handleDeleteMany={handleDeleteMany}
                  handleRestore={handleRestore}
                />
              ) : (
                <TeacherFolderList
                  setFolderDrag={setFolderDrag}
                  folderDrag={folderDrag}
                  folderDataList={folderDataList}
                  setFolderDataList={setFolderDataList}
                  setFolderDragIndex={setFolderDragIndex}
                  folderDragIndex={folderDragIndex}
                  setCourseList={setCourseList}
                  setFolderParentID={setFolderParentID}
                  onFolderClick={onFolderClick}
                  setDragFolderID={setDragFolderID}
                  dragFolderID={dragFolderID}
                  selectedFolderId={selectedFolderId}
                  setTreeData={setTreeData}
                  setDragType={setDragType}
                  dragType={dragType}
                  treeData={treeData}
                  courseDataID={courseDataID}
                  getBreadcrumb={getBreadcrumb}
                  setBreadcrumbs={setBreadcrumbs}
                  drag={drag}
                  setDrag={setDrag}
                  breadcrumbs={breadcrumbs}
                  UserID={UserID}
                  getAllFolderAndCourseQuery={getAllFolderAndCourseQuery}
                  getRecord={getRecord}
                  modalFolderShow={modalFolderShow}
                  setModalFolderShow={setModalFolderShow}
                />
              )}
              {isDelete === false ||
                (isButtonVisible && (
                  <>
                    <div className='d-flex justify-content-end align-items-center'>
                      <button
                        type='button'
                        className='btn btn-primary me-3'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Trash Restore'
                        onClick={handleRestore}
                      >
                        <i className='fas fa-trash-restore fs-3'></i>
                      </button>
                      <button
                        type='button'
                        className='btn btn-danger'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Trash Delete'
                        onClick={handleDeleteMany}
                      >
                        <i className='fa-solid fa-trash fs-3'></i>
                      </button>
                    </div>
                  </>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default TeacherCourse;
