import LoaderContent from 'app/components/ContentLoader';
import useIntlCustom from 'hooks/useIntlCustom';
import React from 'react';
import { GRADE_COLOR } from 'shared/constants';

const CourseGrading = ({ getCourseByCourseInvitedIdQuery }: any) => {
  const { formatMessage } = useIntlCustom();
  return (
    <div className='card card-stretch'>
      <LoaderContent loading={getCourseByCourseInvitedIdQuery.isLoading} />
      {getCourseByCourseInvitedIdQuery?.isSuccess && (
        <>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-gray-800'>
                {/* CourseGrading Scale */}
                {formatMessage({
                  id: 'COURSE.RATINGS.GRADING_SCALE',
                })}
              </h3>
            </div>
          </div>
          <div className='card-body'>
            {getCourseByCourseInvitedIdQuery?.data?.ResultObject?.course?.gradingRubric.map(
              (item: any, index: number) => {
                return (
                  <div className='d-flex align-items-center mb-5'>
                    <span
                      className={`bullet bullet-vertical h-25px bg-${
                        GRADE_COLOR[item.name]
                      }`}
                    ></span>
                    <div className='flex-grow-1 mx-5'>
                      <div
                        className={`text-gray-800 text-hover-${
                          GRADE_COLOR[item.name]
                        } fw-bold fs-6`}
                      >
                        {item.name}
                      </div>
                    </div>
                    <span
                      className={`badge badge-light-${
                        GRADE_COLOR[item.name]
                      } fs-6 fw-bold`}
                    >
                      {getCourseByCourseInvitedIdQuery?.isSuccess && (
                        <>
                          <>{item.min}%</>
                          {' - '}
                          <>{item?.max}%</>
                        </>
                      )}
                    </span>
                  </div>
                );
              }
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CourseGrading;
