import { combineReducers } from 'redux';

import login from './auth/reducer';
import student from './student/reducer';
import lessons from './lessons/reducer';
import classroom from './classroom/reducer';
import profileimage from './profileimage/reducer';
import openai from './openai/reducer';
import aiactivityimage from './aiactivityimage/reducer';

const rootReducer = combineReducers({
  login,
  student,
  lessons,
  classroom,
  profileimage,
  openai,
  aiactivityimage,
});

export type AuthState = ReturnType<typeof rootReducer>;

export default rootReducer;
