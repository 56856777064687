import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";
import { get, post } from "../../helpers/api-helper";
import { aiImageGenerateFailure, aiImageGenerateSuccess } from "./actions";
import { AI_IMAGE_GENERATE } from "./actionTypes";

// const ImageGenerateRequest = (data: any, config: any) => post("openai/test/openai/image/generations", data, config)

const ImageGenerateRequest = (data: any) => post("openai/test/image/generations", data)

function* ImageUploadSaga(action: any) {
    try {
        // const formData = new FormData();
        // formData.append('file', action.payload.file);
        const response: {
            ResultCode: string;
            ResultMessage: string;
            ResultObject: any;
        } = yield call(ImageGenerateRequest, {
            AIImageUrl: action.payload.AIImageUrl,
            Sentance: action.payload.Sentance
        });
        yield put(aiImageGenerateSuccess(response));
    } catch (error: any) {
        yield put(aiImageGenerateFailure({ error: error }));
    }
}

function* authSaga() {
    yield all([takeLatest(AI_IMAGE_GENERATE, ImageUploadSaga)]);
}

export default authSaga;

