import { SidebarMenuMain } from './SidebarMenuMain';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'app/modules/auth';
import StrickCounter from 'app/components/StrickCounter';
import UpgradePlan from 'app/components/UpgradePlan';

const SidebarMenu = () => {
  const { authSubscription } = useAuth();

  const history = useNavigate();

  const valueOfProgress = Math.floor(
    (authSubscription?.remainingFreeTrialDays /
      authSubscription?.freeTrialDay) *
      100
  );
  return (
    <div
      className='flex-column-fluid px-4 px-lg-8 py-4'
      id='kt_app_sidebar_nav'
    >
      <div
        id='kt_app_sidebar_nav_wrapper'
        className='d-flex flex-column hover-scroll-y pe-4 me-n4 h-100'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar, #kt_app_sidebar_nav'
        data-kt-scroll-offset='5px'
      >
        <div className='d-flex align-items-end flex-column mb-3 h-100'>
          <StrickCounter />
          <div className='w-100'>
            <div
              className='row row-cols-1 row-cols-lg-12'
              data-kt-buttons='true'
              data-kt-buttons-target='[data-kt-button]'
            >
              <SidebarMenuMain />
            </div>
          </div>
          <div className='mt-auto w-100'>
            <div className='d-flex align-items-center flex-column w-100'>
              <div className='d-flex justify-content-between fw-bolder fs-6 text-gray-800 w-100 mt-auto'>
                <div className='card text-center bg-light rounded w-100'>
                  <div className='card-body px-6 py-6'>
                    {authSubscription?.isFreeExpired === false &&
                      authSubscription?.remainingFreeTrialDays > 0 && (
                        <>
                          <h6>{`Cuenta Premium sin anuncios expira en ${authSubscription?.remainingFreeTrialDays} días`}</h6>
                          <UpgradePlan
                            className={`btn btn-sm fw-semibold btn-success py-1 px-3`}
                            title='Adquirir Premium'
                          />
                        </>
                      )}

                    {authSubscription?.isFreeExpired === false &&
                      authSubscription?.remainingFreeTrialDays > 0 && (
                        <div
                          className='w-100 bg-light-primary rounded mt-4'
                          style={{ height: '13px' }}
                        >
                          <div
                            className='bg-primary rounded'
                            role='progressbar'
                            style={{
                              height: '13px',
                              width: `${
                                valueOfProgress >= 0 ? valueOfProgress : 0
                              }%`,
                            }}
                            aria-valuenow={valueOfProgress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      )}

                    {authSubscription?.isFreeExpired === true &&
                      authSubscription?.isPremium === false && (
                        <>
                          <h6>Aprende más rápido</h6>
                          <UpgradePlan
                            className={`btn btn-sm fw-semibold btn-success py-1 px-3`}
                            title='Adquirir Premium'
                          />
                        </>
                      )}

                    {authSubscription?.isPremium === true &&
                      authSubscription?.isSubscriptionCancel === false && (
                        <button
                          className={`btn btn-sm fw-semibold btn-primary`}
                          onClick={(e) => {
                            e.preventDefault();
                            history('/account/payment');
                          }}
                        >
                          Premium
                        </button>
                      )}

                    {authSubscription?.isPremium === true &&
                      authSubscription?.isSubscriptionCancel === true &&
                      !!!authSubscription?.remainingSubscriptionDays && (
                        <span
                          className={`btn btn-sm fw-semibold btn-primary pe-none `}
                        >
                          {authSubscription?.remainingSubscriptionDays > 0 ? (
                            <>
                              Remaining day{' '}
                              {authSubscription?.remainingSubscriptionDays}
                            </>
                          ) : (
                            <>Your plan has expired today</>
                          )}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
